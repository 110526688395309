import { RetailEvent } from "../ReportCompiler/classes/RetailEvent.js";
import { AgreementEvent } from "../ReportCompiler/classes/AgreementEvent.js";
import { createDateFromString } from "../GeneralFunctions/CreateDateFromString.js";
import { ReportData } from "../utility-functions.js";
export function readGrossSalesDetail(json, confirmCommissionFromGrossSales, paymentColumn) {
  const salesArray = [];
  let staffInFile = [];

  let salesLocation = "";
  let salesDateI = 0;
  let salesInvoiceTypeI = 0;
  let salesProductI = 0;
  let salesSalesPersonI = 0;
  let salesSecondarySalesPersonI = 0;
  let salesFirstNameI = 0;
  let salesLastNameI = 0;
  let salesPriceI = 0;
  let salesNotesI = 0;
  let salesDiscountI = 0;
  let salesCategoryI = 0;
  let salesCreditI = 0;
  let salesIdI = 0;
  let salesRanByI = 0;
  let correctFileType = false;

  if (isCorrectSalesLog(json[0])) {
    alert("Wrong retail sales file, please use the 'Gross Sales Detail'");
    return correctFileType;
  }
  for (let i = 0; i < json.length; i++) {
    if (isGrossLocationRow(json[i])) {
      salesLocation = json[i][0].substring(
        json[i][0].indexOf("-") + 2,
        json[i][0].lastIndexOf("(") - 1
      );
    } else if (isGrossHeaderRow(json[i])) {
      for (let j = 0; j < json[i].length; j++) {
        if (
          json[i][j].includes("Date") &&
          !json[i][j].includes("Pending") &&
          !json[i][j].includes("Due") &&
          !json[i][j].includes("Agreement")
        ) {
          salesDateI = j;
        } else if (json[i][j].includes("Invoice Type")) {
          salesInvoiceTypeI = j;
        } else if (json[i][j].includes("Description")) {
          salesProductI = j;
        } else if (json[i][j].includes("Primary Sales Person")) {
          salesSalesPersonI = j;
        } else if (json[i][j].includes("Secondary Sales Person")) {
          salesSecondarySalesPersonI = j;
        } else if (json[i][j].includes("First Name")) {
          salesFirstNameI = j;
        } else if (json[i][j].includes("Last Name")) {
          salesLastNameI = j;
        } else if (
          (json[i][j].includes("Gross Revenue") && paymentColumn === -1) ||
          (paymentColumn > -1 && j === paymentColumn)
        ) {
        //   agreePriceI = j;
        // } else if (json[i][j].includes("Gross Revenue")) {
          salesPriceI = j;
        } else if (json[i][j].includes("Notes")) {
          salesNotesI = j;
        } else if (json[i][j].includes("Discount")) {
          salesDiscountI = j;
        } else if (json[i][j].includes("Payment Category")) {
          salesCategoryI = j;
        } else if (json[i][j].includes("Client Credit")) {
          salesCreditI = j;
        } else if (json[i][j].includes("Agreement ID")) {
          salesIdI = j;
        } else if (json[i][j].includes("Ran By")) {
          salesRanByI = j;
        }
      }
      correctFileType = true;
    } else if (isGrossDataRow(json[i], json[i][salesInvoiceTypeI])) {
      const salesDate = createDateFromString(json[i][salesDateI], "00:00");

      /**** VERIFY WITH SCOTT MY CHANGE */
      let price = json[i][salesPriceI];
      if (typeof price === "string") {
        price = parseFloat(price.replace(/\$/g, ""));
      } else {
        console.warn("price is not of type string");
      }
      // const price = parseFloat(json[i][salesPriceI].replaceAll("$", ""));

      let discount = calculateDiscount(json[i][salesDiscountI], price);

      if (
        json[i][salesInvoiceTypeI].includes("Product") ||
        (confirmCommissionFromGrossSales &&
          useAdditionalGrossSales(
            json[i][salesInvoiceTypeI],
            json[i][salesProductI]
          ))
      ) {
        const newStaff = {
          name: json[i][salesSalesPersonI],
          type: "sales",
          location: salesLocation,
        };
        
        let isDuplicate = staffInFile.some(staff => 
          staff.name === newStaff.name && staff.type === newStaff.type && staff.location === newStaff.location
        );
        
        if (!isDuplicate) {
          staffInFile.push(newStaff);
        }
        // staffInFile.push({
        //   name: json[i][salesSalesPersonI],
        //   type: "sales",
        //   location: salesLocation,
        // });

        let secondarySalesperson = "-";
        if (json[i][salesSecondarySalesPersonI].length > 0) {
          secondarySalesperson = json[i][salesSecondarySalesPersonI];
        }

        const newStaff2 = {
          name: secondarySalesperson,
          type: "sales",
          location: salesLocation,
        };
        
        let isDuplicate2 = staffInFile.some(staff => 
          staff.name === newStaff2.name && staff.type === newStaff2.type && staff.location === newStaff2.location
        );
        
        if (!isDuplicate2) {
          staffInFile.push(newStaff2);
        }

        // staffInFile.push({
        //   name: secondarySalesperson,
        //   type: "sales",
        //   location: salesLocation,
        // });

        const productEvent = new RetailEvent(
          salesLocation,
          salesDate,
          json[i][salesProductI],
          json[i][salesSalesPersonI],
          secondarySalesperson,
          json[i][salesFirstNameI],
          json[i][salesLastNameI],
          price,
          json[i][salesNotesI]
        );
        productEvent.discountPercent = discount;

        if (parseFloat(json[i][salesCreditI]) > 0) {
          productEvent.comment = "CREDIT USED";
        }

        // productEvent.id = json[i][salesIdI];

        salesArray.push(productEvent);
      }
      if (
        json[i][salesInvoiceTypeI].includes("Payment") &&
        json[i][salesCategoryI].includes("Membership")
      ) {
        const agreementEvent = new AgreementEvent(
          salesLocation,
          salesDate,
          json[i][salesProductI],
          json[i][salesSalesPersonI],
          "",
          json[i][salesFirstNameI],
          json[i][salesLastNameI],
          "",
          price,
          -1,
          "",
          json[i][salesNotesI]
        );

        agreementEvent.discountPercent = discount;
        if (json[i][salesRanByI] === "Automatic Draft") {
          agreementEvent.id = "";
        } else {
          agreementEvent.id = json[i][salesIdI];
        }
        salesArray.push(agreementEvent);
      }
    }
  }
  let reportData = new ReportData(correctFileType, salesArray);
  reportData.staffInFile = staffInFile;

  return reportData;
  return { correctFile: correctFileType, grossArray: salesArray };
}

function isCorrectSalesLog(row) {
  return row[0].includes("Product Sales");
}

function isGrossLocationRow(row) {
  return row[0].includes("Gross Sales Detail -");
}

function isGrossHeaderRow(row) {
  return row[0] === "Date" && row[1] === "Time";
}

function isGrossDataRow(row, salesInvoiceType) {
  //return (row[1] !== "" && salesInvoiceType.includes("Product"));
  return row[1] !== "";
}

function useAdditionalGrossSales(type, description) {
  if (type === "Payment" && description.includes("Enroll")) {
    return true;
  }
  if (
    type.includes("Credit Placed on Account") &&
    description.includes("Credit On Account")
  ) {
    return true;
  }
  return false;
}

function calculateDiscount(discountStr, price) {
  let discount = parseFloat(discountStr);
  if (isNaN(discount)) {
    discount = 0;
  }
  if (discount >= 0) {
    discount = 0;
  } else {
    discount = discount * -1;
  }
  let decimalDiscount = discount / (discount + price);
  let discountRounded = Math.round(decimalDiscount * 100) / 100;
  return discountRounded;
}
