import React from 'react';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';

export function CustomQuickFilter({ apiRef }) {
  const [value, setValue] = React.useState('');

  const handleChange = (event) => {
    setValue(event.target.value);
    apiRef.current.setQuickFilterValues([value]);

  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      apiRef.current.setQuickFilterValues([value]);
    }
  };

  const handleSearchClick = () => {
    apiRef.current.setQuickFilterValues([value]);
  };

  return (
    <TextField
      value={value}
      onChange={handleChange}
      onKeyDown={handleKeyDown}
      placeholder="Search..."
      variant="standard"
      InputProps={{
        endAdornment: (
          <IconButton onClick={handleSearchClick}>
            <SearchIcon />
          </IconButton>
        ),
      }}
    />
  );
}
