import { convertToBoolean } from "./GeneralTypeConversions.js";

export class BooleanToggle{
    constructor(value, defaultValue){
        this.value = defaultValue;
        this.addValue(value);
    }

    addValue(value){
        let calculatedValue = convertToBoolean(value);
        if(calculatedValue !== null){
            this.value = calculatedValue;
            return;
        }
        this.errorMessage = "Non-boolean input";
    }
}