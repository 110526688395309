import React, { useState } from "react";
import { API } from "aws-amplify";
import { LoadingButton } from "@mui/lab";
import {
  TextField,
  Button,
  Container,
  Box,
  MenuItem,
  InputLabel,
  FormControl,
  Select,
  Typography,
} from "@mui/material";
import { useAuth } from "../../contexts/AuthContext";
import { useSnackBar } from "../../contexts/SnackBarContext/SnackBarContext";
import "./mondayForm.scss";
// const MONDAY_API_TOKEN =
//   "eyJhbGciOiJIUzI1NiJ9.eyJ0aWQiOjMwNzQ3MzMxNywiYWFpIjoxMSwidWlkIjo0MzE3MTU4MywiaWFkIjoiMjAyNC0wMS0wNlQwMjowNzoyOS42ODlaIiwicGVyIjoibWU6d3JpdGUiLCJhY3RpZCI6MTE2MDIzMDEsInJnbiI6InVzZTEifQ.-cJLFlkn-hRc-xffR1sbzQFDvj6lK2EI5KStA0r4a10";
// const CUSTOMER_REQUEST_BOARD_ID = "5802642068";// test board
const CUSTOMER_REQUEST_BOARD_ID = "5706975691"; // test board

const MondayForm = () => {
  const showSnackBar = useSnackBar();
  const { currentUser } = useAuth();

  const [loading, setLoading] = useState(false); // State to track loading status

  const [formData, setFormData] = useState({
    typeOfIssue: "",
    description: "",
    files: [],
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleFileChange = (event) => {
    setFormData({
      ...formData,
      files: event.target.files,
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true); // Start loading

    const query = `
  mutation ($boardId: ID!, $columnValues: JSON!) {
    create_item (
      board_id: $boardId,
      group_id: "topics",
      item_name: "${currentUser.email}",
      column_values: $columnValues
    ) {
      id
    }
  }
  `;

    const variables = {
      boardId: parseInt(CUSTOMER_REQUEST_BOARD_ID),
      columnValues: JSON.stringify({
        text: currentUser.email,
        long_text: formData.description,
        status_11: formData.typeOfIssue,
        text0: currentUser.uid,
      }),
    };

    try {
      const response = await API.post("paywellAPIResource", "/MondayApiProxy", {
        body: { query, variables },
      });

      // Handle success
      showSnackBar("Support Ticket Submitted. We will get back to you shortly.", "success")
    } catch (error) {
      console.error("Error calling Lambda function", error);
      showSnackBar("Problem occurred. Please try again shortly.", "error")
      // Handle error
    } finally {
      setLoading(false); // Stop loading regardless of success or failure
    }
  };



  return currentUser?.email ? (
    <div className="monday-form">
      <Box
        className="monday-form__form"
        component="form"
        onSubmit={handleSubmit}
        noValidate
      >
        {/* <div>Email: {currentUser.email}</div> */}
        {/* Dropdown Selector */}
        <FormControl fullWidth margin="normal">
          <InputLabel>Type of Issue</InputLabel>
          <Select
            name="typeOfIssue"
            value={formData.typeOfIssue}
            label="Type of Issue"
            onChange={handleChange}
          >
            <MenuItem value="Report a Bug">Report a Bug</MenuItem>
            <MenuItem value="Technical Support">Technical Support</MenuItem>
            <MenuItem value="Billing Question">Billing Question</MenuItem>
            <MenuItem value="Suggest a New Feature">
              Suggest a New Feature
            </MenuItem>
            <MenuItem value="Suggest Improvement">Suggest Improvement</MenuItem>
          </Select>
        </FormControl>

        {/* Large Text Area */}
        <TextField
          fullWidth
          label="Description"
          name="description"
          margin="normal"
          variant="outlined"
          value={formData.description}
          onChange={handleChange}
          multiline
          rows={6}
        />

        {/* File Attachment Upload doing later */}
        {/* <Typography variant="subtitle1" sx={{ mt: 2, mb: 1 }}>
          Files
        </Typography>
        <Button variant="contained" component="label" sx={{ mb: 2 }}>
          Upload File
          <input type="file" hidden multiple onChange={handleFileChange} />
        </Button> */}

        {/* Submit Button */}
        <LoadingButton
          loading={loading}
          size="large"
          type="submit"
          className="monday-form__button"
          variant="contained"
        >
          Submit
        </LoadingButton>
      </Box>
    </div>
  ) : null;
};

export default MondayForm;
