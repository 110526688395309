import React, { useState } from "react";
import { Typography, Box, Divider, Skeleton } from "@mui/material";
import AttributeListBox from "../../Components/AttributeListBox";
import { clubPilatesLocation } from "../CommonVariables";
import { RevertList } from "../../Components/RevertList";

import { useAuth } from "../../../contexts/AuthContext";
import { useSettingsContext } from "../../../contexts/SettingsContext";
import { getUrlVariableValue } from "../../../utility-functions/utility-functions.js";
export default function Studiomanager(props) {
  const { getUID } = useAuth();
  const [isStudioSelected, setIsStudioSelected] = useState(false);
  const { settings, changes, setChanges, dispatch } = useSettingsContext();

  function handleChange(value, id) {
    let newRev = new RevertList(changes.getValues());
    newRev.addElement(
      id,
      settings[getUrlVariableValue("settingsId")].generalSettings[id],
      value
    );
    setChanges(newRev);

    // UPDATE STUDIOS
    dispatch({
      type: "UPDATE_STUDIO_SETTINGS",
      settingsId: getUrlVariableValue("settingsId"),
      uid: getUID(),
      studios: value,
    });

    // setStudioSettings({
    //   ...studioSettings,
    //   generalSettings: {
    //     ...settings[getUrlVariableValue("settingsId")].generalSettings,
    //     [id]: value,
    //   },
    // });
  }


  const order = [
    "Club",
    "Pure",
    "9Round",
    "Stretch",
    "Row",
    "Yoga",
    "Cycle",
    "BFT",
    "Rumble",
    "Stride",
    "AKT",
  ];

  const sortByFirstWord = (a, b) => {
    const firstWordA = a.split(" ")[0];
    const firstWordB = b.split(" ")[0];
    const indexA = order.indexOf(firstWordA);
    const indexB = order.indexOf(firstWordB);
    if (indexA < indexB) {
      return -1;
    } else if (indexA > indexB) {
      return 1;
    } else {
      return 0;
    }
  };

  const groupByLogic = (option) => {
    if (option.includes("Club Pilates")) {
      return "Club Pilates";
    } else if (option.includes("Pure Barre")) {
      return "Pure Barre";
    } else if (option.includes("StretchLab")) {
      return "Stretch Lab";
    } else if (option.includes("9Round")) {
      return "9Round";
    } else if (option.includes("Row House")) {
      return "Row House";
    } else if (option.includes("Yoga Six")) {
      return "Yoga Six";
    } else if (option.includes("Cycle Bar")) {
      return "Cycle Bar";
    } else if (option.includes("BFT")) {
      return "BFT";
    } else if (option.includes("Rumble")) {
      return "Rumble";
    } else if (option.includes("Stride")) {
      return "Stride";
    } else if (option.includes("AKT")) {
      return "AKT";
    } else {
      return "Other";
    }
  };


  /**** ~~~~ need to add logic for if the url settingsId entered does not exist */
  return (settings.hasOwnProperty("1") && settings.hasOwnProperty("staff"))  ? (
    <Box className="settings-page">
      <Typography variant="h2" className="settings-page__title">
        {settings[getUrlVariableValue("settingsId")]?.generalSettings?.locationsName || "Studios"}
      </Typography>
      <Divider className="settings-page__divider" />

      <AttributeListBox
        placeholder={"Type to Add " + settings[getUrlVariableValue("settingsId")]?.generalSettings?.locationsName || "Type to Add Studios"}
        setIsStudioSelected={setIsStudioSelected}
        className="settings-page__attribute-box"
        multiple
        onChange={handleChange}
        id="studios"
        default={settings[getUrlVariableValue("settingsId")].generalSettings.studios}
        size={800}
        label={settings[getUrlVariableValue("settingsId")]?.generalSettings?.locationName + " Names" || "Studio Names"}
        //options={clubPilatesLocation.sort().sort(sortByFirstWord)}
        limitTags={30}
        freeSolo
        noOptions="Type to add a new studio"
        grouping={groupByLogic}
      ></AttributeListBox>
    </Box>
  ) : (
    <Box className="settings-page">
      <Skeleton>
        <Typography variant="h2" className="settings-page__title">
        {settings[getUrlVariableValue("settingsId")]?.generalSettings?.locationsName || "Studios"}
        </Typography>
      </Skeleton>
      <Divider className="settings-page__divider" />
      <Skeleton className="settings-page__attribute-box settings-page__attribute-box--skeleton"></Skeleton>
    </Box>
  );
}
