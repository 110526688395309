import React from "react";
import Typography from "@mui/material/Typography";
import SelectorBox from "../../Components/SelectorBox/SelectorBox";
import InputBoxElement from "../../Components/InputBox/InputBox";
import AgreementSpecifics from "./AgreementSpecifics";
import BooleanToggleElement from "../../Components/BooleanToggle";
//import { AttributeListBox } from "../../Components/AttributeListBox/AttributeListBox";
import AttributeListBox from "../../Components/AttributeListBox";
import { useSettingsContext } from "../../../contexts/SettingsContext";
import { RevertList } from "../../Components/RevertList";
import { Box, Divider } from "@mui/material";
import { MultipleSelectPlaceholder } from "../../Components/SelectorBox/SelectorBox";
import { useAuth } from "../../../contexts/AuthContext";
import { getUrlVariableValue } from "../../../utility-functions/utility-functions.js";
export default function AdvancedCommissionSettings() {
  const { getUID } = useAuth();
  const { settings, dispatch, changes, setChanges } = useSettingsContext();
  
  function handleChange(value, id) {
    let newRev = new RevertList(changes.getValues());
    newRev.addElement(
      id,
      settings[getUrlVariableValue("settingsId")].commissionSettings.general[id],
      value
    );
    setChanges(newRev);

    // setStudioSettings({
    //   ...studioSettings,
    //   commissionSettings: {
    //     ...studioSettings.commissionSettings,
    //     general: { ...studioSettings.commissionSettings.general, [id]: value },
    //   },
    // });
    dispatch({
      type: "UPDATE_SETTINGS_PROPERTY",
      settingsId: getUrlVariableValue("settingsId"),
      uid: getUID(),
      path: `${getUrlVariableValue("settingsId")}.commissionSettings.general.${id}`,
      value: value,
    });
  }
  return (
    <>
      <Box className="settings-page__box settings-page__box--inline">
        <Typography className="settings-page__text">
          Use Gross Sales Detail for retail:
        </Typography>
        <BooleanToggleElement
          onChange={handleChange}
          id="useGrossSalesDetail"
          default={
            settings[getUrlVariableValue("settingsId")].commissionSettings.general
              .useGrossSalesDetail
          }
        ></BooleanToggleElement>
      </Box>

      <Divider className="settings-page__divider settings-page__divider--light" />
      <Box className="settings-page__box settings-page__box--inline">
        <Typography className="settings-page__text">
          The upgrade/downgrade {settings[getUrlVariableValue("settingsId")].generalSettings.commissionName} rate applies to upgrades/downgrades
          found with type(s)
        </Typography>
        <MultipleSelectPlaceholder
          onChange={handleChange}
          id="upgradePaymentTypes"
          size={350}
          options={[
            "Upgrade",
            "Downgrade",
            "Renewal",
            "N/A",
            "Upgrade (after cancel)",
            "Downgrade (after cancel)",
            "Renewal (after cancel)",
            "N/A (after cancel)",
          ]}
          placeholder="ALL TYPES"
          default={
            settings[getUrlVariableValue("settingsId")].commissionSettings.general
              .upgradePaymentTypes
          }
          label="Types"
          name="Upgrade Types"
        ></MultipleSelectPlaceholder>
      </Box>

      <Divider className="settings-page__divider settings-page__divider--light" />
      <Box className="settings-page__box settings-page__box--inline">
        <Typography className="settings-page__text">
          New memberships sold are no longer considered "post-intro sign ups"
          after
        </Typography>
        <InputBoxElement
          onChange={handleChange}
          id="postIntroSignUpTime"
          default={
            settings[getUrlVariableValue("settingsId")].commissionSettings.general
              .postIntroSignUpTime
          }
          types={["number"]}
          size={100}
          label="Hours"
        ></InputBoxElement>
        <Typography>hours</Typography>
      </Box>
      <Divider className="settings-page__divider settings-page__divider--light" />
      <Box className="settings-page__box settings-page__box--inline">
        <AgreementSpecifics></AgreementSpecifics>
        <Typography className="settings-page__text"></Typography>
      </Box>
      <Divider className="settings-page__divider settings-page__divider--light" />
      <Box className="settings-page__box settings-page__box--inline">
        <Typography className="settings-page__text">
          Agreements with ONLY ONE previous single {settings[getUrlVariableValue("settingsId")].generalSettings.sessionName} are considered
        </Typography>
        <SelectorBox
          onChange={handleChange}
          id="agreementSingleGC"
          size={200}
          default={
            settings[getUrlVariableValue("settingsId")].commissionSettings.general
              .agreementSingleGC
          }
          options={["New Agreement", "Upgrade"]}
          label="Type"
          name="Type"
        ></SelectorBox>
      </Box>
      <Divider className="settings-page__divider settings-page__divider--light" />

      <Box className="settings-page__box settings-page__box--inline">
        <Typography className="settings-page__text">
          Pay {settings[getUrlVariableValue("settingsId")].generalSettings.commissionName} on staff bought items:
        </Typography>
        <BooleanToggleElement
          onChange={handleChange}
          id="commissionStaffBought"
          default={
            settings[getUrlVariableValue("settingsId")].commissionSettings.general
              .commissionStaffBought
          }
        ></BooleanToggleElement>
      </Box>
      <Divider className="settings-page__divider settings-page__divider--light" />

      <Box className="settings-page__box settings-page__box--inline">
        <Typography className="settings-page__text">
          {" "}
          Agreements/Retail with a discount greater than
        </Typography>
        <InputBoxElement
          onChange={handleChange}
          id="discountPercentNoCommission"
          default={
            settings[getUrlVariableValue("settingsId")].commissionSettings.general
              .discountPercentNoCommission
          }
          types={["percent"]}
          size={100}
          label="Discount Percent"
        ></InputBoxElement>
        <Typography>% are NOT commissionable</Typography>
      </Box>
      <Divider className="settings-page__divider settings-page__divider--light" />

      <Box className="settings-page__box settings-page__box--inline">
        <Typography className="settings-page__text">
          Introductory {settings[getUrlVariableValue("settingsId")].generalSettings.sessionName} {settings[getUrlVariableValue("settingsId")].generalSettings.specialistsName} are paid a bonus of{" "}
        </Typography>
        <InputBoxElement
          onChange={handleChange}
          id="introInstructorBonus"
          default={
            settings[getUrlVariableValue("settingsId")].commissionSettings.general
              .introInstructorBonus
          }
          types={["dollar"]}
          size={100}
          label="Instructor Bonus"
        ></InputBoxElement>
        <Typography> for each attendee who signs up</Typography>
      </Box>
      <Divider className="settings-page__divider settings-page__divider--light" />

      <Box className="settings-page__box settings-page__box--inline">
        <Typography className="settings-page__text">
          Annual Agreements are paid{" "}
        </Typography>
        <InputBoxElement
          onChange={handleChange}
          id="annualAmount"
          default={
            settings[getUrlVariableValue("settingsId")].commissionSettings.general
              .annualAmount
          }
          types={["dollar", "percent"]}
          size={100}
          label="Annual"
        ></InputBoxElement>
      </Box>
      <Divider className="settings-page__divider settings-page__divider--light" />

      <Box className="settings-page__box settings-page__box--inline">
        <Typography className="settings-page__text">
          Enrollment Fees & Credit (Gift Cards) are commissionable:
        </Typography>
        <BooleanToggleElement
          onChange={handleChange}
          id="confirmCommissionFromGrossSales"
          default={
            settings[getUrlVariableValue("settingsId")].commissionSettings.general
              .confirmCommissionFromGrossSales
          }
        ></BooleanToggleElement>
      </Box>

      <Divider className="settings-page__divider settings-page__divider--light" />

      <Box className="settings-page__box settings-page__box--inline">
        <Typography className="settings-page__text">
          Column for retail payment{" "}
        </Typography>
        <InputBoxElement
          onChange={handleChange}
          id="retailColumn"
          default={
            settings[getUrlVariableValue("settingsId")].commissionSettings.general.retailColumn === undefined ? 'number~-1' : settings[getUrlVariableValue("settingsId")].commissionSettings.general.retailColumn
          }
          types={["number"]}
          size={100}
          label="RetailColumn"
        ></InputBoxElement>
      </Box>
      <Divider className="settings-page__divider settings-page__divider--light" />

      <Box className="settings-page__box settings-page__box--inline">
        <Typography className="settings-page__text">
          Introductory/Single agreements are:
        </Typography>
        <AttributeListBox
          multiple={true}
          freeSolo
          onChange={handleChange}
          id="singleAgreements"
          default={
            settings[getUrlVariableValue("settingsId")].commissionSettings.general.singleAgreements === undefined ? ["Single Class"] : settings[getUrlVariableValue("settingsId")].commissionSettings.general.singleAgreements
          }
          size={400}
          label="Agreement Names"
          options={['Single Class', 'Intro 25', 'Intro 50']}
        ></AttributeListBox>
      </Box>
      <Divider className="settings-page__divider settings-page__divider--light" />
    </>
  );
}
