export const createComparisonFile = (users, userSettings, userStaffCounts, mondayData, stripeData) => {
    console.log("Creating comparison file...");
    let usersArray = createUsersArray(users, userSettings, userStaffCounts);
    addMondayData(usersArray, mondayData);
    addStripeData(usersArray, stripeData);

    let dateRange = getDateRange(stripeData);

    for(let i = 0; i < usersArray.length; i++) {
        //Paid within Date Range Locations Match	Payment Matches	Payment Strucutre Correct	All Correct
        let stripeDate = new Date(usersArray[i]["Stripe Last Billing Date"]);
        let paidWithinDateRange = stripeDate >= dateRange[0] && stripeDate <= dateRange[1];
        let locationsMatch = usersArray[i]["# of Locations"] === usersArray[i]["Monday Active Studios"];
        let paymentMatches = usersArray[i]["Stripe Last Payment"] === usersArray[i]["Monday Revenue"];
        let paymentStructureOutput = parsePayStructure(usersArray[i]["Monday Pay Structure"], parseFloat(usersArray[i]["# of Locations"]));
        let paymentStructureCorrect = paymentStructureOutput === parseFloat(usersArray[i]["Stripe Last Payment"]);
        let allCorrect = paidWithinDateRange && locationsMatch && paymentMatches && paymentStructureCorrect;

        usersArray[i]["Date Range"] = dateRange[0].toLocaleDateString() + " - " + dateRange[1].toLocaleDateString();
        usersArray[i]["*IN RANGE*"] = paidWithinDateRange;
        usersArray[i]["*LOCACTIONS*"] = locationsMatch;
        usersArray[i]["*PAYMENTS*"] = paymentMatches;
        usersArray[i]["*PAY STRUCTURES*"] = paymentStructureCorrect;
        usersArray[i]["*CORRECT*"] = allCorrect;
    }
    return usersArray;
}

const getDateRange = (stripeData) => {
    let earliestDate = new Date();
    let latestDate = new Date(0);
    for (let i = 0; i < stripeData.length; i++) {
        let date = new Date(stripeData[i]["Created date (UTC)"]);
        if (date < earliestDate) {
            earliestDate = date;
        }
        if (date > latestDate) {
            latestDate = date;
        }
    }
    //let earliestDateStr = formatDate(earliestDate);
    //let latestDateStr = formatDate(latestDate);
    return [earliestDate, latestDate];
}

function formatDate(date) {
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // JavaScript months are 0-based.
    const year = date.getFullYear();
  
    return `${month}/${day}/${year}`;
  }

function parsePayStructure(rateDescription, numOfStudios) {
    let total = 0;
    if (rateDescription.includes("per studio")) {
        // Extract the numeric value and calculate the total pay
        const rate = parseFloat(rateDescription.split(" ")[0].substring(1));  // Remove '$' and parse
        total = rate * numOfStudios;
    } else if (rateDescription === "$150 + $50 (1-20) + $40 (21-...)") {
        // Calculate up to 20 studios at $50, and $40 for each studio beyond 20
        total = 150;
        total += Math.min(numOfStudios, 20) * 50;
        if (numOfStudios > 20) {
            total += (numOfStudios - 20) * 40;
        }
    } else if (rateDescription === "$400 + $20 (1-40) + $15 (41-...) + $200") {
        // Fixed rate plus tiered rates
        total = 400 + 200;  // Start with fixed amounts
        total += Math.min(numOfStudios, 40) * 20;
        if (numOfStudios > 40) {
            total += (numOfStudios - 40) * 15;
        }
    } else if (rateDescription === "$75 (1) + $50 (2-...)") {
        // Special rates for the first studio and different rates thereafter
        if (numOfStudios >= 1) {
            total = 75;  // First studio
            total += (numOfStudios - 1) * 50;  // Additional studios
        }
    } else if (rateDescription === "$75 (1) + $50 (2-5) + $35 (6-...)") {
        // Different rates for different ranges of studios
        if (numOfStudios >= 1) {
            total = 75;  // First studio
            if (numOfStudios >= 2) {
                total += Math.min(4, numOfStudios - 1) * 50;  // Studios 2-5
            }
            if (numOfStudios > 5) {
                total += (numOfStudios - 5) * 35;  // Studios beyond 5
            }
        }
    } else if(rateDescription.includes(" flat")) {
        const rate = parseFloat(rateDescription.split(" ")[0].substring(1));  // Remove '$' and parse
        total = rate;
    }
    return total;
}



const createUsersArray = (users, userSettings, userStaffCounts) => {
    let userArray = [];
    let userSettingsArray = Object.entries(userSettings);
    for (let i = 0; i < userSettingsArray.length; i++) {
      let userUID = userSettingsArray[i][0];
      let userSettings = userSettingsArray[i][1];

      let dateRange = "";
      let userEmail = getEmail(userUID, users);
      let presetCount = userSettings.length;
      let locations = getLocations(userSettings);
      let locationsCount = locations.length;
      let staffCount = userStaffCounts[userUID];

      let payWellStatus = users.filter(user => user.uid === userUID)[0].disabled ? "Disabled" : "Active";
      let payWellLastSignIn = users.filter(user => user.uid === userUID)[0].metadata.lastSignInTime;

      let mondayStatus = "";
      let mondayOtherEmails = "";
      let mondayActiveStudios = 0;
      let mondayRevenue = "";
      let mondayPayStructure = "";
      let mondayStudioRights = "";

      let stripeLastBilling = "";
      let stripeLastBillingDate = "";
      let stripeLastPayment = "";
      let stripeLastFee = "";
      let stripeNetRevenue = "";

      userArray.push(
        {
          "Date Range": dateRange,
          "User Email": userEmail,
          "UID": userUID,
          "# of Presets": presetCount,
          "# of Locations": locationsCount,
          "# of Staff": staffCount,
          "Status of User": payWellStatus,
          "Last Sign In": payWellLastSignIn,
          "Monday Status": mondayStatus,
          "Monday Other Emails": mondayOtherEmails,
          "Monday Active Studios": mondayActiveStudios,
          "Monday Revenue": mondayRevenue,
          "Monday Pay Structure": mondayPayStructure,
          "Monday Studio Rights": mondayStudioRights,
          "Stripe Number of Payments" : 0,
          "Stripe Last Billing": stripeLastBilling,
          "Stripe Last Billing Date": stripeLastBillingDate,
          "Stripe Last Payment": stripeLastPayment,
          "Stripe Last Fee": stripeLastFee,
          "Stripe Net Revenue": stripeNetRevenue
        }
      )
    }
    return userArray;
  }

const getLocations = (userSettings) => {
    let locations = [];
    for (let i = 0; i < userSettings.length; i++) {
      let generalSettings = userSettings[i].generalSettings;
      if (generalSettings) {
        let studios = generalSettings.studios;
        locations = locations.concat(studios);
      }
    }
    return locations;
  }

const getEmail = (uid, users) => {
    for (let i = 0; i < users.length; i++) {
      if (users[i].uid === uid) {
        return users[i].email;
      }
    }
  }

  const addMondayData = (usersArray, mondayData) => {
    // Add Monday.com data to the user array
    for (let user of usersArray) {
        let mondayUser;
        for (let md of mondayData) {
            if (md.Email && md.Email.toLowerCase().includes(user["User Email"].toLowerCase())) {
            mondayUser = md;
            break;
            }
        }
        if (mondayUser) {
            user["Monday Status"] = mondayUser.Stage;
            user["Monday Other Emails"] = mondayUser.Email;
            user["Monday Active Studios"] = mondayUser["Active Studios"];
            user["Monday Revenue"] = mondayUser["Monthly Revenue"];
            user["Monday Pay Structure"] = mondayUser["Month Payment Structure"];
            user["Monday Studio Rights"] = mondayUser["Studio Rights"];
        }
    }
  }

  const addStripeData = (usersArray, stripeData) => {
    // Add Stripe data to the user array
    for (let user of usersArray) {
        let numberOfPayments = 0;
        let totalPayment = 0;
        let totalFee = 0;
        let stripeEntries = [];

        for (let sd of stripeData) {
            if ((user["Monday Other Emails"] && user["Monday Other Emails"].toLowerCase().includes(sd["Customer Email"].toLowerCase())) 
                || (user["User Email"] && user["User Email"].toLowerCase().includes(sd["Customer Email"].toLowerCase()))) {
                stripeEntries.push(sd);
            }
        }

        if (stripeEntries.length > 0) {
            stripeEntries.forEach(stripeUser => {
                totalPayment += parseFloat(stripeUser["Converted Amount"]) || 0;
                totalFee += parseFloat(stripeUser["Fee"]) || 0;
                numberOfPayments++;
            });

            user["Stripe Number of Payments"] = numberOfPayments;
            user["Stripe Last Billing"] = stripeEntries[0]["Status"];
            user["Stripe Last Billing Date"] = new Date(stripeEntries[0]["Created date (UTC)"]);
            user["Stripe Last Payment"] = totalPayment;
            user["Stripe Last Fee"] = totalFee;
            user["Stripe Net Revenue"] = totalPayment - totalFee;
        }
    }
}

// const excelSerialDateToJSDate = (serial) => {
//     const excelEpoch = new Date(1899, 11, 30); // Excel epoch is 1900-01-01, adjust for the day offset
//     const daysSinceEpoch = serial;
//     const jsDate = new Date(excelEpoch.getTime() + daysSinceEpoch * 86400000); // 86400000 ms per day
//     return jsDate;
//   };

//   const formatDateToMMDDYYYY = (date) => {
//     const month = (date.getUTCMonth() + 1).toString().padStart(2, '0'); // Months are zero-indexed
//     const day = date.getUTCDate().toString().padStart(2, '0');
//     const year = date.getUTCFullYear();
//     return `${month}/${day}/${year}`;
//   };
