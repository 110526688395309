import React, { useState } from "react";
import Typography from "@mui/material/Typography";
import { Button } from "@mui/material";
import InputBoxElement from "../../Components/InputBox/InputBox";
import SelectorBox, {
  MultipleSelectPlaceholder,
} from "../../Components/SelectorBox/SelectorBox";
import { Stack, Box, Divider } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { IconButton } from "@mui/material";
import { RevertList } from "../../Components/RevertList";
import { v4 as uuidv4 } from "uuid";
import { useSettingsContext } from "../../../contexts/SettingsContext";
import { SingleAttributeListBox } from "../../Components/AttributeListBox";
import { timeLabelExamples } from "../CommonVariables";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import Avatar from "@mui/material/Avatar";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import BasicModal from "../../../components/Modal/Modal";
import { useAuth } from "../../../contexts/AuthContext";
import { getUrlVariableValue } from "../../../utility-functions/utility-functions.js";
const monthDays = [
  "1st",
  "2nd",
  "3rd",
  "4th",
  "5th",
  "6th",
  "7th",
  "8th",
  "9th",
  "10th",
  "11th",
  "12th",
  "13th",
  "14th",
  "15th",
  "16th",
  "17th",
  "18th",
  "19th",
  "20th",
  "21st",
  "22nd",
  "23rd",
  "24th",
  "25th",
  "26th",
  "27th",
  "28th",
  "29th",
  "30th",
  "31st",
];
function compareNumberIndex(a, b) {
  const [firstId, firstObj] = a;
  const [secondId, secondObj] = b;
  let firstIndex = firstObj.exceptionNumber;
  let secondIndex = secondObj.exceptionNumber;

  return firstIndex - secondIndex;
}

export default function TimeCatchAllList() {
  const { settings, dispatch, changes, setChanges } = useSettingsContext();
  
  const { currentUser, getUID } = useAuth();



  const [exceptions, setExceptions] = useState(
    settings[getUrlVariableValue("settingsId")].timeSettings.exceptions
  );

  const [modalException, setModalException] = useState(null);

  const handleAddException = (event) => {
    let id = uuidv4();

    let newRev = new RevertList(changes.getValues());
    newRev.addElement("timeException-" + id, "n/a", "added");
    setChanges(newRev);

    const defaultTimeException = {
      utility: "Add",
      staff: [],
      studio: [],
      hours: "number~",
      rate: "dollar~",
      total: "dollar~",
      comment: "text~",
      daySequence: "ANY DATE",
      day: "15th",
      label: "Default",
      id: id,
      exceptionNumber:
        Object.entries(
          settings[getUrlVariableValue("settingsId")].timeSettings.exceptions
        ).length + 1,
    };

    // setExceptions({ ...exceptions, [id]: defaultTimeException });

    // setStudioSettings({
    //   ...studioSettings,
    //   timeSettings: {
    //     ...studioSettings.timeSettings,
    //     exceptions: {
    //       ...studioSettings.timeSettings.exceptions,
    //       [id]: defaultTimeException,
    //     },
    //   },
    // });
    dispatch({
      type: "UPDATE_SETTINGS_PROPERTY",
      settingsId: getUrlVariableValue("settingsId"),
      uid: getUID(),
      path: `${getUrlVariableValue("settingsId")}.timeSettings.exceptions.${id}`,
      value: defaultTimeException,
    });

    // setModalException(id);
  };

  const handleDeleteException = (id) => {
    
    let newRev = new RevertList(changes.getValues());
    newRev.addElement("timeException-" + id, "n/a", "delete");
    setChanges(newRev);

    let exceptionList = {
      ...settings[getUrlVariableValue("settingsId")].timeSettings.exceptions,
    };

    
    let exceptionNumber = settings[getUrlVariableValue("settingsId")].timeSettings.exceptions[id].exceptionNumber;

    Object.keys(settings[getUrlVariableValue("settingsId")].timeSettings.exceptions).forEach(function(key) {
      settings[getUrlVariableValue("settingsId")].timeSettings.exceptions[key].exceptionNumber > exceptionNumber
        ? settings[getUrlVariableValue("settingsId")].timeSettings.exceptions[key].exceptionNumber--
        : settings[getUrlVariableValue("settingsId")].timeSettings.exceptions[key].exceptionNumber;
    });

    delete exceptionList[id];

    // setExceptions(exceptionList);

    // setStudioSettings({
    //   ...studioSettings,
    //   timeSettings: {
    //     ...studioSettings.timeSettings,
    //     exceptions: exceptionList,
    //   },
    // });
    dispatch({
      type: "UPDATE_SETTINGS_PROPERTY",
      settingsId: getUrlVariableValue("settingsId"),
      uid: getUID(),
      path: `${getUrlVariableValue("settingsId")}.timeSettings.exceptions`,
      value: exceptionList,
    });

    setModalException(null);
  };

  const handleEditException = (id) => {
    
    setModalException(id);
  };

  const handleModalClose = (id) => {
    setModalException(null);
  };

  const createLabel = (entry) => {

    let label = entry.utility + ": " + entry.label.replace("text~", "");

    if (entry.staff.length > 0) {
      let staff = entry.staff.join(", ");
      if(staff.length > 30){
        staff = staff.substring(0, 30) + "...";
      }
      label += " & " + staff;
    }
    if (entry.studio.length > 0) {
      let studios = entry.studio.join(", ");
      if(studios.length > 30){
        studios = studios.substring(0, 30) + "...";
      }
      label += " & " + studios;
    }

    return label;
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 700,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
    minWidth: 800,
    minHeight: 370,
  };

  return (
    <Box className="settings-page">
      <Stack
        sx={{ width: 1 }}
        spacing={1}
        key={"exception-type-stack"}
        direction="column"
      >
        {Object.entries(settings[getUrlVariableValue("settingsId")].timeSettings.exceptions)
          .sort(compareNumberIndex)
          .map(([key, entry], index) => (
            <Card key={"exception" + entry.id} sx={{ maxWidth: 700 }}>
              <CardHeader
                key={"exception-header" + entry.id}
                avatar={
                  <Avatar
                    key={"exception-avatar" + entry.id}
                    sx={{ bgcolor: "#1976d2" }}
                  >
                    {entry.exceptionNumber}
                  </Avatar>
                }
                action={
                  <BasicModal
                    handleOnClose={handleModalClose}
                    openIcon={
                      <IconButton
                        aria-label="settings"
                        key={"exception-type-edit" + index}
                        onClick={() => {
                          
                          handleEditException(entry.id);
                        }}
                        size="large"
                      >
                        <EditRoundedIcon sx={{ color: "#1976d2" }} />
                      </IconButton>
                    }
                  >
                    <TimeCatchAllBox
                      key={"time-exception" + modalException}
                      value={modalException}
                    ></TimeCatchAllBox>
                    <Divider className="settings__divider" />
                    <Button
                      sx={{ color: "red" }}
                      variant="outlined"
                      startIcon={<DeleteIcon sx={{ color: "red" }} />}
                      key={"class-type-delete" + modalException}
                      onClick={() => {
                        handleDeleteException(modalException);
                      }}
                    >
                      Delete
                    </Button>
                  </BasicModal>

                  // <Box spacing={2}>
                  //   <IconButton
                  //     aria-label="settings"
                  //     key={"exception-type-edit" + index}
                  //     onClick={() => {
                  //       handleEditException(entry.id);
                  //     }}
                  //   >
                  //     <EditRoundedIcon sx={{ color: "#1976d2" }} />
                  //   </IconButton
                  // </Box>
                }
                title={
                  <Typography
                    gutterBottom
                    key={"exception-title" + entry.id}
                    component="div"
                  >
                    {createLabel(entry)}
                  </Typography>
                }
                // subheader={getSubheader(entry.columnType)}
              />
            </Card>
          ))}
      </Stack>

      <Button onClick={handleAddException} variant="contained">
      {"Add " + settings[getUrlVariableValue("settingsId")].generalSettings.timeName + " Exception"}
      </Button>
    </Box>
  );
}

function TimeCatchAllBox(props) {
  const { settings, dispatch, changes, setChanges } = useSettingsContext();
  
  const { currentUser, getUID } = useAuth();


  const [values, setValues] = useState(
    settings[getUrlVariableValue("settingsId")].timeSettings.exceptions[props.value]
  );

  const studioOptions = [
    ...settings[getUrlVariableValue("settingsId")].generalSettings.studios,
  ];
  const staffOptions = [
    ...Object.entries(settings.staff.staff).map((s) => s[1].name),
  ];

  const handleChange = (value, id) => {
    setValues({
      ...values,
      [id]: value,
    });

    let newRev = new RevertList(changes.getValues());
    newRev.addElement(
      values.id + "-" + id,
      settings[getUrlVariableValue("settingsId")].timeSettings.exceptions[values.id][id],
      value
    );
    setChanges(newRev);

    // setStudioSettings({
    //   ...studioSettings,
    //   timeSettings: {
    //     ...studioSettings.timeSettings,
    //     exceptions: {
    //       ...studioSettings.timeSettings.exceptions,
    //       [values.id]: {
    //         ...studioSettings.timeSettings.exceptions[values.id],
    //         [id]: value,
    //       },
    //     },
    //   },
    // });
    dispatch({
      type: "UPDATE_SETTINGS_PROPERTY",
      settingsId: getUrlVariableValue("settingsId"),
      uid: getUID(),
      path: `${getUrlVariableValue("settingsId")}.timeSettings.exceptions.${
        values.id
      }.${id}`,
      value: value,
    });
  };

  return (
    <Box className="catch-all">
      <SelectorBox
        className="catch-all__selector-box"
        id="utility"
        onChange={handleChange}
        default={
          settings[getUrlVariableValue("settingsId")].timeSettings.exceptions[props.value]
            .utility
        }
        size={120}
        options={["Add", "Remove"]}
      ></SelectorBox>
      <Typography className="catch-all__text ">
        time entries with label
      </Typography>
      <SingleAttributeListBox
        multiple={false}
        freeSolo
        onChange={handleChange}
        id="label"
        default={values.label}
        options={timeLabelExamples}
        size={200}
        placeholder="Search for Label"
        // label="Label"
      ></SingleAttributeListBox>

      <Typography className="catch-all__text ">, for</Typography>
      <MultipleSelectPlaceholder
        className="catch-all__selector-box"
        id="staff"
        onChange={handleChange}
        label="Staff"
        default={values.staff}
        size={200}
        options={staffOptions.sort()}
        placeholder="ALL STAFF"
        name="Staff"
      ></MultipleSelectPlaceholder>
      {/* <SelectorBox
            id="staff"
            onChange={handleChange}
            label="Staff"
            default={values.staff}
            size={200}
            options={staffOptions}
            name="Staff"
          ></SelectorBox> */}
      <Typography className="catch-all__text ">, at</Typography>
      <MultipleSelectPlaceholder
        className="catch-all__selector-box"
        id="studio"
        onChange={handleChange}
        label="Studio"
        default={values.studio}
        options={["*Studios Worked At*"].concat(studioOptions.sort())}
        size={200}
        name="Studio"
        placeholder="ALL STUDIOS"
      ></MultipleSelectPlaceholder>
      <Typography className="catch-all__text ">
        , with hours displayed as
      </Typography>
      <InputBoxElement
        className="catch-all__input-box"
        id="hours"
        onChange={handleChange}
        default={values.hours}
        types={["number"]}
        size={200}
        label="Hours"
      ></InputBoxElement>

      <Typography className="catch-all__text ">, rate displayed as</Typography>
      <InputBoxElement
        className="catch-all__input-box"
        id="rate"
        onChange={handleChange}
        default={values.rate}
        //types={["dollar", "text"]}
        types={["dollar"]}
        size={200}
        label="Rate"
      ></InputBoxElement>

      <Typography className="catch-all__text ">
        , and total displayed as
      </Typography>
      <InputBoxElement
        className="catch-all__input-box"
        id="total"
        onChange={handleChange}
        default={values.total}
        types={["dollar"]}
        size={600}
        label="Total"
      ></InputBoxElement>

      <Typography className="catch-all__text ">, with a comment of </Typography>
      <InputBoxElement
        className="catch-all__input-box"
        id="comment"
        onChange={handleChange}
        default={values.comment}
        types={["text"]}
        size={200}
        label="Total"
      ></InputBoxElement>

      <Typography className="catch-all__text ">
        IF, the current day is
      </Typography>
      <SelectorBox
        className="catch-all__selector-box"
        id="daySequence"
        onChange={handleChange}
        label="Sequence"
        default={values.daySequence}
        options={["ANY DATE", "After", "Before"]}
        size={200}
        name="Sequence"
      ></SelectorBox>
      {values.daySequence !== "ANY DATE" ? (
        <Stack
          direction="row"
          justifyContent="flex-start"
          alignItems="flex-end"
        >
          <Typography className="catch-all__text ">the </Typography>
          {/* <InputBoxElement
                id="day"
                onChange={handleChange}
                default={values.day}
                types={["number"]}
                size={200}
                label="Day"
              ></InputBoxElement> */}
          <SelectorBox
            className="catch-all__selector-box"
            id="day"
            onChange={handleChange}
            label="Sequence"
            default={values.day}
            options={monthDays}
            size={125}
            name="Day"
          ></SelectorBox>
          <Typography className="catch-all__text ">of the month</Typography>
        </Stack>
      ) : null}
    </Box>
  );
}
