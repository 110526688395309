import { readExcelSheet, findFormulaArrays } from "./ReadExcelSheets";
import XLSX from "@sheet/core"
// import XLSX from "xlsx";  // temporary until amplify private sheet/core is figured out


export async function createStaffEmailArrays(file, staff) {
  const workbook = await readExcelSheet(file);

  let emailsObject = [];

  workbook.SheetNames.forEach((sheetName) => {
    // Get the sheet object
    try {
      const worksheet = workbook.Sheets[sheetName];
      const email = getEmail(sheetName, staff);
      if (email === null || email.length === 0 || !email.includes("@")) {
        return;
      }

      const range = XLSX.utils.decode_range(worksheet["!ref"]);

      const dataArray = [];

      for (let R = range.s.r; R <= range.e.r; R++) {
        const row = [];
        for (let C = range.s.c; C <= range.e.c; C++) {
          const cellAddress = { c: C, r: R };
          const cellRef = XLSX.utils.encode_cell(cellAddress);
          let cellValue = worksheet[cellRef] ? worksheet[cellRef].v : "";

          const regex = /\d+\.\d{3,}/;
          if (regex.test(cellValue)) {
            cellValue = cellValue.toFixed(2);
          }
          // if (!isNaN(cellValue) && cellValue.length > 0) {
          //   cellValue = cellValue.toFixed(2);
          // }
          row.push(cellValue);
        }
        dataArray.push(row);
      }

      emailsObject.push({
        address: email,
        content: dataArray,
      });
    } catch (e) {
      console.warn("error on - " + sheetName);
      return;
    }
  });

  return emailsObject;
}

function getEmail(sheetname, staffArray) {

  for (let i = 0; i < staffArray.length; i++) {
    if (staffArray[i].isNamed(sheetname)) {
      return staffArray[i].getProperty("Any", "email");
    }
  }

  return null;
}

function matchEmailWithStaffTab(tabArrays, studiosInformation) {
  let emailsObject = [];
  const staffArray = studiosInformation.staffArray;

  for (let i = 0; i < tabArrays.length; i++) {
    for (let j = 0; j < staffArray.length; j++) {
      try {
        if (typeof tabArrays[i][4].toString() === "string") {
          let staffName = tabArrays[i][4].toString().replace("'", "");
          if (staffName.includes("/")) {
            staffName = staffName.substring(0, staffName.indexOf("/"));
          }
          if (staffArray[j].isNamed(staffName)) {
            if (
              staffArray[j].getProperty("Any", "email").length > 0 &&
              staffArray[j].getProperty("Any", "email").includes("@")
            ) {
              emailsObject.push({
                address: staffArray[j].email,
                content: tabArrays[i],
              });
            }
          }
        }
      } catch (e) {}
    }
  }
  return emailsObject;
}
