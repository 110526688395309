import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import LooksOneRoundedIcon from "@mui/icons-material/LooksOneRounded";
import LooksTwoRoundedIcon from "@mui/icons-material/LooksTwoRounded";
import Looks3RoundedIcon from "@mui/icons-material/Looks3Rounded";
import useMediaQuery from "@mui/material/useMediaQuery";
import image from "../../img/bridge_illustration_1080x1080.png";
import imageMobile from "../../img/bridge_illustration_1920x1080-04.png";
import DocumentImage from "../../img/DocumentGraphic.svg";
import MultiStudioImage from "../../img/MultiStudio.png";
import MultiStudioAnimation from "../../gifs/MultiStudioSlower.gif";
import DocumentSearchImage from "../../img/DocumentSearch.png";
import DocumentSearchAnimation from "../../gifs/DocumentSearch.gif";
import PlugAnimation from "../../gifs/Plug.gif";
import ResponsiveAppBar from "../AppBar/ResponsiveAppBar";
import BasicModal from "../Modal/Modal";
import PlayArrowRoundedIcon from "@mui/icons-material/PlayArrowRounded";
import "./homepage.scss";
import FooterLinkList from "../Footer/FooterLinks";

const Animation = (props) => {
  const matches = useMediaQuery("(min-width:768px)");
  return (
    <Box>
      <img
        className="featured-row__image-gif"
        src={matches ? props.gif : props.gif}
        alt="PayWell video"
      />
    </Box>
  );
};
export const Timeline = (props) => {
  const matches = useMediaQuery("(min-width:900px)");

  return (
    <Box
      style={{
        paddingTop: "56px",
        maxWidth: "1038px",
        width: "100%",
      }}
    >
      <Box
        style={
          matches
            ? {
                width: "100%",
                display: "flex",
                justifyContent: "space-around",
              }
            : {
                display: "flex",
                flexDirection: "column",
                boxAlign: "center",
                alignItems: "center",
              }
        }
      >
        {props.children}
      </Box>
    </Box>
  );
};
export const Step = function (props) {
  const matches = useMediaQuery("(min-width:768px)");

  return (
    <Box
      style={
        matches
          ? { height: "100%", width: "auto" }
          : { height: "100%", width: "auto", paddingBottom: "72px" }
      }
    >
      <Box
        style={{
          display: "flex",
          width: "auto",
        }}
      >
        <Box
          style={{
            display: "flex",
            boxAlign: "center",
            alignItems: "center",
            flexDirection: "column",
            width: "24px",
          }}
        >
          {props.stepIcon}
          <div
            style={{
              width: "1px",
              height: "205px",
              background: "rgb(242, 242, 242)",
            }}
          ></div>
        </Box>
        <Box
          component={"div"}
          style={{
            display: "flex",
            flexDirection: "column",
            marginLeft: "16px",
            width: "auto",
          }}
        >
          <Typography
            component={"span"}
            variant="h6"
            style={{
              fontSize: "1.5rem",
              lineHeight: "1.33333",
              fontWeight: "900",
              letterSpacing: "-0.01em",
              textAlign: "left",
              width: "150px",
            }}
          >
            {props.stepHeader}
          </Typography>
          <Typography
            component={"span"}
            variant="subtitle1"
            style={{
              letterSpacing: "-0.01em",
              textAlign: "left",
              fontSize: "1.125rem",
              lineHeight: "2",
              fontWeight: "400",
              marginTop: "24px",
              width: "230px",
            }}
          >
            {props.stepDescription}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};
export const Page = function (props) {
  return (
    <Box className="homepage-page-container">
      <Box
        className={`homepage-page ${
          props.noBottomPadding ? "homepage-page--no-bottom-padding" : ""
        }`}
      >
        {props.children}
      </Box>
    </Box>
  );
};

export const FeatureList = function (props) {
  return <Box>{props.children}</Box>;
};
export const FeatureRow = function (props) {
  const matches = useMediaQuery("(min-width:900px)");

  return (
    <Box
      style={
        matches
          ? {
              width: "100%",
              display: "flex",
              alignItems: props.alignItems || "flex-start",
              boxPack: "justify",
              paddingBottom: props.paddingBottom ?? "80px",

              justifyContent: "space-between",
              flexDirection: props.flexDirection
                ? props.flexDirection
                : "unset",
            }
          : {
              width: "100%",
              display: "flex",
              alignItems: props.alignItems || "flex-start",
              boxPack: "justify",
              justifyContent: "space-between",

              paddingBottom: props.paddingBottom ?? "66.6667px",
              flexWrap: "wrap",
              flexDirection: "column-reverse",
            }
      }
    >
      <Box
        style={
          matches
            ? {
                width: "100%",
                display: "flex",
                flexDirection: "column",
                marginTop: "32px",
                minWidth: "435px",
                maxWidth: "47%",
              }
            : {
                display: "flex",
                flexDirection: "column",
                marginTop: "32px",

                width: "100%",
                minWidth: "auto",
                maxWidth: "100%",
                flexBasis: "auto",
              }
        }
      >
        <Typography
          component={"span"}
          variant="h3"
          style={
            matches
              ? {
                  fontWeight: "900",
                  letterSpacing: "-0.01em",
                  textAlign: "left",
                  color: "rgb(0, 0, 0)",
                  fontSize: "2.36875rem",
                  lineHeight: "1.2",
                  hyphens: "initial",
                  marginBottom: "32px",
                }
              : {
                  fontWeight: "900",
                  letterSpacing: "-0.01em",
                  textAlign: "left",
                  color: "rgb(0, 0, 0)",
                  fontSize: "2.36875rem",
                  lineHeight: "1.2",
                  hyphens: "initial",

                  marginBottom: "26.6667px",
                }
          }
        >
          {props.featureHeader}
        </Typography>
        <Typography
          component={"span"}
          variant="subtitle1"
          style={{
            letterSpacing: "-0.01em",
            textAlign: "left",
            color: "rgb(0, 0, 0)",
            fontSize: "1.125rem",
            lineHeight: "2",
            fontWeight: "400",
            hyphens: "initial",
          }}
        >
          {props.featureDescription}
        </Typography>
      </Box>

      <Box
        style={
          matches
            ? {
                width: "100%",
                maxWidth: "47%",
                height: "auto",

                position: "relative",
              }
            : {
                position: "relative",
                height: "auto",

                width: "100%",
                minWidth: "auto",
                maxWidth: "100%",
                flexBasis: "auto",
              }
        }
      >
        {/* Change to <img></img>> and remove height attribute*/}
        <div
          style={
            matches
              ? {
                  height: "auto",
                  display: "flex",
                  width: "100%",
                }
              : {
                  width: "100%",
                  height: "100%",
                  transform: "translate3d(0px, 0px, 0px)",
                  contentVisibility: "visible",
                  display: "flex",
                  justifyContent: "center",
                }
          }
        >
          {props.image ? (
            <img
              style={props.imageStyle || null}
              className="featured-row__image"
              src={matches ? props.image : props.image}
              alt="PayWell video"
            />
          ) : (
            props.gif
          )}
        </div>
      </Box>
    </Box>
  );
};
const VideoModal = (props) => {
  const matches = useMediaQuery("(min-width:900px)");

  const style = {
    position: "absolute",
  };
  const buttonStyles = {
    borderRadius: "999px",
    height: "64px",
    width: "64px",
    backgroundColor: "white",
    boxShadow: "rgb(0 0 0 / 41%) 0px 1px 5px 0px",
    "&:hover": {
      backgroundColor: "rgb(0 107 255)",
      color: "white",
    },
    "&:active": {
      boxShadow: "none",
      backgroundColor: "rgb(0 107 255)",
      color: "white",
    },
  };
  return (
    <Box className="homepage-modal" style={style}>
      <BasicModal
        isVideo={true}
        buttonStyles={buttonStyles}
        buttonContent={<PlayArrowRoundedIcon style={{ fontSize: "24px" }} />}
        openIcon={
          <PlayArrowRoundedIcon
            style={{ fontSize: "100px", color: "#006bff" }}
          />
        }
      >
        <iframe
          width="1020"
          height="630"
          src="https://www.youtube.com/embed/POl7JVbvXHU?autoplay=1"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen"
          allowfullscreen
          style={{
            width: "90vw",
            minWidth: "10rem",
            maxWidth: "100%",
            height: "90vh",
            minHeight: "40em",
          }}
        ></iframe>
      </BasicModal>
    </Box>
  );
};

export default function HomePage(props) {
  const matches = useMediaQuery("(min-width:900px)");
  return (
    <>
      <ResponsiveAppBar></ResponsiveAppBar>
      <Box
        style={
          matches
            ? {
                width: "100%",
                height: "104px",
              }
            : {
                width: "100%",
                height: "80px",
              }
        }
      ></Box>
      <Page>
        <Box
          style={{
            width: "100%",
            display: "flex",
            boxAlign: "center",
            alignItems: "center",
            flexDirection: matches ? "row" : "column-reverse",
          }}
        >
          <Box
            style={
              matches
                ? {
                    display: "flex",
                    alignItems: "flex-start",
                    flexDirection: "column",
                    marginRight: "auto",
                    width: "auto",
                    minWidth: "450px",
                  }
                : {
                    display: "flex",
                    alignItems: "flex-start",
                    flexDirection: "column",
                    marginRight: "48px",
                    width: "auto",
                    minWidth: "450px",

                    width: "100%",
                    minWidth: "auto",
                    maxWidth: "100%",
                    flexBasis: "auto",
                    marginRight: "0px",
                    marginLeft: "0px",
                    paddingLeft: "0px",
                    paddingRight: "0px",
                  }
            }
          >
            <Typography
              component={"span"}
              style={{
                fontSize: "4rem",
                lineHeight: "1",
                fontWeight: "900",
                letterSpacing: "-0.01em",
                textAlign: "left",
                color: "rgb(0, 0, 0)",
                maxWidth: "450px",
              }}
              variant="h2"
            >
              What would you do with more{" "}
              <span style={{ color: "#006bff" }}>time?</span>
            </Typography>
            <Typography
              component={"span"}
              style={{
                letterSpacing: "-0.01em",
                textAlign: "left",
                color: "rgb(0, 0, 0)",
                fontSize: "1.125rem",
                lineHeight: "2",
                fontWeight: "400",
                paddingTop: "48px",
                paddingBottom: "32px",
                maxWidth: "450px",
              }}
              variant="subtitle1"
            >
              PayWell bridges the gap between your Gym Management System and your Payroll
              Processor, allowing you to spend your time how you choose.
            </Typography>
            <Button
              style={{
                position: "relative",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                backgroundColor: "#006bff",
                minHeight: "66px",
                borderRadius: "40px",
              }}
              variant="contained"
              href="contact"
            >
              Schedule a Demo
            </Button>
          </Box>
          <Box
            style={
              matches
                ? {
                    width: "100%",
                    height: "100%",
                    maxWidth: "fit-content",
                    display: "flex",
                    justifyContent: "flex-end",
                    paddingBottom: "24px",
                    alignItems: "center",
                    position: "relative",

                    justifyContent: "center",
                    right: "-106px",
                  }
                : {
                    width: "100%",
                    height: "100%",
                    maxWidth: "fit-content",
                    display: "flex",
                    paddingBottom: "24px",
                    position: "relative",
                    alignItems: "center",
                    justifyContent: "center",
                  }
            }
          >
            <VideoModal />

            <img
              src={matches ? image : imageMobile}
              style={
                matches
                  ? {
                      width: "72%",
                      borderRadius: "337px",
                    }
                  : {
                      width: "110%",
                      borderRadius: "0px",
                    }
              }
              alt="PayWell video"
            />
          </Box>
        </Box>
      </Page>
      <Page>
        <Timeline>
          <Step
            stepHeader="Run"
            stepDescription="Capture your payroll data."
            stepIcon={
              <LooksOneRoundedIcon
                style={{ color: "#006bff" }}
              ></LooksOneRoundedIcon>
            }
          ></Step>
          <Step
            stepHeader="Review"
            stepDescription="Download and review your PayWell output file."
            stepIcon={
              <LooksTwoRoundedIcon
                style={{ color: "#006bff" }}
              ></LooksTwoRoundedIcon>
            }
          ></Step>
          <Step
            stepHeader="Share"
            stepDescription="Automatically email your staff a preview of their pay stubs (if desired)."
            stepIcon={
              <Looks3RoundedIcon
                style={{ color: "#006bff" }}
              ></Looks3RoundedIcon>
            }
          ></Step>
        </Timeline>
      </Page>
      <Page>
        <Box
          style={{
            width: "100%",
            display: "flex",
            boxAlign: "center",
            alignItems: "center",
            boxPack: "center",
            justifyContent: "center",
            flexDirection: "column",
            paddingBottom: "80px",
          }}
        >
          <Typography
            component={"span"}
            variant="h3"
            style={{
              fontSize: "3.25rem",
              lineHeight: "1.25",
              fontWeight: "900",
              letterSpacing: "-0.01em",
              textAlign: "center",
              color: "rgb(0, 0, 0)",
              paddingBottom: "48px",
              maxWidth: "675px",
            }}
          >
            Do more of what you do best
          </Typography>
          <Typography
            component={"span"}
            variant={"h5"}
            style={{
              width: "100%",
              textAlign: "center",
              maxWidth: "675px",
              height: "auto",
              fonSize: "1.5rem",
              lineHeight: "1.33333",
              fontWeight: "400",
            }}
          >
            PayWell automates tedious payroll excel tasks, clearing your day for
            greatness.
          </Typography>
        </Box>
      </Page>
      <Page noBottomPadding={true}>
        <FeatureList>
          <FeatureRow
            featureHeader="Comprehensive Output"
            featureDescription="Our excel output encapsulates time clock hours, sales commission and class bonuses, all in one. PayWell provides detailed pay information broken out for each staff member."
            image={DocumentImage}
          ></FeatureRow>
          <FeatureRow
            featureHeader="Multi-Studio Capabilities"
            featureDescription="We accommodate payroll for all your studios in a single file. No more struggling paying staff who work across studios."
            gif={
              <Animation
                gif={MultiStudioAnimation}
                image={MultiStudioImage}
              ></Animation>
            }
            flexDirection="row-reverse"
          ></FeatureRow>
          <FeatureRow
            featureHeader="Accommodation of Complex Pay Structures"
            featureDescription="Customize rules based on class sizes, instructors, day/times and much more. PayWell also automates the tedious processes when calculating sales commission."
            gif={
              <Animation
                gif={DocumentSearchAnimation}
                image={DocumentSearchImage}
              ></Animation>
            }
          ></FeatureRow>
          {/* <FeatureRow
            paddingBottom="0px"
            featureHeader="ClubReady Connection"
            featureDescription="Update and maintain rates within PayWell instead of ClubReady. No more changing rates for each one of your instructors' classes. Additionally, PayWell automatically pulls the necessary ClubReady reports for you."
            flexDirection="row-reverse"
            gif={<Animation gif={PlugAnimation}></Animation>}
          ></FeatureRow> */}
        </FeatureList>
      </Page>

      {/* <ResponsiveFooter /> */}
      <FooterLinkList />
    </>
  );
}
