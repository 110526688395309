import { db } from "../firebase";

import {
  collection,
  getDocs,
  addDoc,
  updateDoc,
  deleteDoc,
  doc,
  where,
  query,
} from "firebase/firestore";
import { getEditDate } from "../pages/StudioSettings/CommonVariables.js";

async function getUserDoc(id, settingsId) {
  let foundDoc = null;
  const studioSettings =
    process.env.REACT_APP_STUDIO_SETTINGS_FIREBASE_COLLECTION;

  try {
    const querySnapshot = await getDocs(collection(db, studioSettings));
    querySnapshot.forEach((doc) => {
      let data = doc.data();
      if (data["uid"] === id && data["settingsId"] === parseInt(settingsId)) {
        foundDoc = doc;
      }
    });
  } catch (e) {
    return null;
  }

  return foundDoc;
}

export async function updateStudioSettings(data, id, settingsId) {
  
  const docSnap = await getUserDoc(id, settingsId);

  let lastEditData = { ...data, ["lastEdit"]: getEditDate() };

  if (docSnap !== null) {
    await updateDoc(
      doc(
        db,
        process.env.REACT_APP_STUDIO_SETTINGS_FIREBASE_COLLECTION,
        docSnap.id
      ),
      lastEditData
    );
    return true;
  } else {
    console.warn(
      "Failed to update studio settings no settings found to update with: " +
        id +
        settingsId
    );
    return false;
    // THIS CAUSING BS
    // await addDoc(
    //   collection(db, process.env.REACT_APP_STUDIO_SETTINGS_FIREBASE_COLLECTION),
    //   lastEditData
    // );
  }
}

export async function addStudioSettings(data, id, settingsId) {
  const docSnap = await getUserDoc(id, settingsId);

  let lastEditData = { ...data, ["lastEdit"]: getEditDate() };

  if (docSnap == null) {
    await addDoc(
      collection(db, process.env.REACT_APP_STUDIO_SETTINGS_FIREBASE_COLLECTION),
      lastEditData
    );
  }
}

export async function deleteStudioSettings(id, settingsId) {
  const querySnapshot = await getDocs(
    collection(db, process.env.REACT_APP_STUDIO_SETTINGS_FIREBASE_COLLECTION)
  );
  let res;
  querySnapshot.forEach(async (doc) => {
    let data = doc.data();
    if (data["uid"] === id && data["settingsId"] === parseInt(settingsId)) {
      res = await deleteDoc(doc.ref);
    }
  });
  return res;
}

export async function getUserSettings(uid) {
  var hasSettings = false;
  let convertedObjects = {};
  try {
    const studioQuery = await getDocs(
      collection(db, process.env.REACT_APP_STUDIO_SETTINGS_FIREBASE_COLLECTION)
    );

    for (let doc of studioQuery.docs) {
      let data = doc.data();
      if (data["uid"] === uid) {
        convertedObjects[data["settingsId"]] = data;
        hasSettings = true;
      }
    }

    const q = query(
      collection(db, process.env.REACT_APP_STAFF_SETTINGS_FIREBASE_COLLECTION),
      where("paywellUID", "==", uid)
    );

    let staffObject = {
      staff: {},
      lastEdit: "N/A",
      settingsId: 0,
      staffNumber: 0,
      uid: uid,
    };

    const querySnapshot = await getDocs(q);
    const staffArray = [];

    // for (let doc of querySnapshot.docs) {
    //   let staffData = doc.data();
    //   staffObject.staff[staffData.id] = staffData;
    // }
    for (let doc of querySnapshot.docs) {
      let staffData = doc.data();
      staffArray.push(staffData);
    }

    // Sort the array based on the 'name' field
    staffArray.sort((a, b) => a.name.localeCompare(b.name));

    // Convert the sorted array back into an object
    staffArray.forEach(staff => {
        staffObject.staff[staff.id] = staff;
    });

    // Optionally, update the number of staff
    staffObject.staffNumber = staffArray.length;

    convertedObjects["staff"] = staffObject;
  } catch (e) {
    // Check if the error message contains the specific Firestore error message
    if (e.message.includes("Could not reach Cloud Firestore backend")) {
      console.error("Firestore backend not reachable: ", e);
      // You can handle this error scenario specifically
      // For example, you might want to set a flag or show a user-friendly message
      return { error: "Firestore backend not reachable", settings: false };
    } else {
      // Handle other errors that might occur within the try block
      console.error("Failed getting user settings: ", e);
      alert("Failed getting user settings: " + e.message);
      return { error: e, settings: false };
    }
  }

  if (Object.keys(convertedObjects).length > 0 && hasSettings) {
    return { settings: convertedObjects };
  }
  return { settings: false };
}



export async function getAllStudioFromAllPresets(uid) {
  // Reference to the 'settings' collection
  const settingsRef = collection(db, 'settings');
  
  // Query to find settings documents where 'uid' matches the provided uid
  const q = query(settingsRef, where('uid', '==', uid));
  const querySnapshot = await getDocs(q);
  
  const userSettings = [];
  querySnapshot.forEach((doc) => {
      userSettings.push(doc.data());
  });

  let allStudios = [];

  // Collect all studios from the settings
  for (const setting of userSettings) {
      const studios = setting.generalSettings?.studios || [];
      allStudios = allStudios.concat(studios);
  }

  return allStudios;
}


export async function getSettingsIdsForUid(uid) {
  // Create a reference to the "settings" collection
  const settingsCollection = collection(db, "settings");

  // Create a query to get documents where "uid" is equal to the provided uid
  const q = query(settingsCollection, where("uid", "==", uid));

  try {
    const querySnapshot = await getDocs(q);

    // Initialize an array to store settingsIds
    const settingsIds = [];

    // Iterate through the documents and push "settingsId" to the array
    querySnapshot.forEach((doc) => {
      const settingsId = doc.data().settingsId;
      settingsIds.push(settingsId);
    });

    // Sort the array in ascending order
    settingsIds.sort((a, b) => a - b);

    return settingsIds;
  } catch (error) {
    console.error("Error getting settings:", error);
    throw error;
  }
}
