// AddStaffComponent.js
import React, { useState, useEffect } from "react";
import {
  Container,
  TextField,
  Grid,
  Typography,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  OutlinedInput,
  FormGroup,
  FormControlLabel,
  Switch,
  Card,
  CardContent,
  Autocomplete,
  Button,
  DialogActions,
  Input,
  Checkbox,
  ListItemText,
  Stack,
} from "@mui/material";
import { v4 as uuidv4 } from "uuid";
import { useSnackBar } from "../../contexts/SnackBarContext/SnackBarContext";
import { useStudioScrapeStatus } from "../../contexts/StudioScrapeStatus/StudioScrapeStatusContext";
import { useSettingsContext } from "../../contexts/SettingsContext";
import {
  deepClone,
  splitRateAndInfoDetails,
  getFirstObject,
  findStaffByName,
  findNewStaffObjects,
  makeFirstLetterLowercase,
} from "./utils"; // Adjust the path as necessary
import "./staffDialog.scss";
import { getUrlVariableValue } from "../../utility-functions/utility-functions";
import { createNewStaffObject } from "../../pages/StaffSettings/utils";
import { useAuth } from "../../contexts/AuthContext";
import { updateStaffMasterSettings } from "../../services/staff.services";
import { consolidateStaff, ensureUniqueIds } from "./utils";

import { useStaffContext } from "../../contexts/StaffContext/StaffContext";
import { LoadingButton } from "@mui/lab";
import NumericTextField from "../CustomTextFields/NumericTextField";


const InputComponent = React.memo(({ label, type, value, onChange }) => {
  return type === 'number' ? (
    <NumericTextField label={label} value={value} onChange={onChange} />
  ) : (
    <TextField
      label={label}
      type={type}
      fullWidth
      margin="normal"
      value={value || ""}
      onChange={onChange}
    />
  );
});

const GeneratedInputComponents = React.memo(
  ({ bucketName, bucketSetup, editableStaff, handleChange }) => {
    const ratePropertyName = makeFirstLetterLowercase(bucketName) + "Rate";

    return (
      <>
        {bucketSetup.rate.includeZero === "Paid A Different Amount" && (
          <InputComponent
            key={`${bucketName}-zero-attendee`}
            label="Zero Attendee"
            type="number"
            value={editableStaff[ratePropertyName]?.zero || ""}
            onChange={(e) =>
              handleChange(`${ratePropertyName}.zero`, e.target.value)
            }


          />
        )}

        {bucketSetup.rate.structure === "Flat Rate" && (
          <InputComponent
            key={`${bucketName}-flat-rate`}
            label={`${bucketSetup.rate.structure} (1-12 Attendees)`}
            type="number"
            value={editableStaff[ratePropertyName]?.flat || ""}
            onChange={(e) =>
              handleChange(`${ratePropertyName}.flat`, e.target.value)
            }
          />
        )}

        {bucketSetup.rate.structure === "Base Rate + Per Head Bonus" && (
          <>
            <InputComponent
              key={`${bucketName}-base-rate`}
              label="Base Rate"
              type="number"
              value={editableStaff[ratePropertyName]?.base || ""}
              onChange={(e) =>
                handleChange(`${ratePropertyName}.base`, e.target.value)
              }
            />
            <InputComponent
              key={`${bucketName}-per-head-rate`}
              label="Per Head Rate"
              type="number"
              value={editableStaff[ratePropertyName]?.perHead || ""}
              onChange={(e) =>
                handleChange(`${ratePropertyName}.perHead`, e.target.value)
              }
            />
            <InputComponent
              key={`${bucketName}-starting-after`}
              label="Starting After"
              type="number"
              value={editableStaff[ratePropertyName]?.afterAttendee || ""}
              onChange={(e) =>
                handleChange(
                  `${ratePropertyName}.afterAttendee`,
                  e.target.value
                )
              }
            />
          </>
        )}

        {bucketSetup.rate.structure === "Custom Amount" &&
          Array.from(
            { length: parseInt(bucketSetup.capacity.split("~")[1]) },
            (_, i) => (
              <InputComponent
                key={`${bucketName}-custom-amount-${i}`}
                label={`Custom Amount for ${i + 1} Attendees`}
                type="number"
                value={
                  editableStaff[ratePropertyName]?.customAmounts?.[i] || ""
                }
                onChange={(e) =>
                  handleChange(
                    `${ratePropertyName}.customAmounts[${i}]`,
                    e.target.value
                  )
                }
              />
            )
          )}
      </>
    );
  }
);

const DynamicRateInput = React.memo(
  ({ bucketName, bucketSetup, editableStaff, handleChange }) => {

    return (
      <>
        <Typography variant="h5" gutterBottom>
          {bucketName} Rates
        </Typography>
        <GeneratedInputComponents
          bucketName={bucketName}
          bucketSetup={bucketSetup}
          editableStaff={editableStaff}
          handleChange={handleChange}
        />
      </>
    );
  }
);

const DynamicRates = ({ settings, editableStaff, handleChange }) => {
  const classBuckets =
    settings[getUrlVariableValue("settingsId")].classSettings.classBuckets;

  return (
    <>
      {Object.entries(classBuckets).map(([bucketName, bucketSetup]) => (
        <DynamicRateInput
          key={bucketName}
          bucketName={bucketName}
          bucketSetup={bucketSetup}
          editableStaff={editableStaff}
          handleChange={handleChange}
        />
      ))}
    </>
  );
};
const AddStaffComponent = ({ staff, onSave, onClose }) => {
  /*** Staff Context ***/
  const { getUID } = useAuth();
  const { settings, dispatch, reportCompilerDispatch } = useSettingsContext(); // Assuming this hook/context is available
  const {
    editableStaff,
    updateEditableStaff,
    resetEditableStaff,
    staffContextDispatch,
    loading,
  } = useStaffContext();

  const handleChange = (path, value) => {
    // Check if path is a string indicating a nested property
    if (path.includes(".")) {
      const keys = path.split(".");
      let updatedStaff = { ...editableStaff }; // Copy the current state

      let current = updatedStaff;
      keys.forEach((key, index) => {
        if (index === keys.length - 1) {
          current[key] = value; // Set the new value at the target
        } else {
          current[key] = current[key] || {}; // Ensure nested objects exist
          current = current[key];
        }
      });


      const existingStaff = findStaffByName(settings.staff.staff, staff.name);
      updateEditableStaff(updatedStaff);
    } else {
      // Handle direct property updates
      const existingStaff = findStaffByName(settings.staff.staff, staff.name);

      updateEditableStaff({ ...editableStaff, [path]: value });
    }
  };

  const handleSubmit = async (e) => {

    e.preventDefault();

    staffContextDispatch({ type: "SET_LOADING", payload: true });

    dispatch({
      type: "ADD_STAFF_SETTINGS_STAFF_MEMBER",
      newStaffId: editableStaff.id,
      newStaff: editableStaff,
    });

    await handleSaveStaff(getUID(), editableStaff); // Update this call as per your implementation

    staffContextDispatch({ type: "ADD_STAFF", payload: editableStaff });
    resetEditableStaff(); // Use resetEditableStaff to clear the form
    staffContextDispatch({ type: "SET_LOADING", payload: false });

    onSave(editableStaff); // Assuming onSave is a callback to handle post-save actions
  };

  /*** End Staff Context ***/

  const showSnackBar = useSnackBar();
  const { currentlySelectedStudios } = useStudioScrapeStatus();

  const instructorTypes = settings[
    getUrlVariableValue("settingsId")
  ].generalSettings?.staffTypes
    ?.filter((staff) => staff.sessionPay === true)
    .map((staff) => staff.name);

  const allTypes = settings[
    getUrlVariableValue("settingsId")
  ].generalSettings?.staffTypes?.map((staff) => staff.name);

  const handleSaveStaff = async (uid, staff) => {
    const newStaffObjects = {};
    newStaffObjects[staff.id] = staff;
    const updateSuccessful = await updateStaffMasterSettings(
      uid,
      newStaffObjects
    );
    if (updateSuccessful) {
      showSnackBar(`Successfully added ${staff.name}`, "success");
      reportCompilerDispatch({
        type: "BUILD_REPORT_COMPILER_STATE_STAFF",
      });
    } else {
      showSnackBar(`Failed to add ${staff.name}`, "error");
    }
  };

  return (
    <Stack spacing={3} sx={{ margin: "16px  0" }}>
      {/* <Typography variant="h5" gutterBottom>
        Info
      </Typography> */}

      {/* Locations Selector */}
      <FormControl fullWidth>
        <InputLabel>Locations</InputLabel>
        <Select
          multiple
          disabled={staff.errorType == "unaddedLocation"}
          value={editableStaff.locations || []}
          onChange={(event) => handleChange("locations", event.target.value)}
          input={<OutlinedInput label="Locations" />}
          renderValue={(selected) => selected.join(", ")}
          MenuProps={{
            PaperProps: {
              style: {
                maxHeight: 224,
              },
            },
          }}
        >
          {currentlySelectedStudios.map((location) => (
            <MenuItem key={location} value={location}>
              <Checkbox
                checked={editableStaff.locations?.indexOf(location) > -1}
              />
              <ListItemText primary={location} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      {/* Home Location */}
      <FormControl fullWidth>
        <InputLabel>Home Location</InputLabel>
        <Select
          value={editableStaff.homeLocation || ""}
          onChange={(event) => handleChange("homeLocation", event.target.value)}
          input={<OutlinedInput label="Home Location" />}
        >
          {currentlySelectedStudios.map((location) => (
            <MenuItem key={location} value={location}>
              {location}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      {/* Name */}
      <TextField
        label="Name"
        fullWidth
        name="name"
        margin="dense"
        value={editableStaff.name || ""}
        onChange={(e) => handleChange("name", e.target.value)}
      />
      {/* idString */}
      <TextField
        label="idString"
        name="idString"
        fullWidth
        margin="dense"
        value={editableStaff.idString || ""}
        onChange={(e) => handleChange("idString", e.target.value)}
      />
      {/* secondIdString */}
      <TextField
        label="secondIdString"
        name="secondIdString"
        fullWidth
        margin="dense"
        value={editableStaff?.secondIdString || ""}
        onChange={(e) => handleChange("secondIdString", e.target.value)}
      />
      {/* Staff Type */}
      <FormControl fullWidth>
        <InputLabel id="staff-type-label">Staff Type</InputLabel>
        <Select
          labelId="staff-type-label"
          label="Staff Type"
          name="type"
          defaultValue={editableStaff.type || ""}
          value={editableStaff.type || ""}
          onChange={(e) => handleChange("type", e.target.value)}
          input={<OutlinedInput label="Staff Type" />}
        >
          {allTypes.map((type) => (
            <MenuItem key={type} value={type}>
              {type}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {/* Email */}
      <TextField
        label="Email"
        name="email"
        fullWidth
        margin="dense"
        value={editableStaff.email || ""}
        onChange={(e) => handleChange("email", e.target.value)}
      />
      {/* Titles */}
      <Autocomplete
        multiple
        freeSolo
        options={[]}
        getOptionLabel={(option) => option}
        value={editableStaff.titles || []}
        onChange={(event, newValue) => {
          handleChange("titles", newValue);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            label="Titles"
            placeholder="Select Titles"
          />
        )}
      />
      {/* Alternate Names */}
      <Autocomplete
        multiple
        freeSolo
        options={[]}
        value={editableStaff.alternateNames || []}
        onChange={(event, newValue) => {
          handleChange("alternateNames", newValue);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            label="Alternate Names"
            placeholder="Add Alternate Names"
          />
        )}
      />

      {/* Staff Rates Section */}

      <Typography variant="h5" gutterBottom>
        Hourly Rates
      </Typography>

      {/* Static Hourly Rate */}
      <NumericTextField
        label="Primary Hourly Rate"
        value={editableStaff.hourly || ""}
        onChange={(e) => handleChange("hourly", e.target.value)}
      />




      <NumericTextField
        label="Secondary Hourly Rate"
        value={editableStaff.secondaryHourly || ""}
        onChange={(e) => handleChange("secondaryHourly", e.target.value)}
      />

      {/* Dynamic Rates - Assuming you have a component to handle these */}
      <DynamicRates
        settings={settings}
        editableStaff={editableStaff}
        handleChange={handleChange}
      />

      {/* Static Sales Commission */}
      <Typography variant="h5" gutterBottom>
        Commission Rates
      </Typography>

      {/* Retail Commission Rate */}
      <TextField
        label="Retail Commission Rate"
        type="text"
        fullWidth
        margin="dense"
        value={editableStaff.commissionRate?.retail || ""}
        onChange={(e) => handleChange("commissionRate.retail", e.target.value)}
      />
      {/* Agreement Commission Rate */}
      <TextField
        label="Agreement Commission Rate"
        type="text"
        fullWidth
        margin="dense"
        value={editableStaff.commissionRate?.agreement || ""}
        onChange={(e) =>
          handleChange("commissionRate.agreement", e.target.value)
        }
      />

      <DialogActions className="add-staff-component__actions">
        <Button onClick={() => onClose(staff)}>Cancel</Button>

        <LoadingButton
          loading={loading}
          variant="contained"
          onClick={handleSubmit}
        >
          Confirm
        </LoadingButton>
      </DialogActions>
    </Stack>
  );
};

export default AddStaffComponent;
