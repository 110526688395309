import React, { useEffect, useState } from 'react';
import { Button, Box, Typography, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { useAuth } from '../../../contexts/AuthContext';
import { getAllUsers } from "../OutputTest";
import { API } from 'aws-amplify';
import { getFirebaseJwtToken } from '../../../utility-functions/tokenUtility';
import { useHistory } from 'react-router-dom';
import { useAdminImpersonationBanner } from '../../../contexts/Admin/AdminImpersonationBannerContext';

const AdminUserImpersonation = () => {
  const { loginWithCustomToken, logout, auth } = useAuth();
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedUser, setSelectedUser] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const { showAdminImpersonationBanner, hideAdminImpersonationBanner } = useAdminImpersonationBanner();
  const history = useHistory();

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const users = await getAllUsers();
        const activeUsers = users.users.filter((user) => !user.disabled);
        activeUsers.sort((a, b) => a.email.localeCompare(b.email));
        setUsers(activeUsers);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching users:', error);
        setLoading(false);
      }
    };

    fetchUsers();
  }, []);

  const handleImpersonateUser = async () => {
    const adminJwtToken = await getFirebaseJwtToken();

    try {
      const response = await API.post('paywellAPIResource', '/AdminImpersonate', {
        body: {
          userId: selectedUser.uid,
        },
        headers: {
          Authorization: `Bearer ${adminJwtToken}`,
        },
      });
      const { token } = response;

      // Redirect to the impersonation login route with the token as a URL parameter
      history.push(`/impersonation-login?token=${token}`);
    } catch (error) {
      console.error('Error impersonating user:', error);
      alert('Failed to impersonate user.');
    }
    setOpenDialog(false);
  };

  const handleUserSelect = (event) => {
    const userId = event.target.value;
    const user = users.find((user) => user.uid === userId);
    setSelectedUser(user);
  };

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  if (loading) {
    return <Typography>Loading users...</Typography>;
  }

  return (
    <Box>
      <Typography variant="h4">Admin User Impersonation</Typography>
      <select onChange={handleUserSelect} value={selectedUser?.uid || ''}>
        <option value="" disabled>Select a user</option>
        {users.map((user) => (
          <option key={user.uid} value={user.uid}>
            {`${user.email} - ${user.uid}`}
          </option>
        ))}
      </select>
      {selectedUser && (
        <Box mt={2}>
          <Typography variant="h6">Selected User:</Typography>
          <Typography>Email: {selectedUser.email}</Typography>
          <Typography>UID: {selectedUser.uid}</Typography>
          <Typography>Admin: {selectedUser.customClaims?.admin ? 'Yes' : 'No'}</Typography>
        </Box>
      )}
      <Button onClick={handleOpenDialog} disabled={!selectedUser} variant="contained" color="primary" mt={2}>
        Impersonate User
      </Button>

      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle component='h3'>Confirm Impersonation</DialogTitle>
        <DialogContent>
          <DialogContentText>
            This will log you in as if you were the following user:
          </DialogContentText>
          {selectedUser && (
            <>
              <Typography>Email: {selectedUser.email}</Typography>
              <Typography>UID: {selectedUser.uid}</Typography>
              <Typography>Admin: {selectedUser.customClaims?.admin ? 'Yes' : 'No'}</Typography>
            </>
          )}
          <DialogContentText>
            Are you sure you want to proceed?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="error">
            Cancel
          </Button>
          <Button onClick={handleImpersonateUser} color="primary" autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default AdminUserImpersonation;
