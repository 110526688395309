function valuesAreSame(prevValue, newValue) {
  if (Array.isArray(prevValue) && Array.isArray(newValue)) {
    if (prevValue.length === newValue.length) {
      for (let i = 0; i < prevValue.length; i++) {
        if (prevValue[i] !== newValue[i]) {
          return false;
        }
      }
      return true;
    }
    return false;
  } else if (typeof prevValue === "string" && typeof newValue === "string") {
    return prevValue === newValue;
  }
  return false;
}

export class RevertList {
  constructor(originalValues) {
    this.values = originalValues;
  }

  addElement(id, previousValue, newValue) {
    if (valuesAreSame(previousValue, newValue)) {
      return;
    }
    let newObject = { id: id, previous: previousValue, new: newValue };

    let oldIndex = -1;
    for (let i = 0; i < this.values.length; i++) {
      if (this.values[i].id === id) {
        oldIndex = i;
      }
    }
    if (oldIndex === -1) {
      this.values.push(newObject);
    } else {
      let oldValue = this.values[oldIndex];
      if (
        oldValue.previous === newObject.new &&
        oldValue.new === newObject.previous
      ) {
        this.values.splice(oldIndex, 1);
      } else {
        this.values[oldIndex] = {
          id: id,
          previous: oldValue.previous,
          new: newValue,
        };
      }
    }

    this.cleanList();
  }

  cleanList() {
    let newRevList = [];
    for (let i = 0; i < this.values.length; i++) {
      if (!valuesAreSame(this.values[i].previous, this.values[i].new)) {
        newRevList.push(this.values[i]);
      }
    }

    this.values = newRevList;
  }

  getLength() {
    return this.values.length;
  }

  getValues() {
    return this.values;
  }
}
