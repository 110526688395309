import { convertToBoolean, convertToNumber } from "./GeneralTypeConversions.js";

export class AgreementPriceSettings{
    constructor(column, discardSetupFee){
        this.column = -1; // default
        this.discardSetupFee = true; // default
        this.addColumn(column);
        this.addDiscardSetupFeeLogic(discardSetupFee);
    }

    addColumn(column){
        let convertedValue = convertToNumber(false, column);
        if(convertedValue !== null){
            this.column = convertedValue;
            return;
        }
        this.errorMessage = "Column contained a non-integer input";
    }

    addDiscardSetupFeeLogic(discardSetupFee){
        let discard = convertToBoolean(discardSetupFee);
        if(discard !== null){
            this.discardSetupFee = discard;
            return;
        }
        this.errorMessage = "discard-setup-fee contained a non-boolean input";
    }
}