import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  DialogActions,
  DialogContentText,
} from "@mui/material";
import AddStaffComponent from "./AddStaffComponent";

const AddStaffDialog = ({ open, onClose, onSave, staff }) => {
 
  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle component={"h3"}>Add Staff Info</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Please fill out all staff info and rates.
        </DialogContentText>
        <AddStaffComponent staff={staff} onSave={onSave} onClose={onClose} />
      </DialogContent>
    </Dialog>
  );
};

export default AddStaffDialog;
