import React from "react";
// Context Providers
import { ClubReadyProvider } from '../ClubReadyContext';
import SettingsContextProvider from '../SettingsContext';
import { StudioScrapeStatusProvider } from '../StudioScrapeStatus/StudioScrapeStatusContext';
import { ClassCreationContextProvider } from '../ClassCreation/ClassCreationContext.js';
import { CommissionCreationContextProvider } from '../CommissionCreation/CommissionCreationContext';
import { XLSXConverterContextProvider } from '../XLSXConverterContext';
import { ExcelOutputContextProvider } from '../ExcelOutput/ExcelOutputContext';
import { EmailCreationContextProvider } from '../EmailCreationContext';
import { StaffProvider } from "../StaffContext/StaffContext.js";
import StepperContextProvider from '../../components/LinearStepper/stepperContext';
import UpdateReleaseDialog from '../../components/Dialogs/UpdateReleaseDialog';
import UserNotification from "../../components/Notifications/UserNotification.js";
// Define a function that composes all context providers
const PrivateContextProvider = ({ children }) => {
  return (
    <>

      <UserNotification />
      <UpdateReleaseDialog></UpdateReleaseDialog>
        <ClubReadyProvider>
          <SettingsContextProvider>
            <ClassCreationContextProvider>
              <CommissionCreationContextProvider>
                <XLSXConverterContextProvider>
                  <ExcelOutputContextProvider>
                    <EmailCreationContextProvider>
                      <StepperContextProvider>
                        <StudioScrapeStatusProvider>
                          <StaffProvider>
                            {children}
                          </StaffProvider>
                        </StudioScrapeStatusProvider>
                      </StepperContextProvider>
                    </EmailCreationContextProvider>
                  </ExcelOutputContextProvider>
                </XLSXConverterContextProvider>
              </CommissionCreationContextProvider>
            </ClassCreationContextProvider>
          </SettingsContextProvider>
        </ClubReadyProvider>
    </>
  );
};

export default PrivateContextProvider;
