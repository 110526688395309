import React from "react";
import { BrowserRouter as Router, Route, Switch, Link } from "react-router-dom";
import { Box, CssBaseline, Drawer, AppBar, Toolbar, Typography, List, ListItem, ListItemIcon, ListItemText, IconButton, Divider } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import AddAlertIcon from "@mui/icons-material/AddAlert";
import NotificationsIcon from "@mui/icons-material/Notifications";
import PersonIcon from "@mui/icons-material/Person";
import BugReportRoundedIcon from '@mui/icons-material/BugReportRounded';
import DonutSmallRoundedIcon from '@mui/icons-material/DonutSmallRounded';
const drawerWidth = 240;

function AdminDashboard(props) {
  console.log("props: ", props)
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <div>
      <Toolbar />
      <Divider />
      <List>
        <ListItem button component={Link} to="/admin/add-notification">
          <ListItemIcon><AddAlertIcon /></ListItemIcon>
          <ListItemText primary="Add Notification" />
        </ListItem>
        <ListItem button component={Link} to="/admin/notifications">
          <ListItemIcon><NotificationsIcon /></ListItemIcon>
          <ListItemText primary="View Notifications" />
        </ListItem>
        <ListItem button component={Link} to="/admin/output-test">
          <ListItemIcon><BugReportRoundedIcon /></ListItemIcon>
          <ListItemText primary="Output Test" />
        </ListItem>
        <ListItem button component={Link} to="/admin/user-impersonation">
          <ListItemIcon><PersonIcon /></ListItemIcon>
          <ListItemText primary="User Impersonation" />
        </ListItem>
        <ListItem button component={Link} to="/admin/user-metrics">
          <ListItemIcon><DonutSmallRoundedIcon /></ListItemIcon>
          <ListItemText primary="Metrics" />
        </ListItem>
      </List>
    </div>
  );

  return (

    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: "none" } }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap>
            Admin Dashboard
          </Typography>
        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
      >
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{ keepMounted: true }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": { boxSizing: "border-box", width: drawerWidth },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", sm: "block" },
            "& .MuiDrawer-paper": { boxSizing: "border-box", width: drawerWidth },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{ flexGrow: 1, p: 3, width: { sm: `calc(100% - ${drawerWidth}px)` } }}
      >
        <Toolbar />
        {props.drawerContent}
      </Box>
    </Box>

  );
}

export default AdminDashboard;
