import React, { useState } from "react";
import { TextField, Button } from "@mui/material";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { API } from "aws-amplify";
import {
  PopupButton,
} from "react-calendly";
import { useSnackBar } from "../contexts/SnackBarContext/SnackBarContext";

const defaultValues = {
  name: "",
  lastName: "",
  email: "",
  studioNumber: 1,
  software: "",
  studios: "",
  extra: "",
};

const ContactForm = () => {
  const showSnackBar = useSnackBar();
  const [formValues, setFormValues] = useState(defaultValues);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const email = `
    <em>Name</em>: ${formValues.name}<br>
    <em>Email</em>: ${formValues.email}<br> 
    <em>Studio Number</em>: ${formValues.studioNumber}<br>
    <em>Software</em>: ${formValues.software}<br>
    <em>Studio(s)</em>: ${formValues.studios}<br>

    <em>Payroll Issues</em>: ${formValues.extra}<br>
    `;
    sendEmail(email);
  };

  const sendEmail = async (email) => {
    const myAPI = "paywellAPIResource";
    const path = "/email";
    const event = {
      
      body: {
        to: "start@paywell.solutions",
        cc: "paywell.llc@gmail.com",
        subject: "New PayWell Prospect",
        html: email,
      },
    };
    let res;
    await API.post(myAPI, path, event)
      .then((response) => {
        response.success === true
          ? showSnackBar("Email sent!", "success")
          : showSnackBar("Failed sending email try again later.", "error");
        res = response;
      })
      .catch((error) => {
        console.error(error);
        res = error;
        showSnackBar("Failed sending email try again later.", "error");
      });
    return res;
  };

  const handleCalendlyButtonClick = () => {
    document.querySelector(".contact-form__calendly-button").click();
  };
  return (
    <>
      <form className="contact-form" onSubmit={handleSubmit}>
        <Card
          sx={{ minWidth: 275 }}
          style={{
            background: "rgb(255, 255, 255)",
            border: "1px solid rgb(230, 230, 230)",
            boxSizing: "border-box",
            boxShadow: "rgb(45 40 77 / 10%) 0px 12px 32px",
            borderRadius: "8px",
          }}
        >
          <CardContent
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Typography>
              <Box component="span" fontWeight="bold">
                Name
              </Box>
            </Typography>
            <TextField
              fullWidth
              style={{
                marginTop: ".75em",
                marginBottom: "1.5em", // Add margin-bottom here
              }}
              name="name"
              label="Enter your name"
              type="text"
              value={formValues.name}
              onChange={handleInputChange}
            />

            <Typography>
              <Box component="span" fontWeight="bold">
                Email
              </Box>
            </Typography>
            <TextField
              fullWidth
              style={{
                marginTop: ".75em",
                marginBottom: "1.5em", // Add margin-bottom here
              }}
              name="email"
              label="email@example.com"
              type="text"
              value={formValues.email}
              onChange={handleInputChange}
            />

            <Typography>
              <Box component="span" fontWeight="bold">
                Management Software
              </Box>
            </Typography>
            <TextField
              fullWidth
              style={{
                marginTop: ".75em",
                marginBottom: "1.5em", // Add margin-bottom here
              }}
              name="software"
              label="ClubReady, MarianaTek..."
              type="text"
              value={formValues.software}
              onChange={handleInputChange}
            />

            <Typography>
              <Box component="span" fontWeight="bold">
                Brand
              </Box>
            </Typography>
            <TextField
              fullWidth
              style={{
                marginTop: ".75em",
                marginBottom: "1.5em", // Add margin-bottom here
              }}
              name="studios"
              label="Brand Name"
              type="text"
              value={formValues.studios}
              onChange={handleInputChange}
            />

            <Typography>
              <Box component="span" fontWeight="bold">
                Number of Studios
              </Box>
            </Typography>
            <TextField
              fullWidth
              style={{
                marginTop: ".75em",
                marginBottom: "1.5em", // Add margin-bottom here
              }}
              name="studioNumber"
              type="number"
              value={formValues.studioNumber}
              onChange={handleInputChange}
              inputProps={{ min: "0" }}
            />

            <Typography>
              <Box component="span" fontWeight="bold">
                Select a Day/Time for a Demo
              </Box>
            </Typography>
            <div
              className="calendly"
              style={{
                marginBottom: "1.5em",
              }}
            >
              <Button
                type="button"
                style={{
                  position: "relative",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: "2em",
                  minHeight: "66px",
                  borderRadius: "40px",
                  width: "100%",
                }}
                onClick={handleCalendlyButtonClick}
                variant="outlined"
              >
                <PopupButton
                  className="contact-form__calendly-button"
                  url="https://calendly.com/scott-7373/paywell-general-discussion?hide_event_type_details=1&hide_gdpr_banner=1"
                  rootElement={document.getElementById("root")}
                  text="Schedule Demo"
                  styles={{
                    color: "inherit",
                    background: "none",
                    backgroundColor: "none",
                    border: "none",
                    outline: "none",
                    fontFamily: "inherit",
                    fontWeight: "inherit",
                    fontSize: "inherit",
                    lineHeight: "inherit",
                    letterSpacing: "inherit",
                    textTransform: "uppercase",
                    width: "100%",
                    height: "100%",
                    padding: "0",
                    margin: "0",
                  }}
                />
              </Button>

            </div>

            <Typography>
              <Box component="span" fontWeight="bold">
                What issues are you having with your current payroll process?
              </Box>
            </Typography>
            <TextField
              fullWidth
              style={{
                marginTop: ".75em",
                marginBottom: "1.5em", // Add margin-bottom here
              }}
              multiline
              rows={4}
              name="extra"
              label="Pain-Points..."
              type="text"
              value={formValues.extra}
              onChange={handleInputChange}
            />

            <Button
              type="submit"
              style={{
                position: "relative",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "2em",
                backgroundColor: "#006bff",
                minHeight: "66px",
                borderRadius: "40px",
              }}
              onClick={handleSubmit}
              variant="contained"
              href="contact"
            >
              Submit
            </Button>
          </CardContent>
        </Card>
      </form>
    </>
  );
};
export default ContactForm;
