import { SessionPayrollClassEvent } from "../ReportCompiler/classes/SessionPayrollClassEvent.js";
import { createDateFromString } from "../GeneralFunctions/CreateDateFromString.js";
import { ClassAttributes } from "../ReportCompiler/classes/BookingEvent.js";
import { EventAttributes } from "../ReportCompiler/classes/BookingEvent.js";
import { BookingEvent } from "../ReportCompiler/classes/BookingEvent.js";

export function readSessionPayroll(json, alternateJson) {
  let [customerDetailArray, backupBELArray] = createCustomerTable(alternateJson); // should it be alternateJson or json? I added alternateJson to parameters?

  const payArray = [];
  let staffInFile = [];
  let customerI = 0;
  let locationName = 0;
  let dateI = 0;
  let timeI = 0;
  let classNameI = 0;
  let priceI = 0;
  let instructorI = 0;
  let packageNameI = 0;
  let correctFileType = false;

  for (let i = 0; i < json.length; i++) {
    if (isSessionHeaderRow(json[i])) {
      for (let u = 0; u < json[i].length; u++) {
        if (json[i][u] !== "undefined") {
          if (json[i][u].includes("Date") && !json[i][u].includes("Logged")) {
            dateI = u;
          } else if (json[i][u].includes("Customer")) {
            customerI = u;
          } else if (json[i][u].includes("Start Time")) {
            timeI = u;
          } else if (json[i][u].includes("Price")) {
            priceI = u;
          } else if (json[i][u].includes("Session Type")) {
            classNameI = u;
          } else if (json[i][u].includes("Provider")) {
            instructorI = u;
          } else if (json[i][u].includes("Package Name")) {
            packageNameI = u;
          }
        }
      }
      correctFileType = true;
    } else if (isSessionLocationRow(json[i])) {
      let index = findLocationColumnIndex(json[i]);

      locationName = json[i][index].substring(
        json[i][index].indexOf("-") + 2,
        json[i][index].lastIndexOf("(") - 1
      );
    } else if (isSessionDataRow(json[i])) {
      let className = formatClassName(json[i][classNameI]);

      const payDate = createDateFromString(json[i][dateI], json[i][timeI]);
      const sessionPayrollClassEvent = new SessionPayrollClassEvent(
        json[i][customerI],
        parseFloat(json[i][priceI].replace(/\$/g, "")),
        locationName,
        className,
        payDate,
        json[i][instructorI],
        json[i][packageNameI]
      );

      const newStaff = {
        name: json[i][instructorI],
        type: "class",
        location: locationName,
      };
      
      let isDuplicate = staffInFile.some(staff => 
        staff.name === newStaff.name && staff.type === newStaff.type && staff.location === newStaff.location
      );
      
      if (!isDuplicate) {
        staffInFile.push(newStaff);
      }

      // staffInFile.push(
      //   {
      //     name: json[i][instructorI],
      //     type: "class",
      //     location: locationName
      //   }
      // )
      payArray.push(sessionPayrollClassEvent);
    }
  }

  let reportData = new ReportData(correctFileType, payArray);
  reportData.customerDetailArray = customerDetailArray;
  reportData.staffInFile = staffInFile;
  reportData.backupBELArray = backupBELArray;

  return reportData;

  // return {correctFile: correctFileType, sessionArray: payArray, customerDetailArray: customerDetailArray};
}

function isSessionHeaderRow(row) {
  return (
    row.includes("Booking ID") ||
    row.includes("Date") ||
    row.includes("Home Location")
  );
}

function findLocationColumnIndex(row) {
  let index = 0;
  if (row[1].includes("Bookings - ")) {
    index = 1;
  }
  return index;
}

function isSessionLocationRow(row) {
  return row[0].includes("Bookings - ") || row[1].includes("Bookings - ");
}

function isSessionDataRow(row) {
  return !row[0].includes("Totals");
}

function formatClassName(className) {
  className = className.replace("30 Mins session", "(30 min)");
  return className;
}

class ReportData {
  constructor(isCorrectFile, fileDataArray) {
    this.correctFileType = isCorrectFile;
    this.dataArray = fileDataArray;
    this.backupBELArray = null;
    this.payPeriod = null;
    this.classesInFile = null;
    this.staffInFile = null;
    this.customerDetailArray = null;
  }
}

// export function sessionFileConverter(json, alternateJson) {

//   let customerDetailArray = createCustomerTable(alternateJson);

//     const payArray = [];
//     let customerI = 0;
//     let locationName = 0;
//     let dateI = 0;
//     let timeI = 0;
//     let classNameI = 0;
//     let priceI = 0;
//     let instructorI = 0;
//     let packageNameI = 0;
//     let correctFileType = false;

//     for (let i = 0; i < json.length; i++) {
//       if (isSessionHeaderRow(json[i])) {
//         for (let u = 0; u < json[i].length; u++) {
//           if (json[i][u] !== "undefined") {
//             if (json[i][u].includes("Date") && !json[i][u].includes("Logged")) {
//               dateI = u;
//             } else if (json[i][u].includes("Customer")) {
//               customerI = u;
//             } else if (json[i][u].includes("Start Time")) {
//               timeI = u;
//             } else if (json[i][u].includes("Price")) {
//               priceI = u;
//             } else if (json[i][u].includes("Session Type")) {
//               classNameI = u;
//             } else if (json[i][u].includes("Provider")) {
//               instructorI = u;
//             } else if (json[i][u].includes("Package Name")) {
//               packageNameI = u;
//             }
//           }
//         }
//         correctFileType = true;
//       } else if (isSessionLocationRow(json[i])) {
//         let index = findLocationColumnIndex(json[i]);

//         locationName = json[i][index].substring(
//           json[i][index].indexOf("-") + 2,
//           json[i][index].lastIndexOf("(") - 1
//         );
//       } else if (isSessionDataRow(json[i])) {
//         let className = formatClassName(json[i][classNameI]);

//         const payDate = createDateFromString(json[i][dateI], json[i][timeI]);
//         const sessionPayrollClassEvent = new SessionPayrollClassEvent(
//           json[i][customerI],
//           parseFloat(json[i][priceI].replaceAll('$', '')),
//           locationName,
//           className,
//           payDate,
//           json[i][instructorI],
//           json[i][packageNameI]
//         );
//         payArray.push(sessionPayrollClassEvent);
//       }
//     }

//     return {correctFile: correctFileType, sessionArray: payArray, customerArray: customerDetailArray};
//   }

function createCustomerTable(json) {
  const customerArray = [];
  const backupBELArray = [];
  let classNameI = 0;
  let classDateI = 0;
  let startTime = 0;
  let endTime = 0;
  let instructorNameI = 0;
  let attendeeNameI = 0;
  let statusI = 0;
  let packageNameI = 0;
  let leadTypeI = 0;
  let membershipTypeI = 0;

  let locationName = "";

  for (let i = 0; i < json.length; i++) {
    if (isCustomerSessionHeaderRow(json[i])) {
      for (let u = 0; u < json[i].length; u++) {
        if (json[i][u] !== "undefined") {
          if (json[i][u].includes("Class Name")) {
            classNameI = u;
          } else if (json[i][u].includes("Class Date")) {
            classDateI = u;
          } else if (json[i][u].includes("Start Time")) {
            startTime = u;
          } else if (json[i][u].includes("End Time")) {
            endTime = u;
          } else if (json[i][u].includes("Employee Name")) {
            instructorNameI = u;
          } else if (json[i][u].includes("Attendee Name")) {
            attendeeNameI = u;
          } else if (json[i][u].includes("Status")) {
            statusI = u;
          } else if (json[i][u].includes("Package Name")) {
            packageNameI = u;
          } else if (json[i][u].includes("Lead Type")) {
            leadTypeI = u;
          } else if (json[i][u].includes("Membership Type")) {
            membershipTypeI = u;
          }
        }
      }
    } else if (isCustomerSessionLocationRow(json[i])) {
      let index = findCustomerLocationColumnIndex(json[i]);

      locationName = json[i][index].substring(
        json[i][index].indexOf("-") + 2,
        json[i][index].lastIndexOf("(") - 1
      );
    } else if (isCustomerSessionDataRow(json[i])) {

      const belDate = createDateFromString(
        json[i][classDateI],
        json[i][startTime]
      );

      let endTimeDate = createDateFromString(
        json[i][classDateI],
        json[i][endTime]
      );

      let sessionLength = (endTimeDate - belDate) / (1000 * 60);

      let sessionName = json[i][classNameI];
      if (sessionLength === 30) {
        sessionName = json[i][classNameI] + " (30 min)";
      }

      const classAttributes = new ClassAttributes(
        json[i][instructorNameI],
        sessionName,
        locationName,
        belDate,
        sessionLength
      );

      // const loggedDateObject = createLoggedDateObject(
      //   json[i][bookingLoggedDateTimeI]
      // );
      // const loggedBy = findLoggedByPerson(json[i][bookingLoggedByI]);

      let attendeeNameArray = json[i][attendeeNameI].split(" ");
      if(attendeeNameArray.length === 1) {
        attendeeNameArray.push("");
      }

      let sessionStatus = json[i][statusI];
      let belStatus = [];
      if(sessionStatus === "Showed"){
        belStatus.push("Booking Completed");
        belStatus.push("Completed Booking ");
      } else if(sessionStatus === "No Show") {
        belStatus.push("No-Show");
        belStatus.push("No Show Booking ");
      } else if(sessionStatus === "Cancelled/Lost") {
        belStatus.push("Booking Cancelled");
        belStatus.push("Cancelled Outside Policy Rules - Session Lost");
      } else if(sessionStatus === "Not Logged") {
        belStatus.push("Past But Not Logged");
        belStatus.push("Open Booking - not yet logged");
      }

      if(belStatus.length === 0){
        belStatus = ["???", "???"];
      } else if(belStatus.length === 1) {
        let tempStatus = belStatus[0];
        belStatus.push(tempStatus);
      }

      const eventAttributes = new EventAttributes(
        belStatus[0],
        belStatus[1],
        attendeeNameArray[0],
        attendeeNameArray[1],
        "N/A",
        belDate
      );

      // if (isValidBookingEvent(json[i], belDate, currentBelPayPeriod)) {
        const bookingEvent = new BookingEvent(
          classAttributes,
          eventAttributes,
          true
        );

        if(bookingEvent.classInstructor !== "" && bookingEvent.className !== "" && bookingEvent.location !== ""){
          backupBELArray.push(bookingEvent);
        }

        

      let className = json[i][classNameI];

      const payDate = createDateFromString(
        json[i][classDateI],
        json[i][startTime]
      );
      let customer = {
        name: json[i][attendeeNameI],
        location: locationName,
        class: className,
        date: payDate,
        status: json[i][statusI],
        package: json[i][packageNameI],
        lead: json[i][leadTypeI],
        membership: json[i][membershipTypeI],
      };
      // const sessionPayrollClassEvent = new SessionPayrollClassEvent(
      //   json[i][customerI],
      //   parseFloat(json[i][priceI].replaceAll('$', '')),
      //   locationName,
      //   className,
      //   payDate,
      //   json[i][instructorI],
      //   json[i][packageNameI]
      // );
      customerArray.push(customer);
    }
  }

  return [customerArray, backupBELArray];
}

function isCustomerSessionLocationRow(row) {
  return (
    row[0].includes("Class Attendance - ") ||
    row[1].includes("Class Attendance - ")
  );
}

function findCustomerLocationColumnIndex(row) {
  let index = 0;
  if (row[1].includes("Class Attendance - ")) {
    index = 1;
  }
  return index;
}

function isCustomerSessionHeaderRow(row) {
  return (
    row.includes("Class Name") ||
    row.includes("Employee Name") ||
    row.includes("Attendee ID")
  );
}

function isCustomerSessionDataRow(row) {
  return !row[0].includes("Totals");
}