import React from "react";
import { API, input } from "aws-amplify";
// import * as XLSX from 'xlsx';
import XLSX from "@sheet/core";

import { getFirebaseJwtToken } from "./tokenUtility";

import { useLocation } from "react-router-dom";
// import { BooleanToggle } from "./QuestionObjects/BooleanToggle";
import {
  convertToBoolean,
  convertToNumber,
  convertToArray,
} from "./QuestionObjects/GeneralTypeConversions.js";

import { AgreementPriceSettings } from "./QuestionObjects/AgreementPriceSettings.js";
import { BooleanToggle } from "./QuestionObjects/BooleanToggle.js";
import { CatchAllClassLogic } from "./QuestionObjects/CatchAllClassLogic.js";
import { CatchAllCommissionLogic } from "./QuestionObjects/CatchAllCommissionLogic.js";
import { CatchAllTimeLogic } from "./QuestionObjects/CatchAllTimeLogic.js";
import { ClassAttributeList } from "./QuestionObjects/ClassAttributeList.js";
import { ClassBucket } from "./QuestionObjects/ClassBuckets.js";
import { CommissionBucket } from "./QuestionObjects/CommissionBuckets.js";
import { HoursVsClassFlag } from "./QuestionObjects/HourVsClassFlag.js";
import { InputBox } from "./QuestionObjects/InputBox.js";
import { ProcessorConfig } from "./QuestionObjects/ProcessorConfig.js";
import { Column } from "./QuestionObjects/ProcessorConfig.js";
import { StaffAttribute } from "./QuestionObjects/ProcessorConfig.js";
import { ExcelAttribute } from "./QuestionObjects/ProcessorConfig.js";
import { RowAttribute } from "./QuestionObjects/ProcessorConfig.js";
// import Studio from "../components/Studio"; ~~~~ check with scott
import { AgreementEvent } from "./ReportCompiler/classes/AgreementEvent.js";
import { BookingEvent } from "./ReportCompiler/classes/BookingEvent.js";
import { ClassAttributes } from "./ReportCompiler/classes/BookingEvent.js";
import { EventAttributes } from "./ReportCompiler/classes/BookingEvent.js";
import { CellReference } from "./ReportCompiler/classes/CellReference.js";
import { CommissionObject } from "./ReportCompiler/classes/CommissionObject.js";
import { DetailReferenceBox } from "./ReportCompiler/classes/DetailReferenceBox.js";
import { Member } from "./ReportCompiler/classes/Member.js";
import { PayWellStaff } from "./ReportCompiler/classes/PayWellStaff.js";
import { RetailEvent } from "./ReportCompiler/classes/RetailEvent.js";
import { SessionPayrollClassEvent } from "./ReportCompiler/classes/SessionPayrollClassEvent.js";
import { TimeEvent } from "./ReportCompiler/classes/TimeEvent.js";
import { findPayPeriods } from "../contexts/ReportCompiler/utility-functions.js";
// Classes
// I had to put all classes in here becyase apparently using eval() on a imported class doesnt work

export class ReportData {
  constructor(isCorrectFile, fileDataArray) {
    this.correctFileType = isCorrectFile;
    this.dataArray = fileDataArray;
    this.payPeriod = null;
    this.classesInFile = null;
    this.staffInFile = null;
  }
}

export class FullClassPaySpecifications {
  constructor(valid, statuses) {
    this.valid = false;
    this.attributes = ["Cancelled Outside Policy", "Rescheduled By Admin"];
    this.addValid(valid);
    this.addAttributes(statuses);
    this.hoursCancelledWithin = -1;
  }

  addValid(valid) {
    let booleanValid = convertToBoolean(valid);
    if (booleanValid !== null) {
      this.valid = booleanValid;
      return;
    }
    this.errorMessage = "valid was not of type boolean";
  }

  addAttributes(statuses) {
    let statusesArray = convertToArray(statuses);
    if (statusesArray !== null) {
      this.valid = statusesArray;
      return;
    }
    this.errorMessage = "class statuses could not be read as type array";
  }

  addStatusesCancelledWithin(hours) {
    let hoursInt = convertToNumber(false, hours);
    if (hoursInt !== null) {
      this.hoursCancelledWithin = hoursInt;
      return;
    }
    this.errorMessage = "hours was not of type integer";
  }
}
export class PayPeriodInfo {
  constructor(frequency) {
    this.frequency = frequency;
    this.exampleStartDate = null;
    this.exampleEndDate = null;
  }

  setStartDate(date) {
    if (typeof date === "string") {
      this.exampleStartDate = createDateFromString(date);
      return;
    } else if (date instanceof Date) {
      this.exampleStartDate = date;
      return;
    }
    this.errorMessage = "start value was not of type date";
  }

  setEndDate(date) {
    if (typeof date === "string") {
      this.exampleEndDate = createDateFromString(date);
      return;
    } else if (date instanceof Date) {
      this.exampleEndDate = date;
      return;
    }
    this.errorMessage = "end value was not of type date";
  }

  findMostRecentPayPeriod() {
    let today = new Date();
    //complete later
  }
}
export class SelectorBox {
  constructor(optionArray) {
    this.options = {};
    let convertedOptions = convertToArray(optionArray);
    if (convertedOptions !== null) {
      for (let i = 0; i < convertedOptions.length; i++) {
        this.options[convertedOptions[i]] = false;
      }
    }
  }

  selectOption(option) {
    if (this.options.hasOwnProperty(option)) {
      this.options[option] = true;
    } else {
      this.errorMessage = "No property: " + option;
    }
  }
}

// Report Compiler Classes
export class ClassEvent {
  constructor(name, instructor, location, date, sessionLength) {
    this.type = "None";
    this.name = name;
    this.secondaryName = "";
    this.instructor = instructor;
    this.location = location;
    this.date = date;
    this.attendeeCount = 0;
    this.pay = null;
    this.sessionLength = sessionLength;
    this.comment = [];
    this.attendeeList = [];

    this.addedToExcel = false;
  }

  getClassTime() {
    let hours = this.date.getHours();
    let minutes = this.date.getMinutes();
    const ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours ? hours : 12;
    minutes = minutes < 10 ? "0" + minutes : minutes;
    const strTime = hours + ":" + minutes + ":00 " + ampm;
    return strTime;
  }

  commentsToString() {
    let commentStr = "";
    for (let i = 0; i < this.comment.length; i++) {
      if (this.comment[i].length > 0) {
        commentStr = commentStr + " / " + this.comment[i];
      }
    }
    if (commentStr.length === 0) {
      return "";
    }
    return commentStr.substring(3);
  }
}
export class ClassAttendee {
  constructor(name, loggedBy, loggedTime, bookingEventType, bookingStatus) {
    this.name = name;
    this.loggedBy = loggedBy;
    this.loggedTime = loggedTime;
    this.bookingEventType = bookingEventType;
    this.bookingStatus = bookingStatus;

    this.signedUpAfterSession = null;

    this.completed = false;
  }

  setSignedUpAfterSession(outcome) {
    this.signedUpAfterSession = outcome;
  }

  toString() {
    return (
      "Name: " +
      this.name +
      "\nLogged By: " +
      this.loggedBy +
      "\nLogged Time: " +
      this.loggedTime
    );
  }
}
export class ClassTypeRequirements {
  constructor(type, correctTypes, incorrectTypes) {
    this.type = type;
    this.correctTypes = correctTypes;
  }
}
export class CommissionDetailReferenceBox {
  constructor(type, staffName, staffType, ref) {
    this.type = type;
    this.staffName = staffName;
    this.staffType = staffType;
    this.ref = ref;
  }
}
export class TimeDetailReference {
  constructor(staffName, location, row) {
    this.staffName = staffName;
    this.location = location;
    this.row = row;
  }
}

const classMap = {
  // ClassCreation,
  TimeEvent,
  ReportData,
  AgreementPriceSettings,
  BooleanToggle,
  CatchAllClassLogic,
  CatchAllCommissionLogic,
  CatchAllTimeLogic,
  ClassAttributeList,
  ClassBucket,
  CommissionBucket,
  FullClassPaySpecifications,
  HoursVsClassFlag,
  InputBox,
  PayPeriodInfo,
  ProcessorConfig,
  Column,
  StaffAttribute,
  ExcelAttribute,
  RowAttribute,
  SelectorBox,
  // Studio, ~~~~ check with scott
  AgreementEvent,
  BookingEvent,
  ClassAttributes,
  EventAttributes,
  CellReference,
  ClassEvent,
  ClassAttendee,
  ClassTypeRequirements,
  CommissionDetailReferenceBox,
  CommissionObject,
  DetailReferenceBox,
  Member,
  PayWellStaff,
  RetailEvent,
  SessionPayrollClassEvent,
};
function createInstanceByClassName(className) {
  if (className in classMap) {
    const classConstructor = classMap[className];
    return new classConstructor();
  } else {
    throw new Error(`Class '${className}' not found.`);
  }
}

// Functions
export function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

/**
 * Parses through an object and returns all objects that have the property called "status" at the top level.
 * @param {object} obj - The object to be parsed.
 * @returns {array} - An array containing all objects that have the "status" property.
 */
export function findObjectsWithProperty(obj, property) {
  let result = [];

  for (let key in obj) {
    if (
      obj.hasOwnProperty(key) &&
      obj[key] !== null && // Add this check to ensure obj[key] is not null
      typeof obj[key] === "object" &&
      obj[key].hasOwnProperty(property) &&
      !isNullOrEmpty(obj[key][property])
    ) {
      result.push(obj[key]);
    }
  }

  return result;
}

/**
 * Checks if a string is null or empty.
 * @param {string} str - The string to be checked.
 * @returns {boolean} - Returns true if the string is null or empty, otherwise returns false.
 */
function isNullOrEmpty(str) {
  return str === null || str.trim() === "";
}

/**
 * Adds or updates a variable with its value in the URL without refreshing the page.
 * @param {string} variable - The variable name to add or update in the URL.
 * @param {string} value - The corresponding value of the variable.
 */
export function addOrUpdateUrlVariable(variable, value) {
  // Get the current URL
  const currentUrl = window.location.href;

  // Check if the variable already exists in the URL
  const urlSearchParams = new URLSearchParams(window.location.search);
  urlSearchParams.set(variable, value);

  // Create the new URL with the updated variable value
  const newUrl = currentUrl.split("?")[0] + "?" + urlSearchParams.toString();

  // Push the new URL to the browser history without refreshing the page
  window.history.pushState({ path: newUrl }, "", newUrl);
}

/**
 * Retrieves the value of a URL variable.
 * @param {string} variable - The variable name to retrieve its value.
 * @returns {string|null} - The value of the URL variable, or null if it doesn't exist.
 */
export function getUrlVariableValue(variable) {
  const urlSearchParams = new URLSearchParams(window.location.search);
  return urlSearchParams.get(variable);
}

/**
 * Checks if a property exists in an object.
 * @param {object} object - The object to check for the property.
 * @param {string} property - The property to check for existence.
 * @returns {boolean} - Returns true if the property exists, false otherwise.
 */
export function checkObjectProperty(object, property) {
  return Object.prototype.hasOwnProperty.call(object, property);
}

// export function updateObject(obj, path, value) {
//   const keys = path.split(".");
//   let currentObj = obj;

//   for (let i = 0; i < keys.length - 1; i++) {
//     const key = keys[i];
//     if (!currentObj.hasOwnProperty(key)) {
//       currentObj[key] = {};
//     }
//     currentObj = currentObj[key];
//   }

//   currentObj[keys[keys.length - 1]] = value;
//   return obj;
// }

export function updateObject(obj, path, value, replace = true) {
  const keys = path.split(".");
  let currentObj = obj;

  for (let i = 0; i < keys.length - 1; i++) {
    const key = keys[i];
    if (!currentObj.hasOwnProperty(key)) {
      currentObj[key] = {};
    }
    currentObj = currentObj[key];
  }

  if (replace) {
    currentObj[keys[keys.length - 1]] = value;
  } else {
    const key = keys[keys.length - 1];
    if (!currentObj.hasOwnProperty(key)) {
      currentObj[key] = value;
    } else if (Array.isArray(currentObj[key])) {
      currentObj[key] = currentObj[key].concat(value);
    } else {
      currentObj[key] = [currentObj[key], value];
    }
  }

  return obj;
}

function isFunctionString(str) {
  return /^function\s*\(/.test(str);
}

function createFunctionFromSource(source) {
  return eval(`(${source})`);
}
/**
 * A custom serialization function that converts an object with class instances to a plain object with serialized versions of the class instances.
 *
 * @param {object} obj - The object to serialize.
 * @returns {object} A plain object with serialized versions of the class instances.
 */
// export function serializeObjectWithMethods(obj) {
//   const clonedObj = Array.isArray(obj) ? [...obj] : { ...obj };
//   Object.entries(obj).forEach(([key, value]) => {
//     // If the property is an instance of a class, replace it with a serialized version of the instance.
//     if (!Array.isArray(value)  ) {
//       if (
//         typeof value === "object" &&
//         value !== null &&
//         value.constructor &&
//         value.constructor.name !== "Object"

//       ) {

//         // Serialize the instance to a plain object.

//         const serializedInstance = {};
//         Object.entries(value).forEach(([key, value]) => {
//           serializedInstance[key] = value;
//         });
//         serializedInstance["__class__"] = value.constructor.name;

//         // Replace the instance with the serialized version.
//         clonedObj[key] = serializedInstance;

//       }
//     }
//     // If the property is an object or array, serialize it recursively.
//     if (typeof obj[key] === "object" && obj[key] !== null) {
//       clonedObj[key] = serializeObjectWithMethods(clonedObj[key]);
//     }
//   });

//   // Return the cloned object with serialized class instances.
//   return clonedObj;
// }

/**
 * A custom deserialization function that converts a plain object with serialized versions of class instances to an object with proper class instances.
 *
 * @param {object} obj - The object to deserialize.
 * @returns {object} An object with proper class instances.
 */

// export function deserializeObjectWithMethods(obj) {
//   // Clone the input object to avoid modifying the original.
//   const clonedObj = Array.isArray(obj) ? [...obj] : { ...obj };

//   // Loop through each property of the cloned object.
//   Object.entries(obj).forEach(([key, value]) => {
//     let instanceCreated = false;
//     // If the property is a serialized instance of a class, replace it with an actual instance of the class.
//     if (!Array.isArray(value)) {
//       if (typeof value === "object" && value !== null && "__class__" in value) {
//         // Get the class name and constructor function.
//         const className = value["__class__"];

//         const classConstructor = eval(className);

//         // Create a new instance of the class and set its properties from the serialized version.
//         const instance = new classConstructor();

//         Object.entries(value).forEach(([key, value]) => {
//           if (key !== "__class__") {
//             instance[key] = value;
//           }
//         });

//         // Replace the serialized instance with the actual instance.
//         clonedObj[key] = instance;

//         instanceCreated = true;
//       }
//     }
//     // If the property is an object or array, deserialize it recursively.
//     if (!instanceCreated && typeof obj[key] === "object" && obj[key] !== null) {
//       clonedObj[key] = deserializeObjectWithMethods(clonedObj[key]);
//     }

//   });

//   // Return the cloned object with deserialized class instances.
//   return clonedObj;
// }

export function serializeObjectWithMethods(obj) {
  const clonedObj = Array.isArray(obj) ? [...obj] : { ...obj };
  Object.entries(clonedObj).forEach(([key, value]) => {
    if (value instanceof Date) {
      clonedObj[key] = { __date__: value.toISOString() };
    } else if (typeof value === "object" && value !== null) {
      if (value.constructor && value.constructor.name !== "Object") {
        const serializedInstance = serializeObjectWithMethods(value);
        serializedInstance["__class__"] = value.constructor.name;
        clonedObj[key] = serializedInstance;
      } else {
        clonedObj[key] = serializeObjectWithMethods(value);
      }
    }
  });
  return clonedObj;
}

export function deserializeObjectWithMethods(obj) {
  if (typeof obj === "object" && obj !== null) {
    if ("__date__" in obj) {
      return new Date(obj["__date__"]);
    } else if ("__string__" in obj) {
      return obj["__string__"];
    } else if ("__boolean__" in obj) {
      return obj["__boolean__"];
    } else if ("__class__" in obj) {
      const className = obj["__class__"];
      var instance;
      var classConstructor;

      if (className in classMap) {
        instance = createInstanceByClassName(className);
      } else {
        classConstructor = eval(className);
        instance = new classConstructor();
      }
      Object.entries(obj).forEach(([key, value]) => {
        if (key !== "__class__") {
          instance[key] = deserializeObjectWithMethods(value);
        }
      });
      return instance;
    } else {
      const clonedObj = Array.isArray(obj) ? [...obj] : { ...obj };
      Object.entries(clonedObj).forEach(([key, value]) => {
        clonedObj[key] = deserializeObjectWithMethods(value);
      });
      return clonedObj;
    }
  }
  return obj;
}

export function getObjectLength(obj) {
  return Object.keys(obj).length;
}
// export function pushToCollection(collection, ...values) {
//   if (typeof collection === "object" && getObjectLength(collection) < 1) {
//     return [...values];
//   } else if (Array.isArray(collection)) {
//     return [...collection, ...values];
//   } else if (typeof collection === "object" && collection !== null) {
//     return [
//       ...collection,
//       ...Object.fromEntries(values.map((val) => [val.key, val.value])),
//     ];
//   } else {
//     throw new TypeError("First argument must be an array or an object.");
//   }
// }

export function findStudioNumberFromInputId(fileId) {
  const number = fileId.match(/\d/g);
  if (number === null) {
    return 0;
  } else {
    return number;
  }
}

export function findPayRates(inputFilesArrays, studiosInformation) {
  for (let i = 0; i < inputFilesArrays.TIME.length; i++) {
    if (
      inputFilesArrays?.TIME[i]?.staffName &&
      inputFilesArrays?.TIME[i]?.location &&
      !inputFilesArrays?.TIME[i]?.exception &&
      inputFilesArrays
    ) {
      let payRate = findHourlyPayRate(
        inputFilesArrays.TIME[i].staffName,
        inputFilesArrays.TIME[i].location,
        studiosInformation
      );
      if (payRate !== 0) {
        inputFilesArrays.TIME[i].payRate = payRate;
      }
    } else {
      //console.warn("Undefined Parameter in function");
    }
    if (inputFilesArrays.TIME[i].overrideRate !== undefined) {
      inputFilesArrays.TIME[i].payRate = parseFloat(inputFilesArrays.TIME[i].overrideRate);
    }
  }
  inputFilesArrays.TIME = addTimeTypes(inputFilesArrays, inputFilesArrays.TIME);
  if(inputFilesArrays.questions.useComplexTimeSettings){
    addComplexTimeBucketRates(inputFilesArrays, studiosInformation);
  }

  return inputFilesArrays.TIME;
}

function addComplexTimeBucketRates(inputFilesArrays, studiosInformation) {
  for (let i = 0; i < inputFilesArrays.TIME.length; i++) {
    if (
      inputFilesArrays?.TIME[i]?.staffName &&
      inputFilesArrays?.TIME[i]?.location &&
      !inputFilesArrays?.TIME[i]?.exception &&
      inputFilesArrays
    ) {
      let payRate = findHourlyBucketPayRate(
        inputFilesArrays.TIME[i].staffName,
        inputFilesArrays.TIME[i].location,
        inputFilesArrays.TIME[i].bucketName,
        studiosInformation
      );
      if (payRate !== 0) {
        inputFilesArrays.TIME[i].payRate = payRate;
      }
    } else {
      //console.warn("Undefined Parameter in function");
    }
    if (inputFilesArrays.TIME[i].overrideRate !== undefined) {
      inputFilesArrays.TIME[i].payRate = parseFloat(inputFilesArrays.TIME[i].overrideRate);
    }
  }
}

export function addEarnings(timeEvents, staffArray) {
  let staffInEarnings = [];
  let earnings = [];
  for(let i = 0; i < staffArray.length; i++){
    let staffObj = staffArray[i];
    Object.entries(staffObj).map(([key, value]) => {
      if(key.includes('Earning')){
        for(let j = 0; j < value.length; j++){
          let location = value[j].location;
          let earningsName = (key.charAt(0).toUpperCase() + key.slice(1)).replace('Earning', '');
          let earningsEvent = new TimeEvent(staffObj.names[0], location, 0, 0, earningsName, value[j].amount);
          earningsEvent.earnings = true;
          earnings.push(earningsEvent);
          staffInEarnings.push({name: staffObj.names[0], type: 'time', location: location});
        }
      }
    });
  }
  return [timeEvents.concat(earnings), staffInEarnings];
}

function addTimeTypes(inputFilesArrays, timeEvents) {
  let timeBuckets = inputFilesArrays.questions.timeBuckets;
  for (let i = 0; i < timeEvents.length; i++) {
    let bucketName = null;
    let overtimeApplicable = false;
    let type = null;
    for (let j = 0; j < timeBuckets.length; j++) {
      let timeBucketNames = timeBuckets[j].names;
      for(let k = 0; k < timeBucketNames.length; k++){
        if (timeEvents[i].description.includes(timeBucketNames[k])) {
          bucketName = timeBuckets[j].name;
          overtimeApplicable = timeBuckets[j].overtimeApplicable;
          type = timeBuckets[j].type;
        }
      }
    }
    if(type === null){
      let standardBucket = timeBuckets.filter(bucket => bucket.isStandardTimeType);
      bucketName = standardBucket.name;
      overtimeApplicable = standardBucket.overtimeApplicable;
      type = standardBucket.type;
    }
    timeEvents[i].type = type;
    timeEvents[i].bucketName = bucketName;
    timeEvents[i].overtimeApplicable = overtimeApplicable;
  }
  return timeEvents;
}

export function findHourlyPayRate(name, location, studiosInformation) {
  const instructorPayTable = studiosInformation.staffArray;

  for (let i = 0; i < instructorPayTable.length; i++) {
    if (instructorPayTable[i].isNamed(name)) {
      if (instructorPayTable[i].isLocated(location)) {
        if (location !== "All") {
          let studioHourlyRate = instructorPayTable[i].getHourlyRate(location);

          return parseFloat(studioHourlyRate);
        }

        let firstHourlyRate = instructorPayTable[i].getHourlyRate(
          instructorPayTable[i].getLocations()[0]
        );
        return parseFloat(firstHourlyRate);
      }
    }
  }
  return 0;
}

export function findHourlyBucketPayRate(name, location, bucketName, studiosInformation) {
  const instructorPayTable = studiosInformation.staffArray;
  let key = bucketName.toLowerCase() + 'Hourly';

  for (let i = 0; i < instructorPayTable.length; i++) {
    if (instructorPayTable[i].isNamed(name)) {
      if (instructorPayTable[i].isLocated(location)) {
        if (location !== "All") {
          let studioHourlyRate = instructorPayTable[i].getProperty(location, key);

          return parseFloat(studioHourlyRate);
        }

        let firstHourlyRate = instructorPayTable[i].getProperty(
          instructorPayTable[i].getLocations()[0],
          key
        );
        return parseFloat(firstHourlyRate);
      }
    }
  }
  return 0;
}

export function addHolidayPay(reportCompilerState) {
  let inputFilesArrays = reportCompilerState.inputFilesArrays;
  let timeEvents = inputFilesArrays.TIME;

  let holidayRate = inputFilesArrays.questions.holidayRate;
  let holidaysObserved = inputFilesArrays.questions.holidaysObserved;
  let holidayDates = getExactHolidayDates(holidaysObserved, new Date().getFullYear());

  for (let i = 0; i < timeEvents.length; i++) {
    if (timeEvents[i].exception || timeEvents[i].earnings) {
      continue;
    }
    let event = timeEvents[i];
    let eventDate = new Date(event.date);

    // Normalize the event date to midnight for comparison
    let normalizedEventDate = new Date(eventDate.getFullYear(), eventDate.getMonth(), eventDate.getDate());

    for (let j = 0; j < holidayDates.length; j++) {
      // Normalize the holiday date to midnight for comparison
      let normalizedHolidayDate = new Date(holidayDates[j].getFullYear(), holidayDates[j].getMonth(), holidayDates[j].getDate());

      if (normalizedHolidayDate.getTime() === normalizedEventDate.getTime()) {
        event.holiday = true;
        let staffObject = findStaffObject(event.staffName, reportCompilerState);
        if(!staffObject){
          break;
        }
        let holidayPayRate = getHolidayPayRate(reportCompilerState, staffObject, event, holidayRate);
        event.holidayPayRate = holidayPayRate;
        if(event.classification === 'Regular'){
          event.classification = 'Holiday';
          event.payRate = holidayPayRate;
          event.description = event.description + ' Holiday';
        }
        break; // Stop checking once a match is found
      }
    }
  }

  return timeEvents;
}

function getHolidayPayRate(reportCompilerState, staffObj, timeEvent, holidayRate){
  let payPeriod = reportCompilerState.payrollInformation.belPayPeriods;
  let inputFilesArrays = reportCompilerState.inputFilesArrays;
  if (inputFilesArrays.questions.payPeriodObject.payPeriodExamples.length === 0 || !payPeriod) {
    console.log("No Pay Period to determine holiday");
    return 0;
  }

  let weeks = getFiscalWeeks(inputFilesArrays.questions.payPeriodObject.fiscalWorkweekStartDay, payPeriod);
  
  let week = weeks[getWeekIndex(new Date(timeEvent.date), weeks)];
  let regularRateOfPay = getRegularRateOfPay(reportCompilerState, inputFilesArrays.TIME, timeEvent.staffName, week);
  let rate = 0;
  if(holidayRate === '1.5x Hourly Rate'){
    rate = 1.5 * staffObj.getHourlyRate(timeEvent.location);
  } else if(holidayRate === '1.5x Regular Rate'){
    rate = 1.5 * regularRateOfPay;
  } else if(holidayRate === 'Overtime Rate'){
    rate = 1.5 * staffObj.getProperty(timeEvent.location, "overtime");
  } else {
    rate = 1.5 * timeEvent.payRate;
  }
  return rate;
}

function getExactHolidayDates(holidays, year) {
  let holidayDates = [];

  // Helper function to calculate specific Monday holidays (e.g., MLK Day, Presidents' Day)
  function getNthDayOfMonth(n, dayOfWeek, month, year) {
    const firstDay = new Date(year, month, 1).getDay();
    const offset = (dayOfWeek - firstDay + 7) % 7;
    return new Date(year, month, 1 + offset + 7 * (n - 1));
  }

  // Iterate over each holiday
  for (let holiday of holidays) {
    if (/^\d{1,2}\/\d{1,2}\/\d{4}$/.test(holiday)) {
      // It's a date string, parse it and add to the list
      const [month, day, year] = holiday.split('/');
      holidayDates.push(new Date(year, month - 1, day));
      continue;
    }
    // Generate dates for last year, this year, and next year
    for (let offset = -1; offset <= 1; offset++) {
      let currentYear = year + offset;
      let date;

      switch (holiday) {
        case "New Year's Day":
          date = new Date(currentYear, 0, 1);
          break;
        case "Martin Luther King Jr. Day":
          date = getNthDayOfMonth(3, 1, 0, currentYear);
          break;
        case "Presidents' Day":
          date = getNthDayOfMonth(3, 1, 1, currentYear);
          break;
        case "Memorial Day":
          date = getNthDayOfMonth(5, 1, 4, currentYear); // Last Monday of May
          break;
        case "Independence Day":
          date = new Date(currentYear, 6, 4);
          break;
        case "Labor Day":
          date = getNthDayOfMonth(1, 1, 8, currentYear);
          break;
        case "Columbus Day":
          date = getNthDayOfMonth(2, 1, 9, currentYear);
          break;
        case "Veterans Day":
          date = new Date(currentYear, 10, 11);
          break;
        case "Thanksgiving Day":
          date = getNthDayOfMonth(4, 4, 10, currentYear);
          break;
        case "Christmas Day":
          date = new Date(currentYear, 11, 25);
          break;
        case "Family Day":
          date = getNthDayOfMonth(3, 1, 1, currentYear);
          break;
        case "Good Friday":
          date = getGoodFriday(currentYear);
          break;
        case "Victoria Day":
          date = getVictoriaDay(currentYear);
          break;
        case "Canada Day":
          date = new Date(currentYear, 6, 1);
          break;
        case "Civic Holiday":
          date = getNthDayOfMonth(1, 1, 7, currentYear);
          break;
        case "Labour Day (Canada)":
          date = getNthDayOfMonth(1, 1, 8, currentYear);
          break;
        case "Thanksgiving (Canada)":
          date = getNthDayOfMonth(2, 1, 9, currentYear);
          break;
        case "Boxing Day":
          date = new Date(currentYear, 11, 26);
          break;
        case "Constitution Day (Mexico)":
          date = new Date(currentYear, 1, 5);
          break;
        case "Benito Juárez's Birthday":
          date = new Date(currentYear, 2, 21);
          break;
        case "Revolution Day (Mexico)":
          date = new Date(currentYear, 10, 20);
          break;
        case "Easter Monday":
          date = getEasterMonday(currentYear);
          break;
        case "May Day / Labor Day":
          date = new Date(currentYear, 4, 1);
          break;
        case "Ascension Day":
          date = getAscensionDay(currentYear);
          break;
        case "Whit Monday":
          date = getWhitMonday(currentYear);
          break;
        case "National Day (varies by country)":
          date = new Date(currentYear, 6, 14);  // Assuming France's National Day
          break;
        case "Assumption Day":
          date = new Date(currentYear, 7, 15);
          break;
        case "All Saints' Day":
          date = new Date(currentYear, 10, 1);
          break;
        default:
          continue;  // If no matching case, skip
      }
      holidayDates.push(date);
    }
  }
  return holidayDates;
}

// Helper functions for Easter based holidays (Good Friday, Easter Monday)
function getGoodFriday(year) {
  let easter = getEaster(year);
  let goodFriday = new Date(easter.getTime());
  goodFriday.setDate(easter.getDate() - 2);
  return goodFriday;
}

function getEasterMonday(year) {
  let easter = getEaster(year);
  let easterMonday = new Date(easter.getTime());
  easterMonday.setDate(easter.getDate() + 1);
  return easterMonday;
}

function getEaster(year) {
  let f = Math.floor,
      // Golden Number - 1
      G = year % 19,
      C = f(year / 100),
      // Related to Epact
      H = (C - f(C / 4) - f((8 * C + 13) / 25) + 19 * G + 15) % 30,
      // Number of days from 21 March to the Paschal full moon
      I = H - f(H / 28) * (1 - f(29 / (H + 1)) * f((21 - G) / 11)),
      // Weekday for the Paschal full moon
      J = (year + f(year / 4) + I + 2 - C + f(C / 4)) % 7,
      // Number of days from 21 March to the Sunday on or before the Paschal full moon
      L = I - J,
      month = 3 + f((L + 40) / 44),
      day = L + 28 - 31 * f(month / 4);

  return new Date(Date.UTC(year, month - 1, day));
}

// Helper function for Ascension Day and Whit Monday, 39 and 50 days after Easter, respectively
function getAscensionDay(year) {
  let easter = getEaster(year);
  return new Date(easter.setDate(easter.getDate() + 39));
}

function getWhitMonday(year) {
  let easter = getEaster(year);
  return new Date(easter.setDate(easter.getDate() + 50));
}

// Helper function for Victoria Day - last Monday before May 25th
function getVictoriaDay(year) {
  let victoriaDay = new Date(Date.UTC(year, 4, 25));
  while (victoriaDay.getDay() !== 1) { // Loop until Monday
    victoriaDay.setDate(victoriaDay.getDate() - 1);
  }
  return victoriaDay;
}

export function addOvertime(reportCompilerState) {
  let inputFilesArrays = reportCompilerState.inputFilesArrays;
  let payrollInformation = reportCompilerState.payrollInformation;

  let timeEvents = inputFilesArrays.TIME;
  let weeklyOvertime = inputFilesArrays.questions.weeklyOvertime;
  let dailyOvertime = inputFilesArrays.questions.dailyOvertime;
  let dailyDoubleOvertime = inputFilesArrays.questions.dailyDoubleOvertime;
  let consecutiveOvertime = inputFilesArrays.questions.consecutiveOvertime;

  let overtimeRate = inputFilesArrays.questions.overtimeRate;
  let doubleOvertimeRate = inputFilesArrays.questions.doubleOvertimeRate;
  let holidayRate = inputFilesArrays.questions.holidayRate;
  let addOvertimePremiumAmount = inputFilesArrays.questions.addOvertimePremiumAmount;
  
  //let payPeriod = payrollInformation.belPayPeriods;
  //remake settings so we can calucate the pay periods
  // let settings = {
  //   generalSettings: inputFilesArrays.questions.payPeriodObject,
  // }
  //let payPeriod = findPayPeriodFromDatesRan(settings, payrollInformation.belPayPeriods[1]);
  //let payPeriod = payrollInformation.payPeriods;
  if (inputFilesArrays.questions.payPeriodObject.payPeriodExamples.length === 0 || !payrollInformation.belPayPeriods) {
    console.log("No Pay Period to determine overtime");
    return timeEvents;
  }

  let weeks = getFiscalWeeks(inputFilesArrays.questions.payPeriodObject.fiscalWorkweekStartDay, payrollInformation.belPayPeriods);

  // Collect unique staff names
  let staffNames = [...new Set(timeEvents.map(event => event.staffName))];

  for(let t = 0; t < timeEvents.length; t++){
    timeEvents[t].regularHours = timeEvents[t].hoursWorked;
  }

  // Group time events by staff
  let staffTimeEvents = {};
  for (let staffName of staffNames) {
    let staffObject = findStaffObject(staffName, reportCompilerState);
    if(staffObject){
      let staffPrimaryName = staffObject.names[0];
      staffTimeEvents[staffPrimaryName] = timeEvents.filter(event => staffObject.isNamed(event.staffName) && !event.earnings && !event.exception && event.isPunchEvent);
    }
  }

  // Process overtime for each staff member
  for (let staffName of staffNames) {
    let staffObject = findStaffObject(staffName, reportCompilerState);
    let primaryStaffName = staffObject.primaryName;
    let staffEvents = staffTimeEvents[primaryStaffName];

    if(staffEvents === undefined){
      continue;
    }

    // Sort events by clockInDate
    staffEvents.sort((a, b) => new Date(a.clockInDate) - new Date(b.clockInDate));

    calculateOvertimeForStaff(staffEvents, weeks, {
      weeklyOvertime,
      dailyOvertime,
      dailyDoubleOvertime,
      consecutiveOvertime
    });

    addOvertimeRates(reportCompilerState, staffEvents, staffObject, weeks, {
      overtimeRate,
      doubleOvertimeRate,
      holidayRate,
      addOvertimePremiumAmount
    });
  }

  timeEvents = splitTimeEvents(timeEvents, addOvertimePremiumAmount);

  return timeEvents;
}

function findPayPeriodFromDatesRan(payrollInformation, date){
  let payPeriods = findPayPeriods(payrollInformation, date, false);
  return payPeriods;
}

function splitTimeEvents(timeEvents, addOvertimePremiumAmount) {
  let splitEvents = [];

  for (let i = 0; i < timeEvents.length; i++) {
    const event = timeEvents[i];
    if(event.earnings || event.exception || !event.isPunchEvent){
      splitEvents.push(event);
      continue;
    }
    const {
      doubleOvertimeHoursConsecutive,
      doubleOvertimeHoursDaily,
      overtimeHoursConsecutive,
      overtimeHoursWeekly,
      overtimeHoursDaily,
      regularHours,
      ...baseEvent
    } = event;

    let beginingTime = new Date(event.clockInDate);

    // if(event.earnings || event.exception){
    //   event.hoursWorked = -1;
    //   splitEvents.push(event);
    //   continue;
    // }

    if (regularHours > 0) {
      let endTime = new Date(beginingTime.getTime() + regularHours * 60 * 60 * 1000);
      let newDescription = formatTimeRange(beginingTime, endTime) + " Regular";
      let regularEvent = {
        ...baseEvent,
        description: newDescription,
        hoursWorked: roundToNearestThousandth(regularHours)
      };
      splitEvents.push(regularEvent);
      
      beginingTime = new Date(endTime);
    }

    if (overtimeHoursDaily > 0) {
      let endTime = new Date(beginingTime.getTime() + overtimeHoursDaily * 60 * 60 * 1000);
      let newDescription = formatTimeRange(beginingTime, endTime) + " Daily Overtime";
      
      let overtimeEvent = {
        ...baseEvent,
        description: newDescription,
        hoursWorked: roundToNearestThousandth(overtimeHoursDaily),
        payRate: baseEvent.overtimePayRate,
        classification: "Daily Overtime"
      };
      splitEvents.push(overtimeEvent);
      if(addOvertimePremiumAmount){
        let deltaPremiumRate = (baseEvent.regularRateOfPay * 1.5) - baseEvent.overtimePayRate;
        let premiumAmount = deltaPremiumRate * overtimeHoursDaily;
        let premiumEvent = {
          ...baseEvent,
          description: newDescription + " Premium",
          hoursWorked: -1,
          payRate: premiumAmount,
          payTotal: premiumAmount,
          classification: "Daily Overtime",
          exception: true
        };
        splitEvents.push(premiumEvent);
      }

      beginingTime = new Date(endTime);
    }

    if (overtimeHoursWeekly > 0) {
      let endTime = new Date(beginingTime.getTime() + overtimeHoursWeekly * 60 * 60 * 1000);
      let newDescription = formatTimeRange(beginingTime, endTime) + " Weekly Overtime";
      let overtimeEvent = {
        ...baseEvent,
        description: newDescription,
        hoursWorked: roundToNearestThousandth(overtimeHoursWeekly),
        payRate: baseEvent.overtimePayRate,
        classification: "Weekly Overtime"
      };
      splitEvents.push(overtimeEvent);
      if(addOvertimePremiumAmount){
        let deltaPremiumRate = (baseEvent.regularRateOfPay * 1.5) - baseEvent.overtimePayRate;
        let premiumAmount = deltaPremiumRate * overtimeHoursWeekly;
        let premiumEvent = {
          ...baseEvent,
          description: newDescription + " Premium",
          hoursWorked: -1,
          payRate: premiumAmount,
          payTotal: premiumAmount,
          classification: "Weekly Overtime",
          exception: true
        };
        splitEvents.push(premiumEvent);
      }
      beginingTime = new Date(endTime);
    }

    if (overtimeHoursConsecutive > 0) {
      let endTime = new Date(beginingTime.getTime() + overtimeHoursConsecutive * 60 * 60 * 1000);
      let newDescription = formatTimeRange(beginingTime, endTime) + " Consecutive Overtime";
      let overtimeEvent = {
        ...baseEvent,
        description: newDescription,
        hoursWorked: roundToNearestThousandth(overtimeHoursConsecutive),
        payRate: baseEvent.overtimePayRate,
        classification: "Consecutive Overtime"
      };
      splitEvents.push(overtimeEvent);
      if(addOvertimePremiumAmount){
        let deltaPremiumRate = (baseEvent.regularRateOfPay * 1.5) - baseEvent.overtimePayRate;
        let premiumAmount = deltaPremiumRate * overtimeHoursConsecutive;
        let premiumEvent = {
          ...baseEvent,
          description: newDescription + " Premium",
          hoursWorked: -1,
          payRate: premiumAmount,
          payTotal: premiumAmount,
          classification: "Consecutive Overtime",
          exception: true
        };
        splitEvents.push(premiumEvent);
      }
      beginingTime = new Date(endTime);
    }

    if (doubleOvertimeHoursConsecutive > 0) {
      let endTime = new Date(beginingTime.getTime() + doubleOvertimeHoursConsecutive * 60 * 60 * 1000);
      let newDescription = formatTimeRange(beginingTime, endTime) + " Consecutive Double Overtime";
      let doubleOvertimeEvent = {
        ...baseEvent,
        description: newDescription,
        hoursWorked: roundToNearestThousandth(doubleOvertimeHoursConsecutive),
        payRate: baseEvent.doubleOvertimePayRate,
        classification: "Consecutive Double Overtime"
      };
      splitEvents.push(doubleOvertimeEvent);
      if(addOvertimePremiumAmount){
        let deltaPremiumRate = (baseEvent.regularRateOfPay * 2) - baseEvent.doubleOvertimePayRate;
        let premiumAmount = deltaPremiumRate * doubleOvertimeHoursConsecutive;
        let premiumEvent = {
          ...baseEvent,
          description: newDescription + " Premium",
          hoursWorked: -1,
          payRate: premiumAmount,
          payTotal: premiumAmount,
          classification: "Consecutive Double Overtime",
          exception: true
        };
        splitEvents.push(premiumEvent);
      }

      beginingTime = new Date(endTime);
    }

    if (doubleOvertimeHoursDaily > 0) {
      let endTime = new Date(beginingTime.getTime() + doubleOvertimeHoursDaily * 60 * 60 * 1000);
      let newDescription = formatTimeRange(beginingTime, endTime) + " Daily Double Overtime";
      let doubleOvertimeEvent = {
        ...baseEvent,
        description: newDescription,
        hoursWorked: roundToNearestThousandth(doubleOvertimeHoursDaily),
        payRate: baseEvent.doubleOvertimePayRate,
        classification: "Daily Double Overtime"
      };
      splitEvents.push(doubleOvertimeEvent);
      if(addOvertimePremiumAmount){
        let deltaPremiumRate = (baseEvent.regularRateOfPay * 2) - baseEvent.doubleOvertimePayRate;
        let premiumAmount = deltaPremiumRate * doubleOvertimeHoursDaily;
        let premiumEvent = {
          ...baseEvent,
          description: newDescription + " Premium",
          hoursWorked: -1,
          payRate: premiumAmount,
          payTotal: premiumAmount,
          classification: "Daily Double Overtime",
          exception: true
        };
        splitEvents.push(premiumEvent);
      }

      beginingTime = new Date(endTime);
    }
  }

  return splitEvents;
}

function roundToNearestThousandth(num) {
  return Math.round(num * 1000) / 1000;
}

function formatTimeRange(beginningTime, endTime) {
  const dateOptions = { weekday: 'short', year: 'numeric', month: 'numeric', day: 'numeric' };
  const timeOptions = { hour: 'numeric', minute: 'numeric', hour12: true };

  // Format the full date and time for the beginning to extract the date part
  const fullBeginningFormat = beginningTime.toLocaleString('en-US', { ...dateOptions, ...timeOptions });
  const [day, date, startTime] = fullBeginningFormat.split(', ');

  // Format only the time for the end
  const endTimeOnly = endTime.toLocaleString('en-US', timeOptions);

  // Combine into the final format, adding a comma after the day
  return `${day}, ${date} (${startTime}-${endTimeOnly})`;
}

function addOvertimeRates(reportCompilerState, timeEvents, staffObj, weeks, overtimeRateRules){
  
  for(let i = 0; i < timeEvents.length; i++){
    if(timeEvents[i].overtimeHoursDaily > 0 || timeEvents[i].overtimeHoursWeekly > 0 || timeEvents[i].overtimeHoursConsecutive > 0){
      let week = weeks[getWeekIndex(new Date(timeEvents[i].clockInDate), weeks)];
      let regularRateOfPay = getRegularRateOfPay(reportCompilerState, timeEvents, timeEvents[i].staffName, week);
      let overtimeRate = 0;
      if(overtimeRateRules.overtimeRate === '1.5x Hourly Rate'){
        overtimeRate = 1.5 * staffObj.getHourlyRate(timeEvents[i].location);
      } else if(overtimeRateRules.overtimeRate === '1.5x Regular Rate'){
        overtimeRate = 1.5 * regularRateOfPay;
      } else if(overtimeRateRules.overtimeRate === 'Overtime Rate'){
        overtimeRate = 1.5 * staffObj.getProperty(timeEvents[i].location, "overtime");
      } else {
        overtimeRate = 0;
      }
      timeEvents[i].overtimePayRate = overtimeRate ?? 0;
      timeEvents[i].regularRateOfPay = regularRateOfPay;
    }

    if(timeEvents[i].doubleOvertimeHoursDaily > 0 || timeEvents[i].doubleOvertimeHoursConsecutive > 0){
      let week = weeks[getWeekIndex(new Date(timeEvents[i].clockInDate), weeks)];
      let regularRateOfPay = getRegularRateOfPay(reportCompilerState, timeEvents, timeEvents[i].staffName, week);
      let doubleOvertimeRate = 0;
      if(overtimeRateRules.doubleOvertimeRate === '2x Hourly Rate'){
        doubleOvertimeRate = 2 * staffObj.getHourlyRate(timeEvents[i].location);
      } else if(overtimeRateRules.doubleOvertimeRate === '2x Regular Rate'){
        doubleOvertimeRate = 2 * regularRateOfPay;
      } else if(overtimeRateRules.doubleOvertimeRate === 'Double Overtime Rate'){
        doubleOvertimeRate = 2 * staffObj.getProperty(timeEvents[i].location, "doubleOvertime");
      } else {
        doubleOvertimeRate = 0;
      }
      timeEvents[i].doubleOvertimePayRate = doubleOvertimeRate ?? 0;
      timeEvents[i].regularRateOfPay = regularRateOfPay;
    }
  }
}

function getRegularRateOfPay(reportCompilerState, timeEvents, staffName, week) {
  let staffObject = findStaffObject(staffName, reportCompilerState);
  if(!staffObject){
    return 0;
  }

  // Convert week start and end dates to UTC
  let weekStart = new Date(week.startDate + 'T00:00:00Z');
  let weekEnd = new Date(week.endDate + 'T23:59:59Z');

  let hours = 0;
  let pay = 0;

  let hourlyPay = 0;
  let sessionPay = 0;
  let commissionPay = 0;

  // Calculate pay for hourly work
  for (let i = 0; i < timeEvents.length; i++) {
    let clockInDate = new Date(timeEvents[i].clockInDate);
    if (staffObject.isNamed(timeEvents[i].staffName) && clockInDate >= weekStart && clockInDate <= weekEnd) {
      hours += timeEvents[i].hoursWorked;
      hourlyPay += timeEvents[i].hoursWorked * timeEvents[i].payRate;
    }
  }

  // Calculate pay for classes
  let classes = reportCompilerState.studiosInformation.classes;
  for (let i = 0; i < classes.length; i++) {
    let classDate = new Date(classes[i].date);
    if (staffObject.isNamed(classes[i].instructor) && classDate >= weekStart && classDate <= weekEnd) {
      sessionPay += classes[i].pay;
    }
  }

  // Calculate commissions
  let commissions = reportCompilerState.studiosInformation.commissions;
  for (let i = 0; i < commissions.length; i++) {
    let commissionDate = new Date(commissions[i].dateSold);
    if (commissionDate >= weekStart && commissionDate <= weekEnd) {
      if (staffObject.isNamed(commissions[i].salespeople.open)) {
        commissionPay += commissions[i].salespay.open;
      }
      if (staffObject.isNamed(commissions[i].salespeople.close)) {
        commissionPay += commissions[i].salespay.close;
      }
      if (staffObject.isNamed(commissions[i].salespeople.instructor)) {
        commissionPay += commissions[i].salespay.instructor;
      }
      if (staffObject.isNamed(commissions[i].salespeople.secondary)) {
        commissionPay += commissions[i].salespay.secondary;
      }
    }
  }

  pay = (isNaN(hourlyPay) ? 0 : hourlyPay) + (isNaN(sessionPay) ? 0 : sessionPay) + (isNaN(commissionPay) ? 0 : commissionPay);

  return hours > 0 ? pay / hours : 0; // Return 0 if no hours to avoid division by zero
}

function calculateOvertimeForStaff(staffEvents, weeks, overtimeRules) {
  let { dailyOvertime, dailyDoubleOvertime, weeklyOvertime, consecutiveOvertime } = overtimeRules;

  let dailyEvents = {};
  let dailyTotals = {};

  if(dailyOvertime && dailyOvertime !== -1){
    // Group events by day
    for (let event of staffEvents) {
      let dateStr = new Date(event.clockInDate).toISOString().split('T')[0];
      if (!dailyEvents[dateStr]) {
        dailyEvents[dateStr] = [];
        dailyTotals[dateStr] = 0;
      }
      dailyEvents[dateStr].push(event);
      let hours = (new Date(event.clockOutDate) - new Date(event.clockInDate)) / (1000 * 60 * 60);
      dailyTotals[dateStr] += hours;
    }

    // Process daily overtime
    for (let dateStr in dailyEvents) {
      let eventsOnDay = dailyEvents[dateStr];
      let totalHours = dailyTotals[dateStr];
      processDailyOvertime(eventsOnDay, totalHours, dailyOvertime, dailyDoubleOvertime);
    }
  }

  if (consecutiveOvertime && consecutiveOvertime !== -1) {
    for(let i = 0; i < weeks.length; i++){
      let consecutiveGoal = 7;
      let fiscalWeek = weeks[i];
      let fiscalStartDate = new Date(fiscalWeek.startDate);
      let fiscalEndDate = new Date(fiscalWeek.endDate);

      let currentDate = new Date(fiscalStartDate);
      let consecutiveCount = 0;
      let datesWithEvents = [];

      while(currentDate <= fiscalEndDate){
        let dateStr = currentDate.toISOString().split('T')[0];
        if(dailyEvents[dateStr]){
          consecutiveCount++;
          datesWithEvents.push(dateStr);
          if(consecutiveCount === consecutiveGoal){
            // This is the 7th day and all previous days had events
            applyOvertimeForConsecutiveDays(
              datesWithEvents,
              dailyEvents,
              consecutiveOvertime
            );
            break;
          }
        } else {
          consecutiveCount = 0;
          datesWithEvents = [];
        }
        currentDate.setDate(currentDate.getDate() + 1); // Move to the next day
      }
    }
  }

  if (weeklyOvertime && weeklyOvertime !== -1) {
    // Group events by week
    let weeklyEvents = {};
    let weeklyTotals = {};
    
    // Step 1: Calculate total hours worked per week
    for (let event of staffEvents) {
        let weekIndex = getWeekIndex(new Date(event.clockInDate), weeks);
        if (weekIndex === -1) continue; // Skip if event is not within any week
        if (!weeklyEvents[weekIndex]) {
            weeklyEvents[weekIndex] = [];
            weeklyTotals[weekIndex] = 0;
        }
        let totalEventHours = event.hoursWorked;
        weeklyEvents[weekIndex].push(event);
        weeklyTotals[weekIndex] += totalEventHours;
    }

    // Step 2: Process weekly overtime
    for (let weekIndex in weeklyEvents) {
        let eventsInWeek = weeklyEvents[weekIndex];

        // Calculate total regular hours in the week (after daily overtime has been applied)
        let totalRegularHoursInWeek = eventsInWeek.reduce((sum, event) => sum + event.regularHours, 0);

        // Check if total regular hours exceed 40
        if (weeklyOvertime && totalRegularHoursInWeek > weeklyOvertime) {
            let weeklyOvertimeHoursToAssign = totalRegularHoursInWeek - weeklyOvertime;

            // Step 3: Assign weekly overtime from remaining regular hours (start from last event)
            for (let i = eventsInWeek.length - 1; i >= 0; i--) {
                if (weeklyOvertimeHoursToAssign <= 0) break; // Stop if all weekly overtime is assigned
                let event = eventsInWeek[i];
                let availableRegularHours = event.regularHours;
                
                // Assign weekly overtime from remaining regular hours
                if (availableRegularHours > 0) {
                    let assignedWeeklyOvertimeHours = Math.min(availableRegularHours, weeklyOvertimeHoursToAssign);
                    event.regularHours -= assignedWeeklyOvertimeHours;
                    event.overtimeHoursWeekly += assignedWeeklyOvertimeHours;
                    weeklyOvertimeHoursToAssign -= assignedWeeklyOvertimeHours;
                }
            }
        }
    }
}

}

function applyOvertimeForConsecutiveDays(dates, dailyEvents, overtimeThreshold) {
  let lastDay = dates[dates.length - 1];
  let eventsOnLastDay = dailyEvents[lastDay];

  let remainingRegularHoursForOvertime = overtimeThreshold;  // Default to 8 hours for overtime
  
  for (let event of eventsOnLastDay) {
      let regularHours = event.regularHours;

      // Apply consecutive overtime (first 8 hours)
      if (remainingRegularHoursForOvertime > 0) {
          let overtimeToAssign = Math.min(regularHours, remainingRegularHoursForOvertime);
          event.overtimeHoursConsecutive += overtimeToAssign; // Assign overtime for the first 8 hours
          remainingRegularHoursForOvertime -= overtimeToAssign; // Decrease overtime threshold
          regularHours -= overtimeToAssign;  // Remove assigned hours from regular
      }

      // Assign remaining hours (if any) to double overtime
      if (regularHours > 0) {
          event.doubleOvertimeHoursConsecutive += regularHours; // Any hours above 8 are double overtime
          event.regularHours = 0;  // All regular hours are now accounted for
      }
  }
}

// function getWeekIndex(date, weeks) {
//   date = new Date(date); // Ensuring the input is a Date object
//   for (let i = 0; i < weeks.length; i++) {
//     let weekStart = new Date(weeks[i].startDate); // Assuming start at midnight UTC of startDate
//     let weekEnd = new Date(weeks[i].endDate); // Assuming end just before midnight UTC of endDate

//     // Set payPeriodStart to the beginning of the day
//     weekStart.setHours(0, 0, 0, 0);

//     // Set payPeriodEnd to the end of the day
//     weekEnd.setHours(23, 59, 59, 999);

//     if (date >= weekStart && date <= weekEnd) {
//       return i;
//     }
//   }
//   return -1; // Date is not within any week
// }

function getWeekIndex(date, weeks) {
  date = new Date(date); // date comes in ISO format with UTC timezone

  for (let i = 0; i < weeks.length; i++) {
    // Parse the start and end dates as local dates at the beginning and end of each day
    let weekStart = new Date(weeks[i].startDate + 'T00:00:00'); // Convert to local start of the day
    let weekEnd = new Date(weeks[i].endDate + 'T23:59:59'); // Convert to local end of the day

    // Adjust to ensure dates are compared in the same time zone context
    // weekStart = new Date(weekStart.getTime() + (weekStart.getTimezoneOffset() * 60000));
    // weekEnd = new Date(weekEnd.getTime() + (weekEnd.getTimezoneOffset() * 60000));

    if (date >= weekStart && date <= weekEnd) {
      return i;
    }
  }
  return -1; // Date is not within any week
}

function processDailyOvertime(eventsOnDay, totalHours, dailyOvertime, dailyDoubleOvertime) {
  let regularHoursLimit = dailyOvertime || 8;  // Default to 8 hours
  let doubleOvertimeLimit = dailyDoubleOvertime || 12;  // Default to 12 hours

  let regularHours = Math.min(totalHours, regularHoursLimit);  // First 8 hours
  let overtimeHoursDaily = Math.min(Math.max(0, totalHours - regularHoursLimit), doubleOvertimeLimit - regularHoursLimit);  // Hours between 8 and 12
  let doubleOvertimeHoursDaily = Math.max(0, totalHours - doubleOvertimeLimit);  // Hours over 12

  let remainingRegularHours = regularHours;
  let remainingOvertimeHours = overtimeHoursDaily;
  let remainingDoubleOvertimeHours = doubleOvertimeHoursDaily;

  for (let event of eventsOnDay) {
    let eventHours = (new Date(event.clockOutDate) - new Date(event.clockInDate)) / (1000 * 60 * 60);
    event.regularHours = 0;
    event.overtimeHoursDaily = 0;
    event.doubleOvertimeHoursDaily = 0;

    // Assign regular hours
    if (remainingRegularHours > 0) {
      let assignedRegularHours = Math.min(eventHours, remainingRegularHours);
      event.regularHours = assignedRegularHours;
      remainingRegularHours -= assignedRegularHours;
      eventHours -= assignedRegularHours;
    }

    // Assign overtime hours
    if (eventHours > 0 && remainingOvertimeHours > 0) {
      let assignedOvertimeHours = Math.min(eventHours, remainingOvertimeHours);
      event.overtimeHoursDaily = assignedOvertimeHours;
      remainingOvertimeHours -= assignedOvertimeHours;
      eventHours -= assignedOvertimeHours;
    }

    // Assign double overtime hours
    if (eventHours > 0 && remainingDoubleOvertimeHours > 0) {
      let assignedDoubleOvertimeHours = Math.min(eventHours, remainingDoubleOvertimeHours);
      event.doubleOvertimeHoursDaily = assignedDoubleOvertimeHours;
      remainingDoubleOvertimeHours -= assignedDoubleOvertimeHours;
      eventHours -= assignedDoubleOvertimeHours;
    }

    // If any remaining hours are left unaccounted for (in rare cases)
    if (eventHours > 0) {
      event.regularHours += eventHours;  // Assign as regular
    }
  }
}


function getFiscalWeeks(fiscalWorkweekStartDay, payPeriod) {
  const dayNames = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
  const fiscalStartDayIndex = dayNames.indexOf(fiscalWorkweekStartDay);

  let payPeriodStart = new Date(payPeriod[0]);
  let payPeriodEnd = new Date(payPeriod[1]);

  // Set payPeriodStart to the beginning of the day
  payPeriodStart.setHours(0, 0, 0, 0);

  // Set payPeriodEnd to the end of the day
  payPeriodEnd.setHours(23, 59, 59, 999);

  // Adjust the start date to the beginning of the first full fiscal week within the pay period
  while (payPeriodStart.getDay() !== fiscalStartDayIndex) {
    payPeriodStart.setDate(payPeriodStart.getDate() + 1);
  }

  let weeks = [];
  let currentWeekStart = new Date(payPeriodStart);
  
  // Make sure we start the week calculation from the start date of the pay period
  if (currentWeekStart > new Date(payPeriod[0])) {
    currentWeekStart.setDate(currentWeekStart.getDate() - 7); // Step back to include the incomplete starting week
  }

  while (currentWeekStart <= payPeriodEnd) {
    let currentWeekEnd = new Date(currentWeekStart);
    currentWeekEnd.setDate(currentWeekEnd.getDate() + 6); // Complete a 7-day cycle

    let isComplete = true;
    if (currentWeekEnd > payPeriodEnd) {
      currentWeekEnd = new Date(payPeriodEnd); // Adjust the end date for the last incomplete week
      isComplete = false;
    }

    // Determine if the week is complete
    const daysDiff = (currentWeekEnd - currentWeekStart) / (1000 * 60 * 60 * 24);
    if (daysDiff < 6) { // Less than 7 days (0-based index: 0 to 6 is 7 days)
      isComplete = false;
    }

    function formatDate(date) {
      return date.toISOString().split('T')[0];
    }

    weeks.push({
      startDate: formatDate(currentWeekStart),
      endDate: formatDate(currentWeekEnd),
      complete: isComplete
    });

    // Set up the next week
    currentWeekStart.setDate(currentWeekStart.getDate() + 7);
  }

  return weeks;
}

export function checkLocation(staffObj, location) {
  return (
    location === "Any" || location === "All" || staffObj.isLocated(location)
  );
}

export function findStaffObject(name, reportCompilerState) {
  let staffArray = reportCompilerState.studiosInformation.staffArray;
  for (let i = 0; i < staffArray.length; i++) {
    if (staffArray[i].isNamed(name)) {
      return staffArray[i];
    }
  }

  return false;
}

export function getStaff(staffArray, reportCompilerState) {
  let staffFound = reportCompilerState.studiosInformation["staffArray"];
  if (staffArray.length === 0) {
    let allStaff = staffFound.map((staff) => staff.primaryName);
    return allStaff;
  }
  return staffArray;
}

export function getLocations(locationArray, reportCompilerState) {
  if (locationArray.length === 0) {
    return reportCompilerState.payrollInformation["studiosInInput"];
  }
  return locationArray;
}

export function checkDay(timeException) {
  var today = new Date();
  let day = parseInt(timeException.payLogic.day.replace(/\D/g, ""));
  if (day === null) {
    return true;
  }
  if (timeException.payLogic.sequence === "ANY DATE") {
    return true;
  } else if (timeException.payLogic.sequence === "Before") {
    if (today.getDate() < day) {
      return true;
    }
  } else if (timeException.payLogic.sequence === "After") {
    if (today.getDate() > day) {
      return true;
    }
  }
  return false;
}

function checkIfArrayContainsStaff(staffArray, staffName, reportCompilerState) {
  for (let i = 0; i < staffArray.length; i++) {
    let staffObject = findStaffObject(staffArray[i], reportCompilerState);
    if (staffObject && staffObject.isNamed(staffName)) {
      return true;
    }
  }
  return false;
}

export function checkQualifications(
  timeEvent,
  timeException,
  reportCompilerState
) {
  let staffArray = getStaff(timeException.staff, reportCompilerState);
  let locations = getLocations(timeException.location, reportCompilerState);
  if (timeEvent?.staffName) {
    if (
      !checkIfArrayContainsStaff(
        staffArray,
        timeEvent.staffName,
        reportCompilerState
      )
    ) {
      //if (!staffArray.includes(timeEvent.staffName)) {
      return false;
    }

    if (!locations.includes(timeEvent.location)) {
      return false;
    }
    if (
      timeEvent.description !== timeException.type &&
      timeException.type !== "" &&
      timeException.type !== "Default"
    ) {
      return false;
    }
    if (
      parseFloat(timeEvent.hoursWorked) !== parseFloat(timeException.hours) &&
      parseFloat(timeException.hours) !== -1
    ) {
      return false;
    }
    if (
      parseFloat(timeEvent.payRate) !== parseFloat(timeException.rate) &&
      parseFloat(timeException.rate) !== -1
    ) {
      return false;
    }
    if (
      parseFloat(timeEvent.payTotal) !== parseFloat(timeException.total) &&
      parseFloat(timeException.total) !== -1
    ) {
      return false;
    }
    if (
      timeEvent.comment !== timeException.comment &&
      timeException.comment !== ""
    ) {
      return false;
    }
    if (
      timeException.payLogic.sequence !== "ANY DATE" &&
      !checkDay(timeException)
    ) {
      return false;
    }
  } else {
    console.error("timeEvent is undefined");
  }

  return true;
}

export function getFirstNumericKey(obj) {
  for (var key in obj) {
    if (!isNaN(Number(key))) {
      return key;
    }
  }
  return null; // Return null if no numeric key is found
}

export function removeStringFromArray(array, stringToRemove) {
  const index = array.indexOf(stringToRemove);
  if (index !== -1) {
    array.splice(index, 1);
  }

  return array;
}

export function getSecondaryPageFromUrl() {
  // Get the current pathname from the window's location
  const pathname = window.location.pathname;

  // Split the pathname into segments using '/' as the delimiter
  const segments = pathname.split("/");

  // Find the segment following "studio-settings"
  const index = segments.indexOf("studio-setting");
  if (index !== -1 && index < segments.length - 1) {
    // The segment following "studio-settings" is at index + 1
    return segments[index + 1];
  } else {
    return "studios"; // Return null if no segment found after 'studio-settings'
  }
}

export function formatDate(date) {
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const day = date
    .getDate()
    .toString()
    .padStart(2, "0");
  const year = date.getFullYear();
  return `${month}-${day}-${year}`;
}

export async function uploadFileToS3(
  file,
  studioName,
  uid,
  payPeriodStartDate,
  payPeriodEndDate,
  fileNameWithExtension
) {




  try {
    const token = await getFirebaseJwtToken();
    // Use the token in your API call or other logic
    // Check if 'file' is a real file
    if (!(file instanceof File)) {
      console.error("Invalid file parameter. 'file' must be a File object.");
      return;
    }
    const data = await file.arrayBuffer();
    try {
      // Make a request to your Lambda function to get the pre-signed POST URL
      const startDate = formatDate(new Date(payPeriodStartDate));
      const endDate = formatDate(new Date(payPeriodEndDate));
      const payPeriod = startDate + "_" + endDate;

      const myAPI = "paywellAPIResource";
      const path = "/createPresignedPost";
      const apiEvent = {
        headers: {
          'Authorization': `Bearer ${token}`
        },
        body: {
          Bucket: "paywell-user-runs",
          studio: studioName,
          // fileName: file.name,
          fileName: fileNameWithExtension,
          payPeriod: payPeriod
        },
        queryStringParameters: {
          Bucket: "paywell-user-runs",
          studio: studioName,
          // fileName: file.name,
          fileName: fileNameWithExtension,
          payPeriod: payPeriod
        },
      };
      var s3FormData;
      try {
        s3FormData = await API.post(myAPI, path, apiEvent);
      } catch (e) {
        console.error("Failed to fetch pre-signed POST URL");
        return;
      }

      // Create a FormData object with the S3 POST fields and values
      const formData = new FormData();
      for (const key in s3FormData.fields) {
        formData.append(key, s3FormData.fields[key]);
      }
      formData.append("file", new Blob([data], { type: file.type }));

      // Use fetch to send the file to S3 using the pre-signed POST URL
      const uploadResponse = await fetch(s3FormData.url, {
        method: "POST",
        body: formData,
      });

      if (uploadResponse.ok) {
        // File uploaded successfully, you can handle the response as needed
      } else {
        console.error("File upload failed.");
      }
    } catch (error) {
      console.error("Error uploading file:", error);
    }
  } catch (error) {
    console.error('Error in someHelperFunction:', error);
    // Handle errors, possibly related to token fetching
  }


}
export function getSubstringBeforeFirstDot(str) {
  
  // Split the string at the first occurrence of "."
  const parts = str.split(".");
  // Return the first element which contains the substring before the first dot
  return parts[0];
}
export function removeSubstringBeforeFirstDot(str) {
  // Count the number of dots in the string
  const dotCount = str.split(".").length - 1;
  
  // If there is only one dot, return the original string unchanged
  if (dotCount === 1) {
      return str;
  }
  
  // If there are multiple dots, find the index of the first dot
  const dotIndex = str.indexOf(".");
  
  // Return everything after the first dot
  if (dotIndex !== -1) {
      return str.slice(dotIndex + 1); // +1 to exclude the dot itself
  }
  
  // If no dot is found (for completeness), return the original string
  return str;
}
export async function uploadSessionSnapshotToS3(
  file,
  studioName,
  uid,
  payPeriodStartDate,
  payPeriodEndDate,
  fileName = "", // Add a parameter for the file name
  isSessionSnapshotUpload = false
) {

  try {
    const token = await getFirebaseJwtToken();
    // Use the token in your API call or other logic
    // Check if 'file' is a real file, Blob, or neither
    if (!(file instanceof File || file instanceof Blob)) {
      console.error("Invalid file parameter. 'file' must be a File or Blob object.");
      return;
    }

    // If 'file' is a File, convert it to a Blob and use its name
    if (file instanceof File) {
      file = new Blob([await file.arrayBuffer()], { type: file.type });
      fileName = file.name; // Use the name of the File object
    }


    try {
      // Make a request to your Lambda function to get the pre-signed POST URL
      const startDate = formatDate(new Date(payPeriodStartDate));
      const endDate = formatDate(new Date(payPeriodEndDate));
      const payPeriod = startDate + "_" + endDate;

      const myAPI = "paywellAPIResource";
      const path = "/createPresignedPost";
      const apiEvent = {
        headers: {
          'Authorization': `Bearer ${token}`
        },
        body: {
          Bucket: "paywell-user-runs",
          // uid, // Admin only
          studio: studioName,
          fileName: fileName,
          payPeriod: payPeriod,
          isSessionSnapshotUpload
        },
        queryStringParameters: {
          Bucket: "paywell-user-runs",
          // uid, // Admin only
          studio: studioName,
          fileName: fileName,
          payPeriod: payPeriod,
          isSessionSnapshotUpload
        },
      };

      var s3FormData;

      try {
        s3FormData = await API.post(myAPI, path, apiEvent);
      } catch (e) {
        console.error("Failed to fetch pre-signed POST URL");
        return;
      }

      // Create a FormData object with the S3 POST fields and values
      const formData = new FormData();
      for (const key in s3FormData.fields) {
        formData.append(key, s3FormData.fields[key]);
      }
      formData.append("file", file); // Append the Blob or File here

      // Use fetch to send the file to S3 using the pre-signed POST URL
      const uploadResponse = await fetch(s3FormData.url, {
        method: "POST",
        body: formData,
      });

      if (uploadResponse.ok) {
        // File uploaded successfully, you can handle the response as needed
        // You can return or handle the successful upload response here.
        return;
      } else {
        console.error("File upload failed.");
        // Handle the failed upload response or throw an error.
        throw new Error("File upload failed.");
      }
    } catch (error) {
      console.error("Error uploading file:", error);
      // Handle the error appropriately or rethrow it if needed.
      throw error;
    }
  } catch (error) {
    console.error('Error in someHelperFunction:', error);
    // Handle errors, possibly related to token fetching
  }

}

export async function sendToS3(
  file,
  key
) {
  try {
    const token = await getFirebaseJwtToken();
    // Use the token in your API call or other logic
    try {
      // Make a request to your Lambda function to get the pre-signed POST URL
      const myAPI = "paywellAPIResource";
      const path = "/createS3UploadUrl";
      const apiEvent = {
        headers: {
          'Authorization': `Bearer ${token}`
        },
        body: {

          Bucket: process.env.REACT_APP_PAYWELL_RUNS_BUCKET_NAME,
          Key: key
        },
        queryStringParameters: {
          Bucket: process.env.REACT_APP_PAYWELL_RUNS_BUCKET_NAME,
          Key: key
        },
      };

      var s3FormData;

      try {
        s3FormData = await API.post(myAPI, path, apiEvent);
      } catch (e) {
        console.error("Failed to fetch pre-signed POST URL");
        return;
      }

      // Create a FormData object with the S3 POST fields and values
      const formData = new FormData();
      for (const key in s3FormData.fields) {
        formData.append(key, s3FormData.fields[key]);
      }
      formData.append("file", file); // Append the Blob or File here

      // Use fetch to send the file to S3 using the pre-signed POST URL
      const uploadResponse = await fetch(s3FormData.url, {
        method: "POST",
        body: formData,
      });

      if (uploadResponse.ok) {
        // File uploaded successfully, you can handle the response as needed
        // You can return or handle the successful upload response here.
        return;
      } else {
        console.error("File upload failed.");
        // Handle the failed upload response or throw an error.
        throw new Error("File upload failed.");
      }
    } catch (error) {
      console.error("Error uploading file:", error);
      // Handle the error appropriately or rethrow it if needed.
      throw error;
    }
  } catch (error) {
    console.error('Error in someHelperFunction:', error);
    // Handle errors, possibly related to token fetching
  }

}

export async function uploadTestOutput(latestSessionPath, outputWorkbook, outputFileName = false) {
  outputFileName = outputFileName || "test-output.xls";
  // ~~~~ need to think of way to decipher between live run and auto test run. one will upload initial session output while test will append the newly tested output.

  // GET LATEST SESSION PATH
  const currentDate = new Date().toLocaleDateString().replace(/\//g, '-');
  const currentTime = new Date().toLocaleTimeString().replace(/:/g, '-');
  const datetime = `${currentDate}_${currentTime}`;


  // GET OUTPUT FILE
  const wbout = XLSX.write(outputWorkbook, {
    bookType: "xlsx",
    type: "array"
  });
  const excelBlob = new Blob([wbout], {
    type: "application/octet-stream"
  });
  const testOutputS3Path = `${latestSessionPath}session_testing/session_test_${datetime}/${outputFileName}`;
  await sendToS3(excelBlob, testOutputS3Path);

  return testOutputS3Path;
}


