import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import IconButton from "@mui/material/IconButton";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import "./modal.scss";

export default function BasicModal(props) {
  const [open, setOpen] = React.useState(props.referral ?? false);
  const handleOpen = () => {
    setOpen(true);
    props.handleOnOpen ? props.handleOnOpen() : null;
  };
  const handleClose = () => {
    setOpen(false);
    props.handleClose ? props.handleOnClose() : null;
  };

  return props.referral ? (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box className={`modal-popup modal-popup--referral`}>
        <IconButton
          size="large"
          color="primary"
          className="modal-popup__button"
          aria-label="close"
          onClick={handleClose}
        >
          <CloseRoundedIcon fontSize="inherit" />
        </IconButton>
        <div className="modal-popup__background-image"></div>
        <Typography className="modal-popup__h5" variant="h5">
          Liam, wants your to try PayWell. Get 1 month free after you run
          payroll.
          {props.params.refereeEmail ? <span>Continue as:</span> : null}
        </Typography>

        <Button onClick={handleClose} variant="contained">
          {props.params.refereeEmail ?? "Sign Up"}
        </Button>
      </Box>
    </Modal>
  ) : (
    <div>
      <Button disabled={props.disabled ?? false} onClick={handleOpen}>
        {props.openIcon ?? "Configure"}
      </Button>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          className={`modal-popup modal-popup${props.isVideo ? "--video" : ""}`}
        >
          <IconButton
            size="large"
            color="primary"
            className="modal-popup__button"
            aria-label="close"
            onClick={handleClose}
          >
            <CloseRoundedIcon fontSize="inherit" />
          </IconButton>
          {props.children ?? "empty"}
        </Box>
      </Modal>
    </div>
  );
}
