import React, { useState, useReducer } from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import Avatar from "@mui/material/Avatar";
import List from "@mui/material/List";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import { ListItem } from "@mui/material";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import PersonIcon from "@mui/icons-material/Person";
import AddIcon from "@mui/icons-material/Add";
import { blue } from "@mui/material/colors";
import ErrorIcon from "@mui/icons-material/Error";
import TextField from "@mui/material/TextField";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import { useClubReady } from "../../contexts/ClubReadyContext";
import { useStudioScrapeStatus } from "../../contexts/StudioScrapeStatus/StudioScrapeStatusContext";
import { LoadingButton } from "@mui/lab";
import IconButton from "@mui/material/IconButton";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import { useAuth } from "../../contexts/AuthContext";
import Badge from "@mui/material/Badge";
import "./clubReadyAccountSelector.scss";
import PersonRoundedIcon from "@mui/icons-material/PersonRounded";
import Tooltip from "@mui/material/Tooltip";
import ListItemIcon from "@mui/material/ListItemIcon";
import Collapse from "@mui/material/Collapse";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import StoreIcon from "@mui/icons-material/Store";
import CircularProgress from "@mui/material/CircularProgress";
import { useSettingsContext } from "../../contexts/SettingsContext";
import { getUrlVariableValue } from "../../utility-functions/utility-functions.js";
import { useSnackBar } from "../../contexts/SnackBarContext/SnackBarContext";

function containsSpecialChars(s) {
  for (let i = 0; i < s.length; i++) {
    const c = s.charAt(i);
    if (
      c.charCodeAt(0) < 32 ||
      c.charCodeAt(0) == 127 ||
      c === "." ||
      c === "$" ||
      c === "#" ||
      c === "[" ||
      c === "]" ||
      c === "/"
    ) {
      return true;
    }
  }
  return false;
}

function NestedListItem(props) {
  const showSnackBar = useSnackBar();
  const { getUID } = useAuth();
  const {
    scrapeAccountsDispatch,
    deleteSecretManagerCredential
  } = useClubReady();

  const { username, credentialId, studios } = props.account;
  
  const { disabled } = props;

  const [open, setOpen] = React.useState(false);
  const handleClick = () => {
    setOpen(!open);
  };

  // const handleDeleteAccount = async (credentialId) => {
  //   await deleteSecretManagerCredential(await getUID(), 'clubready', credentialId, username);

  //   const isDeleted = await deleteClubReadyLogin(credentialId, username); // ~~~~ remove from aws secret
  //   if (isDeleted === true) {
  //     showSnackBar(`Successfully Deleted ${credentialId}`, "success");
  //     // handleSetSnackbar("success", `Successfully Deleted ${credentialId}`);
  //     scrapeAccountsDispatch({
  //       type: "REMOVE_LOGIN",
  //       username: username,
  //       credentialId: credentialId,
  //       uid: getUID(),
  //     });
  //   }
  // };

  const handleDeleteAccount = async (credentialId) => {
    
    const isSecretDeleted = await deleteSecretManagerCredential(await getUID(), 'clubready', credentialId, username);
  
    if (isSecretDeleted) {
      showSnackBar(`Successfully Deleted ${credentialId}`, "success");
      scrapeAccountsDispatch({
        type: "REMOVE_LOGIN",
        username: username,
        credentialId: credentialId,
        uid: await getUID(),
      });
    } else {
      // You can show an error message here if needed
      showSnackBar(`Failed to Delete ${credentialId}`, "error");
    }
  };
  

  return (
    <>
      <Tooltip
        style={{ fontSize: "1rem" }}
        placement="top"
        className="account-manager-dialog__list-item-tooltip"
        title={`${props.account.errors ?? ""}`}
        arrow
      >
        <ListItem
          className={`dialog__list-item account-manager-dialog__list-item account-manager-dialog__list-item${props.account.hasOwnProperty("studios") && studios.length > 0
            ? "--dropdown"
            : ""
            } 
      ${props.account.errors?.length > 0
              ? "account-manager-dialog__list-item--error"
              : ""
            }

      ${props.account.validated && props.account.validated == "pending"
              ? "account-manager-dialog__list-item--pending"
              : ""
            }

      ${props.account.validated && props.account.validated == true
              ? "account-manager-dialog__list-item--validated"
              : ""
            }


      `}
          key={credentialId}
          data-username={username}
          data-label={credentialId}
          onClick={handleClick}
          secondaryAction={
            <IconButton
              disabled={
                disabled ||
                (props.account.validated &&
                  props.account.validated == "pending")
              }
              className="dialog__icon-button dialog__icon-button--remove"
              data-username={username}
              data-label={credentialId}
              onClick={() => handleDeleteAccount(credentialId)}
              edge="end"
              aria-label="delete"
              size="large"
            >
              <DeleteRoundedIcon />
            </IconButton>
          }
        >
          <ListItemAvatar className="account-manager-dialog__list-item-avatar-container">
            {(disabled ||
              (props.account.validated &&
                props.account.validated == "pending")) && (
                <CircularProgress
                  className="account-manager-dialog__list-item-loader"
                  sx={{
                    color: blue[600],
                  }}
                />
              )}
            <Avatar
              className={`"account-manager-dialog__list-item-avatar${disabled ||
                (props.account.validated &&
                  props.account.validated == "pending")
                ? "--loading"
                : ""
                }"`}
              sx={{
                bgcolor:
                  props.account.errors?.length > 0 ? "transparent" : blue[100],
                color:
                  props.account.errors?.length > 0 ? "transparent" : blue[600],
              }}
            >
              {props.account.errors?.length > 0 ? (
                <ErrorIcon fontSize="large" color="error" />
              ) : (
                <PersonIcon />
              )}
            </Avatar>
          </ListItemAvatar>

          <div className="account-manager-dialog__list-item-text-container ">
            <ListItemText
              style={{ maxWidth: "fit-content" }}
              primary={credentialId}
              secondary={username}
            />
            {props.account.hasOwnProperty("studios") && studios.length > 0 ? (
              open ? (
                <ExpandLess
                  className={"account-manager-dialog__list-item-chevron"}
                />
              ) : (
                <ExpandMore
                  className={"account-manager-dialog__list-item-chevron"}
                />
              )
            ) : null}
          </div>
        </ListItem>
      </Tooltip>
      {props.account.hasOwnProperty("studios") && studios.length > 0 ? (
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {studios.map((studio) => (
              <ListItem sx={{ pl: 4 }}>
                <ListItemIcon>
                  <StoreIcon />
                </ListItemIcon>
                <ListItemText primary={studio} />
              </ListItem>
            ))}
          </List>
        </Collapse>
      ) : null}
    </>
  );
}
function NestedList(props) {
  const { scrapeAccounts } = useClubReady();
  const { handleAddAccount, disabled } = props;
  return (
    <List
      sx={{ width: "100%", bgcolor: "background.paper" }}
      component="nav"
      aria-labelledby="nested-list-subheader"
    >
      {scrapeAccounts.logins?.length > 0
        ? scrapeAccounts.logins.map((account) => {
          return <NestedListItem disabled={disabled} account={account} />;
        })
        : null}

      <ListItem
        id="addClubReadyAccountDialogButton"
        autoFocus
        button
        onClick={handleAddAccount}
      >
        <ListItemAvatar>
          <Avatar>
            <AddIcon />
          </Avatar>
        </ListItemAvatar>
        <ListItemText primary="Add account" />
      </ListItem>
    </List>
  );
}
function SimpleDialog(props) {
  const {
    accountRefresh,
  } = useClubReady();

  const { onClose, selectedValue, open } = props;

  const handleClose = () => {
    onClose(selectedValue);
  };
  const handleAddAccount = () => {
    props.setAddAccount(true);
  };

  return (
    <>
      <Dialog
        className="account-manager-dialog"
        onClose={handleClose}
        open={open}
      >
        <DialogTitle component={"h3"}>Club Ready Accounts </DialogTitle>
        <NestedList
          disabled={!accountRefresh["finished"]}
          handleAddAccount={handleAddAccount}
        />
      </Dialog>
    </>
  );
}

SimpleDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selectedValue: PropTypes.string.isRequired,
};

export default function ClubReadyAccountSelector() {

  const showSnackBar = useSnackBar();
  const [open, setOpen] = React.useState(false);

  const {
    scrapeAccounts,
    scrapeAccountsDispatch,
    getSecretManagerCredentials,
    addClubReadyAccountLambda
  } = useClubReady();
  const [addAccount, setAddAccount] = React.useState(false);

  const { getUID } = useAuth();
  const { currentlySelectedStudios } = useStudioScrapeStatus();
  const { dispatch } = useSettingsContext();

  const [selectedValue, setSelectedValue] = useState("Add Account");

  const [isLoading, setIsloading] = useState(false);

  const handleClickOpen = async () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleAddAccountClose = () => {
    setAddAccount(false);
  };
  const handleClubReadyAccountValidation = async (
    email,
    password,
    studio,
    credentialId
  ) => {
    
let result;
var cookies = false;
    let studioNames = false;


// Call /AddClubReadyAccount Lambda
try {
  result = await addClubReadyAccountLambda(email, password, credentialId);
  studioNames = result.studioNames;


} catch (e) {
  console.error(e);
  console.log("failed running function addClubReadyAccountLambda(): ", e);
}



    return { cookies, studioNames, errorMessage: result.errorMessage };
  };
  const [formInput, setFormInput] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      password: "",
      email: "",
      tagName: "",
    }
  );
  const [tagNameInputError, setTagNameInputError] = useState({
    isError: false,
    helperText: "",
  });


  const handleSubmit = async (evt) => {
   
    setIsloading(true);
    evt.preventDefault();

    let data = { formInput };
    var res = false;

    let uid = getUID();

    /**** ~~~~SECURITY RISK~~~~ THIS WILL BE DONE IN LAMBDA FUNCTION FOR SECURITY REASONS */
    // Verify club ready account is valid *need studios
    res = await handleClubReadyAccountValidation(
      data.formInput.email,
      data.formInput.password,
      currentlySelectedStudios[0],
      data.formInput.tagName
    );




    // ~~~ all logic below needs to transition to aws secrets
    if (res.cookies || res.studioNames) {


      const response = await getSecretManagerCredentials();
      // let logins = await getClubReadyLogins();
      
      let logins = response.integrations.clubready.credentials;

      scrapeAccountsDispatch({
        type: "ADD_LOGIN",
        username: data.formInput.email,
        uid: uid,
        credentialId: data.formInput.tagName,
        studios: res.studioNames,
      });

      dispatch({
        type: "UPDATE_SETTINGS_PROPERTY",
        settingsId: getUrlVariableValue("settingsId"),
        uid: getUID(),
        path: `${getUrlVariableValue("settingsId")}.clubReadyAccounts`,
        value: logins,
      });

      setSelectedValue("Accounts");
      showSnackBar("ClubReady Account successfully added!", "success");

      // handleSetSnackbar("success", "ClubReady Account successfully added!");
      setIsloading(false);
      // else {
      //   showSnackBar("ClubReady Account Already Exists", "error");

      //   // handleSetSnackbar("error", "ClubReady Account Already Exists");
      // }
    }



    setIsloading(false);
  };

  const handleInput = (evt) => {
    const name = evt.target.name;
    const newValue = evt.target.value;
    if (name == "tagName") {
      if (containsSpecialChars(newValue)) {
        // set tagnname state input to error with error message
        setTagNameInputError({
          isError: true,
          helperText:
            "Account label cannot contain the following characters: ., $, #, [, ], /, : ",
        });
      } else {
        setTagNameInputError({
          isError: false,
          helperText: "",
        });
        setFormInput({ [name]: newValue });
      }
    } else {
      setFormInput({ [name]: newValue });
    }
  };

  function countInvalidAccounts(accounts) {
    let count = 0;
    for (let i = 0; i < accounts.length; i++) {
      if (accounts[i].validated === false) {
        count++;
      }
    }
    return count;
  }

  return (
    <div className="clubready-account-selector">
      <Badge
        className="clubready-account-selector__button-badge"
        badgeContent={countInvalidAccounts(scrapeAccounts.logins)}
        color="error"
      >
        <Button
          className="clubready-account-selector__button"
          variant="outlined"
          onClick={
            scrapeAccounts.status == "empty"
              ? () => setAddAccount(true)
              : handleClickOpen
          }
          key={
            scrapeAccounts.status == "empty"
              ? "Add ClubReady Account"
              : "Accounts"
          }
          startIcon={<PersonRoundedIcon />}
        >
          {scrapeAccounts.status == "empty"
            ? "Add ClubReady Account"
            : "Accounts"}
        </Button>
      </Badge>
      <SimpleDialog
        selectedValue={
          scrapeAccounts.status == "empty"
            ? "Add ClubReady Account"
            : "Accounts"
        }
        open={open}
        onClose={handleClose}
        setAddAccount={setAddAccount}
      />

      <Dialog onClose={() => setAddAccount(false)} open={addAccount}>
        {/* {snackbar} */}
        <DialogTitle component={"h3"}>Add Club Ready Account</DialogTitle>

        <DialogContent>
          <DialogContentText>
            Use the information associated with the Club Ready account you setup
            for PayWell.
          </DialogContentText>
          <form
            onSubmit={handleSubmit}
            style={{ display: "flex", flexDirection: "column" }}
          >
            <TextField
              required
              label="Account Label"
              name="tagName"
              defaultValue={formInput.tagName}
              onChange={handleInput}
              id="addClubReadyAccountLabelField"
              variant="outlined"
              style={{ marginTop: "1em" }}
              error={tagNameInputError["isError"]}
              helperText={tagNameInputError["helperText"]}
            />
            <TextField
              required
              label="Username"
              name="email"
              defaultValue={formInput.name}
              onChange={handleInput}
              id="addClubReadyAccountUsernameField"
              variant="outlined"
              style={{ marginTop: "1em" }}
            />
            <TextField
              required
              label="Password"
              name="password"
              type="password"
              defaultValue={formInput.name}
              onChange={handleInput}
              id="addClubReadyAccountPasswordField"
              variant="outlined"
              style={{ marginTop: "1em" }}
            />
            <DialogActions sx={{ marginTop: "1em" }}>
              <Button onClick={handleAddAccountClose}>Cancel</Button>

              <LoadingButton
                id="addClubReadyAccountButton"
                loading={isLoading}
                variant="contained"
                type="submit"
                disabled={tagNameInputError["isError"]}
              >
                Add
              </LoadingButton>
            </DialogActions>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  );
}
