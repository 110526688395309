import React, { useState } from "react";
import { TextField, Button } from "@mui/material";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { useAuth } from "../contexts/AuthContext";
import { Link } from "react-router-dom";
import { useSnackBar } from "../contexts/SnackBarContext/SnackBarContext";
import image from "../img/PayWellLogoBlack.png";

const defaultValues = {
  email: "",
};

const ForgotPassword = () => {
  const [formValues, setFormValues] = useState(defaultValues);
  const [loading, setLoading] = useState(false);
  const { resetPassword } = useAuth();
  const showSnackBar = useSnackBar();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    try {
      const res = await resetPassword(formValues.email);
      if (res.message) {
        showSnackBar(res.message, "error");
      } else {
        showSnackBar("Password reset email sent!", "success");
      }
    } catch (e) {
      console.error(e);
      showSnackBar(e.message, "error");
    }
    setLoading(false);
  };

  return (
    <Box
      className="auth"
      style={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        alignItems: "center",
        padding: "65px 20px",
      }}
    >
      <a className="auth__paywell" href="/">
        <Typography
          style={{
            textAlign: "center",
            color: "#494949",
            marginBottom: "30px",
          }}
          variant="h6"
          noWrap
          component="div"
        >
          <img src={image} alt="PayWell Logo" style={{ height: "1.25em" }} />
        </Typography>
        <Typography
          style={{
            textAlign: "center",
            color: "#494949",
            marginBottom: "30px",
          }}
          variant="h6"
          noWrap
          component="div"
        >
          Reset your PayWell password
        </Typography>
      </a>

      <form className="auth__form" onSubmit={handleSubmit}>
        <Card
          className="auth__card"
          style={{
            background: "rgb(255, 255, 255)",
            border: "1px solid rgb(230, 230, 230)",
            boxSizing: "border-box",
            boxShadow: "rgb(45 40 77 / 10%) 0px 12px 32px",
            borderRadius: "8px",
            minWidth: "275px",
            width: "100vw",
            maxWidth: "550px",
          }}
        >
          <CardContent
            className="auth__card-content"
            style={{
              display: "flex",
              flexDirection: "column",
              margin: "1em",
              marginLeft: "1em",
            }}
          >
            <Box
              className="auth__box  auth__box--align-start"
              style={{
                display: "flex",
                width: "100%",
                flexDirection: "column",
                alignItems: "flex-start",
              }}
            >
              <Box
                className="auth__box  auth__box--align-start"
                style={{
                  display: "flex",
                  width: "100%",
                  flexDirection: "column",
                  alignItems: "flex-start",
                }}
              >
                <TextField
                  className="auth__text-field"
                  fullWidth
                  name="email"
                  label="Email"
                  type="text"
                  value={formValues.email}
                  onChange={handleInputChange}
                  autoComplete="username"
                  placeholder="Enter your email"
                  InputLabelProps={{ shrink: true }}
                />
              </Box>
            </Box>

            <Button
              className="auth__button"
              disabled={loading}
              type="submit"
              style={{
                position: "relative",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                marginTop: "2em",
                backgroundColor: "#006bff",
                minHeight: "66px",
                borderRadius: "40px",
              }}
              variant="contained"
            >
              Reset Password
            </Button>
            <Link
              style={{ textAlign: "center", marginTop: "1em" }}
              to="/login"
            >
              Sign In
            </Link>
          </CardContent>
        </Card>
        <Box
          className="auth__box"
          style={{ textAlign: "center", marginTop: "1em" }}
        >
          Don't have an account? <Link to="/signup">Sign Up</Link>
        </Box>
      </form>
    </Box>
  );
};

export default ForgotPassword;
