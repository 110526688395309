import React, { useContext, useReducer } from "react";
import {
  serializeObjectWithMethods,
  deserializeObjectWithMethods,
  updateObject,
} from "../../utility-functions/utility-functions.js";
import { ClassCreation } from "./utility-functions.js";
/**** CONTEXT REDUCER HELPER FUNCTIONS ****/

/**** REDUCER ****/
const deserializedClassCreationContextReducer = (state, action) => {
  const serializedState = serializeObjectWithMethods(state);
  const serializedAction = serializeObjectWithMethods(action);
  const newSerializedState = classCreationContextReducer(
    serializedState,
    serializedAction
  );

  const deserializedState = deserializeObjectWithMethods(newSerializedState);
  return deserializedState;
};
const classCreationContextReducer = (state, action) => {
  let newState = JSON.parse(JSON.stringify(state));

  switch (action.type) {
    // classCreationContextDispatch({
    //   type:  "INIT_CLASS_CREATION",
    //   reportCompilerState: reportCompilerState
    // });
    case "INIT_CLASS_CREATION":
      newState = new ClassCreation(action.reportCompilerState, []);
      return newState;

    // classCreationContextDispatch({
    //   type: "CREATE_CLASSES_METHOD",
    //   reportCompilerState: reportCompilerState
    // });
    case "CREATE_CLASSES_METHOD":
      newState.reportCompilerState = action.reportCompilerState;
      newState.createClasses();
      return newState;

    //  classCreationContextDispatch({
    //   type: "SET_CLASS_CREATION_CONTEXT_STATE",
    //   newClassCreationInstance: <ClassCreationInstance>,
    // });
    case "SET_CLASS_CREATION_CONTEXT_STATE":
      newState = action.newClassCreationInstance;

      return newState;

    //  classCreationContextDispatch({
    //   type: "UPDATE_CLASS_CREATION_CONTEXT_STATE_PROPERTY",
    //   path: `property.property.property`,
    //   value: value,
    //   replace: true/false
    // });
    case "UPDATE_CLASS_CREATION_CONTEXT_STATE_PROPERTY":
      updateObject(
        newState,
        action.path,
        action.value,
        action.hasOwnProperty("replace") ? action.replace : true
      );

      return newState;

    default:
      return state;
  }
};

/**** CONTEXT ****/
const ClassCreationContext = React.createContext();
export function useClassCreationContext() {
  return useContext(ClassCreationContext);
}
export function ClassCreationContextProvider({ children }) {

  const initialClassCreationContextState = {};

  const [classCreationContextState, classCreationContextDispatch] = useReducer(
    deserializedClassCreationContextReducer,
    initialClassCreationContextState
  );

  // Context values to pass down
  const value = {
    classCreationContextState,
    classCreationContextDispatch,
  };

  return (
    <ClassCreationContext.Provider value={value}>
      {children}
    </ClassCreationContext.Provider>
  );
}
