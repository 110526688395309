import React, { createContext, useContext, useState, useEffect } from 'react';

const NetworkStatusContext = createContext();

export const NetworkStatusProvider = ({ children }) => {
  const [offline, setOffline] = useState(!navigator.onLine);

  const handleOnline = () => setOffline(false);
  const handleOffline = () => setOffline(true);

  useEffect(() => {
    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, []);

  return (
    <NetworkStatusContext.Provider value={offline}>
      {children}
    </NetworkStatusContext.Provider>
  );
};

export const useNetworkStatus = () => useContext(NetworkStatusContext);
