import BooleanToggleElement from "../../Components/BooleanToggle";
import { Typography, Box } from "@mui/material";
import { useState } from "react";
import InputBoxElement from "../../Components/InputBox/InputBox";
import { useSettingsContext } from "../../../contexts/SettingsContext";
import { RevertList } from "../../Components/RevertList";
import { useAuth } from "../../../contexts/AuthContext";
import { getUrlVariableValue } from "../../../utility-functions/utility-functions.js";
export default function ClassesHoursFlag(props) {
  const { getUID } = useAuth();
  const { settings, dispatch, changes, setChanges } = useSettingsContext();

  const [attendeeCount, setAttendeeCount] = useState(
    settings[getUrlVariableValue("settingsId")].formatSettings.general
      .classCountVsHourFlag.valid
  );

  function handleChange(value, id) {
    setAttendeeCount(value);
    let newRev = new RevertList(changes.getValues());
    newRev.addElement(
      "classHoursFlag-" + id,
      settings[getUrlVariableValue("settingsId")].formatSettings.general
        .classCountVsHourFlag[id],
      value
    );
    setChanges(newRev);
    dispatch({
      type: "UPDATE_SETTINGS_PROPERTY",
      settingsId: getUrlVariableValue("settingsId"),
      uid: getUID(),
      path: `${getUrlVariableValue(
        "settingsId"
      )}.formatSettings.general.classCountVsHourFlag.${id}`,
      value: value,
    });
  }

  const modifier = `settings-page__toggle-content${
    !attendeeCount ? "--off" : ""
  }`;

  return (
    <>
      <Typography className="settings-page__typography">
        {props.description}
      </Typography>
      <BooleanToggleElement
        id="valid"
        default={
          settings[getUrlVariableValue("settingsId")].formatSettings.general
            .classCountVsHourFlag.valid
        }
        onChange={handleChange}
      ></BooleanToggleElement>
      <Box className={`settings-page__toggle-content ${modifier}`}>
        <FlagSpecifications />
      </Box>
    </>
  );
}

function FlagSpecifications() {
  const { getUID } = useAuth();
  const { settings, dispatch, changes, setChanges } = useSettingsContext();

  const [attendeeCount, setAttendeeCount] = useState(
    settings[getUrlVariableValue("settingsId")].formatSettings.general
      .classCountVsHourFlag.valid
  );

  function handleChange(value, id) {
    setAttendeeCount(value);
    let newRev = new RevertList(changes.getValues());
    newRev.addElement(
      "classHoursFlag-" + id,
      settings[getUrlVariableValue("settingsId")].formatSettings.general
        .classCountVsHourFlag[id],
      value
    );
    setChanges(newRev);
    dispatch({
      type: "UPDATE_SETTINGS_PROPERTY",
      settingsId: getUrlVariableValue("settingsId"),
      uid: getUID(),
      path: `${getUrlVariableValue(
        "settingsId"
      )}.formatSettings.general.classCountVsHourFlag.${id}`,
      value: value,
    });
  }

  return (
    <ul className="setting-page__toggle-content__list">
      <li className="setting-page__toggle-content__list-item">
        <Typography>{"Average length of a " + settings[getUrlVariableValue("settingsId")].generalSettings.sessionName + ":"}</Typography>
        <InputBoxElement
          id="classLength"
          default={
            settings[getUrlVariableValue("settingsId")].formatSettings.general
              .classCountVsHourFlag.classLength
          }
          onChange={handleChange}
          types={["number"]}
          size={100}
          label="Average Class Length (hours)"
        ></InputBoxElement>
      </li>
      <li className="setting-page__toggle-content__list-item">
        <Typography>Percent difference needed for flag:</Typography>
        <InputBoxElement
          id="percentDifference"
          default={
            settings[getUrlVariableValue("settingsId")].formatSettings.general
              .classCountVsHourFlag.percentDifference
          }
          onChange={handleChange}
          types={["percent"]}
          size={100}
          label="Percent Difference"
        ></InputBoxElement>
      </li>
    </ul>
  );
}
