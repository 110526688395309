import React, { useRef, useState } from "react";
import NativeSelect from "@mui/material/NativeSelect";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const names = [
  "Oliver Hansen",
  "Van Henry",
  "April Tucker",
  "Ralph Hubbard",
  "Omar Alexander",
  "Carlos Abbott",
  "Miriam Wagner",
  "Bradley Wilkerson",
  "Virginia Andrews",
  "Kelly Snyder",
];

export default function MultipleSelectCheckmarks(props) {
  const [currentlySelected, setCurrentlySelected] = useState(
    props.default ?? ""
  );
  const selectorBoxRef = useRef();
  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setCurrentlySelected(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );

    if (props.onChange) {
      props.onChange(event.target.value, props.id);
    }
  };

  return (
    <div>
      <FormControl ref={selectorBoxRef} sx={{ m: 1, width: 300 }}>
        <InputLabel style={{ left: "-15px" }} id="multiple-checkbox-label">
          {props.tag}
        </InputLabel>
        <Select
          labelId="multiple-checkbox-label"
          id={props.id}
          name={props.name}
          value={currentlySelected}
          variant="standard"
          multiple
          onChange={handleChange}
          input={<NativeSelect label={props.tag} />}
          renderValue={(selected) => selected.join(", ")}
          MenuProps={MenuProps}
        >
          {props.options.map((name) => (
            <MenuItem key={name} value={name}>
              <Checkbox checked={currentlySelected.indexOf(name) > -1} />
              <ListItemText primary={name} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}
