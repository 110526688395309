import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";
import { useHistory } from "react-router-dom";
import PrivateContextProvider from "../contexts/PrivateContextProvider/PrivateContextProvider";

export default function PrivateRoute({ component: Component, ...rest }) {
  const { currentUser } = useAuth();
  const history = useHistory();
  const user = currentUser;
  if (user !== null) {
    return (
      <Route
        {...rest}
        render={(props) => {
          return <PrivateContextProvider><Component {...props}></Component></PrivateContextProvider>;
        }}
      ></Route>
    );
  } else {
    history.push("/login");
    return null;
  }
}
