import React from 'react';
import TextField from '@mui/material/TextField';

const NumericTextField = React.memo(({ label, value, onChange }) => {
  const handleInputChange = (e) => {
    const { value } = e.target;
    // Allow numbers and decimal points
    if (/^\d*\.?\d*$/.test(value)) {
      onChange(e);
    }
  };

  return (
    <TextField
      label={label}
      type="text"
      fullWidth
      margin="normal"
      value={value || ""}
      onChange={handleInputChange}
      inputProps={{ inputMode: 'decimal' }}
    />
  );
});

export default NumericTextField;
