import React from "react";
import { CircularProgress } from "@mui/material";
import { useStudioScrapeStatus } from "../contexts/StudioScrapeStatus/StudioScrapeStatusContext.js";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";

export default function CircularProgressWrapper(props) {
  const { status, dispatch } = useStudioScrapeStatus();
  const indicator = () => {
    let component;
    switch (status[props.studioIndex]["status"]) {
      case "loading":
        component = (
          <>
            <span
              style={{
                display: "flex",
                flex: "none",
                fontSize: "0.65em",
                fontWeight: "400",
                position: "absolute",
                color: "#4baf4f",
              }}
            >
              {`${status[props.studioIndex]["completedReportsCount"]} /
          ${status[props.studioIndex]["reportsCount"]}`}
            </span>
            <CircularProgress
              color="inherit"
              thickness={1.5}
              style={{
                height: "2em",
                width: "2em",
                color: "#4baf4f",
              }}
            />
          </>
        );
        break;
      case "error":
        component = (
          <>
            <span
              style={{
                display: "flex",
                flex: "none",
                fontSize: "0.65em",
                fontWeight: "400",
                position: "absolute",
                color: "#d32f2f",
              }}
            >
              {`${status[props.studioIndex]["completedReportsCount"]} /
          ${status[props.studioIndex]["reportsCount"]}`}
            </span>
            <CircularProgress
              thickness={1.5}
              variant="determinate"
              value={
                (parseInt(status[props.studioIndex]["completedReportsCount"]) /
                  parseInt(status[props.studioIndex]["reportsCount"])) *
                100
              }
              color={"error"}
              style={{
                height: "2em",
                width: "2em",
              }}
            />
          </>
        );
        break;
      case "idle":
        component = (
          <>
            <span
              style={{
                display: "flex",
                flex: "none",
                fontSize: "0.65em",
                fontWeight: "400",
                position: "absolute",
                color: "#c3c3c3",
              }}
            >
              {`${status[props.studioIndex]["completedReportsCount"]} /
          ${status[props.studioIndex]["reportsCount"]}`}
            </span>
            <CircularProgress
              color="inherit"
              thickness={1.5}
              variant="determinate"
              value={
                (parseInt(status[props.studioIndex]["completedReportsCount"]) /
                  parseInt(status[props.studioIndex]["reportsCount"])) *
                100
              }
              style={{
                height: "2em",
                width: "2em",
                color: "#c3c3c3",
              }}
            />
          </>
        );
        break;
      case "done":
        component = (
          <CheckCircleRoundedIcon
            style={{
              color: "#4baf4f",
              fontSize: "1.5em",
            }}
          />
        );
        break;

      default:
        break;
    }
    return component;
  };
  return indicator();
}
