
import React from "react";
import { Link, useHistory } from "react-router-dom";
import SearchBar from "./SearchBar";
import { resources } from "./resources";
import InfoIcon from "@mui/icons-material/Info";
import SettingsIcon from "@mui/icons-material/Settings";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import EventIcon from "@mui/icons-material/Event";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import SwapHorizIcon from "@mui/icons-material/SwapHoriz";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import EmailIcon from "@mui/icons-material/Email";
import ImportExportIcon from "@mui/icons-material/ImportExport";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import CompareIcon from "@mui/icons-material/Compare";
import ListAltIcon from "@mui/icons-material/ListAlt";

import Button from "@mui/material/Button";
import ArrowBackIosRoundedIcon from "@mui/icons-material/ArrowBackIosRounded";
import PayWellLogoBlack from "./PayWellLogoBlack (2).png"; // Import the logo image
import "./VideoResources.css";

const iconMap = {
  InfoIcon: <InfoIcon className="resource-icon" />,
  SettingsIcon: <SettingsIcon className="resource-icon" />,
  LocationOnIcon: <LocationOnIcon className="resource-icon" />,
  EventIcon: <EventIcon className="resource-icon" />,
  AccessTimeIcon: <AccessTimeIcon className="resource-icon" />,
  AutorenewIcon: <AutorenewIcon className="resource-icon" />,
  SwapHorizIcon: <SwapHorizIcon className="resource-icon" />,
  TrendingUpIcon: <TrendingUpIcon className="resource-icon" />,
  AdminPanelSettingsIcon: (
    <AdminPanelSettingsIcon className="resource-icon" />
  ),
  GroupAddIcon: <GroupAddIcon className="resource-icon" />,
  PlayCircleOutlineIcon: <PlayCircleOutlineIcon className="resource-icon" />,
  EmailIcon: <EmailIcon className="resource-icon" />,
  ImportExportIcon: <ImportExportIcon className="resource-icon" />,
  SupportAgentIcon: <SupportAgentIcon className="resource-icon" />,
  ListAltIcon: <ListAltIcon className="resource-icon" />,
  CompareIcon: <CompareIcon className="resource-icon" />,
};

const VideoResources = () => {
  const history = useHistory();
  function handleDashboardClick() {
    history.push({
      pathname: "/dashboard",
      search: window.location.search,
    });
  }

  return (
    <div className="resources video-resources">
      <div className="header-container">
        <Button
          className="drawer__back-button"
          onClick={handleDashboardClick}
          startIcon={<ArrowBackIosRoundedIcon className="icon" />}
        >
          Dashboard
        </Button>

        <div className="title-container">
          <img
            src={PayWellLogoBlack}
            alt="PayWell Logo"
            className="paywell-logo"
          />
          <h1 className="resources__h1">knowledge base</h1>
        </div>
      </div>

      <SearchBar />

      {/* Browse Topics Section */}
      <div className="browse-header">
        <h3 className="resources__h3">Browse Topics</h3>
        <p>Everything you need to know about PayWell</p>
      </div>

      <div className="resources-list">
        {resources.map((resource) => (
          // <div className="resource-card" key={resource.name}>
            <Link
            key={resource.name}
              to={`/resources/${encodeURIComponent(resource.name)}`}
              className="resource-card"
            >
              {iconMap[resource.icon] || <InfoIcon className="resource-icon" />}
              <h4 className="resources__h4">{resource.name.replaceAll("-", " ")}</h4>
              <p>{resource.summary}</p>
            </Link>
          // </div>
        ))}
      </div>
    </div>
  );
};

export default VideoResources;
