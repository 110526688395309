// CustomDrawer.js

import React from "react";
import Drawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import { useDrawer } from "./CustomDrawerContext";
import "./CustomDrawer.scss";
import { useAuth } from "../../contexts/AuthContext";
const CustomDrawer = () => {
  const { currentUser } = useAuth();
  const { drawerOpen, drawerTitle, drawerContent, closeDrawer } = useDrawer();

  return (
    <Drawer
      className="custom-drawer"
      anchor="right"
      open={currentUser?.email ? drawerOpen : false}
      onClose={closeDrawer}
    >
      <Box className="custom-drawer__box">
        <Box
          className="custom-drawer__header-container"
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography className="custom-drawer__title h3" variant="h3">
            {drawerTitle}
          </Typography>
          <IconButton
            className="custom-drawer__close-button"
            onClick={closeDrawer}
          >
            <CloseIcon />
          </IconButton>
        </Box>
        <Box className="custom-drawer__content">{drawerContent}</Box>
      </Box>
    </Drawer>
  );
};

export default CustomDrawer;
