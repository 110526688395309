import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import {
  getAuth,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  sendEmailVerification,
  onAuthStateChanged,
  signOut, 
} from "firebase/auth";
import { getDatabase, ref, onValue } from "firebase/database";


let app;
try {
  app = initializeApp({
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
  });
} catch (error) {
  
  console.error("Firebase initialization error: ", error);
  // Handle Firebase initialization error, e.g., show a user-friendly message
  throw error; // Rethrow the error for the calling code to handle if needed
}

let auth;
try {
  auth = getAuth(app);

} catch (error) {
  
  console.error("Auth initialization error: ", error);
  // Handle Auth initialization error, e.g., show a user-friendly message
  throw error; // Rethrow the error for the calling code to handle if needed
}



let db;
try {
  db = getFirestore(app);
} catch (error) {
  
  console.error("Firestore initialization error: ", error);
  // Handle Firestore initialization error, e.g., show a user-friendly message
  throw error; // Rethrow the error for the calling code to handle if needed
}



function isFirebaseConnected() {
  const db = getDatabase();
  const connectedRef = ref(db, ".info/connected");
  onValue(connectedRef, (snap) => {
    if (snap.val() === true) {
      console.log("connected");
      return true;
    } else {
      console.log("not connected");
      return false;
    }
  });
}
export {
  isFirebaseConnected,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  sendEmailVerification,
  auth,
  db,
};

export default app;
