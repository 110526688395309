import React from 'react';

export const ComparisonView = ({ data }) => {
  if (!data) return null;

  return (
    <div className='output-comparison'>
      {Object.entries(data).map(([key, value]) => (
        <div key={key} className="output-comparison__section">
          <h3 className='output-comparison__header'>{key}</h3>
          {Array.isArray(value) ? (
            <table className='output-comparison__table'>
              <thead>
                <tr>
                  <th>Status</th>
                  <th>Sheet</th>
                  <th>Old Row</th>
                  <th>New Row</th>
                  <th>Old Value</th>
                  <th>New Value</th>
                  <th>Incorrect Cells</th>
                </tr>
              </thead>
              <tbody>
                {value.map((item, index) => (
                  <tr key={index}>
                    <td>{item.status}</td>
                    <td>{item.sheet}</td>
                    <td>{item.oldRow}</td>
                    <td>{item.newRow}</td>
                    <td>{item.oldValue}</td>
                    <td>{item.newValue}</td>
                    <td>{item.incorrectCells}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <p>{value}</p>
          )}
        </div>
      ))}
    </div>
  );
};
