import React, { useState } from 'react';
import { Box, Button, Typography, IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import AttributeListBox from './AttributeListBox'; // Assuming this is your custom component

const TieredItemManager = ({ initialTiers, buckets, allItems, onChange, id, options }) => {
  const [tiers, setTiers] = useState(initialTiers);

  const handleAddTier = () => {
    const newTierIndex = Object.keys(tiers).length;
    const newTiers = { ...tiers, [newTierIndex]: [] };
    setTiers(newTiers);
    if (onChange) {
      onChange(newTiers, id);
    }
  };

  const handleRemoveTier = (tierIndex) => {
    const { [tierIndex]: _, ...remainingTiers } = tiers;

    // Re-index the tiers after the removed one
    const newTiers = Object.keys(remainingTiers).reduce((acc, key) => {
      const index = parseInt(key);
      acc[index > tierIndex ? index - 1 : index] = remainingTiers[key];
      return acc;
    }, {});

    setTiers(newTiers);
    if (onChange) {
      onChange(newTiers, id);
    }
  };

  const handleItemChange = (items, tierIndex) => {
    const newTiers = { ...tiers, [tierIndex]: items };
    setTiers(newTiers);
    if (onChange) {
      onChange(newTiers, id); // Pass the tier index to the parent component
    }
  };

  const getFilteredOptions = (currentTierIndex) => {
    // Gather all items in other tiers
    const otherItems = Object.entries(tiers)
      .filter(([index]) => parseInt(index) !== currentTierIndex)
      .flatMap(([, items]) => items);

    // Filter out items already in other tiers
    return options.filter(option => !otherItems.includes(option));
  };

  return (
    <Box>
      <Typography variant="h6" sx={{ marginBottom: 2 }}>
        Manage Item Tiers
      </Typography>
      {Object.entries(tiers).map(([index, items]) => (
        <Box key={index} sx={{ display: 'flex', alignItems: 'center', marginBottom: 2 }}>
          <AttributeListBox
            key={index + "-hierarchy"}
            multiple={true}
            default={items}
            onChange={(newItems) => handleItemChange(newItems, index)}
            label={`Tier ${index}` + (index < 1 ? ' (Unpaid)' : '')}
            options={getFilteredOptions(parseInt(index))}
            sx={{ flex: 1, minWidth: '500px', maxWidth: '500px' }} // Adjust the minWidth and maxWidth as needed
          />
          <IconButton onClick={() => handleRemoveTier(index)} color="error">
            <DeleteIcon />
          </IconButton>
        </Box>
      ))}
      <Button onClick={handleAddTier} startIcon={<AddIcon />}>
        Add Tier
      </Button>
    </Box>
  );
};

export default TieredItemManager;
