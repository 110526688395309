import React from "react";
import { Typography } from "@mui/material";
import image from "../../img/PayWellLogoBlack.png";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedin, faInstagram, faFacebook, faYoutube } from '@fortawesome/free-brands-svg-icons';

import "./Footer.scss";

const FooterLinkList = () => {
  return (
    <>
      <div className="footer__seperator"></div>
      <footer className="footer">
        <div className="footer-logo">
          <a href="/">
            <Typography
              style={{ color: "#494949" }}
              variant="h6"
              noWrap
              component="div"
              sx={{ mr: 2, display: "flex" }}
            >
              <img src={image} alt="PayWell Logo" className="footer-logo__img" />
            </Typography>
          </a>
        </div>
        {/* Social Media Links */}
        <div className="footer-socials">
          <a href="https://www.linkedin.com/company/paywell-solutions/" className="footer__social-link" aria-label="LinkedIn">
            <FontAwesomeIcon icon={faLinkedin} />
          </a>
          <a href="https://www.instagram.com/paywellsolutions/" className="footer__social-link" aria-label="Instagram">
            <FontAwesomeIcon icon={faInstagram} />
          </a>
          <a href="https://www.facebook.com/people/PayWell-Solutions/61559177678559/" className="footer__social-link" aria-label="Facebook">
            <FontAwesomeIcon icon={faFacebook} />
          </a>
          <a href="https://www.youtube.com/@PayWellSolutions" className="footer__social-link" aria-label="YouTube">
            <FontAwesomeIcon icon={faYoutube} />
          </a>
        </div>
        <div className="footer-links">
          <a className="footer__link" href="/">
            About Us
          </a>
          <a className="footer__link" href="/legal/privacy-policy">
            Privacy Policy
          </a>
          <a className="footer__link" href="/legal/terms-conditions">
            Terms & Conditions
          </a>
          <a className="footer__link" href="/contact">
            Contact Us
          </a>
        </div>
      </footer>
    </>
  );
};

export default FooterLinkList;
