import React from "react";
import Button from "@mui/material/Button";
import "./clubReadyAccountSelector.scss";
import PersonRoundedIcon from "@mui/icons-material/PersonRounded";

export default function ClubReadyAccountSelectorSkeleton(props) {
  return (
    <div className="clubready-account-selector">
      <Button
        className="clubready-account-selector__button"
        variant="outlined"
        disabled={true}
        key={"clubready-account-selector-button"}
        startIcon={<PersonRoundedIcon />}
      >
        Accounts
      </Button>
    </div>
  );
}
