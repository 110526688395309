import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import image from "../../img/PayWellLogoBlack.png";
import useMediaQuery from "@mui/material/useMediaQuery";
import CustomizedSnackbars from "../Snackbar";
import DashboardRoundedIcon from "@mui/icons-material/DashboardRounded";
import LogoutRoundedIcon from "@mui/icons-material/LogoutRounded";
import { useAuth } from "../../contexts/AuthContext";
import { Link, useHistory } from "react-router-dom";
import RedeemRoundedIcon from "@mui/icons-material/RedeemRounded";
import SendIcon from "@mui/icons-material/Send";
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import { useSnackBar } from "../../contexts/SnackBarContext/SnackBarContext";
import QuestionMarkRoundedIcon from "@mui/icons-material/QuestionMarkRounded";
import HelpCenterIcon from '@mui/icons-material/HelpCenter';
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDownRounded"
import { ArrowRightRounded, Help } from "@mui/icons-material";
import MondayForm from "../Forms/MondayForm";
import Avatar from '@mui/material/Avatar';
import Divider from '@mui/material/Divider';

import "./navBar.scss";

import { useDrawer } from "../CustomDrawer/CustomDrawerContext";
import { Dropdown, DropdownMenuItem, NestedMenuItem } from "../Menus/NestedMenu/Dropdown";

const pages = ["About"];
// const integrations = [
//   "Mariana Tek", "Serviceminder", "ServiceTitan", "ABC Glofox",
//   "Boulevard", "Housecall Pro", "Mindbody"
// ];
const settings = [
  "Dashboard",
  "Email Paystubs",
  "Refer an Owner",
  "Resources",
  "Help",
  "Logout",
];
const settingsIcons = [
  <DashboardRoundedIcon style={{ color: "#6c6c72", fontSize: "18px" }} />,
  <SendIcon style={{ color: "#6c6c72", fontSize: "18px" }} />,
  <RedeemRoundedIcon style={{ color: "#6c6c72", fontSize: "20px" }} />,
  <HelpCenterIcon style={{ color: "#6c6c72", fontSize: "20px" }} />,
  <QuestionMarkRoundedIcon style={{ color: "#6c6c72", fontSize: "20px" }} />
];
const ResponsiveAppBar = (props) => {
  const { logout, currentUser, isAdmin } = useAuth();
  const history = useHistory();
  const matches = useMediaQuery("(min-width:900px)");
  const [shadow, setShadow] = useState(false);
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const showSnackBar = useSnackBar();

  const { openDrawer } = useDrawer();
  const [iframeLoaded, setIframeLoaded] = useState(false);

  const handleOpenHelpDrawer = () => {
    const helpContent = <MondayForm />;

    const title = "Support";
    openDrawer(helpContent, title);
  };

  const handleScroll = () => {
    if (document.documentElement.scrollTop > 0) {
      setShadow(true);
    } else {
      setShadow(false);
    }
  };

  useEffect(() => (window.onscroll = () => handleScroll()), []);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = (label) => {
    setAnchorElUser(null);
    if (label == "Dashboard") {
      history.push({
        pathname: "/dashboard",
        search: location.search, // this includes the current query parameters
      });
    } else if (label == "Settings") {
      history.push({
        pathname: "/studio-settings",
        search: location.search, // this includes the current query parameters
      });
    } else if (label == "Refer an Owner") {
      history.push({
        pathname: "/referrals",
        search: location.search, // this includes the current query parameters
      });
    } else if(label === "Resources") {
      history.push({
        pathname: "/resources",
        search: location.search, // this includes the current query parameters
      })
    } else if (label == "Email Paystubs") {
      history.push({
        pathname: "/staff-emailer",
        search: location.search, // this includes the current query parameters
      });
    } else if (label == "Help") {
      handleOpenHelpDrawer();
    }
  };

  async function handleLogout() {
    try {
      await logout();
      history.push("/");
    } catch {
      console.error("Failed to log out");
      showSnackBar("Failed to logout.", "error");
    }
  }


  const [integrationMenuAnchorEl, setIntegrationMenuAnchorEl] = useState(null);
  const openIntegrationMenu = Boolean(integrationMenuAnchorEl);

  const handleIntegrationMenuClick = (event) => {
    setIntegrationMenuAnchorEl(event.currentTarget);
  };

  const handleIntegrationMenuClose = () => {
    setIntegrationMenuAnchorEl(null);
  };




  const menuItems = [
    ...pages.map(page => ({
      label: (
        <Link
          key={page}
          style={{ textDecoration: "none" }}
          to={page === "About" ? "/" : `/${page.toLowerCase()}`}
        >
          <Typography component={"span"} style={{ color: "#494949" }} textAlign="center">
            {page}
          </Typography>
        </Link>
      )
    })),
    {
      label: (
        <Link
          key="get-started"
          style={{ textDecoration: "none" }}
          to="/contact"
        >
          <Typography component={"span"} style={{ color: "#494949" }} textAlign="center">
           Contact Us
          </Typography>
        </Link>
      )
    },
    // {
    //   label: "Integrations",
    //   rightIcon: <KeyboardArrowDownIcon />,
    //   children: integrations.map(integration => ({
    //     label: (
    //       <Link
    //         key={integration}
    //         to={`/beta/${integration}?integration=${encodeURIComponent(integration)}`}
    //         style={{
    //           display: "flex",
    //           alignItems: "center",
    //           gap: "8px",
    //           textDecoration: "none",
    //           color: "#494949"
    //         }}
    //       >
    //         <Typography textAlign="center">
    //           {integration}
    //         </Typography>
    //       </Link>
    //     )
    //   }))
    // },
    {
      label: (
        <Link
          key="sign-in"
          style={{ textDecoration: "none", color: "#006bff" }}
          to="/dashboard"
        >
          <Typography component={"span"} textAlign="center">
            Sign In
          </Typography>
        </Link>
      )
    }
  ];
  return (
    <>
      <nav
        style={
          matches
            ? {
              position: "fixed",
              top: "0px",
              left: "0px",
              right: "0px",
              zIndex: "1201",
              backgroundColor: "white",
              display: "flex",
              flexDirection: "column",
              boxPack: "center",
              justifyContent: "center",
              boxAlign: "center",
              alignItems: "center",
              width: "100%",
              height: props.hideMenu ? "64px" : "104px",
              boxShadow: shadow ? "rgb(0 0 0 / 4%) 0px 4px 4px" : "none",
              borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
            }
            : {
              position: "fixed",
              top: "0px",
              left: "0px",
              right: "0px",
              zIndex: "1201",
              backgroundColor: "white",
              display: "flex",
              flexDirection: "column",
              boxPack: "center",
              justifyContent: "center",
              boxAlign: "center",
              alignItems: "center",
              width: "100%",
              boxShadow: shadow ? "rgb(0 0 0 / 4%) 0px 4px 4px" : "none",
              borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
              height: "48px",
              paddingTop: "28px",
              paddingBottom: "28px",
            }
        }
      >
        <Toolbar
          style={
            matches
              ? {
                width: "100%",
                paddingLeft: "32px",
                paddingRight: "32px",
                display: "flex",
                boxPack: "justify",
                justifyContent: "space-between",
                boxAlign: "center",
                alignItems: "center",
                margin: "0px auto",
              }
              : {
                width: "100%",
                display: "flex",
                boxPack: "justify",
                justifyContent: "space-between",
                boxAlign: "center",
                alignItems: "center",

                margin: "0px auto",

                paddingLeft: "16px",
                paddingRight: "16px",
              }
          }
          disableGutters
        >
          {/* Desktop PayWell Logo */}
          <a href="/">
            <Typography
              style={{ color: "#494949" }}
              variant="h6"
              noWrap
              component="div"
              sx={{ mr: 2, display: "flex" }}
            >
              <img src={image} alt="PayWell Logo" style={{ height: "1em" }} />
            </Typography>
          </a>
          <a href="/">
            {/* Mobile PayWell Logo */}
            <Typography
              style={{ color: "#494949" }}
              variant="h6"
              noWrap
              component="div"
              sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}
            >
              {/* <img src={image} alt="PayWell Logo" style={{ height: "1em" }} /> */}
            </Typography>
          </a>

          {/* Desktop Menu Links */}
          <Box
            sx={
              props.hideMenu
                ? { flexGrow: 1, display: { xs: "none", md: "none" } }
                : { flexGrow: 1, display: { xs: "none", md: "flex" } }
            }
          >
            {pages.map((page) => (
              <Button
                key={page}
                onClick={handleCloseNavMenu}
                sx={{ my: 2, color: "#494949", display: "block", lineHeight: "inherit !important" }}
                href={page === "About" ? "/" : page}
              >
                {page}
              </Button>
            ))}

            {/* Integration Menu Button
            <Button
              aria-controls="integration-menu"
              aria-haspopup="true"
              aria-expanded={openIntegrationMenu ? 'true' : undefined}
              onClick={handleIntegrationMenuClick}
              endIcon={<KeyboardArrowDownIcon />}


              sx={{ my: 2, color: "#494949", display: "flex", lineHeight: "inherit !important" }}
            >
              Integrations
            </Button> */}

            {/* Integration Dropdown Menu */}
            {/* <Menu
              id="integration-menu"
              anchorEl={integrationMenuAnchorEl}
              open={openIntegrationMenu}
              onClose={handleIntegrationMenuClose}
              MenuListProps={{
                'aria-labelledby': 'integration-button',
              }}
            > */}
              {/* {integrations.map((integration) => (
                <MenuItem
                  key={integration}
                  onClick={handleIntegrationMenuClose}
                  component={Link}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "8px",
                  }}
                  to={`/beta/${integration}?integration=${encodeURIComponent(integration)}`}
                >
                  <Typography style={{ color: "#494949" }} textAlign="center">
                    {integration}
                  </Typography>

                </MenuItem>

              ))} */}
            {/* </Menu> */}


            <Box>


            </Box>
          </Box>

          {props.showUser && currentUser ? (
            <Box
              className="nav-bar__links"
              style={{
                display: "flex",
                gap: "16px",
                marginLeft: "auto",
                marginRight: "16px",
              }}
            >
              <Button
                onClick={() =>
                  history.push({
                    pathname: "/staff-emailer",
                    search: location.search, // this includes the current query parameters
                  })
                }
                style={{ color: "#6c6c72" }}
                startIcon={
                  <SendIcon style={{ color: "#6c6c72", fontSize: "18px" }} />
                }
              >
                Email Paystubs
              </Button>
              <Button
                onClick={() =>
                  history.push({
                    pathname: "/referrals",
                    search: location.search, // this includes the current query parameters
                  })
                }
                style={{ color: "#6c6c72" }}
                startIcon={
                  <RedeemRoundedIcon
                    style={{ color: "#6c6c72", fontSize: "20px" }}
                  />
                }
              >
                Refer an Owner
              </Button>
              {/* Resources Button */}
              <Button
                onClick={() =>
                  history.push({
                    pathname: "/resources",
                    search: location.search, // this includes the current query parameters
                  })
                }
                style={{ color: "#6c6c72" }}
                startIcon={
                  <HelpCenterIcon
                    style={{ color: "#6c6c72", fontSize: "20px" }}
                  />
                }
              >
                Resources
              </Button>

              {/* Help Button */}
              <Button
                id="customer-support-ticket-help-button-desktop"
                onClick={handleOpenHelpDrawer}
                style={{ color: "#6c6c72" }}
                startIcon={
                  <QuestionMarkRoundedIcon
                    style={{ color: "#6c6c72", fontSize: "20px" }}
                  />
                }
              >
                Help
              </Button>
            </Box>
          ) : null}
          {/* Login & Get Started & Account Settings */}
          <Box sx={{ flexGrow: 0 }}>
            {props.showUser && currentUser ? (
              <Tooltip title="Open settings">
                <Button
                  onClick={handleOpenUserMenu}
                  variant="outlined"
                  sx={{
                    padding: "18px",
                    minWidth: "34px",
                    maxWidth: "34px",
                    minHeight: "34px",
                    maxHeight: "34px",
                    borderRadius: "40px",
                  }}
                >
                  <Typography component={"span"}>
                    <span
                      id="userEmail"
                      data-email={currentUser.email ? currentUser.email : null}
                    >
                      {currentUser.email
                        ? currentUser.email.charAt(0).toUpperCase()
                        : ""}
                    </span>
                  </Typography>
                </Button>
              </Tooltip>
            ) : (
              <div className="app-bar__buttons app-bar__buttons--homepage">
                <Button
                  className="app-bar__button app-bar__button--sign-in"
                  disableElevation={true}
                  href="/dashboard"

                  sx={{ borderRadius: "40px", color: "#006bff" }}
                >
                  Sign in
                </Button>

                <Link
                  className="app-bar__button app-bar__button--get-started"
                  style={{ textDecoration: "none" }}
                  to={`/contact`}
                >
                  <Button
                    disableElevation={true}
                    variant="contained"
                    sx={{
                      borderRadius: "40px",
                      bgcolor: "#006bff",
                      marginLeft: "1em",
                    }}
                  >
                   Contact Us
                  </Button>
                </Link>
              </div>
            )}


            <Menu
              sx={{ mt: "45px" }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '16px' }}>
                <Avatar sx={{ mb: '8px' }}>{currentUser?.email?.charAt(0).toUpperCase()}</Avatar>
                <Typography sx={{ color: "#494949" }}>{currentUser?.email}</Typography>
              </Box>
              <Divider />
              <Box sx={{ paddingTop: "8px" }}>

                {/* If current user is admin show admin nav link */}
                <MenuItem
                  hidden={!isAdmin}
                  onClick={() => history.push({
                    pathname: "/admin/user-impersonation"
                  })}
                  key={"admin"}
                  label={"Admin"}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "8px",
                  }}
                >
                  <AdminPanelSettingsIcon style={{ color: "#6c6c72", fontSize: "20px" }} />


                  <Typography style={{ color: "#494949" }} textAlign="center">
                    Admin
                  </Typography>
                </MenuItem>


                {settings.map((label, i) =>
                  label !== "Logout" ? (
                    <MenuItem
                      onClick={() => handleCloseUserMenu(label)}
                      key={label}
                      label={label}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "8px",
                      }}
                    >
                      {settingsIcons[i]}

                      <Typography style={{ color: "#494949" }} textAlign="center">
                        {label}
                      </Typography>
                    </MenuItem>
                  ) : (
                    <MenuItem
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "8px",
                      }}
                      onClick={handleLogout}
                      key={label}
                      label={label}
                    >
                      {
                        <LogoutRoundedIcon
                          style={{ color: "#6c6c72", fontSize: "20px" }}
                        />
                      }
                      <Typography
                        component={"span"}
                        style={{ color: "#494949" }}
                        textAlign="center"
                      >
                        {label}
                      </Typography>
                    </MenuItem>
                  )
                )}


              </Box>
            </Menu>

          </Box>
          {/* Hamburger Menu */}
          <Box
            sx={
              props.hideMenu
                ? { display: { xs: "none", md: "none" } }
                : { display: { xs: "flex", md: "none" } }
            }
          >


            <Dropdown
              menuItems={menuItems}
              trigger={
                <IconButton
                  size="large"
                  aria-label="menu"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  style={{
                    color: "#000000", marginRight: "-12px"
                  }}
                >
                  <MenuIcon />
                </IconButton>}

            />
          </Box>
        </Toolbar>
      </nav>
    </>
  );
};
export default ResponsiveAppBar;
