import React, { useEffect, useState } from "react";
import { TextField, Button } from "@mui/material";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import CustomizedSnackbars from "../Snackbar";
import { useAuth } from "../../contexts/AuthContext";
import { Link, useHistory, useParams } from "react-router-dom";
import image from "../../img/PayWellLogoBlack.png";
import "./auth.css";
import BasicModal from "../Modal/Modal";
import { useSnackBar } from "../../contexts/SnackBarContext/SnackBarContext";

const defaultValues = {
  email: "",
  password: "",
  passwordConfirmation: "",
};

const referralModal = () => {
  const params = useParams();

  return <BasicModal params={params} referral={true}></BasicModal>;
};
const Signup = () => {
  const params = useParams();
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);
  const { signup, currentUser, verifyEmail } = useAuth();
  const history = useHistory();
  const showSnackBar = useSnackBar();

  const [formValues, setFormValues] = useState(defaultValues);
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    if (formValues.password !== formValues.passwordConfirmation) {
      return showSnackBar("Passwords do not match", "error");
    }
    let res;
    try {
      setError("");
      setLoading(true);
      if (params) {
        res = await signup(formValues.email, formValues.password, params);
      } else {
        res = await signup(formValues.email, formValues.password);
      }
      if (res.email) {
        showSnackBar("Signing in...", "success");
        setTimeout(() => history.push("/dashboard"), 2000);
      } else {
        showSnackBar(res.message, "error");
      }
    } catch (e) {
      console.error(e);
      showSnackBar(e.message, "error");
    }
    setLoading(false);
  };

  useEffect(
    () =>
      params
        ? setFormValues({
            email: `${params?.refereeEmail || ""}`,
            password: "",
            passwordConfirmation: "",
          })
        : null,
    []
  );
  return (
    <>
      <Box
        className="auth"
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          alignItems: "center",
          padding: "65px 20px",
        }}
      >
        {/* Desktop PayWell Logo */}
        <a className="auth__paywell" href="/">
          <Typography
            style={{
              textAlign: "center",
              color: "#494949",
              marginBottom: "30px",
            }}
            variant="h6"
            noWrap
            component="div"
          >
            <img src={image} alt="PayWell Logo" style={{ height: "1.25em" }} />
          </Typography>
          <Typography
            style={{
              textAlign: "center",
              color: "#494949",
              marginBottom: "30px",
            }}
            variant="h6"
            noWrap
            component="div"
          >
            Signup up with PayWell
          </Typography>
        </a>
        <form className="auth__form" onSubmit={handleSubmit}>
          <Card
            className="auth__card"
            style={{
              background: "rgb(255, 255, 255)",
              border: "1px solid rgb(230, 230, 230)",
              boxSizing: "border-box",
              boxShadow: "rgb(45 40 77 / 10%) 0px 12px 32px",
              borderRadius: "8px",
              minWidth: "275px",
              width: "100vw",
              maxWidth: "484px",
            }}
          >
            <CardContent
              className="auth__card-content"
              style={{
                display: "flex",
                flexDirection: "column",
                margin: "1em",

                marginLeft: "1em",
              }}
            >
              <Box
                className="auth__box auth__box--align-center"
                style={{
                  display: "flex",
                  width: "100%",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Box
                  className="auth__box  auth__box--align-start"
                  style={{
                    display: "flex",
                    width: "100%",
                    flexDirection: "column",
                    alignItems: "flex-start",
                  }}
                >
                  <TextField
                    className="auth__text-field"
                    fullWidth
                    name="email"
                    label="Enter your email"
                    type="text"
                    value={params.refereeEmail ?? formValues.email}
                    onChange={handleInputChange}
                    autoComplete="email"

                  />
                </Box>
                <Box
                  className="auth__box auth__box--align-start"
                  style={{
                    marginTop: "1em",
                    display: "flex",
                    width: "100%",
                    flexDirection: "column",
                    alignItems: "flex-start",
                  }}
                >
                  <TextField
                    className="auth__text-field"
                    fullWidth
                    name="password"
                    label="Enter your password"
                    type="password"
                    value={formValues.password}
                    onChange={handleInputChange}
                    autoComplete="new-password"

                  />
                </Box>
              </Box>
              <Box
                className="auth__box auth__box--align-start"
                style={{
                  marginLeft: ".25em",
                  display: "flex",
                  marginTop: "1em",
                  flexDirection: "column",
                  alignItems: "flex-start",
                }}
              >
                <TextField
                  className="auth__text-field"
                  fullWidth
                  name="passwordConfirmation"
                  label="Confirm your password"
                  type="password"
                  value={formValues.passwordConfirmation}
                  onChange={handleInputChange}
                  autoComplete="new-password"

                />
              </Box>
              <Button
                className="auth__button"
                disabled={loading}
                type="submit"
                style={{
                  position: "relative",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                  marginTop: "2em",
                  backgroundColor: "#006bff",
                  minHeight: "66px",
                  borderRadius: "40px",
                }}
                onClick={handleSubmit}
                variant="contained"
                href="contact"
              >
                Submit
              </Button>
            </CardContent>
          </Card>
          <Box
            className="auth__box"
            style={{ textAlign: "center", marginTop: "1em" }}
          >
            Already have an account? <Link to="/login">Sign In</Link>
          </Box>
        </form>
      </Box>
      {params["referralCode"] ? referralModal() : null}
    </>
  );
};
export default Signup;
