// export class CatchAllCommissionLogic {
//   constructor(
//     type,
//     itemName,
//     salesperson,
//     client,
//     location,
//     price,
//     notes,
//     rate
//   ) {
//     this.type = type;
//     this.itemName = itemName;
//     this.salesperson = salesperson;
//     this.client = client;
//     this.location = location;
//     this.price = price;
//     this.notes = notes;
//     this.rate = rate;

//     this.fillInBlankLogic();
//   }

//   fillInBlankLogic() {
//     if (this.type === "") {
//       this.type = "Any";
//     }
//     if (this.itemName === "") {
//       this.itemName = "All";
//     }
//     if (this.salesperson === "" ) {
//       this.salesperson = "Any";
//     }
//     if (this.type === "") {
//       this.type = "Any";
//     }
//     if (this.client === "") {
//       this.client = "Any";
//     }
//     if (this.location === "") {
//       this.location = "Any";
//     }
//     if (this.sequence === "") {
//       this.sequence = "Any";
//     }
//     if (this.price === "") {
//       this.price = "Any";
//     }
//     if (this.notes === "") {
//       this.notes = "Any";
//     }
//     if (this.rate === "") {
//       this.rate = 0;
//     }
//   }
// }

export class CatchAllCommissionLogic {
  constructor() {
    this.types = [];
    this.itemName = "ANY NAME";
    this.locations = [];
    this.priceSequence = "ANY PRICE";
    this.price = 0;
    this.notes = "";

    this.rateFrequency = "Never Pay"
    this.rateNormal = true;
    this.rate = 0;
    this.split = true;
    this.salespeople = [];

    //this.fillInBlankLogic();
  }

  addTypes(typeArray){
    this.types = typeArray;
  }
  
  addItemName(itemName){
    this.itemName = itemName;
  }

  addLocations(locationsArray){
    this.locations = locationsArray;
  }

  addPrice(sequence, amount){
    this.priceSequence = sequence;
    this.price = amount;
  }

  addNotes(notes){
    this.notes = notes;
  }

  addRateType(frequency, normal){
    this.rateFrequency = frequency;
    this.rateNormal = normal;
  }

  addRate(rate, split, salespeople){
    this.rate = rate;
    this.split = split;
    this.salespeople = salespeople;
  }

  fillInBlankLogic() {
    if (this.type === "") {
      this.type = "Any";
    }
    if (this.itemName === "") {
      this.itemName = "All";
    }
    if (this.location === "") {
      this.location = "Any";
    }
    if (this.sequence === "") {
      this.sequence = "Any";
    }
    if (this.price === "") {
      this.price = "Any";
    }
    if (this.notes === "") {
      this.notes = "Any";
    }
    if (this.rate === "") {
      this.rate = 0;
    }
  }
}
