import React, { useState, useEffect, useRef } from 'react';
import { useParams, useLocation, useHistory } from 'react-router-dom';
import { resources } from './resources';
import SearchBar from './SearchBar';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import './CategoryPage.css';
import "./resourcesGlobalStyles.css";
import PayWellLogoBlack from '../../img/PayWellLogoBlack.png';
import { Button } from '@mui/material';
import { ArrowBackIosRounded } from '@mui/icons-material';


function useQuery() {
    return new URLSearchParams(useLocation().search);
}

const CategoryPage = () => {
    const { resourceName } = useParams();
    const query = useQuery();
    const history = useHistory();
    const [resourceDetails, setResourceDetails] = useState({
        name: '',
        slug: '',
        content: [],
        chapters: [],
    });

    useEffect(() => {
        const resource = resources.find((res) => res.name === resourceName);
        if (resource) {
            setResourceDetails(resource);
        }
    }, [resourceName]);

    const startTime = query.get('start');




    const generalInfoRef = useRef(null);
    const contentRef = useRef(null);
    const attachmentsRef = useRef(null);

    useEffect(() => {
        const resource = resources.find(res => res.name === resourceName);
        if (resource) {
            setResourceDetails(resource);
        }
    }, [resourceName]);


    function handleBackClick() {
        const hasUnsavedChanges = false;
        if (hasUnsavedChanges) {
            // Handle unsaved changes if necessary
        } else {
            history.push({
                pathname: "/resources",
                search: window.location.search,
            });
        }
    }

    const handleScroll = () => {
        const sections = [
            { id: "generalInfo", ref: generalInfoRef },
            { id: "content", ref: contentRef },
            { id: "attachments", ref: attachmentsRef }
        ];

        const scrollPosition = window.scrollY + 200; // Offset for highlighting

        sections.forEach(section => {
            if (
                section.ref.current &&
                section.ref.current.offsetTop <= scrollPosition &&
                section.ref.current.offsetTop + section.ref.current.offsetHeight > scrollPosition
            ) {
                setActiveSection(section.id);
            }
        });
    };

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);


    return (
        <div className="resources category-page">
            <div className="header-container">
                <Button
                    className="drawer__back-button "
                    onClick={handleBackClick}
                    startIcon={<ArrowBackIosRounded className="icon" />}
                >
                    Resources
                </Button>
                <div className="title-container">
                    <img src={PayWellLogoBlack} alt="PayWell Logo" className="paywell-logo" />
                    <h1 className="resources__h1">knowledge base</h1>
                </div>
            </div>

            <SearchBar />

            <main className="main-content container">

                <div className="resource-content">
                    <h1 className="resources__h1">{resourceDetails.name.replaceAll('-', ' ')}</h1>

                    <div className="general-info">
                        <p>
                            Last Updated: {resourceDetails.lastUpdated}
                        </p>
                        <p>
                            <AccessTimeIcon className="icon" /> Estimated Learning Time:{' '}
                            {resourceDetails.estimatedReadingTime}
                        </p>
                    </div>

                    {resourceDetails.slug && (
                        <div className="video-container">
                            <iframe
                                src={`https://www.loom.com/embed/${resourceDetails.slug}${startTime ? `?t=${startTime}` : ''
                                    }`}
                                frameBorder="0"
                                allowFullScreen
                                title={resourceDetails.name}
                            ></iframe>
                        </div>
                    )}

                    {resourceDetails.chapters.length > 0 && (
                        <div className="chapters-section">
                            <h2 className="resources__h2">Chapters</h2>
                            <ul className="chapters-list">
                                {resourceDetails.chapters.map((chapter, index) => (
                                    <li key={index}>
                                        <a
                                            href={`?start=${calculateSeconds(chapter.timestamp)}`}
                                            className="chapter-link"
                                        >
                                            {chapter.title} - {chapter.timestamp}
                                        </a>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    )}

                    {resourceDetails.content.length > 0 && (
                        <div className="text-content">
                            <h2 className="resources__h2">Info</h2>
                            {resourceDetails.content.map((item, index) =>
                                item.type === 'paragraph' ? (
                                    <p key={index}>{item.text}</p>
                                ) : (
                                    <img key={index} src={item.src} alt={item.alt} />
                                )
                            )}
                        </div>
                    )}

                    {resourceDetails.attachments?.length > 0 && (
                        <div className="attachments-section">
                            <h2 className="resources__h2">Attachments</h2>
                            <ul>
                                {resourceDetails.attachments.map((attachment, index) => (
                                    <li key={index}>
                                        <a
                                            href={`/ResourceFiles/${attachment.url}`}
                                            download={attachment.url}
                                        >
                                            {attachment.name}
                                        </a>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    )}

                    {resourceDetails.relatedResources?.length > 0 && (
                        <div className="related-section">
                            <h2 className="resources__h2">Related Resources</h2>
                            <ul>
                                {resourceDetails.relatedResources.map((related, index) => (
                                    <li key={index}>
                                        <a href={`/resources/${encodeURIComponent(related)}`}>
                                            {related}
                                        </a>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    )}

                    <div className="support-section">
                        <h2 className="resources__h2">Still need help?</h2>
                        <p>
                            Contact our support team at{' '}
                            <a href="mailto:support@paywell.com">support@paywell.com</a> or
                            visit our <a href="/resources">Support Center</a>.
                        </p>
                    </div>
                </div>
            </main>
        </div>
    );
};

function calculateSeconds(timestamp) {
    if (!timestamp) return 0;
    const [hours, minutes, seconds] = timestamp.split(':').map(Number);
    return hours * 3600 + minutes * 60 + seconds;
}

export default CategoryPage;
