export class CellReference {
  constructor(type, instructor, studio, row, column, value = 0) {
    this.type = type;
    this.instructor = instructor;
    this.studio = studio;
    this.row = row;
    this.column = column;

    this.valueAmount = value;
    
    this.detail = false;
    this.value = null;
  }
}
