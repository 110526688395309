// import * as React from 'react';
// import OfflineDialog from '../Dialogs/OfflineDialog';
// const getOnLineStatus = () =>
//     typeof navigator !== 'undefined' && typeof navigator.onLine === 'boolean'
//         ? navigator.onLine
//         : true;

// const useNavigatorOnLine = () => {
//     const [status, setStatus] = React.useState(getOnLineStatus());

//     const setOnline = () => setStatus(true);
//     const setOffline = () => setStatus(false);

//     React.useEffect(() => {
//         window.addEventListener('online', setOnline);
//         window.addEventListener('offline', setOffline);

//         return () => {
//             window.removeEventListener('online', setOnline);
//             window.removeEventListener('offline', setOffline);
//         };
//     }, []);

//     return <OfflineDialog offline={!status} />;
// };

// export default function NetworkStatusIndicator () {

//     const offline = useNavigatorOnLine();

//     return offline;
// };



import React from 'react';
import OfflineDialog from '../Dialogs/OfflineDialog';
import { useNetworkStatus } from '../../contexts/NetworkStatusContext/NetworkStatusContext';

export default function NetworkStatusIndicator() {
  const offline = useNetworkStatus();

  return <OfflineDialog offline={offline} />;
}
