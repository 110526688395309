import React from 'react';
import {
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  FormControlLabel,
  Checkbox,
  Tooltip,
  Typography,
  Divider,
  Switch,
} from '@mui/material';

import { useState } from 'react';

const RateObjectComponent = ({ rateName, rate, onRateChange, bucketName, on }) => {

  if(!rate){
    rate = {
      rate: 0,
      rateType: 'Fixed',
      split: false,
      salespeople: [],
    };
  }
  
  const [paid, setPaid] = useState(rate.rate !== 0);

  const [rateState, setRateState] = useState(rate);

  const handleFieldChange = (field, value) => {
    setRateState({ ...rate, [field]: value });
    onRateChange({ ...rate, [field]: value }, "rates-single");
  };

  const handleToggleChange = (e) => {
    setPaid(e.target.checked);
    if(!e.target.checked){
      setRateState({ ...rate, rate: 0 });
      onRateChange({ ...rate, rate: 0 }, "rates-single");
    } else {
      setRateState({ ...rate });
      onRateChange({ ...rate }, "rates-single");
    }
  };

  if (!on) {
    return null;
  }

  return (
    <Box sx={{ padding: 2, border: '1px solid #ddd', borderRadius: 2, backgroundColor: '#f9f9f9' }}>
      <Typography variant="h6" gutterBottom>
        {rateName + " " + bucketName}
      </Typography>
      <Divider sx={{ marginBottom: 2 }} />

      {/* Paid Toggle */}
      <Box display="flex" alignItems="center" sx={{ marginBottom: 2 }}>
        <Typography variant="body1" sx={{ marginRight: 1 }}>Unpaid</Typography>
        <Switch
          checked={paid}
          onChange={handleToggleChange}
          color="primary"
        />
        <Typography variant="body1" sx={{ marginLeft: 1 }}>Paid</Typography>
      </Box>

      {paid && (
        <>
          {/* Rate Type Selector */}
          <FormControl fullWidth sx={{ marginBottom: 2 }}>
            <InputLabel>Rate Type</InputLabel>
            <Select
              value={rateState.rateType}
              label="Rate Type"
              onChange={(e) => handleFieldChange('rateType', e.target.value)}
            >
              <MenuItem value="Fixed">Flat Dollar Amount</MenuItem>
              <MenuItem value="PercentFull">Percent of Payment</MenuItem>
              {/* <MenuItem value="PercentPortion">Percent Portion</MenuItem> */}
              <MenuItem value="Delta">Percent of Delta</MenuItem>
            </Select>
          </FormControl>

          {/* Rate Input Field */}
          <TextField
            label="Rate"
            type="number"
            value={rateState.rate}
            onChange={(e) => handleFieldChange('rate', parseFloat(e.target.value))}
            InputLabelProps={{ shrink: true }}
            fullWidth
            sx={{ marginBottom: 2 }}
          />

          {/* Split Checkbox */}
          <Tooltip title="Check if the rate should be split between multiple salespeople">
            <FormControlLabel
              control={
                <Checkbox
                  checked={rateState.split}
                  onChange={(e) => handleFieldChange('split', e.target.checked)}
                />
              }
              label="Split"
              sx={{ marginBottom: 2 }}
            />
          </Tooltip>

          {/* Salespeople Selector */}
          <FormControl fullWidth>
            <InputLabel>Salespeople</InputLabel>
            <Select
              multiple
              value={rateState.salespeople}
              label="Salespeople"
              onChange={(e) => handleFieldChange('salespeople', e.target.value)}
              renderValue={(selected) => selected.join(', ')}
            >
              {['Open', 'Close', 'Instructor', 'Second', 'Refer'].map((name) => (
                <MenuItem key={name} value={name}>
                  {name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </>
      )}
    </Box>
  );
};

export default RateObjectComponent;
