import React, { useState, useEffect } from "react";
import {
  Container,
  TextField,
  Grid,
  Typography,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  OutlinedInput,
  FormGroup,
  FormControlLabel,
  Switch,
  Card,
  CardContent,
  Autocomplete,
  Button,
  DialogActions,
} from "@mui/material";
import StaffAutocomplete from "../StaffAutoComplete";
import { useSettingsContext } from "../../contexts/SettingsContext";
import { deepClone } from "./utils";
import { findStaffByName } from "./utils";
import "./staffDialog.scss";
import { useAuth } from "../../contexts/AuthContext";
import { updateStaffMasterSettings } from "../../services/staff.services";
import { useSnackBar } from "../../contexts/SnackBarContext/SnackBarContext";
import { objFieldChange } from "../../pages/StaffSettings/utils";
import { RevertList } from "../../pages/Components/RevertList";
import { getUrlVariableValue } from "../../utility-functions/utility-functions";
import { LoadingButton } from "@mui/lab";
const MergeStaffComponent = ({ onMerge, onClose, staff }) => {
  const showSnackBar = useSnackBar();
  const {
    settings,
    reportCompilerDispatch,
    dispatch,
    changes,
    setChanges,
    setSaveDisabled,
  } = useSettingsContext();
  const { getUID } = useAuth();
  // Include logic to handle selection and merging process
const [loading, setLoading] = useState(false);
  const [selectedStaff, setSelectedStaff] = useState();
  const [selectedStaffType, setSelectedStaffType] = useState("");


  useEffect(() => {
    if (staff.errorType === "wrongStaffType") {
      const settingsId = getUrlVariableValue("settingsId");
      const staffTypes = settings[settingsId].generalSettings.staffTypes
        .filter(type => type.sessionPay);
      if (staffTypes.length > 0) {
        setSelectedStaffType(staffTypes[0].name); // Set to the first staff type name
      }
    }
  }, [staff, settings]);

  useEffect(()=>{
    
const existingStaff =  findStaffByName(settings.staff.staff, staff.nearestName || staff.name);


setSelectedStaff(existingStaff);
  },[])

  const handleOnSelect = (staff) => {
    setSelectedStaff(staff);
  };

  const handleOnMerge = () => {
    setLoading(true);
  
    var updatedSelectedStaff = deepClone(selectedStaff);
    var updatedField;
    var updatedAlternateNames = [...selectedStaff.alternateNames, staff.name];
    var updatedLocations = [...selectedStaff.locations, staff.location];
    if (staff.errorType == "unaddedLocation") {
      if (!selectedStaff.locations.includes(staff.location)) {
        updatedSelectedStaff.locations = updatedLocations;
        updatedField = "locations";
      }
    } else if (staff.errorType == "unaddedAlternateName") {
      if (!selectedStaff.alternateNames.includes(staff.name)) {
        updatedSelectedStaff.alternateNames = updatedAlternateNames;
        updatedField = "alternateNames";
      }
    } else if (staff.errorType == "wrongStaffType") {
      updatedSelectedStaff.type = selectedStaffType;
      updatedField = "type";
      
    } else if (staff.errorType == "unaddedStaff") {
      if (!selectedStaff.alternateNames.includes(staff.name)) {
        updatedSelectedStaff.alternateNames = updatedAlternateNames;
        updatedField = "alternateNames";
      }
    }
    updatedSelectedStaff.status = "update";
    updatedSelectedStaff.updated = true;

    setSelectedStaff(updatedSelectedStaff);


    handleSaveMergedStaff(updatedSelectedStaff, updatedField);
  };

  const handleSaveMergedStaff = async (staff, updatedField) => {
    
    // Check if 'name' field is empty
    if (!staff.name || staff.name.trim() === "") {
      throw new Error("Staff name cannot be empty"); // Prevent the row update
    }

    const updatedRow = {
      ...staff,
      isNew: false,
      updated: true,
      status: staff.status ? staff.status : "update",
      statusField: updatedField,
    };

    let prevVal = settings.staff.staff[updatedRow.id];
    let newRev = new RevertList(changes.getValues());
    newRev.addElement(updatedRow.id, prevVal, updatedRow);
    setChanges(newRev);

    dispatch({
      type: "UPDATE_STAFF_SETTINGS_STAFF_MEMBER",
      uid: getUID(),
      id: updatedRow.id,
      newStaff: updatedRow,
    });


    
    const newStaffObjects = {};
    newStaffObjects[updatedRow.id] = updatedRow;

    const updateSuccessful = await updateStaffMasterSettings(
      getUID(),
      newStaffObjects
    );
    if (updateSuccessful) {
      setLoading(false);
      showSnackBar(`Successfully updated ${staff.name}`, "success");
      reportCompilerDispatch({
        type: "BUILD_REPORT_COMPILER_STATE_STAFF",
      });
      setChanges(new RevertList([]));
      setSaveDisabled(true);

      onMerge(selectedStaff);
    } else {
      showSnackBar(`Failed to update ${staff.name}`, "error");
      setLoading(false);
    }
  };

  var ContentComponent = () => {
    
    let content;
    if (staff.errorType === "wrongStaffType") {
      return (
        <>
          <Typography className="merge-staff-component__text" variant="body1" component={"p"}>
            Change <strong>{staff.name}</strong> from <strong>{staff.type}</strong> to type:
          </Typography>
          <Select
            value={selectedStaffType}
            // onChange={(e) => setSelectedStaffType(e.target.value)}
            onC
            onSelect={(e) => setSelectedStaffType(e.target.value)}
            displayEmpty
            fullWidth
          >
            {/* {getStaffTypeOptions()} */}
            {settings[getUrlVariableValue("settingsId")].generalSettings.staffTypes
              .filter(type => type.sessionPay)
              .map(type => <MenuItem key={type.name} value={type.name}>{type.name}</MenuItem>)}
          </Select>
        </>
      );
    } else if (
      staff.errorType == "unaddedStaff" ||
      staff.errorType == "unaddedAlternateName"
    ) {
      content = (
        <div>
          <p className="merge-staff-component__text">
            Select the staff that is <span>{staff.name}</span>
          </p>
          <StaffAutocomplete
            onSelect={handleOnSelect}
            allStaff={settings.staff.staff}
            selectedStaff={selectedStaff}
          />
          {selectedStaff && (
            <Typography
              variant="caption"
              className="merge-staff-component__caption"
            >
              This will add the name <span>{staff.name}</span> to the list of
              alternate names for the existing staff{" "}
              <span>{selectedStaff.name}</span>
            </Typography>
          )}
        </div>
      );
    } else if (staff.errorType == "unaddedAlternateName") {
      // content = (
      //   <>
      //     {/* <p className="merge-staff-component__text">
      //       Add the name <span>{staff.name}</span> to the list of alternate
      //       names for the existing staff <span>{staff.nearestName}</span>
      //     </p> */}
      //     <p className="merge-staff-component__text">
      //       Select the staff that is <span>{staff.name}</span>
      //     </p>
      //     <StaffAutocomplete
      //       onSelect={handleOnSelect}
      //       allStaff={settings.staff.staff}
      //       selectedStaff={selectedStaff}
      //     />
      //     {selectedStaff && (
      //       <Typography
      //         variant="caption"
      //         className="merge-staff-component__caption"
      //       >
      //         This will add the name <span>{staff.name}</span> to the list of
      //         alternate names for the existing staff{" "}
      //         <span>{selectedStaff.name}</span>
      //       </Typography>
      //     )}
      //   </>
      // );
    } else if (staff.errorType == "unaddedLocation") {
      content = (
        <p className="merge-staff-component__text">
          Assign <span>{staff.name}</span> to <span> {staff.location} </span>?
        </p>
      );
    }

    return (
      <>
        {content}
        {/* <StaffAutocomplete
          onSelect={handleOnSelect}
          allStaff={settings.staff.staff}
          selectedStaff={selectedStaff}
        /> */}
      </>
    );
  };

  return (
    <div className="merge-staff-component">
      <div className="merge-staff-component__content">
        <ContentComponent />
      </div>
      <DialogActions className="merge-staff-component__actions">
        <Button onClick={onClose}>Cancel</Button>
        <LoadingButton loading={loading} variant="contained" color="primary" onClick={handleOnMerge}>
          Confirm
        </LoadingButton>
      </DialogActions>
    </div>
  );
};
export default MergeStaffComponent;
