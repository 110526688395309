import * as React from "react";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { useDrawerContext } from "./drawerContext";

const drawerWidth = 240;
import "./drawer.scss";
function ResponsiveDrawer(props) {
  const { drawerState, drawerDispatch } = useDrawerContext();
  const { window } = props;

  const drawer = props.drawerContent;

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <Box className="drawer-container">
      {/* <CssBaseline /> */}
      <AppBar
        className="drawer__appbar"
        position="relative"
        style={{ height: "fit-content" }}
      >
        <Toolbar disableGutters className="drawer__toolbar">
          <IconButton
            className="drawer__icon-button"
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={() =>
              drawerDispatch({
                type: "OPEN_DRAWER",
              })
            }
            size="large">
            <MenuIcon />
          </IconButton>
          <Typography
            className="drawer__h6"
            variant="h6"
            noWrap
            component="div"
          >
            {drawerState.pageTitle}
          </Typography>
          <Box style={{ marginLeft: "auto" }}>{props.saveButton}</Box>
        </Toolbar>
      </AppBar>
      <Box
        className="drawer__nav"
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          className="drawer__nav-drawer"
          container={container}
          variant="temporary"
          open={drawerState.mobileOpen}
          onClose={() => drawerDispatch({ type: "CLOSE_DRAWER" })}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          className="drawer__nav-drawer--desktop"
          variant="permanent"
          open
        >
          {drawer}
        </Drawer>
      </Box>
      <Box className="drawer__page-content" component="main">
        {props.pageContent}
      </Box>
    </Box>
  );
}

ResponsiveDrawer.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default ResponsiveDrawer;
