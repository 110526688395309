import React from "react";
import Box from "@mui/material/Box";
import LinearStepper from "./LinearStepper/LinearStepper";
import ResponsiveAppBar from "./AppBar/ResponsiveAppBar";

function Dashboard(props) {


  return (
    <>
      <ResponsiveAppBar hideMenu={true} showUser={true} />
      <Box className="page">
        <LinearStepper />
      </Box>
    </>

  );
}
export default React.memo(Dashboard);
