import React, { useState } from "react";
import AdminNotificationForm from "./AdminNotificationForm";
import NotificationList from "./NotificationList";
import { Box, Grid } from "@mui/material";

export default function NotificationManager() {
  const [selectedNotification, setSelectedNotification] = useState(null);

  const handleSelectNotification = (notification) => {
    setSelectedNotification(notification);
  };

  const handleFormSubmit = () => {
    setSelectedNotification(null); // Reset the selected notification after form submission
  };

  return (
    <Box p={3}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <NotificationList  selectedNotification={selectedNotification} onSelectNotification={handleSelectNotification} />
        </Grid>
        <Grid item xs={12} md={6}>
          <AdminNotificationForm
            selectedNotification={selectedNotification}
            onFormSubmit={handleFormSubmit}
          />
        </Grid>
      </Grid>
    </Box>
  );
}
