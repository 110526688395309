export function convertToString(value){
    if(value === null){
        return null;
    }
    let valueType = typeof value;
    if(valueType === "string"){
        return value;
    }else if(valueType === "object"){
        return "";
    }else if(valueType === "boolean"){
        return ("" + value);
    }else if(valueType === "number"){
        return ("" + value);
    }
    return null;
}

export function convertToNumber(float, value){
    let convertedValue = value;
    if(!float){
        convertedValue = parseInt(value);
    }else if(float){
        convertedValue = parseFloat(value);
    }
    if(isNaN(convertedValue)){
        return null;
    }
    return convertedValue;    
}

export function convertToBoolean(value){
    let valueType = typeof value;
    if(valueType === "boolean"){
        return value;
    } else if(valueType === "string"){
        value = value.replaceAll(" ", "");
        if(value.toUpperCase() === "TRUE"){
            return true;
        }else if(value.toUpperCase() === "FALSE"){
            return false;
        }
    }
    return null;
}

export function convertToArray(value){
    let valueType = typeof value;
    if(valueType === "object"){
        if(Array.isArray(value)){
            return value;
        }
        return null;
    } else if(valueType === "string"){
        return [value];
    }
    return null;
}