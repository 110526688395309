import { TimeEvent } from "../ReportCompiler/classes/TimeEvent.js";
import { findHourlyPayRate } from "../utility-functions.js";
import { ReportData } from "../utility-functions.js";


export function deputyFileConverter(json, workbookName, reportCompilerState) {
  const timeArray = [];
  var staffInFile = [];
  let nameI = 0;

  let correctFileType = false;
  for (let i = 0; i < json.length; i++) {
    if (isDeputyHeaderRow(json[i])) {
      for (let u = 0; u < json[i].length; u++) {
        if (json[i][u].includes("Employee Name")) {
          nameI = u;
        }
      }
      correctFileType = true;
    } else if (isDeputyDataRow(json[i])) {
      let location = workbookName;

      let name = json[i][nameI];

      let hourRate = findHourlyPayRate(name, "All", reportCompilerState.studiosInformation); 

      for(let x = 7; x < json[i].length; x++){
        if(!json[0][x].includes('Total') && !isNaN(parseFloat(json[i][x])) && parseFloat(json[i][x]) !== 0){
            if(!isNaN(parseFloat(json[i][x])) && parseFloat(json[i][x]) > 0){
                const newStaff = {
                  name: name,
                  type: "time",
                  location: location,
                };
                
                let isDuplicate = staffInFile.some(staff => 
                  staff.name === newStaff.name && staff.type === newStaff.type && staff.location === newStaff.location
                );
                
                if (!isDuplicate) {
                  staffInFile.push(newStaff);
                }
                //staffInFile.push({"name": name, "type": "time", "location": location}); // bad
              }
            let timeEvent = createTimeEvent(name, location, parseFloat(json[i][x]), hourRate, json[0][x]);
            timeArray.push(timeEvent);
        }
      }
    }
  }

  let reportData = new ReportData(correctFileType, timeArray);
  reportData.staffInFile = staffInFile;

  return reportData;
}

function isDeputyHeaderRow(row){
  return (
    row[0].includes("Name") ||
    row[1].includes("Manager") ||
    row[2].includes("Total hours")
  );
}

function isDeputyDataRow(row){
  return (row[0] !== 'Employee First Name' && row[0] !== null && row[0] !== "");
}

function createTimeEvent(name, location, hoursStr, hourRate, position){
  let hours = parseFloat(hoursStr);
  return new TimeEvent(
    name,
    location,
    hours,
    hourRate,
    position,
    hours * hourRate
  );
}

export function deputyDetailFileConverter(json, reportCompilerState) {
  const timeArray = [];
  var staffInFile = [];
  let nameI = 1;
  let dateI = 2;
  let startTimeI = 3;
  let endTimeI = 4;
  let hoursI = 6;
  let locationI = 12;

  let correctFileType = false;

  for (let i = 0; i < json.length; i++) {
    if (isDeputyDetailHeaderRow(json[i])) {
      for (let u = 0; u < json[i].length; u++) {
        if (json[i][u].includes("Employee Name")) {
          nameI = u;
        } else if(json[i][u].includes("Date")){
          dateI = u;
        } else if(json[i][u].includes("Start")){
          startTimeI = u;
        } else if(json[i][u].includes("End")){
          endTimeI = u;
        } else if(json[i][u].includes("Total Hours")){
          hoursI = u;
        } else if(json[i][u].includes("Location Name")){
          locationI = u;
        }
      }
      correctFileType = true;
    } else if (isDeputyDetailDataRow(json[i])) {

      let name = json[i][nameI];
      let location = findDeputyLocation(json[i][locationI], reportCompilerState.payrollInformation.studiosInInput,);
      let hours = parseFloat(json[i][hoursI]);

      let hourRate = findHourlyPayRate(name, location, reportCompilerState.studiosInformation);
      let date = new Date(json[i][dateI]);

      let options = {
        weekday: "short",
        year: "numeric",
        month: "numeric",
        day: "numeric",
      };
      let formattedDate = date.toLocaleString("en-US", options);

      const newStaff = {
        name: name,
        type: "time",
        location: location,
      };
      
      let isDuplicate = staffInFile.some(staff => 
        staff.name === newStaff.name && staff.type === newStaff.type && staff.location === newStaff.location
      );
      
      if (!isDuplicate) {
        staffInFile.push(newStaff);
      }

      let timeEvent = createTimeEvent(name, location, hours, hourRate, formattedDate + " (" + json[i][startTimeI] + "-" + json[i][endTimeI] + ")");
      timeEvent.detail = true;
      timeEvent.date = date;
      timeEvent.clockIn = json[i][startTimeI];
      timeEvent.clockOut = json[i][endTimeI];
      timeArray.push(timeEvent);

    }
  }

  let reportData = new ReportData(correctFileType, timeArray);
  reportData.staffInFile = staffInFile;

  return reportData;
}

function isDeputyDetailHeaderRow(row){
  return (
    row[0].includes("Employee Export Code") ||
    row[1].includes("Employee Name") ||
    row[2].includes("Date")
  );
}

function isDeputyDetailDataRow(row){
  return (row[0] !== 'Employee Export Code' && row[0] !== null && row[1] !== "");
}

function findDeputyLocation(locationStr, settingsPresetStudios) {
  let studios = settingsPresetStudios;
  let location = closestMatch(locationStr, studios);

  return location;
}

function closestMatch(str, arr) {
  let minDistance = Infinity;
  let closestStr = "";

  for (let i = 0; i < arr.length; i++) {
    const distance = levenshteinDistance(str, arr[i]);

    if (distance < minDistance) {
      minDistance = distance;
      closestStr = arr[i];
    }
  }

  return closestStr;
}

function levenshteinDistance(s, t) {
  const m = s.length;
  const n = t.length;
  const d = [];

  for (let i = 0; i <= m; i++) {
    d[i] = [i];
  }

  for (let j = 0; j <= n; j++) {
    d[0][j] = j;
  }

  for (let j = 1; j <= n; j++) {
    for (let i = 1; i <= m; i++) {
      if (s[i - 1] === t[j - 1]) {
        d[i][j] = d[i - 1][j - 1];
      } else {
        d[i][j] = Math.min(
          d[i - 1][j] + 1, // deletion
          d[i][j - 1] + 1, // insertion
          d[i - 1][j - 1] + 1 // substitution
        );
      }
    }
  }

  return d[m][n];
}