import { Typography } from "@mui/material";
import BooleanToggleElement from "../../Components/BooleanToggle";
import SelectorBox from "../../Components/SelectorBox/SelectorBox";
import MultipleSelectCheckmarks from "../../../components/MultipleSelectCheckbox/MultipleSelectCheckbox";
import { Stack, Box } from "@mui/material";
import { Divider } from "@mui/material";
import ClassTotalOptions from "./ClassTotalOptions";
import ClassesHoursFlag from "./ClassesHoursFlag";
import { useSettingsContext } from "../../../contexts/SettingsContext";
import "./formatForm.scss";
import { useState } from "react";
import { RevertList } from "../../Components/RevertList";
import { useAuth } from "../../../contexts/AuthContext";
import { getUrlVariableValue } from "../../../utility-functions/utility-functions.js";
import {  Skeleton } from "@mui/material";

export default function FormatForm(props) {
  const { getUID } = useAuth();
  const { settings, dispatch, changes, setChanges } = useSettingsContext();
  
  const [
    classesHoursFlagToggleContent,
    setClassesHoursFlagToggleContent,
  ] = useState();
  function handleChange(value, id) {
    let newRev = new RevertList(changes.getValues());
    newRev.addElement(
      id,
      settings[getUrlVariableValue("settingsId")].formatSettings.general[id],
      value
    );
    setChanges(newRev);

    dispatch({
      type: "UPDATE_SETTINGS_PROPERTY",
      settingsId: getUrlVariableValue("settingsId"),
      uid: getUID(),
      path: `${getUrlVariableValue("settingsId")}.formatSettings.general.${id}`,
      value: value,
    });
  }
  const modifier = (className, value) => `${className}${!value ? "--off" : ""}`;

  

  return (settings.hasOwnProperty("1") && settings.hasOwnProperty("staff"))  ? (
    <Stack className="settings-page" spacing={1}>
    <Typography variant="h2" className="settings-page__title">
      Format
    </Typography>
    <Divider className="settings-page__divider" />

    <Box
      className={`settings-page__box settings-page__box--grid ${modifier(
        "settings-page__box",
        settings[getUrlVariableValue("settingsId")].formatSettings.general
          .organizeStaffLastName
      )}`}
    >
      <Typography className="settings-page__typography">
        Organize staff by last name:
      </Typography>
      <BooleanToggleElement
        onChange={handleChange}
        id="organizeStaffLastName"
        default={
          settings[getUrlVariableValue("settingsId")].formatSettings.general
            .organizeStaffLastName
        }
      ></BooleanToggleElement>
    </Box>

    <Divider className="settings-page__divider settings-page__divider--light" />
    <Box
      className={`settings-page__box settings-page__box--grid  ${modifier(
        "settings-page__box",
        settings[getUrlVariableValue("settingsId")].formatSettings.general
          .displayCostPerClass
      )}`}
    >
      <Typography className="settings-page__typography">
        {"Include a 'Cost-Per-" + settings[getUrlVariableValue("settingsId")].generalSettings.sessionName + "' metric in the summary tab:"}
      </Typography>
      <BooleanToggleElement
        onChange={handleChange}
        id="displayCostPerClass"
        default={
          settings[getUrlVariableValue("settingsId")].formatSettings.general
            .displayCostPerClass
        }
      ></BooleanToggleElement>
    </Box>

    <Divider className="settings-page__divider settings-page__divider--light" />
    <Box
      className={`settings-page__box settings-page__box--grid  ${modifier(
        "settings-page__box",
        settings[getUrlVariableValue("settingsId")].formatSettings.general
          .staffCommissionFormat !== "DO NOT INCLUDE"
      )}`}
    >
      <Typography className="settings-page__typography">
        {"In Staff Tabs, include " + settings[getUrlVariableValue("settingsId")].generalSettings.commissionName +" as a:"}
      </Typography>
      <SelectorBox
        id="staffCommissionFormat"
        onChange={handleChange}
        default={
          settings[getUrlVariableValue("settingsId")].formatSettings.general
            .staffCommissionFormat
        }
        size={200}
        options={[
          "DO NOT INCLUDE",
          "Single Summary Line",
          "Detailed Breakdown",
        ]}
        label="Staff Tab Commission Format"
        name="Staff Tab Commission Format"
      ></SelectorBox>
    </Box>
    <Divider className="settings-page__divider settings-page__divider--light" />
    <Box
      className={`settings-page__box settings-page__box--grid  ${modifier(
        "settings-page__box",
        settings[getUrlVariableValue("settingsId")].formatSettings.general
          .staffPayTypeTotals
      )}`}
    >
      <Typography className="settings-page__typography">
        Include Pay-Type totals in Staff Tabs:
      </Typography>
      <BooleanToggleElement
        id="staffPayTypeTotals"
        onChange={handleChange}
        default={
          settings[getUrlVariableValue("settingsId")].formatSettings.general
            .staffPayTypeTotals
        }
      ></BooleanToggleElement>
    </Box>
    <Divider className="settings-page__divider settings-page__divider--light" />
    <Box
      className={`settings-page__box settings-page__box--grid  ${modifier(
        "settings-page__box",
        settings[getUrlVariableValue("settingsId")].formatSettings.general
          .staffStudioTotals
      )}`}
    >
      <Typography className="settings-page__typography">
        {"Include " + settings[getUrlVariableValue("settingsId")].generalSettings.locationName + " totals in Staff Tabs:"}
      </Typography>
      <BooleanToggleElement
        id="staffStudioTotals"
        onChange={handleChange}
        default={
          settings[getUrlVariableValue("settingsId")].formatSettings.general
            .staffStudioTotals
        }
      ></BooleanToggleElement>
    </Box>
    <Divider className="settings-page__divider settings-page__divider--light" />
    <Box
      className={`settings-page__box settings-page__box--grid  ${modifier(
        "settings-page__box",
        settings[getUrlVariableValue("settingsId")].formatSettings.general
          .staffNonZeroCommission
      )}`}
    >
      <Typography className="settings-page__typography">
        Include only 'non-zero' commissionable items in Staff Tabs:
      </Typography>
      <BooleanToggleElement
        id="staffNonZeroCommission"
        onChange={handleChange}
        default={
          settings[getUrlVariableValue("settingsId")].formatSettings.general
            .staffNonZeroCommission
        }
      ></BooleanToggleElement>
    </Box>

    <Divider className="settings-page__divider settings-page__divider--light" />
    <Box
      className={`settings-page__box settings-page__box--grid  ${modifier(
        "settings-page__box",
        settings[getUrlVariableValue("settingsId")].formatSettings.general
          .classCountVsHourFlag.valid
      )}`}
    >
      <ClassesHoursFlag
        description={` Include a flag marking any ${settings[getUrlVariableValue("settingsId")].generalSettings.specialistName} with dissimilar ${settings[getUrlVariableValue("settingsId")].generalSettings.sessionName} counts
        and ${settings[getUrlVariableValue("settingsId")].generalSettings.timeName} clocked in in the summary tab:`}
      ></ClassesHoursFlag>
    </Box>

    <Divider className="settings-page__divider settings-page__divider--light" />
    
    <Box
      className={`settings-page__box settings-page__box--grid  ${modifier(
        "settings-page__box",
        settings[getUrlVariableValue("settingsId")].formatSettings.general?.displayScheduleTab
      )}`}
    >
    <Typography className="settings-page__typography">
      Display Schedule Breakdown Tab (BETA):
    </Typography>
    <BooleanToggleElement
      id="displayScheduleTab"
      onChange={handleChange}
      default={
        settings[getUrlVariableValue("settingsId")].formatSettings.general?.displayScheduleTab
      }
    ></BooleanToggleElement>
    </Box>

    <Divider className="settings-page__divider settings-page__divider--light" />
    <Box
      className={`settings-page__box settings-page__box--grid  ${modifier(
        "settings-page__box",
        settings[getUrlVariableValue("settingsId")].formatSettings.general?.displayScheduleTab
      )}`}
    >
      <Typography className="settings-page__typography">
        Only show paid hours in schedule tab:
      </Typography>
      <BooleanToggleElement
        id="onlyShowPaidHoursSchedule"
        onChange={handleChange}
        default={
          settings[getUrlVariableValue("settingsId")].formatSettings.general?.onlyShowPaidHoursSchedule
        }
      ></BooleanToggleElement>
    </Box>

    <Divider className="settings-page__divider settings-page__divider--light" />
    <Box
      className={`settings-page__box settings-page__box--grid  ${modifier(
        "settings-page__box",
        settings[getUrlVariableValue("settingsId")].formatSettings.general?.commentsCarryOverToStaffTabs
      )}`}
    >
      <Typography className="settings-page__typography">
        Display comments in Staff Tabs;
      </Typography>
    <MultipleSelectCheckmarks
      tag="Comments in Staff Tabs"
      onChange={handleChange}
      id="commentsCarryOverToStaffTabs"
      multiple
      default={
        settings[getUrlVariableValue("settingsId")].formatSettings.general?.commentsCarryOverToStaffTabs
      }
      size={400}
      //options={attendeeStatuses}
      options={
        ["Class", "Time", "Commission"]
      }
      label="Comments"
      name="Comments"
    ></MultipleSelectCheckmarks>
    </Box>

    <Divider className="settings-page__divider settings-page__divider--light" />
    
    <Box
      className={`settings-page__box settings-page__box--grid  ${modifier(
        "settings-page__box",
        settings[getUrlVariableValue("settingsId")].formatSettings.general?.showRescheduledClassesInOutput
      )}`}
    >
    <Typography className="settings-page__typography">
      Display Rescheduled Classes in Output:
    </Typography>
    <BooleanToggleElement
      id="showRescheduledClassesInOutput"
      onChange={handleChange}
      default={
        settings[getUrlVariableValue("settingsId")].formatSettings.general?.showRescheduledClassesInOutput
      }
    ></BooleanToggleElement>
    </Box>

    <Divider className="settings-page__divider settings-page__divider--light" />
    
    <Box
      className={`settings-page__box settings-page__box--grid  ${modifier(
        "settings-page__box",
        settings[getUrlVariableValue("settingsId")].formatSettings.general?.showAttendanceStatus
      )}`}
    >
    <Typography className="settings-page__typography">
      Display Attendance Status as Comments:
    </Typography>
    <MultipleSelectCheckmarks
      tag="Attendee Statuses"
      onChange={handleChange}
      id="showAttendanceStatus"
      multiple
      default={
        settings[getUrlVariableValue("settingsId")].formatSettings.general?.showAttendanceStatus
      }
      size={400}
      //options={attendeeStatuses}
      options={
        ["All"].concat(settings[getUrlVariableValue("settingsId")].generalSettings?.attendeeStatuses)
      }
      label="Statuses"
      name="Statuses"
    ></MultipleSelectCheckmarks>
    </Box>


    <Divider className="settings-page__divider settings-page__divider--light" />
    
    <Box
      className={`settings-page__box settings-page__box--grid  ${modifier(
        "settings-page__box",
        settings[getUrlVariableValue("settingsId")].formatSettings.general?.showAttendeeBonusComment
      )}`}
    >
    <Typography className="settings-page__typography">
      Display Attendee Bonus Comment:
    </Typography>
    <BooleanToggleElement
      id="showAttendeeBonusComment"
      onChange={handleChange}
      default={
        settings[getUrlVariableValue("settingsId")].formatSettings.general?.showAttendeeBonusComment
      }
    ></BooleanToggleElement>
    </Box>

  </Stack>
  ) : (
    <Box className="settings-page">
      <Skeleton>
        <Typography variant="h2" className="settings-page__title">
          Studios
        </Typography>
      </Skeleton>
      <Divider className="settings-page__divider" />
      <Skeleton className="settings-page__attribute-box settings-page__attribute-box--skeleton"></Skeleton>
    </Box>
  );
}
