export class PayWellStaff {
  constructor(primaryName) {
    this.infoObject = {};
    this.primaryName = primaryName;
    this.location = [];
    this.names = [primaryName];
  }

  addInfo(location, type, namesArray, buckets) {
    this.infoObject[location] = {
      type: type,
      names: namesArray,

      hourly: [],
      secondaryHourly: [],
      email: null,
      homeLocation: null,
      agreementCommissionPercentage: null,
      retailCommissionPercentage: null,

      id: null,
      secondaryId: null,
      titles: [],
    };

    for(let i = 0; i < buckets.length; i++){
      let rateName = buckets[i].charAt(0).toLowerCase() + buckets[i].slice(1) + "Rates";
      this.infoObject[location][rateName] = [];
    }

    this.names = [...new Set([...this.names, ...namesArray])];

    // if(location === 'ALL'){
    //   return;
    // }

    let locationInStaffArray = false;
    for (let i = 0; i < this.location.length; i++) {
      if (this.location[i] === location) {
        locationInStaffArray = true;
      }
    }
    if (!locationInStaffArray) {
      this.location.push(location);
    }
    
  }

  getProperty(location, attribute) {
    try {
      if (location === "Any") {
        let keys = Object.keys(this.infoObject);
        for (let i = 0; i < keys.length; i++) {
          if (this.infoObject[keys[i]].hasOwnProperty(attribute)) {
            return this.infoObject[keys[i]][attribute];
          }
        }
      }

      if (
        this.location.length == 0 ||
        this.location.includes(location) ||
        this.location.includes("ALL")
    ) {
        return this.infoObject.hasOwnProperty("ALL")
          ? this.infoObject["ALL"][attribute]
          : this.infoObject[location][attribute];
      }
      return null;
    } catch (e) {
      console.warn(e);
      return null;
    }

    //return this.infoObject[location][attribute];
  }

  addProperty(location, attribute, value) {
    this.infoObject.hasOwnProperty("ALL")
      ? (this.infoObject["ALL"][attribute] = value)
      : (this.infoObject[location][attribute] = value);
    //this.infoObject[location][attribute] = value;
  }

  addHourlyRate(studio, rate) {
    this.addProperty(studio, "hourly", rate);
    //this.infoObject[studio].hourly = rate;
    //this.hourly[studio] = rate;
  }

  addSecondHourlyRate(studio, rate) {
    this.addProperty(studio, "secondaryHourly", rate);
    //this.infoObject[studio].secondaryHourly = rate;
    //this.secondaryHourly[studio] = rate;
  }

  addOvertimeRate(studio, rate) {
    this.addProperty(studio, "overtime", rate);
  }

  addDoubleOvertimeRate(studio, rate) {
    this.addProperty(studio, "doubleOvertime", rate);
  }

  addRate(studio, rates, type) {
    return this.infoObject.hasOwnProperty("ALL") ? this.infoObject["ALL"][type] = rates : this.infoObject[studio][type] = rates;
  }

  getRate(studio, type) {
    try{
      return this.infoObject.hasOwnProperty("ALL") ? this.infoObject["ALL"][type] : this.infoObject[studio][type];

    }catch(e){
      console.log("getRate() Error: ", this.primaryName);
      return 0;
    }
  }

  getHourlyRate(studio) {
    try{
      return this.infoObject.hasOwnProperty("ALL")
      ? this.infoObject["ALL"].hourly
      : this.infoObject[studio].hourly;
    }catch(e){
      return 0;
    }
    
    //return this.hourly.hasOwnProperty("ALL") ? this.hourly["ALL"] : this.hourly[studio];
  }

  getSecondHourlyRate(studio) {
    try{
      return this.infoObject.hasOwnProperty("ALL")
      ? this.infoObject["ALL"].secondaryHourly
      : this.infoObject[studio].secondaryHourly;
    }catch(e){
      return 0;
    }
    
    //return this.secondaryHourly.hasOwnProperty("ALL") ? this.secondaryHourly["ALL"] : this.secondaryHourly[studio];
  }

  isNamed(name1) {
    if (typeof name1 === "string") {
      //return this.name.includes(name1);
    }
    if (typeof name1 === "object") {
      //console.warn(name1);
      //return this.name.some(n => name1.some(m => m === n));
    }

    let allNames = this.getNames();

    //this.name.some(n => name1.some(m => m === n));
    return allNames.includes(name1);
    //return this.name.some(n => n === name);
  }

  isLocated(location) {
    let keys = Object.keys(this.infoObject);
    for (let i = 0; i < keys.length; i++) {
      let key = keys[i];
      if (key === location || key === "ALL") {
        return true;
      }
    }
    return false;
    // Object.keys(this.infoObject).forEach((key) => {
    //   if (key === location || key === "ALL") {
    //     return true;
    //   }
    // });
    // return false;
  }

  getNames() {
    return this.names;
    // let allNames = [this.primaryName];
    // for (let location in this.infoObject) {
    //   if (this.infoObject[location].hasOwnProperty("names")) {
    //     allNames = allNames.concat(this.infoObject[location].names);
    //   }
    // }
    // return allNames;
  }

  getNameString() {
    return this.getNames().join("/");
  }

  getPrimaryName() {
    return this.primaryName;
  }

  getPrimaryFirstName() {
    let names = this.primaryName.split(" ");
    return names[0];
  }

  getPrimaryLastName() {
    let names = this.primaryName.split(" ");
    return names[1];
  }

  getLocations() {
    return this.location;
  }

  getValidLocations(locationsRunning) {
    let validLocations = [];
    let staffLocations = Object.keys(this.infoObject);
    for (let i = 0; i < staffLocations.length; i++) {
      if (staffLocations[i] === "ALL") {
        return locationsRunning;
      }
      for (let j = 0; j < locationsRunning.length; j++) {
        if (staffLocations[i] === locationsRunning[j]) {
          validLocations.push(staffLocations[i]);
        }
      }
    }
    return validLocations;
  }

  getAllTypes(){
    let locations = this.location;
    let types = [];
    for(let i = 0; i < locations.length; i++){
      types.push(this.infoObject[locations[i]].type);
    }

    return types;
  }

  getValidTitles(locationsRunning, defaultTitle) {
    let staffLocations = Object.keys(this.infoObject);
    let titles = [];
    for (let i = 0; i < staffLocations.length; i++) {
      if (staffLocations[i] === "ALL") {
        return this.infoObject[staffLocations[i]].titles;
      }

      for (let j = 0; j < locationsRunning.length; j++) {
        if (staffLocations[i] === locationsRunning[j]) {
          titles = titles.concat(this.infoObject[staffLocations[i]].titles);
        }
      }
    }
    if (titles.length > 0) {
      //return titles;
      return [...new Set(titles)];
    }
    return [defaultTitle];
  }

  getValidAlternateNames(locationsRunning) {
    let staffLocations = Object.keys(this.infoObject);
    for (let i = 0; i < staffLocations.length; i++) {
      if (staffLocations[i] === "ALL") {
        return this.infoObject[staffLocations[i]].names;
      }
      for (let j = 0; j < locationsRunning.length; j++) {
        if (staffLocations[i] === locationsRunning[j]) {
          return this.infoObject[staffLocations[i]].names;
        }
      }
    }
    return [this.primaryName];
  }

  getAttributesFromLocations(attribute, locationsRunning) {
    let staffLocations = Object.keys(this.infoObject);
    for (let i = 0; i < staffLocations.length; i++) {
      if (staffLocations[i] === "ALL") {
        return this.infoObject[staffLocations[i]][attribute];
      }
      for (let j = 0; j < locationsRunning.length; j++) {
        if (staffLocations[i] === locationsRunning[j]) {
          return this.infoObject[staffLocations[i]][attribute];
        }
      }
    }
    return [];
  }
}
