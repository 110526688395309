import React, { useState } from "react";
import { TextField } from "@mui/material";
import Box from "@mui/material/Box";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";

import TextFieldsIcon from "@mui/icons-material/TextFields";
import AttachMoneyOutlinedIcon from "@mui/icons-material/AttachMoneyOutlined";
import PercentOutlinedIcon from "@mui/icons-material/PercentOutlined";
import NumbersIcon from "@mui/icons-material/Numbers";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import PhoneIcon from "@mui/icons-material/Phone";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";

import InputAdornment from "@mui/material/InputAdornment";

import PropTypes from "prop-types";
import NumberFormat from "react-number-format";
import "./inputBox.scss";
const valueTypes = {
  dollar: <AttachMoneyOutlinedIcon style={{ fontSize: 16 }} />,
  percent: <PercentOutlinedIcon style={{ fontSize: 16 }} />,
  text: <TextFieldsIcon style={{ fontSize: 16 }} />,
  number: <NumbersIcon style={{ fontSize: 16 }} />,
  time: <AccessTimeIcon style={{ fontSize: 16 }} />,
  phone: <PhoneIcon style={{ fontSize: 16 }} />,
  email: <AlternateEmailIcon style={{ fontSize: 16 }} />,
};

const inputAdornments = {
  dollar: {
    startAdornment: <InputAdornment position="start">$</InputAdornment>,
  },
  percent: {
    endAdornment: <InputAdornment position="end">%</InputAdornment>,
  },
  text: {
    startAdornment: <InputAdornment position="start"></InputAdornment>,
  },
  number: {
    startAdornment: <InputAdornment position="start">#</InputAdornment>,
  },
  time: {
    startAdornment: <InputAdornment position="start"></InputAdornment>,
  },
  phone: {
    startAdornment: <InputAdornment position="start"></InputAdornment>,
  },
  email: {
    startAdornment: <InputAdornment position="start"></InputAdornment>,
  },
};

const DollarFormatCustom = React.forwardRef(function DollarFormatCustom(
  props,
  ref
) {
  const { onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      className={
        props.className
          ? `input-box__input ${props.className}-input`
          : "input-box__input"
      }
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      isNumericString
    />
  );
});

DollarFormatCustom.propTypes = {
  onChange: PropTypes.func.isRequired,
};

const TimeFormatCustom = React.forwardRef(function TimeFormatCustom(
  props,
  ref
) {
  const { onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      format="##:##"
      placeholder="00:00"
    />
  );
});

TimeFormatCustom.propTypes = {
  onChange: PropTypes.func.isRequired,
};

const PhoneFormatCustom = React.forwardRef(function PhoneFormatCustom(
  props,
  ref
) {
  const { onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      format="(###) ### ####"
      placeholder=""
      //isNumericString
      //prefix="$"
    />
  );
});

PhoneFormatCustom.propTypes = {
  onChange: PropTypes.func.isRequired,
};

const EmailFormatCustom = React.forwardRef(function EmailFormatCustom(
  props,
  ref
) {
  const { onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      placeholder=""
    />
  );
});

EmailFormatCustom.propTypes = {
  onChange: PropTypes.func.isRequired,
};

const format = {
  dollar: DollarFormatCustom,
  percent: DollarFormatCustom,
  text: "",
  number: DollarFormatCustom,
  time: TimeFormatCustom,
  phone: PhoneFormatCustom,
  email: EmailFormatCustom,
};

export default function InputBoxElement(props) {
  const typeAmount = props.types.length;
  const [inputType, setInputType] = useState(
    props.default.substring(0, props.default.indexOf("~"))
  );
  const [textValue, setValue] = useState(
    props.default.substring(props.default.indexOf("~") + 1)
  );
  const placeholder = props.placeholder === undefined ? "" : props.placeholder;

  const handleInputChange = (event, newType) => {
    if (newType !== null) {
      setInputType(newType);
    }
    if (props.onChange) {
      let combinedValue = newType + "~" + textValue;
      props.onChange(combinedValue, props.id);
    }
  };

  const handleTextChange = (event) => {
    const {
      target: { value },
    } = event;
    setValue(value);
    if (props.onChange) {
      let combinedValue = inputType + "~" + value;
      props.onChange(combinedValue, props.id);
    }
  };

  const toggleAdornment = () => {
    if (typeAmount !== 1) {
      return (
        <ToggleButtonGroup
          className="input-box__button-group"
          onChange={handleInputChange}
          color="primary"
          value={inputType}
          exclusive
        >
          {props.types.map((type) => (
            <ToggleButton className="input-box__button" key={type} value={type}>
              {valueTypes[type]}
            </ToggleButton>
          ))}
        </ToggleButtonGroup>
      );
    } else {
      return "";
    }
  };
  return (
    <Box
      className={props.className ? `${props.className}` : "input-box"}
      key={props.id + "-whole"}
    >
      <TextField
        style={{ display: "flex", alignItems: "center" }}
        placeholder={placeholder}
        className={
          props.className
            ? `input-box__text-field ${props.className}-text-field`
            : "input-box__text-field"
        }
        key={props.id + " key"}
        variant="standard"
        id={props.id}
        defaultValue={textValue}
        onChange={handleTextChange}
        InputProps={{
          ...inputAdornments[inputType],
          inputComponent: format[inputType],
          endAdornment: toggleAdornment(),
          className: "input-box__text-field__box",
        }}
      ></TextField>
    </Box>
  );
}

export function convertInputBoxValue(value) {
  return value.substring(value.indexOf("~") + 1);
}
