import React, { useContext, useReducer } from "react";
// Use Contexts
// Helper Functions
import {
  serializeObjectWithMethods,
  deserializeObjectWithMethods,
  updateObject,
} from "../../utility-functions/utility-functions.js";

/**** HELPER FUNCTIONS (NOT REPORTCOMPILERSTATE DEPENDENT) ****/
/**** REDUCER ****/
const deserializedCommissionCreationContextReducer = (state, action) => {
  const serializedState = serializeObjectWithMethods(state);
  const serializedAction = serializeObjectWithMethods(action);
  const newSerializedState = commissionCreationContextReducer(
    serializedState,
    serializedAction
  );

  const deserializedState = deserializeObjectWithMethods(newSerializedState);
  return deserializedState;
};
const commissionCreationContextReducer = (state, action) => {
  let newState = JSON.parse(JSON.stringify(state));

  switch (action.type) {
    //  commissionCreationContextDispatch({
    //   type: "UPDATE_COMMISSION_CREATION_CONTEXT_STATE_PROPERTY",
    //   path: `property.property.property`,
    //   value: value,
    //   replace: true/false
    // });
    case "UPDATE_COMMISSION_CREATION_CONTEXT_STATE_PROPERTY":
      updateObject(
        newState,
        action.path,
        action.value,
        action.hasOwnProperty("replace") ? action.replace : true
      );

      return newState;

    default:
      return state;
  }
};

/**** CONTEXT ****/
const CommissionCreationContext = React.createContext();
export function useCommissionCreationContext() {
  return useContext(CommissionCreationContext);
}

export function CommissionCreationContextProvider({ children }) {
  const initialCommissionCreationContextState = {};
  const [commissionCreationContextState, commissionCreationContextDispatch] =
    useReducer(
      deserializedCommissionCreationContextReducer,
      initialCommissionCreationContextState
    );

  // Context values to pass down
  const value = {
    commissionCreationContextState,
    commissionCreationContextDispatch,
  };

  return (
    <CommissionCreationContext.Provider value={value}>
      {children}
    </CommissionCreationContext.Provider>
  );
}
