import BooleanToggleElement from "../../Components/BooleanToggle";
import { Stack, Typography } from "@mui/material";
import InputBoxElement from "../../Components/InputBox/InputBox";
import { useSettingsContext } from "../../../contexts/SettingsContext";
import { RevertList } from "../../Components/RevertList";
import { useAuth } from "../../../contexts/AuthContext";
import { getUrlVariableValue } from "../../../utility-functions/utility-functions.js";
export default function AgreementSpecifics() {
  const { getUID } = useAuth();
  const { settings, dispatch, changes, setChanges } = useSettingsContext();
  

  function handleChange(value, id) {
    let newRev = new RevertList(changes.getValues());
    newRev.addElement(
      id,
      settings[getUrlVariableValue("settingsId")].commissionSettings.general[id],
      value
    );
    setChanges(newRev);

    dispatch({
      type: "UPDATE_SETTINGS_PROPERTY",
      settingsId: getUrlVariableValue("settingsId"),
      uid: getUID(),
      path: `${getUrlVariableValue("settingsId")}.commissionSettings.general.${id}`,
      value: value,
    });

    // setStudioSettings({
    //   ...studioSettings,
    //   commissionSettings: {
    //     ...studioSettings.commissionSettings,
    //     general: { ...studioSettings.commissionSettings.general, [id]: value },
    //   },
    // });
  }

  return (
    <div>
      <Stack
        direction="row"
        spacing={2}
        justifyContent="flex-start"
        alignItems="flex-end"
      >
        <Typography>Agreement payments come from column:</Typography>
        <InputBoxElement
          onChange={handleChange}
          id="agreementPayColumn"
          default={
            settings[getUrlVariableValue("settingsId")].commissionSettings.general
              .agreementPayColumn
          }
          types={["number"]}
          size={100}
          label="Column"
        ></InputBoxElement>
        <Typography>in the agreement log</Typography>
      </Stack>
      <Stack
        direction="row"
        spacing={2}
        justifyContent="flex-start"
        alignItems="flex-end"
      >
        <Typography>Subtract setup fee:</Typography>
        <BooleanToggleElement
          onChange={handleChange}
          id="subtractSetupFee"
          default={
            settings[getUrlVariableValue("settingsId")].commissionSettings.general
              .subtractSetupFee
          }
        ></BooleanToggleElement>
      </Stack>
    </div>
  );
}
