import { Typography } from '@mui/material';
import React from 'react';

function NoDataComponent({ imageSrc, message, buttonText, redirectTo }) {

 

    return (
        <div className='no-data-component' style={{ textAlign: 'center', marginTop: '50px' }}>
            <img 
            className='no-data-component__image'
                src={imageSrc} 
                alt="No Data Illustration" 
                style={{ maxWidth: '200px', height: 'auto', marginBottom: '20px' }} 
            />
            <Typography color={'GrayText'} className='no-data-component__caption' variant='body1' component={"p"}>{message}</Typography>
        </div>
    );
}

export default NoDataComponent;
