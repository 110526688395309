import axios from "axios";
// import { read, utils } from "xlsx";
import { read } from  "@sheet/core";

import { removeSubstringBeforeFirstDot } from "../../utility-functions/utility-functions";

export function updateStudios(workbookPaths, studios) {
  // Extract studio names from workbook paths
  const studioNamesInPaths = new Set();
  workbookPaths.forEach(path => {
    const parts = path.split('/'); // Split the path into segments
    const studioName = parts.find(part => studios.includes(part));
    if (studioName) {
      studioNamesInPaths.add(studioName); // Add to set if found in the path
    }
  });

  // Filter the studios array to include only those found in the workbook paths
  return studios.filter(studio => studioNamesInPaths.has(studio));
}

export function selectedDatesValid(startDate, endDate) {
  if (startDate && endDate) {
    if (startDate.length > 0 && endDate.length > 0) {
      if (isValidDateFormat(startDate, "MM/DD/YYYY") && isValidDateFormat(endDate, "MM/DD/YYYY")) {
        return true;
      }
    }
  }
  return false;
}

function isValidDateFormat(dateString, format) {
  let regex;

  // Define regex patterns for different date formats
  switch (format) {
    case 'YYYY/MM/DD':
      regex = /^\d{4}\/\d{2}\/\d{2}$/;
      break;
    case 'DD/MM/YYYY':
      regex = /^\d{2}\/\d{2}\/\d{4}$/;
      break;
    case 'MM/DD/YYYY':
      regex = /^\d{2}\/\d{2}\/\d{4}$/;
      break;
    default:
      return false; // Unsupported format
  }

  // Check if dateString matches the regex
  if (!regex.test(dateString)) {
    return false;
  }

  // Parse the date parts based on the format
  let parts;
  let day, month, year;

  switch (format) {
    case 'YYYY/MM/DD':
      parts = dateString.split('/');
      year = parseInt(parts[0], 10);
      month = parseInt(parts[1], 10) - 1; // months are 0-based in JS
      day = parseInt(parts[2], 10);
      break;
    case 'DD/MM/YYYY':
      parts = dateString.split('/');
      day = parseInt(parts[0], 10);
      month = parseInt(parts[1], 10) - 1;
      year = parseInt(parts[2], 10);
      break;
    case 'MM/DD/YYYY':
      parts = dateString.split('/');
      month = parseInt(parts[0], 10) - 1;
      day = parseInt(parts[1], 10);
      year = parseInt(parts[2], 10);
      break;
  }

  // Create a Date object
  const date = new Date(year, month, day);

  // Check if the Date object represents the correct date
  if (date.getFullYear() !== year || date.getMonth() !== month || date.getDate() !== day) {
    return false;
  }

  return true;
}



export function findEmptyDataTypes(inputFilesArray, ignoreKeys) {
  const emptyKeys = [];
  const ignoreSet = new Set(ignoreKeys); // Convert ignoreKeys array to a Set for faster access

  // Iterate through each key in the inputFilesArray object
  for (const key in inputFilesArray) {
    if (inputFilesArray.hasOwnProperty(key)) {
      // Check if the property is an array, if it's empty, and if it's not in the ignore list
      if (Array.isArray(inputFilesArray[key]) && inputFilesArray[key].length === 0 && !ignoreSet.has(key)) {
        // Check if key includes a number
        const match = key.match(/(\D+)(\d+)/);
        if (match) {
          const fileType = match[1]; // key without the number
          const studioIndex = parseInt(match[2], 10); // the parsed number
          emptyKeys.push({ key: fileType, studioIndex }); // Add the modified key and index
        } else {
          emptyKeys.push({ key, studioIndex: null }); // Add the key without a number
        }
      }
    }
  }

  return emptyKeys; // Return the list of keys with empty arrays that are not ignored
}


export function convertArrayItemsToUpperCase(array) {
  return array.map(item => item.toUpperCase());
}


export function padTo2Digits(num) {
  return num.toString().padStart(2, "0");
}
export function formatDate(date) {
  return [
    padTo2Digits(date.getMonth() + 1),
    padTo2Digits(date.getDate()),
    date.getFullYear(),
  ].join("/");
}


export function getPrevDay(date) {
  let newDate = new Date(date);
  newDate.setDate(newDate.getDate() - 1); // get yesterdays date
  return formatDate(newDate); // put in mm/dd/yyyy format;
}


export function findStudiosWithoutAccount(studios, accounts) {

  return studios.filter(
    (studioName) => getAccountFromStudioName(studioName, accounts).length < 1
  );
}
export function getAccountFromStudioName(studioName, accounts) {
  if (typeof studioName !== "string" || !Array.isArray(accounts)) {
    throw new TypeError("Invalid arguments");
  }

  return accounts.filter((account) => {
    if (
      typeof account === "object" &&
      account !== null &&
      account.hasOwnProperty("studios")
    ) {
      return (
        Array.isArray(account.studios) && account.studios.includes(studioName)
      );
    } else {
      return false;
    }
  });
}

export function findNonMatchingElements(arr1, arr2) {
  const uniqueElementsArr1 = arr1.filter((item) => !arr2.includes(item));
  const uniqueElementsArr2 = arr2.filter((item) => !arr1.includes(item));

  return [...uniqueElementsArr1, ...uniqueElementsArr2];
}

export function getFullNames(data) {
  return data.map((item) => `${item.FirstName} ${item.LastName}`);
}
export function missingTimeDetailStaff(staffInfo, staffData) {
  var staffInfoNames = getFullNames(staffInfo);
  var staffDataKeys = Object.keys(staffData);
  return findNonMatchingElements(staffInfoNames, staffDataKeys);
}

export async function getTextFileFromS3(url) {
  try {
    const response = await axios({
      url,
      method: "GET",
      responseType: "text", // Set the responseType to 'text'
    });

    const fileName = getFileNameFromSignedURL(url);
    const lastModifiedDate = response.headers["last-modified"];

    // Since we're expecting the response to be JSON text, parse it into an object
    const jsonData = JSON.parse(response.data);

    return { jsonData, fileName, lastModifiedDate };
  } catch (error) {
    console.error("Failed to get JSON data from signed URL: ", error);
    throw error;
  }
}
export const getWorkbookFromS3 = async (url) => {
  try {
    const response = await axios({
      url,
      method: "GET",
      responseType: "arraybuffer",
    });

    // Derive file name from URL
    // const fileName = url.substring(url.lastIndexOf("/") + 1);
    const fileName = getFileNameFromSignedURL(url);
    const lastModifiedDate = response.headers["last-modified"];
    const workbook = read(response.data, { type: "buffer" });

    // Return both workbook and filename
    return { workbook, fileName, lastModifiedDate };
  } catch (error) {
    console.error("Failed to get workbook from signed URL: ", error);
    throw error;
  }
};
export function getFileNameFromSignedURL(url) {
  // Get the pathname from the URL and decode it
  const path = decodeURIComponent(new URL(url).pathname);

  // Split the pathname by '/' to get all the path segments
  const segments = path.split("/");

  // The filename is the last segment
  const filename = segments[segments.length - 1];

  return filename;
}


export function updateStudioStatuses(studios, fileRegistry, reportCompilerState) {
  // Extracting required reports and their names
  const requiredReports = reportCompilerState.scrapeInfo.reportsNeeded;
  const fullNameReports = reportCompilerState.scrapeInfo.fullNameReportsNeeded;

  // Mapping over studios to determine the status of each
  return studios.map((studioName, studioIndex) => {
    const status = {
      key: studioName,
      status: "done",
      error: false,
      completedReportsCount: 0,
      reportsCount: requiredReports.length,
      studioIndex,
      reports: []
    };

    requiredReports.forEach((reportType, reportIndex) => {
      // Constructing the key to check in the fileRegistry
      const fileKey = `${reportType}${studioIndex + 1}file`; // Adjusted based on 1-based indexing for file keys

      // Check if the file exists in the registry
      if (fileRegistry[fileKey]) {
        status.reports.push({
          status: "done",
          fileType: reportType,
          fileName: removeSubstringBeforeFirstDot(fileRegistry[fileKey].fileName) ?? fullNameReports[reportIndex],
          fileIndex: reportIndex,
          studioIndex: studioIndex,
          key: reportType,
          workbook: true, // Assuming the workbook property should be set to true when the file exists
        });
        status.completedReportsCount += 1;
      } else {
        status.reports.push({
          status: "error",
          fileType: reportType,
          fileName: fullNameReports[reportIndex],
          fileIndex: reportIndex,
          studioIndex: studioIndex,
          key: reportType,
          workbook: false,
        });
        status.error = true; // Marking the status as error if any required file is missing
      }
    });

    return status;
  });
}

export function trimArray(arr) {
  // Filter the array to exclude empty strings
  return arr.filter(item => item !== "");
}