import React, { useState } from "react";
import Typography from "@mui/material/Typography";
import InputBoxElement from "../../Components/InputBox/InputBox";
import { SingleAttributeListBox } from "../../Components/AttributeListBox";
import SelectorBox from "../../Components/SelectorBox/SelectorBox";
import BooleanToggleElement from "../../Components/BooleanToggle";
import { Stack, Box, Button, Divider } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { IconButton } from "@mui/material";
import { RevertList } from "../../Components/RevertList";
import { v4 as uuidv4 } from "uuid";
import { useSettingsContext } from "../../../contexts/SettingsContext";
import { MultipleSelectPlaceholder } from "../../Components/SelectorBox/SelectorBox";
import BasicModal from "../../../components/Modal/Modal";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import Avatar from "@mui/material/Avatar";

import EditRoundedIcon from "@mui/icons-material/EditRounded";
import { useAuth } from "../../../contexts/AuthContext";
// Any class, anytime,
import { classesExamples } from "../CommonVariables";
import { getUrlVariableValue } from "../../../utility-functions/utility-functions.js";
import "../../../Styles/catchAll.scss";
function compareNumberIndex(a, b) {
  const [firstId, firstObj] = a;
  const [secondId, secondObj] = b;
  let firstIndex = firstObj.exceptionNumber;
  let secondIndex = secondObj.exceptionNumber;

  return firstIndex - secondIndex;
}

export default function ClassCatchAllList() {
  const { settings, dispatch, changes, setChanges } = useSettingsContext();
  const { currentUser, getUID } = useAuth();

  // const [exceptions, setExceptions] = useState(
  //   settings[getUrlVariableValue("settingsId")].classSettings.exceptions
  // );

  const [modalException, setModalException] = useState(null);

  const handleAddException = (event) => {
    let id = uuidv4();

    let newRev = new RevertList(changes.getValues());
    newRev.addElement("class-exception" + id, "n/a", "added");
    setChanges(newRev);

    const rateObj = {
      Exactly: { amount: "dollar~0" },
      "a bonus of": {
        amount: "dollar~0",
      },
      Percentage: {
        percent: "percent~0",
      },
      "Attendee Count": {
        number: "number~0",
      },
      "With No Shows and Late Cancels": {
        selections: [],
      },
      "Amount minus hourly rate": {
        dollar: "dollar~0",
        math: [],
      },
      "Specific Class Rate": { rate: [] },
      "Throw Flag": { flag: "text~" },
    };

    const defaultClassException = {
      timeSequence: "ALL DAY",
      time: "time~00:00",
      override: true,
      rate: "a bonus of",
      rateValueOne: "dollar~0",
      rateValueTwo: "dollar~0",
      rateValueThree: "dollar~0",
      rateAmount: rateObj,
      day: "ANY DAY",
      payAmount: "dollar~0",
      type: "ANY",
      label: "Default Exception",
      instructor: [],
      name: "",
      paySequence: "ANY AMOUNT OF MONEY",
      attendeeNumber: "number~0",
      studio: [],
      attendeeSequence: "ANY NUMBER OF",
      id: id,
      exceptionNumber:
        Object.entries(
          settings[getUrlVariableValue("settingsId")].classSettings.exceptions
        ).length + 1,
    };

    dispatch({
      type: "UPDATE_SETTINGS_PROPERTY",
      settingsId: getUrlVariableValue("settingsId"),
      uid: getUID(),
      path: `${getUrlVariableValue("settingsId")}.classSettings.exceptions.${id}`,
      value: defaultClassException,
    });

    // setModalException(id);
  };

  const handleDeleteException = (id) => {
    let newRev = new RevertList(changes.getValues());
    newRev.addElement("class-exception" + id, "n/a", "delete");
    setChanges(newRev);

    let exceptionList = {
      ...settings[getUrlVariableValue("settingsId")].classSettings.exceptions,
    };
    let exceptionNumber = exceptionList[id].exceptionNumber;

    Object.keys(exceptionList).forEach(function(key) {
      exceptionList[key].exceptionNumber > exceptionNumber
        ? exceptionList[key].exceptionNumber--
        : exceptionList[key].exceptionNumber;
    });
    delete exceptionList[id];

    dispatch({
      type: "UPDATE_SETTINGS_PROPERTY",
      settingsId: getUrlVariableValue("settingsId"),
      uid: getUID(),
      path: `${getUrlVariableValue("settingsId")}.classSettings.exceptions`,
      value: exceptionList,
    });

    setModalException(null);
  };

  const handleEditException = (id) => {
    setModalException(id);
  };

  const handleModalClose = (id) => {
    setModalException(null);
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 900,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
    minWidth: 800,
    minHeight: 400,
  };

  return (
    <Box className="settings-page">
      <Stack
        sx={{ width: 1 }}
        spacing={1}
        key={"exception-type-stack"}
        direction="column"
      >
        {Object.entries(
          settings[getUrlVariableValue("settingsId")].classSettings.exceptions
        )
          .sort(compareNumberIndex)
          .map(([key, entry], index) => (
            <Card key={"exception" + entry.id} sx={{ maxWidth: 700 }}>
              <CardHeader
                key={"exception-header" + entry.id}
                avatar={
                  <Avatar
                    key={"exception-avatar" + entry.id}
                    sx={{ bgcolor: "#1976d2" }}
                  >
                    {entry.exceptionNumber}
                  </Avatar>
                }
                action={
                  <BasicModal
                    handleOnClose={handleModalClose}
                    openIcon={
                      <IconButton
                        aria-label="settings"
                        key={"exception-type-edit" + index}
                        onClick={() => {
                          handleEditException(entry.id);
                        }}
                        size="large"
                      >
                        <EditRoundedIcon sx={{ color: "#1976d2" }} />
                      </IconButton>
                    }
                  >
                    <>
                      <ClassCatchAllBox
                        key={"class-exception" + modalException}
                        value={modalException}
                      ></ClassCatchAllBox>{" "}
                      <Button
                        sx={{ color: "red" }}
                        variant="outlined"
                        startIcon={<DeleteIcon sx={{ color: "red" }} />}
                        key={"class-type-delete" + modalException}
                        onClick={() => {
                          handleDeleteException(modalException);
                        }}
                      >
                        Delete
                      </Button>
                    </>
                  </BasicModal>
                }
                title={
                  <Typography
                    gutterBottom
                    key={"exception-title" + entry.id}
                    component="div"
                  >
                    {entry.label}
                  </Typography>
                }
              />
            </Card>
          ))}
      </Stack>
      <Button id="new" onClick={handleAddException} variant="contained">
        {"Add " + settings[getUrlVariableValue("settingsId")].generalSettings.sessionName + " Exception"}
      </Button>
    </Box>
  );
}

function PayComponent(props) {
  const { settings, dispatch, changes, setChanges } = useSettingsContext();
  
  const { currentUser, getUID } = useAuth();

  const [values, setValues] = useState(
    settings[getUrlVariableValue("settingsId")].classSettings.exceptions[props.value]
  );

  function findRateValue(id) {
    if (id === "exact") {
      return "rateValueOne";
    } else if (id === "bonus") {
      return "rateValueOne";
    } else if (id === "percent") {
      return "rateValueOne";
    } else if (id === "attendee") {
      return "rateValueOne";
    } else if (id === "hourlyAmount") {
      return "rateValueOne";
    } else if (id === "hourlyMath") {
      return "rateValueTwo";
    } else if (id === "classRate") {
      return "rateValueOne";
    } else if (id === "flag") {
      return "rateValueOne";
    } else if (id === "base") {
      return "rateValueOne";
    } else if (id === "perHead") {
      return "rateValueTwo";
    } else if (id === "threshold") {
      return "rateValueThree";
    } else if (id === "payPercent") {
      return "rateValueOne";
    } else if(id === "customPay"){
      return 'rateValueOne'
    }

    return null;
  }

  const createExceptionLabel = (tempValues) => {
    let label = "";
    let count = 0;
    if (tempValues.name !== "") {
      label =
        label + ("Class: " + tempValues.name.replace("text~", "") + " & ");
      count++;
    }
    if (tempValues.instructor.length !== 0) {
      label = label + ("Instructor: " + tempValues.instructor + " & ");
      count++;
    }
    if (tempValues.timeSequence !== "ALL DAY") {
      if (tempValues.timeSequence === "as the First Class") {
        label = label + "Time: First Class & ";
        count++;
      } else {
        let timeStr = tempValues.time.replace("time~", "");
        let timeFormatStr = timeStr.slice(0, 2) + ":" + timeStr.slice(2);
        label =
          label +
          ("Time: " + tempValues.timeSequence + " " + timeFormatStr + " & ");
        count++;
      }
    }
    if (count < 3 && tempValues.type !== "ANY") {
      label = label + ("Type: " + tempValues.type + " & ");
      count++;
    }
    if (count < 3 && tempValues.studio.length !== 0) {
      label = label + ("Studio: " + tempValues.studio + " & ");
      count++;
    }
    if (count < 3 && tempValues.day !== "ANY DAY") {
      label = label + ("Day: " + tempValues.day + " & ");
      count++;
    }
    if (count < 3 && tempValues.attendeeSequence !== "ANY NUMBER OF") {
      label =
        label +
        ("Attendees: " +
          tempValues.attendeeSequence +
          " " +
          tempValues.attendeeNumber.replace("number~", "") +
          " & ");
      count++;
    }
    if (count < 3 && tempValues.paySequence !== "ANY AMOUNT OF MONEY") {
      label =
        label +
        ("Payment: " +
          tempValues.paySequence +
          " $" +
          tempValues.payAmount.replace("dollar~", "") +
          " & ");
      count++;
    }

    if (label.length === 0) {
      label = "Default Exception & ";
    }

    label = label.substring(0, label.length - 3);

    return label;
  };

  const handlePayComponentChange = (value, id) => {
    let newTempSettings;
    let tempValues = { ...values, [id]: value };

    let label = createExceptionLabel(tempValues);

    let field = id;
    let rateValue = findRateValue(id);
    if (rateValue !== null) {
      field = rateValue;
    }

    let newValues = {
      ...values,
      [field]: value,
      ["label"]: label,
    };
    setValues(newValues);

    let newRev = new RevertList(changes.getValues());
    newRev.addElement(
      values.id + "-" + id,
      settings[getUrlVariableValue("settingsId")].classSettings.exceptions[values.id][id],
      value
    );
    setChanges(newRev);

    newTempSettings = {
      ...settings[getUrlVariableValue("settingsId")],
      classSettings: {
        ...settings[getUrlVariableValue("settingsId")].classSettings,
        exceptions: {
          ...settings[getUrlVariableValue("settingsId")].classSettings.exceptions,
          [values.id]: {
            ...settings[getUrlVariableValue("settingsId")].classSettings.exceptions[
              values.id
            ],
            [field]: value,
            ["label"]: label,
          },
        },
      },
    };
    dispatch({
      type: "UPDATE_SETTINGS_PROPERTY",
      settingsId: getUrlVariableValue("settingsId"),
      uid: getUID(),
      path: `${getUrlVariableValue("settingsId")}`,
      value: newTempSettings,
    });
  };

  const getClassTypes = (includeAllOption) => {
    let types = [];
    if (includeAllOption) {
      types.push("ANY");
    }
    let sessions = Object.keys(settings[getUrlVariableValue("settingsId")].classSettings.classBuckets);
    return sessions;
  };

  function getPayComponent(type) {
    let rateOne = values.rateValueOne;
    let rateTwo = values.rateValueTwo;
    let rateThree = values.rateValueThree;

    let defaultValues = getDefaultValue(type);
    if (rateOne === null || isNotDefaultType(rateOne, defaultValues[0])) {
      rateOne = defaultValues[0];
    }
    if (rateTwo === null || isNotDefaultType(rateOne, defaultValues[1])) {
      rateTwo = defaultValues[1];
    }
    if (rateThree === null || isNotDefaultType(rateOne, defaultValues[2])) {
      rateThree = defaultValues[2];
    }

    if (type === "Exactly") {
      return (
        <InputBoxElement
          className="catch-all__input-box"
          key="exact"
          onChange={handlePayComponentChange}
          id="rateValueOne"
          default={rateOne}
          types={["dollar"]}
          size={140}
          name="Exactly"
        ></InputBoxElement>
      );
    } else if (type === "a bonus of") {
      return (
        <InputBoxElement
          className="catch-all__input-box"
          key="bonus"
          onChange={handlePayComponentChange}
          id="rateValueOne"
          default={rateOne}
          types={["dollar"]}
          size={140}
          name="+ Bonus"
        ></InputBoxElement>
      );
    } else if (type === "Percentage") {
      return (
        <InputBoxElement
          className="catch-all__input-box"
          key="percent"
          onChange={handlePayComponentChange}
          id="rateValueOne"
          default={rateOne}
          types={["percent"]}
          size={140}
          name="Percent"
        ></InputBoxElement>
      );
    } else if (type === "Attendee Count") {
      return (
        <InputBoxElement
          className="catch-all__input-box"
          key="attendee"
          onChange={handlePayComponentChange}
          id="rateValueOne"
          default={rateOne}
          types={["number"]}
          size={140}
          name="Attendees"
        ></InputBoxElement>
      );
    } else if (type === "With No Shows and Late Cancels") {
      return (
        <SelectorBox
          // className="catch-all__selector-box"
          key="nslc"
          onChange={handlePayComponentChange}
          id="rateValueOne"
          label="No-Show - Late Cancel"
          multiple
          default={rateOne}
          options={["No Shows", "Late Cancels"]}
          size={300}
          name="No-Show - Late Cancel"
        ></SelectorBox>
      );
    } else if (type === "Amount minus hourly rate") {
      return (
        <Stack
          sx={{ flexWrap: "wrap" }}
          direction="row"
          spacing={1}
          justifyContent="flex-start"
          alignItems="flex-end"
          key="hourlyminus"
        >
          <InputBoxElement
            className="catch-all__input-box"
            onChange={handlePayComponentChange}
            id="rateValueOne"
            default={rateOne}
            types={["dollar"]}
            size={140}
            name="Amount"
          ></InputBoxElement>
          <SelectorBox
            className="catch-all__selector-box"
            onChange={handlePayComponentChange}
            id="rateValueTwo"
            label="Math"
            default={rateTwo}
            options={["-", "+"]}
            size={75}
            name="Math"
          ></SelectorBox>
          <Typography> "Hourly Rate"</Typography>
        </Stack>
      );
    } else if (type === "Specific Class Rate") {
      return (
        <SelectorBox
          className="catch-all__selector-box"
          key="specific"
          onChange={handlePayComponentChange}
          id="rateValueOne"
          label="Rate"
          default={rateOne}
          options={getClassTypes(false)}
          size={200}
          name="Rate"
        ></SelectorBox>
      );
    } else if (type === "Throw Flag") {
      return (
        <InputBoxElement
          className="catch-all__input-box"
          key="flag"
          onChange={handlePayComponentChange}
          id="rateValueOne"
          default={rateOne}
          types={["text"]}
          size={250}
          name="Flag"
        ></InputBoxElement>
      );
    } else if (type === "Base Plus Per Head") {
      return (
        <Stack
          sx={{ flexWrap: "wrap" }}
          direction="row"
          spacing={1}
          justifyContent="flex-start"
          alignItems="flex-end"
          key="baseperheadrate"
        >
          <InputBoxElement
            className="catch-all__input-box"
            onChange={handlePayComponentChange}
            id="rateValueOne"
            default={rateOne}
            types={["dollar"]}
            size={140}
            name="Base"
          ></InputBoxElement>
          <InputBoxElement
            className="catch-all__input-box"
            onChange={handlePayComponentChange}
            id="rateValueTwo"
            default={rateTwo}
            types={["dollar"]}
            size={140}
            name="Per Head"
          ></InputBoxElement>
          <InputBoxElement
            className="catch-all__input-box"
            onChange={handlePayComponentChange}
            id="rateValueThree"
            default={rateThree}
            types={["dollar"]}
            size={140}
            name="Threshold"
          ></InputBoxElement>
        </Stack>
      );
    } else if (type === "Percent of Pay") {
      return (
        <InputBoxElement
          className="catch-all__input-box"
          key="payPercent"
          onChange={handlePayComponentChange}
          id="rateValueOne"
          default={rateOne}
          types={["percent"]}
          size={140}
          name="Pay Percent"
        ></InputBoxElement>
      );
    } else if (type === "Custom") {
      return (
        <InputBoxElement
          className="catch-all__input-box"
          key="custom"
          onChange={handlePayComponentChange}
          id="rateValueOne"
          default={rateOne}
          types={["text"]}
          size={350}
          name="Custom"
        ></InputBoxElement>
      );
    }
  }

  function isNotDefaultType(rate, defaultRate) {
    if (rate === null || defaultRate === null) {
      return true;
    }
    if (typeof rate !== typeof defaultRate) {
      return true;
    }
    if (typeof rate === "string" && typeof defaultRate === "string") {
      if (rate.includes("~") && defaultRate.includes("~")) {
        if (
          rate.substring(0, rate.indexOf("~")) ===
          defaultRate.substring(0, defaultRate.indexOf("~"))
        ) {
          return false;
        } else {
          return true;
        }
      } else {
        return true;
      }
    }
    return false;
  }

  const [component, setComponent] = useState(getPayComponent(values.rate));

  function handlePayRateChange(value, id) {
    //props.onChange(value, id);

    setComponent(getPayComponent(value));

    let newTempSettings;
    // let tempValues = { ...values, [id]: value };

    let defaultValues = getDefaultValue(value);

    let tempValues = {
      ...values,
      [id]: value,
      ["rateValueOne"]: defaultValues[0],
      ["rateValueTwo"]: defaultValues[1],
      ["rateValueThree"]: defaultValues[2],
    };
    setValues(tempValues);

    let newRev = new RevertList(changes.getValues());
    newRev.addElement(
      values.id + "-" + id,
      settings[getUrlVariableValue("settingsId")].classSettings.exceptions[values.id][id],
      value
    );
    setChanges(newRev);

    newTempSettings = {
      ...settings[getUrlVariableValue("settingsId")],
      classSettings: {
        ...settings[getUrlVariableValue("settingsId")].classSettings,
        exceptions: {
          ...settings[getUrlVariableValue("settingsId")].classSettings.exceptions,
          [values.id]: {
            ...settings[getUrlVariableValue("settingsId")].classSettings.exceptions[
              values.id
            ],
            [id]: value,
            ["rateValueOne"]: defaultValues[0],
            ["rateValueTwo"]: defaultValues[1],
            ["rateValueThree"]: defaultValues[2],
          },
        },
      },
    };
    dispatch({
      type: "UPDATE_SETTINGS_PROPERTY",
      settingsId: getUrlVariableValue("settingsId"),
      uid: getUID(),
      path: `${getUrlVariableValue("settingsId")}`,
      value: newTempSettings,
    });
    // setStudioSettings(newTempSettings);
  }

  return (
    <>
      <Typography className="catch-all__text ">should be paid</Typography>
      <SelectorBox
        className="catch-all__selector-box"
        label="Pay Type"
        id="rate"
        default={[values.rate]}
        options={[
          "Exactly",
          "a bonus of",
          "Percentage",
          "Attendee Count",
          "With No Shows and Late Cancels",
          "Amount minus hourly rate",
          "Specific Class Rate",
          "Throw Flag",
          "Base Plus Per Head",
          "Percent of Pay",
          "Custom"
        ]}
        name="RateOption"
        onChange={handlePayRateChange}
      ></SelectorBox>
      {component}
    </>
  );
}

const createExceptionLabel = (tempValues) => {
  let label = "";
  let count = 0;
  if (tempValues.name !== "") {
    label = label + ("Class: " + tempValues.name.replace("text~", "") + " & ");
    count++;
  }
  if (tempValues.instructor.length !== 0) {
    label = label + ("Instructor: " + tempValues.instructor + " & ");
    count++;
  }
  if (tempValues.timeSequence !== "ALL DAY") {
    if (tempValues.timeSequence === "as the First Class") {
      label = label + "Time: First Class & ";
      count++;
    } else {
      let timeStr = tempValues.time.replace("time~", "");
      let timeFormatStr = timeStr.slice(0, 2) + ":" + timeStr.slice(2);
      label =
        label +
        ("Time: " + tempValues.timeSequence + " " + timeFormatStr + " & ");
      count++;
    }
  }
  if (count < 3 && tempValues.type !== "ANY") {
    label = label + ("Type: " + tempValues.type + " & ");
    count++;
  }
  if (count < 3 && tempValues.studio.length !== 0) {
    label = label + ("Studio: " + tempValues.studio + " & ");
    count++;
  }
  if (count < 3 && tempValues.day !== "ANY DAY") {
    label = label + ("Day: " + tempValues.day + " & ");
    count++;
  }
  if (count < 3 && tempValues.attendeeSequence !== "ANY NUMBER OF") {
    label =
      label +
      ("Attendees: " +
        tempValues.attendeeSequence +
        " " +
        tempValues.attendeeNumber.replace("number~", "") +
        " & ");
    count++;
  }
  if (count < 3 && tempValues.paySequence !== "ANY AMOUNT OF MONEY") {
    label =
      label +
      ("Payment: " +
        tempValues.paySequence +
        " $" +
        tempValues.payAmount.replace("dollar~", "") +
        " & ");
    count++;
  }

  if (label.length === 0) {
    label = "Default Exception & ";
  }

  label = label.substring(0, label.length - 3);

  return label;
};

function getDefaultValue(value) {
  let valueArray = [null, null, null];
  if (value === "Exactly" || value === "a bonus of") {
    valueArray[0] = "dollar~0";
  } else if (value === "Amount minus hourly rate") {
    valueArray[0] = "dollar~0";
    valueArray[1] = "-";
  } else if (value === "Percentage" || value === "Percent of Pay") {
    valueArray[0] = "percent~0";
  } else if (
    value === "With No Shows and Late Cancels" ||
    value === "Specific Class Rate"
  ) {
    valueArray[0] = [];
  } else if (value === "Attendee Count") {
    valueArray[0] = "number~0";
  } else if (value === "Throw Flag") {
    valueArray[0] = "text~";
  } else if (value === "Base Plus Per Head") {
    valueArray[0] = "dollar~0";
    valueArray[1] = "dollar~0";
    valueArray[2] = "number~0";
  } else if(value === "Custom"){
    valueArray[0] = "text~";
  }

  return valueArray;
}

function ClassCatchAllBox(props) {
  const { settings, dispatch, changes, setChanges } = useSettingsContext();
  
  const { currentUser, getUID } = useAuth();

  const [values, setValues] = useState(
    settings[getUrlVariableValue("settingsId")].classSettings.exceptions[props.value]
  );

  const studioOptions = [
    ...settings[getUrlVariableValue("settingsId")].generalSettings.studios,
  ];
  const staffOptions = [
    ...Object.entries(settings.staff.staff).map((s) => s[1].name),
  ];

  const getClassTypes = (includeAllOption) => {
    let types = [];
    if (includeAllOption) {
      types.push("ANY");
    }
    types = types.concat(Object.keys(settings[getUrlVariableValue("settingsId")].classSettings.classBuckets));

    return types;
  };

  const handleChange = (value, id) => {
    let newTempSettings;
    let tempValues = { ...values, [id]: value };

    let label = createExceptionLabel(tempValues);

    if (id !== "rate") {
      let field = id;
      let rateValue = findRateValue(id);
      if (rateValue !== null) {
        field = rateValue;
      }

      setValues({
        ...values,
        [field]: value,
        ["label"]: label,
      });

      let newRev = new RevertList(changes.getValues());
      newRev.addElement(
        values.id + "-" + id,
        settings[getUrlVariableValue("settingsId")].classSettings.exceptions[values.id][
          id
        ],
        value
      );
      setChanges(newRev);

      newTempSettings = {
        ...settings[getUrlVariableValue("settingsId")],
        classSettings: {
          ...settings[getUrlVariableValue("settingsId")].classSettings,
          exceptions: {
            ...settings[getUrlVariableValue("settingsId")].classSettings.exceptions,
            [values.id]: {
              ...settings[getUrlVariableValue("settingsId")].classSettings.exceptions[
                values.id
              ],
              [field]: value,
              ["label"]: label,
            },
          },
        },
      };
      dispatch({
        type: "UPDATE_SETTINGS_PROPERTY",
        settingsId: getUrlVariableValue("settingsId"),
        uid: getUID(),
        path: `${getUrlVariableValue("settingsId")}`,
        value: newTempSettings,
      });
      // setStudioSettings(newTempSettings);
    }
  };

  function findRateValue(id) {
    if (id === "exact") {
      return "rateValueOne";
    } else if (id === "bonus") {
      return "rateValueOne";
    } else if (id === "percent") {
      return "rateValueOne";
    } else if (id === "attendee") {
      return "rateValueOne";
    } else if (id === "hourlyAmount") {
      return "rateValueOne";
    } else if (id === "hourlyMath") {
      return "rateValueTwo";
    } else if (id === "classRate") {
      return "rateValueOne";
    } else if (id === "flag") {
      return "rateValueOne";
    } else if (id === "base") {
      return "rateValueOne";
    } else if (id === "perHead") {
      return "rateValueTwo";
    } else if (id === "threshold") {
      return "rateValueThree";
    } else if (id === "payPercent") {
      return "rateValueOne";
    }

    return null;
  }

  return (
    <>
      <Box className="catch-all">
        <Typography className="catch-all__text ">
          For classes of type
        </Typography>
        <SelectorBox
          className="catch-all__selector-box"
          id="type"
          onChange={handleChange}
          label="Type"
          default={[values.type]}
          options={getClassTypes(true)}
          name="Type"
        ></SelectorBox>
        <Typography className="catch-all__text ">taught</Typography>
        <SelectorBox
          className="catch-all__selector-box"
          label="Sequence"
          default={[values.timeSequence]}
          options={["ALL DAY", "Before", "After", "as the First Class"]}
          name="Sequence"
          onChange={handleChange}
          id="timeSequence"
        ></SelectorBox>
        {values.timeSequence !== "ALL DAY" &&
        values.timeSequence !== "as the First Class" ? (
          <InputBoxElement
            className="catch-all__input-box"
            id="time"
            onChange={handleChange}
            default={values.time}
            types={["time"]}
            name="Time (24-Hour)"
          ></InputBoxElement>
        ) : null}
        <Typography className="catch-all__text ">on</Typography>
        <SelectorBox
          id="day"
          className="catch-all__selector-box"
          onChange={handleChange}
          label="Day"
          default={[values.day]}
          options={[
            "ANY DAY",
            "Monday",
            "Tuesday",
            "Wednesday",
            "Thursday",
            "Friday",
            "Saturday",
            "Sunday",
          ]}
          name="Day"
        ></SelectorBox>

        <Typography className="catch-all__text ">at</Typography>
        <MultipleSelectPlaceholder
          className="catch-all__selector-box"
          options={studioOptions.sort()}
          placeholder="any location"
          id="studio"
          onChange={handleChange}
          label="Studio"
          default={values.studio}
          name="Studio"
        ></MultipleSelectPlaceholder>
        <Typography className="catch-all__text ">taught by</Typography>

        <MultipleSelectPlaceholder
          className="catch-all__selector-box"
          options={staffOptions.sort()}
          placeholder="all instructors"
          id="instructor"
          onChange={handleChange}
          label="Instructor"
          default={values.instructor}
          name="Instructor"
        ></MultipleSelectPlaceholder>
        <Typography className="catch-all__text ">with a name of</Typography>

        <SingleAttributeListBox
          //className="catch-all__search"
          multiple={false}
          freeSolo
          onChange={handleChange}
          id="name"
          default={values.name}
          options={classesExamples}
          placeholder="class name"
        ></SingleAttributeListBox>
        <Typography className="catch-all__text ">with</Typography>

        <SelectorBox
          className="catch-all__selector-box"
          label="attendeeSequence"
          default={[values.attendeeSequence]}
          options={[
            "ANY NUMBER OF",
            "less than",
            "more than",
            "exactly",
            "not exactly",
          ]}
          name="attendeeSequence"
          id="attendeeSequence"
          onChange={handleChange}
        ></SelectorBox>
        {values.attendeeSequence !== "ANY NUMBER OF" ? (
          <InputBoxElement
            id="attendeeNumber"
            className="catch-all__input-box"
            onChange={handleChange}
            default={values.attendeeNumber}
            types={["number"]}
            name="Class Name"
          ></InputBoxElement>
        ) : null}
        <Typography className="catch-all__text ">
          attendees, paid out
        </Typography>
        <SelectorBox
          className="catch-all__selector-box"
          label="paySequence"
          default={[values.paySequence]}
          options={[
            "ANY AMOUNT OF MONEY",
            "less than",
            "more than",
            "exactly",
            "not exactly",
          ]}
          name="paySequence"
          id="paySequence"
          onChange={handleChange}
        ></SelectorBox>
        {values.paySequence !== "ANY AMOUNT OF MONEY" ? (
          <InputBoxElement
            className="catch-all__input-box"
            id="payAmount"
            onChange={handleChange}
            default={values.payAmount}
            types={["dollar"]}
            name="Class Name"
          ></InputBoxElement>
        ) : null}
        <PayComponent
          value={props.value}
          onChange={handleChange}
        ></PayComponent>
      </Box>
      {/* <Divider className="settings__divider" />
      <Box className="settings-page__box--inline settings-page__box--flex-start">
        <PayComponent value={props.value}></PayComponent>
      </Box> */}

      <Divider className="settings__divider" />
      <Box className="settings-page__box--inline settings-page__box--flex-start">
        <Typography className="catch-all__text ">
          Override current payment (if lower)
        </Typography>
        <BooleanToggleElement
          id="override"
          onChange={handleChange}
          default={values.override}
        ></BooleanToggleElement>
      </Box>
      <Divider className="settings__divider" />
    </>
  );
}
