import React, { useContext, useReducer } from "react";
import {
  serializeObjectWithMethods,
  deserializeObjectWithMethods,
  updateObject,
} from "../../utility-functions/utility-functions.js";

import { useClassCreationContext } from "../ClassCreation/ClassCreationContext.js";
/**** CREATE CLASS DETAIL ****/
import { useCommissionCreationContext } from "../CommissionCreation/CommissionCreationContext";

/**** HELPER FUNCTIONS ****/

/**** REDUCER ****/
const deserializedExcelOutputContextReducer = (state, action) => {
  const serializedState = serializeObjectWithMethods(state);
  const serializedAction = serializeObjectWithMethods(action);
  const newSerializedState = excelOutputContextReducer(
    serializedState,
    serializedAction
  );

  const deserializedState = deserializeObjectWithMethods(newSerializedState);
  return deserializedState;
};
const excelOutputContextReducer = (state, action) => {
  let newState = JSON.parse(JSON.stringify(state));

  switch (action.type) {
    //  emailCreationContextDispatch({
    //   type: "UPDATE_EMAIL_CREATION_CONTEXT_STATE_PROPERTY",
    //   path: `property.property.property`,
    //   value: value,
    //   replace: true/false
    // });
    case "UPDATE_EMAIL_CREATION_CONTEXT_STATE_PROPERTY":
      updateObject(
        newState,
        action.path,
        action.value,
        action.hasOwnProperty("replace") ? action.replace : true
      );
      return newState;

    default:
      return state;
  }
};

/**** CONTEXT ****/
const ExcelOutputContext = React.createContext();
export function useExcelOutputContext() {
  return useContext(ExcelOutputContext);
}
export function ExcelOutputContextProvider({ children }) {
  const initialExcelOutputContextState = {};
  const [excelOutputContextState, excelOutputContextDispatch] = useReducer(
    deserializedExcelOutputContextReducer,
    initialExcelOutputContextState
  );
  const {
    classCreationContextState,
    classCreationContextDispatch,
  } = useClassCreationContext();
  const { createCommission } = useCommissionCreationContext();

  const value = {
    /**** CONTEXT STATE ****/
    excelOutputContextState,
    excelOutputContextDispatch,
  };

  return (
    <ExcelOutputContext.Provider value={value}>
      {children}
    </ExcelOutputContext.Provider>
  );
}
