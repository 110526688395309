import React, { useState, useRef } from "react";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Checkbox from '@mui/material/Checkbox';
import ListItemText from '@mui/material/ListItemText';
import Select from "@mui/material/Select";
import Chip from "@mui/material/Chip";
import "./selectorBox.scss";
import OutlinedInput from "@mui/material/OutlinedInput";
import { removeStringFromArray } from "../../../utility-functions/utility-functions.js";
// const ITEM_HEIGHT = 38;

const ITEM_HEIGHT = 102;

const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function SelectorBox(props) {
  const [currentlySelected, setCurrentlySelected] = useState(
    props.default ?? ""
  );
  const selectorBoxRef = useRef();
  const handleChange = (event) => {
    setCurrentlySelected(event.target.value);
    if (props.onChange) {
      props.onChange(event.target.value, props.id);
    }
  };

  const menuItemOptions = () => {
    let optionsSorted = props.options;
    let array = optionsSorted.map((option) => {
      let newOption = String(option).toLowerCase();
      return (
        <MenuItem key={option} value={option}>
          {newOption}
        </MenuItem>
      );
    });
    return array;
  };

  const checkSelectorBoxWidth = () => {
    if (selectorBoxRef.current.clientWidth > 420) {
    }
  };
  function addChips(selected) {
    if (props.multiple) {
      const selectedSorted = selected;
      const arr = [];
      selectedSorted.map((value, i) => {
        let seperator = i !== props.options.length - 1 ? "," : "";
        return arr.push(
          <Chip
            // className="selector-box__chip"
            className={
              props.className ? `${props.className}-chip` : "selector-box__chip"
            }
            key={value}
            label={value + seperator}
          />
        );
      });
      return arr;
    }

    return (
      <Chip
        // className="selector-box__chip"
        className={
          props.className ? `${props.className}-chip` : "selector-box__chip"
        }
        key={selected}
        label={selected}
      />
    );
  }

  return props.multiple ? (
    <FormControl
      style={props.style}
      variant="standard"
      className={props.className ? props.className : "selector-box"}
      ref={selectorBoxRef}
      sx={{ width: props.size }}
    >
      {/* <InputLabel
        className="selector-box__label"
        sx={{ backgroundColor: "white" }}
      >
        {props.label}
      </InputLabel> */}
      <Select
        // className="selector-box__select selector-box__select--multiple"
        className={
          props.className
            ? `${props.className}-select ${props.className}-select--multiple`
            : "selector-box__select selector-box__select--multiple"
        }
        id={props.id}
        name={props.name}
        value={currentlySelected}
        multiple={props.multiple}
        onChange={handleChange}
        // input={<OutlinedInput label="Chip2" />}
        renderValue={(selected) => (
          <Box sx={{ display: "flex", flexWrap: "wrap" }}>
            {addChips(selected)}
          </Box>
        )}
        MenuProps={MenuProps}
      >
        {menuItemOptions()}
      </Select>
    </FormControl>
  ) : (
    <FormControl
      style={props.style}
      className={props.className ? props.className : "selector-box"}
      variant="standard"
      sx={{ width: props.size }}
    >
      {/* <InputLabel sx={{ backgroundColor: "white" }}>{props.label}</InputLabel> */}
      <Select
        // className="selector-box__select"
        className={
          props.className ? `${props.className}-select` : "selector-box__select"
        }
        id={props.id}
        name={props.name}
        value={currentlySelected}
        onChange={handleChange}
        MenuProps={MenuProps}
      >
        {menuItemOptions()}
      </Select>
    </FormControl>
  );
}

export function MultipleSelectPlaceholder(props) {
  const [currentlySelected, setCurrentlySelected] = useState(
    props.default ?? ""
  );
  
  const options = [...new Set(props.options)];




  const [allStudiosSelected, setAllStudiosSelected] = useState(false);

  let disabled = props.disabled === undefined ? false : props.disabled;

  const handleChange = (event) => {
    
    
    let value = event.target.value;




    setCurrentlySelected(value);


    if (props.onChange) {
      props.onChange(value, props.id);
    }

  };




  return (
    <div style={{ width: "100%" }}>
      <FormControl
        style={props.style}
        className={props.className ? props.className : "selector-box multiple-select"}
        variant="standard"
        sx={{ width: props.size ?? "100%" }}
      >
        <Select

          fullWidth
          disabled={disabled}
          multiple
          id={props.id}
          name={props.name}
          displayEmpty
          value={currentlySelected}
          onChange={handleChange}
          renderValue={(selected) => {

            if (selected.length === 0) {
              return `Select ${props.name}`
            } else if (selected.indexOf("All Studios") > -1) {
              return "All Studios"
            } else if (selected.length > 2) {

              return selected.slice(0, 2).join(", ") + " +" + (selected.length - 2)
            }
            return selected.join(", ");
          }}
          MenuProps={MenuProps}
          // className="selector-box__select"
          className={
            props.className
              ? `${props.className}-select`
              : "selector-box__select"
          }
          inputProps={{ "aria-label": "Without label" }}
        >
          {/* <MenuItem disabled value="">
            <em>{props.placeholder}</em>
          </MenuItem> */}
          {options.map((name, index) => {
            
           return (name == "All Studios" ?
              <MenuItem key={`${name}-${index}`} value={name}>
                <Checkbox  checked={currentlySelected.indexOf("All Studios") > -1} />
                <ListItemText primary={name} />
              </MenuItem>
              :
              <MenuItem disabled={currentlySelected.indexOf("All Studios") > -1} key={`${name}-${index}`} value={name} >
                <Checkbox  checked={currentlySelected.indexOf("All Studios") > -1 || currentlySelected.indexOf(name) > -1} />
                <ListItemText  primary={name} />
              </MenuItem>)
          }
          )}
        </Select>
      </FormControl>
    </div>
  );
}
