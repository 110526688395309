import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { CardActionArea, CardActions } from "@mui/material";
import Modal from "@mui/material/Modal";
import { Box } from "@mui/material";
import tableImg from "../img/table.png";
import Blur from "react-blur";

const modalStyle = {
  display: "flex",
  flexDirection: "column",
  padding: "1.5em",
  bgcolor: "background.paper",
  boxShadow: 24,
  overflow: "scroll",
};
const capitalize = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};
export default function EmailCard(props) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  return (
    <Card sx={{ maxWidth: 345, margin: "1em" }}>
      <CardActionArea onClick={handleOpen}>
        <Blur alt="data table" blurRadius={2} img={tableImg} />
        <CardContent>
          <Typography
            component={"span"}
            variant="caption"
            display="block"
            color="text.secondary"
            gutterBottom
          >
            Pay Stub
          </Typography>

          <Typography gutterBottom variant="h5" component="div">
            {props.title}
          </Typography>
          <Typography component={"span"} variant="body1">
            {capitalize(props.staffEmail)}
          </Typography>
          <Typography
            component={"span"}
            variant="body2"
            color={"text.secondary"}
          >
            {props.subtitle}
          </Typography>
        </CardContent>
      </CardActionArea>
      <CardActions>
        {props.sendEmailButton}
        {props.sendAllButton}
      </CardActions>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "flex-start",
          overflowY: "scroll",
          marginRight: "1em",
          marginLeft: "1em",
          marginBottom: "1em",

          marginTop: "0em",
          top: 0,
          padding: "5em",
        }}
      >
        <Box sx={modalStyle}>
          {props.tables.map((table, i) =>
            i === 0 ? null : (
              <>
                <Typography gutterBottom variant="h5" component="div">
                  {table.props.rows[0] ? table.props.rows[0] : null}
                </Typography>
                {table ? table : null}
              </>
            )
          )}
        </Box>
      </Modal>
    </Card>
  );
}
