import { convertToNumber } from "./GeneralTypeConversions.js";

export class CommissionBucket{
    constructor(type){
        this.type = type;
        this.amount = 0;
        this.salesPeoplePay = {"Open": false, "Close": false, "Instructor": false, "Second": false}

        this.split = true;
    }
    
    setAmount(amount){
        let convertedAmount = convertToNumber(true, amount);
        if(convertedAmount !== null){
            this.amount = convertedAmount;
            return;
        }
        this.errorMessage = "commission amount was not a number";
    }

    // checkAmount(amount){
    //     let amountNumber = parseFloat(amount);
    //     if(isNaN(amountNumber)){
    //         return null;
    //     }

    //     if(amountNumber <= 0){
    //         return null;
    //     }
    //     return amountNumber;
    // }

    setSalesPersonPay(person, paid){
        this.salesPeoplePay[person] = paid;
    }

    setAllSalesPersonPay(salespeople){
        for(let i = 0; i < salespeople.length; i++){
            if(salespeople[i].includes("Opening")){
                this.salesPeoplePay["Open"] = true;
            } else if(salespeople[i].includes("Closing")){
                this.salesPeoplePay["Close"] = true;
            } else if(salespeople[i].includes("Instructor")){
                this.salesPeoplePay["Instructor"] = true;
            } else if(salespeople[i].includes("Secondary")){
                this.salesPeoplePay["Second"] = true;
            }
        }
    }

    getSalesPersonPay(person){
        return this.salesPeoplePay[person];
    }

    setSplit(boolean){
        this.split = boolean;
    }
}