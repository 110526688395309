// AddStaffComponent.js
import React, { useState, useEffect } from "react";
import {
  Container,
  TextField,
  Grid,
  Typography,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  OutlinedInput,
  FormGroup,
  FormControlLabel,
  Switch,
  Card,
  CardContent,
  Autocomplete,
  Button,
  DialogActions,
  Input,
  Checkbox,
  ListItemText,
  Stack,
} from "@mui/material";
import { useSnackBar } from "../../contexts/SnackBarContext/SnackBarContext";
import { useStudioScrapeStatus } from "../../contexts/StudioScrapeStatus/StudioScrapeStatusContext";
import { useSettingsContext } from "../../contexts/SettingsContext";
// import {
//   deepClone,
//   splitRateAndInfoDetails,
//   getFirstObject,
//   findStaffByName,
//   findNewStaffObjects,
//   makeFirstLetterLowercase,
// } from "./utils"; // Adjust the path as necessary

import { findStaffByName, makeFirstLetterLowercase } from "../../components/StaffProfile/utils";
//import "./staffDialog.scss";
import { getUrlVariableValue } from "../../utility-functions/utility-functions";
import { useAuth } from "../../contexts/AuthContext";
import { updateStaffMasterSettings } from "../../services/staff.services";
import { consolidateStaff, ensureUniqueIds } from "./utils";

import { useStaffContext } from "../../contexts/StaffContext/StaffContext";
import { LoadingButton } from "@mui/lab";

const AddStaffComponentSettings = ({ onSave, onClose }) => {
  /*** Staff Context ***/
  const { getUID } = useAuth();
  const { settings, dispatch, reportCompilerDispatch } = useSettingsContext(); // Assuming this hook/context is available
  const {
    editableStaff,
    loading,
  } = useStaffContext();

  const allTypes = settings[
    getUrlVariableValue("settingsId")
  ].generalSettings?.staffTypes?.map((staff) => staff.name);

  const [formValues, setFormValues] = useState(settings[getUrlVariableValue("settingsId")].generalSettings.staffFields.reduce((acc, item) => {
    if (item.required === true && item.field !== "locations") {
      acc[item.field] = item.default;
      if(item.field === 'type'){
        acc[item.field] = allTypes[0];
      }
    }
    return acc;
  }, {})
);

  const handleChange = (fieldName) => (event) => {
    setFormValues(prevValues => ({
      ...prevValues,
      [fieldName]: event.target.value
    }));
  };

  const handleChangeArray = (fieldName) => (value) => {
    setFormValues(prevValues => ({
      ...prevValues,
      [fieldName]: value
    }));
  };

  const handleSubmit = async (e) => {
    onSave(formValues); // Assuming onSave is a callback to handle post-save actions
  };

  /*** End Staff Context ***/

  const showSnackBar = useSnackBar();
  const { currentlySelectedStudios } = useStudioScrapeStatus();

  
  let staffFields = settings[getUrlVariableValue("settingsId")].generalSettings.staffFields.filter(field => field.order >= 0).sort((a, b) => a.order - b.order);
  let studiosOptions = settings[getUrlVariableValue("settingsId")].generalSettings.studios;

  const checkEmptyFields = (obj) => {
    for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
            const value = obj[key];
            // Check for empty string or empty array
            if (value === "" || (Array.isArray(value) && value.length === 0)) {
                return true;
            }
        }
    }
    return false; // Return false if no empty fields are found
}
  return (
    <Stack spacing={3} sx={{ margin: "16px  0" }}>
      {
        staffFields.map(field => {
          const fieldValue = formValues[field.default];

        if ((field.type === 'text' || field.type === 'string') && field.field === 'name') {
          return (
            <TextField
              required
              key={field.field}
              label={field.name}
              fullWidth
              value={fieldValue}
              onChange={handleChange(field.field)}
              margin="dense"
            />
          );
        } else if (field.type === 'singleSelect' && field.field === 'type') {
          return (
            <FormControl fullWidth key={'form-' + field.field}>
              <InputLabel id="staff-type-label">{field.name}</InputLabel>
              <Select
                required
                labelId={`${field.name}-label`}
                key={field.field}
                label={field.name}
                name={field.field}
                onChange={(e) => {
                    handleChange(field.field)(e);
                  }
                }
                defaultValue={formValues[field.field]}
                input={<OutlinedInput label={field.name} />}
              >
                {allTypes.map((type) => (
                  <MenuItem key={type} value={type}>
                    {type}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          );
        } else if (field.type === "list" && field.field === "alternateNames" && field.required){
          return (
            <Autocomplete
              required
              key={field.field}
              multiple
              freeSolo
              options={field.options ? field.options : []}
              value={formValues[field.field]}
              onChange={(event, newValue) => {
                handleChange(field.field, newValue);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label={field.name}
                  placeholder={"Add " + field.name}
                />
              )}
            />
          );
        } else if (field.type === "list" && field.field === "titles" && field.required) {
          return(
            <Autocomplete
              required
              multiple
              freeSolo
              key={field.field}
              options={field.options ? field.options : []}
              getOptionLabel={(option) => option}
              value={formValues[field.field]}
              onChange={(event, newValue) => {
                handleChangeArray(field.field)(newValue);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label={field.name}
                  placeholder={"Select " + field.name}
                />
              )}
            />
          )
        } else if (field.type === "list" && field.field === "locations" && field.required) {
          // return(
          //   <FormControl fullWidth>
          //     <InputLabel>{field.name}</InputLabel>
          //     <Select
          //       required
          //       multiple
          //       //disabled={staff.errorType == "unaddedLocation"}
          //       value={editableStaff.locations}
          //       onChange={(event) => handleChange(field.field, event.target.value)}
          //       input={<OutlinedInput label={field.name} />}
          //       renderValue={(selected) => selected.join(", ")}
          //       MenuProps={{
          //         PaperProps: {
          //           style: {
          //             maxHeight: 224,
          //           },
          //         },
          //       }}
          //     >
          //       {studiosOptions.map((location) => (
          //         <MenuItem key={location} value={location}>
          //           <Checkbox
          //             checked={editableStaff.locations?.indexOf(location) > -1} //maybe an issue
          //           />
          //           <ListItemText primary={location} />
          //         </MenuItem>
          //       ))}
          //     </Select>
          //   </FormControl>
          // )
        } else if( (field.type === "text" || field.type === 'string') && field.field === "email" && field.required){
          return(
            <TextField
              required
              key={field.field}
              label={field.name}
              name={field.field}
              fullWidth
              margin="dense"
              value={formValues[field.field]}
              onChange={handleChange(field.field)}
            />
          )
        } else if( (field.type === "text" || field.type === 'string') && field.field === "idString" && field.required){
          return(
            <TextField
              required
              key={field.field}
              label={field.name}
              name={field.field}
              fullWidth
              margin="dense"
              value={formValues[field.field]}
              onChange={handleChange(field.field)}
            />
          )
        } else if( (field.type === "text" || field.type === 'string') && field.field === "secondIdString" && field.required){
          return(
            <TextField
              required
              key={field.field}
              label={field.name}
              name={field.field}
              fullWidth
              margin="dense"
              value={formValues[field.field]}
              onChange={handleChange(field.field)}
            />
          )
        } else if( (field.type === "text" || field.type === 'string') && field.field === "homeRegion" && field.required){
          return(
            <TextField
              required
              key={field.field}
              label={field.name}
              name={field.field}
              fullWidth
              margin="dense"
              value={formValues[field.field]}
              onChange={handleChange(field.field)}
            />
          )
        } else if( (field.type === "singleSelect") && field.field === "homeLocation" && field.required){
          return(
            <FormControl fullWidth key={'form-' + field.field}>
              <InputLabel>{field.name}</InputLabel>
              <Select
              required
              key={field.field}
                value={formValues[field.field]}
                onChange={(e) => {
                    handleChange(field.field)(e);
                  }
                }
                input={<OutlinedInput label={field.name} />}
              >
                {studiosOptions.map((location) => (
                  <MenuItem key={location} value={location}>
                    {location}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )
        } else if( (field.type === "singleSelect") && field.field === "tier" && field.required && false){ //not built out yet
          return(
            <FormControl fullWidth key={'form-' + field.field}>
              <InputLabel>{field.name}</InputLabel>
              <Select
              key = {field.field}
              required
                value={field.default}
                onChange={(e) => {
                  handleChange(field.field)(e);
                }
              }
                input={<OutlinedInput label={field.name} />}
              >
                {currentlySelectedStudios.map((location) => (
                  <MenuItem key={location} value={location}>
                    {location}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )
        } else if( (field.type === "text" || field.type === 'string') && field.required){
          return(
            <TextField
              required
              key={field.field}
              label={field.name}
              name={field.field}
              fullWidth
              margin="dense"
              value={formValues[field.field]}
              //onChange={(e) => handleChange(field.field, e.target.value)}
              onChange={handleChange(field.field)}
            />
          )
        } else if( (field.type === "singleSelect") && field.required ){
          return(
            <FormControl fullWidth key={"form-" + field.field}>
              <InputLabel>{field.name}</InputLabel>
              <Select
              required
                key={field.field}
                value={formValues[field.field]}
                onChange={(e) => {
                  handleChange(field.field)(e);
                }
              }
                input={<OutlinedInput label={field.name} />}
              >
                {field.options.map((location) => (
                  <MenuItem key={opt} value={opt}>
                    {opt}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )
        } else if (field.type === "list" && field.required) {
            return(
              <Autocomplete
                required
                multiple
                freeSolo
                key={field.field}
                options={field.options ? field.options : []}
                getOptionLabel={(option) => option}
                value={formValues[field.field]}
                onChange={(event, newValue) => {
                  handleChangeArray(field.field)(newValue);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label={field.name}
                    placeholder={"Select " + field.name}
                  />
                )}
              />
            )
          }
        })
      }

      <DialogActions className="add-staff-component__actions">
        <Button onClick={() => onClose()}>Cancel</Button>

        <LoadingButton
          loading={loading}
          variant="contained"
          disabled={checkEmptyFields(formValues)}
          onClick={handleSubmit}
        >
          Confirm
        </LoadingButton>
      </DialogActions>
    </Stack>
  );
};

export default AddStaffComponentSettings;
