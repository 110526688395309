//Type, Location, Date Sold, Sold To, Payment, What was sold, Previous Agreement (if applicable), Upgrade or Downgrade (if applicable)
//.. openedsalesperson (if applicable), opened salesperson pay (if applicable), closed salesperson (if applicable), opened salesperson pay (if applicable), 

export class CommissionObject {
    constructor(location, dateSold, payment, customerFirst, customerLast, description) {
      this.location = location;
      this.dateSold = dateSold;
      this.payment = payment;
      this.clientFirstName = customerFirst;
      this.clientLastName = customerLast;
      this.customer = customerFirst + " " + customerLast;
      this.description = description;
      this.previousPurchases = {previousAmount: 0, previousPurchase: "", type: "N/A"};
      this.allPreviousPurchases = [];
      this.notes = "";

      this.type = "";
      this.discount = 0;

      this.salespeople = {open:null, close:null, instructor:null, secondary:null};
      this.salespay = {open:0, close:0, instructor:0, secondary:0};

      this.comment = [];
  
      //this.addedToExcel = false;
    }

    addPreviousPurchase(amount, agreements, fullName, date, type, cancelDate, previousPay){
        this.previousPurchases.previousAmount = amount;
        this.previousPurchases.previousAgreements = agreements;
        this.previousPurchases.previousPurchase = fullName;
        this.previousPurchases.previousAgreementDate = date;
        this.previousPurchases.type = type;
        this.previousPurchases.previousAgreementCancelDate = cancelDate;
        this.previousPurchases.previousAgreementPay = previousPay;
    }

    addSalesPerson(type, name){
        this.salespeople[type] = name;
    }

    addSalesPay(type, amount){
        this.salespay[type] = amount;
    }

    addSalesPayByName(salesPersonName, amount){
        //
    }
  
    commentsToString() {
      let commentStr = "";
      for (let i = 0; i < this.comment.length; i++) {
        if (this.comment[i].length > 0) {
          commentStr = commentStr + " / " + this.comment[i];
        }
      }
      if (commentStr.length === 0) {
        return "";
      }
      return commentStr.substring(3);
    }
  }
  