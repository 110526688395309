export class BookingEvent {
  constructor(classAttributes, eventAttributes, valid) {
    this.classInstructor = classAttributes?.instructor ?? null;
    this.className = classAttributes?.className ?? null;
    this.location = classAttributes?.location ?? null;
    this.classDate = classAttributes?.date ?? null;
    this.sessionLength = classAttributes?.sessionLength ?? null;

    this.eventType = eventAttributes?.type ?? null;
    this.eventStatus = eventAttributes?.status ?? null;
    this.eventClientFirstName = eventAttributes?.firstName ?? null;
    this.eventClientLastName = eventAttributes?.lastName ?? null;
    this.eventLoggedBy = eventAttributes?.loggedBy ?? null;
    this.eventLogDate = eventAttributes?.loggedTime ?? null;

    this.valid = valid ?? null;
  }

  getMemberName() {
    return this.eventClientFirstName + " " + this.eventClientLastName;
  }

  getClassTime() {
    let hours = this.classDate.getHours();
    let minutes = this.classDate.getMinutes();
    const ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours ? hours : 12;
    minutes = minutes < 10 ? "0" + minutes : minutes;
    const strTime = hours + ":" + minutes + " " + ampm;
    return strTime;
  }
}

export class ClassAttributes {
  constructor(instructor, className, location, dateObject, sessionLength) {
    this.instructor = instructor;
    this.className = className;
    this.location = location
    this.date = dateObject;
    this.sessionLength = sessionLength;
  }
}

export class EventAttributes {
  constructor(type, status, firstName, lastName, loggedBy, loggedTime) {
    this.type = type;
    this.status = status;
    this.firstName = firstName;
    this.lastName = lastName;
    this.loggedBy = loggedBy;
    this.loggedTime = loggedTime;
  }
}
