import { Member } from "../ReportCompiler/classes/Member.js";
import { createDateFromString } from "../GeneralFunctions/CreateDateFromString.js";
export function readActiveMemberLog(json) {
  const memArray = [];
  let memTableStudioName = "";
  let firstNameI = 0;
  let lastNameI = 0;
  let memberStartDateI = 0;

  let correctFileType = false;
  for (let i = 0; i < json.length; i++) {
    if (isMemberHeaderRow(json[i])) {
      for (let u = 0; u < json[i].length; u++) {
        if (json[i][u].includes("First Name")) {
          firstNameI = u;
        } else if (json[i][u].includes("Last Name")) {
          lastNameI = u;
        } else if (json[i][u].includes("Member Since")) {
          memberStartDateI = u;
        }
      }
      correctFileType = true;
    } else if (isMemberDataRow(json[i])) {
      const memDate = createDateFromString(json[i][memberStartDateI], "00:00");

      const member = new Member(
        memTableStudioName,
        memDate,
        json[i][firstNameI],
        json[i][lastNameI]
      );
      memArray.push(member);
    } else if (isMemberLocationRow(json[i])) {
      const startIndex = json[i][0].indexOf("-") + 2;
      const endIndex = json[i][0].lastIndexOf("(") - 1;
      memTableStudioName = json[i][0].substring(startIndex, endIndex);
    }
  }

  const reportData = new ReportData(correctFileType, memArray);
  return reportData;
}

function isMemberHeaderRow(row) {
  return (
    ((row[0].includes("User ID") || row[0].includes("Store ID")) &&
      row[1].includes("Location Name")) ||
    row[1].includes("First Name") ||
    row[0].includes("First Name")
  );
}

function isMemberDataRow(row) {
  return (
    row[0] !== "" &&
    row[2] !== "" &&
    !row[0].includes("Member First Name") &&
    !row[0].includes("Store ID")
  );
}

function isMemberLocationRow(row) {
  return (
    row[0].includes("Member List -") || row[0].includes("Active Members -")
  );
}

class ReportData {
  constructor(isCorrectFile, fileDataArray) {
    this.correctFileType = isCorrectFile;
    this.dataArray = fileDataArray;
    this.payPeriod = null;
    this.classesInFile = null;
    this.staffInFile = null;
  }
}
