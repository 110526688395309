import React, { useContext, useReducer } from "react";
import SettingsPresetCardList from "../SettingPresetCard/SettingPresetCard";
import MultipleSelectChip from "../MultiSelectChip/MultiSelectChip";
import DropzoneScrapeTabs from "../Tabs/DropzoneScrapeTabs";
import { useAuth } from "../../contexts/AuthContext";
import DownloadPreview from "../DownloadPreview/DownloadPreview";
import SettingsIcon from "@mui/icons-material/Settings";
import AddBusinessRoundedIcon from "@mui/icons-material/AddBusinessRounded";
import NoteAddRoundedIcon from "@mui/icons-material/NoteAddRounded";
import DownloadForOfflineRoundedIcon from "@mui/icons-material/DownloadForOfflineRounded";
import { useSettingsContext } from "../../contexts/SettingsContext";
const StepperContext = React.createContext();

export function useStepperContext() {
  return useContext(StepperContext);
}

const stepperReducer = (state, action) => {
  var newState = { ...state };

  switch (action.type) {
    case "NEXT_STEP":
      return {
        steps: [...state.steps],
        activeStep: state.activeStep + 1,
        previousStep: state.activeStep, // Set the previous step
        nextButtonDisabled:
          action.nextButtonDisabled ?? state.nextButtonDisabled,
        nextButtonLoading: action.nextButtonLoading ?? state.nextButtonLoading,
      };
    case "PREV_STEP":
      return {
        steps: [...state.steps],
        activeStep: state.activeStep - 1,
        previousStep: state.activeStep, // Set the previous step
        nextButtonDisabled:
          action.nextButtonDisabled ?? state.nextButtonDisabled,
        nextButtonLoading: action.nextButtonLoading ?? state.nextButtonLoading,
      };
    case "SET_NEXT_BUTTON_LOADING":
      newState["nextButtonLoading"] = action.loading;
      return newState;

    case "ENABLE_NEXT_BUTTON":
      newState["nextButtonDisabled"] = false;
      return newState;

    case "DISABLE_NEXT_BUTTON":
      newState["nextButtonDisabled"] = true;
      return newState;

    default:
      return state;
  }
};

export default function StepperContextProvider({ children }) {
  const { settings, dispatch, reportCompilerDispatch } = useSettingsContext();
  const { currentUser, getUID } = useAuth();
  const [stepperState, dispatchStepper] = useReducer(stepperReducer, {
    steps: [
      {
        label: `1. ${
          currentUser
            ? currentUser["adminSelectedUserUID"]
              ? `Settings for ${currentUser["adminSelectedUserUID"]}`
              : "Settings"
            : "Settings"
        }`,
        labelIcon: <SettingsIcon />,
        title: "Settings ",
        description: "Edit and select PayWell settings",
        content: <SettingsPresetCardList settings={settings} />,
      },
      {
        label: "2. Locations",
        labelIcon: <AddBusinessRoundedIcon />,
        title: "Locations",
        description: "Select locations to run",
        content: <MultipleSelectChip></MultipleSelectChip>,
      },
      {
        label: "3. Reports",
        labelIcon: <NoteAddRoundedIcon />,
        title: "Reports",
        description:
          "Pull your payroll reports",
        content: <DropzoneScrapeTabs />,
      },
      {
        label: "4. Download",
        labelIcon: <DownloadForOfflineRoundedIcon />,
        title: "Review & Download",
        description:
          "Review unadded staff payments before downloading your payroll",
        content: <DownloadPreview />,
      },
    ],
    nextButtonDisabled: true,
    nextButtonLoading: false,
    activeStep: 0,
    previousStep: -1, // Initialize the previousStep
    dialogVisible: false,
  });

  function handleNextStep(step = false) {
    // Going to Studio Selection Page
    if (stepperState.activeStep == 0) {
      dispatchStepper({ type: "NEXT_STEP" });
      // Going to Scrape
    } else if (stepperState.activeStep == 1) {
     
      reportCompilerDispatch({ type: "BUILD_REPORT_COMPILER_STATE" });

      dispatchStepper({ type: "NEXT_STEP", nextButtonDisabled: false });

      // Going to Download Preview
    } else if (stepperState.activeStep == 2) {
      // reportCompilerDispatch({ type: "BUILD_REPORT_COMPILER_STATE_STAFF" });
      
      reportCompilerDispatch({
        type: "BUILD_REPORT_COMPILER_STATE_ERROR_LIST",
      });

      reportCompilerDispatch({ type: "BEL_CHECK" });

      dispatchStepper({ type: "NEXT_STEP" });
    }
  }

  function handlePrevStep() {
    // Going to settinngs selection
    if (stepperState.activeStep == 1) {
      dispatchStepper({ type: "PREV_STEP" });

      // Going to Scrape
    } else if (stepperState.activeStep == 2) {
      
      dispatchStepper({ type: "PREV_STEP" });
    } else if (stepperState.activeStep == 3) {
      dispatchStepper({ type: "PREV_STEP" });
    }
  }

  // Context
  const value = {
    stepperState,
    dispatchStepper,
    handleNextStep,
    handlePrevStep,
  };

  return (
    <StepperContext.Provider value={value}>{children}</StepperContext.Provider>
  );
}
