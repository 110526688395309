import React, { useEffect } from 'react';
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import { DialogContentText, Stack } from '@mui/material';

import "./dialogs.scss";



const ReusableDialog = ({ open, onClose, title, subtitle, children, actions, fullWidth, minWidth }) => {
  return (
    <Dialog  fullWidth={fullWidth || false} maxWidth="lg" open={open} onClose={onClose}>

      {title ? <DialogTitle component={"h3"}>{title}</DialogTitle> : ""}

      <DialogContent>
        {subtitle ? <DialogContentText >{subtitle}</DialogContentText> : ""}

        <Stack spacing={1} sx={{ margin: "16px  0" }}>
          {children}
        </Stack>
      </DialogContent>
      <DialogActions style={{ padding: "24px" }}>
        {actions.map((action, index) => (
          <Button key={index} onClick={action?.onClick} color={action?.color} variant={action?.variant} disabled={action?.disabled}>
            {action.label}
          </Button>
        ))}
      </DialogActions>
    </Dialog>
  );
};

export default ReusableDialog;




// const showOnceReusableDialog = ({ open, onClose, title, customContent, actions }) => {
//   useEffect(() => {
//     const dialogClosed = localStorage.getItem('dialogClosed');

//     if (open && !dialogClosed) {
//       // Set the flag in localStorage to indicate that the dialog is open
//       localStorage.setItem('dialogClosed', 'true');
//     }
//   }, [open]);

//   const handleClose = () => {
//     // Set the flag in localStorage to indicate that the dialog has been closed
//     localStorage.setItem('dialogClosed', 'true');
//     onClose();
//   };

//   return (
//     <Dialog open={open} onClose={handleClose}>
//       <DialogTitle component="h3">{title}</DialogTitle>
//       <DialogContent>{customContent}</DialogContent>
//       <DialogActions>
//         {actions.map((action, index) => (
//           <Button key={index} onClick={action.onClick} color={action.color}>
//             {action.label}
//           </Button>
//         ))}
//       </DialogActions>
//     </Dialog>
//   );
// };

// export showOnceReusableDialog;
