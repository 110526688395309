import { v4 as uuidv4 } from "uuid";

export function deepClone(obj) {
  return JSON.parse(JSON.stringify(obj));
}

export function splitRateAndInfoDetails(staffObject) {
  const rateKeys = [
    "commissionRate",
    "secondaryHourly",
    "groupRate",
    "thirtyMinPrivateRate",
    "hourly",
    "privateRate",
    "introRate",
    "semiPrivateRate",
  ];

  const initialRateDetails = {
    commissionRate: {
      retail: "69",
      agreement: "69",
    },

    // locations: [
    //   "Club Pilates North Naperville",
    //   "Club Pilates South Naperville",
    // ],
    secondaryHourly: "69",

    hourly: "69",

    semiPrivateRate: {
      none: false,
      zero: "0",
      base: "69",
      perHead: "69",
      afterAttendee: "69",
      flat: "0",
      custom: [],
    },
    groupRate: {
      none: false,
      zero: "0",
      base: "69",
      perHead: "69",
      afterAttendee: "69",
      flat: "0",
      custom: [],
    },
    introRate: {
      none: false,
      zero: "69",
      base: "0",
      perHead: "0",
      afterAttendee: "0",
      flat: "69",
      custom: [],
    },
    privateRate: {
      none: false,
      zero: "0",
      base: "0",
      perHead: "0",
      afterAttendee: "0",
      flat: "69",
      custom: [],
    },
    thirtyMinPrivateRate: {
      none: false,
      zero: "0",
      base: "0",
      perHead: "0",
      afterAttendee: "0",
      flat: "69",
      custom: [],
    },
  };

  const initialInfoDetails = {
    alternateNames: ["69"],
    locations: [
      "Club Pilates North Naperville",
      "Club Pilates South Naperville",
    ],

    secondIdString: "69",
    type: "Instructor",
    paywellUID: "0Pqd7ciGP8cXe8TrOMQ9BYp0imp2",
    email: "69",
    homeLocation: "Club Pilates North Naperville",

    name: "Error Correction Test Staff",
    isNew: false,
    status: "new",
    id: "470b2df5-faa7-4e28-bb9b-94c82670f5da",
    homeRegion: "",
    idString: "69",
    titles: ["69"],
    brand: "Club Pilates",

    updated: false,
    statusField: false,
  };

  // Iterate over each property in the input object
  for (const [key, value] of Object.entries(staffObject)) {
    if (rateKeys.includes(key)) {
      // If the key is recognized as a rate detail, transfer it
      initialRateDetails[key] = value;
    } else {
      // Otherwise, consider it an info detail if it exists in initialInfoDetails
      // if (initialInfoDetails.hasOwnProperty(key)) {
      initialInfoDetails[key] = value;
      // }
    }
  }

  // Correcting specific fields based on provided requirements
  initialRateDetails.hourly = staffObject.hourly || "69"; // Assuming you want to default to "69" if not provided

  // Return both objects
  return { initialRateDetails, initialInfoDetails };
}

export function findStaffByName(staffList, name) {
  // Iterate over each property in the staffList object
  for (const id in staffList) {
    // Check if this property is an own property of the object
    if (staffList.hasOwnProperty(id)) {
      const staff = staffList[id];
      // Check if the name matches the given name
      if (staff.name === name) {
        return staff; // Return the matching staff object
      } else if(staff.alternateNames.includes(name)) {
        return staff; // Return the matching staff object
      }
    }
  }
  // If no match is found, return null or an appropriate value
  
  return null;
}

export function findNewStaffObjects(obj) {
  const result = [];
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      const item = obj[key];
      if (item.updated) {
        result.push(item);
      }
    }
  }
  return result;
}

export function getFirstObject(obj) {
  // Ensure the object is not empty
  if (Object.keys(obj).length === 0) {
    return null; // or undefined, based on your preference for empty objects
  }
  const firstKey = Object.keys(obj)[0]; // Get the first key
  return obj[firstKey]; // Return the value associated with the first key
}

// utils/staffUtils.js
export const consolidateStaff = ({
  sharedInfo,
  sharedRates,
  selectedLocations,
  info,
  rates,
}) => {
  let consolidatedStaff = [];

  if (sharedInfo && sharedRates) {
    // Both info and rates are shared across locations
    const staffObject = {
      ...info.shared,
      ...rates.shared,
      locations: selectedLocations,
    };
    consolidatedStaff.push(staffObject);
  } else if (sharedInfo && !sharedRates) {
    // Only info is shared
    selectedLocations.forEach((location) => {
      const staffObject = {
        ...info.shared,
        ...rates[location],
        locations: [location],
      };
      consolidatedStaff.push(staffObject);
    });
  } else if (!sharedInfo && sharedRates) {
    // Only rates are shared
    selectedLocations.forEach((location) => {
      const staffObject = {
        ...info[location],
        ...rates.shared,
        locations: [location],
      };
      consolidatedStaff.push(staffObject);
    });
  } else {
    // Neither info nor rates are shared
    selectedLocations.forEach((location) => {
      const staffObject = {
        ...info[location],
        ...rates[location],
        locations: [location],
      };
      consolidatedStaff.push(staffObject);
    });
  }

  return consolidatedStaff;
};

// utils/staffUtils.js
export const ensureUniqueIds = (staffMembers) => {
  const seenIds = new Set();
  staffMembers.forEach((staff) => {
    if (seenIds.has(staff.id)) {
      staff.id = uuidv4(); // Ensure uuidv4 is imported or available
    }
    seenIds.add(staff.id);
  });
  return staffMembers;
};

export function makeFirstLetterLowercase(str) {
  if (str.length === 0) return str; // Handle empty string case
  return str.charAt(0).toLowerCase() + str.slice(1);
}
