import React, { useState, useRef, forwardRef } from "react";
import { Link } from "react-router-dom";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ArrowRight from "@mui/icons-material/ArrowRight";
import styled from "@emotion/styled";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

// Styled Components
const StyledMenuItem = styled(MenuItem)`
  display: flex;
  justify-content: space-between !important;
  & > svg {
    margin-left: 32px;
  }
`;

export const Dropdown = ({ trigger, menuItems }) => {
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <>
      <div ref={anchorRef} onClick={handleOpen}>
        {trigger}
      </div>
      <Menu
        anchorEl={anchorRef.current}
        open={open}
        onClose={handleClose}
        PaperProps={{ sx: { minWidth: 0 } }}
      >
        {menuItems.map((item, index) => (
          item.children ? (
            <NestedMenuItem
              key={index}
              label={item.label}
              children={item.children}
              handleClose={handleClose} // Pass the handleClose function to manage menu closure
            />
          ) : (
            <MenuItem key={index} onClick={handleClose}>
              {item.label}
            </MenuItem>
          )
        ))}
      </Menu>
    </>
  );
};

export const NestedMenuItem = ({ label, children, rightIcon = <KeyboardArrowDownIcon />, handleClose, ...props }) => {
  const [open, setOpen] = useState(false);
  const menuItemRef = useRef(null);

  const handleToggle = (event) => {
    event.stopPropagation(); // Prevents click from affecting parent elements
    setOpen(prev => !prev);
  };

  return (
    <div ref={menuItemRef}>
      <StyledMenuItem {...props} onClick={handleToggle}>
        {label}
        {rightIcon}
      </StyledMenuItem>
      <Menu
        anchorEl={menuItemRef.current}
        open={open}
        onClose={() => setOpen(false)}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        {children.map((child, childIndex) => (
          <MenuItem key={childIndex} onClick={() => {
            handleClose(); // Close the entire menu structure
          }}>
            {child.label}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};
