import React, { useContext, useReducer } from "react";
 
const DrawerContext = React.createContext();

export function useDrawerContext() {
  return useContext(DrawerContext);
}

const drawerReducer = (state, action) => {
  switch (action.type) {
    case "SET_PAGE_TITLE":
      return { pageTitle: action.pageTitle, mobileOpen: state.mobileOpen };
    case "OPEN_DRAWER":
      return { pageTitle: state.pageTitle, mobileOpen: true };
    case "CLOSE_DRAWER":
      return { pageTitle: state.pageTitle, mobileOpen: false };

    default:
      return state;
  }
};

export default function DrawerContextProvider({ children }) {
  const [drawerState, drawerDispatch] = useReducer(drawerReducer, {
    pageTitle: "Studios",
    mobileOpen: false,
  });

  // Context
  const value = {
    drawerState,
    drawerDispatch,
  };

  return (
    <DrawerContext.Provider value={value}>{children}</DrawerContext.Provider>
  );
}
