import { convertToArray, convertToNumber } from "./GeneralTypeConversions.js";

export class CatchAllTimeLogic{
    constructor(type){
        this.type = type;
        this.utility = "Add"
        this.location = ["All"];
        this.staff = ["All"];
        this.hours = 0;
        this.rate = 0;
        this.total = 0;
        this.payLogic = {sequence: null, day: null};
        this.comment = "";
    }
    setType(type){
        this.type = type;
    }

    setUtility(utility){
        this.utility = utility;
    }

    setLocation(location){
        this.location = location;
    }

    setStaff(staffArray){
        let convertedArray = convertToArray(staffArray);
        if(convertedArray !== null){
            this.staff = convertedArray;
            return;
        }
        this.errorMessage = "staff array could not be read";
    }

    setHours(hours){
        let convertedHours = convertToNumber(true, hours);
        if(convertedHours !== null){
            this.hours = convertedHours;
            return;
        }
        this.errorMessage = "hours are unreadable";
    }

    setRate(rate){
        let convertedRate = convertToNumber(true, rate);
        if(convertedRate !== null){
            this.rate = convertedRate;
            return;
        }
        this.errorMessage = "rate is unreadable";
    }

    setTotal(totalPay, override){
        if(override){
            this.total = totalPay;
            this.rate = totalPay;
            //this.hours = 1;
        } else {
            this.total = totalPay;
        }
    }

    setPayLogic(sequence, dayOfMonth){
        this.payLogic.sequence = sequence;
        this.payLogic.day = dayOfMonth;
    }

    setComment(comment){
        this.comment = comment;
    }
}