import React, { useCallback, useState, useEffect } from "react";
import { createStaffEmailArrays } from "../../utility-functions/EmailCreation/CreateEmails";
import { useDropzone } from "react-dropzone";
import { Card, Box } from "@mui/material";
import FolderRoundedIcon from "@mui/icons-material/FolderRounded";
import Button from "@mui/material/Button";
import BasicTable from "../../components/Table";
import { useAuth } from "../../contexts/AuthContext";
import FileUploadStatus from "../../components/FileUploadStatus";
import { useSettingsContext } from "../../contexts/SettingsContext";
import { buildReportCompilerState } from "../../contexts/ReportCompiler/utility-functions.js";
import "./staffEmailer.scss";
export default function StaffEmailerDropzone(props) {
  const {settings, dispatch, reportCompilerState} = useSettingsContext();


  const { currentUser } = useAuth();
  const [state, setState] = useState();
  // Utility Functions
  const createSeperateTables = (rowsFiltered, rows) => {
    var jsxTables = [];
    let sliceIndexes = [];
    for (let i = 0; i < rowsFiltered.length; i++) {
      if (rowsFiltered[i].length === 1) {
        sliceIndexes.push(i);
      }
    }
    let tables = [];
    for (let i = 0; i < sliceIndexes.length; i++) {
      if (i === 0) {
        tables.push(rows.slice(0, sliceIndexes[i]));
      } else {
        tables.push(rows.slice(sliceIndexes[i - 1], sliceIndexes[i]));
      }
    }
    tables.push(rows.slice(sliceIndexes[sliceIndexes.length - 1]));

    // Convert tables array  into BasicTable's
    jsxTables = tables.map((table, index) => {
      return <BasicTable key={index} rows={table} />;
    });

    return jsxTables;
  };
  const filterArray = (a, b) => {
    return a.filter((e) => {
      return e !== b;
    });
  };
  const filterNestedArray = (a, b) => {
    let firstLevel = filterArray(a, b);

    return firstLevel.map((el) => {
      if (Array.isArray(el)) {
        return filterArray(el, b);
      } else {
        return el;
      }
    });
  };
  const removeEmptyRows = (array) => {
    return array.filter(
      (row) => !row.every((cell) => cell === "" || cell === "'")
    );
  };
  const deleteExcessRows = (table) => {
    for (let i = table.length - 1; i >= 0; i--) {
      for (let j = 0; j < table[i].length; j++) {
        if (table[i][j].length > 0) {
          return table.slice(0, i + 1);
        }
      }
    }
  };
  const arrayToHTML = (unfilteredRows, headers) => {
    var html = "";

    html += headers.map((header) => `<h1>${header}</h1>`);
    let tableStyle = `<head><style>table {empty-cells: hide;border: none;}tbody {font-family: Arial,Helvetica, sans-serif;border-collapse: collapse;width: 100%;border: none;}th {font-weight: bold;border: none;}tr {background-color: #f2f2f2 !important; border: none;padding: 25px;}td {border: none;padding: 8px;margin: 0px;}</style><head><table><tbody>`;
    let tableData = "<table border=1>";
    for (let i = 0; i < unfilteredRows.length; i++) {
      tableData += "<tr>";
      for (let j = 0; j < unfilteredRows[i].length; j++) {
        tableData += "<td>" + unfilteredRows[i][j] + "</td>";
      }
      tableData += "</tr>";
    }
    tableData += "</tbody></table>";
    html += tableStyle;
    html += tableData;
    return html;
  };

  const handleUploadExport = async (acceptedFiles) => {
    let reportCompilerState = false;

    if (settings && settings.staff && Object.keys(settings.staff.staff).length > 0
    ) {
      reportCompilerState = await buildReportCompilerState(settings);
    } else {
      console.warn("no staff! settings is not loaded");
      props.setIsEmailsLoaded(true);
      return;
    }
   
    setState(() => (
      <FileUploadStatus status={"done"} fileName={acceptedFiles[0].path} />
    ));
    // Get email of logged in user

    var ccEmail = "unset";
    try {
      let user = await currentUser;
      ccEmail = user.email;
      // email = user.attributes.email;
    } catch (e) {
      console.error("Failed getting AWS auth username: ", e);
    }
    // Set isEmailsLoaded to false
    props.setIsEmailsLoaded(false);
    let emailContent = await createStaffEmailArrays(
      acceptedFiles[0],
      reportCompilerState.studiosInformation.staffArray
    );
    emailContent.map(async (staff) => {
      let to = staff.address;
      let cc = ccEmail;
      let subject =
      reportCompilerState.inputFilesArrays.questions.customEmailSubjectLine
          .value;
      //let subject = "Pay Stub Summary";
      let rows = removeEmptyRows(staff.content.slice(6));
      let rowsFiltered = filterNestedArray(rows, "");
      let tablesArray = createSeperateTables(rowsFiltered, rows);
      let table = deleteExcessRows(staff.content.slice(6));
      let headers = removeEmptyRows(staff.content.slice(0, 6));
      var html = arrayToHTML(table, headers);

      let email = {
        headers: headers,
        tables: tablesArray,
        staffEmail: staff.address,
      };

      
      props.handleAddEmailCard(email);
      props.addEmail(html, to, cc, subject);
      props.setTableHeaders(headers);
      props.setTables(props.tables.concat(tablesArray));
      props.setTables(tablesArray);
    });
    // Set isEmailsLoaded to true
    props.setIsEmailsLoaded(true);
  };

  const onDrop = useCallback(async (acceptedFiles) => {
    props.setEmailCards([]);
    handleUploadExport(acceptedFiles);
  }, []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept:
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel",
    onDrop,
  });

  return (
    <Box className={`staff-emailer-dropzone`}>
      <Card
        className="dropzone__card"
        raised={true}
        sx={{
          p: 3,
          mt: 1,
          ml: 0,
          left: 0,
          mr: 1,
          mb: 1,
          borderRadius: 5,
          flex: 1,
          bgcolor: "background.paper",
        }}
      >
        <div {...getRootProps()}>
          <input {...getInputProps()} />
          {isDragActive ? (
            <Card
              className="dropzone__card-drop-area"
              raised={false}
              sx={{
                textAlign: "center",
                color: "#5f697a",
                borderRadius: 3,
                border: "2px solid #4d5efa",
                boxShadow: "none",
                backgroundColor: "#e8ecfe",
                minHeight: "20em",
                alignItems: "center",
                display: "flex",
                flexDirection: "column",
                p: 2,
                width: "100%",
                justifyContent: "center",
              }}
            >
              <FolderRoundedIcon
                fontSize="large"
                style={{ color: "#4d5efa" }}
              />
              <div style={{ maxWidth: "12em" }}>
                {props.type === "inputFile"
                  ? `Drop your PayWell input file here to upload`
                  : props.type === "addStaff"
                  ? `Drop your reports here to add staff members`
                  : props.studio === undefined
                  ? `Drop your PayWell output file here to upload`
                  : `Drop your ${props.studio} reports here to upload or replace`}
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <hr
                  style={{
                    width: "3.5em",
                    borderWidth: "2px",
                    borderRadius: 2,
                    backgroundColor: "#CBD2EA",
                  }}
                />
                <div
                  style={{
                    marginLeft: ".5em",
                    marginTop: ".18em",
                    marginRight: ".5em",
                    opacity: 0.5,
                  }}
                >
                  OR
                </div>
                <hr
                  style={{
                    width: "3.5em",
                    borderWidth: "2px",
                    borderRadius: 2,
                    backgroundColor: "#CBD2EA",
                  }}
                />
              </div>
              <Button variant="contained">Browse files</Button>
            </Card>
          ) : (
            <Card
              className="dropzone__card-drop-area"
              raised={false}
              sx={{
                textAlign: "center",
                color: "#5f697a",
                borderRadius: 3,
                border: "1px dashed #CBD2EA",
                boxShadow: "none",
                backgroundColor: "#f4f7fd",
                minHeight: "20em",
                alignItems: "center",
                display: "flex",
                flexDirection: "column",
                p: 2,
                width: "100%",
                justifyContent: "center",
              }}
            >
              <FolderRoundedIcon
                fontSize="large"
                style={{ color: "#4d5efa" }}
              />
              <div style={{ maxWidth: "12em" }}>
                {props.type === "inputFile"
                  ? `Drag your PayWell input file here to upload`
                  : props.type === "addStaff"
                  ? `Drag your reports here to add staff members`
                  : props.studio === undefined
                  ? `Drag your PayWell output file here to upload`
                  : `Drag your ${props.studio} reports here to upload or replace`}
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <hr
                  style={{
                    width: "3.5em",
                    borderWidth: "2px",
                    borderRadius: 2,
                    backgroundColor: "#CBD2EA",
                  }}
                />
                <div
                  style={{
                    marginLeft: ".5em",
                    marginTop: ".18em",
                    marginRight: ".5em",
                    opacity: 0.5,
                  }}
                >
                  OR
                </div>
                <hr
                  style={{
                    width: "3.5em",
                    borderWidth: "2px",
                    borderRadius: 2,
                    backgroundColor: "#CBD2EA",
                  }}
                />
              </div>
              <Button variant="contained">Browse files</Button>
            </Card>
          )}
        </div>
        <Box className="dropzone__card__content">{state}</Box>
      </Card>
    </Box>
  );
}
