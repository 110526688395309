import React, { useState } from 'react';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { db } from '../../../firebase';
import { getAllUsers } from '../OutputTest';
import { TextField, Box, Typography, Autocomplete, Grid, Paper, Card, CardContent } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useDropzone } from 'react-dropzone';
import * as XLSX from '@sheet/core';
import { createComparisonFile } from './utilityFunctionsUserMetrics';

const UserMetrics = () => {
  const [users, setUsers] = useState([]);
  const [userSettings, setUserSettings] = useState({});
  const [userStaffCounts, setUserStaffCounts] = useState({});
  const [loading, setLoading] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [totalStaff, setTotalStaff] = useState(0);
  const [stripeFile, setStripeFile] = useState(null);
  const [mondayFile, setMondayFile] = useState(null);

  // Array of emails to exclude
  const emailsToExclude = [
    'liam@paywell.solutions',
    'liamkbillington@gmail.com',
    'scott@paywell.solutions',
    'scott.smith0703@gmail.com',
    'leigh@leighwelchconsulting.com',
  ]; // Add the emails to exclude here

  const fetchUsers = async () => {
    setLoading(true);
    try {
      let users = await getAllUsers();
      users = users.users.filter(user => !user.disabled);
      users = users.filter(user => !emailsToExclude.includes(user.email));

      users.sort((a, b) => a.email.localeCompare(b.email));

      const userSettings = {};
      const userStaffCounts = {};

      // Get settings and staff count for each user
      for (const user of users) {
        const settingsQuery = query(collection(db, 'settings'), where('uid', '==', user.uid));
        const settingsSnapshot = await getDocs(settingsQuery);
        userSettings[user.uid] = settingsSnapshot.docs.map(doc => doc.data());

        const staffQuery = query(collection(db, 'staff'), where('paywellUID', '==', user.uid));
        const staffSnapshot = await getDocs(staffQuery);
        userStaffCounts[user.uid] = staffSnapshot.size;
      }

      setUsers(users.map(user => ({ ...user, settings: userSettings[user.uid] || [], staffCount: userStaffCounts[user.uid] || 0 })));
      setUserStaffCounts(userStaffCounts);
      // Calculate total number of staff
      const totalStaffCount = Object.values(userStaffCounts).reduce((acc, count) => acc + count, 0);
      setTotalStaff(totalStaffCount);

      setUserSettings(userSettings);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching users:', error);
      setLoading(false);
    }
  };

  const handleUserSelect = (event, value) => {
    setSelectedUser(value);
  };

  const handleFileDrop = (acceptedFiles, fileType) => {
    if (acceptedFiles.length !== 1) {
      alert(`Please upload exactly one ${fileType} file.`);
      return;
    }

    if (fileType === 'Stripe') {
      setStripeFile(acceptedFiles[0]);
    } else if (fileType === 'Monday') {
      setMondayFile(acceptedFiles[0]);
    }
  };

  const readExcelFile = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (event) => {
        try {
          const data = new Uint8Array(event.target.result);
          const workbook = XLSX.read(data, { type: 'array' });
          const firstSheet = workbook.Sheets[workbook.SheetNames[0]];
          const jsonData = XLSX.utils.sheet_to_json(firstSheet, { header: 1 });
  
          // Filter out rows with 4 or fewer columns
          const filteredData = jsonData.filter(row => row.length > 4);
  
          // Convert array of arrays to array of objects
          const keys = filteredData[0]; // First row as keys
          const result = filteredData.slice(1).map(row => {
            return keys.reduce((acc, key, index) => {
              acc[key] = row[index];
              return acc;
            }, {});
          });
          
          resolve(result);
        } catch (error) {
          console.error('Error processing file:', error);
          reject(error);
        }
      };
      reader.onerror = (error) => {
        console.error('Error reading file:', error);
        reject(error);
      };
      reader.readAsArrayBuffer(file);
    });
  };
  
  
  

  const compareFiles = async () => {
    try {
      const stripeData = await readExcelFile(stripeFile);
      const mondayData = await readExcelFile(mondayFile);

      // Create user array with data from both sources
      let data = createComparisonFile(users, userSettings, userStaffCounts, mondayData, stripeData);
      downloadExcel(data);
    } catch (error) {
      console.error('Error comparing files:', error);
    }
  };

  const downloadExcel = (data) => {
    // Create a new workbook
    const workbook = XLSX.utils.book_new();
  
    // Convert JSON data to worksheet
    const worksheet = XLSX.utils.json_to_sheet(data);
  
    // Applying colors and hiding columns using the !cols property
    worksheet['!cols'] = []; // Initialize array for column settings
  
    // Coloring and hiding specific columns
    worksheet['!cols'][0] = { hidden: true }; // Column A: hidden
    worksheet['!cols'][2] = { hidden: true }; // Column C: hidden
    worksheet['!cols'][3] = { hidden: true }; // Column D: hidden
    worksheet['!cols'][5] = { hidden: true }; // Column F: hidden
    worksheet['!cols'][6] = { hidden: true }; // Column G: hidden
    worksheet['!cols'][7] = { hidden: true }; // Column H: hidden
    worksheet['!cols'][9] = { hidden: true }; // Column J: hidden
    worksheet['!cols'][13] = { hidden: true }; // Column N: hidden
    worksheet['!cols'][14] = { hidden: true }; // Column O: hidden
    worksheet['!cols'][15] = { hidden: true }; // Column P: hidden
    worksheet['!cols'][16] = { hidden: true }; // Column Q: hidden
    worksheet['!cols'][18] = { hidden: true }; // Column S: hidden
    worksheet['!cols'][19] = { hidden: true }; // Column T: hidden

    worksheet['!autofilter'] = { ref: "A1:Y1" };
  
    // Append the worksheet to the workbook
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
  
    // Generate Excel file and trigger download
    XLSX.writeFile(workbook, 'data.xlsx');
  };
  

  // const downloadExcel = (data) => {
  //   // Create a new workbook
  //   const workbook = XLSX.utils.book_new();
  
  //   // Convert JSON data to worksheet
  //   const worksheet = XLSX.utils.json_to_sheet(data);
  
  //   // Append the worksheet to the workbook
  //   XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
  
  //   // Generate Excel file and trigger download
  //   XLSX.writeFile(workbook, 'data.xlsx');
  // };

  const { getRootProps: getStripeRootProps, getInputProps: getStripeInputProps } = useDropzone({
    onDrop: (acceptedFiles) => handleFileDrop(acceptedFiles, 'Stripe')
  });

  const { getRootProps: getMondayRootProps, getInputProps: getMondayInputProps } = useDropzone({
    onDrop: (acceptedFiles) => handleFileDrop(acceptedFiles, 'Monday')
  });

  const totalStudios = users.reduce((acc, user) => {
    const userStudios = user.settings.reduce((innerAcc, setting) => {
      if (setting.generalSettings && setting.generalSettings.studios) {
        return innerAcc + setting.generalSettings.studios.length;
      }
      return innerAcc;
    }, 0);
    return acc + userStudios;
  }, 0);

  const activeUsers = users.filter(user => user.disabled === false).length;

  return (
    <Box p={3}>
      <Typography variant="h4" gutterBottom>User Metrics Dashboard</Typography>
      <Grid container spacing={3} mb={3}>
        <Grid item xs={12} md={3}>
          <Card>
            <CardContent>
              <Typography variant="h6">Total Users</Typography>
              <Typography variant="h4">{users.length}</Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={3}>
          <Card>
            <CardContent>
              <Typography variant="h6">Total Studios</Typography>
              <Typography variant="h4">{totalStudios}</Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={3}>
          <Card>
            <CardContent>
              <Typography variant="h6">Active Users</Typography>
              <Typography variant="h4">{activeUsers}</Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={3}>
          <Card>
            <CardContent>
              <Typography variant="h6">Total Staff</Typography>
              <Typography variant="h4">{totalStaff}</Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <Box mb={3}>
        <LoadingButton loading={loading} variant="contained" color="primary" onClick={fetchUsers}>
          Refresh Data
        </LoadingButton>
      </Box>
      <Autocomplete
        options={users}
        getOptionLabel={(user) => user.email}
        onChange={handleUserSelect}
        renderInput={(params) => <TextField {...params} label="Search User by Email" variant="outlined" fullWidth />}
      />
      {selectedUser && (
        <Box mt={4} component={Paper} p={3}>
          <Typography variant="h5" gutterBottom>User Details</Typography>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="body1"><strong>UID:</strong> {selectedUser.uid}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1"><strong>Email:</strong> {selectedUser.email}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1"><strong># of Settings:</strong> {selectedUser.settings.length}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1"><strong># of Studios:</strong> {selectedUser.settings.reduce((acc, setting) => {
                if (setting.generalSettings && setting.generalSettings.studios) {
                  return acc + setting.generalSettings.studios.length;
                }
                return acc;
              }, 0)}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1"><strong># of Staff:</strong> {selectedUser.staffCount}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1"><strong>Studios:</strong></Typography>
              <ul>
                {selectedUser.settings.map((setting, index) =>
                  setting.generalSettings && setting.generalSettings.studios
                    ? setting.generalSettings.studios.map((studio, studioIndex) => (
                        <li key={`${index}-${studioIndex}`}>{studio}</li>
                      ))
                    : null
                )}
              </ul>
            </Grid>
          </Grid>
        </Box>
      )}
      {users.length > 0 && (
        <Box mt={4} p={3} component={Paper}>
          <Typography variant="h5" gutterBottom>Upload Files for Comparison</Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Box {...getStripeRootProps({ className: 'dropzone' })} p={2} border="2px dashed #cccccc" textAlign="center">
                <input {...getStripeInputProps()} />
                <Typography>Drag 'n' drop the Stripe file here, or click to select file</Typography>
              </Box>
              {stripeFile && <Typography mt={2}>Stripe File: {stripeFile.name}</Typography>}
            </Grid>
            <Grid item xs={12} md={6}>
              <Box {...getMondayRootProps({ className: 'dropzone' })} p={2} border="2px dashed #cccccc" textAlign="center">
                <input {...getMondayInputProps()} />
                <Typography>Drag 'n' drop the Monday file here, or click to select file</Typography>
              </Box>
              {mondayFile && <Typography mt={2}>Monday File: {mondayFile.name}</Typography>}
            </Grid>
          </Grid>
          {stripeFile && mondayFile && (
            <Box mt={3}>
              <LoadingButton variant="contained" color="primary" onClick={compareFiles}>
                Compare Files
              </LoadingButton>
              {/* {comparisonResult && (
                <Box mt={2}>
                  <Typography variant="h6">Comparison Result:</Typography>
                  <Typography>{comparisonResult}</Typography>
                </Box>
              )} */}
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
};

export default UserMetrics;
