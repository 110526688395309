
export function createDateFromString(dateString, timeString) {
    if(checkDateString(dateString)){
      dateString = dateString.replace(/\s+/g, ' ').trim();  // Remove excess white space

      let dateArr = [];
      if(dateString.includes("/")){
        dateArr = dateString.split("/");
      }else if (dateString.includes(".")){
        dateArr = dateString.split(".");
      }
  
      if (timeString === "0" || timeString === null || timeString === "" || timeString === undefined || !timeString.includes(":")) {
        timeString = "00:00";
      }
      
      let hourConv = parseInt(timeString.substring(0, timeString.indexOf(":")));
      if (timeString.includes("PM") && hourConv !== 12) {
        hourConv += 12;
      }
      const minConv = parseInt(
        timeString.substring(
          timeString.indexOf(":") + 1,
          timeString.indexOf(":") + 4
        )
      );
      const date = new Date(
        dateArr[2],
        dateArr[0] - 1,
        dateArr[1],
        hourConv,
        minConv
      );
      return date;
    }
    return new Date(0);
  }


  export function checkDateString(dateString){
    if(typeof dateString !== 'string'){
      return false;
    }

    if (dateString === "0" || dateString === "") {
      return false;
    }
    let dateArray = [];
    if( dateString.includes("/")){
      dateArray = dateString.split("/");
    }else if( dateString.includes(".")){
      dateArray = dateString.split(".");
    }else{
      return false;
    }

    if(dateArray.length !== 3){
      return false;
    }

    if(dateArray[0].length === 0 || dateArray[0].length >= 3){
      return false;
    }

    if(dateArray[1].length === 0 || dateArray[1].length >= 3){
      return false;
    }

    if(dateArray[2].length !== 2 && dateArray[2].length !== 4){
      return false;
    }
    return true;
  }