import { db } from "../firebase";

import { collection, addDoc } from "firebase/firestore";

export async function uploadUserMetrics(
  currentUser,
  uid,
  selectedDates,
  currentlySelectedStudios
) {
  var now = new Date();
  var dateString =
    (now.getMonth() + 1).toString().padStart(2, "0") +
    "-" +
    now
      .getDate()
      .toString()
      .padStart(2, "0") +
    "-" +
    now.getFullYear();
  var timeString =
    now
      .getHours()
      .toString()
      .padStart(2, "0") +
    ":" +
    now
      .getMinutes()
      .toString()
      .padStart(2, "0") +
    ":" +
    now
      .getSeconds()
      .toString()
      .padStart(2, "0");
  var dateTimeString = dateString + " " + timeString;
  let data = {
    user: currentUser.email,
    uid: uid,
    payPeriod: selectedDates.slice(0, 2),
    studios: currentlySelectedStudios,
    time: dateTimeString,
  };
  await addDoc(collection(db, "usage-metrics"), data);
}
