import * as React from "react";
import Stack from "@mui/material/Stack";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { Button } from "@mui/material";
import { useAuth } from "../AuthContext";
// 1. Define the AdminImpersonationBannerContext and its associated provider
const AdminImpersonationBannerContext = React.createContext();

export function useAdminImpersonationBanner() {
    return React.useContext(AdminImpersonationBannerContext);
}

export function AdminImpersonationBannerProvider({ children }) {
    const {logout} = useAuth();
    const [impersonatingState, setImpersonatingState] = React.useState({
        open: false,
        message: "",
        severity: "success",
    });

    const showAdminImpersonationBanner = (message) => {
        setImpersonatingState({ open: true, message });
    };

    const hideAdminImpersonationBanner = () => {
        setImpersonatingState((prev) => ({ ...prev, open: false }));
    };


    const handleStopImpersonation = async () => {
        setImpersonatingState((prev) => ({ ...prev, open: false }));
        await logout();
      };



    const value = {
        showAdminImpersonationBanner, hideAdminImpersonationBanner, impersonatingState
    };
    return (
        <AdminImpersonationBannerContext.Provider value={value}>
            {children}

            <Stack position={"absolute"} spacing={2} sx={{ width: "100%" }}>
                <Snackbar
                    id="snackbar"
                    data-severity={"error"}
                    open={impersonatingState.open}
                    anchorOrigin={{ vertical: "top", horizontal: "center" }}
                    

                >
                    <Alert severity={"error"} sx={{ width: "100%"}}>
                        {impersonatingState.message}
                        <Button sx={{marginLeft: "12px"}} onClick={handleStopImpersonation} variant="outlined" color="inherit">
                            Stop Impersonation
                        </Button>
                    </Alert>
                </Snackbar>
            </Stack>



        </AdminImpersonationBannerContext.Provider>
    );
}

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});


