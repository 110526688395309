import React, { useState } from 'react';
import { Modal, Box, Typography, IconButton, Button, TextField, MenuItem, Select, FormControl, InputLabel } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import SaveIcon from '@mui/icons-material/Save';
import CloseIcon from '@mui/icons-material/Close';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};

const RateOverrideManager = ({ rateOverrides, setRateOverrides, distinctRates }) => {
  const [open, setOpen] = useState(false);
  const [editRowsModel, setEditRowsModel] = useState({});
  const [newRateType, setNewRateType] = useState('');
  const [newOverrideName, setNewOverrideName] = useState('');

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const processRowUpdate = (newRow) => {
    const updatedOverrides = { ...rateOverrides, [newRow.id]: newRow.name };
    setRateOverrides(updatedOverrides);
    return newRow;
  };

  const deleteOverride = (id) => {
    const updatedOverrides = { ...rateOverrides };
    delete updatedOverrides[id];
    setRateOverrides(updatedOverrides);
  };

  const handleAddOverride = () => {
    if (distinctRates.includes(newRateType)) {
      const updatedOverrides = { ...rateOverrides, [newRateType]: newOverrideName };
      setRateOverrides(updatedOverrides);
      setNewRateType('');
      setNewOverrideName('');
    }
  };

  const filteredDistinctRates = distinctRates.filter(rate => !rateOverrides.hasOwnProperty(rate));

  const columns = [
    { field: 'id', headerName: 'Rate Type', width: 150 },
    { field: 'name', headerName: 'Override Name', width: 200, editable: true },
    {
      field: 'actions',
      type: 'actions',
      width: 100,
      getActions: (params) => [
        <IconButton
          color="error" // Change the color to red
          onClick={() => deleteOverride(params.id)}
          size="large"
        >
          <DeleteIcon />
        </IconButton>
      ],
    },
  ];

  const rows = Object.keys(rateOverrides).map(key => ({
    id: key,
    name: rateOverrides[key]
  }));

  return (
    <div>
      <Button onClick={handleOpen} startIcon={<EditIcon />}>
        Edit Rate Overrides
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Rate Name Overrides
          </Typography>
          <Box sx={{ height: 300, width: '100%', marginBottom: 2 }}>
            <DataGrid
              rows={rows}
              columns={columns}
              processRowUpdate={processRowUpdate}
              editRowsModel={editRowsModel}
              onEditRowsModelChange={setEditRowsModel}
              autoHeight
              pageSize={5}
              rowsPerPageOptions={[5]}
            />
          </Box>
          <Box display="flex" alignItems="center" gap={2} marginBottom={2}>
            <FormControl fullWidth>
              <InputLabel>Rate Type</InputLabel>
              <Select
                value={newRateType}
                onChange={(e) => setNewRateType(e.target.value)}
                label="Rate Type"
              >
                {filteredDistinctRates.map((rate) => (
                  <MenuItem key={rate} value={rate}>
                    {rate}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <TextField
              label="Override Name"
              value={newOverrideName}
              onChange={(e) => setNewOverrideName(e.target.value)}
              fullWidth
            />
            <Button variant="contained" onClick={handleAddOverride} startIcon={<SaveIcon />}>
              Add
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

export default RateOverrideManager;
