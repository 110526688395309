import React, { useState } from "react";
import { TextField, Button } from "@mui/material";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import CustomizedSnackbars from "../Snackbar";
import { useAuth } from "../../contexts/AuthContext";
import { Link, useHistory } from "react-router-dom";
import image from "../../img/PayWellLogoBlack.png";
import { useSnackBar } from "../../contexts/SnackBarContext/SnackBarContext";
const defaultValues = {
  email: "",
  password: "",
};
const Login = () => {
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);
  const { login, currentUser } = useAuth();
  const showSnackBar = useSnackBar();
  const history = useHistory();

  const [formValues, setFormValues] = useState(defaultValues);
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };


  
  const handleSubmit = async (event) => {
    let res;
    try {
      setError("");
      setLoading(true);
      res = await login(formValues.email, formValues.password);
      if (res.email) {
        showSnackBar(`Welcome back ${res.email}!`, "success");
        setTimeout(() => history.push("/dashboard"), 1500);
      } else {
        showSnackBar(res.message, "error");
      }
    } catch (e) {
      console.error(e);
      showSnackBar(e.message, "error");
    }
    setLoading(false);
  };




  return (
    <Box
      className="auth"
      style={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        alignItems: "center",
        padding: "65px 20px",
      }}
    >
      {/* Desktop PayWell Login */}
      <a className="auth__paywell" href="/">
        <Typography
          style={{
            textAlign: "center",
            color: "#494949",
            marginBottom: "30px",
          }}
          variant="h6"
          noWrap
          component="div"
        >
          <img src={image} alt="PayWell Logo" style={{ height: "1.25em" }} />
        </Typography>
        <Typography
          style={{
            textAlign: "center",
            color: "#494949",
            marginBottom: "30px",
          }}
          variant="h6"
          noWrap
          component="div"
        >
          Sign in to your PayWell account.
        </Typography>
      </a>

      <form className="auth__form" onSubmit={handleSubmit}>
        <Card
          className="auth__card"
          style={{
            background: "rgb(255, 255, 255)",
            border: "1px solid rgb(230, 230, 230)",
            boxSizing: "border-box",
            boxShadow: "rgb(45 40 77 / 10%) 0px 12px 32px",
            borderRadius: "8px",
            minWidth: "275px",
            width: "100vw",
            maxWidth: "550px",
          }}
        >
          <CardContent
            className="auth__card-content"
            style={{
              display: "flex",
              flexDirection: "column",
              margin: "1em",

              marginLeft: "1em",
            }}
          >
            <Box
              className="auth__box  auth__box--align-start"
              style={{
                display: "flex",
                width: "100%",
                flexDirection: "column",
                alignItems: "flex-start",
              }}
            >
              <Box
                className="auth__box  auth__box--align-start"
                style={{
                  display: "flex",
                  width: "100%",
                  flexDirection: "column",
                  alignItems: "flex-start",
                }}
              >
                <TextField
                  className="auth__text-field"
                  fullWidth
                  name="email"
                  label="Email"
                  type="text"
                  value={formValues.email}
                  onChange={handleInputChange}
                  autoComplete="username"
                   placeholder={"Enter your email"}
                   InputLabelProps={{ shrink: true }}

                />
              </Box>
              <Box
                className="auth__box  auth__box--align-start"
                style={{
                  marginTop: "1em",
                  display: "flex",
                  width: "100%",
                  flexDirection: "column",
                  alignItems: "flex-start",
                }}
              >
                <TextField
                  className="auth__text-field"
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  value={formValues.password}
                  onChange={handleInputChange}
                  autoComplete="current-password"
                  placeholder="Enter your password"
                  InputLabelProps={{ shrink: true }}

                />
              </Box>
            </Box>

            <Button
              className="auth__button"
              disabled={loading}
              type="submit"
              style={{
                position: "relative",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                marginTop: "2em",
                backgroundColor: "#006bff",
                minHeight: "66px",
                borderRadius: "40px",
              }}
              onClick={handleSubmit}
              variant="contained"
            >
              Sign In
            </Button>
            <Link
              style={{ textAlign: "center", marginTop: "1em" }}
              to="/forgot-password"
            >
              Forgot Password?
            </Link>
          </CardContent>
        </Card>
        <Box
          className="auth__box"
          style={{ textAlign: "center", marginTop: "1em" }}
        >
          Don't have an account? <Link to="/signup">Sign Up</Link>
        </Box>
      </form>
    </Box>
  );
};
export default Login;
