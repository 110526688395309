import React, { useContext, useReducer } from "react";
import {
  serializeObjectWithMethods,
  deserializeObjectWithMethods,
  updateObject,
} from "../utility-functions/utility-functions";
import { useSettingsContext } from "./SettingsContext";

/**** HELPER FUNCTIONS ****/
/**** REDUCER ****/
const deserializedEmailCreationContextReducer = (state, action) => {
  const serializedState = serializeObjectWithMethods(state);
  const serializedAction = serializeObjectWithMethods(action);
  const newSerializedState = emailCreationContextReducer(
    serializedState,
    serializedAction
  );

  const deserializedState = deserializeObjectWithMethods(newSerializedState);
  return deserializedState;
};
const emailCreationContextReducer = (state, action) => {
  let newState = JSON.parse(JSON.stringify(state));

  switch (action.type) {
    //  emailCreationContextDispatch({
    //   type: "UPDATE_EMAIL_CREATION_CONTEXT_STATE_PROPERTY",
    //   path: `property.property.property`,
    //   value: value,
    //   replace: true/false
    // });
    case "UPDATE_EMAIL_CREATION_CONTEXT_STATE_PROPERTY":
      updateObject(
        newState,
        action.path,
        action.value,
        action.hasOwnProperty("replace") ? action.replace : true
      );
      return newState;

    default:
      return state;
  }
};

/**** CONTEXT ****/
const EmailCreationContext = React.createContext();
export function useEmailCreationContext() {
  return useContext(EmailCreationContext);
}
export function EmailCreationContextProvider({ children }) {
  const initialEmailCreationContextState = {};
  const [emailCreationContextState, emailCreationContextDispatch] = useReducer(
    deserializedEmailCreationContextReducer,
    initialEmailCreationContextState
  );
  const { reportCompilerState } = useSettingsContext();

  // Context values to pass down
  const value = {
    emailCreationContextState,
    emailCreationContextDispatch,
  };

  return (
    <EmailCreationContext.Provider value={value}>
      {children}
    </EmailCreationContext.Provider>
  );
}
