import React, { useContext, useReducer } from "react";
import {
  serializeObjectWithMethods,
  deserializeObjectWithMethods,
  updateObject,
} from "../utility-functions/utility-functions.js";

/**** HELPER FUNCTIONS ****/
/**** REDUCER ****/
const deserializedXLSXConverterContextReducer = (state, action) => {
  const serializedState = serializeObjectWithMethods(state);
  const serializedAction = serializeObjectWithMethods(action);
  const newSerializedState = XLSXConverterContextReducer(
    serializedState,
    serializedAction
  );

  const deserializedState = deserializeObjectWithMethods(newSerializedState);
  return deserializedState;
};
const XLSXConverterContextReducer = (state, action) => {
  let newState = JSON.parse(JSON.stringify(state));

  switch (action.type) {
    //  XLSXConverterContextDispatch({
    //   type: "UPDATE_XLSX_CONVERTER_CONTEXT_STATE_PROPERTY",
    //   path: `property.property.property`,
    //   value: value,
    //   replace: true/false
    // });
    case "UPDATE_XLSX_CONVERTER_CONTEXT_STATE_PROPERTY":
      updateObject(
        newState,
        action.path,
        action.value,
        action.hasOwnProperty("replace") ? action.replace : true
      );
      return newState;

    default:
      return state;
  }
};

/**** CONTEXT ****/
const XLSXConverterContext = React.createContext();
export function useXLSXConverterContext() {
  return useContext(XLSXConverterContext);
}
export function XLSXConverterContextProvider({ children }) {
  const initialXLSXConverterContextState = {};
  const [XLSXConverterContextState, XLSXConverterContextDispatch] = useReducer(
    deserializedXLSXConverterContextReducer,
    initialXLSXConverterContextState
  );

  const value = {
    XLSXConverterContextState,
    XLSXConverterContextDispatch,
  };

  return (
    <XLSXConverterContext.Provider value={value}>
      {children}
    </XLSXConverterContext.Provider>
  );
}
