import { TimeEvent } from "../ReportCompiler/classes/TimeEvent.js";
import { findHourlyPayRate } from "../utility-functions.js";
import { ReportData } from "../utility-functions.js";

export function gustoFileConverter(json, reportCompilerState) {
  const timeArray = [];
  var staffInFile = [];
  let nameI = 0;
  let regularI = 0;
  let overtimeI = 0;
  let doubleOvertimeI = 0;
  let timeOffI = 0;

  let jobOneNameI = 0;
  let jobOneAmountI = 0;
  let jobTwoNameI = 0;
  let jobTwoAmountI = 0;
  let jobThreeNameI = 0;
  let jobThreeAmountI = 0;
  let jobFourNameI = 0;
  let jobFourAmountI = 0;

  let correctFileType = false;
  for (let i = 0; i < json.length; i++) {
    if (isGustoHeaderRow(json[i])) {
      for (let u = 0; u < json[i].length; u++) {
        if (json[i][u].includes("Name")) {
          nameI = u;
        } else if (json[i][u].includes("Regular hours")) {
          regularI = u;
        } else if (json[i][u].includes("Overtime") ) {
          overtimeI = u;
        } else if (json[i][u].includes("Double overtime")) {
          doubleOvertimeI = u;
        } else if (json[i][u].includes("Time off")) {
            timeOffI = u;
        } else if (json[i][u].includes("Job 1") && !json[i][u].includes("total")) {
            jobOneNameI = u;
        } else if (json[i][u].includes("Job 1 total")) {
            jobOneAmountI = u;
        } else if (json[i][u].includes("Job 2") && !json[i][u].includes("total")) {
            jobTwoNameI = u;
        } else if (json[i][u].includes("Job 2 total")) {
            jobTwoAmountI = u;
        }  else if (json[i][u].includes("Job 3") && !json[i][u].includes("total")) {
            jobThreeNameI = u;
        } else if (json[i][u].includes("Job 3 total")) {
            jobThreeAmountI = u;
        }  else if (json[i][u].includes("Job 4") && !json[i][u].includes("total")) {
            jobFourNameI = u;
        } else if (json[i][u].includes("Job 4 total")) {
            jobFourAmountI = u;
        }
      }
      correctFileType = true;
    } else if (isGustoDataRow(json[i])) {
      //let location = "Club Pilates Blue Oaks";
      let location = reportCompilerState.payrollInformation.studiosInInput[0]; //payrollInformation.studiosInInput

      let name = json[i][nameI];

      let hourRate = findHourlyPayRate(name, "All", reportCompilerState.studiosInformation); // needs inputfilesarray

      if(parseFloat(json[i][regularI]) !== 0){

        const newStaff = {
          name: name,
          type: "time",
          location: location,
        };
        
        let isDuplicate = staffInFile.some(staff => 
          staff.name === newStaff.name && staff.type === newStaff.type && staff.location === newStaff.location
        );
        
        if (!isDuplicate) {
          staffInFile.push(newStaff);
        }

        //staffInFile.push({"name": name, "type": "time", "location": location});
      }

        if(!isNaN(parseFloat(json[i][jobOneAmountI])) && json[i][jobOneNameI].length > 0){
            let timeEvent = createTimeEvent(name, location, parseFloat(json[i][jobOneAmountI]), hourRate, "Regular", json[i][jobOneNameI]);
            timeArray.push(timeEvent);
        }
        if(!isNaN(parseFloat(json[i][jobTwoAmountI])) && json[i][jobTwoNameI].length > 0){
            let timeEvent = createTimeEvent(name, location, parseFloat(json[i][jobTwoAmountI]), hourRate, "Regular", json[i][jobTwoNameI]);
            timeArray.push(timeEvent);
        }
        if(!isNaN(parseFloat(json[i][jobThreeAmountI])) && json[i][jobThreeNameI].length > 0){
            let timeEvent = createTimeEvent(name, location, parseFloat(json[i][jobThreeAmountI]), hourRate, "Regular", json[i][jobThreeNameI]);
            timeArray.push(timeEvent);
        }
        if(!isNaN(parseFloat(json[i][jobFourAmountI])) && json[i][jobFourNameI].length > 0){
            let timeEvent = createTimeEvent(name, location, parseFloat(json[i][jobFourAmountI]), hourRate, "Regular", json[i][jobFourNameI]);
            timeArray.push(timeEvent);
        }

      if (parseFloat(json[i][overtimeI]) !== 0) {
        let timeEvent = createTimeEvent(name, location, parseFloat(json[i][overtimeI]), hourRate * 1.5, "Overtime", json[i][jobOneNameI]);
            timeArray.push(timeEvent);
      }

      if (parseFloat(json[i][doubleOvertimeI]) !== 0) {
        let timeEvent = createTimeEvent(name, location, parseFloat(json[i][doubleOvertimeI]), hourRate * 2, "Double Overtime", json[i][jobOneNameI]);
            timeArray.push(timeEvent);
      }

      if (parseFloat(json[i][timeOffI]) !== 0) {
        let timeEvent = createTimeEvent(name, location, parseFloat(json[i][timeOffI]), hourRate , "Time Off", "");
            timeArray.push(timeEvent);
      }
    }

    if(json[i][0].includes("Hours for ")){
      let reportData = new ReportData(correctFileType, timeArray);
      reportData.staffInFile = staffInFile;

      return reportData;
        //return {correctFile: correctFileType, timeArray: timeArray};
    }
  }

  let reportData = new ReportData(correctFileType, timeArray);
  reportData.staffInFile = staffInFile;

      return reportData;
}

function isGustoHeaderRow(row){
  return (
    row[0].includes("Name") ||
    row[1].includes("Manager") ||
    row[2].includes("Total hours")
  );
}

function isGustoDataRow(row){
  return (row[0] !== null && row[0] !== "" && row[3].length > 0);
}

function createTimeEvent(name, location, hoursStr, hourRate, type, position){
  let hours = parseFloat(hoursStr);
  return new TimeEvent(
    name,
    location,
    hours,
    hourRate,
    type + " " + position,
    hours * hourRate
  );
}