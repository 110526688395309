import React from "react";
import { createRoot } from "react-dom/client";

import App from "./components/App";
import "bootstrap/dist/css/bootstrap.min.css";
import { CookiesProvider } from "react-cookie";
import ReactGA from "react-ga";
import { LicenseInfo } from "@mui/x-license-pro";
import { AuthProvider } from "./contexts/AuthContext";
import "./index.css";
LicenseInfo.setLicenseKey(
  "c96033ec2c9df06525b298b06027fb37T1JERVI6NDIxNDgsRVhQSVJZPTE2ODIwMjcyMzUwMDAsS0VZVkVSU0lPTj0x"
);
const TRACKING_ID = "G-WDJW5VFQRG"; // YOUR_OWN_TRACKING_ID
ReactGA.initialize(TRACKING_ID);
ReactGA.pageview(window.location.pathname + window.location.search);
const container = document.getElementById("root");
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(
  // <React.StrictMode>
    <AuthProvider>
      <CookiesProvider>
        <App />
      </CookiesProvider>
    </AuthProvider>
  // </React.StrictMode>
);
