import { db } from "../firebase";

import {
  collection,
  getDocs,
  getDoc,
  setDoc,
  deleteDoc,
  doc,
} from "firebase/firestore";

export async function updateStaffMasterSettings(
  paywellUID,
  updatedStaffRowIds
) {


  if (typeof paywellUID !== "string") {
    throw new Error("Invalid argument: paywellUID must be a string.");
  }

  if (typeof updatedStaffRowIds !== "object") {
    throw new Error("Invalid argument: updatedStaffRowIds must be an object.");
  }

  const collectionPath =
    process.env.REACT_APP_STAFF_SETTINGS_FIREBASE_COLLECTION;

  const staffRowIds = Object.keys(updatedStaffRowIds);

  try {
    for (let i = 0; i < staffRowIds.length; i++) {
      const staffDocId = staffRowIds[i];
      let staffDoc = updatedStaffRowIds[staffDocId];

      if (!staffDoc || typeof staffDoc.paywellUID !== "string") {
        continue;
      }

      if (staffDoc.paywellUID === paywellUID && staffDoc.updated) {
        
        staffDoc.updated = false; // Set updated to false
        const docRef = doc(db, collectionPath, staffDocId);

        const docSnapshot = await getDoc(docRef);

        if (docSnapshot.exists()) {
          if (staffDoc.status === "delete") {
            await deleteDoc(docRef);
          } else {
            staffDoc = { ...staffDoc, status: "" }; // Set status to an empty string
            await setDoc(docRef, staffDoc, { merge: true });
         
          }
        } else {
          staffDoc = { ...staffDoc, status: "" }; // Set status to an empty string
          await setDoc(docRef, staffDoc);
        }
      }
    }
  } catch (error) {
    console.error("Failed to update staff:", error);
    return false;
    // You can choose to throw the error here or handle it as needed
  }

  return true;
}

export async function deleteStaffSettings(id, settingsId) {
  let res;
  const querySnapshot = await getDocs(
    collection(db, process.env.REACT_APP_STAFF_SETTINGS_FIREBASE_COLLECTION)
  );
  querySnapshot.forEach(async (doc) => {
    let data = doc.data();
    if (data["uid"] === id && data["settingsId"] === parseInt(settingsId)) {
      res = await deleteDoc(doc.ref);
    }
  });
  return res;
}
