import React, { useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  DialogActions,
  DialogContentText,
} from "@mui/material";
import AddClassComponent from "./AddClassComponent";
const AddClassDialog = ({ open, onClose, onAdd, className }) => {
  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle component={"h3"}>Add Class Name</DialogTitle>
      <DialogContent>
        {/* <DialogContentText>
          What type of class is CP Control 1.5 (50 Mins) class?
        </DialogContentText> */}
        <AddClassComponent
          className={className}
          onClose={onClose}
          onAdd={onAdd}
        />
      </DialogContent>
    </Dialog>
  );
};

export default AddClassDialog;
