import { TimeEvent } from "../ReportCompiler/classes/TimeEvent.js";
import { findHourlyPayRate } from "../utility-functions.js";
import { ReportData } from "../utility-functions.js";

export function readProsprFile(json, settingsPresetStudios, studiosInformation) {
  const timeArray = [];
  let staffInFile = [];
  let firstNameI = 0;
  let lastNameI = 0;
  let locationI = 0;
  let dateI = 0;
  let timeInI = 0;
  let timeOutI = 0;
  let hoursI = 0;
  let overtimeI = 0;

  let correctFileType = false;
  for (let i = 0; i < json.length; i++) {
    if (isProsprHeaderRow(json[i])) {
      for (let u = 0; u < json[i].length; u++) {
        if (json[i][u].includes("First Name")) {
          firstNameI = u;
        } else if (json[i][u].includes("Last Name")) {
          lastNameI = u;
        } else if (json[i][u].includes("Location")) {
          locationI = u;
        } else if (json[i][u].includes("Date")) {
          dateI = u;
        } else if (json[i][u].includes("Actual Start")) {
          timeInI = u;
        } else if (json[i][u].includes("Actual End")) {
          timeOutI = u;
        } else if (json[i][u].includes("RT (hrs)")) {
          hoursI = u;
        } else if (json[i][u].includes("OT (hrs)")) {
          overtimeI = u;
        }
      }
      correctFileType = true;
    } else if (isProsprDataRow(json[i])) {
      let hours = json[i][hoursI];
      let staff = json[i][firstNameI] + " " + json[i][lastNameI];
      let location = findProsprLocation(
        json[i][locationI],
        settingsPresetStudios
      );
      let hourRate = findHourlyPayRate(staff, "All", studiosInformation);

      let description = json[i][dateI] + " Regular";

      if (hours !== 0) {
        const newStaff = {
          name: staff,
          type: "time",
          location: location,
        };
        
        let isDuplicate = staffInFile.some(staff => 
          staff.name === newStaff.name && staff.type === newStaff.type && staff.location === newStaff.location
        );
        
        if (!isDuplicate) {
          staffInFile.push(newStaff);
        }
        // staffInFile.push({
        //   name: staff,
        //   type: "time",
        //   location: location,
        // });
      }

      let timeEvent = createProsprTimeEvent(
        staff,
        location,
        hours,
        hourRate,
        description
      );
      timeEvent.detail = true;
      timeArray.push(timeEvent);

      let overtime = parseFloat(json[i][overtimeI]);
      if (overtime !== 0) {
        let otDescription = json[i][dateI] + " Overtime";
        let overtimeEvent = createProsprTimeEvent(
          staff,
          location,
          overtime,
          hourRate * 1.5,
          otDescription
        );
        overtimeEvent.detail = true;
        timeArray.push(overtimeEvent);
      }
      //}
    }
  }

  let reportData = new ReportData(correctFileType, timeArray);
  reportData.staffInFile = staffInFile;

  return reportData;
}

function isProsprHeaderRow(row) {
  return (
    row[0].includes("First Name") ||
    row[1].includes("Last Name") ||
    row[2].includes("Date")
  );
}

function isProsprDataRow(row) {
  return row[0] !== null && row[0] !== "" && row[0] !== "TOTAL";
}

function findProsprLocation(locationStr, settingsPresetStudios) {
  // let studios = reportCompilerState.payrollInformation.studiosInInput;
  let studios = settingsPresetStudios;
  let location = closestMatch(locationStr, studios);

  return location;
}

function closestMatch(str, arr) {
  let minDistance = Infinity;
  let closestStr = "";

  for (let i = 0; i < arr.length; i++) {
    const distance = levenshteinDistance(str, arr[i]);

    if (distance < minDistance) {
      minDistance = distance;
      closestStr = arr[i];
    }
  }

  return closestStr;
}

function levenshteinDistance(s, t) {
  const m = s.length;
  const n = t.length;
  const d = [];

  for (let i = 0; i <= m; i++) {
    d[i] = [i];
  }

  for (let j = 0; j <= n; j++) {
    d[0][j] = j;
  }

  for (let j = 1; j <= n; j++) {
    for (let i = 1; i <= m; i++) {
      if (s[i - 1] === t[j - 1]) {
        d[i][j] = d[i - 1][j - 1];
      } else {
        d[i][j] = Math.min(
          d[i - 1][j] + 1, // deletion
          d[i][j - 1] + 1, // insertion
          d[i - 1][j - 1] + 1 // substitution
        );
      }
    }
  }

  return d[m][n];
}

function createProsprTimeEvent(name, location, hoursStr, hourRate, type) {
  let hours = parseFloat(hoursStr);
  return new TimeEvent(name, location, hours, hourRate, type, hours * hourRate);
}
