import * as React from "react";
import { useStepperContext } from "./stepperContext";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import LoadingButton from "@mui/lab/LoadingButton";
import "../styles.scss";
import "./linearStepper.scss";
import FileDownloadRoundedIcon from "@mui/icons-material/FileDownloadRounded";
import { useSettingsContext } from "../../contexts/SettingsContext";
import { useStudioScrapeStatus } from "../../contexts/StudioScrapeStatus/StudioScrapeStatusContext";
import { asyncHandleDownload } from "../../contexts/Middleware/AsyncMiddleware";
export default function LinearStepper(props) {
  const { reportCompilerState, reportCompilerDispatch, settings, handleDownloadAsync } = useSettingsContext();
  const {
    selectedDates,
  } = useStudioScrapeStatus();
  const {
    stepperState,
    handleNextStep,
    handlePrevStep,
  } = useStepperContext();

  const currentStep = stepperState.steps[stepperState.activeStep];

  function LabelIcon(props) {
    return <Box className="stepper__label-icon">{props.children}</Box>;
  }

  return (
    <Box className="stepper">
      <div className="section-container">
        <Typography className="stepper__h3" variant="h2">
          {currentStep.title}
        </Typography>
        <Typography className="stepper__subtitle" variant="h6">
          {currentStep.description}
        </Typography>
        <Box
          className={`stepper__content stepper__content--${stepperState.activeStep}`}
        >
          {currentStep.content}
        </Box>
      </div>

      <Box className="stepper__actions">
        <Stepper
          variant="dots"
          className="stepper__header"
          activeStep={stepperState.activeStep}
          orientation="horizontal"
        >
          {stepperState.steps.map((step, index) => {
            return (
              <Step
                className={`stepper__step ${
                  stepperState.activeStep == index
                    ? "stepper__step--active"
                    : stepperState.activeStep > index
                    ? "stepper__step--complete"
                    : "stepper__step--disabled"
                }`}
                key={step.label}
              >
                <StepLabel
                  icon={<LabelIcon>{step.labelIcon}</LabelIcon>}
                  className="stepper__label"
                >
                  <span className="stepper__label-text">{step.label}</span>
                </StepLabel>
              </Step>
            );
          })}
        </Stepper>
        <Box className="stepper__actions-inner">
          <>
            <span></span>
            <Button
              hidden={stepperState.activeStep == 0}
              variant="outlined"
              size="large"
              className="stepper__button stepper__button--back"
              onClick={handlePrevStep}
            >
              Back
            </Button>

            <LoadingButton
              disabled={stepperState.nextButtonDisabled}
              hidden={stepperState.activeStep == 3}
              loading={stepperState.nextButtonLoading}
              size="large"
              className="stepper__button stepper__button--next"
              variant="contained"
              onClick={handleNextStep}
            >
              <span>Next</span>
            </LoadingButton>

            <LoadingButton
              disabled={stepperState.nextButtonDisabled}
              hidden={stepperState.activeStep !== 3}
              loading={
                (stepperState.nextButtonLoading &&
                !reportCompilerState.output.downloaded) || reportCompilerState.output.loading
              }
              startIcon={
                <FileDownloadRoundedIcon className="stepper__button-icon" />
              }
              size="large"
              // onClick={() =>
              //   reportCompilerDispatch({
              //     type: "HANDLE_DOWNLOAD"
              //   })
              // }
              onClick={() =>
                reportCompilerDispatch(handleDownloadAsync({}, settings))

              }
              variant="contained"
              className="stepper__button stepper__button--download"
            >
              <span>Download</span>
            </LoadingButton>
          </>
        </Box>
      </Box>
    </Box>
  );
}
