import { convertToNumber } from "./GeneralTypeConversions.js";

export class HoursVsClassFlag{
    constructor(valid){
        this.valid = valid;
        this.averageClassHours = 1;
        this.percentError = 1;
    }

    addAverageClassHours(hours){
        let hoursFound = convertToNumber(true, hours);
        if(hoursFound !== null){
            this.averageClassHours = hoursFound;
            return;
        }
        this.errorMessage = "hours not of type float";
    }

    addPercentError(percent){
        let percentFound = convertToNumber(true, percent);
        if(percentFound !== null){
            if(percentFound > 1) {
                this.percentError = percentFound / 100;
            }else {
                this.percentError = percentFound;
            }
            return;
        }
        this.errorMessage = "percent not of type float";
    }

}