import React, { useState } from "react";
import IconButton from "@mui/material/IconButton";
import { styled } from "@mui/material/styles";
import { useStudioScrapeStatus } from "../contexts/StudioScrapeStatus/StudioScrapeStatusContext.js";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ReplayRoundedIcon from "@mui/icons-material/ReplayRounded";
import UploadFileRoundedIcon from "@mui/icons-material/UploadFileRounded";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import CircularProgress from "@mui/material/CircularProgress";
import EditCalendarRoundedIcon from "@mui/icons-material/EditCalendarRounded";
import { getStudioAndReportTypeForFile } from "../contexts/ReportCompiler/utility-functions.js";
import ReusableDialog from "./Dialogs/ReusableDialog.js";
import { useSettingsContext } from "../contexts/SettingsContext.js";
import { useAuth } from "../contexts/AuthContext.js";
import { useSnackBar } from "../contexts/SnackBarContext/SnackBarContext.js";
import CustomDateRangePicker from "./DatePickers/CustomDateRangePicker.js";
// import XLSX from "xlsx";
import XLSX from "@sheet/core";
import {
  uploadFileToS3,
} from "../utility-functions/utility-functions.js";
import { formatDate } from "../contexts/StudioScrapeStatus/utility-functions.js";
const options = ["Retry", "Upload", "Change Pay Period", "Delete"];

import {
  Menu,
  MenuItem,
  Button,
  Typography,
} from "@mui/material";

import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';

const Input = styled("input")({
  display: "none",
});

const ITEM_HEIGHT = 48;

export default function LongMenu(props) {
  const { reportCompilerDispatch, reportCompilerState } = useSettingsContext();
  const {
    getReportIndex,
    reports,
    currentlySelectedStudios,
    retryAllFailedReports,
    retryReport,
    dispatch,
    selectedDates,
  } = useStudioScrapeStatus();

  var loading = false;
  const { getUID } = useAuth();
  const showSnackBar = useSnackBar();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const [openDialog, setOpenDialog] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null); // State for storing the selected date


  const [selectedStartDate, setSelectedStartDate] = useState(null);
  const [selectedEndDate, setSelectedEndDate] = useState(null);

  const [applyToAllStudios, setApplyToAllStudios] = useState(false); // Default to false

  const handleToggleChange = (event) => {
    setApplyToAllStudios(event.target.checked);
  };



  const handleDateChange = (selectedDates) => {
    setSelectedStartDate(selectedDates[0]);
    setSelectedEndDate(selectedDates[1]);
  };
  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };
  
  const handleSubmit = () => {
    let startDate = formatDate(new Date(selectedStartDate));
    let endDate = formatDate(new Date(selectedEndDate));

    let independentDates = [startDate, endDate];

    if (applyToAllStudios) {
      currentlySelectedStudios.forEach((location) => {
        retryReport(
          {
            fileIndex: props.fileIndex,
            fileType: props.fileType,
            studioIndex: currentlySelectedStudios.indexOf(location),
          },
          independentDates
        );
      });
    } else {
      retryReport(
        {
          fileIndex: props.fileIndex,
          fileType: props.fileType,
          studioIndex: props.studioIndex,
        },
        independentDates
      );
    }

    // Close the dialog
    handleCloseDialog();
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  async function handleReportUpload(event) {

    let uid = await getUID();
    let reportInfo = await getStudioAndReportTypeForFile(
      event.target.files[0],
      reportCompilerState
    );
    if (reportInfo.studio) {
      if (
        (reportInfo.studio.includes(
          currentlySelectedStudios[props.studioIndex]
        ) ||
          reportInfo.studio.toLowerCase() == "all") &&
        reportInfo.fileType === props.fileType
      ) {

        var fileNameWithExtension = `${reportInfo.fileType}.${event.target.files[0].name}`;


        dispatch({
          type: "UPDATE_REPORT",

          reportObj: {
            status: "loading",
            fileType: props.fileType,
            // fileName: event.target.files[0].name,
            fileName: fileNameWithExtension,
            fileIndex: props.fileIndex,
            studioIndex: props.studioIndex,
            key: props.fileType,
            workbook: event.target.files,
          },
        });
        dispatch({ type: "UPDATE_STUDIO_PROGRESS" });

        // let isUploaded = await uploadFileToS3(
        //   event.target.files[0],
        //   reportInfo,
        //   uid
        // );

        const data = await event.target.files[0].arrayBuffer();
        const workbook = XLSX.read(data, {
          cellFormula: true,
          sheetStubs: true,
          cellNF: true,
          bookDeps: true,
        });

        if (workbook) {
          // Compile and check if report is valid
          // ~~~~ sepreate function for is report valid function
          // let isReportValid = await addExcelData(
          //   props.fileType + (props.studioIndex + 1) + "file",
          //   isUploaded
          // );
          reportCompilerDispatch({
            type: "ADD_EXCEL_DATA",
            fileId: props.fileType + (props.studioIndex + 1) + "file",
            workbook: workbook,
            // fileName: event.target.files[0].name,
            fileName: fileNameWithExtension,
          });
          //if (isReportValid) {
          // Set File Status to done
          dispatch({
            type: "UPDATE_REPORT_VALUE",
            studioIndex: props.studioIndex,
            reportIndex: props.fileIndex,
            key: "status",
            value: "done",
          });
          dispatch({ type: "UPDATE_STUDIO_PROGRESS" });

          await uploadFileToS3(
            event.target.files[0],
            currentlySelectedStudios[props.studioIndex],
            uid,
            selectedDates[0],
            selectedDates[1],
            fileNameWithExtension
          );

          // } else {
          //   dispatch({
          //     type: "UPDATE_REPORT_VALUE",
          //     studioIndex: props.studioIndex,
          //     reportIndex: props.fileIndex,
          //     key: "status",
          //     value: "error",
          //   });
          //   dispatch({ type: "UPDATE_STUDIO_PROGRESS" });
          // }
        } else {
          // Set to report status to upload error
          dispatch({
            type: "UPDATE_REPORT_VALUE",
            studioIndex: props.studioIndex,
            reportIndex: props.fileIndex,
            key: "status",
            value: "error",
          });
          dispatch({ type: "UPDATE_STUDIO_PROGRESS" });
        }
      } else {
        if (reportInfo.studio !== currentlySelectedStudios[props.studioIndex]) {
          showSnackBar("Incorrect Studio", "error");
          try {
            dispatch({
              type: "UPDATE_REPORT_VALUE",
              studioIndex: props.studioIndex,
              reportIndex: props.fileIndex,
              key: "status",
              value: "error",
            });
            dispatch({ type: "UPDATE_STUDIO_PROGRESS" });
          } catch (e) {
            console.error("Failed to handleSetFileStatusToStudioError()");
          }
        } else if (reportInfo.fileType !== props.fileType) {
          showSnackBar("Incorrect File Type", "error");
          dispatch({
            type: "UPDATE_REPORT_VALUE",
            studioIndex: props.studioIndex,
            reportIndex: props.fileIndex,
            key: "status",
            value: "done",
          });
          dispatch({ type: "UPDATE_STUDIO_PROGRESS" });
        }
      }
    } else {
      showSnackBar("Incorrect File", "error");
    }
  }

  async function handleRetryAllFailedReports(event) {
    retryAllFailedReports();
  }

  function handleRetryReport(event) {
    retryReport({
      fileIndex: props.fileIndex,
      fileType: props.fileType,
      studioIndex: props.studioIndex,
    });
  }

  function handleDeleteReport() {
    let reportIndex = getReportIndex(props.fileType, reportCompilerState);

    // ~~~~ WE HAD THE DELETE COMMENTED OUT? WAS THERE A PROBLEM THAT DELETING WAS CAUSING AND WHY WE WENT WITH SOFT DELETE?
    dispatch({
      type: "DELETE_REPORT",
      studioIndex: props.studioIndex,
      reportIndex: props.fileIndex ? props.fileIndex : reportIndex,
      fileType: props.fileType,
      key: "status",
      value: "idle",
    });
    // dispatch({
    //   type: "UPDATE_REPORT_VALUE",
    //   studioIndex: props.studioIndex,
    //   reportIndex: reportIndex,
    //   key: "status",
    //   value: "idle",
    // });
    dispatch({ type: "UPDATE_STUDIO_PROGRESS" });
  }

  async function handleRetryAll(event) {
    startScrapeSync((retryAll = true));
  }

  async function handleRetryAll(event) {
    startScrapeSync((retryAll = true));
  }

  const handleClose = () => {
    setAnchorEl(null);
  };

  const renderOptionMenuItem = (option) => {
    const commonProps = {
      key: option,
      selected: option === "Pyxis",
      style: { color: "#757575" },
    };

    switch (option) {
      case "Retry":
        return !props.isStudio ? (
          <MenuItem {...commonProps} onClick={handleRetryReport}>
            <ReplayRoundedIcon
              style={{ color: "#757575", marginRight: ".75em" }}
            />
            {option}
          </MenuItem>
        ) : (
          <MenuItem
            {...commonProps}
            disabled={loading}
            onClick={handleRetryAllFailedReports}
          >
            {loading ? (
              <>
                <CircularProgress
                  style={{ color: "#757575", marginRight: ".75em" }}
                  size={"1rem"}
                  color="inherit"
                />
                {option}
              </>
            ) : (
              <>
                <ReplayRoundedIcon
                  style={{ color: "#757575", marginRight: ".75em" }}
                />
                {option}
              </>
            )}
          </MenuItem>
        );
      case "Upload":
        return !props.isStudio ? (
          <MenuItem {...commonProps} onClick={props.handleUploadReport}>
            <label style={{ marginBottom: "0" }}>
              <Input
                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, text/csv"
                id="contained-button-file"
                multiple
                type="file"
                onChange={handleReportUpload}
                onClick={(event) => {
                  event.target.value = null;
                }}
              />
              <Button
                style={{
                  background: "none",
                  color: "inherit",
                  border: "none",
                  padding: "0",
                  font: "inherit",
                  cursor: "pointer",
                  outline: "inherit",
                  textTransform: "capitalize",
                  boxShadow: "none",
                  margin: "0",
                }}
                variant="text"
                component="span"
                raised={false}
              >
                <UploadFileRoundedIcon
                  style={{
                    color: "#757575",
                    marginRight: ".75em",
                    minWidth: "1em",
                    minHeight: "1em",
                  }}
                />
                Upload
              </Button>
            </label>
          </MenuItem>
        ) : null;
      case "Delete":
        if (!props.isStudio) {
          return (
            <MenuItem {...commonProps} onClick={handleDeleteReport}>
              <CloseRoundedIcon
                style={{ color: "#757575", marginRight: ".75em" }}
              />
              {option}
            </MenuItem>
          );
        } else {
          return null;
        }
      case "Change Pay Period":
        if (!props.isStudio) {
          return (
            <>
              <MenuItem {...commonProps} onClick={handleOpenDialog}>
                <EditCalendarRoundedIcon
                  style={{ color: "#757575", marginRight: ".75em" }}
                />
                {option}
              </MenuItem>
              <ReusableDialog
                open={openDialog}
                onClose={handleCloseDialog}
                title="Change Pay Period"
                subtitle={"Select the new pay period for the report."}
                actions={[
                  {
                    label: "Close",
                    onClick: handleCloseDialog,
                    color: "primary",
                    variant: "text"
                  },
                  {
                    label: "Confirm",
                    onClick: handleSubmit,
                    color: "primary",
                    variant: "contained",
                    disabled: !selectedStartDate || !selectedEndDate // Disable button if dates are not selected
                  },
                  // Add more action buttons if needed
                ]}
              >
                <div className="clubready-scrape__date-picker-container">
                  <div className="clubready-scrape__date-picker-label"></div>
                  <div className="clubready-scrape__date-picker">
                    {/* <BasicDateRangePicker
                        disabled={true}
                        marginTop={".3em"}
                        marginBotttom={".3em"}
                      ></BasicDateRangePicker> */}
                    <CustomDateRangePicker
                      marginTop={".3em"}
                      marginBotttom={".3em"}
                      startDate={selectedStartDate}
                      endDate={selectedEndDate}
                      onDateChange={handleDateChange}
                      // onSubmit={handleSubmit}
                      disabled={false}
                    />
                  </div>
                </div>

                {/* Apply for All Studios Toggle */}
                <FormGroup>
                  <FormControlLabel
                  className="reusable-dialog-form-control-label"
                    control={<Switch checked={applyToAllStudios} onChange={handleToggleChange} />}
                    label={<Typography component={"p"} variant="body1">Change Pay Period for All Locations</Typography>}
                    
                  />
                </FormGroup>



              </ReusableDialog>
            </>
          );
        }
        return null;

      default:
        return null;
    }
  };

  return (
    <>
      <div>
        <IconButton
          aria-label="more"
          id="long-button"
          aria-controls={open ? "long-menu" : undefined}
          aria-expanded={open ? "true" : undefined}
          aria-haspopup="true"
          onClick={handleClick}
          size="large"
        >
          <MoreVertIcon />
        </IconButton>
        <Menu
          id="long-menu"
          MenuListProps={{
            "aria-labelledby": "long-button",
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          PaperProps={{
            style: {
              maxHeight: ITEM_HEIGHT * 4.5,
              // width: "20ch",
            },
          }}
        >
          {/* Dropzone only */}
          {props.deleteOnly ? (
            <MenuItem
              key={"Delete"}
              selected={"Delete" === "Pyxis"}
              onClick={() => {
                if (props.isStudio) {
                  props.handleDeleteStudio(props.studioIndex);
                } else {
                  if (props.isDropZone) {
                    props.handleDeleteReport(props.fileType);
                  } else {
                    handleDeleteReport();
                  }
                }
              }}
              style={{ color: "#757575" }}
            >
              <CloseRoundedIcon
                style={{ color: "#757575", marginRight: ".75em" }}
              />
              {props.cancelOnly ? "Cancel" : "Delete"}
            </MenuItem>
          ) : (
            options.map((option) => renderOptionMenuItem(option))

            // options.map((option) => {
            //   if (!props.isStudio && option === "Retry") {
            //     return (
            //       <MenuItem
            //         key={option}
            //         selected={option === "Pyxis"}
            //         onClick={handleRetryReport}
            //         style={{ color: "#757575" }}
            //       >
            //         <ReplayRoundedIcon
            //           style={{ color: "#757575", marginRight: ".75em" }}
            //         />
            //         {option}
            //       </MenuItem>
            //     );
            //   } else if (props.isStudio && option === "Retry") {
            //     return (
            //       <MenuItem
            //         key={option}
            //         disabled={loading}
            //         selected={option === "Pyxis"}
            //         onClick={handleRetryAllFailedReports}
            //         style={{ color: "#757575" }}
            //       >
            //         {loading ? (
            //           <>
            //             <CircularProgress
            //               style={{ color: "#757575", marginRight: ".75em" }}
            //               size={"1rem"}
            //               color="inherit"
            //             />
            //             {option}
            //           </>
            //         ) : (
            //           <>
            //             <ReplayRoundedIcon
            //               style={{ color: "#757575", marginRight: ".75em" }}
            //             />
            //             {option}
            //           </>
            //         )}
            //       </MenuItem>
            //     );
            //   } else if (props.isStudio && option === "Upload") {
            //     return;
            //   } else if (!props.isStudio && option === "Upload") {
            //     return (
            //       <MenuItem
            //         key={option}
            //         selected={option === "Pyxis"}
            //         onClick={props.handleUploadReport}
            //         style={{ color: "#757575" }}
            //       >
            //         <label style={{ marginBottom: "0" }}>
            //           <Input
            //             accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            //             id="contained-button-file"
            //             multiple
            //             type="file"
            //             onChange={(event) => {
            //               handleReportUpload(event);
            //             }}
            //             onClick={(event) => {
            //               event.target.value = null;
            //             }}
            //           />
            //           <Button
            //             style={{
            //               background: "none",
            //               color: "inherit",
            //               border: "none",
            //               padding: "0",
            //               font: "inherit",
            //               cursor: "pointer",
            //               outline: "inherit",
            //               textTransform: "capitalize",
            //               boxShadow: "none",
            //               margin: "0",
            //             }}
            //             variant="text"
            //             component="span"
            //             raised={false}
            //           >
            //             <UploadFileRoundedIcon
            //               style={{
            //                 color: "#757575",
            //                 marginRight: ".75em",
            //                 minWidth: "1em",
            //                 minHeight: "1em",
            //               }}
            //             />
            //             Upload
            //           </Button>
            //         </label>
            //       </MenuItem>
            //     );
            //   } else if (option === "Delete") {
            //     if (props.isStudio) {
            //       return;
            //     } else {
            //       return (
            //         <MenuItem
            //           key={option}
            //           selected={option === "Pyxis"}
            //           onClick={() => {
            //             if (props.isStudio) {
            //               props.handleDeleteStudio(props.studioIndex);
            //             } else {
            //               handleDeleteReport();
            //             }
            //           }}
            //           style={{ color: "#757575" }}
            //         >
            //           <CloseRoundedIcon
            //             style={{ color: "#757575", marginRight: ".75em" }}
            //           />
            //           {option}
            //         </MenuItem>
            //       );
            //     }
            //   }
            // })
          )}
        </Menu>
      </div>
    </>
  );
}
