// asyncMiddleware.js


// actionTypes.js
import { handleDownload } from "../ReportCompiler/utility-functions";

export const START_DOWNLOAD = 'START_DOWNLOAD';
export const COMPLETE_DOWNLOAD = 'COMPLETE_DOWNLOAD';
export const ERROR_DOWNLOAD = 'ERROR_DOWNLOAD';



export const asyncMiddleware = ({ dispatch, getState }) => next => action => {
    console.log("asyncMiddleware();")

    if (typeof action === 'function') {
      return action(dispatch, getState);
    }
    return next(action);
  };
    
  export const startDownload = () => ({ type: START_DOWNLOAD });
  
  export const completeDownload = (data) => ({
    type: COMPLETE_DOWNLOAD,
    payload: data
  });
  
  export const errorDownload = (error) => ({
    type: ERROR_DOWNLOAD,
    error
  });
  
  export const asyncHandleDownload = (action, reportCompilerState, settings) => async (dispatch) => {
    console.log("asyncHandleDownload();")
    //console.log("state before startDownload: ", reportCompilerState);
    dispatch(startDownload());
    try {
      const newState = await handleDownload(action, reportCompilerState, settings);
      //console.log("after handledownload: ", newState)
      dispatch(completeDownload(newState));
    } catch (error) {
      dispatch(errorDownload(error));
    }
  };
  

  export const middlewareEnhancer = (middleware, reducer) => {
    console.log("middlewareEnhancer();")

    return (state, action) => {
      let dispatch = () => { };
      const middlewareAPI = {
        getState: () => state,
        dispatch: (action) => dispatch(action)
      };
      const chain = middleware.map(mw => mw(middlewareAPI));
      dispatch = chain.reduce((a, b) => (...args) => a(b(...args)))(action => reducer(state, action));
      return dispatch(action);
    };
  };