import { Box } from "@mui/material";
import React, {  useState } from "react";
//import { attendeeStatuses } from "../CommonVariables";
import InputBoxElement from "../../Components/InputBox/InputBox";
import { RevertList } from "../../Components/RevertList";
import { useSettingsContext } from "../../../contexts/SettingsContext";
import MultipleSelectCheckmarks from "../../../components/MultipleSelectCheckbox/MultipleSelectCheckbox";
import { useAuth } from "../../../contexts/AuthContext";
import { getUrlVariableValue } from "../../../utility-functions/utility-functions.js";
export default function FullClassWithNonAttendees(props) {
  const { settings, dispatch, changes, setChanges } = useSettingsContext();
  const { getUID } = useAuth();
  
  const [fullClassPay, setFullClassPay] = useState(
    settings[getUrlVariableValue("settingsId")].classSettings.general.fullClassSettings
      .valid
  );

  // useEffect(() => {
  //   handleUpdate(props.toggle, "valid");
  // }, [props.toggle]);
  const handleUpdate = (value, id) => {
    if (id === "valid") {
      setFullClassPay(value);
    }

    let newRev = new RevertList(changes.getValues());
    newRev.addElement(
      "fullClassSettings-" + id,
      settings[getUrlVariableValue("settingsId")].classSettings.general.fullClassSettings[
        id
      ],
      value
    );
    setChanges(newRev);

    dispatch({
      type: "UPDATE_GENERAL_FULL_CLASS_SETTINGS_WITH_KEY",
      settingsId: getUrlVariableValue("settingsId"),
      uid: getUID(),
      key: id,
      value: value,
    });
  };
  var toggleContentModifier = `settings-page__toggle-content${
    props.toggle ? "" : "--off"
  } settings-page__toggle-content--inline`;
  return (
    <Box className={toggleContentModifier}>
      <p variant="subtitle1">Count any attendees with statuses:</p>

      <MultipleSelectCheckmarks
        tag="Status"
        default={
          settings[getUrlVariableValue("settingsId")].classSettings.general
            .fullClassSettings.statuses
        }
        onChange={handleUpdate}
        id="statuses"
        multiple
        size={400}
        //options={attendeeStatuses}
        options={settings[getUrlVariableValue("settingsId")].generalSettings.attendeeStatuses}
        label="Statuses"
        name="Statuses"
      ></MultipleSelectCheckmarks>
      <p> and that cancelled within</p>
      <InputBoxElement
        default={
          settings[getUrlVariableValue("settingsId")].classSettings.general
            .fullClassSettings.timeCancel
        }
        onChange={handleUpdate}
        id="timeCancel"
        types={["number"]}
        size={100}
        label="Hours"
      ></InputBoxElement>
      <p> hours (regardless of status)</p>
    </Box>
  );
}
