import React, { useState, useEffect } from "react";
import ReusableDialog from "./ReusableDialog";
import { getFirestore, doc, getDoc, setDoc, collection, getDocs, updateDoc } from "firebase/firestore";
import { useAuth } from "../../contexts/AuthContext";
import { Typography } from "@mui/material";

import './dialogs.scss';
export default function UpdateReleaseDialog() {
  const [visible, setVisible] = useState(false);
  const { getUID } = useAuth();
  const userUid = getUID(); // Assuming getUID returns the user's UID

  useEffect(() => {
    // Fetch and check the popup status for the current user

    fetchPopupStatus(userUid);

    // setAllPopupsStatusToOne();
  }, [userUid]); // Add userUid as a dependency to rerun the effect when it changes

  const fetchPopupStatus = async (uid) => {
    const db = getFirestore();
    const popupDocRef = doc(db, 'popups', uid);

    try {
      const popupDocSnapshot = await getDoc(popupDocRef);

      if (popupDocSnapshot.exists()) {
        const popupData = popupDocSnapshot.data();

        // Check if the popup should be shown
        if (!popupData || popupData.status === '1') {
          setVisible(true);
        }
      } else {
        // If the document does not exist, create it with status '1' (show the popup)
        await setDoc(popupDocRef, { status: '1' });
        setVisible(true);
      }
    } catch (error) {
      console.error("Error fetching or creating popup status:", error);
    }
  };

  const setAllPopupsStatusToOne = async () => {
    const db = getFirestore();
    const popupsCollectionRef = collection(db, 'popups');

    try {
      // Retrieve all documents in the 'popups' collection
      const querySnapshot = await getDocs(popupsCollectionRef);

      // Iterate over each document and update the 'status' to '1'
      querySnapshot.forEach(async (doc) => {
        const popupDocRef = doc.ref;
        await updateDoc(popupDocRef, { status: '1' });
      });

      console.log("All popups status set to '1'");
    } catch (error) {
      console.error("Error updating popups status:", error);
    }
  };


  const setAllPopupsStatusToZero = async () => {
    const db = getFirestore();
    const popupsCollectionRef = collection(db, 'popups');

    try {
      // Retrieve all documents in the 'popups' collection
      const querySnapshot = await getDocs(popupsCollectionRef);

      // Iterate over each document and update the 'status' to '1'
      querySnapshot.forEach(async (doc) => {
        const popupDocRef = doc.ref;
        await updateDoc(popupDocRef, { status: '0' });
      });

      console.log("All popups status set to '0'");
    } catch (error) {
      console.error("Error updating popups status:", error);
    }
  };


  const handleClose = async () => {
    setVisible(false);

    // After the user closes the popup, set the status to '0' for the current user
    const db = getFirestore();
    const popupDocRef = doc(db, 'popups', userUid);
    await setDoc(popupDocRef, { status: '0' }, { merge: true });
  };

  return (
    <ReusableDialog
      open={visible}
      onClose={handleClose}
      fullWidth={true}
      // title="Recent Improvements!"

      actions={[
        {
          label: "dismiss",
          onClick: handleClose,
          color: "primary",
        },
      ]}
    >
      <div className="update-release">
        <div className={`update-release__image-container update-release__image-container--faq`}>
        </div>
        {/* <div className={`update-release__image-container update-release__image-container--error`}>
        </div>
        <div className={`update-release__image-container update-release__image-container--error`}>
        </div> */}

        <div className="update-release__content">


          <h3 className="update-release__title">ClubReady Issue</h3>

          <div className="update-release__section">
            {/* <Typography className="update-release__subheading"  variant="h5">
                Enhanced Customization for Class Types
              </Typography> */}
            <Typography className="update-release__subheading" variant="h5">
              If the Booking Event Log is not loading after 4 or 5 retries please read below:
            </Typography>

            <Typography className="update-release__body">
              ClubReady Booking Event Log is currently having issues. We have found a solution, however we have mapped the session payroll attendee statuses with the Booking Event Logs so some statuses may be missing.
            </Typography>
            <Typography className="update-release__body" >
              If the following statuses are paid for they will likely not be captured correctly: rescheduled by admin, canceled within policy, canceled by admin.              </Typography>
            <Typography className="update-release__body" >
              We are currently not able to see times in which attendees were logged in and by who meaning any commission for opening bookings or per sign up logic will not be captured.              </Typography>
          </div>
        </div>
      </div>
    </ReusableDialog>
  );
}



// {/* <div className="update-release">
// <div className="update-release__image-container">
//   {/* <img className="update-release__image" src="/assets/images/undraw_happy_news_re_tsbd (1).svg" /> */}
//  </div>

// <div className="update-release__content">


//   <h3 className="update-release__title">Recent Updates!</h3>

//   <div className="update-release__section">
//     <Typography className="update-release__subheading"  variant="h5">
//       Enhanced Customization for Class Types
//     </Typography>
//     <Typography className="update-release__body">
//       We're expanding beyond the traditional class types of group, intro, and private. With our latest update, you have the freedom to customize your class groupings to better suit your needs.
//     </Typography>
//     <Typography className="update-release__body" >
//       Private Class Variants: We've detailed our private class offerings for greater clarity. Now, you can choose from Private, Semi-Private, and Thirty-Minute Private classes.
//     </Typography>
//   </div>

//   <div className="update-release__section">
//     <Typography className="update-release__subheading" variant="h5" >
//       File Mapping Improvements
//     </Typography>
//     <Typography className="update-release__body" >
//       Shift Bonuses Payment: You can now easily process shift bonuses for your staff.
//     </Typography>
//     <Typography className="update-release__body" >
//       Staff Class Bookings Payment: It's also simpler to compensate staff for their class bookings.
//     </Typography>
//   </div>

//   <div className="update-release__section">
//     <Typography className="update-release__subheading" variant="h5">
//       Alternate Date Range capabilities
//     </Typography>
//     <Typography className="update-release__body">
//       Monthly Commission Report: If you pay sales commission each month, you can now override the original pay period file pull with a different date range.
//     </Typography>
//     {/* <Typography className="update-release-subtitle" variant="body1">
//           Interested in adding or removing certain class types, or implementing any of these new features? Contact us, and we'll help set everything up for you. (Scott@paywell.solutions)
//         </Typography> */}
//   </div>
// </div>
// </div> */}

{/* <div className="update-release__section">
<Typography className="update-release__subheading" variant="h5" >
  File Mapping Improvements
</Typography>
<Typography className="update-release__body" >
  Shift Bonuses Payment: You can now easily process shift bonuses for your staff.
</Typography>
<Typography className="update-release__body" >
  Staff Class Bookings Payment: It's also simpler to compensate staff for their class bookings.
</Typography>
</div>

<div className="update-release__section">
<Typography className="update-release__subheading" variant="h5">
  Alternate Date Range capabilities
</Typography>
<Typography className="update-release__body">
  Monthly Commission Report: If you pay sales commission each month, you can now override the original pay period file pull with a different date range.
</Typography>
{/* <Typography className="update-release-subtitle" variant="body1">
      Interested in adding or removing certain class types, or implementing any of these new features? Contact us, and we'll help set everything up for you. (Scott@paywell.solutions)
    </Typography> */}
// </div> */}