import React, { useState } from "react";
import { Button, TextField, IconButton, List, ListItem, ListItemText, ListItemSecondaryAction } from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';

export default function PayPeriodsConfig(props) {
    const [payPeriods, setPayPeriods] = useState(props.payPeriods.length > 0 ? props.payPeriods : [{ startDate: '', endDate: '' }]);

    const handleAddPeriod = () => {
        let newList = [...payPeriods, { startDate: '', endDate: '' }];
        setPayPeriods(newList);
        props.onChange(newList, props.id);
    };

    const handleRemovePeriod = (index) => {
        const newList = [...payPeriods];
        newList.splice(index, 1);
        setPayPeriods(newList);
        props.onChange(newList, props.id);
    };

    const handleChange = (index, field, value) => {
        const updatedPeriods = payPeriods.map((period, i) => {
            if (i === index) {
                return { ...period, [field]: value };
            }
            return period;
        });
        setPayPeriods(updatedPeriods);
        props.onChange(updatedPeriods, props.id);
    };

    return (
        <List dense id="payPeriodExamples">
            {payPeriods.map((period, index) => (
                <ListItem key={index}>
                    <ListItemText>
                        <TextField
                            label="Start Date"
                            type="date"
                            value={period.startDate}
                            onChange={(e) => handleChange(index, 'startDate', e.target.value)}
                            sx={{ marginRight: 2 }}
                            InputLabelProps={{ shrink: true }}
                        />
                        <TextField
                            label="End Date"
                            type="date"
                            value={period.endDate}
                            onChange={(e) => handleChange(index, 'endDate', e.target.value)}
                            InputLabelProps={{ shrink: true }}
                        />
                    </ListItemText>
                    <ListItemSecondaryAction>
                        <IconButton edge="end" aria-label="delete" onClick={() => handleRemovePeriod(index)}>
                            <DeleteIcon />
                        </IconButton>
                    </ListItemSecondaryAction>
                </ListItem>
            ))}
            <Button startIcon={<AddIcon />} onClick={handleAddPeriod}>
                Add Pay Period
            </Button>
        </List>
    );
}
