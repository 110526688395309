import { convertToArray } from "./GeneralTypeConversions.js";

export class ClassBucket{
    constructor(type){
        this.type = type;
        this.classNames = [];
        this.classStatuses = [];
        this.capacity = -1;
        this.hasSignUpsPostSession = false;
        this.hasSecondaryName = false;
        this.isAffectedByAlternateClientPayment = false;
        this.isStandardSessionType = false;
        this.includeSessionTimeInName = true;
        this.wouldHaveBeenFullApplicable = true;
        this.paidOnSignUps = false;
        this.defaultPay = -1;
        this.payment = null;
    }

    addNames(classNamesArray){
        let namesArray = convertToArray(classNamesArray)
        if(namesArray !== null){
            this.classNames = namesArray;
            return;
        }
        this.errorMessage = "class names could not be read as type array";
    }

    addStatuses(classStatusesArray){
        let statusesArray = convertToArray(classStatusesArray)
        if(statusesArray !== null){
            this.classStatuses = statusesArray;
            return;
        }
        this.errorMessage = "class statuses could not be read as type array";
    }

    setCapacity(capacity){
        this.capacity = capacity;
    }

    setDefaultPay(defaultPay){
        this.defaultPay = defaultPay;
    }

    getNames(){
        return this.classNames;
    }

    getStatuses(){
        return this.classStatuses;
    }

    getBucketRateString(){
        return this.type.charAt(0).toLowerCase() + this.type.slice(1) + "Rates";
    }
}