import { getSubstringBeforeFirstDot, uploadSessionSnapshotToS3 } from "../utility-functions.js";
// import * as XLSX from 'xlsx';
import XLSX from "@sheet/core";

import { API } from 'aws-amplify';
import { v4 as uuidv4 } from "uuid";
import { getTextFileFromS3, getWorkbookFromS3 } from "../../contexts/StudioScrapeStatus/utility-functions.js";
import { addOrUpdateUrlVariable, getUrlVariableValue } from "../utility-functions.js";
import { getFirebaseJwtToken } from "../tokenUtility";
import { addExcelData, compileTimePunchDetailData } from "../../contexts/ReportCompiler/utility-functions.js";
export class SessionSnapshot {
  constructor(settingsData, staffData, outputData, selectedDatesArray) {
    this.settings = settingsData || {};
    this.staff = staffData || {};
    this.output = outputData || {};
    this.selectedDates = selectedDatesArray || [];
  }

  // Method to initialize and upload the session data
  async initialize() {

    try {
      await Promise.all([
        this.uploadSettingsSnapshot(),
        this.uploadStaffSnapshot(),
        this.uploadOutputSnapshot(),
      ]);

    } catch (error) {
      console.error('Error during initialization:', error);
      throw error; // Rethrow the error to indicate initialization failure
    }
  }




  // Method to upload the settings snapshot to S3
  async uploadSettingsSnapshot() {
    try {
      const settingsText = JSON.stringify(this.settings);
      const settingsBlob = new Blob([settingsText], { type: 'text/plain' });
      await uploadSessionSnapshotToS3(
        settingsBlob,
        null,
        this.settings?.uid,
        this.selectedDates[0],
        this.selectedDates[1],
        `settings_id_${this.settings?.settingsId}.txt`, true
      );
    } catch (error) {
      console.error('Error uploading settings:', error);
    }
  }

  // Method to upload the staff snapshot to S3
  async uploadStaffSnapshot() {
    try {
      const staffText = JSON.stringify(this.staff);
      const staffBlob = new Blob([staffText], { type: 'text/plain' });
      await uploadSessionSnapshotToS3(
        staffBlob,
        null,
        this.settings?.uid,
        this.selectedDates[0],
        this.selectedDates[1], "staff.txt", true
      );
    } catch (error) {
      console.error('Error uploading staff:', error);
    }
  }

  // Method to upload the output snapshot to S3
  async uploadOutputSnapshot() {
    try {
      const wbout = XLSX.write(this.output, {
        bookType: "xlsx",
        type: "array"
      });
      const excelBlob = new Blob([wbout], {
        type: "application/octet-stream"
      });

      await uploadSessionSnapshotToS3(
        excelBlob,
        null,
        this.settings?.uid,
        this.selectedDates[0],
        this.selectedDates[1],
        "output.xlsx",
        true
      );
    } catch (error) {
      console.error('Error uploading output:', error);
    }
  }

  // add all snapshot retireval like workbooks/session path etc to the class for cleanless

}

export async function initializeSessionSnapshot(settingsPreset, staff, output, selectedDates) {
  try {
    const session = new SessionSnapshot(settingsPreset, staff, output, selectedDates);
    await session.initialize();


  } catch (error) {
    console.error('Error during session initialization:', error);
    // Handle the error appropriately.
  }
}


// export const getWorkbookFromS3 = async (url) => {
//     try {
//       const response = await axios({
//         url,
//         method: "GET",
//         responseType: "arraybuffer",
//       });

//       // Derive file name from URL
//       // const fileName = url.substring(url.lastIndexOf("/") + 1);
//       const fileName = getFileNameFromSignedURL(url);

//       const workbook = read(response.data, { type: "buffer" });

//       // Return both workbook and filename
//       return { workbook, fileName };
//     } catch (error) {
//       console.error("Failed to get workbook from signed URL: ", error);
//       throw error;
//     }
//   };

export async function createPresignedUrl(bucket, key) {

  try {
    const token = await getFirebaseJwtToken();
    // Use the token in your API call or other logic
    const apiName = 'paywellAPIResource';
    const path = '/createPresignedUrl';

    const myInit = {
      headers: {
        'Authorization': `Bearer ${token}`
      },
      body: {
        bucket: bucket,
        key: key,
      },
    };

    try {
      const response = await API.post(apiName, path, myInit);
      return response.url; // This assumes the Lambda function returns the URL as 'url' in the response
    } catch (error) {
      console.error('Error generating pre-signed URL:', error);
      throw error;
    }
  } catch (error) {
    console.error('Error in someHelperFunction:', error);
    // Handle errors, possibly related to token fetching
  }


}

export async function createPresignedUrlsForFolder(bucket, folderKey) {

  try {
    const keys = await listObjectsInS3Folder(bucket, folderKey); // ~~~~ just fixed this so debug from here
    const preSignedUrls = [];

    for (const key of keys) {
      const preSignedUrl = await createPresignedUrl(bucket, key);
      preSignedUrls.push(preSignedUrl);
    }

    return preSignedUrls;
  } catch (error) {
    console.error('Error generating pre-signed URLs for folder:', error);
    throw error;
  }
}

export const createS3Folder = async (bucketName, folderPath) => {

  try {
    const token = await getFirebaseJwtToken();
    // Use the token in your API call or other logic
    try {
      const response = await API.post(
        "paywellAPIResource",
        "/createS3BucketFolder",
        {
          headers: {
            'Authorization': `Bearer ${token}`
          },
          body: {
            path: folderPath,
            bucketName,
          },
          queryStringParameters: {
            path: folderPath,
            bucketName,
          },
        }
      );
    } catch (error) {
      console.error("Error creating folder:", error);
    }

  } catch (error) {
    console.error('Error in someHelperFunction:', error);
    // Handle errors, possibly related to token fetching
  }



};


/** Recursive listing */
export async function listObjectsInS3Folder(bucket, folderKey) {

  try {
    const token = await getFirebaseJwtToken();
    // Use the token in your API call or other logic
    // List all objects in the S3 bucket folder and its subfolders
    const apiName = 'paywellAPIResource';
    const path = '/listObjectsInS3Folder';

    const myInit = {
      headers: {
        'Authorization': `Bearer ${token}`
      },
      body: {
        bucket: bucket,
        key: folderKey,
      },
    };

    try {
      const response = await API.post(apiName, path, myInit);

      // const objects = response.objects;

      // // Check if there are subfolders
      // const subfolders = response.subfolders;

      // if (subfolders && subfolders.length > 0) {
      //   for (const subfolder of subfolders) {
      //     // Recursively list objects in subfolders
      //     const subfolderKey = `${folderKey}${subfolder}`;
      //     const subfolderObjects = await listObjectsInS3Folder(bucket, subfolderKey);
      //     objects.push(...subfolderObjects);
      //   }
      // }

      return response;
    } catch (error) {
      console.error('Error listing objects in folder:', error);
      throw error;
    }


  } catch (error) {
    console.error('Error in someHelperFunction:', error);
    // Handle errors, possibly related to token fetching
  }



}
export async function listS3PayrollRunPaths(
  bucketName,
  uid,
  maxKeys = 10,
  settingsId) {

  try {
    const token = await getFirebaseJwtToken();
    // Use the token in your API call or other logic
    // List all objects in the S3 bucket folder and its subfolders
    const apiName = 'paywellAPIResource';
    const path = '/listS3PayrollRunPaths';

    //   const event ={
    //     "body": "{\"bucketName\":\"paywell-user-runs\",\"uid\":\"N5qbaV1PeUVCkbhxAESlOFg5lDh1/\", \"maxKeys\": 10, \"settingsId\": 17}"
    // }

    const myInit = {
      headers: {
        'Authorization': `Bearer ${token}`
      },
      body: {
        bucketName,
        uid,
        maxKeys,
        settingsId
      },
    };

    try {
      const response = await API.post(apiName, path, myInit);

      // const objects = response.objects;

      // // Check if there are subfolders
      // const subfolders = response.subfolders;

      // if (subfolders && subfolders.length > 0) {
      //   for (const subfolder of subfolders) {
      //     // Recursively list objects in subfolders
      //     const subfolderKey = `${folderKey}${subfolder}`;
      //     const subfolderObjects = await listObjectsInS3Folder(bucket, subfolderKey);
      //     objects.push(...subfolderObjects);
      //   }
      // }

      return response;
    } catch (error) {
      console.error('Error listing objects in folder:', error);
      throw error;
    }


  } catch (error) {
    console.error('Error in someHelperFunction:', error);
    // Handle errors, possibly related to token fetching
  }



}

export async function fetchLatestSessionPathWithSnapshot(bucketName, uid, payPeriod, settingsId = false, sessionSnapshotRequired = true) {

  try {
    const token = await getFirebaseJwtToken();
    // Use the token in your API call or other logic
    try {
      const apiName = 'paywellAPIResource'; // Replace with your API name
      const path = '/getLatestSessionPath';

      // Prepare the request body
      const requestData = {
        bucketName,
        uid,
        payPeriod,
        settingsId,
        sessionSnapshotRequired
      };

      const response = await API.post(apiName, path, {
        headers: {
          'Authorization': `Bearer ${token}`
        },
        body: requestData,
      });

      if (response.latestSessionPath) {
        return response.latestSessionPath;
      } else {
        return null;
      }
    } catch (error) {
      console.error('Error fetching latest session path:', error);
      return null;
    }

  } catch (error) {
    console.error('Error in someHelperFunction:', error);
    // Handle errors, possibly related to token fetching
  }



}

export async function fetchLatestSessionPath(bucketName, uid, payPeriod, settingsId = false, sessionSnapshotRequired = false) {

  try {
    const token = await getFirebaseJwtToken();
    // Use the token in your API call or other logic
    try {
      const apiName = 'paywellAPIResource'; // Replace with your API name
      const path = '/getLatestSessionPath';

      // Prepare the request body
      const requestData = {
        bucketName,
        uid,
        payPeriod,
        settingsId,
        sessionSnapshotRequired
      };

      const response = await API.post(apiName, path, {
        headers: {
          'Authorization': `Bearer ${token}`
        },
        body: requestData,
      });

      if (response.latestSessionPath) {
        return response.latestSessionPath;
      } else {
        return null;
      }
    } catch (error) {
      console.error('Error fetching latest session path:', error);
      return null;
    }

  } catch (error) {
    console.error('Error in someHelperFunction:', error);
    // Handle errors, possibly related to token fetching
  }



}


export async function fetchPreSignedUrls(bucketName, latestSessionPath) {

  if (!latestSessionPath) {
    return [];
  }

  try {
    const urls = await createPresignedUrlsForFolder(bucketName, latestSessionPath);
    return urls;
  } catch (error) {
    console.error('Error fetching pre-signed URLs:', error);
    return [];
  }
}


export async function fetchObjectData(url) {
  try {
    const response = await fetch(url);

    if (response.ok) {
      const contentType = response.headers.get('Content-Type');

      if (contentType.includes('application/json')) {
        // For JSON files
        const data = await response.json();
        return data;
      } else if (contentType.includes('text')) {
        // For text-based files (TXT)
        const data = await response.text();
        return data;
      } else if (contentType.includes('application/vnd.ms-excel')) {
        // For XLS (Excel) files
        const blob = await response.blob();
        // You can handle the Excel blob here, e.g., display or download it
        return blob;
      } else {
        // Handle other file types as needed
        // You may want to add more conditions for other file types
        const data = await response.arrayBuffer();
        return data;
      }
    }
  } catch (error) {
    console.error('Error fetching object data:', error);
  }
}



export async function processS3Urls(urls) {

  // Process session snapshot URLs
  const settings = await getSettingsFromSessionSnapshot(urls);


  // Process report URLs
  const workbooks = await getAllWorkbooksFromSession(urls);


  return { settings, workbooks };

}



export async function getSettingsFromSessionSnapshot(sessionSnapshotUrls) {
  sessionSnapshotUrls = sessionSnapshotUrls.filter(url => url.includes("session_snapshot/"));

  const settings = {
    "staff": {}
  };

  for (const url of sessionSnapshotUrls) {
    if (!url.includes("session_snapshot/")) {
      throw new Error("URL does not include 'session_snapshot/'");
    }

    try {
      const data = await fetchAndExtractJSONFromTxt(url);
      const fileName = getFileNameFromUrl(url);

      if (fileName.includes("staff.txt")) {
        settings.staff.staff = data; // ~~~~ TEMP FIX BECUASE WE HAVE SETTINGS.STAFF.STAFF AS OUR STANDARD SETUP WHICH NEEDS TO BE FIXED.
      } else if (fileName.startsWith("settings_id_")) {
        const key = fileName.match(/(\d+)\.txt/)[1];
        settings[key] = data;
      }
    } catch (error) {
      console.warn('Error processing session snapshot data:', error);
    }
  }

  return settings;
}

async function fetchAndExtractJSONFromTxt(url) {
  const response = await fetch(url);
  if (response.ok) {
    const txtData = await response.text();
    try {
      const jsonData = JSON.parse(txtData);
      return jsonData;
    } catch (error) {
      throw new Error('Error parsing JSON from text data');
    }
  } else {
    throw new Error('Error fetching text data from URL');
  }
}

function getFileNameFromUrl(url) {
  const urlParts = url.split('/');
  return urlParts[urlParts.length - 1];
}




export async function getAllWorkbooksFromSession(sessionWorkbookUrls) {
  sessionWorkbookUrls = sessionWorkbookUrls.filter(url => !url.includes("session_snapshot/"));

  const workbooks = {};

  for (const url of sessionWorkbookUrls) {
    const keyPath = extractKeyPathFromUrl(url);
    if (!keyPath.endsWith("/")) {
      try {
        const fileType = getFileTypeFromUrl(url);
        if (fileType.includes("xls") || fileType.includes("xlsx") || fileType.includes("csv")) {
          const {
            workbook,
            fileName,
            lastModifiedDate,
          } = await getWorkbookFromS3(url);
          workbooks[keyPath] = {
            workbook,
            fileName,
            lastModifiedDate,
          }; // Store the Excel workbook
        } else {
          const {
            jsonData,
            fileName,
            lastModifiedDate, } = await getTextFileFromS3(url);
          workbooks[keyPath] = {
            jsonData,
            fileName,
            lastModifiedDate,
          } // Store the JSON data
        }
      } catch (error) {
        console.warn('Error processing session workbooks:', error);
      }
    }
  }

  return workbooks;
}



function getFileTypeFromUrl(url) {
  const urlParts = url.split('/');
  const fileName = urlParts[urlParts.length - 1];
  const fileType = fileName.split('.').pop(); // Extract the file extension
  return fileType.toLowerCase();
}

function extractKeyPathFromUrl(url) {
  // Use a regular expression to capture the part between the domain and the query string
  const regex = /https:\/\/[^/]+\/(.+)\?/;
  const match = url.match(regex);

  if (match && match[1]) {
    const keyPath = decodeURIComponent(match[1]);
    return keyPath;
  }

  // If the regular expression doesn't match, you may want to handle this case differently
  throw new Error("Failed to extract S3 key path from URL");
}

function extractNameFromFilename(filename) {
  const firstIntegerMatch = filename.match(/\d+/); // Match the first integer in the string

  if (firstIntegerMatch) {
    const firstInteger = firstIntegerMatch[0];
    const name = filename.split(firstInteger)[0].trim();
    return name;
  }

  return filename.trim(); // Return the original string if no integer is found
}



export function convertS3PathToFileId(s3Path, studios) {
  // Extract studio name from S3 path (second to last part)
  const parts = s3Path.split('/');

  if (parts.length < 3) {
    throw new Error(`Invalid S3 path: ${s3Path}`);
  }

  const studioName = parts[parts.length - 2];
  const studioIndex = studios.indexOf(studioName);

  if (studioIndex === -1) {
    throw new Error(`Studio "${studioName}" not found in studios list.`);
  }

  // Extract report type from the last part of S3 path
  const reportName = parts[parts.length - 1];



  // Extract the report name without numbers, date, and file extension
  var trimmedReportName = extractNameFromFilename(reportName);

  // NEED TO MAKE IT SO THAT EVERY FILE START WITH (FILETYPE.NAME)
  trimmedReportName = getSubstringBeforeFirstDot(trimmedReportName);
  

  // Define mappings for report type abbreviations
  const reportTypeMappings = {
    "Booking Events Log": "bel",
    "Active Members Log": "mem",
    "Active Members": "mem",
    "ClubReady Time Punch Detail": "time",
    "Time Clock Payroll": "time",
    "Time Clock Payroll - Summary": "time",
    "Time Clock Payroll - Employee Detail": "time",
    "Session Payroll Detail": "pay",
    "Session Payroll": "pay",
    "Session Payroll - Detail": "pay",
    "Agreements Log": "agree",
    "Gross Sales Detail": "gross",
    "Gross Sales - Detail": "gross",
    "Gross Sales": "gross",
    "Product Sales Log": "sale",
  };

  // Use the transformed reportName to get the abbreviation
  const reportTypeAbbreviation = reportTypeMappings[trimmedReportName] || trimmedReportName;



  if (!reportTypeAbbreviation) {
    throw new Error(`Unrecognized report type: ${trimmedReportName}`);
  }

  // Construct the file ID
  const fileId = `${reportTypeAbbreviation}${studioIndex + 1}file`;

  return fileId;
}




function findFirstIntegerKey(obj) {
  for (const key in obj) {
    if (obj.hasOwnProperty(key) && Number.isInteger(Number(key))) {
      return key;
    }
  }
  return null; // Return null if no integer key is found
}
export function processWorkbooks(sessionSnapshotData) {

  const { workbooks } = sessionSnapshotData;
  const settingsId = findFirstIntegerKey(sessionSnapshotData.settings);

  addOrUpdateUrlVariable("settingsId", settingsId);
  const { studios } = sessionSnapshotData.settings[settingsId].generalSettings;

  for (const s3Path in workbooks) {

    const fileId = convertS3PathToFileId(s3Path, studios);
    const workbook = workbooks[s3Path];


    // handleAddExcelData(fileId, workbook);
  }
}


export function getStudiosAndWorkbooksFromSessionData(sessionSnapshotData) {
  const { workbooks } = sessionSnapshotData;
  const settingsId = findFirstIntegerKey(sessionSnapshotData.settings);

  addOrUpdateUrlVariable("settingsId", settingsId);
  const { studios } = sessionSnapshotData.settings[settingsId].generalSettings;

  //  const onlyStudiosWithData = updateStudios(Object.keys(sessionSnapshotData.workbooks), studios);
  return { workbooks, studios };
}




export async function getS3OutputFilePaths(bucket, folderKey) {
  const paths = await listObjectsInS3Folder(bucket, folderKey); // ~~~~ just fixed this so debug from here
  return filterExcelOutputPaths(paths);
}
function filterExcelOutputPaths(paths) {
  return paths.filter(path => {
    // Check if the path includes '/session_testing/' and is an Excel file
    const testingOutput = path.includes('/session_testing/') && path.endsWith('.xlsx');

    // Check if the path is the specific '/session_snapshot/output.xlsx'
    const originalOutput = path.endsWith('/session_snapshot/output.xlsx');

    return { originalOutput, testingOutput }
  });
}
function extractStudioNameFromS3Path(path) {
  const parts = path.split('/'); // Split the string by '/'
  const studioName = parts[2]; // The studio name is expected to be at the 3rd position (index 2)
  return studioName;
}
function extractFilenameFromS3Path(path) {
  const parts = path.split('/'); // Split the string by '/'
  const filename = parts[3]; // The studio name is expected to be at the 3rd position (index 2)
  return filename;
}



// need scotts help to make sure this function is up to date
export async function addSessionSnapshotWorkbooksToReportCompiler(
  workbooks,
  studios,
  reportCompilerState
) {

  for (const s3Path in workbooks) {
    var fileId;
    try {
      fileId = convertS3PathToFileId(s3Path, studios);
    } catch (e) {
      console.log("Error thrown in convertS3PathToFileId(): ", e);
      console.warn(e);
    }
    const workbook = workbooks[s3Path].workbook;
    const studio = extractStudioNameFromS3Path(s3Path);
    const filename = extractFilenameFromS3Path(s3Path);


    // 10/31/23 ALSO JUST CHEKC THIS TO MAKE SURE IM NOT MISSING ANY LOGIC THAT WAS JAMMED INTO THIS FUNCTION AND RELATED TIME PUNCH JSON FUNCTIONS
    // - getTimeClockDataFromStudio
    if (s3Path.includes("Time Punch Detail")) {
      reportCompilerState = compileTimePunchDetailData(
        { studioId: fileId, timeObjects: workbook },
        reportCompilerState
      );
    } else {
      // need logic to only addExcelData for the most recent file for each type(bel,mem,agree,etc) using lastModifiedDate
      // for example if there are more thatn one time clcok file use the most recent
      reportCompilerState = addExcelData(
        {
          fileId: fileId,
          workbook: workbook,
          fileName: filename ?? null,
          studio
        },
        reportCompilerState
      );
    }
  }
  return reportCompilerState;
}



export async function addOrganizedSessionSnapshotWorkbooksToReportCompiler(
  organizedWorkbooks,
  studios,
  reportCompilerState
) {
  let fileRegistry = {};  // Object to store the most recent file for each fileId

  // Collect all files and determine the most recent for each fileId
  for (const userId in organizedWorkbooks) {
    const userWorkbooks = organizedWorkbooks[userId];

    for (const dateRange in userWorkbooks) {
      const dateWorkbooks = userWorkbooks[dateRange];

      for (const location in dateWorkbooks) {
        const files = dateWorkbooks[location];

        for (const file of files) {
          const s3Path = userId + '/' + dateRange + '/' + location + '/' + file.fileName;
          let fileId;
          try {
            fileId = convertS3PathToFileId(s3Path, studios);
          } catch (e) {
            console.log("Error thrown in convertS3PathToFileId(): ", e);
            continue;  // Skip this file if the fileId cannot be determined
          }

          // Check if this fileId already has a registered file or if the current file is more recent
          if (!fileRegistry[fileId] || new Date(file.lastModifiedDate) > new Date(fileRegistry[fileId].lastModifiedDate)) {
            fileRegistry[fileId] = { ...file, s3Path };
          }
        }
      }
    }
  }

  // Process the most recent files from the registry
  for (const [fileId, file] of Object.entries(fileRegistry)) {
    const { workbook, fileName, lastModifiedDate, s3Path } = file;


    const studio = extractStudioNameFromS3Path(s3Path);


    reportCompilerState = addExcelData(
      {
        fileId: fileId,
        workbook: workbook,
        fileName: fileName ?? null,
        studio
      },
      reportCompilerState
    );

  }

  return { updatedReportCompilerState: reportCompilerState, fileRegistry };
}


export function organizeWorkbooks(workbooks) {
  const organized = {};

  // Iterate through each key in the workbooks object
  Object.keys(workbooks).forEach(key => {
    const parts = key.split('/'); // Split the key to extract identifiers
    const userId = parts[0];
    const dateRange = parts[1];
    const location = parts[2];
    const fileName = parts[3];

    // Check if the userId does not exist in the organized object and initialize
    if (!organized[userId]) {
      organized[userId] = {};
    }

    // Check if the dateRange does not exist under this userId and initialize
    if (!organized[userId][dateRange]) {
      organized[userId][dateRange] = {};
    }

    // Check if the location does not exist under this dateRange and initialize
    if (!organized[userId][dateRange][location]) {
      organized[userId][dateRange][location] = [];
    }

    if ("jsonData" in workbooks[key]) {
      // Push the file details to the corresponding location
      organized[userId][dateRange][location].push({
        fileName: workbooks[key].fileName,
        lastModifiedDate: workbooks[key].lastModifiedDate,
        jsonData: workbooks[key].jsonData // Assuming the workbook data is part of the initial object

      });
    } else {
      // Push the file details to the corresponding location
      organized[userId][dateRange][location].push({
        fileName: workbooks[key].fileName,
        lastModifiedDate: workbooks[key].lastModifiedDate,
        workbook: workbooks[key].workbook // Assuming the workbook data is part of the initial object

      });
    }

  });

  return organized;
}




export async function getReportCompilerStateFromLatestSessionSnapshot(latestSessionPath, studios, selectedSettingsId, reportCompilerState) {

  try {

    const preSignedUrls = await fetchPreSignedUrls(
      process.env.REACT_APP_PAYWELL_RUNS_BUCKET_NAME,
      latestSessionPath
    );

    if (!preSignedUrls || preSignedUrls.length === 0) {
      console.error("!ERROR!: Pre-signed URLs not found.");
      throw new Error("No pre-signed URLs could be fetched from S3.");
    }


    const allWorkbooks = await getAllWorkbooksFromSession(preSignedUrls);

  
    const organizedWorkbooks = organizeWorkbooks(allWorkbooks);

    const { updatedReportCompilerState, fileRegistry } = await addOrganizedSessionSnapshotWorkbooksToReportCompiler(
      organizedWorkbooks,
      studios,
      reportCompilerState
    );


    return { updatedReportCompilerState, studios, fileRegistry };
  } catch (error) {
    console.error("Failed to compile report from session snapshot:", error);
    // Optionally rethrow the error or handle it to return a default value/state
    throw error; // or return null; or return a default state
  }
}


// export function formatSessionPathUrl(sessionPath) {
//   // Extract the relevant session data part from the full sessionPath string
//   const sessionData = sessionPath.split('/')[1];

//   if (!sessionData) {
//     return { error: 'Invalid sessionPath format' };
//   }

//   // Split the extracted string by underscores
//   const parts = sessionData.split('_');
//   if (parts.length !== 3) {
//     return { error: 'Invalid sessionPath format' };
//   }

//   const startDate = parts[0];
//   const endDate = parts[1];
//   const timestamp = parts[2];

//   // Format start and end dates
//   const formattedStartDate = new Date(startDate).toLocaleDateString('en-US', {
//     month: 'short',
//     day: 'numeric',
//     year: 'numeric',
//   });

//   const formattedEndDate = new Date(endDate).toLocaleDateString('en-US', {
//     month: 'short',
//     day: 'numeric',
//     year: 'numeric',
//   });

//   // Combine start and end dates for the pay period
//   const payPeriod = `${formattedStartDate} - ${formattedEndDate}`;

//   // Use a static status for now; customize as needed
//   const status = "Pending"; // This could be dynamic based on your application's logic

//   // Get the 'time ago' string
//   const timeAgoString = formatTimeAgo(timestamp);

//   // Return the object in the format expected by the table component
//   return {
//     payPeriod: payPeriod,
//     status: status,
//     timeAgo: timeAgoString,
//     action: "View Details" // Static action, can be customized or made dynamic
//   };
// }



/** Get latest run data to show in ui that asks the user if they want to use latest run */
export async function getLatestRunData(uid) {

  const selectedSettingsId = getUrlVariableValue("settingsId");
  if (selectedSettingsId) {


    try {
      const latestSessionPath = await fetchLatestSessionPath(
        process.env.REACT_APP_PAYWELL_RUNS_BUCKET_NAME,
        uid,
        undefined,
        selectedSettingsId
      );
      if (!latestSessionPath) {
        console.error(`Session Path for Settings Preset #${selectedSettingsId}: Not Found`);
        throw new Error(`No session path found for settings ID ${selectedSettingsId}`);
      }

      const latestRunDetails = formatSessionPathUrl(latestSessionPath);

      latestRunDetails["uid"] = uid;
      latestRunDetails["sessionPath"] = latestSessionPath;


      return latestRunDetails;
    } catch (error) {
      console.error("Failed to fetchLatestSessionPath and format data:", error);
      // Optionally rethrow the error or handle it to return a default value/state
      throw error; // or return null; or return a default state
    }
  }
}

function formatDate(dateStr) {
  const date = new Date(dateStr);
  return date.toISOString().split('T')[0]; // Converts date to 'YYYY-MM-DD' format
}



export function formatSessionPathUrl(sessionPath) {

  // Extract `settingsId` from the filename (e.g., "settings_id_16.txt")
  const settingsId = extractSettingsIdFromPath(sessionPath);



  const sessionData = sessionPath.split('/')[1];
  if (!sessionData) {
    return { error: 'Invalid sessionPath format' };
  }
  const parts = sessionData.split('_');
  if (parts.length !== 3) {
    return { error: 'Invalid sessionPath format' };
  }

  const [startDate, endDate, timestamp] = parts;


  const payPeriod = convertDateRange(startDate, endDate);
  const timeAgoString = formatTimeAgo(timestamp);
  const rootSessionPath = sessionPath.replace(/settings_id_\d+\//, '');

  // Replace all instances of "-" with "/" using a regular expression
  const payPeriodStartDate = startDate.replace(/-/g, '/');
  const payPeriodEndDate = endDate.replace(/-/g, '/');



  return {
    id: uuidv4(), // Ensure uuidv4 is imported or available
    payPeriod,
    // status: "Pending", // This can be dynamic based on other data or logic
    sessionPath: rootSessionPath,
    timeAgo: timeAgoString,
    payPeriodStartDate,
    payPeriodEndDate,
    timestamp,
    settingsId,
    action: "View Details"
  };


}

export function convertDateRange(startDate, endDate) {
  // Helper function to convert numeric month to full name
  function getMonthName(monthIndex) {
    const monthNames = [
      "January", "February", "March", "April", "May", "June",
      "July", "August", "September", "October", "November", "December"
    ];
    return monthNames[monthIndex];
  }

  // Helper function to format the day with ordinal suffixes
  function getOrdinalSuffix(day) {
    const suffixes = ["th", "st", "nd", "rd"];
    const v = day % 100;
    return day + (suffixes[(v - 20) % 10] || suffixes[v] || suffixes[0]);
  }

  // Parse the start and end date strings
  const start = new Date(startDate);
  const end = new Date(endDate);

  // Extract the components for the start date
  const startMonthName = getMonthName(start.getMonth());
  const startDayWithSuffix = getOrdinalSuffix(start.getDate());
  const startYear = start.getFullYear();

  // Extract the components for the end date
  const endMonthName = getMonthName(end.getMonth());
  const endDayWithSuffix = getOrdinalSuffix(end.getDate());
  const endYear = end.getFullYear();

  // // Check if both dates are in the same year and month
  // if (startYear === endYear && start.getMonth() === end.getMonth()) {
  //     return `${startMonthName} ${startDayWithSuffix}, ${startYear} to ${endDayWithSuffix}, ${endYear}`;
  // }

  // Otherwise, include full month name for both dates
  return `${startMonthName} ${startDayWithSuffix}, ${startYear} - ${endMonthName} ${endDayWithSuffix}, ${endYear}`;
}


/**
 * Extracts the integer following "settings_id_" if it exists in the provided string.
 * @param {string} path - The input string to check.
 * @returns {number|null} The extracted integer if found, otherwise null.
 */
export function extractSettingsIdFromPath(path) {
  // Regular expression to match "settings_id_" followed by an integer
  const match = path.match(/settings_id_(\d+)\//);
  // If a match is found, convert the captured group to an integer and return
  return match ? parseInt(match[1], 10) : null;
}
export function formatTimeAgo(timestamp) {
  const date = new Date(parseInt(timestamp));
  const now = new Date();
  const secondsAgo = Math.floor((now - date) / 1000);

  let interval = secondsAgo / 31536000;
  if (interval > 1) {
    return Math.floor(interval) + " years ago";
  }
  interval = secondsAgo / 2592000;
  if (interval > 1) {
    return Math.floor(interval) + " months ago";
  }
  interval = secondsAgo / 86400;
  if (interval > 1) {
    return Math.floor(interval) + " days ago";
  }
  interval = secondsAgo / 3600;
  if (interval > 1) {
    return Math.floor(interval) + " hours ago";
  }
  interval = secondsAgo / 60;
  if (interval > 1) {
    return Math.floor(interval) + " minutes ago";
  }
  return Math.floor(secondsAgo) + " seconds ago";
}

export function filterBySlashCount(strings, count) {
  return strings.filter(item => (item.match(/\//g) || []).length === count);
}

function filterBySettingsId(paths, settingsId) {
  const uniquePaths = new Set(); // Use a Set to store unique paths

  // Filters paths to include only those that contain the specified settingsId
  paths.filter(path => path.includes(`settings_id_${settingsId}`))
    .map(path => {
      const parts = path.split('/');
      return parts.slice(0, 3).join('/'); // Joins the first three parts (up to the second "/")


    })
    .forEach(path => uniquePaths.add(path)); // Add each path to the Set, automatically handling duplicates

  return Array.from(uniquePaths); // Convert Set back to Array to return
}


function getSettingsIdPaths(paths) {

  return paths.filter(path => path.includes("settings_id_"));

}
export async function getAllUserPayrollRows(uid, maxKeys = 10) {
  const res = await listS3PayrollRunPaths(process.env.REACT_APP_PAYWELL_RUNS_BUCKET_NAME, uid, maxKeys, getUrlVariableValue("settingsId"));

  let allRows = res.paths.map((path) => formatSessionPathUrl(path))

  return allRows;
}