export class RetailEvent {
  constructor(
    location,
    dateObject,
    name,
    salesPerson,
    secondarySalesperson,
    clientFirstName,
    clientLastName,
    price,
    notes
  ) {
    this.commissionType = "Retail";
    this.location = location;
    this.date = dateObject;
    this.description = name;
    this.name = name;
    this.salesPerson = salesPerson;
    this.salespeople = {
      PrimarySalesperson: salesPerson,
      SecondarySalesperson: secondarySalesperson,
    };
    this.clientFirstName = clientFirstName;
    this.clientLastName = clientLastName;
    this.price = price;
    this.notes = notes;
    this.discountPercent = 0;

    this.id = "";
    this.comment = "";
  }

  getCustomer(){
    return this.clientFirstName + " " + this.clientLastName;
  }

  getClientName() {
    return this.clientFirstName + " " + this.clientLastName;
  }
}
