import React, { useEffect, useState } from "react";
import { Route } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";
import PrivateContextProvider from "../contexts/PrivateContextProvider/PrivateContextProvider";
export default function AdminRoute({ component: Component, drawerContent, ...rest }) {
  const { currentUser } = useAuth();
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    const checkAdminStatus = async () => {
      if (currentUser !== null) {
        try {
          const idTokenResult = await currentUser.getIdTokenResult();
         // Check if the user is an Admin.
          if (!!idTokenResult.claims.admin) {
            setIsAdmin(true);
          } else {
            setIsAdmin(false);
          }
        } catch (error) {
          console.error(error);
          setIsAdmin(false);
        }
      } else {
        setIsAdmin(false);
      }
    };

    checkAdminStatus();
  }, [currentUser]);

  return isAdmin ? (
    <Route
      {...rest}
      render={(props) => (
        <PrivateContextProvider>
          <Component {...props} drawerContent={drawerContent} />
        </PrivateContextProvider>
      )}
    />
  ) : (
    <div>
      <p>You don't have the privileges to access this page.</p>
      {/* You can add a button or link here to navigate back to another page */}
    </div>
  );
}

