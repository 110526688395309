// EditStaffComponent.js
import React, { useState, useEffect } from "react";
import { v4 as uuidv4 } from "uuid";
import {
  Container,
  TextField,
  Grid,
  Typography,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  OutlinedInput,
  FormGroup,
  FormControlLabel,
  Switch,
  Card,
  CardContent,
  Autocomplete,
  Button,
  DialogActions,
} from "@mui/material";
import { useSnackBar } from "../../contexts/SnackBarContext/SnackBarContext";
import { useStudioScrapeStatus } from "../../contexts/StudioScrapeStatus/StudioScrapeStatusContext";
import { useSettingsContext } from "../../contexts/SettingsContext";
import {
  deepClone,
  splitRateAndInfoDetails,
  getFirstObject,
  findStaffByName,
  findNewStaffObjects,
  consolidateStaff,
  ensureUniqueIds,
} from "./utils"; // Adjust the path as necessary
import "./staffDialog.scss";
import { getUrlVariableValue } from "../../utility-functions/utility-functions";
import { createNewStaffObject } from "../../pages/StaffSettings/utils";
import { useAuth } from "../../contexts/AuthContext";
import { updateStaffMasterSettings } from "../../services/staff.services";

class StaffRate {
  constructor(rateDetails) {
    Object.assign(this, rateDetails);
  }
}

class StaffInfo {
  constructor(infoDetails) {
    Object.assign(this, infoDetails);
  }
}

const testStaff = {
  commissionRate: {
    retail: "69",
    agreement: "69",
  },
  alternateNames: ["69"],
  locations: ["Club Pilates North Naperville", "Club Pilates South Naperville"],
  secondaryHourly: "69",
  secondIdString: "69",
  type: "Instructor",
  paywellUID: "0Pqd7ciGP8cXe8TrOMQ9BYp0imp2",
  email: "69",
  homeLocation: "Club Pilates North Naperville",
  hourly: "69",
  name: "Error Correction Test Staff",
  isNew: false,
  status: "new",
  id: "470b2df5-faa7-4e28-bb9b-94c82670f5da",
  homeRegion: "",
  idString: "69",
  titles: ["69"],
  brand: "Club Pilates",
  semiPrivateRate: {
    none: false,
    zero: "0",
    base: "69",
    perHead: "69",
    afterAttendee: "69",
    flat: "0",
    custom: [],
  },
  groupRate: {
    none: false,
    zero: "0",
    base: "69",
    perHead: "69",
    afterAttendee: "69",
    flat: "0",
    custom: [],
  },
  introRate: {
    none: false,
    zero: "69",
    base: "0",
    perHead: "0",
    afterAttendee: "0",
    flat: "69",
    custom: [],
  },
  privateRate: {
    none: false,
    zero: "0",
    base: "0",
    perHead: "0",
    afterAttendee: "0",
    flat: "69",
    custom: [],
  },
  thirtyMinPrivateRate: {
    none: false,
    zero: "0",
    base: "0",
    perHead: "0",
    afterAttendee: "0",
    flat: "69",
    custom: [],
  },
  updated: false,
  statusField: false,
};
const EditStaffComponent = ({ staff, onSave, onClose }) => {
  const showSnackBar = useSnackBar();
  const { getUID } = useAuth();
  const {
    settings,
    dispatch,
    changes,
    setChanges,
    reportCompilerDispatch,
    reportCompilerState,
  } = useSettingsContext();
  const { currentlySelectedStudios } = useStudioScrapeStatus();

  // const newStaffArray = findNewStaffObjects(settings.staff.staff);

  const newStaff = findStaffByName(settings.staff.staff, staff.name);
  var initialRateDetails = {};

  var initialInfoDetails = {};
  
  ({ initialInfoDetails, initialRateDetails } =
    splitRateAndInfoDetails(newStaff));

  const [homeLocation, setHomeLocation] = useState(
    initialInfoDetails.homeLocation || ""
  );
  const [selectedLocations, setSelectedLocations] = useState(
    currentlySelectedStudios
  );
  const [sharedRates, setSharedRates] = useState(
    newStaff["sharedRates"] ?? true
  );
  const [sharedInfo, setSharedInfo] = useState(newStaff["sharedInfo"] ?? true);
  const [rates, setRates] = useState(initialRateDetails);
  const [info, setInfo] = useState(initialInfoDetails);
  const instructorTypes = settings[
    getUrlVariableValue("settingsId")
  ].generalSettings?.staffTypes
    ?.filter((staff) => staff.sessionPay === true)
    .map((staff) => staff.name);
  const titlesOptions = ["Manager", "Instructor", "Assistant", "Coordinator"];

  // Update shared or individual rates/info across locations
  useEffect(() => {
    let updatedRates = {};
    let updatedInfo = {};
    if (sharedRates) {
      const sharedRate = new StaffRate(initialRateDetails);
      updatedRates = {
        shared: sharedRate,
        ...selectedLocations.reduce(
          (acc, location) => ({ ...acc, [location]: sharedRate }),
          {}
        ),
      };
    } else {
      updatedRates = selectedLocations.reduce(
        (acc, location) => ({
          ...acc,
          [location]: new StaffRate(initialRateDetails),
        }),
        {}
      );
    }

    if (sharedInfo) {
      const sharedInfoDetail = new StaffInfo(initialInfoDetails);
      updatedInfo = {
        shared: sharedInfoDetail,
        ...selectedLocations.reduce(
          (acc, location) => ({ ...acc, [location]: sharedInfoDetail }),
          {}
        ),
      };
    } else {
      updatedInfo = selectedLocations.reduce(
        (acc, location) => ({
          ...acc,
          [location]: new StaffInfo(initialInfoDetails),
        }),
        {}
      );
    }

    setRates(updatedRates);
    setInfo(updatedInfo);
  }, [selectedLocations, sharedRates, sharedInfo, settings]);

  const handleHomeLocationChange = (event) => {
    setHomeLocation(event.target.value);
  };

  const handleLocationsChange = (event) => {
    setSelectedLocations(
      typeof event.target.value === "string"
        ? event.target.value.split(",")
        : event.target.value
    );
  };

  const handleRateChange = (location, path, newValue) => {
    const keys = path.split(".");
    let updatedRates = { ...rates }; // Shallow clone at the top level

    if (sharedRates) {
      // Deep clone the shared rates to ensure no shared references between locations
      const sharedRateObject = deepClone(updatedRates.shared);
      let current = sharedRateObject;
      keys.forEach((key, index) => {
        if (index === keys.length - 1) {
          current[key] = newValue; // Update the value
        } else {
          if (!current[key]) current[key] = {}; // Ensure nested objects are initialized
          current = current[key];
        }
      });

      // Apply the updated shared rates to all locations
      Object.keys(updatedRates).forEach((key) => {
        updatedRates[key] = sharedRateObject;
      });
    } else {
      // Ensure a deep clone for individual location updates to prevent shared references
      updatedRates[location] = deepClone(updatedRates[location]);
      let current = updatedRates[location];
      keys.forEach((key, index) => {
        if (index === keys.length - 1) {
          current[key] = newValue;
        } else {
          if (!current[key]) current[key] = {};
          current = current[key];
        }
      });
    }

    setRates(updatedRates);
  };

  const handleInfoChange = (location, field, newValue) => {
    const updatedValue = sharedInfo
      ? { ...info.shared, [field]: newValue }
      : { ...info[location], [field]: newValue };

    if (sharedInfo) {
      const sharedUpdate = Object.keys(info).reduce(
        (acc, key) => ({ ...acc, [key]: updatedValue }),
        {}
      );
      setInfo(sharedUpdate);
    } else {
      setInfo({ ...info, [location]: updatedValue });
    }
  };

  const handleOnSave = () => {
    // Replace above code with this
    var consolidatedStaff = consolidateStaff({
      sharedInfo,
      sharedRates,
      selectedLocations,
      info,
      rates,
    });

    // Ensure unique IDs for each staff member(replaces above code)
    consolidatedStaff = ensureUniqueIds(consolidatedStaff);

    // Dispatch each staff member
    consolidatedStaff.forEach((staff) => {
      staff["sharedRates"] = sharedRates;
      staff["sharedInfo"] = sharedInfo;
      dispatch({
        type: "ADD_STAFF_SETTINGS_STAFF_MEMBER", // or use a different action type if appropriate
        newStaffId: staff.id,
        newStaff: staff,
      });
    });

    // Once all staff members have been processed and dispatched, save the settings for all
    handleSaveStaff(getUID(), consolidatedStaff);
 
    onSave(staff);
  };

  const handleSaveStaff = async (uid, staff) => {
    
    const newStaffObjects = {};

    if (Array.isArray(staff)) {
      staff.forEach((s) => {
        newStaffObjects[s.id] = s;
      });
    } else {
      newStaffObjects[staff.id] = staff;
    }

    const updateSuccessful = await updateStaffMasterSettings(
      uid,
      newStaffObjects
    );
    if (updateSuccessful) {
      // alert("Staff Succesfully Added!");
      showSnackBar(`Successfully updated ${staff[0].name}`, "success");
      reportCompilerDispatch({
        type: "BUILD_REPORT_COMPILER_STATE_STAFF",
      });
    } else {
      alert("Error Adding Staff!");
      showSnackBar(`Failed to update ${staff[0].name}`, "error");
    }
  };

  return (
    <div className="add-staff-component">
      <div className="add-staff-component__content">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel>Home Location</InputLabel>
              <Select
                value={homeLocation}
                onChange={handleHomeLocationChange}
                input={<OutlinedInput label="Home Location" />}
              >
                {currentlySelectedStudios.map((location) => (
                  <MenuItem key={location} value={location}>
                    {location}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel>Locations</InputLabel>
              <Select
                multiple
                value={selectedLocations}
                onChange={handleLocationsChange}
                input={<OutlinedInput label="Locations" />}
                renderValue={(selected) => selected.join(", ")}
              >
                {currentlySelectedStudios.map((location) => (
                  <MenuItem key={location} value={location}>
                    <Typography>{location}</Typography>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          {/* Staff Info Section */}
          <Grid item xs={12}>
            <FormGroup>
              <FormControlLabel
                control={
                  <Switch
                    disabled
                    checked={sharedInfo}
                    onChange={() => setSharedInfo(!sharedInfo)}
                  />
                }
                label="Shared Info Across Locations"
              />
            </FormGroup>
          </Grid>

          {sharedInfo ? (
            <Grid item xs={12}>
              <Card>
                <CardContent>
                  <Typography variant="h5">Shared Staff Information</Typography>
                  {/* name: "liam changed again", */}
                  <TextField
                    label="Name"
                    fullWidth
                    name="name"
                    margin="dense"
                    defaultValue={staff.name}
                    value={info.shared?.name}
                    onChange={(e) =>
                      handleInfoChange("shared", "name", e.target.value)
                    }
                  />
                  {/* idString: "69", */}
                  <TextField
                    label="idString"
                    name="idString"
                    fullWidth
                    margin="dense"
                    value={info.shared?.idString || ""}
                    onChange={(e) =>
                      handleInfoChange("shared", "idString", e.target.value)
                    }
                  />

                  {/* secondIdString: "", */}
                  <TextField
                    label="secondIdString"
                    name="secondIdString"
                    fullWidth
                    margin="dense"
                    value={info.shared?.secondIdString || ""}
                    onChange={(e) =>
                      handleInfoChange(
                        "shared",
                        "secondIdString",
                        e.target.value
                      )
                    }
                  />

                  {/* type: "Non-Instructor", */}
                  <FormControl fullWidth>
                    <InputLabel id="staff-type">Staff Type</InputLabel>

                    <Select
                      label={"Staff Type"}
                      name="type"
                      value={info.shared?.type}
                      onChange={(e) =>
                        handleInfoChange("shared", "type", e.target.value)
                      }
                      input={<OutlinedInput label="Staff Type" />}
                    >
                      {instructorTypes.map((type) => (
                        <MenuItem key={type} value={type}>
                          {type}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>

                  {/* email: "Liamkujawski23@gmail.com", */}
                  <TextField
                    label="email"
                    name="email"
                    fullWidth
                    margin="dense"
                    value={info.shared?.email || ""}
                    onChange={(e) =>
                      handleInfoChange("shared", "email", e.target.value)
                    }
                  />

                  {/* titles: [], */}
                  <Autocomplete
                    multiple
                    options={titlesOptions}
                    getOptionLabel={(option) => option}
                    value={info.shared?.titles || []}
                    onChange={(event, newValue) => {
                      handleInfoChange("shared", "titles", newValue);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label="Titles"
                        placeholder="Select Titles"
                      />
                    )}
                  />

                  {/* alternateNames: [], */}
                  <Autocomplete
                    multiple
                    freeSolo
                    options={
                      info.shared?.alternateNames.map((name) => name) || []
                    } // If you have predefined options, list them here.
                    value={info.shared?.alternateNames || []}
                    onChange={(event, newValue) => {
                      handleInfoChange("shared", "alternateNames", newValue);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label="Alternate Names"
                        placeholder="Add Alternate Names"
                      />
                    )}
                  />
                </CardContent>
              </Card>
            </Grid>
          ) : (
            selectedLocations.map((location) => (
              <Grid item xs={12} sm={6} key={`${location}-info`}>
                <Card>
                  <CardContent>
                    <Typography variant="h6">
                      {location} - Staff Info
                    </Typography>
                    {/* name: "liam changed again", */}

                    <TextField
                      label="Name"
                      name="name"
                      fullWidth
                      margin="dense"
                      value={info[location]?.name || ""}
                      onChange={(e) =>
                        handleInfoChange(location, "name", e.target.value)
                      }
                    />

                    {/* idString: "69", */}
                    <TextField
                      label="idString"
                      name="idString"
                      fullWidth
                      margin="dense"
                      value={info[location]?.idString || ""}
                      onChange={(e) =>
                        handleInfoChange(location, "idString", e.target.value)
                      }
                    />

                    {/* secondIdString: "", */}
                    <TextField
                      label="secondIdString"
                      name="secondIdString"
                      fullWidth
                      margin="dense"
                      value={info[location]?.secondIdString || ""}
                      onChange={(e) =>
                        handleInfoChange(
                          location,
                          "secondIdString",
                          e.target.value
                        )
                      }
                    />

                    {/* type: "Non-Instructor", */}
                    <FormControl fullWidth>
                      <InputLabel id="staff-type">Staff Type</InputLabel>

                      <Select
                        label={"Staff Type"}
                        name="type"
                        defaultValue={info[location]?.type}
                        value={info[location]?.type}
                        onChange={(e) =>
                          handleInfoChange(location, "type", e.target.value)
                        }
                        input={<OutlinedInput label="Staff Type" />}
                      >
                        {["Instructor", "Non-Instructor"].map((type) => (
                          <MenuItem key={type} value={type}>
                            {type}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>

                    {/* email: "Liamkujawski23@gmail.com", */}
                    <TextField
                      label="email"
                      name="email"
                      fullWidth
                      margin="dense"
                      value={info[location]?.email || ""}
                      onChange={(e) =>
                        handleInfoChange(location, "email", e.target.value)
                      }
                    />

                    {/* titles: [], */}
                    <Autocomplete
                      multiple
                      options={titlesOptions}
                      getOptionLabel={(option) => option}
                      value={info[location]?.titles || []}
                      onChange={(event, newValue) => {
                        handleInfoChange(location, "titles", newValue);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          label="Titles"
                          placeholder="Select Titles"
                        />
                      )}
                    />

                    {/* alternateNames: [], */}
                    <Autocomplete
                      multiple
                      freeSolo
                      options={
                        info[location]?.alternateNames.map((name) => name) || []
                      } // Adjust as necessary for predefined options.
                      value={info[location]?.alternateNames || []}
                      onChange={(event, newValue) => {
                        handleInfoChange(location, "alternateNames", newValue);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          label="Alternate Names"
                          placeholder="Add Alternate Names"
                        />
                      )}
                    />
                  </CardContent>
                </Card>
              </Grid>
            ))
          )}

          {/* Staff Rates Section */}
          <Grid item xs={12}>
            <FormGroup>
              <FormControlLabel
                control={
                  <Switch
                    disabled
                    checked={sharedRates}
                    onChange={() => setSharedRates(!sharedRates)}
                  />
                }
                label="Shared Rates Across Locations"
              />
            </FormGroup>
          </Grid>
          {sharedRates ? (
            <Grid item xs={12}>
              <Card>
                <CardContent>
                  <Typography variant="h5">Shared Staff Rates</Typography>

                  {/* Static Hourly Rate */}
                  <Grid item xs={12}>
                    <Typography variant="h6">Hourly Rate</Typography>
                    <TextField
                      label="Primary Hourly Rate"
                      type="number"
                      fullWidth
                      margin="dense"
                      value={rates.shared?.hourly || ""}
                      onChange={(e) =>
                        handleRateChange("shared", "hourly", e.target.value)
                      }
                    />
                    <TextField
                      label="Secondary Hourly Rate"
                      type="number"
                      fullWidth
                      margin="dense"
                      value={rates.shared?.secondaryHourly || ""}
                      onChange={(e) =>
                        handleRateChange(
                          "shared",
                          "secondaryHourly",
                          e.target.value
                        )
                      }
                    />
                  </Grid>

                  {Object.entries(
                    settings[getUrlVariableValue("settingsId")].classSettings
                      .classBuckets
                  ).map(([bucketName, bucketSetup]) => {
                    //<Grid item xs={12}>
                    //<Typography variant="h6">{bucketName} Rate</Typography>
                    if (
                      bucketSetup.rate.includeZero === "Paid A Different Amount"
                    ) {
                      <TextField
                        label="Zero Attendee"
                        type="number"
                        fullWidth
                        margin="dense"
                        value={rates.shared?.introRate.zero || ""}
                        onChange={(e) =>
                          handleRateChange(
                            "shared",
                            "introRate.zero",
                            e.target.value
                          )
                        }
                      />;
                    }
                    if (bucketSetup.rate.structure === "Flat") {
                      <TextField
                        label={`${bucketSetup.rate.structure} Rate (1-12 Attendees)`}
                        type="number"
                        fullWidth
                        margin="dense"
                        value={rates.shared?.introRate.flat || ""}
                        onChange={(e) =>
                          handleRateChange(
                            "shared",
                            "introRate.flat",
                            e.target.value
                          )
                        }
                      />;
                    } else if (
                      bucketSetup.rate.structure ===
                      "Base Rate + Per Head Bonus"
                    ) {
                      <TextField
                        label={bucketSetup.rate.structure}
                        type="number"
                        fullWidth
                        margin="dense"
                        value={rates.shared?.introRate.flat || ""}
                        onChange={(e) =>
                          handleRateChange(
                            "shared",
                            "introRate.flat",
                            e.target.value
                          )
                        }
                      />;
                      //   <TextField
                      //   label="Base Rate"
                      //   type="number"
                      //   fullWidth
                      //   margin="dense"
                      //   value={rates.shared?.groupRate.base || ""}
                      //   onChange={(e) =>
                      //     handleRateChange(
                      //       "shared",
                      //       "groupRate.base",
                      //       e.target.value
                      //     )
                      //   }
                      // />
                      // <TextField
                      //   label="Per Head Rate"
                      //   type="number"
                      //   fullWidth
                      //   margin="dense"
                      //   value={rates.shared?.groupRate.perHead || ""}
                      //   onChange={(e) =>
                      //     handleRateChange(
                      //       "shared",
                      //       "groupRate.perHead",
                      //       e.target.value
                      //     )
                      //   }
                      // />
                      // <TextField
                      //   label="Starting After"
                      //   type="number"
                      //   fullWidth
                      //   margin="dense"
                      //   value={rates.shared?.groupRate.afterAttendee || ""}
                      //   onChange={(e) =>
                      //     handleRateChange(
                      //       "shared",
                      //       "groupRate.afterAttendee",
                      //       e.target.value
                      //     )
                      //   }
                      // />
                    } else if (bucketSetup.rate.structure === "Custom Amount") {
                      let capacity = bucketSetup.rate.capacity;
                      for (let i = 0; i < capacity; i++) {
                        <TextField
                          label={bucketSetup.rate.structure}
                          type="number"
                          fullWidth
                          margin="dense"
                          value={rates.shared?.introRate.flat || ""}
                          onChange={(e) =>
                            handleRateChange(
                              "shared",
                              "introRate.flat",
                              e.target.value
                            )
                          }
                        />;
                      }
                    }
                    <MenuItem key={type} value={type}>
                      {type}
                    </MenuItem>;
                  })}

                  <Grid item xs={12}>
                    <Typography variant="h6">Group Rate</Typography>
                    <TextField
                      label="Base Rate"
                      type="number"
                      fullWidth
                      margin="dense"
                      value={rates.shared?.groupRate.base || ""}
                      onChange={(e) =>
                        handleRateChange(
                          "shared",
                          "groupRate.base",
                          e.target.value
                        )
                      }
                    />
                    <TextField
                      label="Per Head Rate"
                      type="number"
                      fullWidth
                      margin="dense"
                      value={rates.shared?.groupRate.perHead || ""}
                      onChange={(e) =>
                        handleRateChange(
                          "shared",
                          "groupRate.perHead",
                          e.target.value
                        )
                      }
                    />
                    <TextField
                      label="Starting After"
                      type="number"
                      fullWidth
                      margin="dense"
                      value={rates.shared?.groupRate.afterAttendee || ""}
                      onChange={(e) =>
                        handleRateChange(
                          "shared",
                          "groupRate.afterAttendee",
                          e.target.value
                        )
                      }
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Typography variant="h6">Intro Rate</Typography>
                    {/* Base Rate */}
                    {/* <TextField
                      label="Base Rate"
                      type="number"
                      fullWidth
                      margin="dense"
                      value={rates.shared?.introRate.base || ""}
                      onChange={(e) =>
                        handleRateChange(
                          "shared",
                          "introRate.base",
                          e.target.value
                        )
                      }
                    /> */}
                    {/* Per Head */}
                    {/* <TextField
                      label="Per Head"
                      type="number"
                      fullWidth
                      margin="dense"
                      value={rates.shared?.introRate.perHead || ""}
                      onChange={(e) =>
                        handleRateChange(
                          "shared",
                          "introRate.perHead",
                          e.target.value
                        )
                      }
                    /> */}
                    {/* Flat Rate */}
                    <TextField
                      label="Flat Rate (1-12 Attendees)"
                      type="number"
                      fullWidth
                      margin="dense"
                      value={rates.shared?.introRate.flat || ""}
                      onChange={(e) =>
                        handleRateChange(
                          "shared",
                          "introRate.flat",
                          e.target.value
                        )
                      }
                    />
                    {/* After Attendee */}
                    {/* <TextField
                      label="After Attendee"
                      type="number"
                      fullWidth
                      margin="dense"
                      value={rates.shared?.introRate.afterAttendee || ""}
                      onChange={(e) =>
                        handleRateChange(
                          "shared",
                          "introRate.afterAttendee",
                          e.target.value
                        )
                      }
                    /> */}
                    {/* Zero Attendee */}
                    <TextField
                      label="Zero Attendee"
                      type="number"
                      fullWidth
                      margin="dense"
                      value={rates.shared?.introRate.zero || ""}
                      onChange={(e) =>
                        handleRateChange(
                          "shared",
                          "introRate.zero",
                          e.target.value
                        )
                      }
                    />
                  </Grid>

                  {/* Static Sales Commission */}
                  <Grid item xs={12}>
                    <Typography variant="h6">Sales Commission</Typography>
                    {/* Retail Commission Rate */}
                    <TextField
                      label="Retail Commission Rate"
                      type="text"
                      fullWidth
                      margin="dense"
                      value={rates.shared?.commissionRate.retail || ""}
                      onChange={(e) =>
                        handleRateChange(
                          "shared",
                          "commissionRate.retail",
                          e.target.value
                        )
                      }
                    />
                    {/* Agreement Commission Rate */}
                    <TextField
                      label="Agreement Commission Rate"
                      type="text"
                      fullWidth
                      margin="dense"
                      value={rates.shared?.commissionRate.agreement || ""}
                      onChange={(e) =>
                        handleRateChange(
                          "shared",
                          "commissionRate.agreement",
                          e.target.value
                        )
                      }
                    />
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          ) : (
            selectedLocations.map((location) => (
              <Grid item xs={12} sm={6} key={`${location}-rates`}>
                <Card>
                  <CardContent>
                    <Typography variant="h6">
                      {location} - Staff Rates
                    </Typography>

                    {/* Hourly Rate Section */}
                    <TextField
                      label="Hourly Rate"
                      type="number"
                      fullWidth
                      margin="dense"
                      value={rates[location]?.hourly || ""}
                      onChange={(e) =>
                        handleRateChange(location, "hourly", e.target.value)
                      }
                    />

                    {/* Group Rate Section */}
                    <Typography variant="h6">
                      {location} - Group Rate
                    </Typography>
                    <TextField
                      label="Base Rate"
                      type="number"
                      fullWidth
                      margin="dense"
                      value={rates[location]?.groupRate.base || ""}
                      onChange={(e) =>
                        handleRateChange(
                          location,
                          "groupRate.base",
                          e.target.value
                        )
                      }
                    />
                    <TextField
                      label="Per Head Rate"
                      type="number"
                      fullWidth
                      margin="dense"
                      value={rates[location]?.groupRate.perHead || ""}
                      onChange={(e) =>
                        handleRateChange(
                          location,
                          "groupRate.perHead",
                          e.target.value
                        )
                      }
                    />
                    <TextField
                      label="Flat Rate"
                      type="number"
                      fullWidth
                      margin="dense"
                      value={rates[location]?.groupRate.flat || ""}
                      onChange={(e) =>
                        handleRateChange(
                          location,
                          "groupRate.flat",
                          e.target.value
                        )
                      }
                    />
                    <TextField
                      label="After Attendee"
                      type="number"
                      fullWidth
                      margin="dense"
                      value={rates[location]?.groupRate.afterAttendee || ""}
                      onChange={(e) =>
                        handleRateChange(
                          location,
                          "groupRate.afterAttendee",
                          e.target.value
                        )
                      }
                    />
                    <TextField
                      label="Zero Attendee"
                      type="number"
                      fullWidth
                      margin="dense"
                      value={rates[location]?.groupRate.zero || ""}
                      onChange={(e) =>
                        handleRateChange(
                          location,
                          "groupRate.zero",
                          e.target.value
                        )
                      }
                    />

                    {/* Intro Rate Section */}
                    <Typography variant="h6">
                      {location} - Intro Rate
                    </Typography>
                    <TextField
                      label="Base Rate"
                      type="number"
                      fullWidth
                      margin="dense"
                      value={rates[location]?.introRate.base || ""}
                      onChange={(e) =>
                        handleRateChange(
                          location,
                          "introRate.base",
                          e.target.value
                        )
                      }
                    />
                    <TextField
                      label="Per Head"
                      type="number"
                      fullWidth
                      margin="dense"
                      value={rates[location]?.introRate.perHead || ""}
                      onChange={(e) =>
                        handleRateChange(
                          location,
                          "introRate.perHead",
                          e.target.value
                        )
                      }
                    />
                    <TextField
                      label="Flat Rate"
                      type="number"
                      fullWidth
                      margin="dense"
                      value={rates[location]?.introRate.flat || ""}
                      onChange={(e) =>
                        handleRateChange(
                          location,
                          "introRate.flat",
                          e.target.value
                        )
                      }
                    />
                    <TextField
                      label="After Attendee"
                      type="number"
                      fullWidth
                      margin="dense"
                      value={rates[location]?.introRate.afterAttendee || ""}
                      onChange={(e) =>
                        handleRateChange(
                          location,
                          "introRate.afterAttendee",
                          e.target.value
                        )
                      }
                    />
                    <TextField
                      label="Zero Attendee"
                      type="number"
                      fullWidth
                      margin="dense"
                      value={rates[location]?.introRate.zero || ""}
                      onChange={(e) =>
                        handleRateChange(
                          location,
                          "introRate.zero",
                          e.target.value
                        )
                      }
                    />

                    {/* Private Rate Section */}
                    <Typography variant="h6">
                      {location} - Private Rate
                    </Typography>
                    <TextField
                      label="Base Rate"
                      type="number"
                      fullWidth
                      margin="dense"
                      value={rates[location]?.privateRate.base || ""}
                      onChange={(e) =>
                        handleRateChange(
                          location,
                          "privateRate.base",
                          e.target.value
                        )
                      }
                    />
                    <TextField
                      label="Per Head Rate"
                      type="number"
                      fullWidth
                      margin="dense"
                      value={rates[location]?.privateRate.perHead || ""}
                      onChange={(e) =>
                        handleRateChange(
                          location,
                          "privateRate.perHead",
                          e.target.value
                        )
                      }
                    />
                    <TextField
                      label="Flat Rate"
                      type="number"
                      fullWidth
                      margin="dense"
                      value={rates[location]?.privateRate.flat || ""}
                      onChange={(e) =>
                        handleRateChange(
                          location,
                          "privateRate.flat",
                          e.target.value
                        )
                      }
                    />
                    <TextField
                      label="After Attendee"
                      type="number"
                      fullWidth
                      margin="dense"
                      value={rates[location]?.privateRate.afterAttendee || ""}
                      onChange={(e) =>
                        handleRateChange(
                          location,
                          "privateRate.afterAttendee",
                          e.target.value
                        )
                      }
                    />
                    <TextField
                      label="Zero Attendee"
                      type="number"
                      fullWidth
                      margin="dense"
                      value={rates[location]?.privateRate.zero || ""}
                      onChange={(e) =>
                        handleRateChange(
                          location,
                          "privateRate.zero",
                          e.target.value
                        )
                      }
                    />

                    {/* Semi-Private Rate Section */}
                    <Typography variant="h6">
                      {location} - Semi-Private Rate
                    </Typography>
                    <TextField
                      label="Base Rate"
                      type="number"
                      fullWidth
                      margin="dense"
                      value={rates[location]?.semiPrivateRate.base || ""}
                      onChange={(e) =>
                        handleRateChange(
                          location,
                          "semiPrivateRate.base",
                          e.target.value
                        )
                      }
                    />
                    <TextField
                      label="Per Head Rate"
                      type="number"
                      fullWidth
                      margin="dense"
                      value={rates[location]?.semiPrivateRate.perHead || ""}
                      onChange={(e) =>
                        handleRateChange(
                          location,
                          "semiPrivateRate.perHead",
                          e.target.value
                        )
                      }
                    />
                    <TextField
                      label="Flat Rate"
                      type="number"
                      fullWidth
                      margin="dense"
                      value={rates[location]?.semiPrivateRate.flat || ""}
                      onChange={(e) =>
                        handleRateChange(
                          location,
                          "semiPrivateRate.flat",
                          e.target.value
                        )
                      }
                    />
                    <TextField
                      label="After Attendee"
                      type="number"
                      fullWidth
                      margin="dense"
                      value={
                        rates[location]?.semiPrivateRate.afterAttendee || ""
                      }
                      onChange={(e) =>
                        handleRateChange(
                          location,
                          "semiPrivateRate.afterAttendee",
                          e.target.value
                        )
                      }
                    />
                    <TextField
                      label="Zero Attendee"
                      type="number"
                      fullWidth
                      margin="dense"
                      value={rates[location]?.semiPrivateRate.zero || ""}
                      onChange={(e) =>
                        handleRateChange(
                          location,
                          "semiPrivateRate.zero",
                          e.target.value
                        )
                      }
                    />

                    {/* 30 Min Private Rate Section */}
                    <Typography variant="h6">
                      {location} - 30 Min Private Rate
                    </Typography>
                    <TextField
                      label="Base Rate"
                      type="number"
                      fullWidth
                      margin="dense"
                      value={rates[location]?.thirtyMinPrivateRate.base || ""}
                      onChange={(e) =>
                        handleRateChange(
                          location,
                          "thirtyMinPrivateRate.base",
                          e.target.value
                        )
                      }
                    />
                    <TextField
                      label="Per Head Rate"
                      type="number"
                      fullWidth
                      margin="dense"
                      value={
                        rates[location]?.thirtyMinPrivateRate.perHead || ""
                      }
                      onChange={(e) =>
                        handleRateChange(
                          location,
                          "thirtyMinPrivateRate.perHead",
                          e.target.value
                        )
                      }
                    />
                    <TextField
                      label="Flat Rate"
                      type="number"
                      fullWidth
                      margin="dense"
                      value={rates[location]?.thirtyMinPrivateRate.flat || ""}
                      onChange={(e) =>
                        handleRateChange(
                          location,
                          "thirtyMinPrivateRate.flat",
                          e.target.value
                        )
                      }
                    />
                    <TextField
                      label="After Attendee"
                      type="number"
                      fullWidth
                      margin="dense"
                      value={
                        rates[location]?.thirtyMinPrivateRate.afterAttendee ||
                        ""
                      }
                      onChange={(e) =>
                        handleRateChange(
                          location,
                          "thirtyMinPrivateRate.afterAttendee",
                          e.target.value
                        )
                      }
                    />
                    <TextField
                      label="Zero Attendee"
                      type="number"
                      fullWidth
                      margin="dense"
                      value={rates[location]?.thirtyMinPrivateRate.zero || ""}
                      onChange={(e) =>
                        handleRateChange(
                          location,
                          "thirtyMinPrivateRate.zero",
                          e.target.value
                        )
                      }
                    />

                    {/* Sales Commission Section */}
                    <Typography variant="h6">
                      {location} - Sales Commission
                    </Typography>
                    <TextField
                      label="Retail Commission Rate"
                      type="text"
                      fullWidth
                      margin="dense"
                      value={rates[location]?.commissionRate.retail || ""}
                      onChange={(e) =>
                        handleRateChange(
                          location,
                          "commissionRate.retail",
                          e.target.value
                        )
                      }
                    />
                    <TextField
                      label="Agreement Commission Rate"
                      type="text"
                      fullWidth
                      margin="dense"
                      value={rates[location]?.commissionRate.agreement || ""}
                      onChange={(e) =>
                        handleRateChange(
                          location,
                          "commissionRate.agreement",
                          e.target.value
                        )
                      }
                    />
                  </CardContent>
                </Card>
              </Grid>
            ))
          )}
        </Grid>
      </div>
      <DialogActions className="add-staff-component__actions">
        <Button onClick={() => onClose(staff)}>Cancel</Button>

        <Button variant="contained" onClick={handleOnSave}>
          Confirm
        </Button>
      </DialogActions>
    </div>
  );
};

export default EditStaffComponent;
