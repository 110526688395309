import React, { useState } from "react";
import { Skeleton } from "@mui/material";
import Box from "@mui/material/Box";
import ResponsiveAppBar from "../../components/AppBar/ResponsiveAppBar";
import "./staffEmailer.scss";
import StaffEmailerDropzone from "./staffEmailerDropzone";
import Button from "@mui/material/Button";
import EmailCard from "../../components/EmailCard";
import SendRoundedIcon from "@mui/icons-material/SendRounded";
import CustomizedSnackbars from "../../components/Snackbar";
import CircularIntegration from "../../components/LoadingButton";
import { Typography } from "@mui/material";
import { API } from "aws-amplify";
import { useHistory } from "react-router-dom";
import { useSettingsContext } from "../../contexts/SettingsContext";
import { useSnackBar } from "../../contexts/SnackBarContext/SnackBarContext";
import { useAuth } from "../../contexts/AuthContext";
export default function StaffEmailer(props) {
  const {getFirebaseJwtToken} = useAuth();
  const showSnackBar = useSnackBar();
  const history = useHistory();
  const { settings, dispatch, initSettingsPresets } = useSettingsContext();

  const [tables, setTables] = useState([]);
  const [tableHeaders, setTableHeaders] = useState([]);
  const [emailCards, setEmailCards] = useState([]);
  const [emails, setEmails] = useState([]);
  const [sendAllEmailButtonDisabled, setSendAllEmailButtonDisabled] = useState(
    false
  );
  const [isEmailsLoaded, setIsEmailsLoaded] = useState(false);
  const emailSkeleton = [...Array(8)].map((i) => (
    <Skeleton
      variant="rectangular"
      style={{ width: "100%", borderRadius: ".2em", marginTop: ".5em" }}
      height={115}
      key={i}
    />
  ));

  const addEmailCard = (newEmail) => {
    setEmailCards((emailCards) => {
      return emailCards.concat(newEmail);
    });
  };
  const addEmail = (newEmail, to, cc, subject) => {
    return setEmails((emails) =>
      emails.concat({ email: newEmail, to: to, cc: cc, subject: subject })
    );
  };
  const sendEmail = async (email) => {
    const token = await getFirebaseJwtToken();

    const myAPI = "paywellAPIResource";
    const path = "/email";
    const event = {
      headers: {
        'Authorization': `Bearer ${token}`
      },
      body: {
        to: email.to,
        cc: email.cc,
        subject: email.subject,
        html: email.email,
      },
    };
    let res;
    await API.post(myAPI, path, event)
      .then((response) => {
        response.success === true
          ? showSnackBar("Email sent to " + email.to, "success")
          : showSnackBar("Failed sending email to " + email.to, "error");
        res = response;
      })
      .catch((error) => {
        console.error(error);
        res = error;
        showSnackBar("Failed sending email to " + email.to, "error");
      });
    return res;
  };
  const sendAllEmails = async (emails) => {
    emails.forEach((email) => {
      sendEmail(email);
    });
    setSendAllEmailButtonDisabled(true);
    setTimeout(function() {
      setSendAllEmailButtonDisabled(false);
    }, 30000);
  };

  // function checkForErrors() {

  //   (<><div style={{ marginTop: "50px" }}>No staff emails found. Please add emails for your staff in staff settings.</div>
  //                 <Button
  //                   style={{ margin: "10px 16px", paddingLeft: "8px" }}
  //                   onClick={() =>
  //                     history.push({
  //                       pathname: "studio-setting/staff",
  //                       search: location.search, // this includes the current query parameters
  //                     })
  //                   }
  //                 // startIcon={<ArrowBackIosRoundedIcon className="icon" />}
  //                 >
  //                   Staff Settings
  //                 </Button></>)
  // }

  return (
    <>
      <ResponsiveAppBar hideMenu={true} showUser={true} />
      <Box className="page">
        <Box className="staff-emailer">
          <Typography variant="h2">Email Staff Paystubs</Typography>
          <Typography variant="subtitle1">
            Upload your PayWell output file, review staff paystubs, and send!
          </Typography>
          {settings.hasOwnProperty("1") && settings.hasOwnProperty("staff") ? (
            <>
              <StaffEmailerDropzone
                setIsEmailsLoaded={setIsEmailsLoaded}
                setEmailCards={setEmailCards}
                tables={tables}
                setTables={setTables}
                tableHeaders={tableHeaders}
                setTableHeaders={setTableHeaders}
                handleAddEmailCard={addEmailCard}
                emails={emails}
                addEmail={addEmail}
                type={"export"}
              />

              {isEmailsLoaded ? (
                emailCards.length < 1 ? (
                  <>
                    <div style={{ marginTop: "50px" }}>
                      No staff emails found. Please add emails for your staff in
                      staff settings.
                    </div>
                    <Button
                      style={{ margin: "10px 16px", paddingLeft: "8px" }}
                      onClick={() =>
                        history.push({
                          pathname: "studio-setting/staff",
                          search: location.search, // this includes the current query parameters
                        })
                      }
                      // startIcon={<ArrowBackIosRoundedIcon className="icon" />}
                    >
                      Staff Settings
                    </Button>
                  </>
                ) : (
                  emailCards.map((email, i) => {
                    return (
                      <EmailCard
                        key={i}
                        title={email.headers[2] ? email.headers[2] : null}
                        subtitle={email.headers[0] ? email.headers[0] : null}
                        staffEmail={email.staffEmail}
                        sendAllButton={
                          <Button
                            variant="outlined"
                            startIcon={<SendRoundedIcon></SendRoundedIcon>}
                            disabled={sendAllEmailButtonDisabled}
                            onClick={() => sendAllEmails(emails)}
                          >
                            Send All
                          </Button>
                        }
                        sendEmailButton={
                          <CircularIntegration
                            sendEmail={() => sendEmail(emails[i])}
                            size="small"
                          >
                            Send
                          </CircularIntegration>
                        }
                        tables={email.tables}
                      ></EmailCard>
                    );
                  })
                )
              ) : null}
            </>
          ) : (
            <Skeleton className="staff-emailer__skeleton"></Skeleton>
          )}

          {/* {isEmailsLoaded ? null : emailSkeleton} */}
        </Box>
      </Box>
    </>
  );
}
