import * as React from "react";
import Stack from "@mui/material/Stack";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
// 1. Define the SnackBarContext and its associated provider

const SnackBarContext = React.createContext();

export function useSnackBar() {
  return React.useContext(SnackBarContext);
}

export function SnackBarProvider({ children }) {
  const [snackBarConfig, setSnackBarConfig] = React.useState({
    open: false,
    message: "",
    severity: "success",
  });

  const showSnackBar = (message, severity = "success") => {
    setSnackBarConfig({ open: true, message, severity });
  };

  const hideSnackBar = () => {
    setSnackBarConfig((prev) => ({ ...prev, open: false }));
  };

  return (
    <SnackBarContext.Provider value={showSnackBar}>
      {children}
      <CustomizedSnackbars
        open={snackBarConfig.open}
        message={snackBarConfig.message}
        severity={snackBarConfig.severity}
        onClose={hideSnackBar}
      />
    </SnackBarContext.Provider>
  );
}

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function CustomizedSnackbars({ open, message, severity, onClose }) {
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    onClose();
  };

  return (
    <Stack position={"absolute"} spacing={2} sx={{ width: "100%" }}>
      <Snackbar
        id="snackbar"
        data-severity={severity}
        open={open}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity={severity} sx={{ width: "100%" }}>
          {message}
        </Alert>
      </Snackbar>
    </Stack>
  );
}

// Usage example:

function App() {
  const showSnackBar = useSnackBar();

  return (
    <div>
      <button onClick={() => showSnackBar("This is an error message", "error")}>
        Show Error
      </button>
    </div>
  );
}

export default App;
