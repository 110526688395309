import React, { useState, useEffect } from 'react';
import { Box, Button, Select, MenuItem, FormControl, InputLabel, Checkbox, FormControlLabel, Typography } from '@mui/material';

const RateConstructor = ({ onAddRate, currentRates }) => {
  const [rateType, setRateType] = useState('');
  const [reactivation, setReactivation] = useState(false);
  const [conversion, setConversion] = useState(false);
  const [tierEnabled, setTierEnabled] = useState(false);
  const [tier, setTier] = useState(0);
  const [stepEnabled, setStepEnabled] = useState(false);
  const [step, setStep] = useState(0);
  const [addEnabled, setAddEnabled] = useState(false);

  // Update the constructed rate and check if it's already included in currentRates
  useEffect(() => {
    let rate = rateType;
    if (reactivation) rate += " Reactivation";
    if (conversion) rate += " Conversion";
    if (stepEnabled) rate += ` Step${step}`;
    if (tierEnabled) rate += ` Tier${tier}`;

    const isRateExisting = currentRates.includes(rate);
    setAddEnabled(!isRateExisting && rateType !== '');
  }, [rateType, reactivation, conversion, tierEnabled, tier, stepEnabled, step, currentRates]);

  const handleAddRate = () => {
    let rate = rateType;
    if (reactivation) rate += " Reactivation";
    if (conversion) rate += " Conversion";
    if (stepEnabled) rate += ` Step${step}`;
    if (tierEnabled) rate += ` Tier${tier}`;

    onAddRate(rate);
    setRateType('');
    setReactivation(false);
    setConversion(false);
    setTierEnabled(false);
    setTier(0);
    setStepEnabled(false);
    setStep(0);
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 2, flexWrap: 'wrap', marginTop: 2 }}>
      <FormControl sx={{ minWidth: 120 }}>
        <InputLabel>Rate Type</InputLabel>
        <Select
          value={rateType}
          onChange={(e) => setRateType(e.target.value)}
          label="Rate Type"
        >
          {["New", "Upgrade", "Downgrade", "Renewal", "N/A"].map((type) => (
            <MenuItem key={type} value={type}>
              {type}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <FormControlLabel
        control={<Checkbox checked={reactivation} onChange={(e) => setReactivation(e.target.checked)} />}
        label="Reactivation"
      />

      <FormControlLabel
        control={<Checkbox checked={conversion} onChange={(e) => setConversion(e.target.checked)} />}
        label="Conversion"
      />

      <FormControlLabel
        control={<Checkbox checked={tierEnabled} onChange={(e) => setTierEnabled(e.target.checked)} />}
        label="Tier"
      />

      {tierEnabled && (
        <FormControl sx={{ minWidth: 60 }}>
          <InputLabel>Tier</InputLabel>
          <Select
            value={tier}
            onChange={(e) => setTier(parseInt(e.target.value))}
            label="Tier"
          >
            {Array.from({ length: 5 }, (_, i) => i).map((tier) => (
              <MenuItem key={tier} value={tier}>
                {tier}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}

      <FormControlLabel
        control={<Checkbox checked={stepEnabled} onChange={(e) => setStepEnabled(e.target.checked)} />}
        label="Step"
      />

      {stepEnabled && (
        <FormControl sx={{ minWidth: 60 }}>
          <InputLabel>Step</InputLabel>
          <Select
            value={step}
            onChange={(e) => setStep(parseInt(e.target.value))}
            label="Step"
          >
            {Array.from({ length: 9 }, (_, i) => i - 4).map((step) => (
              <MenuItem key={step} value={step}>
                {step}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}

      <Button variant="contained" onClick={handleAddRate} disabled={!addEnabled}>
        Add Rate
      </Button>
    </Box>
  );
};

export default RateConstructor;
