import React, { useState } from "react";
import { Box, Tabs, Tab, Typography, Divider } from "@mui/material";
import { useSettingsContext } from "../../../contexts/SettingsContext";
import SelectorBox from "../../Components/SelectorBox/SelectorBox";
import InputBoxElement from "../../Components/InputBox/InputBox";
import BooleanToggleElement from "../../Components/BooleanToggle";
import { RevertList } from "../../Components/RevertList";
import { useAuth } from "../../../contexts/AuthContext";
import { getUrlVariableValue } from "../../../utility-functions/utility-functions";
import { Skeleton } from "@mui/material";
import AttributeListBox from "../../Components/AttributeListBox";

// Tab properties helper function
function tabProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

// General settings form component
function GeneralTimeSettings() {
  const { getUID } = useAuth();
  const { settings, dispatch, changes, setChanges } = useSettingsContext();

  const handleUpdate = (value, id) => {
    let newRev = new RevertList(changes.getValues());
    newRev.addElement(
      id,
      settings[getUrlVariableValue("settingsId")].timeSettings.general[id],
      value
    );
    setChanges(newRev);

    dispatch({
      type: "UPDATE_GENERAL_TIME_SETTINGS_WITH_KEY",
      settingsId: getUrlVariableValue("settingsId"),
      uid: getUID(),
      key: id,
      value: value,
    });
  };

  return (
    <Box>
    <Typography>
           {"Which " +
            settings[getUrlVariableValue("settingsId")].generalSettings
              .timeName +
            " clock file(s) do you use:"}
        </Typography>
        <SelectorBox
          className="selector-box__select"
          id="useTimeClock"
          onChange={handleUpdate}
          default={
            settings[getUrlVariableValue("settingsId")].timeSettings.general
              .useTimeClock
          }
          size={350}
          options={[
            "ClubReady Time Clock Summary",
            "ClubReady Time Punch Detail",
            "WhenIWork Timesheet",
            "WhenIWork Detail Timesheet",
            "Sling Timesheet",
            "Sling Basic Timesheet",
            "Sling Advanced Timesheet",
            "Prospr Timesheet",
            "Gusto Timesheet",
            "Paycom Timesheet",
            "Deputy Timesheet",
            "Deputy Detail Timesheet"
          ]}
          placeholder="None"
          label="Time File"
          name="Time File"
        ></SelectorBox>
        
        <Divider className="settings-page__divider settings-page__divider--light" />
        <Typography>
          Display Punch Detail in Staff Tabs (if applicable)
        </Typography>
        <BooleanToggleElement
          onChange={handleUpdate}
          default={
            settings[getUrlVariableValue("settingsId")].timeSettings.general
              .punchDetailInStaffTabs
          }
          id="punchDetailInStaffTabs"
        ></BooleanToggleElement>

        <Divider className="settings-page__divider settings-page__divider--light" />
        <Typography>
          Use Complex Time Clock Settings:
        </Typography>
        <BooleanToggleElement
          onChange={handleUpdate}
          default={
            settings[getUrlVariableValue("settingsId")].timeSettings.general
              .useComplexTimeSettings || false
          }
          id="useComplexTimeSettings"
        ></BooleanToggleElement>

        {settings[getUrlVariableValue("settingsId")].timeSettings.general.useComplexTimeSettings && (
          <>

        <Divider className="settings-page__divider settings-page__divider--light" />
        <Typography>
          Does the system automatically clock out staff at a certain time?
        </Typography>
        <InputBoxElement
            id="systemClockOutTime"
            default={
              settings[getUrlVariableValue("settingsId")].timeSettings.general?.systemClockOutTime 
              ? settings[getUrlVariableValue("settingsId")].timeSettings.general?.systemClockOutTime.includes("text~") ? settings[getUrlVariableValue("settingsId")].timeSettings.general?.systemClockOutTime : "text~" + settings[getUrlVariableValue("settingsId")].timeSettings.general?.systemClockOutTime 
              : "text~"
            }
            onChange={handleUpdate}
            label="System Clock Out Time"
            types={["text"]}
          />

        <Divider className="settings-page__divider settings-page__divider--light" />
        <Typography>
          Do you require meal breaks? If so, what is the minimum time required (minutes):
        </Typography>
        <InputBoxElement
            id="mealBreakFlag"
            default={
              settings[getUrlVariableValue("settingsId")].timeSettings.general?.mealBreakFlag || "hour~-1"
            }
            onChange={handleUpdate}
            label="Meal Break Violation"
            types={["time"]}
          />

        <Divider className="settings-page__divider settings-page__divider--light" />
        <Typography>
          Do you want to calcuate WEEKLY overtime hours? If so, how many hours do they need to exceed:
        </Typography>
        <InputBoxElement
            id="weeklyOvertime"
            default={
              settings[getUrlVariableValue("settingsId")].timeSettings.general?.weeklyOvertime || "hour~-1"
            }
            onChange={handleUpdate}
            label="Weekly Overtime"
            types={["time"]}
          />

        <Divider className="settings-page__divider settings-page__divider--light" />
        <Typography>
          Do you want to calcuate DAILY overtime hours? If so, how many hours do they need to exceed:
        </Typography>
        <InputBoxElement
            id="dailyOvertime"
            default={
              settings[getUrlVariableValue("settingsId")].timeSettings.general?.dailyOvertime || "hour~-1"
            }
            onChange={handleUpdate}
            label="Daily Overtime"
            types={["time"]}
          />

        <Divider className="settings-page__divider settings-page__divider--light" />
        <Typography>
          Do you want to calcuate DAILY double overtime hours? If so, how many hours do they need to exceed:
        </Typography>
        <InputBoxElement
            id="dailyDoubleOvertime"
            default={
              settings[getUrlVariableValue("settingsId")].timeSettings.general?.dailyDoubleOvertime || "hour~-1"
            }
            onChange={handleUpdate}
            label="Daily Double Overtime"
            types={["time"]}
          />

        <Divider className="settings-page__divider settings-page__divider--light" />
        <Typography>
          Do you want to calcuate CONSECUTIVE overtime hours? If so, how many hours do they need to work up to on the 7th day:
        </Typography>
        <InputBoxElement
            id="consecutiveOvertime"
            default={
              settings[getUrlVariableValue("settingsId")].timeSettings.general?.consecutiveOvertime || "hour~-1"
            }
            onChange={handleUpdate}
            label="Consecutive Overtime"
            types={["time"]}
          />

        <Divider className="settings-page__divider settings-page__divider--light" />
        <Typography>
          What is your overtime rate:
        </Typography>
        <SelectorBox
          className="selector-box__select"
          id="overtimeRate"
          onChange={handleUpdate}
          default={
            settings[getUrlVariableValue("settingsId")].timeSettings.general.overtimeRate
          }
          size={350}
          options={[
            '1.5x Hourly Rate',
            '1.5x Regular Rate',
            'Overtime Rate',
          ]}
          placeholder="None"
          label="Overtime Rate"
          name="Overtime Rate"
        ></SelectorBox>

        <Divider className="settings-page__divider settings-page__divider--light" />
        <Typography>
          What is your double overtime rate:
        </Typography>
        <SelectorBox
          className="selector-box__select"
          id="doubleOvertimeRate"
          onChange={handleUpdate}
          default={
            settings[getUrlVariableValue("settingsId")].timeSettings.general.doubleOvertimeRate
          }
          size={350}
          options={[
            'None',
            '2x Hourly Rate',
            '2x Regular Rate',
            'Double Overtime Rate',
          ]}
          placeholder="None"
          label="Double Overtime Rate"
          name="Double Overtime Rate"
        ></SelectorBox>

        <Divider className="settings-page__divider settings-page__divider--light" />
        <Typography>
          What is your holiday rate:
        </Typography>
        <SelectorBox
          className="selector-box__select"
          id="holidayRate"
          onChange={handleUpdate}
          default={
            settings[getUrlVariableValue("settingsId")].timeSettings.general.holidayRate
          }
          size={350}
          options={[
            'None',
            '1.5x Hourly Rate',
            '1.5x Regular Rate',
            'Overtime Rate',
            '2x Hourly Rate',
            '2x Regular Rate',
            'Holiday Rate',
          ]}
          placeholder="None"
          label="Holiday Rate"
          name="Holiday Rate"
        ></SelectorBox>

        <Divider className="settings-page__divider settings-page__divider--light" />
        <Typography>
          Add Overtime Premium Adjustment
        </Typography>
        <BooleanToggleElement
          onChange={handleUpdate}
          default={
            settings[getUrlVariableValue("settingsId")].timeSettings.general
              .addOvertimePremiumAmount
          }
          id="addOvertimePremiumAmount"
        ></BooleanToggleElement>

        <Divider className="settings-page__divider settings-page__divider--light" />
        <Typography>
          What days are considered holidays:
        </Typography>
        <AttributeListBox
          freeSolo
          multiple={true}
          limitTags={20}
          onChange={handleUpdate}
          id="holidaysObserved"
          default={
            settings[getUrlVariableValue("settingsId")].timeSettings.general
              .holidaysObserved || []
          }
          size={400}
          label="Holidays Observed"
          options={[
            "New Year's Day",
            "Martin Luther King Jr. Day",
            "Presidents' Day",
            "Memorial Day",
            "Independence Day",
            "Labor Day",
            "Columbus Day",
            "Veterans Day",
            "Thanksgiving Day",
            "Christmas Day",
            "Family Day",
            "Good Friday",
            "Victoria Day",
            "Canada Day",
            "Civic Holiday",
            "Labour Day",
            "Thanksgiving (Canada)",
            "Boxing Day",
            "Constitution Day (Mexico)",
            "Benito Juárez's Birthday",
            "Revolution Day",
            "Easter Monday",
            "May Day / Labor Day",
            "Ascension Day",
            "Whit Monday",
            "National Day (varies by country)",
            "Assumption Day",
            "All Saints' Day",
            "Boxing Day / St. Stephen's Day"
          ]}
        ></AttributeListBox>
        </>)}
    </Box>
  );
}

function TimeBucketBox({ bucketName }) {
  const { getUID } = useAuth();
  const { settings, dispatch, changes, setChanges } = useSettingsContext();

  const handleBucketUpdate = (value, id) => {
    let newRev = new RevertList(changes.getValues());
    newRev.addElement(
      bucketName + "-" + id,
      settings[getUrlVariableValue("settingsId")].timeSettings.timeBuckets[bucketName][id],
      value
    );
    setChanges(newRev);

    dispatch({
      type: "UPDATE_BUCKET_TIME_SETTINGS_WITH_KEY",
      settingsId: getUrlVariableValue("settingsId"),
      uid: getUID(),
      timeBucket: bucketName,
      key: id,
      value: value,
    });
  };

  // Get the current bucket's type
  const bucketType = settings[getUrlVariableValue("settingsId")].timeSettings.timeBuckets[bucketName].type;

  return (
    <Box>
      {/* Always display the Type selector */}
      <Typography>Type:</Typography>
      <SelectorBox
        key={bucketName + "-type"}
        className="selector-box__select"
        id="type"
        onChange={handleBucketUpdate}
        default={bucketType}
        size={350}
        options={['Hourly', 'Earning']}
        placeholder="None"
        label="Type"
        name="Type"
      />
      <Divider className="settings-page__divider settings-page__divider--light" />

      {/* Only show other fields if type is not 'Earning' */}
      {bucketType !== 'Earning' && (
        <>
          <Typography className="settings-page__text">Time Names:</Typography>
          <AttributeListBox
            key={bucketName + "-names"}
            multiple={true}
            freeSolo
            limitTags={20}
            onChange={handleBucketUpdate}
            id="names"
            default={settings[getUrlVariableValue("settingsId")].timeSettings.timeBuckets[bucketName].names}
            size={400}
            label="Time Names"
            options={[]}
          />
          <Divider className="settings-page__divider settings-page__divider--light" />

          <Typography>Standard Type:</Typography>
          <BooleanToggleElement
            key={bucketName + "-isStandardTimeType"}
            id="isStandardTimeType"
            default={settings[getUrlVariableValue("settingsId")].timeSettings.timeBuckets[bucketName].isStandardTimeType}
            onChange={handleBucketUpdate}
            label="Is Standard Time Type"
          />
          <Divider className="settings-page__divider settings-page__divider--light" />

          {/* <Typography>Rounded:</Typography>
          <SelectorBox
            key={bucketName + "-rounded"}
            className="selector-box__select"
            id="rounded"
            onChange={handleBucketUpdate}
            default={settings[getUrlVariableValue("settingsId")].timeSettings.timeBuckets[bucketName].rounded}
            size={350}
            options={['None', 'Up', 'Down', 'Nearest 10th', 'Nearest 100th']}
            placeholder="None"
            label="Rounded"
            name="Rounded"
          /> */}
        </>
      )}

      {/* Only show other fields if type is 'Earning' */}
      {bucketType === 'Earning' && (
        <>
          <Divider className="settings-page__divider settings-page__divider--light" />

          <Typography>Frequency:</Typography>
          <SelectorBox
            key={bucketName + "-earningFrequency"}
            className="selector-box__select"
            id="frequency"
            onChange={handleBucketUpdate}
            default={settings[getUrlVariableValue("settingsId")].timeSettings.timeBuckets[bucketName].frequency}
            size={350}
            // options={['Yearly', 'Monthly', "Pay Period"]}
            options={["Pay Period"]}
            placeholder="None"
            label="Earning Frequency"
            name="Earning Frequency"
          />
          <Divider className="settings-page__divider settings-page__divider--light" />
        </>
      )}

      {/* <Divider className="settings-page__divider settings-page__divider--light" /> */}

      {/* <Typography>Overtime Applicable:</Typography>
      <BooleanToggleElement
        key={bucketName + "-overtimeApplicable"}
        id="overtimeApplicable"
        default={settings[getUrlVariableValue("settingsId")].timeSettings.timeBuckets[bucketName].overtimeApplicable}
        onChange={handleBucketUpdate}
        label="Overtime Applicable"
      /> */}
    </Box>
  );
}

// Main time settings component with tabs
export default function TimeSettings() {
  const { settings } = useSettingsContext();
  const [tab, setTab] = useState("General");
  const [tabIndex, setTabIndex] = useState(0);

  const handleTabChange = (event, newIndex) => {
    const bucketKeys = Object.keys(settings[getUrlVariableValue("settingsId")].timeSettings.timeBuckets).sort();
    setTab(newIndex === 0 ? "General" : bucketKeys[newIndex - 1]);
    setTabIndex(newIndex);
  };

  return settings.hasOwnProperty("1") && settings.hasOwnProperty("staff") ? (
    <Box>
      <Typography variant="h2">Time Settings</Typography>

      <Tabs className="settings-page__tabs" value={tabIndex} onChange={handleTabChange} centered>
        <Tab label="General" {...tabProps(0)} className={`settings-page__tab `}/>
        {Object.keys(settings[getUrlVariableValue("settingsId")].timeSettings.timeBuckets)
          .sort()
          .map((bucketName, index) => (
            <Tab label={bucketName} key={bucketName} {...tabProps(index + 1)} className={`settings-page__tab `} />
          ))}
      </Tabs>

      {tab === "General" ? <GeneralTimeSettings /> : <TimeBucketBox bucketName={tab} />}
    </Box>
  ) : (
    <Box>
      <Skeleton>
        <Typography variant="h2">Loading Time Settings...</Typography>
      </Skeleton>
    </Box>
  );
}
