import React from "react";
import { Autocomplete, TextField } from "@mui/material";

const StaffAutocomplete = ({ allStaff, selectedStaff, onSelect }) => {
  const staffArray = Object.values(allStaff);
  const handleOnChange = (event, newValue) => {
    onSelect(newValue);
  };
 
  return (
    <Autocomplete
      id="staff-search-autocomplete"
      options={staffArray}
      getOptionLabel={(option) => option.name || ""}
      value={selectedStaff}
      onChange={handleOnChange}
      renderInput={(params) => (
        <TextField {...params} label="Select Staff" variant="outlined" />
      )}
      isOptionEqualToValue={(option, value) => option.id === value.id}
    />
  );
};

export default StaffAutocomplete;
