import React, { useState } from "react";
import {
  Typography,
  IconButton,
  Tooltip,
} from "@mui/material";
import PersonAddRoundedIcon from "@mui/icons-material/PersonAddRounded";
import EditIcon from "@mui/icons-material/Edit";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import "../CheckboxList/checkboxList.scss";
import ActionMenu from "./EditStaffActionMenu";
import AddStaffDialog from "../StaffProfile/AddStaffDialog";
import MergeStaffDialog from "../StaffProfile/MergeStaffDialog";
import { Add } from "@mui/icons-material";
import { useAuth } from "../../contexts/AuthContext";
import { useSettingsContext } from "../../contexts/SettingsContext";
import AddClassDialog from "../StaffProfile/AddClassDialog";
import EditStaffDialog from "../StaffProfile/EditStaffDialogSharedToggles";
import { useStaffContext } from "../../contexts/StaffContext/StaffContext";
const StaffList = ({ title, list }) => {
  const { resetEditableStaff } = useStaffContext();

  const [dialogOpen, setDialogOpen] = useState(false);
  const [currentStaff, setCurrentStaff] = useState({});
  const [dialogMode, setDialogMode] = useState(""); // 'add' or 'merge'
  const { settings, dispatch, reportCompilerDispatch } = useSettingsContext();
  const { getUID } = useAuth();

  const handleEditClick = (staff, option) => {
    setCurrentStaff(staff);
    setDialogMode(option);
    setDialogOpen(true);
  };
  const handleEditStaffDialogSave = (staff) => {
    setDialogOpen(false);
    setDialogMode("");
  };
  const handleEditStaffDialogClose = (staff) => {
    setDialogOpen(false);
    setDialogMode("");
  };
  const handleDuplicateStaffDialogSave = (staff) => {
    setDialogOpen(false);
    setDialogMode("");
  };
  const handleDuplicateStaffDialogClose = (staff) => {
    setDialogOpen(false);
    setDialogMode("");
  };
  const handleAddStaffDialogSave = (staff) => {
    setDialogOpen(false);
    setDialogMode("");
    // reportCompilerDispatch({ type: "BUILD_REPORT_COMPILER_STATE_STAFF" });
  };
  const handleAddStaffDialogClose = (staff) => {
    setDialogOpen(false);
    setDialogMode("");
    // use revert list to undo changes
    resetEditableStaff();

   
  };
  const handleMergeStaffDialogSave = (staff) => {
    setDialogOpen(false);
    setDialogMode("");
    // use revert list to undo changes
  };
  const handleMergeStaffDialogClose = (staff) => {
    setDialogOpen(false);
    setDialogMode("");
    // use revert list to undo changes
  };
  const handleAddClassDialogClose = (staff) => {
    setDialogOpen(false);
    setDialogMode("");
    // use revert list to undo changes
  };
  const handleAddClassDialogSave = () => {
    setDialogOpen(false);
    setDialogMode("");

  };

  return (
    <>
      <Typography variant="h6">{title}</Typography>

      <List className="list" sx={{ width: "100%" }}>
        {list.map((listItem, i) => {
          const labelId = `checkbox-list-secondary-label-${i}`;

          var ActionComponent;
          if (listItem.errorType == "unaddedClass") {
            ActionComponent = (
              <Tooltip title={"Add Class"}>
                <IconButton
                  variant="text"
                  color="primary"
                  onClick={() => handleEditClick(listItem, "addClass")}
                  staff={listItem}
                  label="Add"
                  size="large"
                >
                  <Add />
                </IconButton>
              </Tooltip>
            );
          } else if (listItem.errorType == "wrongStaffType") {
            ActionComponent = (
              <Tooltip title={"Edit Staff Type"}>
                <IconButton
                  aria-label="Edit"
                  variant="text"
                  color="primary"
                  onClick={() => handleEditClick(listItem, "merge")}
                  staff={listItem}
                  label="Edit"
                  size="large"
                >
                  <EditIcon />
                </IconButton>
              </Tooltip>
            );
          } else if (listItem.errorType == "unaddedStaff") {
            ActionComponent = (
              <ActionMenu
                errorType={listItem.errorType}
                handleEditClick={handleEditClick}
                staff={listItem}
                label="Add Staff"
                iconButton={true}
                icon={<PersonAddRoundedIcon />}
              />
            );
          } else if (listItem.errorType == "unaddedAlternateName") {
            // ActionComponent = (
            //   <Tooltip title={"Edit Staff"}>
            //     <IconButton
            //       variant="text"
            //       color="primary"
            //       aria-label="Edit"
            //       onClick={() => handleEditClick(listItem, "merge")}
            //       staff={listItem}
            //       label="Edit"
            //       size="large"
            //     >
            //       <EditIcon />
            //     </IconButton>
            //   </Tooltip>
            // );
            ActionComponent = (
              <ActionMenu
                errorType={listItem.errorType}
                handleEditClick={handleEditClick}
                staff={listItem}
                label="Edit"
                iconButton={true}
                icon={<EditIcon />}
              />
            );
          } else if (listItem.errorType == "unaddedLocation") {
            ActionComponent = ActionComponent = (
              <ActionMenu
                errorType={listItem.errorType}
                handleEditClick={handleEditClick}
                staff={listItem}
                label="Add Staff"
                iconButton={true}
                icon={<PersonAddRoundedIcon />}
              />
            );
            // ActionComponent = (
            //   <>
            //     <Tooltip title={"Edit Staff"}>
            //       <Button
            //         variant="text"
            //         color="primary"
            //         aria-label="Edit"
            //         onClick={() => handleEditClick(listItem, "edit")}
            //         staff={listItem}
            //         label="Edit"
            //         size="large"
            //         startIcon={<EditIcon />}
            //       >
            //         {/* <EditIcon /> */}Edit Staff
            //       </Button>
            //     </Tooltip>
            //     <Tooltip title={"Edit Staff"}>
            //       <IconButton
            //         variant="text"
            //         color="primary"
            //         aria-label="Edit"
            //         onClick={() => handleEditClick(listItem, "merge")}
            //         staff={listItem}
            //         label="Edit"
            //         size="large"
            //       >
            //         <EditIcon />
            //       </IconButton>
            //     </Tooltip>
            //   </>
            // );
          }

          return (
            <ListItem className="list__item" key={labelId}>
              <ListItemAvatar>
                <Avatar alt={`Avatar n°${i}`} />
              </ListItemAvatar>
              <ListItemText
                id={labelId}
                primary={
                  <span className="list__item-text">{listItem.label}</span>
                }
              />
              {ActionComponent}
            </ListItem>
          );
        })}
      </List>
      {dialogMode === "edit" && (
        <EditStaffDialog
          open={dialogOpen}
          onClose={handleEditStaffDialogClose}
          onSave={handleEditStaffDialogSave}
          staff={currentStaff}
        />
      )}

      {dialogMode === "add" && (
        <AddStaffDialog
          open={dialogOpen}
          onClose={handleAddStaffDialogClose}
          onSave={handleAddStaffDialogSave}
          staff={currentStaff}
        />
      )}
      {dialogMode === "merge" && (
        <MergeStaffDialog
          open={dialogOpen}
          onClose={handleMergeStaffDialogClose}
          onMerge={handleMergeStaffDialogSave}
          staff={currentStaff}
        />
      )}
      {dialogMode === "addClass" && (
        <AddClassDialog
          className={currentStaff.name}
          open={dialogOpen}
          onAdd={handleAddClassDialogSave}
          onClose={handleAddClassDialogClose}
        />
      )}
    </>
  );
};

export default StaffList;
