import React from "react";
import { Box } from "@mui/system";
import { Chip } from "@mui/material";
import "./downloadPreview.scss";
import StaffList from "./StaffList.js";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import { useSettingsContext } from "../../contexts/SettingsContext";

const DownloadPreview = () => {
  const { reportCompilerState } = useSettingsContext();



  let allStaffCorrect =
    reportCompilerState?.errorCorrection?.classList.length == 0 &&
    reportCompilerState?.errorCorrection?.staffList.length == 0;

  return (
    <Box className="section-container-small download-preview">
      {reportCompilerState?.errorCorrection?.classList.length > 0 ? (
        <Box className="download-preview__box">
          <h4 className="download-preview__h4">
            Found
            <Chip
              className="download-preview__chip"
              label={reportCompilerState?.errorCorrection?.classList.length}
              color="primary"
              size="small"
            />
            unadded sessions
          </h4>
          <StaffList
            title={""}
            list={reportCompilerState?.errorCorrection?.classList}
          />
        </Box>
      ) : null}

      {reportCompilerState?.errorCorrection?.staffList.length > 0 ? (
        <Box className="download-preview__box">
          <h4 className="download-preview__h4">
            Found
            <Chip
              className="download-preview__chip"
              label={reportCompilerState?.errorCorrection?.staffList.length}
              color="primary"
              size="small"
            />
            unadded staff
          </h4>
          <StaffList
            title={""}
            list={reportCompilerState?.errorCorrection?.staffList}
          />
        </Box>
      ) : null}

      {allStaffCorrect ? (
        <div className="download-preview__success">
          <div className="download-preview__success-text">
            <CheckCircleRoundedIcon
              style={{
                color: "#4baf4f",
                fontSize: "1.5em",
              }}
            />
            <h4 className="download-preview__h4">
              All Sessions And Staff Accounted For!
            </h4>
          </div>
        </div>
      ) : null}
    </Box>
  );
};

// export default React.memo(DownloadPreview);
export default DownloadPreview;
