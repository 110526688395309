import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import './SearchBar.css';
import "./resourcesGlobalStyles.css";

const SearchBar = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const history = useHistory();

  const handleSearch = (event) => {
    event.preventDefault();
    if (searchTerm.trim()) {
      history.push(`/search/${searchTerm}`);
    }
  };

  return (
    <form onSubmit={handleSearch} className="search-form resources">
      <input
        type="text"
        placeholder="Ask anything..."
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        className="search-input"
        aria-label="Search"
      />
      <button type="submit" className="search-button">
        Search
      </button>
    </form>
  );
};

export default SearchBar;

