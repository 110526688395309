import React, { useState } from "react";
import Switch from "@mui/material/Switch";
import Box from "@mui/material/Box";

export default function BooleanToggleElement(props) {
  const [value, setValue] = useState(props.default);

  const handleChange = (event) => {
    setValue(event.target.checked);
    if (props.onChange) {
      props.onChange(event.target.checked, props.id);
    }
  };

  return (
    <Box display="flex" alignItems="flex-end">
      <Switch
        id={props.id}
        defaultChecked={value}
        size="large"
        onChange={handleChange}
      />
    </Box>
  );
}
