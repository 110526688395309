// tokenUtility.js
import { getAuth } from "firebase/auth";

export const getFirebaseJwtToken = async () => {
    const auth = getAuth();
    const user = auth.currentUser;

    if (user) {
        try {
            return await user.getIdToken(true); // Force token refresh if needed
        } catch (error) {
            console.error('Error fetching JWT token:', error);
            throw error; // Re-throw for handling by the caller
        }
    } else {
        throw new Error("User not authenticated");
    }
};
