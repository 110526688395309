import {
  ClassEvent,
  ClassAttendee,
} from "../../utility-functions/utility-functions.js";
import { addDay } from "../../utility-functions/GeneralFunctions/AddDay.js";

/**** CLASSES ****/
export class ClassCreation {
  constructor(reportCompilerState, classes = null) {
    this.reportCompilerState = reportCompilerState;
    this.classes = classes ?? [];
    this.weekDictionary = {
      Monday: 1,
      Tuesday: 2,
      Wednesday: 3,
      Thursday: 4,
      Friday: 5,
      Saturday: 6,
      Sunday: 0,
      1: "Monday",
      2: "Tuesday",
      3: "Wednesday",
      4: "Thursday",
      5: "Friday",
      6: "Saturday",
      0: "Sunday",
    };
    this.isInitialized = true;
  }

  // Add Class Comments
  addClassComments() {
    let showAttendanceStatus = this.reportCompilerState.inputFilesArrays.questions?.showAttendanceStatus ? this.reportCompilerState.inputFilesArrays.questions.showAttendanceStatus : [];
    //let showAttendeeBonusComment = this.reportCompilerState.inputFilesArrays.questions?.showAttendeeBonus ? this.reportCompilerState.inputFilesArrays.questions.showAttendeeBonus : false;
    for (let i = 0; i < this.classes.length; i++) {
      this.semiPrivateAttendeeCheck(this.classes[i]);
      this.privateAttendeeCheck(this.classes[i]);
      this.addCommentForNoBookingClasses(this.classes[i]);
      if(showAttendanceStatus.length > 0){
        this.addAttendeeStatusComments(this.classes[i], showAttendanceStatus);
      }
      // if(showAttendeeBonus){
      //   this.addAttendeeBonusComment(this.classes[i]);
      // }
    }
  }

  semiPrivateAttendeeCheck(classObj) {
    if (classObj.name.includes("Semi") && classObj.attendeeCount === 1) {
      classObj.comment.push("Semi with only one completed booking");
    }
  }

  privateAttendeeCheck(classObj) {
    if (
      classObj.name.includes("Private") &&
      !classObj.name.includes("Semi") &&
      classObj.attendeeCount > 1
    ) {
      //classObj.comment.push("*PRIVATE WITH MULTIPLE ATTENDEES*");
    }
  }

  addCommentForNoBookingClasses(classObj) {
    let bookingsFound = 0;
    for (let j = 0; j < classObj.attendeeList.length; j++) {
      if (classObj.attendeeList[j].bookingEventType !== "No Bookings") {
        bookingsFound++;
      }
    }
    if (bookingsFound === 0) {
      classObj.comment.push("No Bookings Found");
    }
  }

  addAttendeeStatusComments(classObj, statuses) {
    let statusObj = {};
    for(let i = 0; i < classObj.attendeeList.length; i++){
      let attendee = classObj.attendeeList[i];
      if(statuses.includes(attendee.bookingStatus) || statuses.includes("All")){
        if(attendee.bookingStatus in statusObj){
          statusObj[attendee.bookingStatus]++;
        } else {
          statusObj[attendee.bookingStatus] = 1;
        }
      }
    }

    let statusString = Object.entries(statusObj).map(([key, value]) => `${key}: ${value}`).join(', ');
    classObj.comment.push(statusString);
  }

  // addAttendeeBonusComment(classObj){
  //   let classBucket = this.findClassBucket(classObj.type);
  //   if(classBucket.payment === "Base Rate + Per Head Bonus"){
  //     let rateName = classBucket.getBucketRateString();
  //     let pay = parseFloat(classObj.pay);
      
  //     let baseRate = parseFloat(this.findBaseRate(this.reportCompilerState.studiosInformation.instructorsArray[0].getProperty(classObj.location, rateName)));
  //     let diff = pay - baseRate;
  //     if(diff > 0){
  //       classObj.comment.push("Attendee Bonus: $" + diff);
  //     }
  //   }
  // }

  // Add Class Counts
  addClassCounts() {
    for (let i = 0; i < this.classes.length; i++) {
      const classObj = this.classes[i];
      let bucket = this.findClassBucket(classObj.type);
      if (
        this.reportCompilerState.inputFilesArrays.questions.classBuckets
          .length > 0
      ) {
        this.findSpecificClassCounts(classObj);
      }
      if (
        this.reportCompilerState.inputFilesArrays.questions.payMaxOnFullClasses
          .valid && bucket !== null && bucket.wouldHaveBeenFullApplicable
      ) {
        this.payMaxOnFullClassesCheck(
          classObj,
          this.reportCompilerState.inputFilesArrays.questions
            .payMaxOnFullClasses
        );
      }
    }
  }

  findSpecificClassCounts(classObj) {
    let bucket = this.findClassBucket(classObj.type);
    
    //for(let i = 0; i < buckets.length; i++){
    if (bucket === null) {
      return;
    }
    let validStatuses = bucket.classStatuses;
    if (validStatuses !== null && validStatuses.length === 0) {
      return;
    }
    let capacity = bucket.capacity;

    let classCountNum = 0;
    for (let j = 0; j < classObj.attendeeList.length; j++) {
      let attendee = classObj.attendeeList[j];
      let attendeeCounts = false;

      for (let x = 0; x < validStatuses.length; x++) {
        if (
          attendee.bookingEventType.includes(validStatuses[x]) ||
          attendee.bookingStatus.includes(validStatuses[x])
        ) {
          
          attendeeCounts = true;
        }
      }

      if (
        attendeeCounts &&
        classCountNum < capacity
      ) {
        classObj.attendeeList[j].completed = true;
        classCountNum += 1;
      }
    }
    classObj.attendeeCount = classCountNum;
  }

  payMaxOnFullClassesCheck(classObj, payMaxObject) {
    let nonAttendees = 0;
    let classBucket = this.findClassBucket(classObj.type);
    let capacity = classBucket.capacity;
    if(classBucket === null || !classBucket.wouldHaveBeenFullApplicable){
      return;
    }
    for (let j = 0; j < classObj.attendeeList.length; j++) {
      const attendee = classObj.attendeeList[j];

      //let classBucket = this.findClassBucket(classObj.type);
      //if (classBucket !== null) {

      //Confirm this status is not already being paid for
        let statusCounted = false;
        for (let k = 0; k < classBucket.classStatuses.length; k++) {
          let status = classBucket.classStatuses[k];
          if (
            attendee.bookingEventType.includes(status) ||
            attendee.bookingStatus.includes(status) 
          ) {
            statusCounted = true;
          }
        }
        if (statusCounted) {
          continue;
        }
      
        //check statuses that should be paid if full
        let added = false;
        for(let z = 0; z < payMaxObject.statuses.length; z++){
          let status = payMaxObject.statuses[z];
          if (
            attendee.bookingEventType.includes(status) ||
            attendee.bookingStatus.includes(status) 
          ) {
            nonAttendees++;
            z = payMaxObject.statuses.length;
            added = true;
          }
        }

        if(!added && parseFloat(payMaxObject.time) > ((classObj.date.getTime() - attendee.loggedTime.getTime() ) / (1000 * 60 * 60))){
          nonAttendees++;
        }
    }
    if (
      classObj.attendeeCount + nonAttendees >= capacity &&
      nonAttendees !== 0
    ) {
      classObj.attendeeCount = capacity;
      classObj.comment.push("Class Would Have Been Full");
    }
  }

  // Add Class Pay
  addClassPay() {
    const instructorArray = this.reportCompilerState.studiosInformation.instructorsArray;
    let showAttendeeBonus = this.reportCompilerState.inputFilesArrays.questions?.showAttendeeBonusComment ?? false;
    
    // const instructorArray =
    //   this.reportCompilerState.inputFilesArrays.input1Pay;
    for (let i = 0; i < this.classes.length; i++) {
      let bucket = this.findClassBucket(this.classes[i].type);
      for (let j = 0; j < instructorArray.length; j++) {
        if (this.isCorrectRate(instructorArray[j], this.classes[i])) {
          this.classes[i].pay = this.findPay(instructorArray[j], this.classes[i]);
          if (
            this.reportCompilerState.inputFilesArrays.questions
              .attendeeAlternates.length > 0 && bucket !== null && bucket.isAffectedByAlternateClientPayment
          ) {
            this.addAlternateAttendeePay(
              this.classes[i],
              instructorArray[j]
            );
          }
          if(showAttendeeBonus && bucket?.payment && bucket?.payment === "Base Rate + Per Head Bonus"){
            let rateName = bucket.getBucketRateString();
            let pay = parseFloat(this.classes[i].pay);
            //add logic for if 0 is base rate
            //let baseRate = parseFloat(instructorArray[j].getRate(this.classes[i].location, rateName)[1]);
            let baseRate = this.getBaseRate(instructorArray[j], this.classes[i].location, rateName);
            let diff = pay - baseRate;
            if(diff > 0){
              this.classes[i].comment.push("Attendee Bonus: $" + diff);
            }
          }
        }
      }
      if(bucket && bucket?.excludeOverlapInPayments){ //adjust later
        this.excludeOverlapInPayments(this.classes[i], i);
      }
    }
  }

  excludeOverlapInPayments(currentClass, index) {
    if (currentClass.pay === null || currentClass.pay === 0) {
      return;
    }
    let start1 = currentClass.date; // Assuming date is a string or Date object
    let end1 = new Date(start1.getTime() + currentClass.sessionLength * 60000); // sessionLength in minutes

    // Initialize an array to hold non-overlapping periods
    let comparedSessionName = null;
    let comparedSessionTime = null;
    let nonOverlappingPeriods = [{ start: start1, end: end1 }];

    for (let j = 0; j < this.classes.length; j++) {
      if (index === j) continue; // Skip checking the same class
      if(!this.sameSessionInstructor(currentClass.instructor, this.classes[j].instructor)) continue;
      if(!this.areDatesOnSameDay(currentClass.date, this.classes[j].date)) continue;
      if(this.classes[j].pay === null || this.classes[j].pay === 0) continue;

      let compareClass = this.classes[j];
      let start2 = compareClass.date;
      let end2 = new Date(start2.getTime() + compareClass.sessionLength * 60000);

      let overlapDetected = false;

      // Update non-overlapping periods by checking overlaps
      nonOverlappingPeriods = nonOverlappingPeriods.reduce((acc, period) => {
        if (period.end <= start2 || period.start >= end2) {
          // No overlap
          acc.push(period);
        } else {
          overlapDetected = true; // Set flag for overlap detection
          // Adjust the periods based on overlap
          if (period.start < start2) {
            acc.push({ start: period.start, end: start2 });
          }
          if (period.end > end2) {
            acc.push({ start: end2, end: period.end });
          }
        }
        return acc;
      }, []);

      if (overlapDetected) {
        comparedSessionName = compareClass.name; // Update only if overlap affects payment
        comparedSessionTime = this.formatTime(compareClass.date);
      }
    }

    // Calculate the total non-overlapping duration in minutes
    let nonOverlappingTotal = nonOverlappingPeriods.reduce((sum, period) => sum + (period.end - period.start) / 60000, 0);
    let originalTotal = currentClass.sessionLength;
    let payAdjustmentFactor = nonOverlappingTotal / originalTotal;
    payAdjustmentFactor = Math.round(payAdjustmentFactor * 2) / 2;

    // Update pay proportionally
    currentClass.pay *= payAdjustmentFactor;

    // Add a comment if there was an adjustment
    if (payAdjustmentFactor < 1) {
      currentClass.comment.push(`Pay adjusted to ${payAdjustmentFactor * 100}% due to overlapping session: ` + comparedSessionName + " at " + comparedSessionTime);
    }
  }

  formatTime(date) {
    // Ensure 'date' is a Date object
    if (!(date instanceof Date)) {
      date = new Date(date);
    }
    
    // Configure the time format options
    const options = {
      hour: 'numeric',
      minute: '2-digit',
      hour12: true // Uses 12-hour format with AM/PM
    };
  
    // Return the formatted time string
    return date.toLocaleTimeString('en-US', options);
  }

  sameSessionInstructor(instructor1, instructor2) {
    if(instructor1 === instructor2){
      return true;
    }
    const instructorArray = this.reportCompilerState.studiosInformation.instructorsArray;
    for(let i = 0; i < instructorArray.length; i++){
      if(instructorArray[i].isNamed(instructor1) && instructorArray[i].isNamed(instructor2)){
        return true;
      }
    }

    return false;
  }

  areDatesOnSameDay(date1, date2) {
    return date1.getFullYear() === date2.getFullYear() &&
           date1.getMonth() === date2.getMonth() &&
           date1.getDate() === date2.getDate();
}


  getBaseRate(staffObj, location, rateName) {
    let baseRateZero = staffObj.getRate(location, rateName)[0];
    let baseRateOne = staffObj.getRate(location, rateName)[1];
    let baseRateTwo = staffObj.getRate(location, rateName)[2];
    if (
      parseFloat(baseRateZero) === parseFloat(baseRateOne) ||
      parseFloat(baseRateZero) === 0 ||
      parseFloat(baseRateOne) +
      (parseFloat(baseRateOne) - parseFloat(baseRateZero)) !==
      parseFloat(baseRateTwo)
    ) {
      return baseRateOne;
    } else {
      return baseRateZero;
    }
  }

  addAlternateAttendeePay(classObj, staffObj) {
    let bucket = this.findClassBucket(classObj.type);
    let bucketRateString = bucket.getBucketRateString();
    if(bucket === null || !bucket.isAffectedByAlternateClientPayment){
      return;
    }

    let baseRate = parseFloat(
      this.findBaseRate(staffObj.getProperty(classObj.location, bucketRateString))
    );
    let perHead = parseFloat(
      this.findPerHeadRate(
        staffObj.getProperty(classObj.location, bucketRateString)
      )
    );

    let pay = classObj.pay;

    let stringCounts = {};

    let attendeeAlternates = this.reportCompilerState.inputFilesArrays.questions
      .attendeeAlternates;
    for (let x = 0; x < classObj.attendeeList.length; x++) {
      let attendee = classObj.attendeeList[x];
      if (!attendee.completed) {
        continue;
      }
      for (let i = 0; i < attendeeAlternates.length; i++) {
        let alternate = attendeeAlternates[i];
        let attendeeName = this.attendeeIsPaidDifferent(
          attendee,
          alternate.attendee
        );
        if (attendeeName !== null) {
          if (attendeeName in stringCounts) {
            stringCounts[attendeeName]++;
          } else {
            stringCounts[attendeeName] = 1;
          }
          let difference = parseFloat(alternate.payment) - perHead;
          pay = pay + difference;
          i = attendeeAlternates.length;
        }
      }
    }

    if (Object.keys(stringCounts).length === 0) {
      return;
    }

    let commentToAdd = Object.keys(stringCounts).toString();

    pay = parseFloat(pay);

    if (pay < baseRate) {
      classObj.pay = baseRate;
      classObj.comment.push(commentToAdd);
      return;
    }

    classObj.pay = pay;
    classObj.comment.push(commentToAdd);
    return;
  }

  findBaseRate(rateArray) {
    for (let i = 0; i < rateArray.length - 1; i++) {
      if (rateArray[i] !== 0) {
        if (rateArray[i] !== rateArray[i + 1]) {
          return rateArray[i];
        }
      }
    }
    return rateArray[1] - rateArray[0];
  }

  findPerHeadRate(rateArray) {
    for (let i = 0; i < rateArray.length - 1; i++) {
      if (rateArray[i] !== 0) {
        if (rateArray[i] !== rateArray[i + 1]) {
          return rateArray[i + 1] - rateArray[i];
        }
      }
    }
    return rateArray[1] - rateArray[0];
  }

  attendeeIsPaidDifferent(attendee, alternateAttendee) {
    let attendeeValue = [alternateAttendee];
    if (alternateAttendee === "*All Staff*") {
      attendeeValue = this.getAllStaffNames();
    }

    for (let i = 0; i < attendeeValue.length; i++) {
      if (
        attendee.name === attendeeValue[i] ||
        attendee.leadType === attendeeValue[i] ||
        attendee.membership === attendeeValue[i] ||
        attendee.package === attendeeValue[i] ||
        attendee.sessionStatus === attendeeValue[i]
      ) {
        return attendeeValue[i];
      }
    }
    return null;
  }

  getAllStaffNames() {
    let names = [];

    const instructorArray = this.reportCompilerState.studiosInformation
      .instructorsArray; // fixed
    for (let i = 0; i < instructorArray.length; i++) {
      names = names.concat(instructorArray[i].getNames());
    }
    return names;
  }

  findPay(staffObj, classObj) {
    let type = classObj.type;
    let buckets = this.reportCompilerState.inputFilesArrays.questions.classBuckets;
    for(let i = 0; i < buckets.length; i++){
      if(buckets[i].type === type){
        let rateName = buckets[i].getBucketRateString();
        //let rateName = buckets[i].type.charAt(0).toLowerCase() + buckets[i].type.slice(1) + "Rates";
        //let rateName = buckets[i].type.toLowerCase() + "Rates";
        let attendeeCount = classObj.attendeeCount;
        if(buckets[i].paidOnSignUps){
          attendeeCount = ClassCreation.findSignUps(classObj);
        }
        let pay = parseFloat(staffObj.getRate(classObj.location, rateName)[attendeeCount]);
        if (
          pay < 1 &&
          pay !== 0
        ) {
          return pay * this.findPercentageOfPay(classObj);
        }
        return pay;
      }
    }
  }

  findPercentageOfPay(classObj) {
    const payChartTable = this.reportCompilerState.inputFilesArrays.PAY;

    for (let i = 0; i < payChartTable.length; i++) {
      if (
        payChartTable[i].instructor === classObj.instructor &&
        //!isNaN(payChartTable[i].price) &&
        //payChartTable[i].className.includes(classObj.name) &&
        classObj.location === payChartTable[i].location &&
        classObj.date.getTime() === payChartTable[i].date.getTime()
      ) {
        this.checkForClassPack(classObj, payChartTable[i]);
        return parseFloat(payChartTable[i].price);
      }
    }
    return null;
  }
  isCorrectRate(payRate, classObj) {
    return (
      payRate.isLocated(classObj.location) &&
      payRate.isNamed(classObj.instructor)
    );
  }
  checkThirtyMinPrivate(name, rate) {
    let includesThirty = name.includes("(30 min)");
    let rateCorrect = false;
    if (Array.isArray(rate)) {
      rateCorrect = rate[0] >= 0;
    } else {
      rateCorrect = rate >= 0;
    }
    return includesThirty && rateCorrect;
  }

  static findSignUps(classObj) {
    let signUps = 0;
    for (let i = 0; i < classObj.attendeeList.length; i++) {
      const attendee = classObj.attendeeList[i];
      if (attendee.signedUpAfterSession) {
        signUps++;
      }
    }
    if (signUps > classObj.attendeeCount) {
      return classObj.attendeeCount;
    }
    return signUps;
  }

  checkForClassPack(classObj, sessionPayrollClass) {
    if (sessionPayrollClass.classPackage.length === 0) {
      classObj.comment.push("No Class Package Found");
    }
  }

  // Add Class Type
  addClassType() {
    for (let i = 0; i < this.classes.length; i++) {

      // Get Type
      const className = this.classes[i].name;
      let matches = [];
      let buckets = this.reportCompilerState.inputFilesArrays.questions.classBuckets;
      let standardBucket = buckets.find(bucket => bucket.isStandardSessionType);

      for(let j = 0; j < buckets.length; j++){
        for(let k = 0; k < buckets[j].classNames.length; k++){
          let bucketName = buckets[j].classNames[k];
          if(className.includes(bucketName)){
            matches.push([buckets[j].type, bucketName]);
          }
        }
      }

      if(matches.length === 1){
        this.classes[i].type = matches[0][0];
      } else if(matches.length > 1){
        this.classes[i].type = this.findClosestMatch(className, matches);
      } else if(this.isSpecificPaymentType(className)){
          this.classes[i].type = standardBucket.type;
      }else{
        this.classes[i].type = "None";
      }


      // Get Name
      let newName = className;
      if(this.checkIfCancelled(this.classes[i])){
        newName = "[CANCELLED] " + className;
      }

      if(this.checkIfSoloSession(this.classes[i].type)){
        let attendee = this.findCompletedPrivateAttendee(
          this.classes[i].attendeeList
        );
        newName = attendee.name + " - " + newName;
      }

      if(this.checkIfIncludesSessionTime(this.classes[i].type)){
        newName = newName + " (" + this.classes[i].sessionLength + " min)"
      }

      if(this.checkIfSecondaryNames(this.classes[i].type)){
        let secondaryName = this.findSecondaryName(this.classes[i]);
        if(secondaryName !== null){
          newName = newName + " - " + secondaryName;
        }
      }

      this.classes[i].name = newName;


      // Add Alternate Info
      if(this.checkIfSignUpSession(this.classes[i].type)){
        this.setSessionSignups(this.classes[i]);
      }
    }
  }

  findClosestMatch(className, data) {
    let minDistance = Infinity;
    let closestMatch = "";
    let closestBucketType = "";

    for (let item of data) {
        let bucketType = item[0];
        let bucketName = item[1];
        let distance = this.levenshtein(bucketName, className);
        
        if (distance < minDistance) {
            minDistance = distance;
            closestMatch = bucketName;
            closestBucketType = bucketType;
        }
    }

    return closestBucketType;
}

levenshtein(a, b) {
  const matrix = [];
  let i, j;

  if (a.length === 0) return b.length;
  if (b.length === 0) return a.length;

  for (i = 0; i <= b.length; i++) {
      matrix[i] = [i];
  }

  for (j = 0; j <= a.length; j++) {
      matrix[0][j] = j;
  }

  for (i = 1; i <= b.length; i++) {
      for (j = 1; j <= a.length; j++) {
          if (b.charAt(i - 1) === a.charAt(j - 1)) {
              matrix[i][j] = matrix[i - 1][j - 1];
          } else {
              matrix[i][j] = Math.min(matrix[i - 1][j - 1] + 1, Math.min(matrix[i][j - 1] + 1, matrix[i - 1][j] + 1));
          }
      }
  }

  return matrix[b.length][a.length];
}

checkIfCancelled(classObj){
  for(let i = 0; i < classObj.attendeeList.length; i++){
    let attendee = classObj.attendeeList[i];
    if(!attendee.bookingEventType.includes("Cancelled")){
      return false;
    }
  }
  return true;
}

checkIfSoloSession(type){
  let buckets = this.reportCompilerState.inputFilesArrays.questions.classBuckets;
  for(let i = 0; i < buckets.length; i++){
    if(buckets[i].type === type){
      if(parseInt(buckets[i].capacity) === 1){
        return true;
      } else {
        return false;
      }
    }
  }
  return false;
}

checkIfSignUpSession(type){
  let buckets = this.reportCompilerState.inputFilesArrays.questions.classBuckets;
  for(let i = 0; i < buckets.length; i++){
    if(buckets[i].type === type){
      return buckets[i].hasSignUpsPostSession;
    }
  }
  return false;
}

checkIfIncludesSessionTime(type){
  let buckets = this.reportCompilerState.inputFilesArrays.questions.classBuckets;
  for(let i = 0; i < buckets.length; i++){
    if(buckets[i].type === type){
      return buckets[i].includeSessionTimeInName;
    }
  }
  return false;
}

checkIfSecondaryNames(type){
  let buckets = this.reportCompilerState.inputFilesArrays.questions.classBuckets;
  for(let i = 0; i < buckets.length; i++){
    if(buckets[i].type === type){
      return buckets[i]?.hasSecondaryName;
    }
  }
  return false;
}

findSecondaryName(classObj){
  let sessions = this.reportCompilerState.inputFilesArrays.PAY;
    for(let i = 0; i < sessions.length; i++){
      //for(let j = 0; j < belTable.length; j++){
        //let belObj = belTable[j];
        if(sessions[i].date.getTime() === classObj.date.getTime() &&  sessions[i].location === classObj.location && sessions[i].instructor === classObj.instructor && sessions[i].className !== classObj.name){
          if(sessions[i].className.includes(" - ")){
            let secondName = sessions[i].className.split(" - ")[1];
            return secondName;
          }
        }
      //}
    }
    return null;
}


  findCompletedPrivateAttendee(attendeeList) {
    let attendeeName = attendeeList[0];
    for (let j = 1; j < attendeeList.length; j++) {
      if (attendeeList[j].completed) {
        attendeeName = attendeeList[j];
      }
    }
    return attendeeName;
  }

  findClassBucket(type) {
    let buckets = this.reportCompilerState.inputFilesArrays.questions
      .classBuckets;
    for (let i = 0; i < buckets.length; i++) {
      if (buckets[i].type === type) {
        return buckets[i];
      }
    }
    return null;
  }


  isSpecificPaymentType(className) {
    for (
      let i = 0;
      i <
      this.reportCompilerState.inputFilesArrays.questions.classCatchAllObjects
        .length;
      i++
    ) {
      if (
        className.includes(
          this.reportCompilerState.inputFilesArrays.questions
            .classCatchAllObjects[i].className
        )
      ) {
        return true;
      }
    }
    return false;
  }

  setSessionSignups(classObj) {
    let completedStatuses = this.reportCompilerState.inputFilesArrays.questions.completedStatuses;
    const memTable = this.reportCompilerState.inputFilesArrays.MEM;
    const belPayPeriods = this.reportCompilerState.payrollInformation
      .belPayPeriods;

    for (let i = 0; i < classObj.attendeeList.length; i++) {
      let date = new Date();
      let lastSignUpTime = date.setTime(
        classObj.date.getTime() +
          this.reportCompilerState.inputFilesArrays.questions
            .timeSignUpPeriodPostSession.value *
            60 *
            60 *
            1000
      );
      const attendee = classObj.attendeeList[i];
      attendee.setSignedUpAfterSession(false);

      let completed = false;
      for(let s = 0; s < completedStatuses.length; s++){
        let status = completedStatuses[s];
        if(attendee.bookingStatus.includes(status)){
          completed = true;
        }
      }
      if(!completed){
        continue;
      }
      for (let q = 0; q < memTable.length; q++) {
        if (
          attendee.name === memTable[q].getFullName() &&
          classObj.location === memTable[q].location &&
          belPayPeriods[0].getTime() <= memTable[q].memberSince.getTime() &&
          addDay(belPayPeriods[1]).getTime() >=
            memTable[q].memberSince.getTime() &&
          memTable[q].memberSince.getTime() < addDay(lastSignUpTime).getTime()
        ) {
          attendee.setSignedUpAfterSession(true);
        }
      }
    }
  }

  // Build Classes
  buildClasses() {
    const belTable = this.reportCompilerState.inputFilesArrays.BEL;
    let builtClasses = [];
    let classFound = false;

    //this.addSecondaryNames(belTable);

    for (let i = 0; i < belTable.length; i++) {
      classFound = this.correspondingClassExists(belTable[i], builtClasses);
      if (!classFound) {
        const classEvent = this.createClassEvent(belTable[i]);
        const attendee = this.createClassAttendee(belTable[i]);

        classEvent.attendeeList.push(attendee);
        builtClasses.push(classEvent);
      }
    }

    return builtClasses;
  }

  addSecondaryNames(belTable) {
    let sessions = this.reportCompilerState.inputFilesArrays.PAY;
    for(let i = 0; i < sessions.length; i++){
      for(let j = 0; j < belTable.length; j++){
        let belObj = belTable[j];
        if(sessions[i].date.getTime() === belObj.classDate.getTime() &&  sessions[i].location === belObj.location && sessions[i].instructor === belObj.classInstructor && sessions[i].className !== belObj.className){
          if(sessions[i].className.includes(" - ")){
            belTable[j].className = belObj.className + " - " + sessions[i].className.split(" - ")[1];
            //return belObj.className + " - " + sessions[i].className.split(" - ")[1];
          }
        }
      }
    }
  }
  correspondingClassExists(bookingEvent, builtClasses) {
    let classFound = false;
    for (let y = 0; y < builtClasses.length; y++) {
      if (this.isClassEvent(builtClasses[y], bookingEvent)) {
        const attendee = this.createClassAttendee(bookingEvent);
        builtClasses[y].attendeeList.push(attendee);
        classFound = true;
      }
    }
    return classFound;
  }
  isClassEvent(classObj, belEvent) {
    return (
      classObj.name === belEvent.className &&
      classObj.instructor === belEvent.classInstructor &&
      classObj.location === belEvent.location &&
      classObj.date.getTime() === belEvent.classDate.getTime()
    );
  }

  createClassAttendee(belObj) {
    let customerDetail = this.findCustomerDetail(belObj);
    let name = "N/A";
    try{
      name = belObj.getMemberName();
    }catch(e){
      console.log(e);
    }
    let attendee = new ClassAttendee(
      name,
      belObj.eventLoggedBy,
      belObj.eventLogDate,
      belObj.eventType,
      belObj.eventStatus
    );

    if (customerDetail === null) {
      return attendee;
    }

    attendee.package = customerDetail.package;
    attendee.membership = customerDetail.membership;
    attendee.sessionStatus = customerDetail.status;
    attendee.leadType = customerDetail.lead;

    return attendee;
  }

  findCustomerDetail(belEvent) {
    let customerDetail = this.reportCompilerState.inputFilesArrays.CUSTOMER; // CUST is undefgined
    for (let i = 0; i < customerDetail.length; i++) {
      if (
        customerDetail[i].name === belEvent.getMemberName() &&
        customerDetail[i].date.getTime() === belEvent.classDate.getTime()
      ) {
        return customerDetail[i];
      }
    }
    return null;
  }

  createClassEvent(belObj) {
    //let name = belObj.className;
    let name = this.getClassFullName(belObj);
    let classE = new ClassEvent(
      name,
      belObj.classInstructor,
      belObj.location,
      belObj.classDate,
      belObj.sessionLength
    )
    return classE;
    // return new ClassEvent(
    //   name,
    //   belObj.classInstructor,
    //   belObj.location,
    //   belObj.classDate,
    //   belObj.sessionLength
    // );
  }

  getClassFullName(belObj) {
    let stretchLabNames = ["Stretch", "Intro One on One", "Demo Stretch"];
    for (let i = 0; i < stretchLabNames.length; i++) {
      if (stretchLabNames[i].includes(belObj.className)) {
        return belObj.className + " (" + belObj.sessionLength + " min)";
      }
    }

    // let sessions = this.reportCompilerState.inputFilesArrays.PAY;
    // for(let i = 0; i < sessions.length; i++){
    //   if(sessions[i].date.getTime() === belObj.classDate.getTime() &&  sessions[i].location === belObj.location && sessions[i].instructor === belObj.classInstructor && sessions[i].className !== belObj.className){
    //     if(sessions[i].className.includes(" - ")){
    //       return belObj.className + " - " + sessions[i].className.split(" - ")[1];
    //     }
    //   }
    // }
    return belObj.className;
  }

  /**** CHECK FOR CLASS NUANCES ****/

  addCatchAllLogicPay() {
    for (let i = 0; i < this.classes.length; i++) {
      if (this.classes[i].instructor.length > 0) {
        this.checkClassCatchAllFulfilment(this.classes[i]);
      }
    }
  }

  checkClassCatchAllFulfilment(classObj) {
    let standardBucket = this.findStandardBucket();
    for (
      let i = 0;
      i <
      this.reportCompilerState.inputFilesArrays.questions.classCatchAllObjects
        .length;
      i++
    ) {
      const attributesCorrect = this.catchAllAttributesCorrect(
        classObj,
        this.reportCompilerState.inputFilesArrays.questions
          .classCatchAllObjects[i]
      );
      if (!attributesCorrect) {
        continue;
      }
      const timeCorrect = this.catchAllTimesCorrect(
        classObj,
        this.reportCompilerState.inputFilesArrays.questions
          .classCatchAllObjects[i]
      );
      if (!timeCorrect) {
        continue;
      }
      classObj.type === "None" ? (classObj.type = standardBucket.type) : null;
      let rateComment = this.addCatchAllPayRate(
        classObj,
        this.reportCompilerState.inputFilesArrays.questions
          .classCatchAllObjects[i]
      );
      if (rateComment !== "") {
        classObj.comment.push(rateComment);
      }
    }
  }

  catchAllAttributesCorrect(classObj, catchAll) {
    if (!this.checkCatchAllNames(classObj.name, catchAll.className)) {
      return false;
    }
    if (
      !this.checkCatchAllInstructors(classObj.instructor, catchAll.instructor)
    ) {
      return false;
    }
    if (!this.checkCatchAllStudios(classObj.location, catchAll.studio)) {
      return false;
    }
    if (
      !(
        catchAll.day.toUpperCase().includes("ANY") ||
        this.weekDictionary[catchAll.day] === classObj.date.getDay()
      )
    ) {
      return false;
    }
    if (!(catchAll.type === "Any" || catchAll.type === classObj.type)) {
      return false;
    }
    if (
      !(
        catchAll.payAmount === "Any" ||
        this.isCorrectPayAmount(catchAll.payAmount, parseFloat(classObj.pay))
      )
    ) {
      return false;
    }
    if (
      !(
        catchAll.attendeeAmount === "Any" ||
        this.isCorrectAttendeeAmount(
          catchAll.attendeeAmount,
          parseFloat(classObj.attendeeCount)
        )
      )
    ) {
      return false;
    }
    return true;
  }

  checkCatchAllNames(className, catchAllName) {
    if (Array.isArray(catchAllName)) {
      if (catchAllName.length === 0) {
        return true;
      }
      for (let i = 0; i < catchAllName.length; i++) {
        if (className.includes(catchAllName[i])) {
          return true;
        }
      }
    } else {
      if (catchAllName.toUpperCase() === "ANY") {
        return true;
      }

      if (className.includes(catchAllName)) {
        return true;
      }
    }

    return false;
  }

  checkCatchAllInstructors(classInstructor, catchAllInstructors) {
    if (Array.isArray(catchAllInstructors)) {
      if (catchAllInstructors.length === 0) {
        return true;
      }
      for (let i = 0; i < catchAllInstructors.length; i++) {
        if (classInstructor.includes(catchAllInstructors[i])) {
          return true;
        }
      }
    } else {
      if (catchAllInstructors.toUpperCase() === "ANY") {
        return true;
      }

      if (classInstructor.includes(catchAllInstructors)) {
        return true;
      }
    }

    return false;
  }

  checkCatchAllStudios(classStudio, catchAllStudio) {
    if (Array.isArray(catchAllStudio)) {
      if (catchAllStudio.length === 0) {
        return true;
      }
      for (let i = 0; i < catchAllStudio.length; i++) {
        if (classStudio.includes(catchAllStudio[i])) {
          return true;
        }
      }
    } else {
      if (catchAllStudio.toUpperCase() === "ANY") {
        return true;
      }

      if (classStudio.includes(catchAllStudio)) {
        return true;
      }
    }

    return false;
  }

  isCorrectPayAmount(catchAllPay, classPay) {
    let operator = catchAllPay.substring(0, 1);
    let amount = parseFloat(catchAllPay.substring(1));
    if (operator === "<") {
      return classPay < amount;
    } else if (operator === ">") {
      return classPay > amount;
    } else if (operator === "=") {
      return amount === classPay;
    } else if (operator === "!") {
      return amount !== classPay;
    }
    return false;
  }

  isCorrectAttendeeAmount(catchAllPay, classAttendence) {
    let operator = catchAllPay.substring(0, 1);
    let amount = parseFloat(catchAllPay.substring(1));
    if (operator === "<") {
      return classAttendence < amount;
    } else if (operator === ">") {
      return classAttendence > amount;
    } else if (operator === "=") {
      return amount === classAttendence;
    } else if (operator === "!") {
      return amount !== classAttendence;
    }
    return false;
  }

  catchAllTimesCorrect(classObj, catchAll) {
    const classTime =
      classObj.date.getHours() + classObj.date.getMinutes() / 60;
    const catchAllHours = this.stringHoursToDigit(catchAll.time);

    if (catchAll.sequence.includes("All")) {
      return true;
    } else if (catchAll.sequence.includes("Before")) {
      if (classTime < catchAllHours) {
        return true;
      }
    } else if (catchAll.sequence.includes("After")) {
      if (classTime > catchAllHours) {
        return true;
      }
    } else if (catchAll.sequence.includes("First")) {
      let first = true;
      for (let x = 0; x < this.classes.length; x++) {
        if (this.classes[x].date.getDate() === classObj.date.getDate()) {
          if (this.classes[x].date.getTime() < classObj.date.getTime()) {
            first = false;
          }
        }
      }
      if (first) {
        return true;
      }
    }
    return false;
  }

  stringHoursToDigit(time) {
    let hour = time.substring(0, time.indexOf(":"));
    let minutes = time.substring(time.indexOf(":") + 1);
    if (!time.includes(":")) {
      hour = time.substring(0, 2);
      minutes = time.substring(2);
    }
    const hourStr = parseFloat(hour);
    const minStr = parseFloat(minutes) / 60;
    return hourStr + minStr;
  }

  addCatchAllPayRate(classObj, catchAll) {
    let rate = catchAll.rate;
    let instructorObj = this.findInstructorObj(classObj.instructor);
    let buckets = this.reportCompilerState.inputFilesArrays.questions.classBuckets;
    let standardBucket = this.findStandardBucket();
    let standardBucketNameRate = standardBucket.type.toLowerCase() + "Rates";
    let standardBucketCapacity = standardBucket.capacity;
    if (instructorObj === null) {
      return;
    }
    if (rate.includes("@") && !rate.includes("+")) {
      const studNum = parseFloat(rate.substring(1));
      const studentNumberPay = parseFloat(
        instructorObj.getProperty(classObj.location, standardBucketNameRate)[studNum]
      );
      if (studentNumberPay > classObj.pay || catchAll.overridePay) {
        classObj.pay = studentNumberPay;
        return "" + rate.substring(1) + " attendee rate";
      }
    } else if (rate.includes("@") && rate.includes("+")) {
      let splitRate = rate.split("+");
      let base = parseInt(splitRate[0]);
      let bonus = parseInt(splitRate[1].split("+")[0]);
      let threshold = parseInt(rate.split("@")[1]);
      let rateArray = [];
      for (let i = 0; i < 50; i++) {
        let amount = base;
        let thresholdMet = i - threshold;
        if (thresholdMet > 0) {
          let totalBonus = thresholdMet * bonus;
          amount += totalBonus;
        }
        rateArray.push(amount);
      }
      let rateFound = rateArray[classObj.attendeeCount];
      if (rateFound > classObj.pay || catchAll.overridePay) {
        classObj.pay = rateFound;
        return "*Custom Rate*";
      }
    } else if (rate.substring(0, 1) === "%") {
      let percent = parseFloat(rate.substring(1));
      let pay = this.findPercentageOfPay(classObj);
      let amount = percent * pay;
      if (amount > classObj.pay || catchAll.overridePay) {
        classObj.pay = amount;
        return "Percent of cost";
      }
    } else if (rate.includes("*")) {
      const exactNum = rate.substring(1);
      const exactAmountPay = parseFloat(exactNum);
      if (exactAmountPay > classObj.pay || catchAll.overridePay) {
        classObj.pay = exactAmountPay;
        return "$" + rate.substring(1) + " exactly";
      }
    } else if (
      rate.includes("NS") ||
      rate.includes("LC") ||
      rate.includes("OB")
    ) {
      let students = classObj.attendeeCount;
      let nsComment = "0 No-Shows";
      let lcComment = "0 Late-Cancels";
      let openComment = "0 Open Bookings";

      if (rate.includes("NS")) {
        const noShows = this.findNoShows(classObj.attendeeList);
        students += noShows;
        nsComment = noShows + " No-Shows";
      }
      if (rate.includes("LC")) {
        const lateCancels = this.findLateCancels(classObj.attendeeList);
        students += lateCancels;
        lcComment = lateCancels + " Late-Cancels";
      }
      if (rate.includes("OB")) {
        const openBookings = this.findOpenBookings(classObj.attendeeList);
        students += openBookings;
        openComment = openBookings + " Open-Bookings";
      }
      let bucketName1 = standardBucketNameRate;
      let bucketCapacity1 = standardBucketCapacity;
      let classBucket1 = this.findClassBucket(classObj.type);
      if(classBucket1 !== null){
        bucketName1 = classBucket1.type.toLowerCase() + "Rates";
        bucketCapacity1 = classBucket1.capacity;
      }

      if (
        students > bucketCapacity1
      ) {
        students = bucketCapacity1;
      }
      
      const includingNSLCPay = parseFloat(
        instructorObj.getProperty(classObj.location, bucketName1)[students]
      );
      if (includingNSLCPay > classObj.pay || catchAll.overridePay) {
        classObj.pay = includingNSLCPay;
        return nsComment + " & " + lcComment + " & " + openComment + " paid";
      }
    } else if (rate.includes("hourly")) {
      const hourlyPay = this.findHourlyPayRate(
        classObj.instructor,
        classObj.location,
        this.reportCompilerState.studiosInformation
      );
      const mathSign = rate.indexOf("hourly") - 1;
      if (rate[mathSign] === "+") {
        let staticAmount = rate.substring(0, rate.indexOf("+"));
        if (staticAmount < 1) {
          staticAmount = staticAmount * this.findPercentageOfPay(classObj);
        }
        classObj.pay = parseFloat(staticAmount) - hourlyPay;
        return "$" + staticAmount + " + hourly ($" + hourlyPay + ")";
      } else if (rate[mathSign] === "-") {
        let staticAmount = rate.substring(0, rate.indexOf("-"));
        if (staticAmount < 1) {
          staticAmount = staticAmount * this.findPercentageOfPay(classObj);
        }
        classObj.pay = parseFloat(staticAmount) - hourlyPay;
        return "$" + staticAmount + " - hourly ($" + hourlyPay + ")";
      } else {
        alert("Unrecognizable hourly usage");
      }
      // } else if (parseFloat(rate) > 1) {
    } else if (rate.substring(0, 2) === "++") {
      let rateNum = parseFloat(rate.replace("++", ""));

      let class_pay = classObj.pay;
      // let class_pay = parseFloat(
      //   instructorObj.getProperty(classObj.location, standardBucketNameRate)[classObj.attendeeCount]
      // )
      if(!class_pay || class_pay === 0 || class_pay === null || class_pay === undefined){
        class_pay = parseFloat(
            instructorObj.getProperty(classObj.location, standardBucketNameRate)[classObj.attendeeCount]
          );
      }

      const addedAmountPay = class_pay + parseFloat(rateNum);
      // const addedAmountPay =
      //   parseFloat(
      //     instructorObj.getProperty(classObj.location, standardBucketNameRate)[classObj.attendeeCount]
      //   ) + parseFloat(rateNum);

      if (addedAmountPay > classObj.pay || catchAll.overridePay) {
        classObj.pay = addedAmountPay;
        return "+$" + rateNum + " bonus";
      }
    } else if (rate.slice(-2) === "G%") {
      let slicedRate = parseFloat(rate.replace("G%", ""));
      const percentagePay =
        slicedRate *
        parseFloat(
          instructorObj.getProperty(classObj.location, standardBucketNameRate)[standardBucketCapacity]
        );
      if (percentagePay > classObj.pay || catchAll.overridePay) {
        classObj.pay = percentagePay;
        return "" + slicedRate * 100 + "% of full class rate";
      }
    } 
    for(let i = 0; i < buckets.length; i++){
      if(rate === buckets[i].type){
        let bucketRateStr = buckets[i].getBucketRateString();
        let bucketPayment = parseFloat(
          instructorObj.getProperty(classObj.location, bucketRateStr)[classObj.attendeeCount]
        );
        if(bucketPayment > classObj.pay || catchAll.overridePay){
          classObj.pay = bucketPayment;
          return buckets[i].type + " Rate";
        }        
      }
    }
    
    if (rate.includes("FLAG:")) {
      return rate.substring(rate.indexOf(":") + 1);
    } else if (rate.charAt(0) === "?") {
      let customRateString = rate.slice(1);
      const classRate = customRateString.split(",").map(parseFloat);
      let customPay = parseFloat(classRate[classObj.attendeeCount]);
      if(customPay > classObj.pay || catchAll.overridePay){
        classObj.pay = parseFloat(classRate[classObj.attendeeCount]);
        return "Custom Rate";
      }
    }
    return "";
  }

  findStandardBucket(){
    const buckets = this.reportCompilerState.inputFilesArrays.questions.classBuckets;
    for(let i = 0; i < buckets.length; i++){
      if(buckets[i].isStandardSessionType){
        //return buckets[i].type.toLowerCase() + "Rates";
        return buckets[i];
      }
    }
    return buckets.sort((a, b) => {
      if (a.type < b.type) {
          return -1;
      }
      if (a.type > b.type) {
          return 1;
      }
      return 0;
  })[0];
  }

  findInstructorObj(instructor) {
    const instructorArray = this.reportCompilerState.studiosInformation
      .instructorsArray;
    for (let i = 0; i < instructorArray.length; i++) {
      if (instructorArray[i].isNamed(instructor)) {
        return instructorArray[i];
      }
    }
    return null;
  }

  findNoShows(attendeeList) {
    let noShows = 0;
    for (let i = 0; i < attendeeList.length; i++) {
      if (attendeeList[i].bookingStatus.includes("No Show")) {
        noShows++;
      }
    }
    return noShows;
  }

  findLateCancels(attendeeList) {
    let lateCancels = 0;
    for (let i = 0; i < attendeeList.length; i++) {
      if (attendeeList[i].bookingStatus.includes("Cancelled Outside Policy")) {
        lateCancels++;
      }
    }
    return lateCancels;
  }

  findOpenBookings(attendeeList) {
    let openBookings = 0;
    for (let i = 0; i < attendeeList.length; i++) {
      if (attendeeList[i].bookingStatus.includes("Open Booking")) {
        openBookings++;
      }
    }
    return openBookings;
  }

  findPercentageOfPay(classObj) {
    const payChartTable = this.reportCompilerState.inputFilesArrays.PAY;

    for (let i = 0; i < payChartTable.length; i++) {
      let payInstructor = payChartTable[i].instructor;
      let currentInstructor = classObj.instructor;
      let payLocation = payChartTable[i].location;
      let currentLocation = classObj.location;
      let payDate = payChartTable[i].date.getTime();
      let currentDate = classObj.date.getTime();
      let instructorMatch = payInstructor === currentInstructor;
      let locationMatch = payLocation === currentLocation;
      let dateMatch = payDate === currentDate;
      if (instructorMatch && locationMatch && dateMatch) {
        return parseFloat(payChartTable[i].price);
      }
    }
    return null;
  }

  checkForClassPack(classObj, sessionPayrollClass) {
    if (sessionPayrollClass.classPackage.length === 0) {
      classObj.comment.push("No Class Package Found");
    }
  }

  createCatchAllComment(c) {
    let classNames = Array.isArray(c.className)
      ? c.className.toString()
      : c.className;
    let instructors = Array.isArray(c.instructor)
      ? c.instructor.toString()
      : c.instructor;
    let studios = Array.isArray(c.studio) ? c.studio.toString() : c.studio;

    let comment =
      c.type +
      ", " +
      classNames +
      ", " +
      c.day +
      ", " +
      c.sequence +
      " " +
      c.time +
      ", " +
      instructors +
      ", " +
      studios +
      ", " +
      c.payAmount +
      ", " +
      c.attendeeAmount +
      ", ";
    if (c.rate.includes("FLAG:")) {
      return c.rate.substring(c.rate.indexOf(":") + 1);
    }
    if (c.type.toUpperCase() === "ANY") {
      comment = comment.replace(c.type + ", ", "");
    }
    if (classNames.toUpperCase() === "ANY") {
      comment = comment.replace(classNames + ", ", "");
    }
    if (c.day.toUpperCase() === "ANY") {
      comment = comment.replace(c.day + ", ", "");
    }
    if (c.sequence.toUpperCase() === "ALL") {
      comment = comment.replace("All ", "");
      comment = comment.replace("00:00, ", "");
      comment = comment.replace("0:00, ", "");
    }
    if (c.sequence.toUpperCase() === "FIRST") {
      comment = comment.replace("00:00, ", "Class");
      comment = comment.replace("0:00, ", "Class");
    }
    if (instructors.toUpperCase() === "ANY") {
      comment = comment.replace(instructors + ", ", "");
    }
    if (studios.toUpperCase() === "ANY") {
      comment = comment.replace(studios + ", ", "");
    }
    if (c.payAmount.toUpperCase() === "ANY") {
      comment = comment.replace(c.payAmount + ", ", "");
    } else {
      comment = comment.replace(
        c.payAmount + ", ",
        c.payAmount.substring(0, 1) + "$" + c.payAmount.substring(1) + ", "
      );
    }
    if (c.attendeeAmount.toUpperCase() === "ANY") {
      comment = comment.replace(c.attendeeAmount + ", ", "");
    } else {
      comment = comment.replace(
        c.attendeeAmount + ", ",
        c.attendeeAmount + " attendees, "
      );
    }
    comment = comment + ": ";
    comment = comment.replace(", : ", ": ");
    //return comment;
  }

  checkForRescheduledClasses() {
    for (let x = 0; x < this.classes.length; x++) {
      let classObj = this.classes[x];

      let attendeesWhoWereRescheduled = 0;
      let attendeesWhoWerentReschedule = 0;
      for (let i = 0; i < classObj.attendeeList.length; i++) {
        let rescheduled = false;
        let attendee = classObj.attendeeList[i];
        for (
          let j = 0;
          j <
          this.reportCompilerState.inputFilesArrays.questions
            .rescheduledClassStatuses.attributes.length;
          j++
        ) {
          if (
            attendee.bookingEventType.includes(
              this.reportCompilerState.inputFilesArrays.questions
                .rescheduledClassStatuses.attributes[j]
            ) ||
            attendee.bookingStatus.includes(
              this.reportCompilerState.inputFilesArrays.questions
                .rescheduledClassStatuses.attributes[j]
            )
          ) {
            attendeesWhoWereRescheduled++;
            rescheduled = true;
          }
        }
        if (
          attendee.bookingStatus.includes("Cancelled Within Policy Rules") ||
          rescheduled
        ) {
          // Do nothing
        } else {
          attendeesWhoWerentReschedule++;
        }
      }

      if (
        attendeesWhoWereRescheduled > 0 &&
        attendeesWhoWerentReschedule === 0
      ) {
        classObj.pay = 0;
        classObj.comment.push("*RESCHEDULED*");
      }
    }

    if(this.reportCompilerState.inputFilesArrays.questions.confirmFromSessionPayroll.value){
      //this.confirmSessionFromSessionPayroll();
    }
  }

  confirmSessionFromSessionPayroll(){
    for(let i = 0; i < this.classes.length; i++){
      let classFound = false;
      const sessionClasses = this.reportCompilerState.inputFilesArrays.PAY;
      for(let j = 0; j < sessionClasses.length; j++){
        if(this.classes[i].date.getTime() === sessionClasses[j].date.getTime() && this.classes[i].instructor === sessionClasses[j].instructor && this.classes[i].location === sessionClasses[j].location){
          classFound = true;
        }
      }

      if(!classFound){
        this.classes[i].comment.push("*CANCELLED*");
        this.classes[i].pay = 0;
      }
    }
  }

  // Create Classes
  createClasses() {
    this.classes = this.buildClasses();

    

    this.addClassType();
    this.addClassCounts();
    this.addClassPay();
    if (
      this.reportCompilerState.inputFilesArrays.questions.classCatchAllObjects
        .length > 0
    ) {
      this.addCatchAllLogicPay();
    }
    if (
      this.reportCompilerState.inputFilesArrays.questions
        .rescheduledClassStatuses.attributes.length > 0
    ) {
      this.checkForRescheduledClasses();
    }
    if(this.reportCompilerState.inputFilesArrays.questions.confirmFromSessionPayroll.value){
      this.confirmSessionFromSessionPayroll();
    }
    this.addClassComments();
    this.organizeClasses();
  }

  organizeClasses() {
    this.classes.sort((a, b) => (a.date.getTime() > b.date.getTime() ? 1 : -1));
  }
}
