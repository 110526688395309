import React from "react";
import ContactForm from "./ContactForm";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import useMediaQuery from "@mui/material/useMediaQuery";
import ResponsiveAppBar from "./AppBar/ResponsiveAppBar";

export default function ContactPage() {
  
  const matches = useMediaQuery("(min-width:900px)");
  return (
    <>
      <ResponsiveAppBar></ResponsiveAppBar>
      <Box
        style={
          matches
            ? {
                width: "100%",
                height: "104px",
              }
            : {
                width: "100%",
                height: "80px",
              }
        }
      ></Box>
      <Box
        component="div"
        sx={{
          backgroundColor: "white",
        }}
      >
        <Box
          component="div"
          sx={{
            margin: "0px auto",

            // padding: "0px 60px",
            padding: "0px 16px",
            paddingTop: "36px",
            paddingBottom: "36px",
            maxWidth: "1024px",
            flexDirection: "column",
            display: "flex",
          }}
        >
          <Box>
            <Typography variant="h2" component="div" gutterBottom>
              Contact Us
            </Typography>
            <Typography
              style={{ marginBottom: "2em" }}
              variant="subtitle1"
              gutterBottom
              component="div"
            >
              Fill out the following form and we will get back to you shortly.
            </Typography>
          </Box>
          <Box sx={{ minWidth: 275 }}>
            <ContactForm></ContactForm>
          </Box>
        </Box>
      </Box>
    </>
  );
}
