export class TimeEvent {
  constructor(staffName, location, hoursWorked, payRate, description, payTotal) {
    this.description = description;
    this.classification = 'Regular';
    this.staffName = staffName;
    this.location = location;
    this.hoursWorked = hoursWorked;
    this.payRate = payRate;

    this.payTotal = payTotal;

    this.type = null;

    this.detail = false;
    this.isPunchEvent = false;
    this.date = null;
    this.clockIn = null;
    this.clockOut = null;
    this.clockInDate = null;
    this.clockOutDate = null;
    this.comment = "";

    this.regularHours = 0;
    this.overtimeHoursDaily = 0;
    this.doubleOvertimeHoursDaily = 0;
    this.overtimeHoursConsecutive = 0;
    this.doubleOvertimeHoursConsecutive = 0;
    this.overtimeHoursWeekly = 0;

    this.overtimePayRate = 0;
    this.doubleOvertimePayRate = 0;
    this.holidayPayRate = 0;
    this.regularRateOfPay = 0;
    
    this.exception = false;
    this.earnings = false;
    this.addedToExcel = false;
    this.holiday = false;
  }
}
