
export class SalesBucket{
    constructor(name, type, implicitType, itemNames, trackPrevious){
        this.name = name,
        this.type = type,//"Agreement" OR "Retail" is it a service type or a product type
        this.implicitType = implicitType, //standard
        this.itemNames = itemNames, //array of sold item names
        this.trackPrevious = trackPrevious, //do we care about a purchasers previous purchases when calculating the commissionable percentage
        
        this.staffNamesPaidForThisBucket = [], //blank array is any/all
        this.staffTypesPaidForThisBucket = [], //blank array is any/all

        this.hierarchy = {
            0: [], //not paid
            1: [], // default
            2: []
          },

        this.reactivationOptions = "Disregard length between purchases", //["Do Not Pay", "Pay as a New Sale", "Disregard length between purchases", "Regard as new if length is less than reactivationTimeFrame"],
        this.reactivationTimeFrame = -1, //days

        this.sessionConversion = false, //do we want to subcategorize based on this item being purchased after a class
        this.sessionConversionTimeHours = -1, //-1 for none OR Integar for hours time constraints on how long after the class, it is still considered a post class sign up (only if sessionConversion is true)
        this.sessionConversionClassBuckets = [], //which “Class Buckets” are valid to look through for post class sign ups (only if sessionConversion is true)
        this.sessionConversionInstructorBonus = 0, //are the instructors of these classes given given a flat bonus for any sign up after their class (only if sessionConversion is true)
        //payAmountColumnOrKey: -1, //which column we are pulling the commissionable payment from (if from a file) OR which key (if from an API call)
        
        this.multiplePreviousAgreementsPayment = "Do Not Pay",//["Do Not Pay", "Pay as a New Sale", "Disregard all previous agreements", "Disregard all but last previous agreement", "Disregard cross-bucket sales", "Take into account all previous agreements & use highest tier agreement for base", "Take into account all previous agreements & let PayWell AI decided if its commissionable"],
        
        
        this.discountPercentages = {
            // 0.99:  {
            //       rateType: "Fixed",
            //       rate: 0,
            //       setupFeeIncluded: false,
            //       split: true,
            //       salespeople: ["Closing"]
            //     }
            }, 
        
        this.postDatedSaleOptions = "Do Not Pay", //["Pay as Normal", "Do Not Pay", "Pay on Post Dated sale day"],
        
        this.unpaidItemOptions = "Do Not Pay", //["Pay as Normal", "Do Not Pay", "Pay a Different Rate"],
        this.unpaidItemDifferentRate = {
            rateType: "Fixed",
            rate: 0,
            setupFeeIncluded: false,
            split: true,
            salespeople: ["Close"]
          },
        
        this.distinctRates = ["New", "Upgrade", "Downgrade", "Renewal", "N/A"], //which types do we want to have distinct rates for - by default it is New Sales, Upgrades, Downgrades, Renewals, N/A, (if sessionConversion is true) New Sales Post Class SignUps, (if reactivationOptions unique option) New Sale Reactivations & Upgrades Reactivations...
        this.rates = {
            "New": {
                rateType: "Fixed",
                rate: 0,
                setupFeeIncluded: false,
                split: true,
                salespeople: ["Close"]
              },
            "Upgrade": {
                rateType: "Fixed",
                rate: 0,
                setupFeeIncluded: false,
                split: true,
                salespeople: ["Close"]
              },
            "Downgrade": {
                rateType: "Fixed",
                rate: 0,
                setupFeeIncluded: false,
                split: true,
                salespeople: ["Close"]
              },
            "Renewal": {
                rateType: "Fixed",
                rate: 0,
                setupFeeIncluded: false,
                split: true,
                salespeople: ["Close"]
              },
            "N/A": {
                rateType: "Fixed",
                rate: 0,
                setupFeeIncluded: false,
                split: true,
                salespeople: ["Close"]
              }

        }
    }

    addStaffSpecifications(staffNames, staffTypes){
        this.staffNamesPaidForThisBucket = staffNames;
        this.staffTypesPaidForThisBucket = staffTypes;
    }

    addHierarchy(hierarchy){
        this.hierarchy = hierarchy;
    }

    addReactivationOptions(options, timeFrame){
        this.reactivationOptions = options;
        this.reactivationTimeFrame = timeFrame;
    }

    addSessionConverion(signUp, time, classBuckets, bonus){
        this.sessionConversion = signUp;
        this.sessionConversionTimeHours = time;
        this.sessionConversionClassBuckets = classBuckets;
        this.sessionConversionInstructorBonus = bonus;
    }

    addMultiplePreviousAgreementsLogic(payment){
        this.multiplePreviousAgreementsPayment = payment;
    }

    addDiscountPercentages(discountPercentages, commissionStaffBought){
        this.discountPercentages = discountPercentages;
        this.commissionStaffBought = commissionStaffBought;
    }

    addPostDatedSaleOptions(options){
        this.postDatedSaleOptions = options;
    }

    addUnpaidItemOptions(options, rate){
        this.unpaidItemOptions = options;
        this.unpaidItemDifferentRate = rate;
    }

    addRateLogic(distinctRates, rates, rateNameOverrides){
        this.distinctRates = distinctRates;
        this.rates = rates;
        this.rateNameOverrides = rateNameOverrides;
    }
}