import React, { useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  DialogActions,
  DialogContentText,
} from "@mui/material";
import MergeStaffComponent from "./MergeStaffComponent";
const MergeStaffDialog = ({ open, onClose, onMerge, staff }) => {
  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle component={"h3"}>Add to Existing Staff</DialogTitle>

      <DialogContent>
        {/* <DialogContentText>
          Use the information associated with the Club Ready account you setup
          for PayWell.
        </DialogContentText> */}
        <MergeStaffComponent
          onMerge={onMerge}
          onClose={onClose}
          staff={staff}
        />
      </DialogContent>
      {/* <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleOnMerge}>Confirm</Button>
      </DialogActions> */}
    </Dialog>
  );
};

export default MergeStaffDialog;
