// // import React, { useState, useEffect } from 'react';
// // import { useParams, useHistory } from 'react-router-dom';
// // import { resources } from './resources';
// // import SearchBar from './SearchBar';
// // import ResourceCard from './ResourceCard';
// // import Button from '@mui/material/Button';
// // import ArrowBackIosRoundedIcon from '@mui/icons-material/ArrowBackIosRounded';
// // import PayWellLogoBlack from '../../img/PayWellLogoBlack.png';
// // import './VideoResources.css'; // Use the same CSS file for consistency
// // import "./resourcesGlobalStyles.css";

// // const SearchResults = () => {
// //   const { searchTerm } = useParams();
// //   const [filteredResources, setFilteredResources] = useState([]);
// //   const history = useHistory();

// //   useEffect(() => {
// //     const countKeywordOccurrences = (resources, keyword) => {
// //       return resources
// //         .map((resource) => {
// //           let count = 0;
// //           const searchValues = (obj) => {
// //             if (typeof obj === 'string') {
// //               count += (obj.match(new RegExp(keyword, 'gi')) || []).length;
// //             } else if (Array.isArray(obj)) {
// //               obj.forEach((element) => searchValues(element));
// //             } else if (typeof obj === 'object' && obj !== null) {
// //               Object.values(obj).forEach((value) => searchValues(value));
// //             }
// //           };
// //           searchValues(resource);
// //           return { ...resource, count };
// //         })
// //         .filter((resource) => resource.count > 0);
// //     };

// //     if (searchTerm) {
// //       const countedResults = countKeywordOccurrences(resources, searchTerm);
// //       setFilteredResources(countedResults);
// //     }
// //   }, [searchTerm]);

// //   const handleBackClick = () => {
// //     history.push('/resources');
// //   };

// //   return (
// //     <div className="resources-center">
// //       <div className="header-container">
// //         <Button
// //           className="drawer__back-button"
// //           onClick={handleBackClick}
// //           startIcon={<ArrowBackIosRoundedIcon className="icon" />}
// //         >
// //           Resources
// //         </Button>
// //         <div className="title-container">
// //           <img src={PayWellLogoBlack} alt="PayWell Logo" className="paywell-logo" />
// //           <h1>knowledge base</h1>
// //         </div>
// //       </div>

// //       <SearchBar />

// //       <div className="browse-header">
// //         <h3>Search Results for "{searchTerm}"</h3>
// //       </div>

// //       {filteredResources.length > 0 ? (
// //         <div className="resources-list">
// //           {filteredResources.map((resource) => (
// //             <ResourceCard resource={resource} key={resource.name} />
// //           ))}
// //         </div>
// //       ) : (
// //         <p>No results found.</p>
// //       )}
// //     </div>
// //   );
// // };

// // export default SearchResults;

// import React, { useState, useEffect } from 'react';
// import { useParams, useHistory } from 'react-router-dom';
// import { resources } from './resources';
// import SearchBar from './SearchBar';
// import ResourceCard from './ResourceCard';
// import Button from '@mui/material/Button';
// import ArrowBackIosRoundedIcon from '@mui/icons-material/ArrowBackIosRounded';
// import PayWellLogoBlack from '../../img/PayWellLogoBlack.png';
// import InfoIcon from "@mui/icons-material/Info";
// import './VideoResources.css'; // Use the same CSS file for consistency

// const SearchResults = () => {
//   const { searchTerm } = useParams();
//   const [filteredResources, setFilteredResources] = useState([]);
//   const history = useHistory();

//   useEffect(() => {
//     const countKeywordOccurrences = (resources, keyword) => {
//       return resources
//         .map((resource) => {
//           let count = 0;
//           const searchValues = (obj) => {
//             if (typeof obj === 'string') {
//               count += (obj.match(new RegExp(keyword, 'gi')) || []).length;
//             } else if (Array.isArray(obj)) {
//               obj.forEach((element) => searchValues(element));
//             } else if (typeof obj === 'object' && obj !== null) {
//               Object.values(obj).forEach((value) => searchValues(value));
//             }
//           };
//           searchValues(resource);
//           return { ...resource, count };
//         })
//         .filter((resource) => resource.count > 0);
//     };

//     if (searchTerm) {
//       const countedResults = countKeywordOccurrences(resources, searchTerm);
//       setFilteredResources(countedResults);
//     }
//   }, [searchTerm]);

//   const handleBackClick = () => {
//     history.push('/resources');
//   };

//   return (
//     <div className="video-resources">
//       <div className="header-container">
//         <Button
//           className="drawer__back-button"
//           onClick={handleBackClick}
//           startIcon={<ArrowBackIosRoundedIcon className="icon" />}
//         >
//           Resources
//         </Button>
//         <div className="title-container">
//           <img src={PayWellLogoBlack} alt="PayWell Logo" className="paywell-logo" />
//           <h1>knowledge base</h1>
//         </div>
//       </div>

//       <SearchBar />

//       <div className="browse-header">
//         <h3>Search Results for "{searchTerm}"</h3>
//       </div>

//       {filteredResources.length > 0 ? (
//         <div className="resources-list">
//           {filteredResources.map((resource) => (
//             <ResourceCard resource={resource} key={resource.name} />
//           ))}
//         </div>
//       ) : (
//         <p>No results found.</p>
//       )}
//     </div>
//   );
// };

// export default SearchResults;

import React, { useState, useEffect } from 'react';
import { useParams, useHistory, Link } from 'react-router-dom';
import { resources } from './resources';
import SearchBar from './SearchBar';
import ResourceCard from './ResourceCard';
import Button from '@mui/material/Button';
import ArrowBackIosRoundedIcon from '@mui/icons-material/ArrowBackIosRounded';
import PayWellLogoBlack from '../../img/PayWellLogoBlack.png';
import './VideoResources.css'; // Use the same CSS file for consistency
import InfoIcon from "@mui/icons-material/Info";
import SettingsIcon from "@mui/icons-material/Settings";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import EventIcon from "@mui/icons-material/Event";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import SwapHorizIcon from "@mui/icons-material/SwapHoriz";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import EmailIcon from "@mui/icons-material/Email";
import ImportExportIcon from "@mui/icons-material/ImportExport";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import CompareIcon from "@mui/icons-material/Compare";
import ListAltIcon from "@mui/icons-material/ListAlt";

const iconMap = {
  InfoIcon: <InfoIcon className="resource-icon" />,
  SettingsIcon: <SettingsIcon className="resource-icon" />,
  LocationOnIcon: <LocationOnIcon className="resource-icon" />,
  EventIcon: <EventIcon className="resource-icon" />,
  AccessTimeIcon: <AccessTimeIcon className="resource-icon" />,
  AutorenewIcon: <AutorenewIcon className="resource-icon" />,
  SwapHorizIcon: <SwapHorizIcon className="resource-icon" />,
  TrendingUpIcon: <TrendingUpIcon className="resource-icon" />,
  AdminPanelSettingsIcon: (
    <AdminPanelSettingsIcon className="resource-icon" />
  ),
  GroupAddIcon: <GroupAddIcon className="resource-icon" />,
  PlayCircleOutlineIcon: <PlayCircleOutlineIcon className="resource-icon" />,
  EmailIcon: <EmailIcon className="resource-icon" />,
  ImportExportIcon: <ImportExportIcon className="resource-icon" />,
  SupportAgentIcon: <SupportAgentIcon className="resource-icon" />,
  ListAltIcon: <ListAltIcon className="resource-icon" />,
  CompareIcon: <CompareIcon className="resource-icon" />,
};

const SearchResults = () => {
  const { searchTerm } = useParams();
  const [filteredResources, setFilteredResources] = useState([]);
  const history = useHistory();

  useEffect(() => {
    const countKeywordOccurrences = (resources, keyword) => {
      return resources
        .map((resource) => {
          let count = 0;
          const searchValues = (obj) => {
            if (typeof obj === 'string') {
              count += (obj.match(new RegExp(keyword, 'gi')) || []).length;
            } else if (Array.isArray(obj)) {
              obj.forEach((element) => searchValues(element));
            } else if (typeof obj === 'object' && obj !== null) {
              Object.values(obj).forEach((value) => searchValues(value));
            }
          };
          searchValues(resource);
          return { ...resource, count };
        })
        .filter((resource) => resource.count > 0);
    };

    if (searchTerm) {
      const countedResults = countKeywordOccurrences(resources, searchTerm);
      setFilteredResources(countedResults);
    }
  }, [searchTerm]);

  const handleBackClick = () => {
    history.push('/resources');
  };

  return (
    <div className="resources video-resources">
      <div className="header-container">
        <Button
          className="drawer__back-button"
          onClick={handleBackClick}
          startIcon={<ArrowBackIosRoundedIcon className="icon" />}
        >
          Resources
        </Button>
        <div className="title-container">
          <img src={PayWellLogoBlack} alt="PayWell Logo" className="paywell-logo" />
          <h1 className="resources__h1">knowledge base</h1>
        </div>
      </div>

      <SearchBar />

      <div className="browse-header">
        <h3 className="resources__h3">Search Results for "{searchTerm}"</h3>
      </div>

      {filteredResources.length > 0 ? (
        <div className="resources-list">
          {filteredResources.map((resource) => (
         
              <Link
              className="resource-card" key={resource.name}
                to={`/resources/${encodeURIComponent(resource.name)}`}
               
              >
                {iconMap[resource.icon] || <InfoIcon className="resource-icon" />}
                <h4 className="resources__h4">{resource.name.replaceAll("-", " ")}</h4>
                <p>{resource.summary}</p>
              </Link>
            
          ))}
        </div>
      ) : (
        <p>No results found.</p>
      )}
    </div>
  );
};

export default SearchResults;
