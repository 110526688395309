import React, { useState, useEffect, useReducer } from "react";
import { useContext } from "react";
import {
  auth,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  sendEmailVerification,
  db,
  signInWithEmailAndPassword,
} from "../firebase";

import {
  signInWithCustomToken
} from "firebase/auth";

import {
  collection,
  getDocs,
  query,
  where,
  updateDoc,
  addDoc,
} from "firebase/firestore";

const AuthContext = React.createContext();
export function useAuth() {
  return useContext(AuthContext);
}

const currentUserReducer = (state, action) => {

  let newState;
  switch (action.type) {
    case "SET_USER":
      newState = action.newUser;

      return action.newUser;

    case "SET_ADMIN_PRIVILEGES":
      newState = state;
      newState["admin"] = action.isAdmin;
      return newState;

    case "SET_ADMIN_SELECTED_USER_UID":
      newState = state;
      newState["adminSelectedUserUID"] = action.userUID;
      return newState;

    default:
      return state;
  }
};

export function AuthProvider({ children }) {
  const [currentUser, dispatch] = useReducer(currentUserReducer, null);
  const [jwtToken, setJwtToken] = useState(null); // New state for storing JWT token
  const [loading, setLoading] = useState(true);
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    const checkAdminStatus = async () => {
      if (currentUser) {
        try {
          const idTokenResult = await currentUser.getIdTokenResult();
          if (idTokenResult.claims.admin) {
            setIsAdmin(true);
          }
        } catch (error) {
          console.error("Error fetching admin status:", error);
        }
      }
    };

    checkAdminStatus();
  }, [currentUser]);
  const clubReadyAccountsCollection =
    process.env.REACT_APP_CLUB_PILATES_ACCOUNTS_FIREBASE_COLLECTION;

  function getReferralCode() {
    let user = currentUser;
    let code = user.email.split("@")[0] + user.uid.slice(0, 3);

    return code;
  }
  async function handleSetAdminSelectedUser(uid) {
    if (currentUser) {
      let tempCurrentUser;
      setCurrentUser((currentUser) => {
        tempCurrentUser = currentUser;

        tempCurrentUser["adminSelectedUser"] = uid;

        return tempCurrentUser;
      });
    }
  }
  function logout() {
    dispatch({ type: "SET_ADMIN_PRIVILEGES", isAdmin: false });
    return auth.signOut();
  }

  function getUID() {
    if (currentUser && currentUser.hasOwnProperty("uid")) {
      return currentUser.uid;
    } else {
      return null;
    }

  }

  // Function to refresh JWT token
  const refreshJwtToken = async () => {
    if (currentUser) {
      try {
        const token = await currentUser.getIdToken(true); // Force token refresh
        setJwtToken(token);
        return token;
      } catch (error) {
        console.error('Error refreshing JWT token:', error);
        setJwtToken(null); // Reset the token on error
        throw error; // Re-throw the error for handling by the caller
      }
    } else {
      throw new Error("User not authenticated");
    }
  };
  const getFirebaseJwtToken = async () => {
    if (!jwtToken) {
      // If token is not available or expired, refresh it

      let token = await refreshJwtToken();

      return token;
    }
    return jwtToken;
  };


  async function verifyEmail(email) {
    return sendEmailVerification(auth.currentUser)
      .then(() => {
        // Email verification sent!

        return "Check your inbox for next steps! (Check spam folder)";
      })
      .catch((e) => {
        console.error(e);
        return e;
      });
  }
  async function signup(email, password, referral = false) {
    try {
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );
      // Signed in
      const user = userCredential.user;

      addReferralDoc(referral, user);
      if (referral) {
      }
      return user;
    } catch (error) {
      const errorCode = error.code;
      const errorMessage = error.message;
      return error;
    }
  }
  async function signInWithClubReady(email, password) {
    let res;
    createUserWithEmailAndPassword(auth, email, password)
      .then(async (u) => {
        await login(email, password);
      })
      .catch(async (error) => {
        switch (error.code) {
          case "auth/email-already-in-use":
            res = "Signing in...";
            await login(email, password);
            break;
          case "auth/invalid-email":
            res = `Email address ${email} is invalid.`;
            break;
          case "auth/operation-not-allowed":
            res = "Error during sign up.";
            break;
          case "auth/weak-password":
            res =
              "Password is not strong enough. Add additional characters including special characters and numbers.";
            break;
          default:
            console.error(error.message);
            res = error.message;
            break;
        }
      });
    return res;
  }
  async function login(email, password) {
    return signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        // Signed in
        const user = userCredential.user;
       
        return user;
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        return error;
      });
  }
  async function loginWithCustomToken(token) {
    return signInWithCustomToken(auth, token)
      .then((userCredential) => {
        const user = userCredential.user;
        return user;
      })
      .catch((error) => {
        console.error('Error logging in with custom token:', error);
        return error;
      });
  }
  async function attemptLogin(email, password) {
    return signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        // Signed in
        const user = userCredential.user;
       
        // ...
        return true;
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        return false;
      });
  }
  async function resetPassword(email) {
    return sendPasswordResetEmail(auth, email)
      .then(() => {
        // Password reset email sent!
        // ..
        return "Password reset email sent! (Check spam folder)";
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        // ..
        return error;
      });
    // return auth.sendPasswordResetEmail(email);
  }
  async function updateClubReadyLogin(email, uid) {
    try {
      const q = query(
        collection(db, clubReadyAccountsCollection),
        where("email", "==", email)
      );
      const querySnapshot = await getDocs(q);

      if (querySnapshot.empty) {
        return false;
      } else {
        let ref;
        querySnapshot.forEach((doc) => {
          let docEmail = doc.get("email");
          if (docEmail === email) {
            ref = doc.ref;
          }
        });

        // Ensure that ref is defined before updating the document
        if (ref) {
          await updateDoc(ref, {
            uid: uid,
            signedInWithClubReady: true,
          });
          return true;
        } else {
          throw new Error("Document reference not found.");
        }
      }
    } catch (error) {
      console.error("Error updating ClubReady login:", error);
      return false; // Return false to indicate failure
    }
  }

  async function addUIDToClubReadyLogin(email, uid) {
    let username = email.split("@")[0];
    await updateClubReadyLogin(username, uid);
  }

  /**
Function addDoc() called with invalid data. Unsupported field value: undefined (found in field refererUid in document referrals/Obnlxg5PCIxfAJ1Szyct)
FirebaseError: Function addDoc() called with invalid data. Unsupported field value: undefined (found in field refererUid in document referrals/Obnlxg5PCIxfAJ1Szyct)
   */
  async function addReferralDoc(referral, newUser) {
    const docRef = await addDoc(collection(db, "referrals"), {
      refererUid: referral.refererUid,
      refereeUid: newUser.uid,
      refereeEmail: newUser.email,
      referralCode: referral.referralCode,
    });
  }
  const isNullish = (object) => {
    if (object == null) {
      return true;
    } else {
      return Object.values(object).every((value) => {
        if (value === null) {
          return true;
        }

        return false;
      });
    }
  };



  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      setLoading(false);



      if (!isNullish(user)) {



        dispatch({
          type: "SET_USER",
          newUser: user,
        });

        addUIDToClubReadyLogin(user.email, user.uid)
          .then((res) => { })
          .catch((err) => {
            console.error(err);
          });

        try {
          await refreshJwtToken(); // Refresh token on auth state change
        } catch (error) {
          console.error('Error fetching JWT token:', error);
        }
      } else {

        dispatch({
          type: "SET_USER",
          newUser: null,
        });
        setJwtToken(null); // Clear the JWT token from state

      }
    });
    return unsubscribe;
  }, []);
  const value = {
    getUID,
    getFirebaseJwtToken,
    refreshJwtToken, // Expose refresh token function if needed
    currentUser,
    signup,
    login,
    logout,
    resetPassword,
    verifyEmail,
    signInWithClubReady,
    attemptLogin,
    dispatch,

    getReferralCode,
    loginWithCustomToken,
    isAdmin
  };
  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
}
