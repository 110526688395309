export class ProcessorConfig {
    // processorName : string
    // paywellTab: "summary" OR "staff"
    constructor(processorName, paywellTab){
        this.processorName = processorName;
        this.paywellTab = paywellTab;
        this.replacements = [];
        this.deleteEmptyRows = false;
        this.payComponents = [];
        this.columns = []; // can have many
    }

    // column: Column
    addColumn(column){
        this.columns.push(column);
    }
}

export class Column {
    constructor(name){
        this.name = name;
        this.staffAttribute = null;
        this.excelAttributes = []; // can have many

        this.constantValue = null;
    }

    addStaffAttribute(staffAttribute){
        this.staffAttribute = staffAttribute;
    }

    addExcelAttributes(excelAttribute){
        this.excelAttributes.push(excelAttribute);
    }

    addConstantValue(value){
        this.constantValue = value;

        this.staffAttribute = null;
        this.excelAttributes = [];
    }

}

export class StaffAttribute{
    // attributeName: string (included not exact equality)
    // method: boolean
    constructor(attributeName, method){
        this.name = attributeName;
        this.method = (method === 'true');
        
        this.duplicate = false;
        this.primaryString = "(Primary)";
    }

    // primaryString: string
    isDuplicate(primaryString){
        this.duplicate = true;
        this.primaryString = primaryString;
    }

}

export class ExcelAttribute{
    // lookupColumn: integer
    // valueColumn: integer
    constructor(valueColumn){
        this.primaryLookupColumn = null;
        this.secondaryLookupColumn = null;

        this.primaryValues = [];
        this.secondaryValues = [];

        this.valueColumn = valueColumn;
        
        this.rowAttribute = null;
        this.secondRowAttribute = null;
    }

    addPrimaryParams(lookupColumn, primaryValues){
        this.primaryLookupColumn = lookupColumn;
        this.primaryValues = primaryValues;
    }

    addSecondaryParams(lookupColumn, primaryValues){
        this.secondaryLookupColumn = lookupColumn;
        this.secondaryValues = primaryValues;
    }

    // value: string (included not exact equality)
    addValue(value){
        this.primaryValues.push(value);
    }

    addValues(values){
        this.primaryValues = values;
    }

    addRowAttribute(rowAttribute){
        this.rowAttribute = rowAttribute;
    }

    addSecondRowAttribute(rowAttribute){
        this.secondRowAttribute = rowAttribute;
    }

}

export class RowAttribute{
    // columnName: string (matching a column name)
    // value: string (included not exact equality)
    constructor(columnName, value){
        this.columnName = columnName;
        this.columnValue = value;
    }
}