import { convertToNumber, convertToString } from "./GeneralTypeConversions.js";

export class InputBox{
    constructor(type, value, defaultValue){
        this.type = type;
        this.value = defaultValue;
        this.addValue(value);
    }

    addValue(value){
        if(this.type === "Percent"){
            this.addPercentValue(value);
        }else if(this.type === "Integer"){
            this.addIntegerValue(value);
        }else if(this.type === "String"){
            this.addStringValue(value);
        }else{
            this.errorMessage = "Unknown type";
        }
    }

    addPercentValue(value){
        let percentValue = convertToNumber(true, value);
        if(percentValue !== null){
            if(percentValue > 1) {
                this.value = percentValue / 100;
            }else {
                this.value = percentValue;
            }
            return;
        }
        this.errorMessage = "value was not of type percent";
    }

    // checkPercentValue(value){
    //     if(typeof value === "string"){
    //         value = parseFloat(value);
    //     }
    //     if(isNaN(value)){
    //         this.errorMessage = "Not a number";
    //         return false;
    //     }
    //     return true;
    // }

    addIntegerValue(value){
        let intValue = convertToNumber(false, value);
        if(intValue !== null){
            this.value = intValue;
            return;
        }
        this.errorMessage = "value was not of type integer";
    }

    // checkIntegerValue(value){
    //     if(typeof value === "string"){
    //         value = parseInt(value);
    //     }
    //     if(isNaN(value)){
    //         this.errorMessage = "Not a number";
    //         return false;
    //     }
    //     return true;
    // }

    addStringValue(value){
        let stringValue = convertToString(value);
        if(stringValue !== null){
            this.value = stringValue;
            return;
        }
        this.errorMessage = "value was not of type string";
    }
}