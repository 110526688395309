import { convertToArray } from "./GeneralTypeConversions.js";

export class ClassAttributeList{
    constructor(name){
        this.name = name;
        this.attributes = [];
    }

    setAttributes(array){
        let convertedArray = convertToArray(array);
        if(convertedArray !== null){
            this.attributes = array;
            return;
        }
        this.errorMessage = "attributes could not be read as array";
    }

    // checkAttributes(array){
    //     if(!Array.isArray(array)){
    //         return false;
    //     }

    //     if(array.length <= 0){
    //         return false;
    //     }

    //     return true;
    // }

    getName(){
        return this.name;
    }

    getAttributes(){
        return this.attributesArray
    }
}