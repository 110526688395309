import React, { useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { LoadingButton } from '@mui/lab';
import { useSettingsContext } from '../../contexts/SettingsContext';
import { useStudioScrapeStatus } from '../../contexts/StudioScrapeStatus/StudioScrapeStatusContext';

import Skeleton from "@mui/material/Skeleton";
import { Button, Typography } from '@mui/material';
import NoDataComponent from '../NoDataComponent/NoDataComponent';
import NoDataImage from "../../img/undraw/undraw_no_data.svg"
import ScrapeStatus from '../ScrapeStatus/ScrapeStatus';
import RadioButtonUncheckedRoundedIcon from '@mui/icons-material/RadioButtonUncheckedRounded';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import RadioButtonCheckedRoundedIcon from '@mui/icons-material/RadioButtonCheckedRounded';
import ReusableDialog from '../Dialogs/ReusableDialog';
import { useStepperContext } from '../LinearStepper/stepperContext';
import { getUrlVariableValue } from '../../utility-functions/utility-functions';
const DynamicTableSkeleton = () => {
    const skeletonRows = [
        {
            "id": "1613f08d-8c35-48fc-9ba6-665f315639b2",
            "payPeriod": "2024-05-05 - 2024-05-06",
            "sessionPath": "UIkrlwJBjRSnCZ4CGwmDk4A2DXR2/05-05-2024_05-06-2024_1715112102701/",
            "timeAgo": "26 minutes ago",
            "timestamp": "1715112102701",
            "settingsId": 1,
            "action": "View Details"
        },
        {
            "id": "7e9a64de-ba1b-4f41-ae55-a819bcfad2fd",
            "payPeriod": "2024-03-25 - 2024-03-26",
            "sessionPath": "UIkrlwJBjRSnCZ4CGwmDk4A2DXR2/03-25-2024_03-26-2024_1715111387311/",
            "timeAgo": "38 minutes ago",
            "timestamp": "1715111387311",
            "settingsId": 1,
            "action": "View Details"
        },
        {
            "id": "c7c8bd87-2f30-49f5-81b1-bfc54ec76917",
            "payPeriod": "2024-04-09 - 2024-04-10",
            "sessionPath": "UIkrlwJBjRSnCZ4CGwmDk4A2DXR2/04-09-2024_04-10-2024_1715110985434/",
            "timeAgo": "44 minutes ago",
            "timestamp": "1715110985434",
            "settingsId": 1,
            "action": "View Details"
        },
        {
            "id": "0c3d5d2a-ec06-439b-b610-d577f3df7f14",
            "payPeriod": "2024-03-19 - 2024-03-20",
            "sessionPath": "UIkrlwJBjRSnCZ4CGwmDk4A2DXR2/03-19-2024_03-20-2024_1715110081911/",
            "timeAgo": "59 minutes ago",
            "timestamp": "1715110081911",
            "settingsId": 1,
            "action": "View Details"
        }

    ]
    return skeletonRows.map((row) => (
        <TableRow key={row.id}>
            <TableCell component="th" scope="row">
                <Skeleton animation="wave" variant='text' className='dynamic-table_skeleton-cell'>
                    <Button style={{ visibility: "hidden" }}> {row.payPeriod}</Button>
                </Skeleton>
            </TableCell>
            <TableCell >
                <Skeleton animation="wave" variant='text' className='dynamic-table_skeleton-cell'>
                    <Button style={{ visibility: "hidden" }}> {row.timeAgo}</Button>
                </Skeleton>
            </TableCell>

            <TableCell>
                <Skeleton animation="wave" variant='text' className='dynamic-table_skeleton-cell'>
                    <Button style={{ visibility: "hidden" }}> {row.settingsId}</Button>
                </Skeleton>
            </TableCell>
            <TableCell>
                <Skeleton animation="wave" variant='text' className='dynamic-table_skeleton-cell'>
                    <Button style={{ visibility: "hidden" }}>
                        Resume Payroll
                    </Button>
                </Skeleton>
            </TableCell>
        </TableRow>
    ))
}


function DynamicTable({ rows, loading, handleInitExistingPayroll }) {
    const {settings} = useSettingsContext();
    const { dispatchDates } = useStudioScrapeStatus();
    const {
        stepperState,
        dispatchStepper,
        handleNextStep,
        handlePrevStep,
    } = useStepperContext();
    const [loadingState, setLoadingState] = useState({});
    const [completionState, setCompletionState] = useState({});

    const [openModal, setOpenModal] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);



    const handleOpenModal = (row) => {
        setSelectedRow(row);
        setOpenModal(true);
    };

    const handleCloseModal = () => setOpenModal(false);
    const handleOnInitExistingPayroll = async (sessionPath, id) => {
        setLoadingState(prev => ({ ...prev, [id]: true }));
        // Reset all other completions except the current one
        setCompletionState(prev => {
            const newCompletionState = {};
            for (const key in prev) {
                newCompletionState[key] = false;
            }
            return newCompletionState;
        });

        await handleInitExistingPayroll(sessionPath);

        setLoadingState(prev => ({ ...prev, [id]: false }));
        // Set only the current one to true
        setCompletionState(prev => ({ ...prev, [id]: true }));
    };

    const handleResumePayroll = async (row) => {
    
        // Update the dates in context to be used by other components or operations
        dispatchDates({
            type: "UPDATE_DATES",
            dates: [row.payPeriodStartDate.replace(/-/g, '/'), row.payPeriodEndDate.replace(/-/g, '/')],
        });
    
        // Set the selected row first and open the modal to give immediate feedback to the user
        setSelectedRow(row);
        handleOpenModal(row);
    
        // Check if data has already been loaded
        if (!completionState[row.id]) {
            // Data has not been loaded yet, show loading state in modal
            setLoadingState(prev => ({ ...prev, [row.id]: true }));
    
            try {
                // Fetch data
                await handleOnInitExistingPayroll(row.sessionPath, row.id);
                // After data is fetched, update the completion state
                setCompletionState(prev => ({ ...prev, [row.id]: true }));
            } catch (error) {
                console.error("Failed to load data:", error);
                // Handle error, maybe show an error message in the modal
            }
    
            // Update loading state after data is fetched or if there was an error
            setLoadingState(prev => ({ ...prev, [row.id]: false }));
        }
    };
    

    return <>
        {loading
            ?
            <TableContainer >
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Pay Period</TableCell>
                            <TableCell>Elapsed Time</TableCell>
                            <TableCell>Preset</TableCell>
                            <TableCell>Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <DynamicTableSkeleton />
                    </TableBody>
                </Table>
            </TableContainer>
            :
            !loading && rows.length < 1
                ?
                <NoDataComponent imageSrc={NoDataImage} message={"No payroll histroy yet! Start by creating a payroll"} />
                : <TableContainer >
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                            <TableRow >
                                <TableCell>Pay Period</TableCell>
                                <TableCell>Elapsed Time</TableCell>
                                <TableCell>Preset</TableCell>
                                <TableCell>Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                rows.map((row) => (
                                    <TableRow key={row.id} style={completionState[row.id] ? { borderLeft: "5px solid #006bff" } : {}}>

                                        <TableCell component="th" scope="row">
                                            {row.payPeriod}
                                        </TableCell>
                                        <TableCell >{row.timeAgo}</TableCell>
                                        <TableCell >{settings[getUrlVariableValue("settingsId")]?.settingsTitle ?? row.settingsId}</TableCell>
                                        <TableCell>
                                            <LoadingButton
                                                variant={completionState[row.id] ? "contained" : "outlined"}
                                                color={completionState[row.id] ? "primary" : "primary"}
                                                loading={loadingState[row.id]}
                                                onClick={() => handleResumePayroll(row)}
                                            >
                                                {completionState[row.id] ? "View Details" : "Resume Payroll"}
                                            </LoadingButton>
                                        </TableCell>
                                    </TableRow>
                                ))
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
        }

        <ReusableDialog
            open={openModal}
            onClose={handleCloseModal}
            title="Payroll Details"
            subtitle={
                selectedRow && <>
                    <Typography>Pay Period: {selectedRow.payPeriod}</Typography>
                    <Typography>Elapsed Time: {selectedRow.timeAgo}</Typography>
                    <Typography>Settings ID: {selectedRow.settingsId}</Typography>
                </>
            }
            actions={[
                {
                    label: "Cancel",
                    onClick: handleCloseModal,
                    color: "primary",
                    variant: "text"
                },
                {
                    label: "Review Payroll",
                    onClick: handleNextStep,
                    color: "primary",
                    variant: "contained",
                    disabled: selectedRow && loadingState[selectedRow.id]
                },
            ]}
        >
            {selectedRow && (
                <>
                    {
                        loadingState[selectedRow.id] && (<><Skeleton width={"500px"} animation="wave" height={"48px"} variant='rounded' /> <Skeleton width={"500px"} animation="wave" height={"48px"} variant='rounded' /></>)
                    }

                    {completionState[selectedRow.id] && < ScrapeStatus />}
                </>
            )}

        </ReusableDialog>

    </>
}

export default DynamicTable;
