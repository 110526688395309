import React, { useState } from "react";
import {
  useTheme,
  useMediaQuery,
  Typography,
  TextField,
  Box,
} from "@mui/material";
import { API } from "aws-amplify";
import { LoadingButton } from "@mui/lab";
import { Helmet } from "react-helmet";

import ResponsiveAppBar from "../../components/AppBar/ResponsiveAppBar";
import {
  Page,
  FeatureList,
  FeatureRow,
} from "../../components/Homepage/HomePage";
import FooterLinkList from "../../components/Footer/FooterLinks";
import "./BetaSignupPage.scss";
import { useSnackBar } from "../../contexts/SnackBarContext/SnackBarContext";
import { useParams } from "react-router-dom";

export default function BetaSignupPage() {
  const { integration } = useParams(); // Get the integration parameter from the URL
  var formattedIntegration = integration;
  if (integration.includes("-")) {
    formattedIntegration = integration.split('-').map(word =>
      word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
    ).join(' ');
  }


  const showSnackBar = useSnackBar();
  const imageUrl = "/assets/images/beta-program/undraw_scientist_ft0o.svg";
  const lookingImage = "/assets/images/beta-program/undraw_filter_re_sa16.svg";
  const lookingForImage =
    "/assets/images/beta-program/undraw_people_search_re_5rre (1).svg";
  const whoWeAreImage =
    "/assets/images/beta-program/undraw_engineering_team_a7n2.svg";
  const youGetImage =
    "/assets/images/beta-program/undraw_happy_news_re_tsbd (1).svg";

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("md"));
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);

  const handleAddToBetaProgramBoard = async (event) => {
    setLoading(true);
    event.preventDefault();

    const itemName = `${email}`;
    const comment = `${email} likes ${formattedIntegration}`;

    const query = `
    mutation ($boardId: ID!, $group_id: String!, $itemName: String!, $columnValues: JSON!) {
      create_item (
        board_id: $boardId,
        group_id: $group_id, 
        item_name: $itemName,
        column_values: $columnValues
      ) {
        id
      }
    }
  `;

    const variables = {
      boardId: parseInt("6542546824"),
      group_id: "topics",
      itemName,
      columnValues: JSON.stringify({
        "name": itemName,
        "text6__1": email, // Make sure this is the correct column ID for emails
        "integration2__1": formattedIntegration,
        "text__1": comment
      }),
    };

    try {
      const response = await API.post("paywellAPIResource", "/MondayApiProxy", {
        body: { query, variables },
      });

      // Check if response includes an error message or success indication
      if (response.data && response.data.create_item && response.data.create_item.id) {
        // Successfully created item
        showSnackBar(`You're on the ${formattedIntegration} integration waitlist!`, "success");
      } else if (response.data && response.data.errors) {
        // Handle API errors (response with error data)
        console.error("API Error:", response.data.errors);
        showSnackBar(`Failed to join the ${formattedIntegration} integration waitlist. Please check your details and try again.`, "error");
      } else {
        // Unknown response format
        console.error("Unexpected API Response:", response);
        showSnackBar("Failed to join the ${formattedIntegration} integration waitlist due to an unexpected error.", "error");
      }

    } catch (error) {
      // Network or other error invoking the API
      console.error("Error calling Lambda function", error);
      showSnackBar("Failed to join the waitlist. Please try again.", "error");
    } finally {
      setLoading(false);
    }
  };

  // Generate SEO-friendly title and description
  const pageTitle = `${formattedIntegration} Integrations | Connect Your Payroll with PayWell`;
  const pageDescription = `Instantly connect ${formattedIntegration} with the PayWell to streamline your payroll process.`


  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={pageDescription} />
        {/* Other meta tags like Open Graph tags for social media can also be added here */}
      </Helmet>
      <ResponsiveAppBar />
      <Box style={{ width: "100%", height: matches ? "104px" : "80px" }} />
      <Page>
        <Box
          className="beta-program"
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            flexDirection: matches ? "row" : "column-reverse",
            gap: theme.spacing(2), // Ensures space between content and image
          }}
        >
          <Box
            style={{
              flex: matches ? 3 : 1, // Full width on mobile
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
            }}
          >
            <Typography
              className="beta-program__title"
              component="h2"
              variant="h2"
            >
              {formattedIntegration} Automation
            </Typography>
            <Typography
              className="beta-program__body"
              component="p"
              variant="body1"
              sx={{ marginBottom: theme.spacing(4) }}
            >
              PayWell bridges the gap between {formattedIntegration} and your payroll processor, so you can automate your work and have more time for what matters most.
            </Typography>
            <Box component="form" sx={{ width: "100%" }} onSubmit={handleAddToBetaProgramBoard}>
              <TextField
                fullWidth
                label="Email"
                variant="outlined"
                required
                sx={{ mb: 2 }}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <LoadingButton
                loading={loading}
                style={{ minHeight: "56px" }}
                variant="contained"
                color="primary"
                size="large"
                fullWidth
                type="submit"
                sx={{ mt: 2, borderRadius: 100 }}

              >
                Get on the list
              </LoadingButton>
            </Box>
          </Box>
          <Box
            style={{
              flex: matches ? 4 : 1, // Slightly larger proportion for the image
              minHeight: matches ? "500px" : "200px", // Smaller minimum height on mobile
              width: "100%", // Ensure full width on mobile

              //  backgroundImage: `url("/assets/images/beta-program/undraw_tasting_re_3k5a.svg")`,//  bad
              // backgroundImage: `url("/assets/images/beta-program/undraw_predictive_analytics_re_wxt8.svg")`, // good
              // backgroundImage: `url("/assets/images/beta-program/undraw_begin_chat_re_v0lw.svg")`, //  good

              backgroundImage: matches
                ? `url("/assets/images/beta-program/undraw_to_the_stars_re_wq2x.svg")`
                : `url("/assets/images/beta-program/undraw_scientist_ft0o.svg")`,

              backgroundSize: matches ? "contain" : "contain", // Cover on desktop, contain on mobile
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
            }}
          />
        </Box>
      </Page>

      <Page noBottomPadding={true}>
        <FeatureList>
          <FeatureRow
            featureHeader="What's in It for You?"
            featureDescription={
              <>
                <p>
                  Personalized Solution: Our tool will be developed based on
                  your feedback, ensuring it addresses your unique payroll
                  challenges and workflows.
                </p>
                <p>
                  Discounted Final Product: As a token of our appreciation, beta
                  testers will receive the final, personalized product at a
                  discounted price.
                </p>
                <p>
                  Save Time and Money: We aim to take on the tedious aspects of
                  payroll management, from maintaining instructor rates and
                  bonuses to handling complex commission structures, giving you
                  back valuable time and money.
                </p>
              </>
            }
            flexDirection="row-reverse"
            image={youGetImage}
            imageStyle={{ maxWidth: "70%" }}
          ></FeatureRow>
          <FeatureRow
            featureHeader="What Are We Looking For?"
            featureDescription={`
                  Owners using ${formattedIntegration} who are committed to fully automating their payroll process.
          
                  Forward-thinkers willing to test and provide feedback on a new
                  payroll tool.
            
                  Owners seeking to streamline and enhance their payroll
                  efficiency.
            `}
            image={lookingForImage}
            imageStyle={{ maxWidth: "70%" }}
          ></FeatureRow>
          <FeatureRow
            featureHeader="Who Are We?"
            featureDescription={`
               
                  We're a team passionate about building payroll automation
                  technologies for boutique fitness studio owners, bridging the
                  gap between ${formattedIntegration} and payroll processors.
               
              
                  Based in Chicago, our startup was founded by two recent
                  college graduates.
            
              
                  Over the past 4 years, we've collaborated with Club Pilates
                  and ClubReady owners, with around 360 locations now using our
                  software every pay period.
               `}
            flexDirection="row-reverse"
            image={whoWeAreImage}
            imageStyle={{ maxWidth: "70%" }}
            alignItems="center"
          ></FeatureRow>
        </FeatureList>
      </Page>
      <FooterLinkList />
    </>
  );
}
