import React, { useEffect, useState } from "react";
import { useTheme } from "@mui/material/styles";
import { useStudioScrapeStatus } from "../../contexts/StudioScrapeStatus/StudioScrapeStatusContext.js";
import { useSettingsContext } from "../../contexts/SettingsContext";
import Box from "@mui/material/Box";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Chip from "@mui/material/Chip";
import { useStepperContext } from "../LinearStepper/stepperContext";
import FormHelperText from "@mui/material/FormHelperText";
import { getUrlVariableValue } from "../../utility-functions/utility-functions.js";

import "./multiSelectChip.scss";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, optionLabel, theme) {
  return {
    fontWeight:
      optionLabel.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

export default function MultipleSelectChip(props) {
  const { settings } = useSettingsContext();
  const [selectError, setSelectError] = useState(null);
  const { dispatchStepper } = useStepperContext();

  const { currentlySelectedStudios, updateCurrentlySelectedStudios } =
    useStudioScrapeStatus();

  const theme = useTheme();

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;

    if (value.length < 1) {
      dispatchStepper({ type: "DISABLE_NEXT_BUTTON" });
      setSelectError(
        <FormHelperText className="multi-select-chip__helper-text">
          Select a {settings[getUrlVariableValue("settingsId")].generalSettings.locationName}
        </FormHelperText>
      );
    } else {
      dispatchStepper({ type: "ENABLE_NEXT_BUTTON" });
      setSelectError(null);
    }
    updateCurrentlySelectedStudios(
      typeof value === "string" ? value.split(",") : value
    );
  };

  async function fetchData() {
    dispatchStepper({ type: "SET_NEXT_BUTTON_LOADING", loading: false });
    updateCurrentlySelectedStudios(
      settings[getUrlVariableValue("settingsId")].generalSettings.studios
    );
  }

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div
      className={`multi-select-chip ${
        selectError !== null ? "multi-select-chip--error" : ""
      }`}
    >
      <FormControl
        className="multi-select-chip__form"
        sx={{ m: 1, width: "auto", minWidth: "12em" }}
      >
        <InputLabel
          className="multi-select-chip__input-label"
          sx={{ backgroundColor: "white" }}
          id="demo-multiple-chip-label"
        >
          Select {settings[getUrlVariableValue("settingsId")].generalSettings.locationsName}
        </InputLabel>
        <Select
          className="multi-select-chip__select"
          labelId="demo-multiple-chip-label"
          id="demo-multiple-chip"
          multiple
          value={currentlySelectedStudios}
          onChange={handleChange}
          input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
          renderValue={(selected) => (
            <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
              {selected.map((value) => (
                <Chip key={value} label={value} />
              ))}
            </Box>
          )}
          MenuProps={MenuProps}
        >
          {settings[
            getUrlVariableValue("settingsId")
          ].generalSettings.studios.map((studio) => (
            <MenuItem
              key={studio}
              value={studio}
              style={getStyles(studio, currentlySelectedStudios, theme)}
            >
              {studio}
            </MenuItem>
          ))}
        </Select>
        {selectError}
      </FormControl>
    </div>
  );
}
