import { TimeEvent } from "../ReportCompiler/classes/TimeEvent.js";
import { findHourlyPayRate } from "../utility-functions.js";
import { ReportData } from "../utility-functions.js";
export function readWhenIWorkFile(
  json,
  settingsPresetStudios,
  studiosInformation
) {
  // Check if json argument is null or undefined
  if (json == null) {
    throw new Error("JSON argument is null or undefined.");
  }

  // Check if json argument is not an array
  if (!Array.isArray(json)) {
    throw new Error("JSON argument is not an array.");
  }
  const timeArray = [];
  let staffInFile = [];

  let firstNameI = 0;
  let lastNameI = 0;
  let positionI = 0;
  let regularI = 0;
  let overtimeI = 0;
  let doubleOvertimeI = 0;
  let holidayI = -1;
  let personalI = 0;
  let sickI = 0;
  let scheduleI = 0;
  let jobSiteI = 0;
  let correctFileType = false;

  for (let i = 0; i < json.length; i++) {
    if (isWiwHeaderRow(json[i])) {
      for (let u = 0; u < json[i].length; u++) {
        if (json[i][u].includes("First Name")) {
          firstNameI = u;
        } else if (json[i][u].includes("Last Name")) {
          lastNameI = u;
        } else if (json[i][u].includes("Position")) {
          positionI = u;
        } else if (json[i][u].includes("Regular")) {
          regularI = u;
        } else if (
          json[i][u].includes("OT") &&
          !json[i][u].includes("Double")
        ) {
          overtimeI = u;
        } else if (json[i][u].includes("Double OT")) {
          doubleOvertimeI = u;
        } else if (json[i][u].includes("Holiday")) {
          holidayI = u;
        } else if (json[i][u].includes("Personal")) {
          personalI = u;
        } else if (json[i][u].includes("Sick")) {
          sickI = u;
        } else if (json[i][u].includes("Schedule")) {
          scheduleI = u;
        } else if(json[i][u].includes("Job Site")) {
          jobSiteI = u;
        }
        // } else if (json[i][u].includes("Paid Total")) {
        //   paidTotalI = u;
        // }
      }
      correctFileType = true;
    } else if (isWiwDataRow(json[i])) {
      if (json[i][positionI] === "Total") {
        continue;
      }
      let name = json[i][firstNameI] + " " + json[i][lastNameI];
      let hourRate = findHourlyPayRate(name, "All", studiosInformation);

      let location = findWIWLocation(json[i][scheduleI], settingsPresetStudios);

      let jobSite = findWIWLocation(json[i][jobSiteI], settingsPresetStudios);

      if(jobSite !== "Unknown Studio") {
        location = jobSite;
      }

      if (parseFloat(json[i][regularI]) !== 0) {
        const newStaff = {
          name: name,
          type: "time",
          location: location,
        };
        
        let isDuplicate = staffInFile.some(staff => 
          staff.name === newStaff.name && staff.type === newStaff.type && staff.location === newStaff.location
        );
        
        if (!isDuplicate) {
          staffInFile.push(newStaff);
        }

        // staffInFile.push({
        //   name: name,
        //   type: "time",
        //   location: location,
        // });
      }

      if (json[i][regularI].length > 0) {
        let timeEvent = createWIWTimeEvent(
          name,
          location,
          json[i][regularI],
          hourRate,
          "Regular",
          json[i][positionI]
        );
        timeArray.push(timeEvent);
      }
      if (json[i][overtimeI].length > 0) {
        let timeEvent = createWIWTimeEvent(
          name,
          location,
          json[i][overtimeI],
          hourRate * 1.5,
          "Overtime",
          json[i][positionI]
        );
        timeArray.push(timeEvent);
      }
      if (json[i][doubleOvertimeI].length > 0) {
        let timeEvent = createWIWTimeEvent(
          name,
          location,
          json[i][doubleOvertimeI],
          hourRate * 2,
          "Double Overtime",
          json[i][positionI]
        );
        timeArray.push(timeEvent);
      }
      if (holidayI >= 0 && json[i][holidayI].length > 0) {
        let timeEvent = createWIWTimeEvent(
          name,
          location,
          json[i][holidayI],
          hourRate,
          "Holiday",
          json[i][positionI]
        );
        timeArray.push(timeEvent);
      }
      if (json[i][personalI].length > 0) {
        let timeEvent = createWIWTimeEvent(
          name,
          location,
          json[i][personalI],
          hourRate,
          "Personal",
          json[i][positionI]
        );
        timeArray.push(timeEvent);
      }
      if (json[i][sickI].length > 0) {
        let timeEvent = createWIWTimeEvent(
          name,
          location,
          json[i][sickI],
          hourRate,
          "Sick",
          json[i][positionI]
        );
        timeArray.push(timeEvent);
      }
    }
  }

  //return { correctFile: correctFileType, timeArray: timeArray };
  const reportData = new ReportData(correctFileType, timeArray);
  reportData.staffInFile = staffInFile;
  return reportData;
  // return {
  //   correctFile: correctFileType,
  //   timeArray: timeArray,
  //   staffInFile: staffInFile,
  // };
}

function isWiwHeaderRow(row) {
  return (
    row[0].includes("First Name") ||
    row[1].includes("Last Name") ||
    row[2].includes("Employee ID")
  );
}

function isWiwDataRow(row) {
  return row[0] !== null && row[0] !== "";
}

function findWIWLocation(locationStr, settingsPresetStudios) {
  let studios = settingsPresetStudios;
  let brokenOutLocation = locationStr.split(" ");

  let studioMatchCounter = [];
  for (let t = 0; t < studios.length; t++) {
    studioMatchCounter.push(0);
  }

  let studioMatchIndexes = [];
  for (let j = 0; j < brokenOutLocation.length; j++) {
    for (let i = 0; i < studios.length; i++) {
      if (studios[i].includes(brokenOutLocation[j])) {
        studioMatchCounter[i] += 1;
      }
    }
  }

  let highestMatchIndex = 0;
  for (let v = 0; v < studioMatchCounter.length; v++) {
    if (studioMatchCounter[v] > studioMatchCounter[highestMatchIndex]) {
      highestMatchIndex = v;
    }
  }
  if (studioMatchCounter[highestMatchIndex] !== 0) {
    return studios[highestMatchIndex];
  }
  return "Unknown Studio";
}

function createWIWTimeEvent(
  name,
  location,
  hoursStr,
  hourRate,
  type,
  position
) {
  let hours = parseFloat(hoursStr);
  return new TimeEvent(
    name,
    location,
    hours,
    hourRate,
    type + " " + position,
    hours * hourRate
  );
}


export function readWhenIWorkFileDetail(json,
  settingsPresetStudios,
  studiosInformation){
    // Check if json argument is null or undefined
  if (json == null) {
    throw new Error("JSON argument is null or undefined.");
  }

  // Check if json argument is not an array
  if (!Array.isArray(json)) {
    throw new Error("JSON argument is not an array.");
  }
  const timeArray = [];
  let staffInFile = [];

  let firstNameI = 0;
  let lastNameI = 0;
  let dateI = 0;
  let startTimeI = 0;
  let endTimeI = 0;
  let scheduleI = 0;
  let jobSiteI = 0;
  let regularI = 0;
  let overtimeI = 0;
  let doubleOvertimeI = 0;
  let correctFileType = false;

  for (let i = 0; i < json.length; i++) {
    if (isWiwHeaderRow(json[i])) {
      for (let u = 0; u < json[i].length; u++) {
        if (json[i][u].includes("First Name")) {
          firstNameI = u;
        } else if (json[i][u].includes("Last Name")) {
          lastNameI = u;
        } else if(json[i][u].includes("Job Site")) {
          jobSiteI = u;
        } else if (json[i][u].includes("Date")) {
          dateI = u;
        } else if (json[i][u].includes("Start Time")) {
          startTimeI = u;
        } else if (json[i][u].includes("End Time")) {
          endTimeI = u;
        } else if (json[i][u].includes("Regular")) {
          regularI = u;
        } else if (json[i][u].includes("Schedule")) {
          scheduleI = u;
        } else if(json[i][u].includes("OT") && !json[i][u].includes("Double")) {
          overtimeI = u;
        } else if(json[i][u].includes("Double OT")) {
          doubleOvertimeI = u;
        }
      }
      correctFileType = true;
    } else if (isWiwDataRow(json[i])) {

      let name = json[i][firstNameI] + " " + json[i][lastNameI];
      let hourRate = findHourlyPayRate(name, "All", studiosInformation);

      let location = findWIWLocation(json[i][scheduleI], settingsPresetStudios);

      let jobSite = findWIWLocation(json[i][jobSiteI], settingsPresetStudios);

      let date = new Date(json[i][dateI]);

      let options = {
        weekday: "short",
        year: "numeric",
        month: "numeric",
        day: "numeric",
      };
      let formattedDate = date.toLocaleString("en-US", options);

      if(jobSite !== "Unknown Studio") {
        location = jobSite;
      }

      if (parseFloat(json[i][regularI]) !== 0 || parseFloat(json[i][overtimeI]) !== 0 || parseFloat(json[i][doubleOvertimeI]) !== 0) {
        const newStaff = {
          name: name,
          type: "time",
          location: location,
        };
        
        let isDuplicate = staffInFile.some(staff => 
          staff.name === newStaff.name && staff.type === newStaff.type && staff.location === newStaff.location
        );
        
        if (!isDuplicate) {
          staffInFile.push(newStaff);
        }
      }

      if (parseFloat(json[i][regularI]) > 0) {
        let timeEvent = createWIWTimeEvent(
          name,
          location,
          parseFloat(json[i][regularI]),
          hourRate,
          "Regular " + formattedDate,
          " (" + json[i][startTimeI] + "-" + json[i][endTimeI] + ")"
        );

        timeArray.push(timeEvent);
      }
      if (parseFloat(json[i][overtimeI]) > 0) {
        let timeEvent = createWIWTimeEvent(
          name,
          location,
          parseFloat(json[i][overtimeI]),
          hourRate * 1.5,
          "Overtime " + formattedDate,
          " (" + json[i][startTimeI] + "-" + json[i][endTimeI] + ")"
        );

        timeArray.push(timeEvent);
      }
      if (parseFloat(json[i][doubleOvertimeI]) > 0) {
        let timeEvent = createWIWTimeEvent(
          name,
          location,
          parseFloat(json[i][doubleOvertimeI]),
          hourRate * 2,
          "Double Overtime " + formattedDate,
          " (" + json[i][startTimeI] + "-" + json[i][endTimeI] + ")"
        );

        timeArray.push(timeEvent);
      }
    }
  }

  const reportData = new ReportData(correctFileType, timeArray);
  reportData.staffInFile = staffInFile;
  return reportData;
  }
