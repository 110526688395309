import React, { useContext, useState, useReducer, useEffect } from "react";
import { API } from "aws-amplify";

// import { Auth } from "aws-amplify";
import { useAuth } from "./AuthContext";
import { db } from "../firebase";
//import firebase from "firebase";

import {
  collection,
  getDocs,
  getDoc,
  addDoc,
  updateDoc,
  deleteDoc,
  doc,
  setDoc,
  query,
  where,
} from "firebase/firestore";
import TransitionAlert from "../components/Alert/TransitionAlert";
import { useSnackBar } from "./SnackBarContext/SnackBarContext";

const ClubReadyContext = React.createContext();
export function useClubReady() {
  return useContext(ClubReadyContext);
}
export const scrapeAccountsReducer = (state, action) => {
  var newState = JSON.parse(JSON.stringify(state));


  switch (action.type) {
    case "SET_ALL_ACCOUNT_STATUS":
      newState.status = action.status;
      if (action.status == "empty") {
        newState["logins"] = [];
      }
      return newState;

    case "SET_ALL_LOGINS":
      if (action.logins?.length > 0) {
        newState["status"] = "done";
        newState["logins"] = action.logins;
      } else {
        newState["status"] = "empty";
        newState["logins"] = [];
      }
      return newState;

    case "ADD_LOGIN":
      newState["logins"].push({
        username: action.username,
        uid: action.uid,
        credentialId: action.credentialId,
        studios: action.studios,
      });
      newState["status"] = "done";
      return newState;

    case "UPDATE_LOGIN":
      if (newState.logins && newState.logins.length > 0) {
        newState.logins = newState.logins.map((account) => {
          if (account.username === action.username) {
            return {
              ...account,
              [action.key]: action.value,
            };
          }
          return account;
        });
      }

      return newState;

    // scrapeAccountsDispatch({
    //   type: "REMOVE_LOGIN",
    //   username: accounts[0].username,
    //   password: accounts[0].password,
    //   tagName: accounts[0]credentialId,
    //   uid: uid,
    //        }
    // })
    case "REMOVE_LOGIN":
      if (newState.logins?.length > 0) {
        newState.logins = newState.logins?.filter(
          (el) => el.username !== action.username && el.username !== action.credentialId
        );
      }

      if (newState.logins?.length == 0) {
        newState["status"] = "empty";
      }
      return newState;

    default:
      return state;
  }
};
export const accountRefreshReducer = (state, action) => {
  var newState = JSON.parse(JSON.stringify(state));
  switch (action.type) {
    // accountRefreshDispatch({
    //   type: "INIT",
    // })
    case "INIT":
      newState["finished"] = false;
      return newState;

    // accountRefreshDispatch({
    //   type: "ADD_REFRESHED_ACCOUNT",
    //   account: {}
    //   allAccounts: []
    // })
    case "ADD_REFRESHED_ACCOUNT":
      newState.refreshedAccounts.push(action.account);

      if (newState.refreshedAccounts.length == action.allAccounts.length) {
        newState["finished"] = true;
      }
      return newState;

    default:
      return state;
  }
};

export function ClubReadyProvider({ children }) {
  const showSnackBar = useSnackBar();

  const [scrapeAccounts, scrapeAccountsDispatch] = useReducer(
    scrapeAccountsReducer,
    { logins: [], status: false }
  );
  const [accountRefresh, accountRefreshDispatch] = useReducer(
    accountRefreshReducer,
    {
      refreshedCount: 0,
      refreshedAccounts: [],
      finished: true,
    }
  );

  const [scrapeVisible, setScrapeVisible] = useState(false);
  const { currentUser, getUID, getFirebaseJwtToken } = useAuth();
  const firebaseCollection =
    process.env.REACT_APP_CLUB_PILATES_ACCOUNTS_FIREBASE_COLLECTION;

  async function getClubReadyLogins() {
    scrapeAccountsDispatch({
      type: "SET_ALL_ACCOUNT_STATUS",
      status: "loading",
    });


    let credentials = await getSecretManagerCredentials();

    // let uid = await getUID();
    // const q = query(
    //   collection(db, firebaseCollection),
    //   where("uid", "==", uid)
    // );
    // const querySnapshot = await getDocs(q);
    // if (querySnapshot.empty) {
    //   setScrapeVisible(false);
    //   return false;
    // }
    // let logins = [];

    // querySnapshot.forEach((doc) => {
    //   logins.push(doc.data());
    // });




    validateAllExistingClubReadyAccounts(credentials?.integrations?.clubready?.credentials ?? []);

    return logins;
  }

  async function addLoginStudioCookies(studio, cookies) {
    try {
      const cookiesRef = await db
        .collection("cookies")
        .doc(currentUser.uid)
        .collection(studio)
        .add(cookies);
    } catch (e) {
      console.warn("Failed adding cookies to firestore. Error: ", e);
    }
  }

  async function deleteClubReadyLogin(tagName, email) {
    const uid = await currentUser.uid;

    let res;
    try {
      const q = query(
        collection(
          db,
          process.env.REACT_APP_CLUB_PILATES_ACCOUNTS_FIREBASE_COLLECTION
        ),
        where("uid", "==", uid)
      );
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach(async function (foundDoc) {
        if (
          foundDoc.data().uid === uid &&
          foundDoc.data().credentialId === tagName
        ) {
          const docRef = doc(
            db,
            process.env.REACT_APP_CLUB_PILATES_ACCOUNTS_FIREBASE_COLLECTION,
            foundDoc.id
          );

          try {
            res = await deleteDoc(docRef);
          } catch (e) {
            console.warn("Error deleting club ready login", e);
            return e;
          }
        }
      });

      scrapeAccountsDispatch({
        type: "REMOVE_LOGIN",
        email: email,
        uid: uid,
        tagName: tagName,
      });
      return true;
    } catch (e) {
      console.warn("Error deleting club ready login", e);
      return e;
    }
  }



  async function addClubReadyLogin(
    email,
    password,
    tagName = "Default Tag",
    studios = []
  ) {
    const uid = await currentUser.uid;
    let docName = tagName + "-" + uid;

    try {
      // const userRef = await db.collection("users").add({
      //   email: email,
      //   password: password,
      //   uid: uid,
      // });

      await setDoc(doc(db, firebaseCollection, docName), {
        email: email,
        password: password,
        uid: uid,
        tagName: tagName,
        studios: studios,
      });

      scrapeAccountsDispatch({
        type: "ADD_LOGIN",
        username: email,
        uid: uid,
        credentialId: tagName,
        studios: studios,
      });

      return true;
    } catch (e) {
      console.warn("FAiled adding cllub ready user login: ", e);
      return e;
    }
  }


  async function addClubReadyUsernameAndPassword(email, password) {
    let emailLowerCase = (email + "").toLowerCase();
    try {
      const docRef = await addDoc(collection(db, firebaseCollection), {
        email: email,
        password: password,
        tagName: email,
        uid: "unset",
      });

      return true;
    } catch (e) {
      console.warn("FAiled adding cllub ready user login: ", e);
      return e;
    }
  }

  async function firebaseClubReadyLoginExists(email, password) {
    const uid = await currentUser.uid;

    try {
      const q = query(
        collection(
          db,
          process.env.REACT_APP_CLUB_PILATES_ACCOUNTS_FIREBASE_COLLECTION
        ),
        where("uid", "==", uid)
      );
      const querySnapshot = await getDocs(q);
      if (!querySnapshot.empty) {
        querySnapshot.forEach(function (foundDoc) {
          if (foundDoc.data().uid === uid && foundDoc.data().email === email) {
            const docRef = doc(
              db,
              process.env.REACT_APP_CLUB_PILATES_ACCOUNTS_FIREBASE_COLLECTION,
              foundDoc.id
            );

            return docRef;
          } else {
            return false;
          }
        });
      } else {
        return false;
      }
    } catch (e) {
      console.warn("Error checking if club ready login exists", e);
      return false;
    }
  }

  async function getStudioNamesFromClubReadyLogin(
    email,
    password,
    studio,
    tagName,
    integration = "clubready"
  ) {
    const token = await getFirebaseJwtToken();

    const username = await currentUser.uid;
    const myAPI = "paywellAPIResource";
    const path = "/getStudioNamesFromClubReadyLogin";
    const event = {
      headers: {
        'Authorization': `Bearer ${token}`
      },
      body: {
        email: email,
        password: password,
        studio: studio,
        credentialId: tagName,
        integration
      },

      queryStringParameters: {
        studio: studio,
        credentialId: tagName,
        integration
      },
    };
    let res = false;

    await API.post(myAPI, path, event)
      .then((response) => {
        res = response;
        return response;
      })
      .catch((error) => {
        res = false;
        console.warn("Failed /getStudioNamesFromClubReadyLogin: ", error);
        return false;
      });
    return res;
  }

  async function addClubReadyAccountLambda(
    email,
    password,
    credentialId,
  ) {

    const token = await getFirebaseJwtToken();

    const myAPI = "paywellAPIResource";
    const path = "/AddClubReadyAccount";
    const event = {
      headers: {
        'Authorization': `Bearer ${token}`
      },
      body: {
        email: email,
        password: password,
        credentialId: credentialId
      },

    };
    let res = false;

    await API.post(myAPI, path, event)
      .then((response) => {
        res = response;
        
        return response;
      })
      .catch((error) => {
        
        console.warn("Failed /AddClubReadyAccount: ", error);

        if (error.response.status == 401 && error.response.data.error) {
          showSnackBar(
            error.response.data.error,
            "error"
          );
          res = { errorMessage: error.response.data.error }
        } else {
          showSnackBar(
            "We encountered a problem. Please try again.",
            "error"
          );
          res = { errorMessage: "We encountered a problem. Please try again." }
        }


      });
    return res;
  }

  async function getClubReadyLoginStudioCookies(email, password, studio) {

    const token = await getFirebaseJwtToken();
    const myAPI = "paywellAPIResource";
    const path = "/getClubReadyLoginStudioCookies";
    const event = {

      headers: {
        'Authorization': `Bearer ${token}`
      },
      body: {
        email: email,
        password: password,
        studio: studio
      },

      queryStringParameters: {
        studio: studio
      }
    };
    let res = null;
    await API.post(myAPI, path, event)
      .then(async (response) => {
        res = response;

        if (res == "unset") {
          res = false;
        }

        return res;
      })
      .catch((error) => {
        console.warn("Failed /getClubReadyLoginStudioCookies: ", error);
        res = false;
        return false;
      });
    return res;
  }

  async function handleClubReadyAccountValidation(
    email,
    password,
    studio,
    tagName
  ) {
    


    let studioNames = false;
    let cookies = false;
    let errors = [];

    if (typeof email !== "string" || typeof password !== "string") {
      console.error(
        "Invalid input types. Expected strings for email, password, and studio."
      );
      return false;
    }

    scrapeAccountsDispatch({
      type: "UPDATE_LOGIN",
      username: email,
      key: "validated",
      value: "pending",
    });

    try {

      cookies = await getClubReadyLoginStudioCookies(email, password);
    } catch (e) {
      console.error(
        "An error occurred during getClubReadyLoginStudioCookies:",
        e
      );
    }
    try {

      studioNames = await getStudioNamesFromClubReadyLogin(
        email,
        password,
        studio,
        tagName
      );
    } catch (e) {
      console.error(
        "An error occurred during getStudioNamesFromClubReadyLogin:",
        e
      );
    }

    if (!studioNames) {
      console.warn(
        "Could not get studio names from the following account:",
        email
      );
      // errors.push( 'Could not get studio names from the following account: ' +
      // email)
    } else {
      // await updateClubReadyLoginStudios({
      //   email,
      //   password,
      //   studioNames,
      //   tagName,
      // });
      scrapeAccountsDispatch({
        type: "UPDATE_LOGIN",
        username: email,
        key: "studios",
        value: studioNames,
      });
    }
    // if (!cookies) {
    //   console.warn("Could not get cookies from the following account:", email);
    //   errors.push("Unable to access Clubready with the current credentials.");
    // }
    if (errors.length > 0) {
      scrapeAccountsDispatch({
        type: "UPDATE_LOGIN",
        username: email,
        key: "validated",
        value: false,
      });
      scrapeAccountsDispatch({
        type: "UPDATE_LOGIN",
        username: email,
        key: "errors",
        value: errors,
      });

      return false;
    }

    scrapeAccountsDispatch({
      type: "UPDATE_LOGIN",
      username: email,
      key: "validated",
      value: true,
    });

    return true;
  }

  async function validateAllExistingClubReadyAccounts(accounts) {
   
    scrapeAccountsDispatch({
      type: "SET_ALL_LOGINS",
      logins: accounts,
    });
    let validatedAccounts = [];
    let errorAccounts = [];
    await Promise.all(
      accounts.map(async (account) => {
        if (
          await handleClubReadyAccountValidation(
            account.username,
            account.password,
            false,
            account.credentialId
          )
        ) {
          validatedAccounts.push(account);
        } else {
          errorAccounts.push(account);
        }
      })
    );

    scrapeAccountsDispatch({
      type: "SET_ALL_ACCOUNT_STATUS",
      status: "done",
    });
    setScrapeVisible(true);

    return accounts;
  }



  const setSecretManagerCredentials = async (credentials) => {
    try {
      const response = await API.post('paywellAPIResource', '/setUserCredentials', {
        body: credentials,
        headers: { 'Content-Type': 'application/json' }
      });
      // Handle the response
     

    } catch (error) {
      // Handle any errors
      console.error(error);
    }
  };

  const addSecretManagerCredential = async (userId, integrationName, newCredential) => {
    const token = await getFirebaseJwtToken();
    const credentials = {
      userId,
      integrationName,
      newCredential,
      action: 'add' // Action set to 'add' for adding a credential
    };

    try {
      const response = await API.post('paywellAPIResource', '/setUserCredentials', {
        body: credentials,
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });
      
      return true;
    } catch (error) {
      console.error('Error adding credential:', error);
      return false;
    }
  };



  const deleteSecretManagerCredential = async (userId, integrationName, credentialId, email) => {
    const token = await getFirebaseJwtToken();
    const credentials = {
      userId,
      integrationName,
      newCredential: { credentialId, username: email },
      action: 'delete'
    };

    try {
      await API.post('paywellAPIResource', '/setUserCredentials', {
        body: credentials,
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });
      return true; // Return true on successful deletion
    } catch (error) {
      console.error('Error deleting credential:', error);
      return false; // Return false on failure
    }
  };




  const getSecretManagerCredentials = async () => {

    try {
      const token = await getFirebaseJwtToken();
      const response = await API.get('paywellAPIResource', '/getUserCredentials', {
        headers: {
          'Authorization': `Bearer ${token}`
        },
      });
      
      return response;
    } catch (error) {
      console.error(error);
      return error;
    }
  };

  useEffect(() => {
    async function fetchData() {
      console.log("Clubready context fetchData...")
      let accounts;

      try {
        accounts = await getClubReadyLogins();
      } catch (e) {
        console.warn("Failed getting Club Ready Account", e);
      }

      if (accounts && accounts[0].password.length <= 0) {
        try {
          accounts = await getClubReadyLogins();
        } catch (e) {
          console.error("Failed getting Club Ready Account", e);
        }
      }

      if (accounts === false) {
        setScrapeVisible(false);
        scrapeAccountsDispatch({
          type: "SET_ALL_ACCOUNT_STATUS",
          status: "empty",
        });
      } else {
        setScrapeVisible(true);
      }
    }

    fetchData();
  }, []);

  // Context
  const value = {
    addClubReadyAccountLambda,
    addSecretManagerCredential,
    deleteSecretManagerCredential,
    addLoginStudioCookies,
    getClubReadyLogins,
    db,
    deleteClubReadyLogin,
    addClubReadyLogin,
    getStudioNamesFromClubReadyLogin,
    firebaseClubReadyLoginExists,
    getClubReadyLoginStudioCookies,
    addClubReadyUsernameAndPassword,
    scrapeVisible,
    setScrapeVisible,
    scrapeAccounts,
    scrapeAccountsDispatch,
    accountRefresh,
    accountRefreshDispatch,
    handleClubReadyAccountValidation,

    setSecretManagerCredentials,
    getSecretManagerCredentials
  };

  // if not loading then we render children of auth provider
  return (
    <ClubReadyContext.Provider value={value}>
      {children}
    </ClubReadyContext.Provider>
  );
}
