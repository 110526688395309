import React, { useEffect } from "react";
import { useClubReady } from "../../contexts/ClubReadyContext";
import { useStudioScrapeStatus } from "../../contexts/StudioScrapeStatus/StudioScrapeStatusContext";
import FileUploadStatus from "../FileUploadStatus";
import Studio from "../Studio";
import "./scrapeStatus.scss";
import { uploadUserMetrics } from "../../services/metrics.services";
import { findStudiosWithoutAccount } from "../../contexts/StudioScrapeStatus/utility-functions.js";
import { useAuth } from "../../contexts/AuthContext";
import { useSettingsContext } from "../../contexts/SettingsContext";
export default function ScrapeStatus(props) {
  const { currentUser, getUID } = useAuth();
  const { reportCompilerState } = useSettingsContext();

  const {
    scrapeSelectedStudios,
    status,
    dispatch,
    currentlySelectedStudios,
    currentlySelectedStudiosDispatch,
    selectedDates,
    newPayroll, 
    setNewPayroll,
  } = useStudioScrapeStatus();
  const { scrapeAccounts } = useClubReady();

  async function onCreateNewPayroll() {
    
    dispatch({
      type: "INIT",
    });
    // If studio does not have an accounnt remove from currennty selected and do not scrape
    let studiosWithoutAccount = findStudiosWithoutAccount(
      currentlySelectedStudios,
      scrapeAccounts.logins
    );
    let studiosWithAccounts = currentlySelectedStudios.filter(
      (studioName) => !studiosWithoutAccount.includes(studioName)
    );
    currentlySelectedStudiosDispatch({
      type: "SET_SELECTED_STUDIOS",
      studios: studiosWithAccounts,
    });

    scrapeSelectedStudios(
      studiosWithAccounts,
      scrapeAccounts,
      reportCompilerState,
      false
    );

    await uploadUserMetrics(
      currentUser,
      getUID(),
      selectedDates,
      currentlySelectedStudios
    );

    setNewPayroll(false);
  }


  useEffect(() => {
    if (newPayroll) {
      onCreateNewPayroll();
    }
  }, [newPayroll]);



  return status.length > 0 ? (
    <div class="scrape-status">
      {Object.keys(status).length > 0
        ? Object.keys(status).map((k) => {
            const studio = status[k];

            return (
              <Studio
                key={studio.key}
                studio={studio.key}
                status={studio.status}
                studioIndex={studio.studioIndex}
                reportComponents={Object.keys(studio.reports).map((j) => {
                  const {
                    status,
                    fileType,
                    fileName,
                    fileIndex,
                    studioIndex,
                    key,
                  } = studio.reports[j];
                  return (
                    <FileUploadStatus
                      status={status}
                      fileType={fileType}
                      fileName={fileName}
                      fileIndex={fileIndex}
                      studioIndex={studioIndex}
                      key={key}
                    />
                  );
                })}
              />
            );
          })
        : null}
    </div>
  ) : null;
}
