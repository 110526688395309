import React, { useState } from "react";
import Typography from "@mui/material/Typography";
import { Button } from "@mui/material";
import InputBoxElement from "../../Components/InputBox/InputBox";
import SelectorBox from "../../Components/SelectorBox/SelectorBox";
import { Stack, Box, Divider } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { IconButton } from "@mui/material";
import { RevertList } from "../../Components/RevertList";
import { v4 as uuidv4 } from "uuid";
import { useSettingsContext } from "../../../contexts/SettingsContext";
import { MultipleSelectPlaceholder } from "../../Components/SelectorBox/SelectorBox";
import BasicModal from "../../../components/Modal/Modal";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import Avatar from "@mui/material/Avatar";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import { useAuth } from "../../../contexts/AuthContext";
import { getUrlVariableValue } from "../../../utility-functions/utility-functions.js";
function compareNumberIndex(a, b) {
  const [firstId, firstObj] = a;
  const [secondId, secondObj] = b;
  let firstIndex = firstObj.exceptionNumber;
  let secondIndex = secondObj.exceptionNumber;

  return firstIndex - secondIndex;
}

export default function CommissionCatchAllList() {
  const { settings, dispatch, changes, setChanges } = useSettingsContext();
  
  const { currentUser, getUID } = useAuth();

  const [exceptions, setExceptions] = useState(
    settings[getUrlVariableValue("settingsId")].commissionSettings.exceptions
  );

  const [modalException, setModalException] = useState(null);

  const handleAddException = (event) => {
    
    let id = uuidv4();

    let newRev = new RevertList(changes.getValues());
    newRev.addElement("commissionException-" + id, "n/a", "added");
    setChanges(newRev);

    const defaultCommissionException = {
      type: [],
      name: "text~",
      studio: [],
      label: "Default Exception",
      comment: "text~",
      priceSequence: "ANY PRICE",
      price: "dollar~",
      rateFrequency: "Never Pay",
      rateNormal: "Regular Rate",
      rate: "percent~0",
      rateSplit: "Split between",
      rateSalespeople: ["Closing Salesperson"],
      id: id,
      exceptionNumber:
        Object.entries(
          settings[getUrlVariableValue("settingsId")].commissionSettings.exceptions
        ).length + 1,
    };

    // setExceptions({ ...exceptions, [id]: defaultCommissionException });

    // setStudioSettings({
    //   ...studioSettings,
    //   commissionSettings: {
    //     ...studioSettings.commissionSettings,
    //     exceptions: {
    //       ...studioSettings.commissionSettings.exceptions,
    //       [id]: defaultCommissionException,
    //     },
    //   },
    // });

    dispatch({
      type: "UPDATE_SETTINGS_PROPERTY",
      settingsId: getUrlVariableValue("settingsId"),
      uid: getUID(),
      path: `${getUrlVariableValue("settingsId")}.commissionSettings.exceptions.${id}`,
      value: defaultCommissionException,
    });

        setModalException(id);

  };

  const handleDeleteException = (id) => {
    
    let newRev = new RevertList(changes.getValues());
    newRev.addElement("commissionException-" + id, "n/a", "delete");
    setChanges(newRev);

    let exceptionList = {
      ...settings[getUrlVariableValue("settingsId")].commissionSettings.exceptions,
    };
    let exceptionNumber = exceptionList[id].exceptionNumber;

    Object.keys(exceptionList).forEach(function(key) {
      exceptionList[key].exceptionNumber > exceptionNumber
        ? exceptionList[key].exceptionNumber--
        : exceptionList[key].exceptionNumber;
    });

    delete exceptionList[id];

    // setExceptions(exceptionList);

    // setStudioSettings({
    //   ...studioSettings,
    //   commissionSettings: {
    //     ...studioSettings.commissionSettings,
    //     exceptions: exceptionList,
    //   },
    // });
    dispatch({
      type: "UPDATE_SETTINGS_PROPERTY",
      settingsId: getUrlVariableValue("settingsId"),
      uid: getUID(),
      path: `${getUrlVariableValue("settingsId")}.commissionSettings.exceptions`,
      value: exceptionList,
    });

    setModalException(null);
  };

  const handleEditException = (id) => {
    setModalException(id);
  };

  const handleModalClose = (id) => {
    setModalException(null);
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 700,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
    minWidth: 800,
    minHeight: 400,
  };

  return (
    <Box className="settings-page">
      <Stack
        sx={{ width: 1 }}
        spacing={1}
        key={"exception-type-stack"}
        direction="column"
      >
        {Object.entries(settings[getUrlVariableValue("settingsId")].commissionSettings.exceptions)
          .sort(compareNumberIndex)
          .map(([key, entry], index) => (
            <Card key={"exception" + entry.id} sx={{ maxWidth: 700 }}>
              <CardHeader
                key={"exception-header" + entry.id}
                avatar={
                  <Avatar
                    key={"exception-avatar" + entry.id}
                    sx={{ bgcolor: "#1976d2" }}
                  >
                    {entry.exceptionNumber}
                  </Avatar>
                }
                action={
                  <BasicModal
                    handleOnClose={handleModalClose}
                    openIcon={
                      <IconButton
                        aria-label="settings"
                        key={"exception-type-edit" + index}
                        onClick={() => {
                          
                          handleEditException(entry.id);
                        }}
                        size="large"
                      >
                        <EditRoundedIcon sx={{ color: "#1976d2" }} />
                      </IconButton>
                    }
                  >
                    <CommissionCatchAllBox
                      key={"commission-exception" + modalException}
                      value={modalException}
                    ></CommissionCatchAllBox>
                    <Divider className="settings__divider" />
                    <Button
                      sx={{ color: "red" }}
                      variant="outlined"
                      startIcon={<DeleteIcon sx={{ color: "red" }} />}
                      key={"class-type-delete" + modalException}
                      onClick={() => {
                        handleDeleteException(modalException);
                      }}
                    >
                      Delete
                    </Button>
                  </BasicModal>
                }
                title={
                  <Typography
                    gutterBottom
                    key={"exception-title" + entry.id}
                    component="div"
                  >
                    {entry.label}
                  </Typography>
                }
                // subheader={getSubheader(entry.columnType)}
              />
            </Card>
          ))}
      </Stack>

      <Button onClick={handleAddException} variant="contained">
      {"Add " + settings[getUrlVariableValue("settingsId")].generalSettings.commissionName + " Exception"}
      </Button>
    </Box>
  );
}

function CommissionCatchAllBox(props) {
  const { settings, dispatch, changes, setChanges } = useSettingsContext();
  
  const { currentUser, getUID } = useAuth();

  const [values, setValues] = useState(
    settings[getUrlVariableValue("settingsId")].commissionSettings.exceptions[props.value]
  );

  const studioOptions = [
    ...settings[getUrlVariableValue("settingsId")].generalSettings.studios,
  ];

  const createExceptionLabel = (tempValues) => {
    let label = "";
    let count = 0;
    if (tempValues.name !== "text~") {
      label =
        label + ("Item Name: " + tempValues.name.replace("text~", "") + " & ");
      count++;
    }
    if (tempValues.type.length !== 0) {
      label = label + ("Type: " + tempValues.type + " & ");
      count++;
    }
    if (count < 3 && tempValues.priceSequence !== "ANY PRICE") {
      label =
        label +
        ("Price: " +
          tempValues.priceSequence +
          " $" +
          tempValues.price.replace("dollar~", "") +
          " & ");
      count++;
    }
    if (count < 3 && tempValues.studio.length !== 0) {
      label = label + ("Studio: " + tempValues.studio + " & ");
      count++;
    }
    if (count < 3 && tempValues.comment !== "text~") {
      label =
        label + ("Note: " + tempValues.comment.replace("text~", "") + " & ");
      count++;
    }

    if (label.length === 0) {
      label = "Default Exception & ";
    }

    label = label.substring(0, label.length - 3);

    return label;
  };

  const getCommissionTypes = () => {
    let types = [];
    let configTypes = Object.keys(
      settings[getUrlVariableValue("settingsId")].generalSettings.commissionTypes
    );
    types = [...configTypes.sort()];
    return types;
  };

  const handleChange = (value, id) => {
    let tempValues = { ...values, [id]: value };
    let label = createExceptionLabel(tempValues);

    // replace this with dispatch
    setValues({
      ...settings[getUrlVariableValue("settingsId")].commissionSettings.exceptions[
        props.value
      ],
      [id]: value,
      ["label"]: label,
    });

    let newRev = new RevertList(changes.getValues());
    newRev.addElement(
      values.id + "-" + id,
      settings[getUrlVariableValue("settingsId")].commissionSettings.exceptions[
        values.id
      ][id],
      value
    );
    setChanges(newRev);

    // setStudioSettings({
    //   ...studioSettings,
    //   commissionSettings: {
    //     ...studioSettings.commissionSettings,
    //     exceptions: {
    //       ...studioSettings.commissionSettings.exceptions,
    //       [values.id]: {
    //         ...studioSettings.commissionSettings.exceptions[values.id],
    //         [id]: value,
    //         ["label"]: label,
    //       },
    //     },
    //   },
    // });
    dispatch({
      type: "UPDATE_SETTINGS_PROPERTY",
      settingsId: getUrlVariableValue("settingsId"),
      uid: getUID(),
      path: `${getUrlVariableValue("settingsId")}.commissionSettings.exceptions.${
        values.id
      }.${id}`,
      value: value,
    });
    dispatch({
      type: "UPDATE_SETTINGS_PROPERTY",
      settingsId: getUrlVariableValue("settingsId"),
      uid: getUID(),
      path: `${getUrlVariableValue("settingsId")}.commissionSettings.exceptions.${
        values.id
      }.label`,
      value: label,
    });
  };

  return (
    <Box className="catch-all">
      <MultipleSelectPlaceholder
        className="catch-all__selector-box"
        key="type"
        id="type"
        onChange={handleChange}
        label="Type"
        default={
          settings[getUrlVariableValue("settingsId")].commissionSettings.exceptions[
            props.value
          ]?.type ?? []
        }
        options={getCommissionTypes()}
        size={300}
        name="Type"
        placeholder="ANY COMMISSIONABLE ITEM"
      ></MultipleSelectPlaceholder>

      {/* <SelectorBox
          className="catch-all__selector-box"
            key="type"
            id="type"
            onChange={handleChange}
            label="Type"
            default={[values.type]}
            size={300}
            options={getCommissionTypes(true)}
            name="Staff"
          ></SelectorBox> */}
      <Typography className="catch-all__text "> sold</Typography>

      <Typography className="catch-all__text ">with a name </Typography>
      <InputBoxElement
        className="catch-all__input-box"
        key="name"
        id="name"
        placeholder="ANY NAME"
        onChange={handleChange}
        default={
          settings[getUrlVariableValue("settingsId")].commissionSettings.exceptions[
            props.value
          ]?.name
        }
        types={["text"]}
        style={{ width: 300 }}
        size={200}
        label="Name"
      ></InputBoxElement>

      <Typography className="catch-all__text ">, sold at </Typography>
      <MultipleSelectPlaceholder
        className="catch-all__selector-box"
        key="studio"
        id="studio"
        onChange={handleChange}
        label="Studio"
        default={
          settings[getUrlVariableValue("settingsId")].commissionSettings.exceptions[
            props.value
          ]?.studio
        }
        options={studioOptions}
        size={200}
        name="Studio"
        placeholder="ALL STUDIOS"
      ></MultipleSelectPlaceholder>
      {/* <SelectorBox
            key="studio"
            id="studio"
            onChange={handleChange}
            label="Studio"
            default={values.studio}
            options={studioOptions}
            size={200}
            name="Studio"
          ></SelectorBox> */}

      <Typography className="catch-all__text ">at price </Typography>
      <SelectorBox
        className="catch-all__selector-box"
        key="priceSequence"
        id="priceSequence"
        onChange={handleChange}
        label="Sequence"
        default={
          settings[getUrlVariableValue("settingsId")].commissionSettings.exceptions[
            props.value
          ]?.priceSequence
        }
        options={[
          "ANY PRICE",
          "Greater than",
          "Less than",
          "Equal to",
          "Not equal to",
        ]}
        size={200}
        name="Sequence"
      ></SelectorBox>
      {settings[getUrlVariableValue("settingsId")].commissionSettings.exceptions[
        props.value
      ]?.priceSequence !== "ANY PRICE" ? (
        <InputBoxElement
          className="catch-all__input-box"
          key="price"
          id="price"
          onChange={handleChange}
          default={
            settings[getUrlVariableValue("settingsId")].commissionSettings.exceptions[
              props.value
            ]?.price
          }
          types={["dollar"]}
          size={100}
          label="Name"
        ></InputBoxElement>
      ) : null}
      <Typography className="catch-all__text ">
        , with notes including{" "}
      </Typography>
      <InputBoxElement
        className="catch-all__input-box"
        key="comment"
        id="comment"
        onChange={handleChange}
        default={
          settings[getUrlVariableValue("settingsId")].commissionSettings.exceptions[
            props.value
          ]?.comment
        }
        types={["text"]}
        size={200}
        label="Name"
      ></InputBoxElement>

      <Typography className="catch-all__text "> then </Typography>

      <SelectorBox
        className="catch-all__selector-box"
        key="rateFrequency"
        id="rateFrequency"
        onChange={handleChange}
        label="Pay"
        default={
          settings[getUrlVariableValue("settingsId")].commissionSettings.exceptions[
            props.value
          ]?.rateFrequency
        }
        options={["Never Pay", "Always Pay", "Pay As Normal"]}
        size={200}
        name="Pay"
      ></SelectorBox>
      {settings[getUrlVariableValue("settingsId")].commissionSettings.exceptions[
        props.value
      ]?.rateFrequency !== "Never Pay" ? (
        <>
          <Typography className="catch-all__text "> at a </Typography>
          <SelectorBox
            className="catch-all__selector-box"
            key="rateNormal"
            id="rateNormal"
            onChange={handleChange}
            label="Pay"
            default={
              settings[getUrlVariableValue("settingsId")].commissionSettings.exceptions[
                props.value
              ]?.rateNormal
            }
            options={["Regular Rate", "Custom Rate"]}
            size={200}
            name="Pay"
          ></SelectorBox>
        </>
      ) : null}
      {settings[getUrlVariableValue("settingsId")].commissionSettings.exceptions[
        props.value
      ]?.rateNormal === "Custom Rate" ? (
        <>
          <Typography className="catch-all__text "> of </Typography>
          <InputBoxElement
            className="catch-all__input-box catch-all__input-box--full-width"
            key="rate"
            id="rate"
            onChange={handleChange}
            default={
              settings[getUrlVariableValue("settingsId")].commissionSettings.exceptions[
                props.value
              ]?.rate
            }
            types={["percent", "dollar"]}
            size={200}
            label="Name"
          ></InputBoxElement>
          <SelectorBox
            className="catch-all__selector-box"
            onChange={handleChange}
            id="rateSplit"
            default={
              settings[getUrlVariableValue("settingsId")].commissionSettings.exceptions[
                props.value
              ]?.rateSplit
            }
            size={200}
            options={["Split between", "Given to each,"]}
            label="Split?"
            name="Split"
          ></SelectorBox>
          {/* <SelectorBox
            className="catch-all__selector-box"
            onChange={handleChange}
            id="rateSalespeople"
            default={values.rateSalespeople}
            multiple
            size={500}
            options={[
              "Opening Salesperson",
              "Closing Salesperson",
              "Instructor",
              "Secondary Salesperson",
            ]}
            label="Salespeople"
            name="Salespeople"
          ></SelectorBox> */}
          <MultipleSelectPlaceholder
            className="catch-all__selector-box"
            id="rateSalespeople"
            onChange={handleChange}
            label="Salespeople"
            default={
              settings[getUrlVariableValue("settingsId")].commissionSettings.exceptions[
                props.value
              ]?.rateSalespeople
            }
            options={[
              "Opening Salesperson",
              "Closing Salesperson",
              "Instructor",
              "Secondary Salesperson",
            ]}
            size={500}
            name="Salespeople"
            placeholder="Select Salespeople"
          ></MultipleSelectPlaceholder>
        </>
      ) : null}
    </Box>
  );
}
