import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  DialogActions,
  DialogContentText,
} from "@mui/material";
import EditStaffComponent from "./EditStaffComponentSharedToggles";

const EditStaffDialog = ({ open, onClose, onSave, staff }) => {

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle component={"h3"}>Edit Staff: {staff.name}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Update staff info and rates for {staff.name}
        </DialogContentText>
        <EditStaffComponent staff={staff} onSave={onSave} onClose={onClose} />
      </DialogContent>
    </Dialog>
  );
};

export default EditStaffDialog;
