import { TimeEvent } from "../ReportCompiler/classes/TimeEvent.js";
import { ReportData } from "../utility-functions.js";
//import { findHourlyPayRate } from "../PayWellFunctions/FindHourlyPayRate";

export function paycomFileConverter(json, reportCompilerState) {
  const timeArray = [];

  //Allocation Department Code
//   Allocation Payroll Profile Code
//   Allocation Location Code
//   Allocation Job Type Code
//   Allocation Area of Work Code
//   Last Name
//   First Name
//   Earn Code
//   Pay Class
//   Earn Hours
//   Earn Rate
//   Earn Amount
//   Allocation Department Desc
//   Allocation Payroll Profile Desc
//   Allocation Location Desc
//   Allocation Job Type Desc
//   Allocation Area of Work Desc
let staffInFile = [];
let lastNameI = 0;
let firstNameI = 0;
let deptI = 0;
let payrollProfileI = 0;
let locationNumI = 0;
let locationNameI = 0;
let jobTypeI = 0;
let areaOfWorkI = 0;
let earningCodeI = 0;
let hoursI = 0;
let rateI = 0;


  let correctFileType = false;
  for (let i = 0; i < json.length; i++) {
    if (isPaycomHeaderRow(json[i])) {
      for (let u = 0; u < json[i].length; u++) {
        if (json[i][u].includes("Last Name")) {
          lastNameI = u;
        } else if (json[i][u].includes("First Name")) {
            firstNameI = u;
        } else if (json[i][u].includes("Allocation Department Code")) {
            deptI = u;
        } else if (json[i][u].includes("Allocation Payroll Profile Code")) {
          payrollProfileI = u;
        } else if (json[i][u].includes("Allocation Location Code") ) {
            locationNumI = u;
        } else if (json[i][u].includes("Allocation Location Desc") ) {
          locationNameI = u;
        } else if (json[i][u].includes("Allocation Job Type Code")) {
          jobTypeI = u;
        } else if (json[i][u].includes("Allocation Area of Work Code")) {
            areaOfWorkI = u;
        } else if (json[i][u].includes("Earn Code")) {
            earningCodeI = u;
        } else if (json[i][u].includes("Earn Hours")) {
            hoursI = u;
        } else if (json[i][u].includes("Earn Rate")) {
            rateI = u;
        }
      }
      correctFileType = true;
    } else if (isPaycomDataRow(json[i])) {
      let location = findLocation(json[i][locationNameI]);

      let name = normalizeNames(json[i][firstNameI], json[i][lastNameI]);

      let pipeValue = json[i][earningCodeI] + ' (' + json[i][deptI] + '|' + json[i][payrollProfileI] + '|' + json[i][locationNumI] + '|' + json[i][jobTypeI] + '|' + json[i][areaOfWorkI] + ')';

      //let hourRate = findHourlyPayRate(name, "All");

      if(parseFloat(json[i][hoursI]) !== 0){
        const newStaff = {
          name: name,
          type: "time",
          location: location,
        };
        
        let isDuplicate = staffInFile.some(staff => 
          staff.name === newStaff.name && staff.type === newStaff.type && staff.location === newStaff.location
        );
        
        if (!isDuplicate) {
          staffInFile.push(newStaff);
        }
        // staffInFile.push({
        //     name: name,
        //     type: "time",
        //     location: location,
        //   });
      }

      const keyValuePairs = {
        "6CV": "PSL Self",
        "7CV": "PSL Family/Dependent",
        "8CV": "PFL Dependent",
        "9CV": "COVID 19 Health Expenses",
        "ADE": "Additional Earnings",
        "BON": "Bonus",
        "CCC": "Class Commission",
        "CLP": "CA Lunch",
        "CMS": "Commission",
        "DOT": "Double Weighted Overtime",
        "DRF": "Director Fees",
        "DRR": "Department Rates",
        "HOL": "Holiday",
        "O": "Overtime",
        "OTR": "One Time Reimbursement",
        "P": "Personal",
        "PDR": "Personal Device Reimbursement",
        "PTO": "Paid Time Off",
        "R": "Regular",
        "S": "Sick",
        "TRB": "One Time Reimbursement",
        "UPD": "Unpaid Time Off",
        "V": "Vacation",
        "WOT": "Weighted Overtime",
      };

        if(!isNaN(parseFloat(json[i][hoursI])) && reportCompilerState.payrollInformation.studiosInInput.includes(location)){
            let timeEvent = createTimeEvent(name, location, parseFloat(json[i][hoursI]), parseFloat(json[i][rateI]), keyValuePairs[json[i][earningCodeI]], '');
            timeArray.push(timeEvent);
        }
    }
  }

  let reportData = new ReportData(correctFileType, timeArray);
  reportData.staffInFile = staffInFile;
  return reportData;
}

function isPaycomHeaderRow(row){
  return (
    row[0].includes("Allocation Department Code") ||
    row[1].includes("Allocation Payroll Profile Code") ||
    row[2].includes("Allocation Location Code")
  );
}

function isPaycomDataRow(row){
  return (row[0] !== null && row[0] !== "");
}

function normalizeNames(firstName, lastName) {
    // First, make sure all letters are lower case
    let lowerFirstName = firstName.toLowerCase();
    let lowerLastName = lastName.toLowerCase();

    // Then, capitalize the first letter of each name
    let normalizedFirstName = lowerFirstName.charAt(0).toUpperCase() + lowerFirstName.slice(1);
    let normalizedLastName = lowerLastName.charAt(0).toUpperCase() + lowerLastName.slice(1);

    // Finally, return the names in the correct order (first name, then last name)
    return normalizedFirstName + ' ' + normalizedLastName;
}

function createTimeEvent(name, location, hoursStr, hourRate, type, position){
  let hours = parseFloat(hoursStr);
  return new TimeEvent(
    name,
    location,
    hours,
    hourRate,
    type + " " + position,
    hours * hourRate
  );
}


function findLocation(locationStr){
    let studios = [
        "Club Pilates Oro Valley",
        "Club Pilates Tucson Foothills",
        "Club Pilates Cherry Creek",
        "Club Pilates Mercer Island",
        "Club Pilates Echo Park",
        "Club Pilates WeHo",
        "Club Pilates Happy Valley",
        "Club Pilates Lake Oswego",
        "Club Pilates Progress Ridge",
        "Club Pilates West Hills",
        "Club Pilates West Linn",
        "Club Pilates Tualatin - Sherwood",
        "Club Pilates Cedar Hills",
        "Club Pilates Yorba Linda",
        "Club Pilates Anaheim Hills",
        "Club Pilates Newport Beach",
        "Club Pilates Los Olivos",
        "Club Pilates Alton Square",
        "Club Pilates Mission Viejo",
        "Club Pilates Laguna Niguel",
        "Club Pilates Dana Point",
        "Club Pilates Fremont",
        "Club Pilates Yorba Linda",
        "Club Pilates Anaheim Hills",
        "Club Pilates Newport Beach",
        "Club Pilates Los Olivos",
        "Club Pilates Alton Square",
        "Club Pilates Mission Viejo",
        "Club Pilates Laguna Niguel",
        "Club Pilates Dana Point",
        "Corporate  Office"
    ];
    let location = closestMatch(locationStr, studios);

    return location;
  }

  function closestMatch(str, arr) {
  let minDistance = 6;
  let closestStr = "";

  for (let i = 0; i < arr.length; i++) {
    const distance = levenshteinDistance(str, arr[i]);

    if (distance < minDistance) {
      minDistance = distance;
      closestStr = arr[i];
    }
  }

  return closestStr;
}

function levenshteinDistance(s, t) {
  const m = s.length;
  const n = t.length;
  const d = [];

  for (let i = 0; i <= m; i++) {
    d[i] = [i];
  }

  for (let j = 0; j <= n; j++) {
    d[0][j] = j;
  }

  for (let j = 1; j <= n; j++) {
    for (let i = 1; i <= m; i++) {
      if (s[i - 1] === t[j - 1]) {
        d[i][j] = d[i - 1][j - 1];
      } else {
        d[i][j] = Math.min(
          d[i - 1][j] + 1,    // deletion
          d[i][j - 1] + 1,    // insertion
          d[i - 1][j - 1] + 1 // substitution
        );
      }
    }
  }

  return d[m][n];
}