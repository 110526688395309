// import XLSX from "xlsx";
import XLSX from "@sheet/core";

export async function readExcelSheet(file){
    const data = await file.arrayBuffer();
    const workbook = XLSX.read(data, {
      cellFormula: true,
      sheetStubs: true,
      cellNF: true,
      bookDeps: true,
    });
    return workbook;
  }

export function findFormulaArrays(workbook){
    let formulaArrays = [];
    for (let i = 4; i < workbook.SheetNames.length; i++) {
      let sheetName = workbook.Sheets[workbook.SheetNames[i]];
      formulaArrays.push(XLSX.utils.sheet_to_formulae(sheetName));
    }
    return formulaArrays;
  }