import { newStudioSettings } from "../../pages/StudioSettings/CommonVariables.js";
import { newStaffSettings } from "../../pages/StudioSettings/CommonVariables.js";
import { v4 as uuidv4 } from "uuid";
export function RenderObjectData({ data }) {
  // if (data == null) {
  //
  // }

  return (
    <ul>
      {data !== null && data && typeof data === "object" ? (
        Object.keys(data).map((key) => (
          <li key={key}>
            {key}:{" "}
            {typeof data[key] === "object" ? (
              <RenderObjectData data={data[key]} />
            ) : (
              JSON.stringify(data[key])
            )}
          </li>
        ))
      ) : (
        <li>{JSON.stringify(data)}</li>
      )}
    </ul>
  );
}
export function checkStudioSettingsFormat(studioSettings, uid) {
  newStudioSettings.uid = uid;

  for (const [key, value] of Object.entries(studioSettings)) {
    if (key === "staff") {
      continue;
    }
    const newObject = JSON.parse(JSON.stringify(value)); // Create a deep copy of the object

    //change how zero class buckets are handled
    let buckets = Object.entries(newObject.classSettings.classBuckets);
    for (let i = 0; i < buckets.length; i++) {
      if(buckets[i][1].includeZero === true){
        newObject.classSettings.classBuckets[buckets[i][0]].includeZero = "Paid A Different Amount";
      } else if(buckets[i][1].includeZero === false){
        newObject.classSettings.classBuckets[buckets[i][0]].includeZero = "Not Paid";
      }

      if(buckets[i][1].includesSecondaryName === undefined){
        newObject.classSettings.classBuckets[buckets[i][0]].includesSecondaryName = false;
      }
    }

    const hasAllFields = deepCompare(newObject, newStudioSettings);

    if (!hasAllFields) {
      deepAddFields(newObject, newStudioSettings);
      //newObject.UPDATED = true;
    }
    studioSettings[key] = newObject;
  }

  return studioSettings;
}

function checkClassExceptions(studioSettings) {
  let classExceptions = Object.values(studioSettings.classSettings.exceptions);
  for (let i = 0; i < classExceptions.length; i++) {
    let id = classExceptions[i].id;
    let exception = classExceptions[i];

    exception.attendeeNumber === undefined
      ? (studioSettings.classSettings.exceptions[id].attendeeNumber =
          "number~0")
      : null;
    exception.attendeeSequence === undefined
      ? (studioSettings.classSettings.exceptions[id].attendeeSequence =
          "ANY NUMBER OF")
      : null;
    exception.day === undefined
      ? (studioSettings.classSettings.exceptions[id].day = "ANY DAY")
      : null;
    exception.instructor === undefined
      ? (studioSettings.classSettings.exceptions[id].instructor = [])
      : null;
    exception.name === undefined
      ? (studioSettings.classSettings.exceptions[id].name = "")
      : null;
    exception.override === undefined
      ? (studioSettings.classSettings.exceptions[id].override = true)
      : null;
    exception.payAmount === undefined
      ? (studioSettings.classSettings.exceptions[id].payAmount = "dollar~0")
      : null;
    exception.paySequence === undefined
      ? (studioSettings.classSettings.exceptions[id].paySequence =
          "ANY AMOUNT OF MONEY")
      : null;

    exception.rate === undefined
      ? (studioSettings.classSettings.exceptions[id].rate = "a bonus of")
      : null;
    exception.rateValueOne === undefined
      ? (studioSettings.classSettings.exceptions[id].rateValueOne = null)
      : null;
    exception.rateValueTwo === undefined
      ? (studioSettings.classSettings.exceptions[id].rateValueTwo = null)
      : null;
    exception.rateValueThree === undefined
      ? (studioSettings.classSettings.exceptions[id].rateValueThree = null)
      : null;

    let rateLookup = studioSettings.classSettings.exceptions[id].rate;
    if (studioSettings.classSettings.exceptions[id].rateAmount !== undefined) {
      let trueRates = findOldRate(
        rateLookup,
        studioSettings.classSettings.exceptions[id].rateAmount
      );
      exception.rateValueOne === undefined || exception.rateValueOne === null
        ? (studioSettings.classSettings.exceptions[id].rateValueOne =
            trueRates[0])
        : null;
      exception.rateValueTwo === undefined || exception.rateValueTwo === null
        ? (studioSettings.classSettings.exceptions[id].rateValueTwo =
            trueRates[1])
        : null;
      exception.rateValueThree === undefined ||
      exception.rateValueThree === null
        ? (studioSettings.classSettings.exceptions[id].rateValueThree =
            trueRates[2])
        : null;
    }

    exception.rateAmount === undefined
      ? (studioSettings.classSettings.exceptions[id].rateAmount = {
          Exactly: { amount: "dollar~0" },
          "a bonus of": { amount: "dollar~0" },
          Percentage: { percent: "percent~0" },
          "Attendee Count": { number: "number~0" },
          "With No Shows and Late Cancels": { selections: [] },
          "Amount minus hourly rate": { dollar: "dollar~0", math: [] },
          "Specific Class Rate": { rate: [] },
          "Throw Flag": { flag: "text~" },
        })
      : null;

    exception.studio === undefined
      ? (studioSettings.classSettings.exceptions[id].studio = [])
      : null;
    exception.time === undefined
      ? (studioSettings.classSettings.exceptions[id].time = "time~00:00")
      : null;
    exception.timeSequence === undefined
      ? (studioSettings.classSettings.exceptions[id].timeSequence = "ALL DAY")
      : null;
    exception.type === undefined
      ? (studioSettings.classSettings.exceptions[id].type = "ANY")
      : null;
  }
}

function findOldRate(rate, rateObject) {
  let oldObject = rateObject[rate];
  if (oldObject === undefined) {
    return getDefaultValue(rate);
  }
  let rateArray = Object.values(oldObject);
  while (rateArray.length < 3) {
    rateArray.push(null);
  }
  return rateArray;
}

function getDefaultValue(value) {
  let valueArray = [null, null, null];
  if (value === "Exactly" || value === "a bonus of") {
    valueArray[0] = "dollar~0";
  } else if (value === "Amount minus hourly rate") {
    valueArray[0] = "dollar~0";
    valueArray[1] = "-";
  } else if (value === "Percentage" || value === "Percent of Pay") {
    valueArray[0] = "percent~0";
  } else if (
    value === "With No Shows and Late Cancels" ||
    value === "Specific Class Rate"
  ) {
    valueArray[0] = [];
  } else if (value === "Attendee Count") {
    valueArray[0] = "number~0";
  } else if (value === "Throw Flag") {
    valueArray[0] = "text~";
  } else if (value === "Base Plus Per Head") {
    valueArray[0] = "dollar~0";
    valueArray[1] = "dollar~0";
    valueArray[2] = "number~0";
  }

  return valueArray;
}

function checkTimeExceptions(studioSettings) {
  let timeExceptions = Object.values(studioSettings.timeSettings.exceptions);
  for (let i = 0; i < timeExceptions.length; i++) {
    let id = timeExceptions[i].id;
    let exception = timeExceptions[i];

    exception.comment === undefined
      ? (studioSettings.timeSettings.exceptions[id].comment = "text~")
      : null;
    exception.day === undefined
      ? (studioSettings.timeSettings.exceptions[id].day = "15th")
      : null;
    exception.daySequence === undefined
      ? (studioSettings.timeSettings.exceptions[id].daySequence = "ANY DATE")
      : null;
    exception.hours === undefined
      ? (studioSettings.timeSettings.exceptions[id].hours = "number~")
      : null;
    exception.label === undefined
      ? (studioSettings.timeSettings.exceptions[id].label = "Default")
      : null;
    exception.rate === undefined
      ? (studioSettings.timeSettings.exceptions[id].rate = "dollar~")
      : null;
    exception.staff === undefined
      ? (studioSettings.timeSettings.exceptions[id].staff = [])
      : null;
    exception.studio === undefined
      ? (studioSettings.timeSettings.exceptions[id].studio = [])
      : null;
    exception.total === undefined
      ? (studioSettings.timeSettings.exceptions[id].total = "dollar~")
      : null;
    exception.utility === undefined
      ? (studioSettings.timeSettings.exceptions[id].utility = "Add")
      : null;
  }
}

function checkCommissionExceptions(studioSettings) {
  let commissionExceptions = Object.values(
    studioSettings.commissionSettings.exceptions
  );
  for (let i = 0; i < commissionExceptions.length; i++) {
    let id = commissionExceptions[i].id;
    let exception = commissionExceptions[i];

    exception.comment === undefined
      ? (studioSettings.commissionSettings.exceptions[id].comment = "text~")
      : null;
    exception.name === undefined
      ? (studioSettings.commissionSettings.exceptions[id].name = "text~")
      : null;
    exception.price === undefined
      ? (studioSettings.commissionSettings.exceptions[id].price = "dollar~")
      : null;
    exception.priceSequence === undefined
      ? (studioSettings.commissionSettings.exceptions[id].priceSequence =
          "ANY PRICE")
      : null;
    exception.rate === undefined
      ? (studioSettings.commissionSettings.exceptions[id].rate = "percentr~")
      : null;
    exception.rateFrequency === undefined
      ? (studioSettings.commissionSettings.exceptions[id].rateFrequency =
          "Never Pay")
      : null;
    exception.rateNormal === undefined
      ? (studioSettings.commissionSettings.exceptions[id].rateNormal =
          "Regular Rate")
      : null;
    exception.rateSalespeople === undefined
      ? (studioSettings.commissionSettings.exceptions[id].rateSalespeople = [])
      : null;
    exception.rateSplit === undefined
      ? (studioSettings.commissionSettings.exceptions[id].rateSplit =
          "Split between")
      : null;
    exception.studio === undefined
      ? (studioSettings.commissionSettings.exceptions[id].studio = [])
      : null;
    exception.type === undefined
      ? (studioSettings.commissionSettings.exceptions[id].type = [])
      : null;
  }
}

export function checkStaffSettingsFormat(staffSettings, uid, studioSettings) {
  //iterating through all employees
  //check to make sure each employee has all fileds
  //if they do not have all fields, add the field and add staff member to updated array
  //return array
  //let id = uuidv4();

  // const masterStaffObject = {
  //   alternateNames: [],
  //   commissionRate: {
  //     retail: "",
  //     agreement: "",
  //   },
  //   email: "",
  //   groupRate: {
  //     afterAttendee: "0",
  //     base: "0",
  //     custom: [],
  //     flat: "0",
  //     perHead: "0",
  //     zero: "0",
  //   },
  //   homeLocation: "",
  //   homeRegion: "",
  //   hourly: "0",
  //   id: id,
  //   idString: "",
  //   introRate: {
  //     afterAttendee: "0",
  //     base: "0",
  //     custom: [],
  //     flat: "0",
  //     perHead: "0",
  //     zero: "0",
  //   },
  //   isNew: false,
  //   lastEdit: new Date().getTime(),
  //   locations: [],
  //   name: "",
  //   paywellUID: uid,
  //   privateRate: {
  //     afterAttendee: "0",
  //     base: "0",
  //     custom: [],
  //     flat: "0",
  //     perHead: "0",
  //     zero: "0",
  //   },
  //   secondIdString: "",
  //   secondaryHourly: "0",
  //   titles: [],
  //   type: "Instructor",
  // };

  for (const [key, value] of Object.entries(staffSettings.staff)) {
    const newObject = JSON.parse(JSON.stringify(value)); // Create a deep copy of the object

    //const hasAllFields = deepCompare(newObject, masterStaffObject);
    const hasAllFields = deepStaffCompare(newObject, studioSettings)

    // if (!hasAllFields) {
      //deepAddStaffFields(newObject, studioSettings);
      //deepAddFields(newObject, masterStaffObject);
      //newObject.UPDATED = true;
    // }
    staffSettings.staff[key] = newObject;
  }

  return staffSettings;
}

function deepCompare(copy, master) {
  for (let key in master) {
    if (typeof master[key] === "object") {
      if (!copy.hasOwnProperty(key) || !deepCompare(copy[key], master[key])) {
        return false;
      }
    } else {
      if (!copy.hasOwnProperty(key) || copy[key] !== master[key]) {
        return false;
      }
    }
  }
  return true;
}

// function deepCompare(copy, master) {
//   for (let key in master) {
//     if (key === "rescheduledStatuses") {
//       // Skip comparison for the rescheduledStatuses key
//       continue;
//     }
//     if (Array.isArray(master[key])) {
//       // Check if both are arrays but skip deep comparison
//       if (!Array.isArray(copy[key])) {
//         return false;
//       }
//     } else if (typeof master[key] === "object" && master[key] !== null) {
//       if (!copy.hasOwnProperty(key) || !deepCompare(copy[key], master[key])) {
//         return false;
//       }
//     } else {
//       if (!copy.hasOwnProperty(key) || copy[key] !== master[key]) {
//         return false;
//       }
//     }
//   }
//   return true;
// }


// function deepCompare(copy, master) {
//   for (let key in master) {
//     if (Array.isArray(master[key])) {
//       if (!Array.isArray(copy[key]) || master[key].length !== copy[key].length) {
//         return false;
//       }
//       for (let i = 0; i < master[key].length; i++) {
//         if (!deepCompare(copy[key][i], master[key][i])) {
//           return false;
//         }
//       }
//     } else if (typeof master[key] === "object" && master[key] !== null) {
//       if (!copy.hasOwnProperty(key) || !deepCompare(copy[key], master[key])) {
//         return false;
//       }
//     } else {
//       if (!copy.hasOwnProperty(key) || copy[key] !== master[key]) {
//         return false;
//       }
//     }
//   }
//   return true;
// }


function deepStaffCompare(copy, studioSettings) {
  let staffFields = [];
  for (const key in studioSettings) {
      // Check if the key is a number
      if (!isNaN(Number(key))) {
          const staffFieldsInd = studioSettings[key]?.generalSettings?.staffFields;
          if (staffFieldsInd && Array.isArray(staffFieldsInd)) {
            staffFields = staffFields.concat(staffFieldsInd);
          }
      }
  }

  if(!staffFields){
    staffFields = newStudioSettings.generalSettings.staffFields;
  }

  let buckets = [];
  for (const key in studioSettings) {
    // Check if the key is a number
    if (!isNaN(Number(key))) {
        const bucketTemp = Object.keys(studioSettings[key]?.classSettings?.classBuckets);
        if (bucketTemp && Array.isArray(bucketTemp)) {
          buckets = buckets.concat(bucketTemp);
        }
    }
  }

  if(!buckets){
    buckets = Object.keys(newStudioSettings.classSettings.classBuckets);
  }
  // let buckets = Object.keys(newStudioSettings.classSettings.classBuckets);

  for(let i = 0; i < staffFields.length; i++){
    if(!copy.hasOwnProperty(staffFields[i].field)){
      copy[staffFields[i].field] = staffFields[i].default;
      //return false;
    }
  }

  for(let i = 0; i < buckets.length; i++){
    const rateKey = buckets[i].charAt(0).toLowerCase() + buckets[i].slice(1) + "Rate";
    if(!copy.hasOwnProperty(rateKey)){
      copy[rateKey] = {
        none: false,
        zero: "0",
        base: "0",
        perHead: "0",
        afterAttendee: "0",
        flat: "0",
        custom: [],
      };
      //|| !copy[rateKey].hasOwnProperty("zero") || !copy[rateKey].hasOwnProperty("base") || !copy[rateKey].hasOwnProperty("perHead") || !copy[rateKey].hasOwnProperty("afterAttendee") || !copy[rateKey].hasOwnProperty("flat") || !copy[rateKey].hasOwnProperty("custom")
    } else if(!copy[rateKey].hasOwnProperty("none") ){
      copy[rateKey].none = false;
    } else if(!copy[rateKey].hasOwnProperty("zero") ){
      copy[rateKey].zero = "0";
    } else if(!copy[rateKey].hasOwnProperty("base") ){
      copy[rateKey].base = "0";
    } else if(!copy[rateKey].hasOwnProperty("perHead") ){
      copy[rateKey].perHead = "0";
    } else if(!copy[rateKey].hasOwnProperty("afterAttendee") ){
      copy[rateKey].afterAttendee = "0";
    } else if(!copy[rateKey].hasOwnProperty("flat") ){
      copy[rateKey].flat = "0";
    } else if(!copy[rateKey].hasOwnProperty("custom") ){
      copy[rateKey].custom = [];
    }
    // if(!copy.hasOwnProperty(rateKey)){
    //   return false;
    // } else{
    //   if(!copy[rateKey].hasOwnProperty("none") || !copy[rateKey].hasOwnProperty("zero") || !copy[rateKey].hasOwnProperty("base") || !copy[rateKey].hasOwnProperty("perHead") || !copy[rateKey].hasOwnProperty("afterAttendee") || !copy[rateKey].hasOwnProperty("flat") || !copy[rateKey].hasOwnProperty("custom")){
    //     return false;
    //   }
    // }
  }

  if(!(copy?.commissionRate)){
    copy.commissionRate = {
      retail: "",
      agreement: "",
    };
  }
  if(!copy.commissionRate.retail){
    copy.commissionRate.retail = "";
  }
  if(!copy.commissionRate.agreement){
    copy.commissionRate.agreement = "";
  }
  
  for (const entries in studioSettings?.salesSettings?.salesBuckets) {
    // Check if the key is a number
    let [bucketName, bucket] = entries;
    for(let r = 0; r < bucket.distinctRates.length; r++){
      let rateName = bucketName + bucket.distinctRates[r];
      if(!copy.commissionRate[rateName]){
        copy.commissionRate[rateName] = "";
      }
    }
  }

  // if(!(copy?.commissionRate && copy.commissionRate.retail && copy.commissionRate.agreement)) return false;

  // return true;
}



// function deepAddFields(copy, master) {
//   for (let key in master) {
//     // if (key === "rescheduledStatuses") {
//     //   // Skip the rescheduledStatuses key
//     //   continue;
//     // }

//     if (Array.isArray(master[key])) {
//       // Skip arrays entirely
//       if (!copy.hasOwnProperty(key)) {
//         copy[key] = master[key]; // Only add the array if it doesn't exist in the copy
//       }
//     } else if (typeof master[key] === "object" && master[key] !== null) {
//       if (!copy.hasOwnProperty(key)) {
//         copy[key] = {};
//       }
//       deepAddFields(copy[key], master[key]);
//     } else {
//       if (!copy.hasOwnProperty(key)) {
//         copy[key] = master[key];
//       }
//     }
//   }
// }

function deepAddFields(copy, master) {
  for (let key in master) {
    if (key === 'classBuckets') {
      continue; // Skip the key 'classBuckets'
    }

    if (Array.isArray(master[key])) {
      // Skip arrays entirely
      if (!copy.hasOwnProperty(key)) {
        copy[key] = master[key]; // Only add the array if it doesn't exist in the copy
      }
    } else if (typeof master[key] === "object" && master[key] !== null) {
      if (!copy.hasOwnProperty(key)) {
        copy[key] = {};
      }
      deepAddFields(copy[key], master[key]);
    } else {
      if (!copy.hasOwnProperty(key)) {
        copy[key] = master[key];
      }
    }
  }
}



function deepAddStaffFields(copy) {
  let staffFields = newStudioSettings.generalSettings.staffFields;
  let buckets = Object.keys(newStudioSettings.classSettings.classBuckets);

  for(let i = 0; i < staffFields.length; i++){
    if(!copy.hasOwnProperty(staffFields[i].field)){
      copy[staffFields[i].field] = staffFields[i].default;
    }
  }

  for(let i = 0; i < buckets.length; i++){
    const rateKey = buckets[i].charAt(0).toLowerCase() + buckets[i].slice(1) + "Rate";
    if(!copy.hasOwnProperty(rateKey)){
      copy[rateKey] = {
        none: false,
        zero: "0",
        base: "0",
        perHead: "0",
        afterAttendee: "0",
        flat: "0",
        custom: [],
      };
      //|| !copy[rateKey].hasOwnProperty("zero") || !copy[rateKey].hasOwnProperty("base") || !copy[rateKey].hasOwnProperty("perHead") || !copy[rateKey].hasOwnProperty("afterAttendee") || !copy[rateKey].hasOwnProperty("flat") || !copy[rateKey].hasOwnProperty("custom")
    } else if(!copy[rateKey].hasOwnProperty("none") ){
      copy[rateKey].none = false;
    } else if(!copy[rateKey].hasOwnProperty("zero") ){
      copy[rateKey].zero = "0";
    } else if(!copy[rateKey].hasOwnProperty("base") ){
      copy[rateKey].base = "0";
    } else if(!copy[rateKey].hasOwnProperty("perHead") ){
      copy[rateKey].perHead = "0";
    } else if(!copy[rateKey].hasOwnProperty("afterAttendee") ){
      copy[rateKey].afterAttendee = "0";
    } else if(!copy[rateKey].hasOwnProperty("flat") ){
      copy[rateKey].flat = "0";
    } else if(!copy[rateKey].hasOwnProperty("custom") ){
      copy[rateKey].custom = [];
    }
  }

  if(!(copy?.commissionRate)){
    copy.commissionRate = {
      retail: "",
      agreement: "",
    };
  }
  if(!copy.commissionRate.retail){
    copy.commissionRate.retail = "";
  }
  if(!copy.commissionRate.agreement){
    copy.commissionRate.agreement = "";
  }
}

// function deepCompare(copy, master) {

//   let hasChanges = false;

//   for (let key in master) {
//     if (typeof master[key] === 'object') {
//       if (!copy.hasOwnProperty(key)) {
//         copy[key] = master;
//         hasChanges = true;
//       }
//       const nestedChanges = deepCompare(copy[key], master[key]);
//       hasChanges = hasChanges || nestedChanges;
//     } else {
//       if (!copy.hasOwnProperty(key) || copy[key] !== master[key]) {
//         copy[key] = master[key];
//         hasChanges = true;
//       }
//     }
//   }

//   if (!hasChanges) {
//     return null;
//   }

//   return copy;
// }
