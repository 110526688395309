
export class TimeBucket{
    constructor(bucketName, type){
        this.name = bucketName;
        this.type = type;
        this.names = [];
        this.isStandardTimeType = false;
        this.rounded = null;
        this.overtimeApplicable = true;
        this.frequency = null;
    }
}