import React, { useState, useEffect } from "react";
import { getFirestore, collection, addDoc, updateDoc, doc, serverTimestamp } from "firebase/firestore";
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { TextField, Button, MenuItem, Select, FormControl, InputLabel, FormControlLabel, Checkbox, CircularProgress, Autocomplete, Checkbox as MuiCheckbox, Chip, Typography } from "@mui/material";
import { getAllUsers } from "./OutputTest";

export default function AdminNotificationForm({ selectedNotification, onFormSubmit }) {
    const [title, setTitle] = useState("");
    const [message, setMessage] = useState("");
    const [type, setType] = useState("popup");
    const [targetSelector, setTargetSelector] = useState("");
    const [position, setPosition] = useState("bottom");
    const [flip, setFlip] = useState(true);
    const [preventOverflow, setPreventOverflow] = useState(true);
    const [arrow, setArrow] = useState(true);
    const [isActive, setIsActive] = useState(true);
    const [imageUrl, setImageUrl] = useState("");
    const [uploading, setUploading] = useState(false);
    const [allUsers, setAllUsers] = useState([]);
    const [dismissedUsers, setDismissedUsers] = useState([]);
    const [order, setOrder] = useState(0);
    const [usersLoading, setUsersLoading] = useState(true);

    useEffect(() => {
        const fetchUsers = async () => {
            const usersList = await getAllUsers();
            setAllUsers(usersList?.users ?? []);
            setUsersLoading(false);
        };

        fetchUsers();
    }, []);

    useEffect(() => {
        if (selectedNotification) {
            setTitle(selectedNotification.title);
            setMessage(selectedNotification.message);
            setType(selectedNotification.type);
            setTargetSelector(selectedNotification.targetSelector || "");
            setPosition(selectedNotification.position || "bottom");
            setFlip(selectedNotification.flip || true);
            setPreventOverflow(selectedNotification.preventOverflow || true);
            setArrow(selectedNotification.arrow || true);
            setIsActive(selectedNotification.isActive || true);
            setImageUrl(selectedNotification.imageUrl || "");
            setDismissedUsers(selectedNotification.dismissedUsers || []);
            setOrder(selectedNotification.order || 0);
        }
    }, [selectedNotification, allUsers]);

    const handleImageUpload = async (e) => {
        const file = e.target.files[0];
        if (!file) return;

        setUploading(true);
        const storage = getStorage();
        const storageRef = ref(storage, `images/${file.name}`);
        const uploadTask = uploadBytesResumable(storageRef, file);

        uploadTask.on(
            "state_changed",
            (snapshot) => {
                // Handle upload progress if needed
            },
            (error) => {
                console.error("Error uploading image: ", error);
                alert("Failed to upload image");
                setUploading(false);
            },
            async () => {
                const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
                setImageUrl(downloadURL);
                setUploading(false);
            }
        );
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const db = getFirestore();
        const notificationData = {
            title,
            message,
            type,
            targetSelector,
            position,
            isActive,
            flip,
            preventOverflow,
            arrow,
            imageUrl,
            dismissedUsers,
            order,
            createdAt: selectedNotification ? selectedNotification.createdAt : serverTimestamp(),
        };

        try {
            if (selectedNotification) {
                const notificationRef = doc(db, "notifications", selectedNotification.id);
                await updateDoc(notificationRef, notificationData);
                alert("Notification updated successfully!");
            } else {
                await addDoc(collection(db, "notifications"), {
                    ...notificationData,
                    dismissedUsers: dismissedUsers,
                });
                alert("Notification added successfully!");
            }
            if (onFormSubmit) {
                onFormSubmit();
            }
            // Reset form
            setTitle("");
            setMessage("");
            setType("popup");
            setTargetSelector("");
            setPosition("bottom");
            setFlip(true);
            setPreventOverflow(true);
            setArrow(true);
            setIsActive(true);
            setImageUrl("");
            setDismissedUsers([]);
            setOrder(0);
        } catch (error) {
            console.error("Error saving notification: ", error);
            alert("Failed to save notification");
        }
    };

    const handleDismissedUsersChange = (event, newValue) => {
        //
        setDismissedUsers(newValue.map((user) => user.uid));
    };


    return (
        <div>

            <Typography variant="h4" gutterBottom>
                {selectedNotification ? selectedNotification.title : "Add Notification"}
            </Typography>

            <form onSubmit={handleSubmit} style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>

                {usersLoading ? <CircularProgress /> :


                    <Autocomplete
                        multiple
                        options={allUsers}
                        disableCloseOnSelect
                        getOptionLabel={(option) => option.email || ""}
                        renderOption={(props, option, { selected }) => (
                            <li {...props}>
                                <MuiCheckbox
                                    style={{ marginRight: 8 }}
                                    checked={selected}
                                />
                                {option.email}
                            </li>
                        )}
                        value={allUsers.filter((user) => dismissedUsers.includes(user.uid))}
                        onChange={handleDismissedUsersChange}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant="outlined"
                                label={`Seen by ${allUsers.filter((user) => dismissedUsers.includes(user.uid)).length}/${allUsers.length} Users`}
                                placeholder="Select users"
                            />
                        )}
                        renderTags={(value, getTagProps) =>
                            value.slice(0, 3).map((option, index) => (
                                <Chip
                                    key={option.id}
                                    label={option.email}
                                    {...getTagProps({ index })}
                                />
                            ))
                        }
                    />
                }
                <TextField
                    label="Title"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                    required
                />
                <TextField
                    label="Message"
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    required
                    multiline
                    rows={4} // You can adjust the number of rows as needed
                />
                <TextField
                    label="Order"
                    type="number"
                    value={order}
                    onChange={(e) => setOrder(Number(e.target.value))}
                    required
                />
                <FormControl required>
                    <InputLabel>Type</InputLabel>
                    <Select
                        value={type}
                        onChange={(e) => setType(e.target.value)}
                    >
                        <MenuItem value="popup">Popup</MenuItem>
                        <MenuItem value="tooltip">Tooltip</MenuItem>
                    </Select>
                </FormControl>
                {type === "tooltip" && (
                    <>
                        <TextField
                            label="Target Selector"
                            value={targetSelector}
                            onChange={(e) => setTargetSelector(e.target.value)}
                            required
                        />
                        <FormControl required>
                            <InputLabel>Position</InputLabel>
                            <Select
                                value={position}
                                onChange={(e) => setPosition(e.target.value)}
                            >
                                <MenuItem value="top">Top</MenuItem>
                                <MenuItem value="right">Right</MenuItem>
                                <MenuItem value="bottom">Bottom</MenuItem>
                                <MenuItem value="left">Left</MenuItem>
                            </Select>
                        </FormControl>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={flip}
                                    onChange={(e) => setFlip(e.target.checked)}
                                    color="primary"
                                />
                            }
                            label="Enable Flip"
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={preventOverflow}
                                    onChange={(e) => setPreventOverflow(e.target.checked)}
                                    color="primary"
                                />
                            }
                            label="Prevent Overflow"
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={arrow}
                                    onChange={(e) => setArrow(e.target.checked)}
                                    color="primary"
                                />
                            }
                            label="Enable Arrow"
                        />
                    </>
                )}
                {type === "popup" && (
                    <>
                        <input
                            accept="image/*"
                            type="file"
                            onChange={handleImageUpload}
                            style={{ marginTop: '16px' }}
                        />
                        {uploading && <CircularProgress />}
                        {imageUrl && <img src={imageUrl} alt="Uploaded" style={{ width: '100%', height: 'auto', marginTop: '16px' }} />}
                    </>
                )}



                <FormControlLabel
                    control={
                        <Checkbox
                            checked={isActive}
                            onChange={(e) => setIsActive(e.target.checked)}
                            color="primary"
                        />
                    }
                    label="Active"
                />
                <Button type="submit" variant="contained" color="primary">
                    {selectedNotification ? "Update Notification" : "Add Notification"}
                </Button>
            </form>
        </div>
    );
}
