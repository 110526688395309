import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  DialogActions,
  DialogContentText,
} from "@mui/material";
import AddStaffComponentSettings from "./AddStaffComponentSettings";


const AddStaffDialogSettings = ({ open, onClose, onSave }) => {
  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle component={"h3"}>Add New Staff</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Please fill out all staff info.
        </DialogContentText>
        <AddStaffComponentSettings onSave={onSave} onClose={onClose} />
      </DialogContent>
    </Dialog>
  );
};

export default AddStaffDialogSettings;
