import React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CircularProgressWrapper from "../CircularProgressWrapper";
import Menu from "../Menu";
import { useClubReady } from "../../contexts/ClubReadyContext";
import { useStudioScrapeStatus } from "../../contexts/StudioScrapeStatus/StudioScrapeStatusContext";
import "./accordion.scss";
export default function SimpleAccordion(props) {
  return (
    <Accordion
      className="accordion"
      key={props.title}
      sx={{
        marginBottom: "1em !important",
        boxShadow:
          "0px 0px 2px 0px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)",
        borderTopLeftRadius: "4px",
        borderTopRightRadius: "4px",
        borderBottomLeftRadius: "4px",
        borderBottomRightRadius: "4px",
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          paddingRight: "1em",
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="accordionDropdownButton"
          style={{ width: "100%" }}
        >
          <div id="reportsAccordionLabel" style={{ display: "grid" }}>
            <Typography variant="caption" style={{ flex: "none" }}>
              {props.title}
            </Typography>
          </div>
        </AccordionSummary>
        <span
          style={{
            display: "flex",
            justifyContent: "space-evenly",
            alignItems: "center",
          }}
        >
          <CircularProgressWrapper studioIndex={props.studioIndex} />
        </span>

        <Menu
          studioIndex={props.studioIndex}
          fileType={props.fileType}
          reports={props.reports}
          isStudio={true}
        />
      </div>

      <AccordionDetails
        sx={{
          marginBottom: "1em",
        }}
      >
        <Typography>{props.content}</Typography>
      </AccordionDetails>
    </Accordion>
  );
}
