import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import { DateRangePicker } from "@mui/x-date-pickers-pro/DateRangePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { formatDate } from "../../contexts/StudioScrapeStatus/utility-functions.js";

export default function CustomDateRangePicker(props) {
  const {
    startDate,
    endDate,
    onDateChange,
    onSubmit, // New callback for when the "Submit" button is clicked
    disabled,
  } = props;

  const [value, setValue] = useState([startDate, endDate]);

  const handleOnChange = (newValue) => {
    setValue(newValue);
    onDateChange(newValue);
  };

  const handleSubmit = () => {
    // Call the onSubmit callback with the selected dates
    if (onSubmit) {
    
      
      onSubmit(formatDate(new Date(value[0])), formatDate(new Date(value[1])));
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DateRangePicker
        disabled={disabled}
        disableFuture
        className="date-range-picker"
        startText="Start of Pay Period"
        endText="End of Pay Period"
        value={value}
        onChange={handleOnChange}
        renderInput={(startProps, endProps) => (
          <Box className="date-range-picker__box">
            <Box
              className="date-range-picker__box"
              style={{
                display: "flex",
              }}
            >
              <TextField
                disabled={disabled}
                className="date-range-picker__input-container"
                id="dateRangePickerTextFieldStartDate"
                {...startProps}
              />
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  mx: 2,
                  opacity: disabled ? 0.2 : 1,
                }}
              >
                to
              </Box>
              <TextField
                disabled={disabled}
                className="date-range-picker__input-container"
                id="dateRangePickerTextFieldEndDate"
                {...endProps}
              />
            </Box>
          </Box>
        )}
      />
      {onSubmit && ( // Render the "Submit" button if onSubmit callback is provided
        <Button
          disabled={disabled || !value[0] || !value[1]} // Disable button if dates are not selected
          variant="outlined"
          size="small"
          style={{
            paddingTop: "6px",
            marginBottom: "12px",
          }}
          id="submitDateRangeButton"
          className="date-range-picker__button"
          onClick={handleSubmit}
        >
          Submit
        </Button>
      )}
    </LocalizationProvider>
  );
}
