import React, { useState } from 'react';
import { Modal, Box, Typography, IconButton, Button } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close';
import RateObjectComponent from './RateItem';
import RateConstructor from './RateConstructor';
import AttributeListBox from '../../Components/AttributeListBox';
import RateOverrideManager from './RateOverride';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 'auto',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  maxHeight: '80vh',
  overflowY: 'auto'
};

const RateManager = ({ rates, rateName, setRates, distinctRates, bucketName, rateOverrides, rateCustomInput, handleBucketUpdate }) => {
  const [open, setOpen] = useState(false);
  const [rateTypes, setRateTypes] = useState(distinctRates);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleRateChange = (rateType, newRateSettings) => {
    if(!rateTypes.includes(rateType)){
      setRateTypes([...rateTypes, rateType]);
    }
    const updatedRates = { ...rates, [rateType]: newRateSettings };
    setRates(updatedRates, 'rates');
  };

  const handleAddRate = (rate) => {
    if (!rateTypes.includes(rate)) {
      const updatedRateTypes = [...rateTypes, rate];
      const updatedRates = { ...rates, [rate]: { rateType: "Fixed", rate: 0, setupFeeIncluded: false, split: true, salespeople: ["Close"] } };
      setRates(updatedRates, 'rates');
      setRates(updatedRateTypes, 'distinctRates');

      setRateTypes(updatedRateTypes);
    }
  };

  const handleRateOverrideChange = (newRateOverrides) => {
    setRates(newRateOverrides, 'rateOverrides');
  }

  return (
    <div>
      <Button onClick={handleOpen} startIcon={<EditIcon />}>
        Manage {rateName} Rates
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box sx={style}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography id="modal-title" variant="h6" component="h2">
              {rateName} Rates
            </Typography>
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Box>
          
          <Box>
            <AttributeListBox
              key={rateTypes.join(",") + "-distinctRatesList"}
              //key={bucketName + "-distinctRatesList"}
              multiple={true}
              //onChange={handleDistinctRatesChange}
              id="distinctRates"
              default={rateTypes}
              label=""
              limitTags={20}
              options={[]} // Predefined rate types
              noOptions={null}
            />
            <RateConstructor onAddRate={handleAddRate} currentRates={rateTypes}/>
          </Box>
          
          {rateTypes.map(rateType => (
            <Box key={rateType} sx={{ mt: 2, mb: 2 }}>
              <RateObjectComponent
                on={true}
                id="rates"
                key={rateType + "-rate"}
                bucketName={bucketName}
                rateName={rateOverrides && rateOverrides[rateType] || rateType}
                rate={rates[rateType] || { rateType: "Fixed", rate: 0, setupFeeIncluded: false, split: true, salespeople: ["Close"] }}
                onRateChange={(newRateDetails) => handleRateChange(rateType, newRateDetails)}
              />
            </Box>
          ))}
          <RateOverrideManager 
            key={bucketName + "-rateOverrides"}
            id="rateOverrides"
            rateOverrides={rateOverrides} 
            setRateOverrides={handleRateOverrideChange}
            distinctRates={distinctRates}
          />
        </Box>
      </Modal>
    </div>
  );
};

export default RateManager;
