import React, { useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useAuth } from "../../contexts/AuthContext";
import { useAdminImpersonationBanner } from "../../contexts/Admin/AdminImpersonationBannerContext";
const ImpersonationLogin = () => {
  const { loginWithCustomToken,  } = useAuth();
  const { showAdminImpersonationBanner } = useAdminImpersonationBanner();
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    const token = urlParams.get('token');

    if (token) {
      loginWithCustomToken(token).then((user) => {
        showAdminImpersonationBanner(`You are currently impersonating ${user?.email}`);
        history.push('/dashboard');
      }).catch((error) => {
        console.error("Error logging in with custom token:", error);
        // Redirect to error page or show error message
      });
    }
  }, [location.search, loginWithCustomToken, showAdminImpersonationBanner, history]);

  return (
    <div>
      <p>Logging in...</p>
    </div>
  );
};

export default ImpersonationLogin;
