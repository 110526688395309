import React, { createContext, useContext, useReducer } from "react";
import { createNewStaffObject } from "../../pages/StaffSettings/utils";
import { findStaffByName } from "../../components/StaffProfile/utils";
import { v4 as uuidv4 } from "uuid";
import { getUrlVariableValue } from "../../utility-functions/utility-functions";
import { useAuth } from "../AuthContext";
import { deepClone } from "../../components/StaffProfile/utils";

const initialState = {
  staff: [],
  editableStaff: { name: "" }, // Assuming a minimal default structure
  error: null,
  loading: false,
};

const StaffContext = createContext(initialState);

function staffReducer(state, action) {
  switch (action.type) {
    case "ADD_STAFF":
      return {
        ...state,
        staff: [...state.staff, action.payload],
      };
    case "MERGE_STAFF":
      return {
        ...state,
        staff: state.staff.map((s) =>
          s.id === action.payload.id ? action.payload : s
        ),
      };
    case "UPDATE_EDITABLE_STAFF":
      return {
        ...state,
        editableStaff: {
          ...state.editableStaff,
          updated: true,
          ...action.payload,
        },
      };
    case "SET_EDITABLE_STAFF": // Added for setting the entire editableStaff object
      return {
        ...state,
        editableStaff: action.payload,
      };
    case "RESET_EDITABLE_STAFF":
      return {
        ...state,
        editableStaff: initialState.editableStaff,
      };
    case "SET_ERROR":
      return {
        ...state,
        error: action.payload,
      };
    case "SET_LOADING":
      return {
        ...state,
        loading: action.payload,
      };
    default:
      return state;
  }
}

export const StaffProvider = ({ children }) => {
  const { getUID } = useAuth();
  const [staffContextState, staffContextDispatch] = useReducer(
    staffReducer,
    initialState
  );

  // Helper functions for dispatching actions
  const updateEditableStaff = (updates) => {
    staffContextDispatch({ type: "UPDATE_EDITABLE_STAFF", payload: updates });
  };

  const setEditableStaff = (staff) => {
    staffContextDispatch({ type: "SET_EDITABLE_STAFF", payload: staff });
  };

  const resetEditableStaff = () => {
    staffContextDispatch({ type: "RESET_EDITABLE_STAFF" });
  };

  const initializeEditableStaff = (settings, staff) => {
    
    const settingsId = getUrlVariableValue("settingsId");
    const existingStaff = findStaffByName(settings.staff.staff, staff.name);

    if (existingStaff) {
      const duplicatedStaff = deepClone(existingStaff);
      duplicatedStaff.id = uuidv4();
      duplicatedStaff["updated"] = true;
      if (staff.errorType == "unaddedLocation") {
        if (Array.isArray(staff.location)) {
          duplicatedStaff.locations = staff.location;
        } else {
          duplicatedStaff.locations = [staff.location];
        }
      }
      staffContextDispatch({
        type: "SET_EDITABLE_STAFF",
        payload: duplicatedStaff,
      });
    } else {
      const { classSettings, generalSettings } = settings[settingsId];
      const { newStaffObj } = createNewStaffObject(
        settings,
        getUID,
        classSettings.classBuckets,
        generalSettings
      );
      newStaffObj.name = staff.name;
      newStaffObj["updated"] = true;
      staffContextDispatch({
        type: "SET_EDITABLE_STAFF",
        payload: newStaffObj,
      });
    }
  };

  const contextValue = {
    ...staffContextState,
    staffContextDispatch,
    updateEditableStaff,
    setEditableStaff,
    resetEditableStaff,
    initializeEditableStaff,
  };

  return (
    <StaffContext.Provider value={contextValue}>
      {children}
    </StaffContext.Provider>
  );
};

export const useStaffContext = () => {
  const context = useContext(StaffContext);
  if (context === undefined) {
    throw new Error("useStaffContext must be used within a StaffProvider");
  }
  return context;
};
