import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import useMediaQuery from "@mui/material/useMediaQuery";
import ResponsiveAppBar from "../../components/AppBar/ResponsiveAppBar";
import ResponsiveFooter from "../../components/Footer/Footer";
import { Page } from "../../components/Homepage/HomePage";
import "../../components/Homepage/homepage.scss";
import "./PrivacyPolicy.scss";
import FooterLinkList from "../../components/Footer/FooterLinks";
export default function PrivacyPolicyPage(props) {
  const matches = useMediaQuery("(min-width:900px)");
  return (
    <>
      <ResponsiveAppBar></ResponsiveAppBar>
      <Box
        style={
          matches
            ? {
              width: "100%",
              height: "104px",
            }
            : {
              width: "100%",
              height: "80px",
            }
        }
      ></Box>
      <Page>
        <Box
          className="privacy-policy"
          style={{
            width: "100%",
            display: "flex",
            boxAlign: "center",
            alignItems: "center",
            flexDirection: matches ? "row" : "column-reverse",
          }}
        >
          <Box
            style={
              matches
                ? {
                  display: "flex",
                  alignItems: "flex-start",
                  flexDirection: "column",
                  marginRight: "auto",
                  width: "auto",
                  minWidth: "450px",
                }
                : {
                  display: "flex",
                  alignItems: "flex-start",
                  flexDirection: "column",
                  marginRight: "48px",
                  width: "auto",
                  minWidth: "450px",

                  width: "100%",
                  minWidth: "auto",
                  maxWidth: "100%",
                  flexBasis: "auto",
                  marginRight: "0px",
                  marginLeft: "0px",
                  paddingLeft: "0px",
                  paddingRight: "0px",
                }
            }
          >
            <Typography
              className="privacy-policy__title"
              component={"span"}
              variant="h2"
            >
              Privacy Statement
            </Typography>
            <Typography
              className="privacy-policy__subheader"
              component={"h5"}
              variant="h5"
            >
              DATE EFFECTIVE
            </Typography>
            <Typography
              className="privacy-policy__body"
              component={"p"}
              variant="body1"
            >
              April 2024
            </Typography>

            <Typography
              className="privacy-policy__body"
              component={"p"}
              variant="body1"
            >
              The following is for general website usage information and data. We created this Privacy Policy in order to demonstrate our firm commitment to disclose our current policies regarding privacy and the confidentiality of personal information. In addition, we take further measures to enhance the protection of privacy and personally identifiable information, as outlined in this Privacy Policy. This Privacy Policy describes Our policies and procedures on the collection, use and disclosure of Your information when You use the Service and tells You about Your privacy rights and how the law protects You. We use Your Personal Data to provide and improve the Service. The scope of this policy encompasses all data collection, processing, and storage activities undertaken by the Company in relation to the payroll calculation Services it provides. Data includes that which is provided directly by our users, as well as data collected through the normal operation of Our Services. By using the Service, You agree to the collection and use of information in accordance with this Privacy Policy. Please contact us via Email if you have questions about Our privacy practices that are not addressed in this Privacy Policy. This Privacy Policy is incorporated by reference into the Terms and Conditions and the End User License Agreement made available on Our Website. By using the Services, You agree to the terms of this Privacy Policy. If You do not agree with or You are not comfortable with any aspect of this Privacy Policy, You should immediately stop using the Services. All terms used herein and not specifically defined shall have the meaning ascribed to them in the Terms and Conditions.
            </Typography>
            <Typography
              className="privacy-policy__subheader"
              component={"p"}
              variant="body1"
            >
              Definitions
            </Typography>
            <Typography
              className="privacy-policy__body"
              component={"p"}
              variant="body1"
            >
              For the purposes of this Privacy Policy:
            </Typography>
            <Typography
              className="privacy-policy__body"
              component={"p"}
              variant="body1"
            >
              <ul className="privacy-policy__list">
                <li>
                  Account means a unique account created for You to access our Service or parts of our Service.
                </li>
                <li>
                  Application means the web application and any materials or services related thereto provided by the Company which is accessible from the internet or downloaded by You on any electronic device, named PayWell.
                </li>
                <li>
                  Business, for the purpose of the California Consumer Privacy Act (CCPA), refers to the Company as the legal entity that collects any Consumers' personal information and determines the purposes and means of the processing of Consumers' personal information, or on behalf of which such information is collected and that alone, or jointly with others, determines the purposes and means of the processing of consumers' personal information, that does business in the State of California.
                </li>
                <li>
                  Company (referred to as either "the Company," "We," "Us," or "Our" in this Agreement) refers to Paywell LLC, an Illinois limited liability company.
                </li>
                <li>
                  Consumer, for the purpose of the CCPA, means a natural person who is a California resident. A resident, as defined in the law, includes (1) every individual who is in the USA for other than a temporary or transitory purpose, and (2) every individual who is domiciled in the USA who is outside the USA for a temporary or transitory purpose.
                </li>
                <li>
                  Cookies are small files that are placed on Your computer, mobile device or any other device by a website, one of the many uses of which is to contain the details of Your browsing history on that website among its many uses.
                </li>
                <li>
                  Data Controller, for the purposes of the General Data Protection Regulation (GDPR), refers to the Company as the legal person which alone or jointly with others determines the purposes and means of the processing of Personal Data.
                </li>
                <li>
                  Device means any device that can access the Service such as a computer, a cellphone or a digital tablet.
                </li>
                <li>
                  Do Not Track (DNT) is a concept that has been promoted by US regulatory authorities, in particular the U.S. Federal Trade Commission (FTC), for the internet industry to develop and implement a mechanism for allowing internet users to control the tracking of their online activities across websites.
                </li>
                <li>
                  Personal Data is any information that relates to an identified or identifiable individual. For the purposes of GDPR, Personal Data means any information relating to You such as a name, an identification number, location data, online identifier or to one or more factors specific to the physical, physiological, genetic, mental, economic, cultural or social identity. For the purposes of the CCPA, Personal Data means any information that identifies, relates to, describes or is capable of being associated with, or could reasonably be linked, directly or indirectly, with You.
                </li>
                <li>
                  Sale, for the purpose of the CCPA, means selling, renting, releasing, disclosing, disseminating, making available, transferring, or otherwise communicating orally, in writing, or by electronic or other means, a Consumer's personal information to another business or a third party for monetary or other valuable consideration.
                </li>
                <li>
                  Service refers to the Website and any materials or services related thereto.
                </li>
                <li>
                  Service Provider means any natural or legal person who processes the data on behalf of the Company. It refers to third-party companies or individuals employed by the Company to facilitate the Service, to provide the Service on behalf of the Company, to perform services related to the Service or to assist the Company in analyzing how the Service is used. For the purpose of the GDPR, Service Providers are considered Data Processors.
                </li>
                <li>
                  Third-party Social Media Service refers to any website or any social network website through which a User can log in or create an account to use the Service.
                </li>
                <li>
                  Usage Data refers to data collected automatically, either generated by the use of the Service or from the Service infrastructure itself (for example, the duration of a page visit).
                </li>
                <li>
                  Website refers to www.paywell.solutions
                </li>
                <li>
                  You means the individual accessing or using the Service, or the company, or other legal entity on behalf of which such individual is accessing or using the Service, as applicable.
                </li>
              </ul>
            </Typography>

            <Typography
              className="privacy-policy__subheader"
              component={"p"}
              variant="body1"
            >
              Collecting and Using Your Personal Data
            </Typography>

            <Typography
              className="privacy-policy__subheader"
              component={"p"}
              variant="body1"
            >
              Types of Data Collected
            </Typography>
            <Typography
              className="privacy-policy__subheader"
              component={"p"}
              variant="body1"
            >
              Personal Data
            </Typography>
            <Typography
              className="privacy-policy__body"
              component={"p"}
              variant="body1"
            >
              While using Our Service, You will be providing Us with certain personally identifiable information that can be used to contact or identify Your employees and/or Yourself. Personally identifiable information may include, but is not limited to:
            </Typography>
            <Typography
              className="privacy-policy__body"
              component={"p"}
              variant="body1"
            >
              <ul className="privacy-policy__list">
                <li>
                  First name and last name
                </li>
                <li>
                  Address, City, State, ZIP/Postal code                 </li>
                <li>
                  Email                </li>
              </ul>
            </Typography>
            <Typography
              className="privacy-policy__body"
              component={"p"}
              variant="body1"
            >
              In addition, Our payroll calculator Services require the collection of several categories of information, including but not limited to:             </Typography>

            <Typography
              className="privacy-policy__body"
              component={"p"}
              variant="body1"
            >
              <ul className="privacy-policy__list">
                <li>Bookings and Session Data: Information related to appointments, classes, or sessions booked, including dates, times, and participant details.
                </li>
                <li>Time Clock Punches: Detailed records of employee work hours, including clock-in and clock-out times.  Studio Member Details: Information about individuals associated with Your business, such as names and sign-up dates.
                </li>
                <li>Agreements and Retail Sold: Data pertaining to agreements with clients or customers and details of retail transactions.
                </li> <li>Payroll-Related Data: Critical data for payroll processing, including employee pay rates, pay structures, and other compensation-related details.
                </li></ul>
            </Typography>

            <Typography
              className="privacy-policy__subheader"
              component={"p"}
              variant="body1"
            >
              Usage Data
            </Typography>
            <Typography
              className="privacy-policy__body"
              component={"p"}
              variant="body1"
            >
              Usage Data is collected automatically when using the Service. Usage Data may include information such as Your Device's Internet Protocol address (i.e., IP address), browser type, browser version, the pages of our Service that You visit, the time and date of Your visit, the time spent on those pages, unique device identifiers and other diagnostic data. This information would be collected to facilitate the Services we provide. We may also collect information that Your browser sends whenever You visit our Service or when You access the Service by or through a mobile device. Your activity and interaction with Our Application will be monitored and used by our software to improve the Service and machine learning services.
            </Typography>
            <Typography
              className="privacy-policy__subheader"
              component={"p"}
              variant="body1"
            >
              Tracking Technologies and Cookies
            </Typography>
            <Typography
              className="privacy-policy__body"
              component={"p"}
              variant="body1"
            >
              We use Cookie technologies to track the activity on Our Service and store certain information. Tracking technologies used are beacons, tags, APIs, pixels, scripts and local storage to collect and track information and to improve and analyze Our Service. The technologies We use may include:</Typography>


            <Typography
              className="privacy-policy__body"
              component={"p"}
              variant="body1"
            >
              <ul className="privacy-policy__list">
                <li>Cookies or Browser Cookies. A cookie is a small file placed on Your Device. You can instruct Your browser to refuse all Cookies or to indicate when a Cookie is being sent. You may also control Our Website’s cookies through Our privacy settings popup which is also accessible at the bottom of every main Website page. However, if You do not accept Cookies, You may not be able to use some parts of our Service and some Website functionality, such as fonts and stylistic elements may be affected. Unless you have adjusted Your browser settings or privacy settings on Our Website to refuse Cookies, our Service may use Cookies.
                </li>
                <li>Flash Cookies. Certain features of our Service may use local stored objects (or Flash Cookies) to collect and store information about Your preferences or Your activity on our Service. Flash Cookies are not managed by the same browser settings as those used for Browser Cookies.
                </li>
                <li>Web Beacons. Certain sections of our Service and our emails may contain small electronic files known as web beacons (also referred to as clear gifs, pixel tags, and single-pixel gifs) that permit the Company, for example, to count users who have visited those pages or opened an email and for other related website statistics (for example, recording the popularity of a certain section and verifying system and server integrity).
                </li> </ul>
            </Typography>
            <Typography
              className="privacy-policy__body"
              component={"p"}
              variant="body1"
            >
              Cookies can be "Persistent" or "Session" Cookies. Persistent Cookies remain on Your personal computer or mobile device when You go offline, while Session Cookies are deleted as soon as You close Your web browser.

            </Typography>
            <Typography
              className="privacy-policy__body"
              component={"p"}
              variant="body1"
            >
              We use both Session and Persistent Cookies for the purposes set out below:

            </Typography>
            <Typography
              className="privacy-policy__body"
              component={"p"}
              variant="body1"
            >
              <ul className="privacy-policy__list">
                <li>
                  Necessary / Essential Cookies
                  Type: Session Cookies
                  Administered by: Us
                  Purpose: These Cookies are essential to provide You with services available through the Website and to enable You to use some of its features, as well as to enable Us to be compliant with legal obligations. They help to authenticate users and prevent fraudulent use of user accounts. Without these Cookies, the services that You have asked for cannot be provided, and We only use these Cookies to provide You with those services while remaining legally compliant.
                </li>
                <li> Cookies Policy / Notice Acceptance Cookies
                  Type: Persistent Cookies
                  Administered by: Us
                  Purpose: These Cookies identify if users have accepted the use of cookies on the Website.
                </li>
                <li> Functionality Cookies
                  Type: Persistent Cookies
                  Administered by: Us
                  Purpose: These Cookies allow us to remember choices You make when You use the Website, such as remembering your login details or language preference. The purpose of these Cookies is to provide You with a more personal experience and to avoid You having to re-enter your preferences every time You use the Website. These Cookies also enable Our Services to function properly by providing fonts and enabling Users to view the Website as intended.
                </li>
                <li>Tracking and Performance Cookies
                  Type: Persistent Cookies
                  Administered by: Third-Parties
                  Purpose: These Cookies are used to track information about traffic to the Website and how users use the Website. The information gathered via these Cookies may directly or indirectly identify you as an individual visitor. This is because the information collected is typically linked to a pseudonymous identifier associated with the device you use to access the Website. We may also use these Cookies to test new pages, features or new functionality of the Website to see how our users react to them.
                </li>

              </ul>
            </Typography>
            <Typography

              className="privacy-policy__subheader"
              component={"p"}
              variant="body1"
            >
              Use of Your Personal Data
            </Typography>
            <Typography
              className="privacy-policy__body"
              component={"p"}
              variant="body1"
            >
              The Company may use Personal Data for the following purposes:

            </Typography>

            <Typography
              className="privacy-policy__body"
              component={"p"}
              variant="body1"
            >
              <ul className="privacy-policy__list">
                <li>
                  To provide and maintain our Service: Including to monitor the usage of our Service.
                </li>
                To train our software application: Your activity on the site will be used to train the software and categorize the difficulty level for other users.                 <li>
                </li>
                To manage Your Account: To manage Your registration as a User of the Service. The Personal Data You provide can give You access to different functionalities of the Service that are available to You as a registered User.
                <li>
                  For the performance of a contract: The development, compliance and undertaking of the purchase contract for the services You have purchased or of any other contract with Us through the Service.
                </li>
                <li>
                  To contact You: To contact You by email, telephone calls, SMS, or other equivalent forms of electronic communication, such as a mobile application's push notifications regarding updates or informative communications related to the functionalities, products or contracted services, including the security updates, when necessary or reasonable for their implementation.

                </li>
                <li>
                  To provide You: With news, special offers and general information about other goods, services and events which we offer that are similar to those that you have already purchased or enquired about unless You have opted not to receive such information.

                </li>
                <li>
                  To manage Your requests: To attend and manage Your requests to Us.

                </li>
                <li>
                  For business transfers: We may use Your information to evaluate or conduct a merger, divestiture, restructuring, reorganization, dissolution, or other sale or transfer of some or all of Our assets, whether as a going concern or as part of bankruptcy, liquidation, or similar proceeding, in which Personal Data held by Us about our Service users is among the assets transferred.

                </li>
                <li>
                  For other purposes: We may use Your information for other purposes, such as data analysis, identifying usage trends, determining the effectiveness of our promotional campaigns and to evaluate and improve our Service, products, services, marketing and your experience.

                </li>


              </ul>
            </Typography>
            <Typography
              className="privacy-policy__body"
              component={"p"}
              variant="body1"
            >
              We may share Your personal information in the following situations:

            </Typography>
            <Typography
              className="privacy-policy__body"
              component={"p"}
              variant="body1"
            >
              <ul className="privacy-policy__list">
                <li>
                  With Service Providers: We may share Your personal information with Service Providers to monitor and analyze the use of our Service, for payment processing, or to contact You.

                </li>
                <li>
                  For business transfers: We may share or transfer Your personal information in connection with, or during negotiations of, any merger, sale of Company assets, financing, or acquisition of all or a portion of Our business to another company.
                </li>
                To manage Your Account: To manage Your registration as a User of the Service. The Personal Data You provide can give You access to different functionalities of the Service that are available to You as a registered User.
                <li>
                  With Affiliates: We may share Your information with Our affiliates, in which case we will require those affiliates to honor this Privacy Policy. Affiliates include Our parent company and any other subsidiaries, joint venture partners or other companies that We control or that are under common control with Us.               </li>


                <li>
                  With business partners: We may share Your information with Our business partners to offer You certain products, services or promotions.

                </li>
                <li>
                  With Your consent: We may disclose Your personal information for any other purpose with Your consent.

                </li>
                <li>
                  With other third parties to protect us or others: We may share information about you with other parties for our business purposes, including to protect the vital interests of a person; to protect our property, the Services and legal rights; to help assess and manage risk and prevent fraud against us, our Users and fraud involving the Site or use of the Services, including fraud that occurs at or involves our business partners, strategic ventures, or other individuals; to companies that we plan to merge with or be acquired by; and to support our audit, compliance, and corporate governance functions.

                </li>
                <li>
                  With other parties as permitted or required by law and regulations: We may share information about you with other parties if we need to do so to comply with a law, legal process or regulations; or as required by law enforcement, regulators, government officials, or other third parties in relation to a subpoena, court order, or other legal process or requirement under applicable law or regulation; or when we believe, in our sole discretion, that the disclosure of Personal Information is necessary or appropriate to prevent physical harm or financial loss; or to report suspected illegal activity or to investigate
                  violations of a User agreement; or to satisfy regulatory requirements as applicable to provide our Services.

                </li>


              </ul>
            </Typography>
            <Typography

              className="privacy-policy__subheader"
              component={"p"}
              variant="body1"
            >
              Security Measures
            </Typography>
            <Typography
              className="privacy-policy__body"
              component={"p"}
              variant="body1"
            >
              To maintain the highest level of data security, We have implemented several measures including:

            </Typography>


            <Typography
              className="privacy-policy__body"
              component={"p"}
              variant="body1"
            >
              <ul className="privacy-policy__list">
                <li>
                  Encryption: We encrypt all sensitive data, both at rest and in transit. This means that Your data is protected by cryptographic measures at all times, making it inaccessible to unauthorized parties.
                </li>
                <li>
                  Access Controls: Access to data is strictly controlled and monitored. We implement stringent access controls to ensure that only authorized personnel have access to sensitive information. Employees and contractors are granted access on a need-to-know basis, and their interactions with the data are logged and audited.
                </li>
                <li>
                  Regular Security Audits: Our systems undergo regular security audits to identify and rectify any potential vulnerabilities. These audits help Us stay ahead of emerging security threats and ensure that our defenses are robust and effective.
                </li>
                <li>
                  Continuous Monitoring: We employ continuous monitoring mechanisms to detect and respond to any unusual activity or potential security breaches. Our team is alerted to any anomalies in real-time, enabling swift action to safeguard your data.
                </li>
                <li>
                  Data Backup and Recovery: We maintain regular backups of data to prevent data loss and ensure business continuity. Our backup and recovery procedures are designed to restore data quickly and efficiently in the event of an unforeseen incident.
                </li>
              </ul>
            </Typography>
            <Typography

              className="privacy-policy__subheader"
              component={"p"}
              variant="body1"
            >
              Retention of Your Personal Data

            </Typography>
            <Typography
              className="privacy-policy__body"
              component={"p"}
              variant="body1"
            >

              The Company will retain Your Personal Data only for as long as is necessary for the purposes set out in this Privacy Policy. We will retain and use Your Personal Data to the extent necessary to comply with our legal obligations (for example, if we are required to retain your data to comply with applicable laws), resolve disputes, and enforce our legal agreements and policies. The Company will also retain Usage Data for internal analysis purposes. Usage Data is generally retained for a shorter period of time, except when this data is used to strengthen the security or to improve the functionality of Our Service, or We are legally obligated to retain this data for longer time periods.


            </Typography>
            <Typography

              className="privacy-policy__subheader"
              component={"p"}
              variant="body1"
            >
              Transfer of Your Personal Data

            </Typography>
            <Typography
              className="privacy-policy__body"
              component={"p"}
              variant="body1"
            >

              Your information, including Personal Data, is processed at the Company's operating offices and in any other places where the parties involved in the processing are located. It means that this information may be transferred to — and maintained on — computers located outside of Your state where the data protection laws may differ from those from Your jurisdiction. Your consent to this Privacy Policy followed by Your submission of such information represents Your agreement to that transfer. The Company will take all steps reasonably necessary to ensure that Your data is treated securely and in accordance with this Privacy Policy and no transfer of Your Personal Data will take place to an organization or a country unless there are adequate controls in place including the security of Your data and other personal information.


            </Typography>

            <Typography

              className="privacy-policy__subheader"
              component={"p"}
              variant="body1"
            >
              Disclosure of Your Personal Data

            </Typography>


            <Typography

              className="privacy-policy__subheader"
              component={"p"}
              variant="body1"
            >
              Business Transactions
            </Typography>


            <Typography
              className="privacy-policy__body"
              component={"p"}
              variant="body1"
            >

              If the Company is involved in a merger, acquisition or asset sale, Your Personal Data may be transferred. We will provide notice before Your Personal Data is transferred and becomes subject to a different Privacy Policy.


            </Typography>



            <Typography
              className="privacy-policy__subheader"
              component={"p"}
              variant="body1"
            >
              Law Enforcement
            </Typography>
            <Typography
              className="privacy-policy__body"
              component={"p"}
              variant="body1"
            >

              Under certain circumstances, the Company may be required to disclose Your Personal Data if required to do so by law or in response to valid requests by public authorities (e.g.., a court or a government agency).


            </Typography>


            <Typography
              className="privacy-policy__subheader"
              component={"p"}
              variant="body1"
            >
              Other Legal Requirements
            </Typography>
            <Typography
              className="privacy-policy__body"
              component={"p"}
              variant="body1"
            >

              The Company may disclose Your Personal Data in good faith if We believe that such action is necessary to:


            </Typography>
            <Typography
              className="privacy-policy__body"
              component={"p"}
              variant="body1"
            >
              <ul className="privacy-policy__list">
                <li>
                  Comply with a legal obligation;
                </li>
                <li>
                  Protect and defend the rights or property of the Company;
                </li>
                <li>
                  Prevent or investigate possible wrongdoing in connection with the Service;
                </li>
                <li>
                  Protect the personal safety of Users of the Service or the public; and/or
                </li>
                <li>
                  Protect against legal liability.
                </li>
              </ul>
            </Typography>


            <Typography
              className="privacy-policy__subheader"
              component={"p"}
              variant="body1"
            >
              Security of Your Personal Data
            </Typography>
            <Typography
              className="privacy-policy__body"
              component={"p"}
              variant="body1"
            >

              The security of Your Personal Data is important to Us; but remember that no method of transmission over the Internet, or method of electronic storage is 100% secure. While We strive to use commercially acceptable means to protect Your Personal Data, We cannot guarantee its absolute security.


            </Typography>
            <Typography
              className="privacy-policy__subheader"
              component={"p"}
              variant="body1"
            >
              Detailed Information on the Processing of Your Personal Data
            </Typography>
            <Typography
              className="privacy-policy__body"
              component={"p"}
              variant="body1"
            >

              The Service Providers We use may have access to Your Personal Data. These third-party vendors collect, store, use, process and transfer information about Your activity on Our Service in accordance with their Privacy Policies.

            </Typography>
            <Typography
              className="privacy-policy__subheader"
              component={"p"}
              variant="body1"
            >
              Analytics
            </Typography>
            <Typography
              className="privacy-policy__body"
              component={"p"}
              variant="body1"
            >

              We may use third-party Service providers to monitor and analyze the use of our Service. Google Analytics is a web analytics service offered by Google that tracks and reports website traffic. Google uses the data collected to track and monitor the use of our Service. This data is shared with other Google services. Google may use the collected data to contextualize and personalize the ads of its own advertising network. You can opt-out of having made your activity on the Service available to Google Analytics by installing the Google Analytics opt-out browser add-on. The add-on prevents the Google Analytics JavaScript (ga.js, analytics.js and dc.js) from sharing information with Google Analytics about visits activity. You may opt-out of certain Google Analytics features through your mobile device settings, such as your device advertising settings or by following the instructions provided by Google in their Privacy Policy: https://policies.google.com/privacy. For more information on the privacy practices of Google, please visit the Google Privacy & Terms web page: https://policies.google.com/privacy
            </Typography>
            <Typography
              className="privacy-policy__subheader"
              component={"p"}
              variant="body1"
            >
              Email Marketing
            </Typography>
            <Typography
              className="privacy-policy__body"
              component={"p"}
              variant="body1"
            >

              We may use Your Personal Data to contact You with newsletters, marketing or promotional materials and other information that may be of interest to You. You may opt-out of receiving any, or all, of these communications from Us by following the unsubscribe link or instructions provided in any email We send or by contacting Us. We may use Email Marketing Service Providers to manage and send emails to You. Mailchimp is an email marketing sending service provided by The Rocket Science Group LLC. For more information on the privacy practices of Mailchimp, please visit their Privacy policy: https://mailchimp.com/legal/privacy/
            </Typography>
            <Typography
              className="privacy-policy__subheader"
              component={"p"}
              variant="body1"
            >
              Payments

            </Typography>
            <Typography
              className="privacy-policy__body"
              component={"p"}
              variant="body1"
            >

              We provide paid products within the Service. As a result, we will use the third-party services of Stripe as Our payment processor. We will not store or collect Your payment card details. That information is provided directly to Our third-party payment processors whose use of Your personal information is governed by their Privacy Policy. These payment processors adhere to the standards set by PCI-DSS as managed by the PCI Security Standards Council, which is a joint effort of brands like Visa, Mastercard, American Express, and Discover. PCI-DSS requirements help ensure the secure handling of payment information. The Authorize.net Privacy Policy can be viewed at https://usa.visa.com/legal/privacy-policy.html. </Typography>

            <Typography
              className="privacy-policy__subheader"
              component={"p"}
              variant="body1"
            >
              Behavioral Remarketing

            </Typography>

            <Typography
              className="privacy-policy__body"
              component={"p"}
              variant="body1"
            >

              The Company uses remarketing services to advertise to You after You accessed or visited our Service. We and Our third-party vendors use cookies and non-cookie technologies to help Us recognize Your Device and understand how You use our Service so that We can improve our Service to reflect Your interests and serve You advertisements that are likely to be of more interest to You. These third-party vendors collect, store, use, process and transfer information about Your activity on Our Service in accordance with their Privacy Policies and to enable Us to:


            </Typography>
            <Typography
              className="privacy-policy__body"
              component={"p"}
              variant="body1"
            >
              <ul className="privacy-policy__list">
                <li>
                  Measure and analyze traffic and browsing activity on Our Service;

                </li>
                <li>
                  Show advertisements for our products and/or services to You on third-party websites;
                </li>
                <li>
                  Measure and analyze the performance of Our advertising campaigns.
                </li>

              </ul>
            </Typography>
            <Typography
              className="privacy-policy__body"
              component={"p"}
              variant="body1"
            >

              Some of these third-party vendors may use non-cookie technologies that may not be impacted by browser settings that block cookies. Your browser may not permit You to block such technologies. You can use the following third-party tools to decline the collection and use of information for the purpose of serving You interest-based advertising:

            </Typography>
            <Typography
              className="privacy-policy__body"
              component={"p"}
              variant="body1"
            >
              <ul className="privacy-policy__list">
                <li>
                  The NAI's opt-out platform: http://www.networkadvertising.org/choices/
                </li>
                <li>
                  The EDAA's opt-out platform http://www.youronlinechoices.com/
                </li>
                <li>
                  The DAA's opt-out platform: http://optout.aboutads.info/?c=2&lang=EN
                </li>

              </ul>
            </Typography>
            <Typography
              className="privacy-policy__body"
              component={"p"}
              variant="body1"
            >

              You may opt-out of all personalized advertising by enabling privacy features on Your mobile device such as Limit Ad Tracking (iOS) and Opt Out of Ads Personalization (Android). See Your mobile device Help system for more information. We may share information, such as hashed email addresses (if available) or other online identifiers collected on Our Service with these third-party vendors. This allows Our third-party vendors to recognize and deliver You ads across devices and browsers. To read more about the technologies used by these third-party vendors and their cross-device capabilities please refer to the Privacy Policy of each vendor listed below.
            </Typography>

            <Typography
              className="privacy-policy__body"
              component={"p"}
              variant="body1"
            >

              The third-party vendors We may use are:

            </Typography>


            <Typography
              className="privacy-policy__body"
              component={"p"}
              variant="body1"
            >
              <ul className="privacy-policy__list">
                <li>
                  Google Ads (AdWords) - Google Ads (AdWords) remarketing service is provided by Google Inc. You can opt out of Google Analytics for Display Advertising and customize the Google Display Network ads by visiting the Google Ads Settings page: http://www.google.com/settings/ads. Google also recommends installing the Google Analytics Opt-out Browser Add-on - https://tools.google.com/dlpage/gaoptout - for your web browser. Google Analytics Opt-out Browser Add-on provides visitors with the ability to prevent their data from being collected and used by Google Analytics. For more information on the privacy practices of Google, please visit the Google Privacy & Terms web page: https://policies.google.com/privacy.
                </li>
                <li>
                  Bing Ads Remarketing - Bing Ads remarketing service is provided by Microsoft Inc. You can opt-out of Bing Ads interest-based ads by following their instructions: https://advertise.bingads.microsoft.com/en-us/resources/policies/personalized-ads. You can learn more about the privacy practices and policies of Microsoft by visiting their Privacy Policy page: https://privacy.microsoft.com/en-us/PrivacyStatement.
                </li>
                <li>
                  Twitter - Twitter remarketing service is provided by Twitter Inc. You can opt-out from Twitter's interest-based ads by following their instructions: https://support.twitter.com/articles/20170405. You can learn more about the privacy practices and policies of Twitter by visiting their Privacy Policy page: https://twitter.com/privacy.
                </li>
                <li>
                  Facebook - Facebook remarketing service is provided by Facebook Inc. You can learn more about interest-based advertising from Facebook by visiting this page: https://www.facebook.com/help/516147308587266. To opt out from Facebook's interest-based ads, follow these instructions from Facebook: https://www.facebook.com/help/568137493302217. Facebook adheres to the Self-Regulatory Principles for Online Behavioral Advertising established by the Digital Advertising Alliance. You can also opt out from Facebook and other participating companies through the Digital Advertising Alliance in the USA http://www.aboutads.info/choices/, the Digital Advertising Alliance of Canada in Canada http://youradchoices.ca/ or the European Interactive Digital Advertising Alliance in Europe http://www.youronlinechoices.eu/, or opt out using your mobile device settings. For more information on the privacy practices of Facebook, please visit Facebook's Data Policy: https://www.facebook.com/privacy/explanation.
                </li>
                <li>
                  Pinterest - Pinterest remarketing service is provided by Pinterest Inc. You can opt out from Pinterest's interest-based ads by enabling the "Do Not Track" functionality of your web browser or by following Pinterest instructions: http://help.pinterest.com/en/articles/personalization-and-data. You can learn more about the privacy practices and policies of Pinterest by visiting their Privacy Policy page: https://policy.pinterest.com/en/privacy-policy.
                </li>
              </ul>
            </Typography>












            <Typography
              className="privacy-policy__subheader"
              component={"p"}
              variant="body1"
            >
              Usage, Performance and Miscellaneous

            </Typography>



            <Typography
              className="privacy-policy__body"
              component={"p"}
              variant="body1"
            >

              We may use third-party Service Providers to provide better improvement of our Service. We use an invisible captcha service named reCAPTCHA. reCAPTCHA is operated by Google. The reCAPTCHA service may collect information from You and from Your Device for security purposes. The information gathered by reCAPTCHA is held in accordance with the Privacy Policy of Google:
              https://www.google.com/intl/en/policies/privacy/



            </Typography>







            <Typography
              className="privacy-policy__subheader"
              component={"p"}
              variant="body1"
            >
              GDPR Privacy
            </Typography>
            <Typography
              className="privacy-policy__subheader"
              component={"p"}
              variant="body1"
            >
              Legal Basis for Processing Personal Data under GDPR

            </Typography>

            <Typography
              className="privacy-policy__body"
              component={"p"}
              variant="body1"
            >

              We may process Personal Data under the following conditions:




            </Typography>
            <Typography
              className="privacy-policy__body"
              component={"p"}
              variant="body1"
            >
              <ul className="privacy-policy__list">
                <li>
                  Consent: You have given Your consent for processing Personal Data for one or more specific purposes.
                </li>
                <li>
                  Performance of a contract: Provision of Personal Data is necessary for the performance of an agreement with You and/or for any pre-contractual obligations thereof.
                </li>
                <li>
                  Legal obligations: Processing Personal Data is necessary for compliance with a legal obligation to which the Company is subject.
                </li>
                <li>
                  Vital interests: Processing Personal Data is necessary in order to protect Your vital interests or of another natural person.
                </li>
                <li>
                  Public interests: Processing Personal Data is related to a task that is carried out in the public interest or in the exercise of official authority vested in the Company.
                </li>
                <li>
                  Legitimate interests: Processing Personal Data is necessary for the purposes of the legitimate interests pursued by the Company.
                </li>
              </ul>
            </Typography>

            <Typography
              className="privacy-policy__body"
              component={"p"}
              variant="body1"
            >

              In any case, the Company will gladly help to clarify the specific legal basis that applies to the processing, and in particular whether the provision of Personal Data is a statutory or contractual requirement, or a requirement necessary to enter into a contract.



            </Typography>
            <Typography
              className="privacy-policy__subheader"
              component={"p"}
              variant="body1"
            >
              Your Rights under the GDPR


            </Typography>
            <Typography
              className="privacy-policy__body"
              component={"p"}
              variant="body1"
            >

              The Company undertakes to respect the confidentiality of Your Personal Data and to guarantee You can exercise Your rights. You have the right under this Privacy Policy, and by law if You are within the EU, to:




            </Typography>
            <Typography
              className="privacy-policy__body"
              component={"p"}
              variant="body1"
            >
              <ul className="privacy-policy__list">
                <li>
                  Request access to Your Personal Data. The right to access, update or delete the information We have on You. Whenever made possible, you can access or update of Your Personal Data directly within Your account settings pages. To request deletion of your data, please contact Us to assist You. This also enables You to receive a copy of the Personal Data We hold about You.
                </li>
                <li>
                  Request correction of the Personal Data that We hold about You. You have the right to have any incomplete or inaccurate information We hold about You corrected.
                </li>
                <li>
                  Object to processing of Your Personal Data. This right exists where We are relying on a legitimate interest as the legal basis for Our processing and there is something about Your particular situation, which makes You want to object to our processing of Your Personal Data on this ground. You also have the right to object where We are processing Your Personal Data for direct marketing purposes.
                </li>
                <li>
                  Request erasure of Your Personal Data. You have the right to ask Us to delete or remove Personal Data when there is no good reason for Us to continue processing it.
                </li>
                <li>
                  Request the transfer of Your Personal Data. We will provide to You, or to a third-party You have chosen, Your Personal Data in a structured, commonly used, machine-readable format. Please note that this right only applies to automated information which You initially provided consent for Us to use or where We used the information to perform a contract with You.
                </li>
                <li>
                  Withdraw Your consent. You have the right to withdraw Your consent on using your Personal Data. If You withdraw Your consent, We may not be able to provide You with access to certain specific functionalities of the Service.
                </li>
              </ul>
            </Typography>


            <Typography
              className="privacy-policy__subheader"
              component={"p"}
              variant="body1"
            >
              Exercising of Your GDPR Data Protection Rights



            </Typography>
            <Typography
              className="privacy-policy__body"
              component={"p"}
              variant="body1"
            >

              You may exercise Your rights of access, rectification, cancellation and opposition by contacting Us. Please note that we may ask You to verify Your identity before responding to such requests. If You make a request, We will try our best to respond to You as soon as possible. You have the right to complain to a Data Protection Authority about Our collection and use of Your Personal Data. For more information, if You are in the European Economic Area (EEA), please contact Your local data protection authority in the EEA.



            </Typography>
            <Typography
              className="privacy-policy__subheader"
              component={"p"}
              variant="body1"
            >
              CCPA Privacy
            </Typography>
            <Typography
              className="privacy-policy__body"
              component={"p"}
              variant="body1"
            >

              This privacy notice section for California residents supplements the information contained in Our Privacy Policy and it applies solely to all visitors, users, and others who reside in the State of California.




            </Typography>
            <Typography
              className="privacy-policy__subheader"
              component={"p"}
              variant="body1"
            >
              Categories of Personal Information Collected
            </Typography>
            <Typography
              className="privacy-policy__body"
              component={"p"}
              variant="body1"
            >

              We collect information that identifies, relates to, describes, references, is capable of being associated with, or could reasonably be linked, directly or indirectly, with a particular Consumer or Device. The following is a list of categories of personal information which we may collect or may have been collected from California residents within the last twelve (12) months. Please note that the categories and examples provided in the list below are those defined in the CCPA. This does not mean that all examples of that category of personal information were in fact collected by Us but reflects our good faith belief to the best of our knowledge that some of that information from the applicable category may be and may have been collected. For example, certain categories of personal information would only be collected if You provided such personal information directly to Us.



            </Typography>
            <Typography className="privacy-policy__body" component={"p"} variant="body1">
  <ul className="privacy-policy__list">
    <li>
       <strong>Category A: Identifiers.</strong><br />
      Examples: A real name, alias, postal address, unique personal identifier, online identifier, Internet Protocol address, email address, account name, driver's license number, passport number, or other similar identifiers.<br />
      Collected: Yes.
    </li>
    <li>
       <strong>Category B: Personal information categories</strong> listed in the California Customer Records statute (Cal. Civ. Code § 1798.80(e)).<br />
      Examples: A name, signature, Social Security number, physical characteristics or description, address, telephone number, passport number, driver's license or state identification card number, insurance policy number, education, employment, employment history, bank account number, credit card number, debit card number, or any other financial information, medical information, or health insurance information. Some personal information included in this category may overlap with other categories.<br />
      Collected: Yes.
    </li>
    <li>
       <strong>Category C: Protected classification characteristics</strong> under California or federal law.<br />
      Examples: Age (40 years or older), race, color, ancestry, national origin, citizenship, religion or creed, marital status, medical condition, physical or mental disability, sex (including gender, gender identity, gender expression, pregnancy or childbirth and related medical conditions), sexual orientation, veteran or military status, genetic information (including familial genetic information).<br />
      Collected: No.
    </li>
    <li>
       <strong>Category D: Commercial information.</strong><br />
      Examples: Records and history of products or services purchased or considered.<br />
      Collected: Yes.
    </li>
    <li>
       <strong>Category E: Biometric information.</strong><br />
      Examples: Genetic, physiological, behavioral, and biological characteristics, or activity patterns used to extract a template or other identifier or identifying information, such as, fingerprints, faceprints, and voiceprints, iris or retina scans, keystroke, gait, or other physical patterns, and sleep, health, or exercise data.<br />
      Collected: No.
    </li>
    <li>
       <strong>Category F: Internet or other similar network activity.</strong><br />
      Examples: Interaction with our Service or advertisement.<br />
      Collected: Yes.
    </li>
    <li>
       <strong>Category G: Geolocation data.</strong><br />
      Examples: Approximate physical location.<br />
      Collected: No.
    </li>
    <li>
       <strong>Category H: Sensory data.</strong><br />
      Examples: Audio, electronic, visual, thermal, olfactory, or similar information.<br />
      Collected: No.
    </li>
    <li>
       <strong>Category I: Professional or employment-related information.</strong><br />
      Examples: Current or past job history or performance evaluations.<br />
      Collected: No.
    </li>
    <li>
       <strong>Category J: Non-public education information</strong> (per the Family Educational Rights and Privacy Act (20 U.S.C. Section 1232g, 34 C.F.R. Part 99)).<br />
      Examples: Education records directly related to a student maintained by an educational institution or party acting on its behalf, such as grades, transcripts, class lists, student schedules, student identification codes, student financial information, or student disciplinary records.<br />
      Collected: No.
    </li>
    <li>
       <strong>Category K: Inferences drawn from other personal information.</strong><br />
      Examples: Profile reflecting a person's preferences, characteristics, psychological trends, predispositions, behavior, attitudes, intelligence, abilities, and aptitudes.<br />
      Collected: No.
    </li>
  </ul>
</Typography>
<Typography
              className="privacy-policy__body"
              component={"p"}
              variant="body1"
            >

Under CCPA, personal information does not include: 



            </Typography>
            <Typography className="privacy-policy__body" component={"p"} variant="body1">
  <ul className="privacy-policy__list">
    <li>
       Publicly available information from government records;
    </li>
    <li>
       Deidentified or aggregated consumer information;
    </li>
    <li>
       Information excluded from the CCPA's scope, such as:
      <ul>
        <li>
          ○ Health or medical information covered by the Health Insurance Portability and Accountability Act of 1996 (HIPAA) and the California Confidentiality of Medical Information Act (CMIA) or clinical trial data;
        </li>
        <li>
          ○ Personal Information covered by certain sector-specific privacy laws, including the Fair Credit Reporting Act (FRCA), the Gramm-Leach-Bliley Act (GLBA) or California Financial Information Privacy Act (FIPA), and the Driver's Privacy Protection Act of 1994.
        </li>
      </ul>
    </li>
  </ul>
</Typography>

<Typography
              className="privacy-policy__subheader"
              component={"p"}
              variant="body1"
            >
              Sources of Personal Information 
            </Typography>
            <Typography
              className="privacy-policy__body"
              component={"p"}
              variant="body1"
            >

We obtain the categories of personal information listed above from the following categories of sources: 



            </Typography>
            <Typography className="privacy-policy__body" component={"p"} variant="body1">
  <ul className="privacy-policy__list">
    <li>
       Directly from You. For example, from the forms You complete on our Service, preferences You express or provide through our Service, or from Your purchases on our Service. 

    </li>
    <li>
       Indirectly from You. For example, from observing Your activity on our Service.

    </li>
    <li>
       Automatically from You. For example, through Cookies We or our Service Providers set on Your Device as You navigate through our Service. 

    </li>
    <li>
     From Service Providers. For example, third-party vendors to monitor and analyze the use of our Service, third-party vendors to deliver targeted advertising to You, third-party vendors for payment processing, or other third-party vendors that We use to provide the Service to You. 
    </li>
  </ul>
  </Typography>
            <Typography
              className="privacy-policy__subheader"
              component={"p"}
              variant="body1"
            >
              Use of Personal Information for Business Purposes or Commercial Purposes 

            </Typography>
            <Typography
              className="privacy-policy__body"
              component={"p"}
              variant="body1"
            >

We may use or disclose personal information We collect for "business purposes" or "commercial purposes" (as defined under the CCPA), which may include the following examples: 
            </Typography>


            <Typography className="privacy-policy__body" component={"p"} variant="body1">
  <ul className="privacy-policy__list">
    <li>
       To operate our Service and provide You with our Service.
    </li>
    <li>
       To provide You with support and to respond to Your inquiries, including to investigate and address Your concerns and monitor and improve our Service.
    </li>
    <li>
       To fulfill or meet the reason You provided the information. For example, if You share Your contact information to ask a question about our Service, We will use that personal information to respond to Your inquiry. If You provide Your personal information to purchase a product or service, We will use that information to process Your payment and facilitate delivery.
    </li>
    <li>
       To respond to law enforcement requests and as required by applicable law, court order, or governmental regulations.
    </li>
    <li>
       As described to You when collecting Your personal information or as otherwise set forth in the CCPA.
    </li>
    <li>
       For internal administrative and auditing purposes.
    </li>
    <li>
       To detect security incidents and protect against malicious, deceptive, fraudulent, or illegal activity, including, when necessary, to prosecute those responsible for such activities.
    </li>
  </ul>
</Typography>



<Typography
              className="privacy-policy__body"
              component={"p"}
              variant="body1"
            >

Please note that the examples provided above are illustrative and not intended to be exhaustive. For more details on how we use this information, please refer to the "Use of Your Personal Data" section. If We decide to collect additional categories of personal information or use the personal information We collected for materially different, unrelated, or incompatible purposes We will update this Privacy Policy. 
            </Typography>
            <Typography
              className="privacy-policy__subheader"
              component={"p"}
              variant="body1"
            >
              Disclosure of Personal Information for Business Purposes or Commercial Purposes 

            </Typography>
            <Typography
              className="privacy-policy__body"
              component={"p"}
              variant="body1"
            >

We may use or disclose and may have used or disclosed in the last twelve (12) months the following categories of personal information for business or commercial purposes:             
</Typography>

<Typography className="privacy-policy__body" component={"p"} variant="body1">
  <ul className="privacy-policy__list">
    <li>
       Category A: Identifiers
    </li>
    <li>
       Category B: Personal information categories listed in the California Customer Records statute (Cal. Civ. Code § 1798.80(e))
    </li>
    <li>
       Category D: Commercial information
    </li>
    <li>
       Category F: Internet or other similar network activity
    </li>
  </ul>
</Typography>

<Typography
              className="privacy-policy__body"
              component={"p"}
              variant="body1"
            >

Please note that the categories listed above are those defined in the CCPA. This does not mean that all examples of that category of personal information were in fact disclosed but reflects our good faith belief to the best of our knowledge that some of that information from the applicable category may be and may have been used or disclosed. When We disclose personal information for a business purpose or a commercial purpose, We enter a contract that describes the purpose and requires the recipient to both keep that personal information confidential and not use it for any purpose except performing the contract. </Typography>




<Typography
              className="privacy-policy__subheader"
              component={"p"}
              variant="body1"
            >
              Sale of Personal Information 
            </Typography>


            <Typography
              className="privacy-policy__body"
              component={"p"}
              variant="body1"
            >

As defined in the CCPA, "sell" and "sale" mean selling, renting, releasing, disclosing, disseminating, making available, transferring, or otherwise communicating orally, in writing, or by electronic or other means, a consumer's personal information by the business to a third party for valuable consideration. This means that We may have received some kind of benefit in return for sharing personal information, but not necessarily a monetary benefit. We do not sell any category of personal information. 

</Typography>
<Typography
              className="privacy-policy__subheader"
              component={"p"}
              variant="body1"
            >
Sharing of Personal Information 
            </Typography>

            <Typography
              className="privacy-policy__body"
              component={"p"}
              variant="body1"
            >

We may share Your personal information identified in the above categories with the following categories of third parties: 
</Typography>

<Typography className="privacy-policy__body" component={"p"} variant="body1">
  <ul className="privacy-policy__list">
    <li>
       Service Providers
    </li>
    <li>
       Payment processors
    </li>
    <li>
       Our affiliates
    </li>
    <li>
       Our business partners
    </li>
    <li>
       Third party vendors to whom You or Your agents authorize Us to disclose Your personal information in connection with products or services We provide to You
    </li>
  </ul>
</Typography>



<Typography
              className="privacy-policy__subheader"
              component={"p"}
              variant="body1"
            >
Sale of Personal Information of Minors Under 16 Years of Age 
            </Typography>

            <Typography
              className="privacy-policy__body"
              component={"p"}
              variant="body1"
            >

We do not sell the personal information of Consumers less than 16 years of age. 
</Typography>


<Typography
              className="privacy-policy__subheader"
              component={"p"}
              variant="body1"
            >
Your Rights under the CCPA 
            </Typography>

            <Typography
              className="privacy-policy__body"
              component={"p"}
              variant="body1"
            >

The CCPA provides California residents with specific rights regarding their personal information. If You are a resident of California, You have the following rights: 
</Typography>



<Typography className="privacy-policy__body" component={"p"} variant="body1">
  <ul className="privacy-policy__list">
    <li>
       The right to notice. You have the right to be notified which categories of Personal Data are being collected and the purposes for which the Personal Data is being used.
    </li>
    <li>
       The right to request. Under CCPA, You have the right to request that We disclose information to You about Our collection, use, sale, disclosure for business purposes and share of personal information. Once We receive and confirm Your request, We will disclose to You:
      <ul>
        <li>The categories of personal information We collected about You</li>
        <li>The categories of sources for the personal information We collected about You</li>
        <li>Our business or commercial purpose for collecting or selling that personal information</li>
        <li>The categories of third parties with whom We share that personal information</li>
        <li>The specific pieces of personal information We collected about You</li>
        <li>If we sold Your personal information or disclosed Your personal information for a business purpose, We will disclose to You:
          <ul>
            <li>The categories of personal information categories sold</li>
            <li>The categories of personal information categories disclosed</li>
          </ul>
        </li>
      </ul>
    </li>
    <li>
       The right to say no to the sale of Personal Data (opt-out). You have the right to direct Us to not sell Your personal information. As noted above, we do NOT sell personal information.
    </li>
    <li>
       The right to delete Personal Data. You have the right to request the deletion of Your Personal Data, subject to certain exceptions. Once We receive and confirm Your request, We will delete (and direct Our Service Providers to delete) Your personal information from our records, unless an exception applies. We may deny Your deletion request if retaining the information is necessary for Us or Our Service Providers to:
      <ul>
        <li>Complete the transaction for which We collected the personal information, provide a good or service that You requested, take actions reasonably anticipated within the context of our ongoing business relationship with You, or otherwise perform our contract with You.</li>
        <li>Detect security incidents, protect against malicious, deceptive, fraudulent, or illegal activity, or prosecute those responsible for such activities.</li>
        <li>Debug products to identify and repair errors that impair existing intended functionality.</li>
        <li>Exercise free speech, ensure the right of another consumer to exercise their free speech rights, or exercise another right provided for by law.</li>
        <li>Comply with the California Electronic Communications Privacy Act (Cal. Penal Code § 1546 et. seq.).</li>
        <li>Engage in public or peer-reviewed scientific, historical, or statistical research in the public interest that adheres to all other applicable ethics and privacy laws, when the information's deletion may likely render impossible or seriously impair the research's achievement, if You previously provided informed consent.</li>
        <li>Enable solely internal uses that are reasonably aligned with consumer expectations based on Your relationship with Us.</li>
        <li>Comply with a legal obligation.</li>
        <li>Make other internal and lawful uses of that information that are compatible with the context in which You provided it.</li>
      </ul>
    </li>
    <li>
       The right not to be discriminated against. You have the right not to be discriminated against for exercising any of Your consumer's rights, including by:
      <ul>
        <li>Denying goods or services to You</li>
        <li>Charging different prices or rates for goods or services, including the use of discounts or other benefits or imposing penalties</li>
        <li>Providing a different level or quality of goods or services to You</li>
        <li>Suggesting that You will receive a different price or rate for goods or services or a different level or quality of goods or services</li>
      </ul>
    </li>
  </ul>
</Typography>


<Typography
              className="privacy-policy__subheader"
              component={"p"}
              variant="body1"
            >
Exercising Your CCPA Data Protection Rights 
            </Typography>

            <Typography
              className="privacy-policy__body"
              component={"p"}
              variant="body1"
            >

In order to exercise any of Your rights under the CCPA, and if You are a California resident, You can contact Us: 

</Typography>

<Typography className="privacy-policy__body" component={"p"} variant="body1">
  <ul className="privacy-policy__list">
    <li>
     By email: legal@paywell.solutions 
    </li>
  </ul>
</Typography>
<Typography
              className="privacy-policy__body"
              component={"p"}
              variant="body1"
            >

Only You, or a person registered with the California Secretary of State that You authorize to act on Your behalf, may make a verifiable request related to Your personal information. 
</Typography>
<Typography
              className="privacy-policy__body"
              component={"p"}
              variant="body1"
            >

Your request to Us must: 
</Typography>
<Typography className="privacy-policy__body" component={"p"} variant="body1">
  <ul className="privacy-policy__list">
    <li>
     Provide sufficient information that allows Us to reasonably verify You are the person about whom We collected personal information or an authorized representative 
    </li>
    <li>
     Describe Your request with sufficient detail that allows Us to properly understand, evaluate, and respond to it 
    </li>
  </ul>
</Typography>

<Typography
              className="privacy-policy__body"
              component={"p"}
              variant="body1"
            >

We cannot respond to Your request or provide You with the required information if We cannot: 
</Typography>

<Typography className="privacy-policy__body" component={"p"} variant="body1">
  <ul className="privacy-policy__list">
    <li>
     Verify Your identity or authority to make the request 
    </li>
    <li>
     And confirm that the personal information relates to You 
    </li>
  </ul>
</Typography>

<Typography
              className="privacy-policy__body"
              component={"p"}
              variant="body1"
            >

We will disclose and deliver the required information free of charge within 45 days of receiving Your verifiable request. The time period to provide the required information may be extended once by an additional 45 days when reasonably necessary and with prior notice. Any disclosures We provide will only cover the 12-month period preceding the verifiable request's receipt. For data portability requests, We will select a format to provide Your personal information that is readily usable and should allow You to transmit the information from one entity to another entity without hindrance. 
</Typography>

<Typography
              className="privacy-policy__subheader"
              component={"p"}
              variant="body1"
            >
Do Not Sell My Personal Information
            </Typography>
<Typography
              className="privacy-policy__body"
              component={"p"}
              variant="body1"
            >

We do not sell personal information. In the event that we would do so, You would have the right to opt out of the sale of Your personal information. Once We receive and confirm a verifiable consumer request from You, we will stop selling Your personal information. To exercise Your right to opt-out please contact Us. The Service Providers we partner with (for example, our analytics or advertising partners) may use technology on the Service that sells personal information as defined by the CCPA law. If you wish to opt out of the use of Your personal information for interest-based advertising purposes and these potential sales as defined under CCPA law, you may do so by following the instructions below. Please note that any opt out is specific to the browser You use. You may need to opt out on every browser that You use. </Typography>








            <Typography
              className="privacy-policy__subheader"
              component={"p"}
              variant="body1"
            >
              Website
            </Typography>
            <Typography
              className="privacy-policy__body"
              component={"p"}
              variant="body1"
            >
In the event that we provided ads which we NOT, You could opt out of receiving ads that are personalized as served by our Service Providers by following our instructions presented on the Service: 
            </Typography>
            <Typography className="privacy-policy__body" component={"p"} variant="body1">
  <ul className="privacy-policy__list">
    <li>
     The NAI's opt-out platform: http://www.networkadvertising.org/choices/     </li>
    <li>
     The EDAA's opt-out platform http://www.youronlinechoices.com/ 
    </li>
    <li>
     The DAA's opt-out platform: http://optout.aboutads.info/?c=2&lang=EN 
    </li>
  </ul>
</Typography>
            <Typography
              className="privacy-policy__body"
              component={"p"}
              variant="body1"
            >
The opt out will place a cookie on Your computer that is unique to the browser You use to opt out. If you change browsers or delete the cookies saved by your browser, You will need to opt out again.             </Typography>
            <Typography
              className="privacy-policy__subheader"
              component={"p"}
              variant="body1"
            >
              "Do Not Track" Policy as Required by California Online Privacy Protection Act (CalOPPA)
            </Typography>

            <Typography
              className="privacy-policy__body"
              component={"p"}
              variant="body1"
            >
Our Service does not respond to Do Not Contact Us 
            </Typography>

            <Typography
              className="privacy-policy__body"
              component={"p"}
              variant="body1"
            >
Track signals. However, some third-party websites do keep track of Your browsing activities. If You are visiting such websites, You can set Your preferences in Your web browser to inform websites that You do not want to be tracked. You can enable or disable DNT by visiting the preferences or settings page of Your web browser. 
            </Typography>










            <Typography
              className="privacy-policy__subheader"
              component={"p"}
              variant="body1"
            >
              Children's Privacy
            </Typography>
            <Typography
              className="privacy-policy__body"
              component={"p"}
              variant="body1"
            >
Our Website is not intended for the Use by children. The Service will not collect and store persistent identifiers such as cookies or IP addresses from Children in the event any Children obtain access.             </Typography>
            <Typography
              className="privacy-policy__subheader"
              component={"p"}
              variant="body1"
            >
              Your California Privacy Rights (California's Shine the Light law)

            </Typography>

            <Typography
              className="privacy-policy__body"
              component={"p"}
              variant="body1"
            >
Under California Civil Code Section 1798 (California's Shine the Light law), California residents with an established business relationship with us can request information once a year about sharing their Personal Data with third parties for the third parties' direct marketing purposes. If you'd like to request more information under the California Shine the Light law, and if You are a California resident, You can contact Us using the contact information provided below. </Typography>
           


            <Typography
              className="privacy-policy__subheader"
              component={"p"}
              variant="body1"
            >
              California Privacy Rights for Minor Users (California Business and Professions Code Section 22581)
            </Typography>
            <Typography
              className="privacy-policy__body"
              component={"p"}
              variant="body1"
            >
              California Business and Professions Code Section 22581 allows California residents under the age of 18 who are registered users of online sites, services or applications to request and obtain removal of content or information they have publicly posted. The Service does not authorize individuals under the age of 18 to access the Services. 
              </Typography>

            <Typography
              className="privacy-policy__subheader"
              component={"p"}
              variant="body1"
            >
              Links to Other Websites
            </Typography>
            <Typography
              className="privacy-policy__body"
              component={"p"}
              variant="body1"
            >
              Our Service will NOT contain links to other websites that are not operated by Us. 

              </Typography>
            <Typography
              className="privacy-policy__subheader"
              component={"p"}
              variant="body1"
            >
              Changes to this Privacy Policy

            </Typography>
            <Typography
              className="privacy-policy__body"
              component={"p"}
              variant="body1"
            >
             We may update Our Privacy Policy from time to time. We will notify You of any changes by posting the new Privacy Policy on this page. We will let You know via email and/or a prominent notice on Our Service, prior to the change becoming effective and update the "Last updated" date at the top of this Privacy Policy. You are
advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page. 


              </Typography>
              <Typography
              className="privacy-policy__body"
              component={"p"}
              variant="body1"
            >
         If you have any questions about this Privacy Policy, You can contact us via email: legal@paywell.solutions


              </Typography>
          

          </Box>
        </Box>
      </Page>

      {/* <ResponsiveFooter /> */}
      <FooterLinkList />
    </>
  );
}
