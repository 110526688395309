import React, { useState, useEffect } from "react";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import { Typography, CardActionArea, Skeleton } from "@mui/material";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import { useHistory } from "react-router-dom";
import Chip from "@mui/material/Chip";
import CardHeader from "@mui/material/CardHeader";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import "./settingPresetCard.scss";
import { useSettingsContext } from "../../contexts/SettingsContext";
import Box from "@mui/material/Box";
import LoadingButton from "@mui/lab/LoadingButton";
import { useAuth } from "../../contexts/AuthContext";
import { useStepperContext } from "../LinearStepper/stepperContext";
import {
  addOrUpdateUrlVariable,
  getUrlVariableValue,
} from "../../utility-functions/utility-functions.js";
export default function SettingsPresetCardList(props) {
  const { dispatchStepper } = useStepperContext();
  const {
    settings,
    initializeNewSettings,
    setSettingsMenuButtonsDisabled,
    dispatch,
    reportCompilerDispatch,
  } = useSettingsContext();

  const { getUID } = useAuth();
  const history = useHistory();
  const [skeletonLoading, setSkeletonLoading] = useState(true);

  const handleOnSelect = async (settingsId) => {
    dispatch({
      type: "UPDATE_ACTIVE_STUDIO_SETTINGS",
      settingsId: settingsId,
      uid: getUID(),
      isActiveSettingPreset: true,
    });
    addOrUpdateUrlVariable("settingsId", settingsId);
  };
  const handleOnEdit = async (settingsId) => {
    history.push(`/studio-settings?settingsId=${settingsId}`);
    setSettingsMenuButtonsDisabled(false);
  };
  const handleSetupSettings = async (settingsId) => {
    try {
      await initializeNewSettings(settingsId);
    } catch (e) {
      console.error("Failed setting up new settings: ", e);
    }
  };
  const SetupSettingsCard = (props) => {
    let { defaultCard, settingsId } = props;
    const [loading, setLoading] = useState(false);
    const handleOnSetup = () => {
      setLoading(true);
      handleSetupSettings(settingsId);
    };
    return (
      <Card className="settings-card">
        <CardContent className="settings-card__content">
          <Typography variant="h6" className="settings-card__title">
            PayWell Settings
          </Typography>
          <Typography variant="body2" className="settings-card__label">
            Studios
          </Typography>
          <div className="settings-card__skeleton"></div>
          <Typography variant="body2" className="settings-card__label">
            Last Edited{" "}
          </Typography>
          <div className="settings-card__skeleton"></div>
        </CardContent>
        <CardActions className="settings-card__actions">
          <Button variant="outlined">Select</Button>

          <Button startIcon={<ManageAccountsIcon />}>Edit</Button>
        </CardActions>
        <div className="settings-card__setup-button">
          <LoadingButton
            loading={loading}
            variant={`${loading ? "text" : "outlined"}`}
            onClick={() => handleOnSetup(settingsId)}
            size="large"
            startIcon={<ManageAccountsIcon />}
          >
            Setup Settings
          </LoadingButton>
          {/* {defaultCard == false && !loading ? (
            <Button
              onClick={removeSetupCardFromList}
              size="large"
              color="error"
            >
              Cancel
            </Button>
          ) : null} */}
        </div>
      </Card>
    );
  };
  const EditSettingsCard = (props) => {
    let { settingsId, studios, lastEdit, title, isActiveSettingPreset } = props;
    lastEdit = lastEdit === undefined ? "N/A" : lastEdit;

    let settingsTitle =
      title == null || title == undefined
        ? "PayWell Settings #" + settingsId
        : title;
    const [loading, setLoading] = useState(false);

    let studiosStr = () => {
      let namesOnly = [];
      for (let i = 0; i < studios.length; i++) {
        namesOnly.push(studios[i].replace("Club Pilates ", ""));
      }

      let namesStr = namesOnly.join(", ");
      if (namesStr.length > 30) {
        return namesStr.substring(0, 27) + "...";
      }
      return namesStr;
    };

    return (
      <Card
        className={`settings-card ${
          isActiveSettingPreset ? "settings-card--selected" : ""
        }`}
      >
        <CardActionArea
          onClick={() => handleOnSelect(settingsId)}
          className="settings-card__action-area"
        >
          <CardHeader
            className="settings-card__header"
            action={
              <div
                // onClick={() => handleOnSelect(settingsId)}
                className="settings-card__icon-button"
                // size="large"
              >
                {isActiveSettingPreset ? (
                  <CheckCircleRoundedIcon className="settings-card__icon--selected" />
                ) : (
                  <div className="settings-card__icon"></div>
                )}
              </div>
            }
            title={
              <Typography variant="h6" className="settings-card__title">
                {settingsTitle}
              </Typography>
            }
          />
          <CardContent className="settings-card__content">
            <Typography
              component={"div"}
              variant="body2"
              className="settings-card__label"
            >
              Studios
              <Chip
                className="settings-card__label-chip"
                variant="contained"
                size="small"
                label={studios.length}
                color={"default"}
              />
            </Typography>
            <Typography variant="body2" className="settings-card__studios">
              {studiosStr()}
            </Typography>
            <Typography variant="body2" className="settings-card__label">
              Last Edited{" "}
            </Typography>
            <Typography variant="body2" className="settings-card__date">
              {lastEdit}
            </Typography>
          </CardContent>
        </CardActionArea>
        <CardActions className="settings-card__actions">
          <Button
            className="settings-card__edit-button"
            onClick={() => handleOnEdit(settingsId)}
            disabled={!isActiveSettingPreset}
            startIcon={<ManageAccountsIcon />}
          >
            Edit
          </Button>
        </CardActions>
        <div
          className={`${
            loading
              ? "settings-card__loading-overlay--active"
              : "settings-card__loading-overlay"
          }`}
        >
          <LoadingButton
            loading={loading}
            disabled={true}
            disableFocusRipple={true}
            disableRipple={true}
            size="large"
          ></LoadingButton>
        </div>
      </Card>
    );
  };

  useEffect(() => {
    if (settings.hasOwnProperty("1") && settings.hasOwnProperty("staff")) {
      setSkeletonLoading(false);

      if (
        settings[getUrlVariableValue("settingsId")]?.generalSettings?.studios
          ?.length > 0
      ) {
        dispatchStepper({ type: "ENABLE_NEXT_BUTTON" });
      }
    }
  }, [settings]);


  return skeletonLoading ? (
    <Box className="settings-selection">
      <Box className="settings-cards">
        <Skeleton className="settings-card__skeleton--card" />
      </Box>
    </Box>
  ) : (
    <Box className="settings-selection">
      <Box className="settings-cards">
        {settings ? (
          Object.entries(settings).map(([settingsId, settingPreset], index) =>
            settingsId !== "staff" ? (
              <Box key={settingPreset.settingsId}>
                <EditSettingsCard
                  key={"settings-" + settingPreset.settingsId}
                  settingsId={parseInt(settingPreset.settingsId)}
                  studios={settingPreset.generalSettings.studios}
                  lastEdit={settingPreset.lastEdit}
                  title={settingPreset.settingsTitle}
                  isActiveSettingPreset={settingPreset.isActiveSettingPreset}
                />
              </Box>
            ) : null
          )
        ) : (
          <SetupSettingsCard
            defaultCard={true}
            setupCard={true}
            key={"settings-" + 1}
            settingsId={1}
          />
        )}
      </Box>
    </Box>
  );
}
