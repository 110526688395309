import React, { createContext, useState, useContext } from "react";

const CustomDrawerContext = createContext();

export const useDrawer = () => useContext(CustomDrawerContext);

export const CustomDrawerProvider = ({ children }) => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [drawerContent, setDrawerContent] = useState(null);
  const [drawerTitle, setDrawerTitle] = useState("Default Title");

  const openDrawer = (content, title = "Default Title") => {

    setDrawerTitle(title);

    setDrawerContent(content);
    setDrawerOpen(true);
  };

  const closeDrawer = () => {
    setDrawerOpen(false);
    setDrawerContent(null);
    setDrawerTitle("Default Title");
  };

  return (
    <CustomDrawerContext.Provider
      value={{
        drawerOpen,
        drawerTitle,
        drawerContent,
        openDrawer,
        closeDrawer,
      }}
    >
      {children}
    </CustomDrawerContext.Provider>
  );
};
