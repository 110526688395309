import React, { useState } from 'react';
import { Box, Switch, Typography, Tab, Tabs } from '@mui/material';
import RateObjectComponent from './RateItem';
import { MenuItem, Select, InputLabel, FormControl } from '@mui/material';


import { TextField, Checkbox, FormControlLabel, Button } from '@mui/material';

import { useSettingsContext } from "../../../contexts/SettingsContext";

import AttributeListBox from "../../Components/AttributeListBox";
import SelectorBox from "../../Components/SelectorBox/SelectorBox";
import { Divider } from "@mui/material";
import { attendeeStatuses, classesExamples } from "../CommonVariables";
import InputBoxElement from "../../Components/InputBox/InputBox";
import BooleanToggleElement from "../../Components/BooleanToggle";
import { RevertList } from "../../Components/RevertList";
import "../studioSettings.scss";
import MultipleSelectCheckmarks from "../../../components/MultipleSelectCheckbox/MultipleSelectCheckbox";
import BasicModal from "../../../components/Modal/Modal";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import { useAuth } from "../../../contexts/AuthContext";
import { getUrlVariableValue } from "../../../utility-functions/utility-functions.js";
import { Skeleton } from "@mui/material";
import TieredItemManager from '../../Components/TieredItemManager.js';
import RateOverrideManager from './RateOverride.js';
import RateManager from './RateForm.js';
import RateConstructor from './RateConstructor.js';
import DiscountRateManager from './DiscountRateForm.js';
import { db } from '../../../firebase.js';
import { getFirestore, doc, setDoc, deleteDoc, getDoc } from "firebase/firestore";
import { API } from 'aws-amplify';
import { getFirebaseJwtToken } from '../../../utility-functions/tokenUtility.js';
import { getAllStudioFromAllPresets } from '../../../services/studio.services.js';

export default function SalesSettings() {

  const { getUID } = useAuth();
  const { settings, dispatch, changes, setChanges } = useSettingsContext();
  const [salesSettings, setSalesSettings] = useState(settings[getUrlVariableValue("settingsId")]?.salesSettings || false);
  const [activeTab, setActiveTab] = useState(0);

  const handleToggle = async (event) => {
    const userUID = getUID();
    setSalesSettings({
      ...salesSettings,
      on: event.target.checked,
    });

    let newRev = new RevertList(changes.getValues());
    newRev.addElement(
      "salesSettings-on",
      settings[getUrlVariableValue("settingsId")].salesSettings["on"],
      event.target.checked
    );
    setChanges(newRev);

    dispatch({
      type: "UPDATE_SALES_SETTINGS_ON",
      settingsId: getUrlVariableValue("settingsId"),
      uid: userUID,
      key: "on",
      value: event.target.checked,
    });

  };

  const handleOnHistoricalDataPullChange = async (event) => {
    const userUID = getUID();
    const salesDataDocRef = doc(db, "5-year-sales-data", userUID);

    dispatch({
      type: "UPDATE_SALES_SETTINGS_HISTORICAL_DATA_PULL",
      settingsId: getUrlVariableValue("settingsId"),
      uid: userUID,
      key: "historicalDataPull",
      value: event.target.checked,
    });

    let newRev = new RevertList(changes.getValues());
    newRev.addElement(
      "salesSettings-historicalDataPull",
      settings[getUrlVariableValue("settingsId")].salesSettings["historicalDataPull"],
      event.target.checked
    );
    setChanges(newRev);


    try {
      if (event.target.checked) {
        // Check if the UID is already present in the collection
        const docSnap = await getDoc(salesDataDocRef);
        if (!docSnap.exists()) {
          // Add the UID to the collection if not present
          await setDoc(salesDataDocRef, { uid: userUID });

          // Retrieve Firebase JWT Token
          const token = await getFirebaseJwtToken();


          let allStudios = await getAllStudioFromAllPresets(userUID);


          allStudios.forEach(async (studio) => {
            console.log('Step Function started for: ', studio);

            // Set up the API call with JWT token in the headers
            const apiName = 'paywellAPIResource';
            const path = '/startStepFunctionExecution'; // Adjusted API path for Lambda invocation
            const myInit = {
              headers: {
                'Authorization': `Bearer ${token}`
              },
              body: {
                uid: userUID,
                studio
              },
            };

            // Call the API to trigger the Step Function with necessary parameters
            const response = await API.post(apiName, path, myInit);

            console.log('Step Function triggered:', response);


          })


        }
      } else {
        // Remove the UID from the collection if the toggle is turned off
        await deleteDoc(salesDataDocRef);
      }
    } catch (error) {
      console.error("Error updating sales data collection or triggering step function:", error);
    }
  };



  const handleChangeBucket = (event, newValue) => {
    setActiveTab(newValue);
  };

  const sortBucketKeys = (buckets) => {
    const agreementTrue = [];
    const retailTrue = [];
    const others = [];

    Object.keys(buckets).forEach(key => {
      const bucket = buckets[key];
      if (bucket.type === "Agreement" && bucket.implicitType) {
        agreementTrue.push(key);
      } else if (bucket.type === "Retail" && bucket.implicitType) {
        retailTrue.push(key);
      } else {
        others.push(key);
      }
    });

    // Sorting non-priority buckets alphabetically
    others.sort();
    // Concatenating arrays to ensure the right order
    return [...agreementTrue, ...retailTrue, ...others];
  }











  return settings.hasOwnProperty("1") && settings.hasOwnProperty("staff") ? (
    <Box className="settings-page">
      <Typography variant="h2" className="settings-page__title">
        {/* {
        "Sales " + settings[getUrlVariableValue("settingsId")].generalSettings
          .commissionName
      }
       */}
        Sales Settings
      </Typography>
      <Divider className="settings-page__divider" />

      <Box className="settings-page__box settings-page__box--inline">
        <Typography className="settings-page__text">
          Enable Sales Settings
        </Typography>
        <Box className="settings-page__box settings-page__box--inline">
          <Switch
            id={"on"}
            checked={settings[getUrlVariableValue("settingsId")].salesSettings.on}
            onChange={handleToggle}
            name="enableSales"
            inputProps={{ 'aria-label': 'Enable sales settings' }}
          />

        </Box>
      </Box>
      <Divider className="settings-page__divider settings-page__divider--light" />

      <Box className="settings-page__box settings-page__box--inline">
        <Typography className="settings-page__text">
          Enable Sales Settings Historical Data Pull
        </Typography>
        <Divider className="settings-page__divider settings-page__divider--light" />

        <Box className="settings-page__box settings-page__box--inline">
          <Switch
            id={"historicalDataPull"}
            checked={settings[getUrlVariableValue("settingsId")].salesSettings.historicalDataPull}
            onChange={handleOnHistoricalDataPullChange}
            name="enableSales"
            inputProps={{ 'aria-label': 'Enable historical data pull' }}
          />

        </Box>
      </Box>
      <Divider className="settings-page__divider" />

      <Tabs value={activeTab} onChange={handleChangeBucket} aria-label="Sales bucket tabs">
        {sortBucketKeys(settings[getUrlVariableValue("settingsId")].salesSettings.salesBuckets).map((key, index) => (
          <Tab label={key} key={index} />
        ))}
      </Tabs>
      <Divider className="settings-page__divider settings-page__divider--light" />
      <SalesBucketSettings
        key={"tab-" + activeTab}
        bucketName={sortBucketKeys(settings[getUrlVariableValue("settingsId")].salesSettings.salesBuckets)[activeTab]}
        track={settings[getUrlVariableValue("settingsId")].salesSettings.salesBuckets[sortBucketKeys(settings[getUrlVariableValue("settingsId")].salesSettings.salesBuckets)[activeTab]].trackPrevious}
        salesSettings={settings[getUrlVariableValue("settingsId")].salesSettings}
        setSalesSettings={setSalesSettings}
      />

    </Box>
  ) : (
    <Box className="settings-page">
      <Skeleton>
        <Typography variant="h2" className="settings-page__title">
          Studios
        </Typography>
      </Skeleton>
      <Divider className="settings-page__divider" />
      <Skeleton className="settings-page__attribute-box settings-page__attribute-box--skeleton"></Skeleton>
    </Box>
  );
}


function SalesBucketSettings({ bucketName, track, salesSettings, setSalesSettings }) {

  const { getUID } = useAuth();
  const { settings, dispatch, changes, setChanges } = useSettingsContext();

  const staffOptions = [
    ...Object.entries(settings.staff.staff).map((s) => s[1].name),
  ];

  const [trackPrevious, setTrackPrevious] = useState(
    settings[getUrlVariableValue("settingsId")].salesSettings.salesBuckets[bucketName].trackPrevious
  );

  const [sessionConversion, setSessionConversion] = useState(
    settings[getUrlVariableValue("settingsId")].salesSettings.salesBuckets[bucketName].sessionConversion
  );

  const [unpaid, setUnpaid] = useState(
    settings[getUrlVariableValue("settingsId")].salesSettings.salesBuckets[bucketName].unpaidItemOptions
  );

  const [reactivation, setReactivation] = useState(
    settings[getUrlVariableValue("settingsId")].salesSettings.salesBuckets[bucketName].reactivationOptions
  );

  const [distinctRates, setDistinctRates] = useState(
    settings[getUrlVariableValue("settingsId")].salesSettings.salesBuckets[bucketName].distinctRates
  );

  // const handleImplicitTypeChange = (bucketName, value, salesSettings, setSalesSettings) => {
  //   const bucketType = salesSettings.salesBuckets[bucketName].type;
  //   const newSalesSettings = { ...salesSettings };

  //   // Set implicitType for the current bucket
  //   newSalesSettings.salesBuckets[bucketName].implicitType = value;

  //   // Unset implicitType for all other buckets of the same type
  //   Object.keys(newSalesSettings.salesBuckets).forEach(key => {
  //     if (newSalesSettings.salesBuckets[key].type === bucketType && key !== bucketName) {
  //       newSalesSettings.salesBuckets[key].implicitType = false;
  //     }
  //   });

  //   // Update state and context
  //   setSalesSettings(newSalesSettings);
  //   let newRev = new RevertList(changes.getValues());
  //   newRev.addElement(
  //     bucketName + "-implicitType",
  //     settings[getUrlVariableValue("settingsId")].salesSettings.salesBuckets[bucketName].implicitType,
  //     value
  //   );
  //   setChanges(newRev);
  //   dispatch({
  //     type: "UPDATE_SALES_SETTINGS_WITH_KEY",
  //     settingsId: getUrlVariableValue("settingsId"),
  //     uid: getUID(),
  //     salesType: bucketName,
  //     key: 'implicitType',
  //     value: value,
  //   });
  // };

  const handleBucketUpdate = (value, id) => {
    if (id === 'implicitType') {
      //handleImplicitTypeChange(bucketName, value, settings[getUrlVariableValue("settingsId")].salesSettings, setSalesSettings);
      //return;
    } else if (id === 'trackPrevious') {
      setTrackPrevious(value);
    } else if (id === 'sessionConversion') {
      setSessionConversion(value);
    } else if (id === 'unpaidItemOptions') {
      setUnpaid(value);
    } else if (id === 'reactivationOptions') {
      setReactivation(value);
    } else if (id === "rates-single") {
      id = "rates";
      value = { "New": value };
    } else if (id === "distinctRates") {
      let rates = settings[getUrlVariableValue("settingsId")].salesSettings.salesBuckets[bucketName].rates;
      const rateValues = value.reduce((acc, key) => {
        acc[key] = rates[key] || {
          rateType: "Fixed",
          rate: 0,
          setupFeeIncluded: false,
          split: true,
          salespeople: ["Close"]
        };
        return acc;
      }, {});

      setDistinctRates(value);
      dispatch({
        type: "UPDATE_SALES_SETTINGS_WITH_KEY",
        settingsId: getUrlVariableValue("settingsId"),
        uid: getUID(),
        salesType: bucketName,
        key: 'rates',
        value: rateValues,
      });
    } else if (id === "discountPercentages-thresholds") {
      id = "discountPercentages";

      let discounts = settings[getUrlVariableValue("settingsId")].salesSettings.salesBuckets[bucketName][id];

      // Create a new discounts object based on the incoming value array
      const newDiscounts = value.reduce((acc, key) => {
        //const decimalKey = (parseFloat(key) / 100).toFixed(2);
        acc[key] = discounts[key] || {
          rateType: "Fixed",
          rate: 0,
          setupFeeIncluded: false,
          split: true,
          salespeople: ["Close"]
        };
        return acc;
      }, {});

      value = newDiscounts;
    } else if (id === "unpaidItemsHierarchy") {
      id = "hierarchy";
      let hierarchy = settings[getUrlVariableValue("settingsId")].salesSettings.salesBuckets[bucketName].hierarchy;
      hierarchy["0"] = value;
      value = hierarchy;
    }
    let newRev = new RevertList(changes.getValues());
    newRev.addElement(
      bucketName + "-" + id,
      settings[getUrlVariableValue("settingsId")].salesSettings.salesBuckets[
      bucketName
      ][id],
      value
    );
    setChanges(newRev);
    dispatch({
      type: "UPDATE_SALES_SETTINGS_WITH_KEY",
      settingsId: getUrlVariableValue("settingsId"),
      uid: getUID(),
      salesType: bucketName,
      key: id,
      value: value,
    });
  };

  const getAllItemOptions = (bucketName) => {
    let buckets = settings[getUrlVariableValue("settingsId")].salesSettings.salesBuckets;
    let bucket = buckets[bucketName];
    let items = bucket.itemNames;
    let allItems = [...items];

    let bucketNames = Object.keys(settings[getUrlVariableValue("settingsId")].salesSettings.salesBuckets);
    for (let i = 0; i < bucketNames.length; i++) {
      if (bucketNames[i] !== bucketName) {
        allItems.push("*" + bucketNames[i] + "*");
      }
    }
    return allItems;
  }

  return (
    <Box>
      <Box className="settings-page__box settings-page__box--inline">
        <Typography className="settings-page__text">
          {"Type: "}
        </Typography>
        <SelectorBox
          label="Type"
          options={["Agreement", "Retail"]}
          default={settings[getUrlVariableValue("settingsId")].salesSettings.salesBuckets[bucketName].type}
          onChange={handleBucketUpdate}
          id="type"
          key={bucketName + "-type"}
        />
      </Box>

      <Divider className="settings-page__divider settings-page__divider--light" />
      <Box className="settings-page__box settings-page__box--inline">
        <Typography className="settings-page__text">
          {"Implicit Type: "}
        </Typography>
        <BooleanToggleElement
          id="implicitType"
          key={bucketName + "-implicit"}
          default={settings[getUrlVariableValue("settingsId")].salesSettings.salesBuckets[bucketName].implicitType}
          onChange={handleBucketUpdate}
          label="Implicit Type"
        />
      </Box>

      <Divider className="settings-page__divider settings-page__divider--light" />
      <Box className="settings-page__box settings-page__box--inline">
        <Typography className="settings-page__text">
          {"Track Previous Sold Items: "}
        </Typography>
        <BooleanToggleElement
          id="trackPrevious"
          key={bucketName + "-trackPrevious"}
          default={settings[getUrlVariableValue("settingsId")].salesSettings.salesBuckets[bucketName].trackPrevious}
          onChange={handleBucketUpdate}
          label="Track Previous Purchases"
        />
      </Box>

      <Divider className="settings-page__divider settings-page__divider--light" />
      <Box className="settings-page__box settings-page__box--inline">
        <Typography className="settings-page__text">
          {"Sold Items: "}
        </Typography>
        <AttributeListBox
          key={bucketName + "-names"}
          multiple={true}
          freeSolo
          onChange={handleBucketUpdate}
          id="itemNames"
          default={
            settings[getUrlVariableValue("settingsId")].salesSettings
              .salesBuckets[bucketName].itemNames
          }
          limitTags={8}
          label=""
          options={classesExamples}
        ></AttributeListBox>
      </Box>

      <Divider className="settings-page__divider settings-page__divider--light" />
      <Box className="settings-page__box settings-page__box--inline">
        <Typography className="settings-page__text">{"Staff Names Paid For This Bucket: "}</Typography>
        <AttributeListBox
          key={bucketName + "-staffNamesPaidForThisBucket"}
          multiple={true}
          freeSolo
          onChange={handleBucketUpdate}
          id="staffNamesPaidForThisBucket"
          default={settings[getUrlVariableValue("settingsId")].salesSettings.salesBuckets[bucketName].staffNamesPaidForThisBucket}
          label="Staff Names"
          limitTags={8}
          options={staffOptions} // Predefined staff names
        />
      </Box>

      <Divider className="settings-page__divider settings-page__divider--light" />
      <Box className="settings-page__box settings-page__box--inline">
        <Typography className="settings-page__text">{"Staff Types Paid For This Bucket: "}</Typography>
        <AttributeListBox
          key={bucketName + "-staffTypesPaidForThisBucket"}
          multiple={true}
          freeSolo
          onChange={handleBucketUpdate}
          id="staffTypesPaidForThisBucket"
          default={settings[getUrlVariableValue("settingsId")].salesSettings.salesBuckets[bucketName].staffTypesPaidForThisBucket}
          label="Staff Types"
          limitTags={8}
          options={Object.values(settings[getUrlVariableValue("settingsId")].generalSettings.staffTypes).map((type) => type.name)} // Predefined staff types
        />
      </Box>

      <Divider className="settings-page__divider settings-page__divider--light" />
      {trackPrevious ? (
        <Box className="settings-page__box settings-page__box--inline">
          <Typography className="settings-page__text">{"Rates: "}</Typography>
          <RateManager
            key={bucketName + "-rates-multi"}
            id="rates"
            bucketName={bucketName}
            rates={settings[getUrlVariableValue("settingsId")].salesSettings.salesBuckets[bucketName].rates}
            setRates={handleBucketUpdate}
            distinctRates={settings[getUrlVariableValue("settingsId")].salesSettings.salesBuckets[bucketName].distinctRates}
            rateCustomInput={false}
            rateOverrides={settings[getUrlVariableValue("settingsId")].salesSettings.salesBuckets[bucketName].rateOverrides || {}}
          />
        </Box>
      ) : (
        <Box className={"settings-page__box settings-page__box--inline"}>
          <Typography className="settings-page__text">{"Rate: "}</Typography>
          <AttributeListBox
            key={bucketName + "-unpaidItemsHierarchy"}
            multiple={true}
            freeSolo
            onChange={handleBucketUpdate}
            id="unpaidItemsHierarchy"
            default={settings[getUrlVariableValue("settingsId")].salesSettings.salesBuckets[bucketName].hierarchy[0]}
            label="Unpaid Items"
            limitTags={8}
            options={getAllItemOptions(bucketName)} // Predefined staff types
          />
          <RateObjectComponent
            on={true}
            key={bucketName + "-rates-single"}
            id="rates-single"
            bucketName={bucketName}
            rateName="New"
            rate={settings[getUrlVariableValue("settingsId")].salesSettings.salesBuckets[bucketName].rates["New"]}
            onRateChange={handleBucketUpdate}
          />
        </Box>
      )}

      {trackPrevious && (
        <>
          <Divider className={"settings-page__divider settings-page__divider--light"} />
          <Box className={"settings-page__box settings-page__box--inline"}>
            <TieredItemManager
              key={bucketName + "-hierarchy"}
              id="hierarchy"
              buckets={Object.keys(settings[getUrlVariableValue("settingsId")].salesSettings.salesBuckets)}
              initialTiers={settings[getUrlVariableValue("settingsId")].salesSettings.salesBuckets[bucketName].hierarchy}
              options={getAllItemOptions(bucketName)}
              onChange={handleBucketUpdate}
            />
          </Box>
        </>
      )
      }


      <Divider className="settings-page__divider settings-page__divider--light" />
      <Box className="settings-page__box settings-page__box--inline">
        <Typography className="settings-page__text">{"Discounted Items: "}</Typography>
        <DiscountRateManager
          key={bucketName + "-discountPercentages"}
          id="discountPercentages"
          rateName="Discounted"
          bucketName={"Discounted " + bucketName}
          rates={settings[getUrlVariableValue("settingsId")].salesSettings.salesBuckets[bucketName].discountPercentages}
          setRates={handleBucketUpdate}
        />
      </Box>

      <Divider className="settings-page__divider settings-page__divider--light" />
      <Box className="settings-page__box settings-page__box--inline">
        <Typography className="settings-page__text">
          {"Pay Commission on Staff Bought Items: "}
        </Typography>
        <BooleanToggleElement
          id="commissionStaffBought"
          key={bucketName + "-commissionStaffBought"}
          default={settings[getUrlVariableValue("settingsId")].salesSettings.salesBuckets[bucketName].commissionStaffBought || false}
          onChange={handleBucketUpdate}
          label="Staff Purchases"
        />
      </Box>

      {/* <Divider className="settings-page__divider settings-page__divider--light" />
      <Box className="settings-page__box settings-page__box--inline">
        <Typography className="settings-page__text">{"Rate Name Overrides: "}</Typography>
        <RateOverrideManager 
          key={bucketName + "-rateOverrides"}
          id="rateOverrides"
          rateOverrides={settings[getUrlVariableValue("settingsId")].salesSettings.salesBuckets[bucketName].rateOverrides || {}} 
          setRateOverrides={handleBucketUpdate}
          distinctRates={settings[getUrlVariableValue("settingsId")].salesSettings.salesBuckets[bucketName].distinctRates}
        />
      </Box> */}

      <Divider className="settings-page__divider settings-page__divider--light" />
      <Box className="settings-page__box settings-page__box--inline">
        <Typography className="settings-page__text">{"Unpaid Items: "}</Typography>
        <SelectorBox
          key={bucketName + "-unpaidItemOptions"}
          id="unpaidItemOptions"
          label="Unpaid Items"
          default={unpaid}
          onChange={handleBucketUpdate}
          options={["Pay as Normal", "Do Not Pay", "Pay a Different Rate"]}
          fullWidth
        />
      </Box>

      {unpaid === "Pay a Different Rate" && (
        <Box className={"settings-page__box settings-page__box--inline"}>
          <Typography className="settings-page__text">{"Unpaid Item Rate: "}</Typography>
          <RateObjectComponent
            on={unpaid === "Pay a Different Rate"}
            key={bucketName + "-unpaidItemRate"}
            id="unpaidItemRate"
            bucketName={bucketName}
            rateName="Unpaid"
            rate={settings[getUrlVariableValue("settingsId")].salesSettings.salesBuckets[bucketName].unpaidItemDifferentRate}
            onRateChange={(rate) => handleBucketUpdate(rate, 'unpaidItemDifferentRate')}
          />
        </Box>
      )
      }

      {trackPrevious && (
        <>
          <Divider className={"settings-page__divider settings-page__divider--light"} />
          <Box className="settings-page__box settings-page__box--inline">
            <Typography className="settings-page__text">{"Multiple Previous Sales: "}</Typography>
            <SelectorBox
              key={bucketName + "-multiplePreviousAgreementsPayment"}
              options={["Do Not Pay", "Disregard all previous agreements", "Disregard all but last previous agreement", "Disregard cross-bucket sales", "Take into account all previous agreements & use highest tier agreement for base", "Take into account all previous agreements & let PayWell AI decided if its commissionable"]}
              default={settings[getUrlVariableValue("settingsId")].salesSettings.salesBuckets[bucketName].multiplePreviousAgreementsPayment}
              onChange={handleBucketUpdate}
              label="Multiple Previous Sales"
              id="multiplePreviousAgreementsPayment"
            />
          </Box>
        </>
      )}

      {trackPrevious && (
        <>
          <Divider className={"settings-page__divider settings-page__divider--light"} />
          <Box className="settings-page__box settings-page__box--inline">
            <Typography className="settings-page__text">{"Reactivation Options: "}</Typography>
            <SelectorBox
              key={bucketName + "-reactivationOptions"}
              options={["Disregard length between purchases", "Regard as new if length is less than reactivationTimeFrame", "Regard as unique 'Returning' type if length is less than reactivationTimeFrame"]}
              default={settings[getUrlVariableValue("settingsId")].salesSettings.salesBuckets[bucketName].reactivationOptions}
              onChange={handleBucketUpdate}
              label="Reactivation Options"
              id="reactivationOptions"
            />
          </Box>
        </>
      )}

      {reactivation !== "Disregard length between purchases" && (
        <Box className="settings-page__box settings-page__box--inline">
          <Typography className="settings-page__text">{"Reactivation Time Frame (days): "}</Typography>
          <InputBoxElement
            id="reactivationTimeFrame"
            key={bucketName + "-reactivationTimeFrame"}
            default={settings[getUrlVariableValue("settingsId")].salesSettings.salesBuckets[bucketName].reactivationTimeFrame.toString()}
            onChange={handleBucketUpdate}
            label="Reactivation Time"
            types={["number"]}
          />
        </Box>
      )
      }

      {/* <Divider className="settings-page__divider settings-page__divider--light" />
      <Box className="settings-page__box settings-page__box--inline">
      <Typography className="settings-page__text">{"Post Dated Sale Options: "}</Typography>
      <SelectorBox
        key={bucketName + "-postDatedSaleOptions"}
        id="postDatedSaleOptions"
        label="Post Dated Sale Options"
        value={settings[getUrlVariableValue("settingsId")].salesSettings.salesBuckets[bucketName].postDatedSaleOptions}
        onChange={(e) => handleBucketUpdate(e.target.value, 'postDatedSaleOptions')}
        options={["Pay as Normal", "Do Not Pay", "Pay on Post Dated Sale Day"]}
        fullWidth
      />
      </Box> */}

      <Divider className={"settings-page__divider settings-page__divider--light"} />
      <Box className={"settings-page__box settings-page__box--inline "}>
        <Typography className="settings-page__text">
          {"Session Conversion: "}
        </Typography>
        <BooleanToggleElement
          id="sessionConversion"
          key={bucketName + "-sessionConversion"}
          default={settings[getUrlVariableValue("settingsId")].salesSettings.salesBuckets[bucketName].sessionConversion}
          onChange={handleBucketUpdate}
          label="Session Conversion"
        />
      </Box>


      {sessionConversion && (
        <>
          <Box className={"settings-page__box settings-page__box--inline"}>
            <Typography className="settings-page__text">
              {"Session Conversion Time Frame: "}
            </Typography>
            <InputBoxElement
              id="sessionConversionTime"
              key={bucketName + "-sessionConversionTime"}
              default={settings[getUrlVariableValue("settingsId")].salesSettings.salesBuckets[bucketName].sessionConversionTime.toString()}
              onChange={handleBucketUpdate}
              label="Conversion Time (Hours)"
              types={["number"]}
            />
          </Box>


          <Box className={"settings-page__box settings-page__box--inline"}>
            <Typography className="settings-page__text">
              {"Session Conversion Buckets: "}
            </Typography>
            <AttributeListBox
              key={bucketName + "-sessionConversionClassBuckets"}
              multiple={true}
              freeSolo
              onChange={handleBucketUpdate}
              id="sessionConversionClassBuckets"
              default={settings[getUrlVariableValue("settingsId")].salesSettings.salesBuckets[bucketName].sessionConversionClassBuckets}
              label="Class Buckets for Conversion"
              options={Object.keys(settings[getUrlVariableValue("settingsId")].classSettings.classBuckets)}  // Make sure to provide the correct options
            />
          </Box>


          <Box className={"settings-page__box settings-page__box--inline"}>
            <Typography className="settings-page__text">
              {"Session Conversion Instructor Bonus: "}
            </Typography>
            <InputBoxElement
              key={bucketName + "-sessionConversionInstructorBonus"}
              id="sessionConversionInstructorBonus"
              default={settings[getUrlVariableValue("settingsId")].salesSettings.salesBuckets[bucketName].sessionConversionInstructorBonus.toString()}
              onChange={handleBucketUpdate}
              label="Instructor Bonus"
              types={["number"]}
            />
          </Box>
        </>
      )}


    </Box>
  );
}
