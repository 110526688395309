

import React, { useEffect, useState, useRef, useCallback } from "react";
import { getFirestore, collection, query, where, getDocs, doc, updateDoc, arrayUnion } from "firebase/firestore";
import { Typography, Button, Popper, Paper, Dialog, DialogContent, DialogContentText, DialogActions, Box } from "@mui/material";
import { useAuth } from "../../contexts/AuthContext";
import { useLocation } from "react-router-dom";
import { useTheme } from '@mui/material/styles';  // Import useTheme hook
import DOMPurify from 'dompurify';



import './UserNotification.scss'; // Import the styles

export default function UserNotification() {
  const [notifications, setNotifications] = useState([]);
  const [currentNotificationIndex, setCurrentNotificationIndex] = useState(0);
  const [visible, setVisible] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const { getUID } = useAuth();
  const userUid = getUID();
  const arrowRef = useRef(null);
  const popperRef = useRef(null);
  const location = useLocation();
  const theme = useTheme();  // Use the useTheme hook to access theme

  useEffect(() => {
    const fetchNotifications = async () => {

      console.log("Fetching notifications");
      const db = getFirestore();
      const notificationsCollectionRef = collection(db, "notifications");
      const q = query(
        notificationsCollectionRef,
        where("isActive", "==", true)
      );

      try {
        const querySnapshot = await getDocs(q);
        const notificationsList = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        localStorage.clear();
        const dismissedNotifications = JSON.parse(localStorage.getItem('dismissedNotifications')) || [];

        // Filter out notifications that the user has dismissed and sort by order
        const activeNotifications = notificationsList
          .filter(notification => !notification.dismissedUsers.includes(userUid) && !dismissedNotifications.includes(notification.id))
          .sort((a, b) => (a.order || 0) - (b.order || 0));

        console.log("Active notifications:", activeNotifications);

        if (activeNotifications.length > 0) {
          setNotifications(activeNotifications);
          setCurrentNotificationIndex(0);
          setVisible(true);
        }
      } catch (error) {
        console.error("Error fetching notifications:", error);
      }
    };

    fetchNotifications();
  }, [userUid]);

  const handleClose = async () => {
    const currentNotification = notifications[currentNotificationIndex];
    if (currentNotification) {
      const db = getFirestore();
      const notificationRef = doc(db, "notifications", currentNotification.id);
      await updateDoc(notificationRef, {
        dismissedUsers: arrayUnion(userUid),
      });

      const dismissedNotifications = JSON.parse(localStorage.getItem('dismissedNotifications')) || [];
      dismissedNotifications.push(currentNotification.id);
      localStorage.setItem('dismissedNotifications', JSON.stringify(dismissedNotifications));
    }

    const nextIndex = currentNotificationIndex + 1;
    if (nextIndex < notifications.length) {
      setCurrentNotificationIndex(nextIndex);
      setVisible(true);
    } else {
      setVisible(false);
    }
  };

  const handleClickOutside = useCallback((event) => {
    if (
      anchorEl &&
      !anchorEl.contains(event.target) &&
      popperRef.current &&
      !popperRef.current.contains(event.target)
    ) {
      setVisible(false);
    }
  }, [anchorEl]);

  useEffect(() => {
    if (visible) {
      document.addEventListener("click", handleClickOutside);
    } else {
      document.removeEventListener("click", handleClickOutside);
    }

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [visible, handleClickOutside]);

  useEffect(() => {
    let timer;
    const checkTargetElement = () => {
      const currentNotification = notifications[currentNotificationIndex];
      const dismissedNotifications = JSON.parse(localStorage.getItem('dismissedNotifications')) || [];

      if (currentNotification && currentNotification.targetSelector && !dismissedNotifications.includes(currentNotification.id)) {
        const targetElement = document.querySelector(currentNotification.targetSelector);
        console.log("Checking for target element:", currentNotification.targetSelector, targetElement);
        if (targetElement) {
          setAnchorEl(targetElement);
          targetElement.scrollIntoView({ behavior: "smooth" });
          setVisible(true);
        } else {
          console.log("Target element not found, will check again in 1 second");
          timer = setTimeout(checkTargetElement, 1000); // Check again after 1 second
        }
      }
    };

    if (notifications.length > 0) {
      checkTargetElement();
    }

    return () => clearTimeout(timer);
  }, [notifications, currentNotificationIndex, location]);

  const currentNotification = notifications[currentNotificationIndex];

  return (
    <>
      {currentNotification && currentNotification.type === "tooltip" && anchorEl && (
        <Popper
          className="notification-tooltip"
          open={visible}
          anchorEl={anchorEl}
          placement={currentNotification.position}
          ref={popperRef}
          keepMounted={true}
          modifiers={[
            {
              name: 'flip',
              enabled: true,
              options: {
                altBoundary: true,
                rootBoundary: 'document',
                padding: 8,
              },
            },
            {
              name: 'preventOverflow',
              enabled: true,
              options: {
                altAxis: true,
                altBoundary: true,
                tether: true,
                rootBoundary: 'document',
                padding: 8,
              },
            },
            {
              name: 'arrow',
              enabled: true,
              options: {
                element: arrowRef.current,
              },
            },
            {
              name: 'offset',
              options: {
                offset: [0, 2],
              },
            },
          ]}
          style={{ zIndex: 1300 }}
        >
          <Box sx={{ position: 'relative' }}>
            <div className="MuiPopper-arrow" ref={arrowRef} />
            <Paper elevation={3} className="notification-tooltip__paper">
              <DialogContent>
                <Typography className="notification-tooltip__title" gutterBottom>{currentNotification.title}</Typography>
                <DialogContentText className="notification-tooltip__message">
                  {currentNotification.message}
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  className="notification-tooltip__button"
                  sx={{
                    backgroundColor: theme.palette.secondary.light,
                    '&:hover': {
                      backgroundColor: theme.palette.secondary["100"],
                    },
                  }}
                  variant="contained"
                  disableElevation
                  size="small"
                  onClick={handleClose}
                >
                  Got it
                </Button>
              </DialogActions>
            </Paper>
          </Box>
        </Popper>
      )}

      {currentNotification && currentNotification.type === "popup" && (
        <Dialog className="notification-popup" open={visible} onClose={handleClose} fullWidth maxWidth="lg">
          <DialogContent className="notification-popup__content">
            {currentNotification.imageUrl && (
              <img src={currentNotification.imageUrl} alt="Notification" className="notification-popup__image" />
            )}
            <div className="notification-popup__text">
              <Typography variant="h3" className="notification-popup__title" gutterBottom>{currentNotification.title}</Typography>
              <DialogContentText className="notification-popup__message">
                <div
                  dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(currentNotification.message) }}
                />
              </DialogContentText>
            </div>
          </DialogContent>
          <DialogActions>
            <Button className="notification-popup__button" color="primary" onClick={handleClose}>
              Dismiss
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
}
