import { AgreementEvent } from "../ReportCompiler/classes/AgreementEvent.js";
import { createDateFromString } from "../GeneralFunctions/CreateDateFromString.js";
import { ReportData } from "../utility-functions.js";
export function readAgreementsLogDetail(json, diffCommissionPayCol, salesBuckets) {
  const agreeArray = [];
  let staffInFile = [];

  const cancelledAgreementsArray = [];

  let agreeLocationI = 0;
  let agreeDateI = 0;
  let agreeDescriptionI = 0;
  let agreeSalesPersonI = 0;
  let agreeSalesPerson2I = 0;
  let agreeFirstNameI = 0;
  let agreeLastNameI = 0;
  let agreeMemTypeI = 0;
  let agreePriceI = 0;
  let agreePreviousI = 0;
  let agreePreviousDateI = 0;
  let agreePreviousDetailI = 0;
  let agreePreviousPaymentI = 0;
  let agreementsNotesI = 0;
  let agreeStatusI = 0;
  let agreeSetupFeeI = 0;
  let agreeIdI = 0;
  let correctFileType = false;

  let paymentColumn = diffCommissionPayCol.column;

  for (let i = 0; i < json.length; i++) {
    if (isAgreementHeaderRow(json[i])) {
      for (let j = 0; j < json[i].length; j++) {
        if (json[i][j].includes("Location Name")) {
          agreeLocationI = j;
        } else if (
          json[i][j].includes("Agreed Date") &&
          !json[i][j].includes("Previous")
        ) {
          agreeDateI = j;
        } else if (json[i][j].includes("Description")) {
          agreeDescriptionI = j;
        } else if (
          json[i][j].includes("Sales Person") &&
          !json[i][j].includes("Second")
        ) {
          agreeSalesPersonI = j;
        } else if (json[i][j].includes("Second Sales Person")) {
          agreeSalesPerson2I = j;
        } else if (json[i][j].includes("Client First")) {
          agreeFirstNameI = j;
        } else if (json[i][j].includes("Client Last")) {
          agreeLastNameI = j;
        } else if (json[i][j].includes("Membership Type")) {
          agreeMemTypeI = j;
        } else if (
          (json[i][j].includes(" Paid Upfront") && paymentColumn === -1) ||
          (paymentColumn > -1 && j === paymentColumn)
        ) {
          agreePriceI = j;
        } else if (json[i][j].includes("Previous Agreements")) {
          agreePreviousI = j;
        } else if (
          json[i][j].includes("Previous") &&
          json[i][j].includes("Agreed Date") &&
          !json[i][j].includes("Membership")
        ) {
          agreePreviousDateI = j;
        } else if (json[i][j].includes("Previous Agreement Detail")) {
          agreePreviousDetailI = j;
        } else if (json[i][j].includes("Agreement Notes")) {
          agreementsNotesI = j;
        } else if (json[i][j].includes(" Setup Fee")) {
          agreeSetupFeeI = j;
        } else if (json[i][j].includes("Current Status")) {
          agreeStatusI = j;
        } else if (json[i][j].includes("Agreement #")) {
          agreeIdI = j;
        }
      }
      correctFileType = true;
    } else if (
      isAgreementDataRow(json[i]) 
      //&&
      //json[i][agreeStatusI] !== "Cancelled"
    ) {
      const agreeDate = createDateFromString(json[i][agreeDateI], "00:00");

      const location = json[i][agreeLocationI].substring(
        0,
        json[i][agreeLocationI].lastIndexOf("(") - 1
      );

      let secondarySalesperson = findSecondarySalePerson(
        json[i][agreeSalesPerson2I]
      );
      let payment = findPayment(
        json[i][agreePriceI],
        json[i][agreeSetupFeeI],
        diffCommissionPayCol,
      );

      const previousAgreementNumber = parseFloat(
        json[i][agreePreviousI].replace(/\$/g, "")
      );

      const newStaff = {
        name: json[i][agreeSalesPersonI],
        type: "sales",
        location: location,
      };
      
      let isDuplicate = staffInFile.some(staff => 
        staff.name === newStaff.name && staff.type === newStaff.type && staff.location === newStaff.location
      );
      
      if (!isDuplicate) {
        staffInFile.push(newStaff);
      }

      const newStaff2 = {
        name: json[i][agreeSalesPersonI],
        type: "sales",
        location: location,
      };
      
      let isDuplicate2 = staffInFile.some(staff => 
        staff.name === newStaff2.name && staff.type === newStaff2.type && staff.location === newStaff2.location
      );
      
      if (!isDuplicate2) {
        staffInFile.push(newStaff2);
      }

      // staffInFile.push({
      //   name: json[i][agreeSalesPersonI],
      //   type: "sales",
      //   location: location,
      // });

      // staffInFile.push({
      //   name: secondarySalesperson,
      //   type: "sales",
      //   location: location,
      // });

      let description = json[i][agreeDescriptionI];
      if(secondarySalesperson !== "-"){
        description = description + " - SPLIT";
      }
      if(json[i][agreeStatusI].includes("Past Due")){
        description = description + " - Past Due";
      }

      const agreementEvent = new AgreementEvent(
        location,
        agreeDate,
        description,
        json[i][agreeSalesPersonI],
        secondarySalesperson,
        json[i][agreeFirstNameI],
        json[i][agreeLastNameI],
        json[i][agreeMemTypeI],
        payment,
        previousAgreementNumber,
        json[i][agreePreviousDetailI],
        json[i][agreementsNotesI],
        json[i][agreePreviousDateI]
      );

      if(previousAgreementNumber > 1 ){
        findCancellationPayment(
          cancelledAgreementsArray,
          agreementEvent
        );
      }

      agreementEvent.id = json[i][agreeIdI];

      if(json[i][agreeStatusI] !== "Cancelled"){
        agreeArray.push(agreementEvent);
      } else {
        if(salesBuckets && false){ //come back to this
          agreementEvent.description = "[CANCELLED] " + agreementEvent.description;
          agreeArray.push(agreementEvent);
        }else{
          cancelledAgreementsArray.push(agreementEvent);
        }
      }
      //agreeArray.push(agreementEvent);
    }
  }

  let reportData = new ReportData(correctFileType, agreeArray);
  reportData.staffInFile = staffInFile;

  return reportData;
}

function findCancellationPayment(cancelledAgreementsArray, agreementEvent) {
  for(let i = 0; i < cancelledAgreementsArray.length; i++){
    if(agreementEvent.getFullName() === cancelledAgreementsArray[i].getFullName() && agreementEvent.date.getTime() > cancelledAgreementsArray[i].date.getTime()){
      agreementEvent.price = cancelledAgreementsArray[i].price;
      agreementEvent.previousAgreementNumber = 0;
      agreementEvent.previousAgreements = "";
      agreementEvent.comments.push("Same Period Cancelation - " + cancelledAgreementsArray[i].description);
      return;
    }
  }
  return;
}

function isAgreementHeaderRow(row) {
  return row[0] === "Location Name" && row[1] === "Agreed Date";
}

function isAgreementDataRow(row) {
  return (
    row[0].length > 7 && row[0] !== "" && !row[0].includes("Agreements Log")
  );
}

function findSecondarySalePerson(secondaryString) {
  let secondarySalesperson = "-";
  if (secondaryString.length > 1) {
    secondarySalesperson = secondaryString;
  }
  return secondarySalesperson;
}

//   let paymentColumn = diffCommissionPayCol.column;
//   let payment = 0;
//   if (paymentColumn === -1) {
//     if(diffCommissionPayCol.discardSetupFee){
//       payment = parseFloat(priceString.replaceAll("$", "")) - parseFloat(setupString.replaceAll("$", ""));
//     }else {
//       payment = parseFloat(priceString.replaceAll("$", ""));
//     }
//   } else {
//     if(diffCommissionPayCol.discardSetupFee){
//       payment = parseFloat(priceString.replaceAll("$", "")) - parseFloat(setupString.replaceAll("$", ""));
//     }else {
//       payment = parseFloat(priceString.replaceAll("$", ""));
//     }
//     //payment = parseFloat(priceString.replace("$", ""));
//   }
//   return payment;
// }

// GPT
function findPayment(priceString, setupString, diffCommissionPayCol) {
  let paymentColumn = diffCommissionPayCol.column;
  let payment = 0;
  if (paymentColumn === -1) {
    if (
      diffCommissionPayCol.discardSetupFee &&
      typeof priceString === "string" &&
      typeof setupString === "string"
    ) {
      payment =
        parseFloat(priceString.replace(/\$/g, "")) -
        parseFloat(setupString.replace(/\$/g, ""));
    } else if (typeof priceString === "string") {
      payment = parseFloat(priceString.replace(/\$/g, ""));
    }
  } else {
    if (
      diffCommissionPayCol.discardSetupFee &&
      typeof priceString === "string" &&
      typeof setupString === "string"
    ) {
      payment =
        parseFloat(priceString.replace(/\$/g, "")) -
        parseFloat(setupString.replace(/\$/g, ""));
    } else if (typeof priceString === "string") {
      payment = parseFloat(priceString.replace(/\$/g, ""));
    }
  }
  return payment;
}

export function addPastAgreements(agreeArray, pastAgreements) {
  for (let i = 0; i < agreeArray.length; i++) {
    if(agreeArray[i].previousAgreementNumber > 0){
      let pastAgreementsArray = [];
      for (let j = 0; j < pastAgreements.length; j++) {
        if (
          agreeArray[i].getFullName() === pastAgreements[j].getFullName() &&
          agreeArray[i].date.getTime() > pastAgreements[j].date.getTime()
        ) {
          pastAgreementsArray.push({
            agreementName: pastAgreements[j].description,
            agreementDate: pastAgreements[j].date,
            agreementPay: pastAgreements[j].price,
            agreementCancelDate: "",
            agreementType: "",
          });
        }
      }
    }
  }
  return agreeArray;
}
