import * as React from "react";
import Skeleton from "@mui/material/Skeleton";
import { Box, Button } from "@mui/material";

export default function SkeletonDashboard() {
  return (
    <>
      {/* Navbar */}
      <Box
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          maxWidth: "1280px",
          margin: " 0px auto",
          padding: "0 77px 0 0px",
          height: "64px",
          marginBottom: "40px",
        }}
      >
        <Skeleton
          variant="rectangular"
          width={136}
          height={30}
          animation="wave"
          style={{ borderRadius: "2px", marginLeft: "80px" }}
        />
        <Skeleton variant="circular" width={40} height={40} animation="wave" />
      </Box>

      {/* Stepper */}
      <Box style={{ display: "grid", justifyItems: "center" }}>
        <Box style={{ display: "grid", justifyItems: "flex-start" }}>
          <Box
            style={{
              padding: "8px 0px",
              maxWidth: "315px",
              display: "grid",
              gridTemplateColumns: "1fr 6fr",
              alignItems: "center",
              gap: "8px",
            }}
          >
            <Skeleton
              variant="circular"
              width={24}
              height={24}
              animation="wave"
            />
            <Skeleton variant="text" animation="wave" height={15} />
          </Box>
          <Box style={{ margin: "8px 0px 24px 32px" }}>
            <Box
              style={{
                display: "grid",
                gridTemplateColumns: "1fr 6fr",
                width: " min-content",
              }}
            >
              <Skeleton
                variant="rectangular"
                width={3}
                height={328}
                animation="wave"
                style={{
                  borderRadius: "20px",
                  position: "absolute",
                  marginLeft: " -22px",
                  height: "414px",
                }}
              />
              <Skeleton
                variant="rectangular"
                width={266}
                height={328}
                animation="wave"
                style={{ borderRadius: "20px" }}
              />
            </Box>

            <Skeleton>
              <Button
                style={{
                  margin: "8px 8px 0px 0px",
                  padding: "6px 16px",
                  minWidth: "64px",
                  height: "60px",
                }}
              >
                Continue
              </Button>
            </Skeleton>
          </Box>
          <Box
            style={{
              padding: "8px 0px",
              maxWidth: "315px",
              display: "grid",
              gridTemplateColumns: "1fr 6fr",
              alignItems: "center",
              gap: "8px",
            }}
          >
            <Skeleton
              variant="circular"
              width={24}
              height={24}
              animation="wave"
            />
            <Skeleton variant="text" animation="wave" height={15} />
          </Box>
          <Skeleton
            variant="rectangular"
            width={3}
            height={328}
            animation="wave"
            style={{ borderRadius: "20px", height: "24px", marginLeft: "10px" }}
          />
          <Box
            style={{
              padding: "8px 0px",
              maxWidth: "315px",
              display: "grid",
              gridTemplateColumns: "1fr 6fr",
              alignItems: "center",
              gap: "8px",
            }}
          >
            <Skeleton
              variant="circular"
              width={24}
              height={24}
              animation="wave"
            />
            <Skeleton variant="text" animation="wave" height={15} />
          </Box>
          <Skeleton
            variant="rectangular"
            width={3}
            height={328}
            animation="wave"
            style={{ borderRadius: "20px", height: "24px", marginLeft: "10px" }}
          />
          <Box
            style={{
              padding: "8px 0px",
              maxWidth: "315px",
              display: "grid",
              gridTemplateColumns: "1fr 6fr",
              alignItems: "center",
              gap: "8px",
            }}
          >
            <Skeleton
              variant="circular"
              width={24}
              height={24}
              animation="wave"
            />
            <Skeleton variant="text" animation="wave" height={15} />
          </Box>
        </Box>
      </Box>
    </>
  );
}
