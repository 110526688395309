import React, { useState, useEffect } from "react";
import {
  Container,
  TextField,
  Grid,
  Typography,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  OutlinedInput,
  FormGroup,
  FormControlLabel,
  Switch,
  Card,
  CardContent,
  Autocomplete,
  Button,
  DialogActions,
} from "@mui/material";
import { useSettingsContext } from "../../contexts/SettingsContext";
import { RevertList } from "../../pages/Components/RevertList";
import { getUrlVariableValue } from "../../utility-functions/utility-functions";
import { useAuth } from "../../contexts/AuthContext";
import { updateStudioSettings } from "../../services/studio.services";
import "./staffDialog.scss";
import { useSnackBar } from "../../contexts/SnackBarContext/SnackBarContext";
import { LoadingButton } from "@mui/lab";
const AddClassComponent = ({ onAdd, onClose, className }) => {
  
  const showSnackBar = useSnackBar();
  const { getUID } = useAuth();
  const [loading, setLoading] = useState(false);
  const {
    settings,
    dispatch,
    changes,
    setChanges,
    setSaveDisabled,
    reportCompilerDispatch,
  } = useSettingsContext();

  const [selectedType, setSelectedType] = useState(Object.keys(settings[getUrlVariableValue("settingsId")].classSettings.classBuckets)[0]);

  useEffect(() => {
    if (
      settings[getUrlVariableValue("settingsId")].classSettings.classBuckets[
        selectedType
      ].names.includes(className)
    ) {
      handleSave(getUID());
      onAdd();
    }
  }, [
    settings[getUrlVariableValue("settingsId")].classSettings.classBuckets,
  ]);

  const handleBucketUpdate = async (value, id) => {
    const uid = getUID();
    let newRev = new RevertList(changes.getValues());
    newRev.addElement(
      selectedType + "-" + id,
      settings[getUrlVariableValue("settingsId")].classSettings.classBuckets[
        selectedType
      ][id],
      value
    );
    setChanges(newRev);
    const updatedBucketNames = [
      ...settings[getUrlVariableValue("settingsId")].classSettings.classBuckets[
        selectedType
      ][id],
      value,
    ];

    dispatch({
      type: "UPDATE_CLASS_SETTINGS_WITH_KEY",
      settingsId: getUrlVariableValue("settingsId"),
      uid: uid,
      classType: selectedType,
      key: id,
      value: updatedBucketNames,
    });

    // handleSave(uid);
  };
  const handleSave = async (uid) => {
    let newRev = new RevertList(changes.getValues());

    const updateSuccessful = await updateStudioSettings(
      settings[getUrlVariableValue("settingsId")],
      uid,
      getUrlVariableValue("settingsId")
    );
    if (updateSuccessful) {
      reportCompilerDispatch({
        type: "BUILD_REPORT_COMPILER_STATE_QUESTIONS",
      });
      showSnackBar(`Successfully added session`, "success");
      setLoading(false);
      // Only perform these actions if the update was successful (returned true)
      setChanges(new RevertList([]));
      setSaveDisabled(true);
    } else {
      showSnackBar(`Failed to add session`, "error");
      setLoading(false);
    }

    onAdd();
  };
  const handleOnSelect = (e) => {
    setSelectedType(e.target.value);
  };

  const handleOnAdd = () => {
    // onAdd(selectedStaff);
    setLoading(true);
    handleBucketUpdate(className, "names");
  };

  return (
    <div className="add-class-component">
      <div className="add-class-component__content">
        <Grid item xs={12}>
          <p className="add-class-component__text">
            Select the type of class for <span>{className}</span>?
          </p>
          <FormControl fullWidth>
            <InputLabel>Type</InputLabel>
            <Select
              value={selectedType}
              onChange={handleOnSelect}
              input={<OutlinedInput label="Type" />}
            >
              {Object.keys(
                settings[getUrlVariableValue("settingsId")].classSettings
                  .classBuckets
              ).map((type) => (
                <MenuItem key={type} value={type}>
                  {type}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </div>
      <DialogActions className="add-class-component__actions">
        <Button onClick={onClose}>Cancel</Button>

        <LoadingButton loading={loading} variant="contained" color="primary" onClick={handleOnAdd}>
          Confirm
        </LoadingButton>
      </DialogActions>
    </div>
  );
};
export default AddClassComponent;
