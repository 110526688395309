import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

export default function BasicTable(props) {
  return (
    <TableContainer
      component={Paper}
      sx={{ width: "fit-content", margin: "0em", boxShadow: "none" }}
    >
      <Table sx={{ minWidth: "1001px" }} aria-label="simple table">
        <TableBody>
          {props.rows.map((cells, i) => {
            if (i === 1) {
              return (
                <TableRow
                  key={i}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  {cells.map((cell) => (
                    <TableCell
                      component="th"
                      scope="row"
                      style={{ fontWeight: "bold" }}
                    >
                      {cell}
                    </TableCell>
                  ))}
                </TableRow>
              );
            } else if (i === 0) {
              return null;
            }

            return (
              <TableRow
                key={i}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                {cells.map((cell) => (
                  <TableCell component="th" scope="row">
                    {cell}
                  </TableCell>
                ))}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
