// import React, { useState } from "react";
// import { Typography, Stack, Box, Divider, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from "@mui/material";
// import SelectorBox from "../../Components/SelectorBox/SelectorBox";
// import InputBoxElement from "../../Components/InputBox/InputBox";
// import BooleanToggleElement from "../../Components/BooleanToggle";
// import { RevertList } from "../../Components/RevertList";
// import { useSettingsContext } from "../../../contexts/SettingsContext";
// import { useAuth } from "../../../contexts/AuthContext";
// import { getUrlVariableValue } from "../../../utility-functions/utility-functions.js";
// import { Skeleton } from "@mui/material";
// import { SingleAttributeListBox } from "../../Components/AttributeListBox.js";

// export default function AdditionalSettings() {
//   const { getUID } = useAuth();
//   const { settings, dispatch, changes, setChanges } = useSettingsContext();
//   const [open, setOpen] = useState(false);

//   const handleClickOpen = () => {
//     setOpen(true);
//   };

//   const handleClose = () => {
//     setOpen(false);
//   };

//   function handleChange(value, id) {
//     let newRev = new RevertList(changes.getValues());
//     newRev.addElement(
//       id,
//       settings[getUrlVariableValue("settingsId")].generalSettings[id],
//       value
//     );
//     setChanges(newRev);

//     dispatch({
//       type: "UPDATE_SETTINGS_PROPERTY",
//       settingsId: getUrlVariableValue("settingsId"),
//       uid: getUID(),
//       path: `${getUrlVariableValue("settingsId")}.generalSettings.${id}`,
//       value: value,
//     });
//   }

//   const payFrequency = settings[getUrlVariableValue("settingsId")].generalSettings.payFrequency;
//   const semiOverride = settings[getUrlVariableValue("settingsId")].generalSettings.semiMonthlyPayPeriodOverride;

//   return (settings.hasOwnProperty("1") && settings.hasOwnProperty("staff")) ? (
//     <Box className="settings-page">
//       <Typography variant="h2" className="settings-page__title">
//         Admin
//       </Typography>
//       <Divider className="settings-page__divider" />
//       <Stack spacing={2}>

//         <Typography variant="h5">Pay Period Settings:</Typography>
//         <Button variant="outlined" color="primary" onClick={handleClickOpen}>
//           Edit Pay Period Settings
//         </Button>
//         <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" maxWidth="md">
//       <DialogTitle id="form-dialog-title">Pay Period Settings</DialogTitle>
//       <DialogContent>
//         <Box sx={{ marginTop: 2 }}>
//           <Grid container spacing={2}>
//             <Grid item xs={12} md={6}>
//               <Typography variant="subtitle1" gutterBottom>Processor:</Typography>
//               <SelectorBox
//                 id="processor"
//                 onChange={handleChange}
//                 default={settings[getUrlVariableValue("settingsId")].generalSettings.processor}
//                 size="auto"
//                 fullWidth
//                 options={["Gusto", "Paychex", "ADP"]}
//                 label="Processor"
//               />
//             </Grid>
//             <Grid item xs={12} md={6}>
//               <Typography variant="subtitle1" gutterBottom>Fiscal Week Start Day:</Typography>
//               <SelectorBox
//                 id="fiscalWorkweekStartDay"
//                 onChange={handleChange}
//                 default={settings[getUrlVariableValue("settingsId")].generalSettings.fiscalWorkweekStartDay}
//                 size="auto"
//                 fullWidth
//                 options={["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"]}
//                 label="Fiscal Week Start Day"
//               />
//             </Grid>
//             <Grid item xs={12} md={6}>
//               <Typography variant="subtitle1" gutterBottom>Frequency:</Typography>
//               <SelectorBox
//                 id="payFrequency"
//                 onChange={handleChange}
//                 default={payFrequency}
//                 size="auto"
//                 fullWidth
//                 options={["Weekly", "Bi-Weekly", "Semi-Monthly", "Monthly"]}
//                 label="Pay Frequency"
//               />
//             </Grid>

//             {payFrequency === "Weekly" && (
//               <Grid item xs={12} md={6}>
//                 <Typography variant="subtitle1" gutterBottom>Pay Day:</Typography>
//                 <SelectorBox
//                   id="weeklyPayday"
//                   onChange={handleChange}
//                   default={settings[getUrlVariableValue("settingsId")].generalSettings.weeklyPayday}
//                   size="auto"
//                   fullWidth
//                   options={["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"]}
//                   label="Weekly Payday"
//                 />
//               </Grid>
//             )}

//             {payFrequency === "Bi-Weekly" && (
//               <Grid item xs={12} md={6}>
//                 <Typography variant="subtitle1" gutterBottom>Pay Day:</Typography>
//                 <SelectorBox
//                   id="biWeeklyPayday"
//                   onChange={handleChange}
//                   default={settings[getUrlVariableValue("settingsId")].generalSettings.biWeeklyPayday}
//                   size="auto"
//                   fullWidth
//                   options={["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"]}
//                   label="Bi-Weekly Payday"
//                 />
//               </Grid>
//             )}

//             {payFrequency === "Monthly" && (
//               <Grid item xs={12} md={6}>
//                 <Typography variant="subtitle1" gutterBottom>Pay Date:</Typography>
//                 <SelectorBox
//                   id="monthlyPayday"
//                   onChange={handleChange}
//                   default={settings[getUrlVariableValue("settingsId")].generalSettings.monthlyPayday}
//                   size="auto"
//                   fullWidth
//                   options={["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23", "24", "25", "26", "27", "28", "29", "30", "31", "Last Day of the Month"]}
//                   label="Monthly Payday"
//                 />
//               </Grid>
//             )}

//             {payFrequency === "Semi-Monthly" && (
//               <>
//                 <Grid item xs={12} md={6}>
//                   <Typography variant="subtitle1" gutterBottom>First Semi-Monthly Payday:</Typography>
//                   <SelectorBox
//                     id="semiMonthlyPaydays-0"
//                     onChange={handleChange}
//                     default={settings[getUrlVariableValue("settingsId")].generalSettings.semiMonthlyPaydays[0]}
//                     size="auto"
//                     fullWidth
//                     options={["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23", "24", "25", "26", "27", "28", "29", "30", "31", "Last Day of the Month"]}
//                     label="First Semi-Monthly Payday"
//                   />
//                 </Grid>
//                 <Grid item xs={12} md={6}>
//                   <Typography variant="subtitle1" gutterBottom>Second Semi-Monthly Payday:</Typography>
//                   <SelectorBox
//                     id="semiMonthlyPaydays-1"
//                     onChange={handleChange}
//                     default={settings[getUrlVariableValue("settingsId")].generalSettings.semiMonthlyPaydays[1]}
//                     size="auto"
//                     fullWidth
//                     options={["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23", "24", "25", "26", "27", "28", "29", "30", "31", "Last Day of the Month"]}
//                     label="Second Semi-Monthly Payday"
//                   />
//                 </Grid>
//                 {semiOverride && (
//                   <>
//                     <Grid item xs={12}>
//                       <Typography variant="h6">Semi Monthly Override:</Typography>
//                       <BooleanToggleElement
//                         onChange={handleChange}
//                         id="semiMonthlyPayPeriodOverride"
//                         default={settings[getUrlVariableValue("settingsId")].generalSettings.semiMonthlyPayPeriodOverride}
//                       />
//                     </Grid>
//                   </>
//                 )}
//               </>
//             )}
//           </Grid>
//         </Box>
//       </DialogContent>
//       <DialogActions>
//         <Button onClick={handleClose} color="primary">Cancel</Button>
//         <Button onClick={handleClose} color="primary">Save</Button>
//       </DialogActions>
//     </Dialog>

        

        

//         <Typography variant="h5">Agreement Pay Sequence:</Typography>
//         <SelectorBox
//           id="agreementCommissionPaySequence"
//           onChange={handleChange}
//           default={settings[getUrlVariableValue("settingsId")].generalSettings.agreementCommissionPaySequence}
//           size={200}
//           options={["Standard", "Advanced", "Custom"]} // Define these based on your business logic
//           label="Commission Pay Sequence"
//         />

//         <Typography variant="h5">Retail Pay Sequence:</Typography>
//         <SelectorBox
//           id="retailCommissionPaySequenceType"
//           onChange={handleChange}
//           default={settings[getUrlVariableValue("settingsId")].generalSettings.retailCommissionPaySequenceType}
//           size={200}
//           options={["Standard", "Advanced", "Custom"]} // Define these based on your business logic
//           label="Commission Pay Sequence"
//         />

//         <Typography>Use PayWell's "Pay-Stub Emailer" Functionality:</Typography>
//         <BooleanToggleElement
//           onChange={handleChange}
//           id="useEmailer"
//           default={settings[getUrlVariableValue("settingsId")].generalSettings.useEmailer}
//         />

//         {settings[getUrlVariableValue("settingsId")].generalSettings?.useEmailer ? (
//           <Stack>
//             <Typography>Custom email subject header:</Typography>
//             <InputBoxElement
//               id="customEmailSubject"
//               onChange={handleChange}
//               default={settings[getUrlVariableValue("settingsId")].generalSettings.customEmailSubject}
//               types={["text"]}
//               size={100}
//               label="Custom Email Subject"
//             />
//           </Stack>
//         ) : null}

//       </Stack>
//     </Box>
//   ) : (
//     <Box className="settings-page">
//       <Skeleton>
//         <Typography variant="h2" className="settings-page__title">
//           Studios
//         </Typography>
//       </Skeleton>
//       <Divider className="settings-page__divider" />
//       <Skeleton className="settings-page__attribute-box settings-page__attribute-box--skeleton"></Skeleton>
//     </Box>
//   );
// }


import React, { useState } from "react";
import { Typography, Stack, Box, Divider, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Accordion, AccordionSummary, AccordionDetails } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SelectorBox from "../../Components/SelectorBox/SelectorBox";
import InputBoxElement from "../../Components/InputBox/InputBox";
import BooleanToggleElement from "../../Components/BooleanToggle";
import { RevertList } from "../../Components/RevertList";
import { useSettingsContext } from "../../../contexts/SettingsContext";
import { useAuth } from "../../../contexts/AuthContext";
import { Skeleton } from "@mui/material";
import { getUrlVariableValue } from "../../../utility-functions/utility-functions.js";
import PayPeriodsConfig from "./PayPeriodsConfig.js";

export default function AdditionalSettings() {
  const { getUID } = useAuth();
  const { settings, dispatch, changes, setChanges } = useSettingsContext();
  const [open, setOpen] = useState(false);

  const handleDialogOpen = () => setOpen(true);
  const handleDialogClose = () => setOpen(false);

    function handleChange(value, id) {
      if(id.includes("-")){
        let [newId, index] = id.split("-");
        let arrayValue = settings[getUrlVariableValue("settingsId")].generalSettings[newId];
        arrayValue[index] = value;
        let newRev = new RevertList(changes.getValues());
        newRev.addElement(
          newId,
          settings[getUrlVariableValue("settingsId")].generalSettings[newId],
          arrayValue
        );
        setChanges(newRev);
  
        dispatch({
          type: "UPDATE_SETTINGS_PROPERTY",
          settingsId: getUrlVariableValue("settingsId"),
          uid: getUID(),
          path: `${getUrlVariableValue("settingsId")}.generalSettings.${newId}`,
          value: arrayValue,
        });
        return;
      }
    let newRev = new RevertList(changes.getValues());
    newRev.addElement(
      id,
      settings[getUrlVariableValue("settingsId")].generalSettings[id],
      value
    );
    setChanges(newRev);

    dispatch({
      type: "UPDATE_SETTINGS_PROPERTY",
      settingsId: getUrlVariableValue("settingsId"),
      uid: getUID(),
      path: `${getUrlVariableValue("settingsId")}.generalSettings.${id}`,
      value: value,
    });
  }

  if (!settings.hasOwnProperty("1") || !settings.hasOwnProperty("staff")) {
    return (
      <Box className="settings-page">
        <Skeleton animation="wave">
          <Typography variant="h2" className="settings-page__title">Loading Settings...</Typography>
        </Skeleton>
      </Box>
    );
  }

  const payFrequency = settings[getUrlVariableValue("settingsId")].generalSettings.payFrequency;
  
  return (
    <Box className="settings-page">
      <Typography variant="h2" gutterBottom>Admin Settings</Typography>
      <Divider />
      <Stack spacing={2}>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="h6">Pay Period Settings</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Button variant="outlined" onClick={handleDialogOpen}>Edit Pay Period Settings</Button>
            <Dialog open={open} onClose={handleDialogClose} aria-labelledby="form-dialog-title" maxWidth="md">
      <DialogTitle id="form-dialog-title">Pay Period Settings</DialogTitle>
      <DialogContent>
        <Box sx={{ marginTop: 2 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Typography variant="subtitle1" gutterBottom>Processor:</Typography>
              <SelectorBox
                id="processor"
                onChange={handleChange}
                default={settings[getUrlVariableValue("settingsId")].generalSettings.processor}
                size="auto"
                fullWidth
                options={["Gusto", "Paychex", "ADP"]}
                label="Processor"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant="subtitle1" gutterBottom>Fiscal Week Start Day:</Typography>
              <SelectorBox
                id="fiscalWorkweekStartDay"
                onChange={handleChange}
                default={settings[getUrlVariableValue("settingsId")].generalSettings.fiscalWorkweekStartDay}
                size="auto"
                fullWidth
                options={["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"]}
                label="Fiscal Week Start Day"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant="subtitle1" gutterBottom>Frequency:</Typography>
              <SelectorBox
                id="payFrequency"
                onChange={handleChange}
                default={payFrequency}
                size="auto"
                fullWidth
                options={["Weekly", "Bi-Weekly", "Semi-Monthly", "Monthly"]}
                label="Pay Frequency"
              />
            </Grid>

            {payFrequency === "Weekly" && (
              <Grid item xs={12} md={6}>
                <Typography variant="subtitle1" gutterBottom>Pay Day:</Typography>
                <SelectorBox
                  id="weeklyPayDay"
                  onChange={handleChange}
                  default={settings[getUrlVariableValue("settingsId")].generalSettings.weeklyPayDay}
                  size="auto"
                  fullWidth
                  options={["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"]}
                  label="Weekly Payday"
                />
              </Grid>
            )}

            {payFrequency === "Bi-Weekly" && (
              <Grid item xs={12} md={6}>
                <Typography variant="subtitle1" gutterBottom>Pay Day:</Typography>
                <SelectorBox
                  id="weeklyPayDay"
                  onChange={handleChange}
                  default={settings[getUrlVariableValue("settingsId")].generalSettings.weeklyPayDay}
                  size="auto"
                  fullWidth
                  options={["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"]}
                  label="Bi-Weekly Payday"
                />
              </Grid>
            )}

            {payFrequency === "Monthly" && (
              <>
              <Grid item xs={12} md={6}>
                <Typography variant="subtitle1" gutterBottom>Pay Date:</Typography>
                <SelectorBox
                  id="monthlyPayDays-0"
                  onChange={handleChange}
                  default={settings[getUrlVariableValue("settingsId")].generalSettings.monthlyPayDays[0]}
                  size="auto"
                  fullWidth
                  options={["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23", "24", "25", "26", "27", "28", "29", "30", "31", "Last Day of the Month"]}
                  label="Monthly Payday"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                  <Typography variant="subtitle1" gutterBottom>Pay Period Start Day:</Typography>
                  <SelectorBox
                    id="payPeriodDays1-0"
                    onChange={handleChange}
                    default={settings[getUrlVariableValue("settingsId")].generalSettings.payPeriodDays1[0]}
                    size="auto"
                    fullWidth
                    options={["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23", "24", "25", "26", "27", "28", "29", "30", "31", "Last Day of the Month"]}
                    label="Start of Pay Period 1"
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography variant="subtitle1" gutterBottom>Pay Period End Day:</Typography>
                  <SelectorBox
                    id="payPeriodDays1-1"
                    onChange={handleChange}
                    default={settings[getUrlVariableValue("settingsId")].generalSettings.payPeriodDays1[1]}
                    size="auto"
                    fullWidth
                    options={["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23", "24", "25", "26", "27", "28", "29", "30", "31", "Last Day of the Month"]}
                    label="End of Pay Period 1"
                  />
                </Grid>
            </>
            )}

            {payFrequency === "Semi-Monthly" && (
              <>
                <Grid item xs={12} md={6}>
                  <Typography variant="subtitle1" gutterBottom>First Semi-Monthly Payday:</Typography>
                  <SelectorBox
                    id="monthlyPayDays-0"
                    onChange={handleChange}
                    default={settings[getUrlVariableValue("settingsId")].generalSettings.monthlyPayDays[0]}
                    size="auto"
                    fullWidth
                    options={["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23", "24", "25", "26", "27", "28", "29", "30", "31", "Last Day of the Month"]}
                    label="First Semi-Monthly Payday"
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography variant="subtitle1" gutterBottom>Second Semi-Monthly Payday:</Typography>
                  <SelectorBox
                    id="monthlyPayDays-1"
                    onChange={handleChange}
                    default={settings[getUrlVariableValue("settingsId")].generalSettings.monthlyPayDays[1]}
                    size="auto"
                    fullWidth
                    options={["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23", "24", "25", "26", "27", "28", "29", "30", "31", "Last Day of the Month"]}
                    label="Second Semi-Monthly Payday"
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography variant="subtitle1" gutterBottom>First Pay Period Start Day:</Typography>
                  <SelectorBox
                    id="payPeriodDays1-0"
                    onChange={handleChange}
                    default={settings[getUrlVariableValue("settingsId")].generalSettings.payPeriodDays1[0]}
                    size="auto"
                    fullWidth
                    options={["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23", "24", "25", "26", "27", "28", "29", "30", "31", "Last Day of the Month"]}
                    label="Start of Pay Period 1"
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography variant="subtitle1" gutterBottom>First Pay Period End Day:</Typography>
                  <SelectorBox
                    id="payPeriodDays1-1"
                    onChange={handleChange}
                    default={settings[getUrlVariableValue("settingsId")].generalSettings.payPeriodDays1[1]}
                    size="auto"
                    fullWidth
                    options={["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23", "24", "25", "26", "27", "28", "29", "30", "31", "Last Day of the Month"]}
                    label="End of Pay Period 1"
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography variant="subtitle1" gutterBottom>Second Pay Period Start Day:</Typography>
                  <SelectorBox
                    id="payPeriodDays2-0"
                    onChange={handleChange}
                    default={settings[getUrlVariableValue("settingsId")].generalSettings.payPeriodDays2[0]}
                    size="auto"
                    fullWidth
                    options={["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23", "24", "25", "26", "27", "28", "29", "30", "31", "Last Day of the Month"]}
                    label="Start of Pay Period 2"
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography variant="subtitle1" gutterBottom>Second Pay Period End Day:</Typography>
                  <SelectorBox
                    id="payPeriodDays2-1"
                    onChange={handleChange}
                    default={settings[getUrlVariableValue("settingsId")].generalSettings.payPeriodDays2[1]}
                    size="auto"
                    fullWidth
                    options={["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23", "24", "25", "26", "27", "28", "29", "30", "31", "Last Day of the Month"]}
                    label="End of Pay Period 2"
                  />
                </Grid>
              </>
            )}

            <PayPeriodsConfig 
              id="payPeriodExamples"
              payPeriods = {settings[getUrlVariableValue("settingsId")].generalSettings?.payPeriodExamples ?? []}
              onChange = {handleChange}
            />
          </Grid>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleDialogClose} color="primary">Cancel</Button>
        <Button onClick={handleDialogClose} color="primary">Save</Button>
      </DialogActions>
    </Dialog>
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="h6">Commission Settings</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="h6">Agreement Pay Sequence:</Typography>
            <SelectorBox
              id="agreementCommissionPaySequence"
              onChange={handleChange}
              default={settings[getUrlVariableValue("settingsId")].generalSettings?.agreementCommissionPaySequence}
              options={["Standard", "Advanced", "Custom"]}
              label="Agreement Pay Sequence"
            />
            <Typography variant="h6">Retail Pay Sequence:</Typography>
            <SelectorBox
              id="retailCommissionPaySequenceType"
              onChange={handleChange}
              default={settings[getUrlVariableValue("settingsId")].generalSettings?.retailCommissionPaySequenceType}
              options={["Standard", "Advanced", "Custom"]}
              label="Retail Pay Sequence"
            />
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="h6">Email Settings</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <BooleanToggleElement
              onChange={handleChange}
              id="useEmailer"
              default={settings[getUrlVariableValue("settingsId")].generalSettings?.useEmailer}
            />
            {settings[getUrlVariableValue("settingsId")].generalSettings?.useEmailer && (
              <InputBoxElement
                id="customEmailSubject"
                types={["text"]}
                onChange={handleChange}
                default={settings[getUrlVariableValue("settingsId")].generalSettings?.customEmailSubject}
                label="Custom Email Subject"
              />
            )}
          </AccordionDetails>
        </Accordion>
      </Stack>
    </Box>
  );
}

