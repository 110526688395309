import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { IconButton } from "@mui/material";
import "./dialogs.scss";
export default function ConfirmationDialog(props) {
  const [open, setOpen] = React.useState(props.open ? props.open : false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    props.onClose ? props.onClose() : null;

    setOpen(false);
  };

  const handleConfirm = () => {
    props.onConfirm ? props.onConfirm() : null;
    setOpen(false);
  };

  return (
    <div>
      {props.icon ? (
        <IconButton onClick={handleClickOpen} size="large">
          {props.icon}
        </IconButton>
      ) : (
        <Button variant="outlined" onClick={handleClickOpen}>
          {props.openIcon ?? " Open confirmation dialog"}
        </Button>
      )}
      <Dialog
        className="confirmation-dialog"
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle component={"h3"} id="alert-dialog-title">
          {props.confirmTitle ?? "Are you sure?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {props.confirmDescription ?? null}
          </DialogContentText>
        </DialogContent>
        <DialogActions className="confirmation-dialog__actions">
          <Button onClick={handleClose}>Cancel</Button>

          <Button
            color={props.confirmButtonColor ?? null}
            variant={props.confirmButtonVariant ?? "text"}
            startIcon={props.confirmButtonIcon ?? null}
            onClick={handleConfirm}
            autoFocus
          >
            {props.confirmButtonText ?? "Confirm"}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
